import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { string } from 'prop-types';
import { I18n } from 'react-redux-i18n';
import styled from 'styled-components';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import copy from 'copy-to-clipboard';

import managedGroupTableActions from '../../../table/tableActions/managedGroup';
import * as actions from '../../../table/actionsList';

import PageWrapper from '../../PageWrapper';
import SimplifiedTable from '../../reusable/Tables/SimplifiedTable';
import ConfirmationModal from '../../reusable/ConfirmationModal';
import BulkActionsSidebar from '../../BulkActionsSidebar';
import Spinner from '../../reusable/Spinner';

import {
  fetchGroupsById,
  fetchManagedGroupSession,
  resetManagedGroupState,
  setActiveGroup,
  endManagedGroupSession,
  setManagedGroupSelectedRespondents,
  authorizeRespondents,
  checkManagedGroupHash,
} from '../../../store/groups/actions';
import groupBreadcrumbs from './group.breadcrumbs';
import createToastNotification from '../../../utils/createToastNotification';
import { resolveBatteryName } from '../../../utils/assessmentsNames';
import useBulkSidebarState from '../../../hooks/useBulkSidebarState';

import { ReactComponent as CopyLinkIcon } from '../../../assets/icons/copy-link-v2.svg';
import managedGroupRespondentsColumns from '../../../table/columns/managedGroupRespondents';
import managedGroupRespondentsBulkActions from '../../../table/bulkActions/managedGroupResponents';
import useModals from '../../../hooks/useModals';

import { ReactComponent as EmptyTableIcon } from '../../../assets/icons/empty-respondents-table.svg';

const ManagedGroupView = ({ groupId }) => {
  const dispatch = useDispatch();
  const activeGroupInView = useSelector((state) => state.groups.activeGroupInView);
  const { link: sessionData, respondents, selectedRespondents } = useSelector(
    (state) => state.groups.activeManagedGroupSession,
  );
  const userLanguages = useSelector((state) => state.user.languages);
  const userBatteries = useSelector((state) => state.user.batteries);
  const [isEndSessionModalVisible, setEndSessionModalState] = useState(false);
  const [isLoading, setLoadingState] = useState(true);

  const fetchSession = () => {
    setLoadingState(true);
    dispatch(
      fetchManagedGroupSession(groupId, () => {
        setLoadingState(false);
      }),
    );
  };

  useEffect(() => {
    if (groupId) {
      dispatch(fetchGroupsById(groupId));
      fetchSession();
    }
  }, [groupId]);
  useEffect(() => {
    return () => {
      dispatch(setActiveGroup(null));
      dispatch(resetManagedGroupState());
    };
  }, []);

  useEffect(() => {
    if (!sessionData?.accessCode) return;
    const intervalId = setInterval(() => {
      dispatch(checkManagedGroupHash(groupId, sessionData.accessCode));
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, [sessionData]);

  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedRespondents });

  const modals = [actions.AUTHORIZE_RESPONDENT];
  const { modalsState, openModal, closeModal } = useModals(modals);

  const onBackButtonClick = () => dispatch(push('/groups'));

  const copyLink = () => {
    copy(sessionData?.url);
    createToastNotification({ message: I18n.t('Link copied successfully') });
  };

  const endSession = () => {
    dispatch(
      endManagedGroupSession(groupId, (err) => {
        if (!err) {
          setLoadingState(true);
          setTimeout(() => {
            dispatch(resetManagedGroupState());
            dispatch(push(`/groups/${groupId}`));
          }, 1000);
        }
      }),
    );
  };

  const onAuthorize = () => {
    dispatch(
      authorizeRespondents(
        groupId,
        selectedRespondents.map((item) => item.inviteId),
        (err) => {
          // reset table state
          if (!err) {
            setLoadingState(true);
            setTimeout(() => {
              dispatch(
                fetchManagedGroupSession(groupId, () => {
                  setLoadingState(false);
                }),
              );
            }, 1000);
          }

          closeModal(actions.AUTHORIZE_RESPONDENT);
        },
      ),
    );
  };

  const onSelectChange = useCallback((data) => {
    dispatch(setManagedGroupSelectedRespondents(data));
  }, []);

  // table actions
  const tableHandlers = {
    [actions.END_GROUP_SESSION]: () => setEndSessionModalState(true),
  };
  const tableActions = useMemo(() => {
    return managedGroupTableActions.map((item) => ({ ...item, handler: tableHandlers[item.name] }));
  }, []);

  const bulkActions = useMemo(() => {
    return managedGroupRespondentsBulkActions.map((item) => ({
      ...item,
      handler: () => openModal(item.name),
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedRespondents) : item.isDisabled,
    }));
  }, [selectedRespondents]);

  const sessionLang = useMemo(() => {
    const lang = userLanguages.find((lang) => lang.langID === sessionData?.langID);
    if (!lang) return '';
    return `${lang.english} (${lang.langID})`;
  }, [sessionData, userLanguages.length]);

  const TitleComponent = () => (
    <TitleWrapper>
      <Title>{activeGroupInView?.description}</Title>
      <TitleLabel>{I18n.t('Managed group session in progress')}</TitleLabel>
    </TitleWrapper>
  );

  const AdditionalRowComponent = () => (
    <InfoWrapper>
      <Pair>
        <span>{I18n.t('Assessment')}</span>
        <span>
          {sessionData?.testID
            ? sessionData?.testID
            : resolveBatteryName(userBatteries.find((bat) => bat.batteryID === sessionData?.batteryID))}
        </span>
      </Pair>
      <Pair>
        <span>{I18n.t('Language')}</span>
        <span>{sessionLang}</span>
      </Pair>
    </InfoWrapper>
  );

  const EmptyTableComponent = () => (
    <EmptyTableWrapper>
      <EmptyTableIcon />
      <EmptyTableTitle>{I18n.t('No respondents to display')}</EmptyTableTitle>
      <EmptyTableDescription>
        {I18n.t(
          "You may add respondents to the group from the existing respondents' list, or they can create new records during the session authorization via the session link",
        )}
      </EmptyTableDescription>
    </EmptyTableWrapper>
  );

  // needed for table row rendering optimization, attempt to prevent unnecessary rerenders
  const rowEqualityFunc = useCallback((prevItem, nextItem) => {
    return prevItem.statusId === nextItem.statusId && prevItem.email === nextItem.email;
  }, []);

  return (
    <StyledPageWrapper
      breadcrumbs={groupBreadcrumbs(activeGroupInView)}
      title={<TitleComponent />}
      additionalInfo={<AdditionalRowComponent />}
      buttons={tableActions}
      uppercaseButtons
      backButtonHandler={onBackButtonClick}
    >
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      <StyledConfirmationModal
        isHeaderHidden
        buttonsBetween
        isVisible={isEndSessionModalVisible}
        onClose={() => setEndSessionModalState(false)}
        description={I18n.t(
          'Do you want to close the session? Assessments that have started may still be completed but no new respondents may be added',
        )}
        onConfirm={endSession}
        confirmText={I18n.t('End session')}
        cancelText={I18n.t('Cancel')}
      />
      <Spinner isLoading={isLoading} />
      <Divider />
      <CopyLinkContainer>
        <Pair marginless>
          <span>{I18n.t('Session link')}</span>
          <span>{sessionData?.url}</span>
        </Pair>
        <CopyLinkWrapper onClick={() => copyLink()}>
          <CopyLinkIcon />
        </CopyLinkWrapper>
      </CopyLinkContainer>
      <TableHeader>
        <TableTitle>
          {I18n.t('Respondent sessions')} ({respondents.length})
        </TableTitle>
      </TableHeader>
      <SimplifiedTable
        columns={managedGroupRespondentsColumns}
        data={respondents}
        onSelectChange={onSelectChange}
        idAccessor="respondentId"
        entityName="respondent"
        customEmptyMessage={<EmptyTableComponent />}
        rowEqualityFunc={rowEqualityFunc}
        bottomOffset={respondents.length > 10 ? 210 : 0}
      />
      {modalsState[actions.AUTHORIZE_RESPONDENT] && (
        <StyledConfirmationModal
          isHeaderHidden
          buttonsBetween
          isVisible
          onClose={() => closeModal(actions.AUTHORIZE_RESPONDENT)}
          description={I18n.t('Authorize selected respondent(s) to commence the assessment?')}
          onConfirm={onAuthorize}
          confirmText={I18n.t('Authorize')}
          cancelText={I18n.t('Cancel')}
        />
      )}
    </StyledPageWrapper>
  );
};

ManagedGroupView.propTypes = {
  groupId: string.isRequired,
};

const StyledPageWrapper = styled(PageWrapper)`
  position: relative;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
`;

const Title = styled.h1`
  color: ${(props) => props.theme.colors.darkBlue2};
  font-weight: bold;
  font-size: 24px;
  margin: 0;
`;

const TitleLabel = styled.span`
  font-size: ${(props) => props.theme.fontSizes.small};
  color: ${(props) => props.theme.colors.mediumBlue};
  font-weight: 500;
  line-height: 14px;
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Pair = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: ${(props) => (props.marginless ? '0' : '0 3.2rem')};

  span:first-child {
    font-size: ${(props) => props.theme.fontSizes.small};
    color: ${(props) => props.theme.colors.mediumBlue};
    font-weight: 500;
  }

  span:last-child {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.88);
  }
`;

const Divider = styled.div`
  margin-top: 1.2rem;
  margin-bottom: 3rem;
  width: auto;
  height: 1px;
  background: rgba(0, 0, 0, 0.12);
`;

const CopyLinkContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CopyLinkWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${(props) => props.theme.colors.mediumBlue};
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  background: none;
  margin-left: 1rem;

  :hover {
    cursor: pointer;
  }
  color: ${(props) => (props.isCopied ? props.theme.colors.green : props.theme.colors.mediumBlue)};
  font-weight: 600;
`;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2.4rem 0;
`;

const TableTitle = styled.h3`
  color: ${(props) => props.theme.colors.darkBlue2};
  font-weight: bold;
  font-size: 20px;
  margin: 0;
`;

const StyledConfirmationModal = styled(ConfirmationModal)`
  width: 45rem;
  max-width: 45rem;
  min-width: 45rem;
`;

const EmptyTableWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 70rem;
`;

const EmptyTableTitle = styled.p`
  color: rgba(0, 0, 0, 0.4);
  margin: 1.6rem 0;
  font-size: 20px;
  font-weight: bold;
`;

const EmptyTableDescription = styled.p`
  color: rgba(0, 0, 0, 0.4);
  font-size: ${(props) => props.theme.fontSizes.normal};
  text-align: center;
`;

export default ManagedGroupView;
