import ch from './ch';
import chtr from './chtr';
import en from './en';
import es from './es';
import esLatam from './es-latam';
import it from './it';
import ko from './ko';
import ptBr from './pt-br';
import ru from './ru';
import ua from './ua';
import tr from './tr';

export default {
  ch,
  chtr,
  en,
  es,
  'es-latam': esLatam,
  it,
  ko,
  'pt-br': ptBr,
  ru,
  ua,
  tr,
};
