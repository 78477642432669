import React, { useState, useCallback, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import apiInstance2 from '../../services/apiService';
import AddCompetencyQuestionForm from './AddCompetencyQuestionForm';
import { errorMessages } from '../../constants/errorMessages';
import NewModal from '../reusable/NewModal';
import { selectAvailableLanguages } from '../../store/user/selectors';
import createToastNotification from '../../utils/createToastNotification';

const AddCompetencyQuestion = ({ competency, onCancel, onClose }) => {
  const languages = useSelector(selectAvailableLanguages);
  const [formData, setFormData] = useState({
    type: '0',
    languages: [
      {
        lang: { value: '', code: '', label: '' },
        question: '',
        instruction: '',
      },
    ],
  });

  const isUpdating = competency && competency.itemID;

  useEffect(() => {
    if (isUpdating && languages && languages.length) {
      const haveCustomLanguages = competency.customLanguages && competency.customLanguages[0]?.langID;

      let languagesObject;

      if (competency.type === '0' || competency.type === 0 || !haveCustomLanguages) {
        languagesObject = Object.keys(competency.languages).map((key) => ({
          lang: languages.find((language) => language.value === key),
          question: competency.languages[key],
          instruction: '',
        }));
      } else {
        languagesObject = competency.customLanguages.map((data) => ({
          lang: languages.find((language) => language.value === data.langID),
          question: data.item,
          instruction: data.instruction || '',
        }));
      }

      setFormData({
        type: String(competency.type),
        languages: languagesObject,
      });
    }
  }, [isUpdating, competency, languages]);

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      setSubmitting(true);

      const oldLanguagesObject = values.languages.reduce((acc, curr) => {
        acc[curr.lang.value] = curr.question;
        return acc;
      }, {});

      const languagesObject = values.languages.map((data) => ({
        langID: data.lang.value,
        item: data.question,
        instruction: data.instruction,
      }));

      let data;

      if (values.type === '1' || values.type === 1) {
        data = {
          competencyID: competency.competencyID,
          type: parseInt(values.type, 10),
          item: languagesObject[0].item,
          customLanguages: languagesObject,
        };
      } else {
        data = {
          competencyID: competency.competencyID,
          type: parseInt(values.type, 10),
          item: oldLanguagesObject[Object.keys(oldLanguagesObject)[0]],
          languages: oldLanguagesObject,
          customLanguages: languagesObject,
        };
      }

      const afterData = data;

      apiInstance2[isUpdating ? 'patch' : 'post'](`/api/v2/360/competencies/${competency.competencyID}/item`, {
        ...data,
        ...(isUpdating && { itemID: competency.itemID }),
      })
        .then(({ data }) => {
          setSubmitting(false);
          createToastNotification({ message: I18n.t(isUpdating ? 'questionUpdateToast' : 'questionAdditionToast') });
          onClose(
            values.type === '1' || values.type === 1
              ? { customLanguages: afterData.customLanguages, languages: oldLanguagesObject, ...data }
              : data,
            !isUpdating,
          );
        })
        .catch(() => {
          setSubmitting(false);
        });
    },
    [competency, isUpdating],
  );

  const validationSchema = Yup.object().shape({
    type: Yup.string().required(),
    languages: Yup.array().of(
      Yup.object().shape({
        lang: Yup.object()
          .shape({
            value: Yup.string().required(),
            code: Yup.string().required(),
            label: Yup.string().required(),
          })
          .required(errorMessages.selectField),
        question: Yup.string()
          .required(errorMessages.inputField)
          .max(500, errorMessages.tooLong(500)),

        instruction: Yup.string(),
      }),
    ),
  });

  return (
    <NewModal isVisible onClose={onCancel} title={isUpdating ? I18n.t('Edit question') : I18n.t('Add question')}>
      <Formik
        enableReinitialize
        initialValues={formData}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        component={AddCompetencyQuestionForm}
      />
    </NewModal>
  );
};

export default AddCompetencyQuestion;
