import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { string, func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import copy from 'copy-to-clipboard';
import { I18n } from 'react-redux-i18n';

import { ReactComponent as EmptyIcon } from '../../assets/icons/empty_state.svg';

import SimplifiedTable from '../reusable/Tables/SimplifiedTable';
import ConfirmationModal from '../reusable/ConfirmationModal';
import BulkActionsSidebar from '../BulkActionsSidebar';
import Checkbox from '../reusable/FormComponents/Checkbox';

import invitesBulkActions from '../../table/bulkActions/invites';

import respondentInvitesColumns from '../../table/columns/respondentInvites';
import useBulkSidebarState from '../../hooks/useBulkSidebarState';
import * as actions from '../../table/actionsList';
import { createCopiedNotification } from '../Invites/Invites';

import { fetchRespondentInvites } from '../../store/respondents/actions';

import { updateExpiryAssessmentInvites } from '../../store/invites/actions';

import useModals from '../../hooks/useModals';
import ResetExpiryDate from '../Invites/ResetExpiryDate';

const RespondentInvites = ({ deleteInvites, respondentId, fetchRespondent }) => {
  const user = useSelector((state) => state.user.user);
  const invites = useSelector((state) => state.respondents.respondentInvites);

  const dispatch = useDispatch();

  const [expiryDate, setExpiryDate] = useState(new Date());
  const [showExpired, setShowExpiredState] = useState(false);
  const toggleExpired = () => setShowExpiredState((prev) => !prev);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    dispatch(
      fetchRespondentInvites(respondentId, showExpired, () => {
        setLoading(false);
      }),
    );
  }, [showExpired]);

  const [selectedInvites, setSelectedInvites] = useState([]);

  const [inviteToDelete, setInviteToDelete] = useState(null);

  const [isConfirmationModalVisible, setConfirmationModalVisibilityStatus] = useState(false);

  const closeConfirmationModal = () => {
    if (inviteToDelete) setInviteToDelete(null);
    setConfirmationModalVisibilityStatus(false);
  };
  const openConfirmationModal = () => setConfirmationModalVisibilityStatus(true);

  const onDeleteConfirm = () => {
    const data = inviteToDelete
      ? [{ inviteID: inviteToDelete.inviteID, inviteType: inviteToDelete.inviteType }]
      : selectedInvites.map((item) => ({ inviteID: item.inviteID, inviteType: item.inviteType }));
    deleteInvites(data, () => {
      closeConfirmationModal();
      fetchRespondent();
    });
  };

  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedInvites });

  const modals = [actions.RESET_DEADLINE];
  const { modalsState, openModal, closeModal } = useModals(modals);

  // BULK ACTIONS
  const bulkActionsHandlers = {
    [actions.RESET_DEADLINE]: () => openModal(actions.RESET_DEADLINE),
    [actions.COPY_LINK]: () => {
      copy(selectedInvites[0].downloadLink);
      createCopiedNotification();
    },
    [actions.DELETE_INVITE]: openConfirmationModal,
  };
  const bulkActions = useMemo(() => {
    return invitesBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedInvites) : item.isDisabled,
    }));
  }, [selectedInvites, user]);

  const onChange = useCallback((data) => {
    setSelectedInvites(data);
  }, []);

  const showTable = isLoading || invites.length;

  const onValueChange = (value) => {
    setExpiryDate(value);
  };

  useEffect(() => {
    setExpiryDate(selectedInvites[0] ? new Date(selectedInvites[0].expiryDate) : new Date());

    return () => {};
  }, [selectedInvites.length]);

  const handleExpiryDateUpdates = (type, invites) => {
    const data = {
      inviteType: type,
      inviteIDs: invites.map((invite) => invite.inviteID),
      expiryDate: expiryDate.toISOString(),
    };
    dispatch(updateExpiryAssessmentInvites(data), closeModal(actions.RESET_DEADLINE));
    setTimeout(() => {
      toggleExpired();
    }, 500);

    setSelectedInvites([]);
  };

  const onExpiryDateUpdates = () => {
    const assessmentInviteType = 0;
    const assessmentInvites = selectedInvites.filter((invitation) => invitation.inviteType === assessmentInviteType);

    if (assessmentInvites.length > 0) handleExpiryDateUpdates(assessmentInviteType, assessmentInvites);
  };

  return (
    <RespondentInvitesContainer alignCenter={Boolean(!showTable)}>
      <ShowExpiredContainer onClick={toggleExpired}>
        <Checkbox isChecked={showExpired} />
        <span>{I18n.t('Show expired')}</span>
      </ShowExpiredContainer>
      <BulkActionsSidebar actions={bulkActions} onClose={closeSidebar} isOpen={isSidebarOpen} />
      {showTable ? (
        <SimplifiedTable
          columns={respondentInvitesColumns}
          data={invites}
          initialState={{ sortBy: [{ id: 'Invitation Date', desc: true }] }}
          onSelectChange={onChange}
          idAccessor="inviteID"
          isLoading={isLoading}
          entityName="invite"
        />
      ) : (
        <EmptyMessageWrapper>
          <EmptyIcon />
          <EmptyMessage>{I18n.t('Sorry, we could not find any invites')}</EmptyMessage>
        </EmptyMessageWrapper>
      )}
      <ConfirmationModal
        title={I18n.t('Invite Deletion')}
        onClose={closeConfirmationModal}
        isVisible={isConfirmationModalVisible}
        description={`Are you sure you want to delete ${selectedInvites.length > 0 ? 'selected' : 'chosen'} invite(s)?`} // TODO discuss with Cloud
        onConfirm={onDeleteConfirm}
        caution
      />
      {modalsState[actions.RESET_DEADLINE] && (
        <ResetExpiryDate
          onClose={() => closeModal(actions.RESET_DEADLINE)}
          onConfirm={onExpiryDateUpdates}
          expiryDate={expiryDate}
          // expiryDate={new Date(selectedInvites[0].expiryDate)}
          onValueChange={onValueChange}
        />
      )}
    </RespondentInvitesContainer>
  );
};

RespondentInvites.propTypes = {
  respondentId: string.isRequired,
  deleteInvites: func.isRequired,
  fetchRespondent: func.isRequired,
};

const RespondentInvitesContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-bottom: 8rem;
  margin-top: 10px;

  ${(props) =>
    props.alignCenter &&
    css`
      justify-content: center;
      align-items: center;
    `}
`;

const ShowExpiredContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: -5.2rem;
  right: 0;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;

  span {
    font-size: ${(props) => props.theme.fontSizes.small};
    font-weight: 500;
    margin-left: 0.5rem;
  }
`;

const EmptyMessageWrapper = styled.div`
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EmptyMessage = styled.p`
  margin: 15px 0 0 0;
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
`;

export default RespondentInvites;
