import { createAction } from 'redux-actions';
import apiInstance2 from '../../../services/apiService';

export const fetchCompetenciesStart = createAction('[REVIEW_COMPETENCIES] FETCH_DATA');
export const fetchCompetenciesSuccess = createAction(
  '[REVIEW_COMPETENCIES] FETCH_DATA_SUCCESS',
  (categories, competencies) => ({
    categories,
    competencies,
  }),
);
export const fetchCompetenciesFailure = createAction('[REVIEW_COMPETENCIES] FETCH_DATA_FAILURE');
export const updateCompetenciesStart = createAction('[REVIEW_COMPETENCIES] UPDATE_DATA');
export const updateCompetenciesSuccess = createAction('[REVIEW_COMPETENCIES] UPDATE_DATA_SUCCESS');
export const updateCompetenciesFailure = createAction('[REVIEW_COMPETENCIES] UPDATE_DATA_FAILURE');
export const resetReviewCompetencies = createAction('[REVIEW_COMPETENCIES] RESET');

export const fetchCompetencies = () => async (dispatch) => {
  dispatch(fetchCompetenciesStart());

  try {
    const categories = await apiInstance2.get('/api/v2/360/competency-categories');
    const competencies = await apiInstance2.get(`/api/v2/360/competencies`);

    if (categories.status === 200) {
      dispatch(fetchCompetenciesSuccess(categories.data, competencies.data));
    }
  } catch (err) {
    dispatch(fetchCompetenciesFailure());
  }
};

export const updateCompetencies = (groupId, data, callback) => async (dispatch) => {
  dispatch(updateCompetenciesStart());

  try {
    const updated = await apiInstance2.patch(`/api/v2/360/competency-groups/${groupId}/competencies`, data);

    if (updated.status === 200) {
      dispatch(updateCompetenciesSuccess());
      callback();
    }
  } catch (err) {
    dispatch(updateCompetenciesFailure());
    callback();
  }
};
