import React from 'react';
import { func, array } from 'prop-types';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import MultiSelect from '../../reusable/Selects/MultiSelect';
import Tag from '../../reusable/Label';

const TagsTab = ({ allTags, onAddTag, selectedTags, onRemoveTag }) => {
  return (
    <Container>
      <MultiSelect options={allTags} creatable onChange={onAddTag} placeholder={I18n.t('Select tags')} />
      {!!selectedTags.length && (
        <TagsWrapper>
          {selectedTags.map((tag) => (
            <Tag value={tag.name} key={tag.tagID || tag.name} onDelete={() => onRemoveTag(tag)} />
          ))}
        </TagsWrapper>
      )}
    </Container>
  );
};

TagsTab.propTypes = {
  allTags: array.isRequired,
  selectedTags: array.isRequired,
  onAddTag: func.isRequired,
  onRemoveTag: func.isRequired,
};

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const TagsWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

export default TagsTab;
