import { combineReducers } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import { i18nReducer } from 'react-redux-i18n';

import user, { initialUserState } from './user/reducer';
import respondents from './respondents/reducer';
import groups from './groups/reducer';
import invites from './invites/reducer';
import reports from './reports/reducer';
import projects from './projects/reducer';
import sessions from './sessions/reducer';
import raters from './raters/reducer';
import routes from './routes/reducer';
import templates from './settings/templates/reducer';
import responseScales from './settings/response-scales/reducer';
import competencyGroups from './settings/competency-groups/reducer';
import competencyGroupsDetails from './settings/competency-groups-details/reducer';
import reviewCompetencies from './settings/review-competencies/reducer';
import competencies from './settings/competencies/reducer';
import notifications from './notifications/reducer';
import settingsNorms from './settings/norms/reducer';
import idealProfiles from './settings/ideal-profiles/reducer';
import welcomeTexts from './settings/welcome-texts/reducer';
import norms from './norms/reducer';

export const { routerReducer, createReduxHistory, routerMiddleware } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const rootReducer = combineReducers({
  user,
  respondents,
  groups,
  invites,
  reports,
  projects,
  sessions,
  raters,
  routes,
  templates,
  responseScales,
  competencyGroups,
  competencyGroupsDetails,
  reviewCompetencies,
  competencies,
  norms,
  idealProfiles,
  settingsNorms,
  notifications,
  welcomeTexts,
  router: routerReducer,
  i18n: i18nReducer,
});

export default (state, action) =>
  action.type === 'RESET_STORE'
    ? rootReducer({ router: state.router, user: { ...initialUserState }, i18n: state.i18n }, action)
    : rootReducer(state, action);
