import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { func } from 'prop-types';
import { format } from 'date-fns';
import styled from 'styled-components';

import { useSelector, useDispatch } from 'react-redux';
import SummarySubHeader from '../../AssessmentProject/components/SumarySubHeader';
import ProjectModalFooter from '../ProjectModalFooter';
import { ReactComponent as ArrowRight } from '../../../../assets/icons/arrow-right-thin.svg';
import CustomButton from '../../../reusable/Buttons/Button';
import Checkbox from '../../../reusable/FormComponents/Checkbox';

import ReportsSetSummaryBlock from '../../AssessmentProject/components/ReportsSetSummaryBlock';
import { createAssessmentProject, resetCreateAssessmentProjectData } from '../../../../store/projects/actions';
import { resolveAssessmentName } from '../../../../utils/assessmentsNames';
import { calculateCredits } from '../../../../store/user/selectors';

const SummaryStep = ({ setStep, goPrev, onClose, onReset }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const { info, assessment, invites, reports } = useSelector((state) => state.projects.createAssessmentProject);

  const [isConfirmed, setConfirmState] = useState(false);

  const costPerRespondent = reports.reports
    .filter((item) => item.isChecked)
    .reduce((acc, item) => acc + item.creditsCost, 0);

  const reportsToShow = reports.reports
    .filter((set) => set.isChecked)
    .map((set) => ({ ...set, reports: set.reports.filter((report) => report.isChecked) }));

  const onCreateProject = () => {
    const data = {
      name: info.projectName,
      description: info.projectDescription,
      langID: assessment.language.code,
      ...(assessment.assessment.batteryID
        ? { batteryID: assessment.assessment.batteryID }
        : { testID: assessment.assessment.testID }),
      emailTID: invites.template.value,
      welcomeTextTID: invites.welcomeText.value,
      inviteExpiredAfter: invites.expireAfter,
      inviteExpiredAfterType: 2,
      sendInviteType: invites.sendingType.backendReference,
      ...(invites.sendingType.value === 'schedule' && { sendInviteDate: invites.invitationDate }),
      remindInviteType: invites.reminderType.backendReference,
      sendDirectFeedbackReport: invites.isDirectReport,
      reportLangID: reports.language.code,
      reports: reports.readyToBeSentReports,
    };

    dispatch(
      createAssessmentProject(data, (err) => {
        if (err) return;
        onClose();
        onReset(true);
        dispatch(resetCreateAssessmentProjectData());
      }),
    );
  };

  const areOptionsVisible = Boolean(
    Object.entries(reports.norms).length && Object.entries(reports.idealProfiles).length,
  );
  return (
    <Container>
      <Header>
        <Title>{I18n.t('Summary')}</Title>
      </Header>
      <Content>
        <StepInfoBlock>
          <SummarySubHeader title={I18n.t('General info')} editHandler={() => setStep(1)} />
          <Row withMarginBottom>
            <ValueBox width="50%">
              <ValueTitle>{I18n.t('Project name')}</ValueTitle>
              <Value>{info.projectName}</Value>
            </ValueBox>
            <div />
          </Row>
          <ValueBox>
            <ValueTitle>{I18n.t('Description')}</ValueTitle>
            <Value>{info.projectDescription}</Value>
          </ValueBox>
        </StepInfoBlock>
        <StepInfoBlock>
          <SummarySubHeader title={I18n.t('Assessment')} editHandler={() => setStep(2)} />
          <Row>
            <ValueBox width="25%">
              <ValueTitle>{I18n.t('Language')}</ValueTitle>
              <Value>{assessment.language?.label}</Value>
            </ValueBox>
            <ValueBox width="25%">
              <ValueTitle>{I18n.t('Category')}</ValueTitle>
              <Value>{assessment.category?.label}</Value>
            </ValueBox>
            <ValueBox width="50%">
              <ValueTitle>{I18n.t(assessment.assessment?.batteryID ? 'Battery' : 'Test')}</ValueTitle>
              <Value>{resolveAssessmentName(assessment.assessment)}</Value>
            </ValueBox>
            <div />
          </Row>
        </StepInfoBlock>

        <StepInfoBlock>
          <SummarySubHeader title={I18n.t('Invites')} editHandler={() => setStep(3)} />
          <Row withMarginBottom>
            <ValueBox width="50%">
              <ValueTitle>{I18n.t('Invite template')}</ValueTitle>
              <Value>{invites.template?.label}</Value>
            </ValueBox>
            <ValueBox width="50%">
              <ValueTitle>{I18n.t('Welcome text')}</ValueTitle>
              <Value>{invites.welcomeText?.label}</Value>
            </ValueBox>
            <div />
          </Row>
          <Row>
            <ValueBox width="25%">
              <ValueTitle>{I18n.t('Expire after')}</ValueTitle>
              <Value>{`${invites.expireAfter} ${I18n.t('days')}`}</Value>
            </ValueBox>
            <ValueBox width="25%">
              <ValueTitle>{I18n.t('Reminder')}</ValueTitle>
              <Value>{invites.reminderType?.label}</Value>
            </ValueBox>
            <ValueBox width="25%">
              <ValueTitle>{I18n.t('Send invites')}</ValueTitle>
              <Value>{invites.sendingType?.label}</Value>
            </ValueBox>
            {invites.sendingType?.value === 'schedule' && (
              <ValueBox width="25%">
                <ValueTitle>{I18n.t('Send on')}</ValueTitle>
                <Value>{format(invites.invitationDate, 'dd/MM/y HH:mm')}</Value>
              </ValueBox>
            )}

            <div />
          </Row>
        </StepInfoBlock>

        <StepInfoBlock>
          <SummarySubHeader title={I18n.t('Reports')} editHandler={() => setStep(4)} />
          <Row withMarginBottom>
            <ValueBox>
              <ValueTitle>{I18n.t('Language')}</ValueTitle>
              <Value>{reports.language?.label}</Value>
            </ValueBox>
            <div />
          </Row>
          <ReportsRow>
            <SetsWrapper>
              {reportsToShow.map((set) => {
                return (
                  <ReportsSetSummaryBlock
                    key={set.reportSetID}
                    set={set}
                    idealProfiles={reports.idealProfiles}
                    norms={reports.norms}
                  />
                );
              })}
            </SetsWrapper>
            {areOptionsVisible && (
              <OptionsContainer>
                <OptionsHeader>{I18n.t('Options')}</OptionsHeader>
                {Object.entries(reports.norms).map(([key, value], index, arr) => {
                  return (
                    <ValueBox marginBottom={index === arr.length - 1 ? 0 : '1.6rem'}>
                      <ValueTitle>{`${key} ${I18n.t('norm')}`}</ValueTitle>
                      <Value>{value.name}</Value>
                    </ValueBox>
                  );
                })}
                {Boolean(Object.entries(reports.idealProfiles).length) && <Divider />}
                {Object.entries(reports.idealProfiles).map(([key, value], index, arr) => {
                  return (
                    <ValueBox marginBottom={index === arr.length - 1 ? 0 : '1.6rem'}>
                      <ValueTitle>{`${key} ${I18n.t('ideal profile')}`}</ValueTitle>
                      <Value>{value.name}</Value>
                    </ValueBox>
                  );
                })}
              </OptionsContainer>
            )}
          </ReportsRow>
        </StepInfoBlock>

        <StepInfoBlock>
          <SummarySubHeader title={I18n.t('Total cost')} />
          <Row>
            <ValueBox width="50%">
              <ValueTitle>{I18n.t('Cost per respondent')}</ValueTitle>
              <Value>{costPerRespondent}</Value>
            </ValueBox>
            <ValueBox width="50%">
              <ValueTitle>{I18n.t('Current credits balance')}</ValueTitle>
              <Value>{calculateCredits(user?.credits, user?.totalFrozenCredits)}</Value>
            </ValueBox>
            <div />
          </Row>
        </StepInfoBlock>

        <ConfirmBlock>
          <Checkbox isChecked={isConfirmed} clickHandler={() => setConfirmState((prev) => !prev)} />
          <span>{I18n.t('I confirm that I have read this summary and I want to proceed')}</span>
        </ConfirmBlock>
      </Content>
      <Footer>
        <Button handler={goPrev} variant="secondary">
          <ArrowLeft />
          {I18n.t('Back')}
        </Button>
        <CreateButton handler={onCreateProject} disabled={!isConfirmed}>
          {I18n.t('Create project')}
        </CreateButton>
      </Footer>
    </Container>
  );
};

SummaryStep.propTypes = {
  goPrev: func.isRequired,
  setStep: func.isRequired,
  onClose: func.isRequired,
  onReset: func.isRequired,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Header = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 3rem;
  padding: 0 2.4rem 1.6rem 2.4rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2.4rem 2rem 2.4rem;
  flex-grow: 1;
  overflow: auto;
  // magical number hah; footer doesn't "jump" visually with exactly this height
  max-height: 32.8rem;
`;

const StepInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3.2rem;
`;

const Title = styled.h3`
  color: ${(props) => props.theme.colors.mediumBlue};
  font-size: ${(props) => props.theme.fontSizes.normal};
  font-weight: 600;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${(props) => props.withMarginBottom && '2.4rem'};
`;
const ReportsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const SetsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
`;

const OptionsContainer = styled.div`
  display: flex;
  width: 48%;
  flex-direction: column;
  padding: 1.2rem 1.6rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);
`;

const OptionsHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  font-size: 14px;
  font-weight: 600;
  ${(props) => props.theme.colors.darkBlue2};
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.12);
  margin: 1.6rem 0;
`;

const ValueBox = styled.div`
  width: ${(props) => props.width || 'auto'};
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.marginBottom || 0};
`;

const ValueTitle = styled.p`
  font-size: 10px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.mediumBlue};
  text-transform: uppercase;
  margin-bottom: 0.8rem;
`;

const Value = styled.p`
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;
  color: rgba(0, 0, 0, 0, 88);
`;

const ConfirmBlock = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 1rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.darkBlue2};
    font-size: 14px;
    cursor: default;
  }
`;

const Footer = styled(ProjectModalFooter)`
  justify-content: space-between;
`;

const ArrowLeft = styled(ArrowRight)`
  transform: rotate(180deg);
  path {
    fill: ${(props) => props.theme.colors.lightBlue};
  }
`;

const Button = styled(CustomButton)`
  text-transform: uppercase;
  width: 12rem;
  justify-content: space-evenly;
`;

const CreateButton = styled(Button)`
  width: 18rem;
`;

export default SummaryStep;
