const columns = [
  {
    Header: 'Report name',
    accessor: 'name',
    name: 'name',
    disableSortBy: true,
  },
  {
    Header: 'Usage count',
    accessor: 'usage',
    name: 'usage',
    disableSortBy: true,
  },
];

export default columns;
