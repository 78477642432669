import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';

import { I18n } from 'react-redux-i18n';
import PageWrapper from '../../PageWrapper';
import SimplifiedTable from '../../reusable/Tables/SimplifiedTable';
import TableTabs from '../../reusable/TableTabs';
import BulkActionsSidebar from '../../BulkActionsSidebar';
import ConfirmationModal from '../../reusable/ConfirmationModal';

import welcomeTextsColumns from '../../../table/columns/templates';
import welcomeTextsTableActions from '../../../table/tableActions/welcomeTexts';
import welcomeTextsBulkActions from '../../../table/bulkActions/welcomeTexts';

import useBulkSidebarState from '../../../hooks/useBulkSidebarState';
import * as actions from '../../../table/actionsList';
import useModals from '../../../hooks/useModals';

import templatesTabs from './templatesTabs';
import { fetchWelcomeTexts, deleteWelcomeTexts } from '../../../store/settings/welcome-texts/actions';

const WelcomeTexts = () => {
  const welcomeTexts = useSelector((state) => state.welcomeTexts.welcomeTexts);
  const dispatch = useDispatch();

  const [isLoading, setLoadingState] = useState(true);

  useEffect(() => {
    dispatch(
      fetchWelcomeTexts(() => {
        setLoadingState(false);
      }),
    );
  }, []);

  const [selectedWelcomeTexts, setSelectedWelcomeTexts] = useState([]);

  const modals = [actions.CREATE_WELCOME_TEXT, actions.DELETE_WELCOME_TEXTS];
  const { modalsState, openModal, closeModal } = useModals(modals);

  const goBackToSettings = () => dispatch(push('/settings'));

  const onWelcomeTextsDelete = () => {
    dispatch(
      deleteWelcomeTexts(
        selectedWelcomeTexts.map((item) => item.templateID),
        () => {
          closeModal(actions.DELETE_WELCOME_TEXTS);
        },
      ),
    );
  };

  const onTabClick = (tab) => {
    if (tab.name === 'templates') dispatch(push('/settings/templates'));
  };

  // Controlling bulk actions sidebar state
  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedWelcomeTexts });

  const navigateToWelcomeText = (welcomeText) => dispatch(push(`/settings/welcome-texts/${welcomeText.templateID}`));

  const bulkActionsHandlers = {
    [actions.EDIT_WELCOME_TEXT]: () => navigateToWelcomeText(selectedWelcomeTexts[0]),
    [actions.DELETE_WELCOME_TEXTS]: () => openModal(actions.DELETE_WELCOME_TEXTS),
  };

  const bulkActions = useMemo(() => {
    return welcomeTextsBulkActions.map((item) => ({ ...item, handler: bulkActionsHandlers[item.name] }));
  }, [selectedWelcomeTexts]);

  const tableActions = useMemo(() => {
    return welcomeTextsTableActions.map((item) => ({
      ...item,
      handler: () => dispatch(push('/settings/welcome-texts/new')),
    }));
  }, []);

  return (
    <PageWrapper backButtonHandler={goBackToSettings} buttons={tableActions} title={I18n.t('Welcome texts')}>
      <BulkActionsSidebar onClose={closeSidebar} isOpen={isSidebarOpen} actions={bulkActions} />
      <TableTabs tabs={templatesTabs} activeTab={templatesTabs[1]} clickHandler={onTabClick} />
      <Table
        columns={welcomeTextsColumns}
        data={welcomeTexts}
        onSelectChange={setSelectedWelcomeTexts}
        entityName="welcome text"
        isLoading={isLoading}
        onRowClick={(welcomeText) => navigateToWelcomeText(welcomeText)}
        interceptCheckboxClick
        bottomOffset={75}
      />
      <ConfirmationModal
        isVisible={modalsState[actions.DELETE_WELCOME_TEXTS]}
        title={I18n.t('Delete welcome texts')}
        description={`${I18n.t('Are you sure you want to delete')} ${
          selectedWelcomeTexts.length > 1 ? I18n.t('selected welcome texts?') : I18n.t('this welcome text?')
        }`}
        onClose={() => closeModal(actions.DELETE_WELCOME_TEXTS)}
        onConfirm={onWelcomeTextsDelete}
        caution
      />
    </PageWrapper>
  );
};

WelcomeTexts.propTypes = {};

const Table = styled(SimplifiedTable)`
  td:first-child,
  th:first-child {
    width: 3%;
  }
`;

export default WelcomeTexts;
