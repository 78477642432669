import { createSelector } from 'reselect';
import languagesMap from '../../constants/languages';

const getSessionInviteLanguages = (state) => state.sessions.languages;

// eslint-disable-next-line import/prefer-default-export
export const selectSessionInviteLanguages = createSelector([getSessionInviteLanguages], (languages) => {
  return languages
    ? languages.map((item) => ({
        code: item.langID,
        value: item.langID,
        label: languagesMap[item.langID] || item.english || item.langID,
      }))
    : [];
});
