import React from 'react';
import { number } from 'prop-types';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';

const ReportsCreditsCost = ({ creditsCost, creditsAvailable }) => {
  return (
    <CostContainer>
      <Block>
        <Text>{I18n.t('Total credits')}:</Text>
        <CreditsAmount type="cost" error={creditsCost > creditsAvailable}>
          {creditsCost}
        </CreditsAmount>
      </Block>
      <Block>
        <Text>{I18n.t('Credits balance:')}</Text>
        <CreditsAmount type="available">{creditsAvailable}</CreditsAmount>
      </Block>
    </CostContainer>
  );
};

ReportsCreditsCost.propTypes = {
  creditsCost: number.isRequired,
  creditsAvailable: number.isRequired,
};

const CostContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Block = styled.div`
  margin-right: 1.6rem;
  display: flex;
  align-items: center;
`;

const Text = styled.span`
  font-weight: 500;
  font-size: ${(props) => props.theme.fontSizes.small};
  color: ${(props) => props.theme.colors.darkBlue2};
  margin-right: 0.8rem;
`;

const CreditsAmount = styled.div`
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  background-color: ${(props) => {
    const costColors = props.error ? props.theme.colors.error : props.theme.colors.mediumBlue;
    return props.type === 'cost' ? costColors : props.theme.colors.darkBlue2;
  }};

  color: ${(props) => props.theme.colors.white};
  font-weight: bold;
  font-size: 14px;
`;

export default ReportsCreditsCost;
