import { I18n } from 'react-redux-i18n';
import { END_GROUP_SESSION } from '../actionsList';

const managedGroupTableActions = [
  {
    name: END_GROUP_SESSION,
    label: I18n.t('End session'),
  },
];

export default managedGroupTableActions;
