import React, { useState } from 'react';
import { number, arrayOf, shape, string, oneOfType, object } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import Tag from '../reusable/Label';
import AddTagModal from './AddTagModal';
import CustomButton from '../reusable/Buttons/Button';

import { deleteTags, addTagsToRespondent } from '../../store/respondents/actions';

const TagsManagement = ({ className, tags, respondent }) => {
  const allTags = useSelector((state) => state.user.tags);
  const dispatch = useDispatch();

  const [isAddTagsModalVisible, setAddTagsModalState] = useState(false);
  const openAddTagsModal = () => setAddTagsModalState(true);
  const closeAddTagsModal = () => setAddTagsModalState(false);

  // all tags without those already added
  const allTagsOptions = allTags.filter((item) => !tags.find((tag) => tag.tagID === item.tagID));

  const onTagsSave = (tags) => {
    dispatch(addTagsToRespondent(respondent.respondentID, tags, () => closeAddTagsModal()));
  };

  const deleteTag = (tag) => {
    dispatch(deleteTags(respondent.respondentID, [tag]));
  };

  return (
    <>
      {isAddTagsModalVisible && (
        <AddTagModal allTags={allTagsOptions} onClose={closeAddTagsModal} onSave={onTagsSave} />
      )}

      <TagsManagementContainer className={className}>
        <TagsManagementTitle>{I18n.t('Tags')}</TagsManagementTitle>
        <TagsWrapper>
          {tags.map((tag) => (
            <Tag value={tag.name} key={tag.tagID} onDelete={() => deleteTag(tag)} />
          ))}
        </TagsWrapper>
        <AddButton variant="secondary" handler={openAddTagsModal}>
          {I18n.t('+ Add tag')}
        </AddButton>
      </TagsManagementContainer>
    </>
  );
};

TagsManagement.propTypes = {
  className: string,
  tags: arrayOf(
    shape({
      name: string.isRequired,
      tagID: oneOfType([string, number]).isRequired,
    }),
  ).isRequired,
  respondent: object.isRequired,
  // onTagDelete: func.isRequired,
};

TagsManagement.defaultProps = {
  className: '',
};

const TagsManagementContainer = styled.div`
  padding: 0 2.4rem 2.4rem 2.4rem;
  display: flex;
  flex-direction: column;
`;

const TagsManagementTitle = styled.h3`
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSizes.normal};
  color: ${(props) => props.theme.colors.darkBlue2};
  margin-bottom: 2.5rem;
`;

const TagsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

const AddButton = styled(CustomButton)`
  width: auto;
  min-width: 10rem;
  padding: 0.8rem;
  display: flex;
  align-self: flex-start;
`;

export default TagsManagement;
