import React from 'react';
import { func, bool, array } from 'prop-types';
import styled, { css } from 'styled-components';
import ReactTooltip from 'react-tooltip';

import { Translate } from 'react-redux-i18n';
import { ReactComponent as CloseIcon } from '../assets/icons/close.svg';

const styleIcon = (Icon) => styled(Icon)`
  min-width: 4rem;
  min-height: 4rem;
  width: 4rem;
  height: 4rem;
`;

const BulkActionsSidebar = ({ isOpen, onClose, actions }) => {
  return (
    <BulkActionsContainer isOpen={isOpen}>
      <Header>
        <Title>
          <Translate value="Actions" />
        </Title>
        <Close onClick={onClose} />
      </Header>
      <ActionsWrapper>
        {actions.map((action) => {
          const { Icon, label, name, handler, isDisabled, hint } = action;
          const StyledIcon = styleIcon(Icon);
          const tooltipProps = {
            'data-tip': true,
            'data-for': name,
          };
          return (
            <div key={`${name}${label}`}>
              <Action
                onClick={() => !isDisabled && handler()}
                disabled={isDisabled}
                {...(action.hint && isDisabled ? tooltipProps : {})}
              >
                <StyledIcon />
                <Translate value={label} />
              </Action>
              {action.hint && isDisabled && <StyledTooltip id={name}>{hint}</StyledTooltip>}
            </div>
          );
        })}
      </ActionsWrapper>
    </BulkActionsContainer>
  );
};

BulkActionsSidebar.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  actions: array.isRequired,
};

const BulkActionsContainer = styled.div`
  position: fixed;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  top: 0;
  left: ${(props) => (props.isOpen ? 0 : '-20rem')};
  z-index: 10;
  height: 100vh;
  width: 20rem;
  transition: left 0.4s ease-in-out;
  padding: 2.5rem 1rem;
  background-color: ${(props) => props.theme.colors.darkBlue2};
`;

const Close = styled(CloseIcon)`
  top: 2rem;
  right: 2rem;
  cursor: pointer;
  transition: all 0.2s;
  :hover {
    transform: scale(1.2);
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
  padding: 0 1rem;
`;
const Title = styled.h6`
  font-size: ${(props) => props.theme.fontSizes.normal};
  font-weight: 600;
  color: ${(props) => props.theme.colors.white};
`;
const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledTooltip = styled(ReactTooltip)`
  width: 40rem;
  opacity: 1 !important;
`;

const Action = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem 0;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  svg,
  span {
    transition: all 0.2s;
  }

  ${(props) =>
    !props.disabled &&
    css`
      :active {
        svg,
        span {
          transform: scale(0.95);
        }
      }

      :hover {
        cursor: pointer;
        span {
          color: ${(props) => props.theme.colors.lightBlue2};
        }
        svg,
        path {
          fill: ${(props) => props.theme.colors.lightBlue2};
          stroke: ${(props) => props.theme.colors.lightBlue2};
        }
      }
    `}

  & > span {
    color: ${(props) => props.theme.colors.white};
    font-size: ${(props) => props.theme.fontSizes.small};
    font-weight: 500;
    margin-left: 1rem;
  }
`;

export default BulkActionsSidebar;
