import React from 'react';
import AnimateHeight from 'react-animate-height';

const AnimateHeightWrapper = ({ children, height, className, onAnimationEnd, onAnimationStart }) => {
  return (
    <AnimateHeight
      duration={400}
      height={height}
      className={className}
      onAnimationEnd={onAnimationEnd}
      onAnimationStart={onAnimationStart}
    >
      {children}
    </AnimateHeight>
  );
};
export default AnimateHeightWrapper;
