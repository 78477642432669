import React, { useCallback, useReducer, useEffect } from 'react';
import { func, bool, array, string, number, oneOfType } from 'prop-types';
import { addMonths, subMinutes } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { I18n } from 'react-redux-i18n';
import Modal from '../../reusable/NewModal';
import CustomButton from '../../reusable/Buttons/Button';

import SessionInvitationStep from './SessionInvitationStep';

import { selectTemplatesAsOptions } from '../../../store/settings/templates/selectors';
import { selectWelcomeTextsOptions } from '../../../store/settings/welcome-texts/selectors';
import { selectSessionInviteLanguages } from '../../../store/sessions/selectors';
import { fetchSessionInviteLanguages, inviteSessions, inviteProject } from '../../../store/sessions/actions';
import { inviteRaters } from '../../../store/raters/actions';

const initState = (languages, welcomeTexts, templates) => {
  const english = languages.find((item) => item.langID === 'EN');
  return {
    language: languages.length ? english || languages[0] : null,
    expiryDate: addMonths(new Date(), 1),
    welcomeTextSelf: welcomeTexts[0],
    templateSelf: templates[0],
    welcomeTextRaters: welcomeTexts[0],
    templateRaters: templates[0],
    invitationDate: null,
  };
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'changeValue':
      return { ...state, [payload.name]: payload.value };
    case 'reset':
      return { ...initState(payload.languages, payload.welcomeTexts, payload.templates) };
    default:
      throw new Error();
  }
};

const descriptionsMap = {
  0: 'Send invite to raters who have not yet completed',
  1: 'Send invite to raters who have not yet been invited',
  2: 'Send invite to raters who have already been invited but haven&apos;t yet completed',
};

const SessionInvite = ({ onClose, items, itemType, commandType, successCallback, sessionId, projectScopeEnabled }) => {
  const templates = useSelector(selectTemplatesAsOptions);
  const welcomeTexts = useSelector(selectWelcomeTextsOptions);
  const languages = useSelector(selectSessionInviteLanguages);
  const [values, reducerDispatch] = useReducer(reducer, initState(languages, welcomeTexts, templates));
  const activeProjectInView = useSelector((state) => state.projects.activeProjectInView);
  const activeSessionInView = useSelector((state) => state.sessions.activeSessionInView);

  const dispatch = useDispatch();
  const changeValue = (name, value) => {
    reducerDispatch({ type: 'changeValue', payload: { name, value } });
  };

  // default language english or, if it's missing, first one on the list
  useEffect(() => {
    if (languages.length) changeValue('language', languages.find((item) => item.langID === 'EN') || languages[0]);
  }, [languages.length]);

  useEffect(() => {
    if (activeProjectInView) dispatch(fetchSessionInviteLanguages(activeProjectInView.competencyGroupID || -1));
  }, [activeProjectInView]);

  const closeModal = () => {
    reducerDispatch({ type: 'reset', payload: { languages, templates, welcomeTexts } });
    onClose();
  };

  const onSessionsInvite = () => {
    const data = {
      commandType,
      emailSelfTID: values.templateSelf.value,
      welcomeTextSelfTID: values.welcomeTextSelf.value,
      raterType: 0,
      iDs: sessionId ? [sessionId] : items.map((item) => item.sessionId),
      langID: values.language.code,
      emailTID: values.templateRaters.value,
      welcomeTextTID: values.welcomeTextRaters.value,
      expiryDate: values.expiryDate,
      sendInviteDate: values.invitationDate || subMinutes(new Date(), 1),
    };
    if (projectScopeEnabled) delete data.iDs;
    const method = projectScopeEnabled ? inviteProject : inviteSessions;
    dispatch(
      method(activeProjectInView.projectId, data, (err) => {
        if (!err && successCallback) successCallback();
        closeModal();
      }),
    );
  };

  const onRatersInvite = () => {
    const data = {
      commandType,
      iDs: items.map((item) => ({ respondentID: item.respondentID, raterID: item.raterID })),
      langID: values.language.code,
      emailTID: values.templateRaters.value,
      welcomeTextTID: values.welcomeTextRaters.value,
      expiryDate: values.expiryDate,
      sendInviteDate: values.invitationDate || subMinutes(new Date(), 1),
    };
    dispatch(
      inviteRaters(activeProjectInView.projectId, activeSessionInView.sessionId, data, (err) => {
        if (!err) {
          if (successCallback) successCallback();
          closeModal();
        }
      }),
    );
  };

  const isNextButtonDisabled =
    !values.language || !values.expiryDate || !values.welcomeTextRaters || !values.templateRaters;

  const onNextButtonClick = useCallback(() => {
    const func = itemType === 'session' ? onSessionsInvite : onRatersInvite;
    func();
  }, [values, activeProjectInView, itemType, projectScopeEnabled, commandType, items]);

  const description = I18n.t(descriptionsMap[commandType]);

  return (
    <StyledModal isVisible onClose={closeModal} title={I18n.t('Send invite to raters')}>
      <Description>{description}</Description>
      <Content>
        <SessionInvitationStep
          welcomeTexts={welcomeTexts}
          templates={templates}
          languages={languages}
          onValueChange={changeValue}
          values={values}
          itemType={itemType}
        />
      </Content>
      <Footer>
        <Button handler={onNextButtonClick} disabled={isNextButtonDisabled}>
          {I18n.t('Send')}
        </Button>
      </Footer>
    </StyledModal>
  );
};

SessionInvite.propTypes = {
  onClose: func.isRequired,
  items: array.isRequired,
  itemType: string.isRequired,
  commandType: number.isRequired,
  successCallback: func,
  sessionId: oneOfType([string, number]),
  projectScopeEnabled: bool,
};

SessionInvite.defaultProps = {
  successCallback: undefined,
  sessionId: '',
  projectScopeEnabled: false,
};

const StyledModal = styled(Modal)`
  width: 75rem;
  height: 75rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem;
`;

const Description = styled.p`
  margin: 0 0 2rem 0;
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;
  color: ${(props) => props.theme.colors.darkBlue2};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
`;

const Button = styled(CustomButton)`
  text-transform: uppercase;
  width: 14rem;
`;

export default SessionInvite;
