import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import { CheckboxWrapper, PanelTitle } from './styles';
import AnimateHeightWrapper from '../AnimateHeight';
import addOrRemove from '../../../utils/addOrRemove';
import Checkbox from '../FormComponents/Checkbox';
import { ReactComponent as ArrowUp } from '../../../assets/icons/blue-arrow-up-big.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/blue-arrow-down-big.svg';
import storageService from '../../../services/storageService';

export const TitleIcon = ({ opened }) => {
  return <TitleIconButton>{opened ? <ArrowUp /> : <ArrowDown />}</TitleIconButton>;
};

const CompetencyGroupsAccordion = ({ list, selected, label, onSelectedChanged }) => {
  const [accordionData, setAccordionData] = useState([]);
  const [openedPanels, setOpenedPanels] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [expand, setExpand] = useState(false);

  const language = storageService.getItem('psLang');

  useEffect(() => {
    if (expand) {
      setOpenedPanels(list.map((item) => item.competencyID));
    } else {
      setOpenedPanels([]);
    }
  }, [expand]);

  useEffect(() => {
    const mapped = list.map((group) => {
      const categories = group.categories.map((item) => {
        const translated = item?.languages?.find((entry) => entry?.langID?.toLowerCase() === language);
        return {
          ...item,
          ...(translated || {}),
          isActive: Boolean(selected[item.competencyID]),
        };
      });

      const translated = group?.languages?.find((entry) => entry?.langID?.toLowerCase() === language);

      return {
        ...group,
        ...(translated || {}),
        categories,
        isActive: categories.length ? categories.every((item) => item.isActive) : false,
        isOpenByDefault: categories.length ? categories.some((item) => item.isActive) : false,
      };
    });
    setOpenedPanels(mapped.filter((group) => group.isOpenByDefault).map((group) => group.competencyID));
    setAccordionData(mapped);
  }, [list]);

  useEffect(() => {
    let result = [];

    for (let i = 0; i < accordionData.length; i += 1) {
      const categories = accordionData[i].categories.filter((category) => category.isActive);

      result = [...result, ...categories];
    }

    onSelectedChanged(result);
  }, [accordionData]);

  const onPanelClick = (id) => {
    setOpenedPanels(addOrRemove(openedPanels, id));
  };

  const checkForAllSelected = (data) => {
    const isSelected = data.every((panel) => {
      return panel.isActive && panel.categories.every((category) => category.isActive);
    });

    setSelectAll(isSelected);
  };

  const onCategoryHandler = (e, panel) => {
    e.stopPropagation();

    const mapped = accordionData.map((accordion) => {
      if (accordion.competencyID !== panel.competencyID) return accordion;

      return {
        ...accordion,
        isActive: !panel.isActive,
        categories: accordion.categories.map((category) => ({
          ...category,
          isActive: !panel.isActive,
        })),
      };
    });

    setAccordionData(mapped);
    checkForAllSelected(mapped);
  };

  const onItemHandler = (e, categoryID) => {
    e.stopPropagation();

    const mapped = accordionData.map((accordion) => {
      const mappedCategories = accordion.categories.map((category) => {
        if (categoryID !== category.competencyID) return category;

        return {
          ...category,
          isActive: !category.isActive,
        };
      });

      return {
        ...accordion,
        categories: mappedCategories,
        isActive: mappedCategories.length ? mappedCategories.every((item) => item.isActive) : false,
      };
    });

    setAccordionData(mapped);
    checkForAllSelected(mapped);
  };

  const onSelectAll = () => {
    const mapped = accordionData.map((panel) => {
      return {
        ...panel,
        isActive: !selectAll,
        categories: panel.categories.map((category) => ({ ...category, isActive: !selectAll })),
      };
    });

    setAccordionData(mapped);
    setSelectAll(!selectAll);
  };

  return (
    <AccordionWrapper>
      <SelectAllWrapper>
        <CheckboxWithLabel>
          <Checkbox clickHandler={() => onSelectAll()} isChecked={selectAll} />
          <span>{label}</span>
        </CheckboxWithLabel>
        <ExpandCollapseAll onClick={() => setExpand(!expand)}>
          {expand ? I18n.t('Collapse all') : I18n.t('Expand all')}
          <IconWrapper isExpanded={expand}>
            <BlueArrowDown />
          </IconWrapper>
        </ExpandCollapseAll>
      </SelectAllWrapper>
      {accordionData.map((panel) => (
        <Panel leftBorderColor={panel.colors ? panel.colors.color : null} key={panel.competencyID}>
          <PanelTitle root selectable onClick={() => onPanelClick(panel.competencyID)}>
            <CheckboxWrapper>
              <Checkbox clickHandler={(e) => onCategoryHandler(e, panel)} isChecked={panel.isActive} />
            </CheckboxWrapper>
            <TitleIconWrapper>
              <TitleIcon opened={openedPanels.includes(panel.competencyID)} />
            </TitleIconWrapper>
            <AccordionTitleBody>
              <AccordionTitleWrapper>
                <AccordionTitle>{panel.name}</AccordionTitle>
                {!panel.competencyType && <InbuiltBadge>{I18n.t('INBUILT')}</InbuiltBadge>}
              </AccordionTitleWrapper>
              <PanelDescription>{panel.description}</PanelDescription>
            </AccordionTitleBody>
          </PanelTitle>
          {!!panel.categories.length &&
            panel.categories.map((category) => (
              <PanelBody height={openedPanels.includes(panel.competencyID) ? 'auto' : 0} key={category.competencyID}>
                <PanelTitle selectable pLeft={60} onClick={() => onPanelClick(category.competencyID)}>
                  <CheckboxWrapper yOffset={30}>
                    <Checkbox
                      clickHandler={(e) => onItemHandler(e, category.competencyID)}
                      isChecked={category.isActive}
                    />
                  </CheckboxWrapper>
                  <span>{category.name}</span>
                </PanelTitle>
              </PanelBody>
            ))}
        </Panel>
      ))}
    </AccordionWrapper>
  );
};

const PanelBody = styled(AnimateHeightWrapper)`
  height: 0;
  padding-left: 0;

  ${(props) => props.opened && `display: block;`}
  ${(props) => props.opened && `border-top: 1px solid ${props.theme.colors.grey6};`}
`;

const Panel = styled.div`
  border: 1px solid #e0e0e0;
  background: ${(props) => props.theme.colors.grey1};
  border-radius: 6px;
  ${(props) => (props.leftBorderColor ? `border-left: 6px solid ${props.leftBorderColor}` : null)};
`;

const AccordionWrapper = styled.div`
  overflow: auto;

  & > ${Panel} {
    margin-bottom: 20px;
  }
`;

const TitleIconWrapper = styled.span`
  position: absolute;
  top: 8px;
  right: 8px;
`;

const SelectAllWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  span {
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    margin-left: 8px;
    line-height: 19px;
  }
`;

const CheckboxWithLabel = styled.div`
  display: flex;
  align-items: center;
`;

const PanelDescription = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => props.theme.colors.dark};
  margin-top: 8px;
`;

const TitleIconButton = styled.span`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};

  &:hover {
    background-color: ${(props) => props.theme.colors.lightBlue1};
  }
`;

const InbuiltBadge = styled.span`
  padding: 0 12px;
  height: 20px;
  background-color: ${(props) => props.theme.colors.grey3};
  text-transform: uppercase;
  font-size: 10px;
  line-height: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  color: ${(props) => props.theme.colors.white};
  font-weight: 600;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-bottom-width: 10px;
    border-top-width: 10px;
    border-left: 10px solid ${(props) => props.theme.colors.grey1};
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-bottom-width: 10px;
    border-top-width: 10px;
    border-right: 10px solid ${(props) => props.theme.colors.grey1};
  }
`;

const AccordionTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AccordionTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
`;

const AccordionTitleBody = styled.div`
  flex-grow: 1;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;

  svg {
    ${(props) => (props.isExpanded ? 'transform: rotate(180deg)' : null)};
  }
`;

const ExpandCollapseAll = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  line-height: 44px;
  text-decoration: underline;
  cursor: pointer;
  color: ${(props) => props.theme.colors.lightBlue};
`;

const BlueArrowDown = styled(ArrowDown)`
  path {
    fill: ${(props) => props.theme.colors.lightBlue};
  }
`;

export default React.memo(CompetencyGroupsAccordion);
