import handleActions from '../../immerHandleActions';
import * as actions from './actions';

const initialState = {
  idealProfiles: [],
  filteringOptions: {},
  availableTests: [],
  availableGroups: [],
  activeIdealProfile: null,
};

const idealProfilesReducer = handleActions(
  {
    [actions.fetchIdealProfilesSuccess]: (draft, { payload: { idealProfiles } }) => {
      draft.idealProfiles = idealProfiles;
    },
    [actions.fetchIdealProfileTestsSuccess]: (draft, { payload: { tests } }) => {
      draft.availableTests = tests;
    },
    [actions.deleteIdealProfilesSuccess]: (draft, { payload: { profilesIds } }) => {
      draft.idealProfiles = draft.idealProfiles.filter((item) => !profilesIds.includes(item.idealProfileID));
    },
    [actions.setActiveIdealProfile]: (draft, { payload: { idealProfile } }) => {
      draft.activeIdealProfile = idealProfile;
    },
    [actions.fetchGroupsForIdealProfilesSuccess]: (draft, { payload: { groups } }) => {
      draft.availableGroups = groups;
    },
    [actions.setIdealProfilesFilteringOptions]: (draft, { payload }) => {
      draft.filteringOptions = payload;
    },
  },
  initialState,
);

export default idealProfilesReducer;
