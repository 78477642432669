const linksData = [
  {
    id: 1,
    assessment: '15FQ+ & Adapt-G (15FQ+ & Adapt-G)',
    creationDate: new Date(),
    expirationDate: new Date(),
    category: 'Battery',
    language: 'English',
  },

  {
    id: 2,
    assessment: '15FQ+ & Adapt-G (15FQ+ & Adapt-G)',
    creationDate: new Date(),
    expirationDate: new Date(),
    category: 'Battery',
    language: 'English',
  },

  {
    id: 3,
    assessment: '15FQ+ & Adapt-G (15FQ+ & Adapt-G)',
    creationDate: new Date(),
    expirationDate: new Date(),
    category: 'Battery',
    language: 'English',
  },
];

export default linksData;
