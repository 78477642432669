const breadcrumbs = (project) => [
  {
    label: 'Projects',
    path: '/projects',
  },
  {
    label: project ? project.name : '',
    path: `/projects/${project?.projectId}`,
  },
  {
    label: 'Session',
  },
];

export default breadcrumbs;
