import styled, { css } from 'styled-components';

export const PanelTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 45px 15px 45px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  position: relative;
  
  ${(props) => props.pLeft && `padding-left: ${props.pLeft}px;`}
  ${(props) => props.root && `font-weight: bold;`}
  ${(props) => props.selectable && `cursor: pointer;`}
  ${(props) =>
    props.noBorder &&
    css`
      border: none !important;

      & + & {
        border-top: 1px solid ${(props) => props.theme.colors.grey6} !important;
      }
    `}
`;

export const CheckboxWrapper = styled.div`
  position: absolute;
  left: ${(props) => (props.yOffset ? `${props.yOffset}px` : '16px')};
  top: 15px;
  cursor: pointer;

  ${(props) => props.nested && `left: 0`}
`;
