import { I18n } from 'react-redux-i18n';

const columns = [
  {
    Header: I18n.t('Name'),
    accessor: 'name',
    name: 'name',
    type: 'text',
    disableSortBy: true,
  },
];

export default columns;
