export const CATEGORY = 1;
export const COMPETENCY = 2;
export const ITEM = 3;

export const ITEMS_LABELS = Object.freeze({
  [CATEGORY]: {
    title: 'Category deletion',
    description: 'category',
  },
  [COMPETENCY]: {
    title: 'Competency deletion',
    description: 'competency',
  },
  [ITEM]: {
    title: 'Item deletion',
    description: 'item',
  },
});
