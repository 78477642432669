import * as actions from './actions';
import handleActions from '../immerHandleActions';

import groupsColumns from '../../table/columns/groups';

import {
  TEXT_FILTER_TYPES,
  DATE_FILTER_TYPES,
  NUMBER_FILTER_TYPES,
  textFilterOptions,
  datesFilterOptions,
  numberFilterOptions,
} from '../../constants/advancedFilterOptions';

const getInitialFilterValue = (type) => {
  if (type === 'number') return numberFilterOptions.find((item) => item.name === NUMBER_FILTER_TYPES.IS_EQUAL);
  if (type === 'date') return datesFilterOptions.find((item) => item.name === DATE_FILTER_TYPES.THREE_DAYS);
  return textFilterOptions.find((item) => item.name === TEXT_FILTER_TYPES.CONTAINS);
};

const GROUPS_FILTER_FIELDS = groupsColumns
  .filter((item) => item.name !== 'actions')
  .map((column) => ({ name: column.name, type: column.type, filteringUiName: column.filteringUiName }));

const initialInputsState = GROUPS_FILTER_FIELDS.map((fieldObj) => ({
  ...fieldObj,
  name: fieldObj.name,
  value: fieldObj.type === 'date' ? null : '',
  type: fieldObj.type,
  filterType: getInitialFilterValue(fieldObj.type),
}));

const initialState = {
  allGroups: [],
  groups: [],
  activeGroupInView: null,
  activeManagedGroupSession: {
    link: null,
    respondents: [],
    selectedRespondents: [],
    hash: '',
  },
  selectedGroups: [],
  search: '',
  filter: {
    isApplied: false,
    dirty: false,
    appliedInputs: initialInputsState,
    inputs: initialInputsState,
  },
  pagesAvailable: 0,
  totalCount: 0,
  lastPageIndex: 0,
  shouldResetTableState: false,
  commonAssessments: [],
  availableReports: [],
  availableNorms: [],
};

const groupsReducer = handleActions(
  {
    [actions.setSelectedGroups]: (draft, { payload: { groups } }) => {
      draft.selectedGroups = groups;
    },
    // FILTERS PART
    [actions.setGroupsSearchValue]: (draft, { payload: { searchValue } }) => {
      draft.search = searchValue;
    },
    [actions.setGroupsFilterValue]: (draft, { payload: { fieldName, value } }) => {
      draft.filter.inputs = draft.filter.inputs.map((item) => (item.name === fieldName ? { ...item, value } : item));
      draft.filter.dirty = true;
    },
    [actions.setGroupsFilterType]: (draft, { payload: { fieldName, filterType } }) => {
      const initValue = getInitialFilterValue(
        groupsColumns.find((item) => item.name.toLowerCase() === fieldName.toLowerCase()).type,
      );
      draft.filter.inputs = draft.filter.inputs.map((item) =>
        item.name === fieldName ? { ...item, filterType: filterType === 'initial' ? initValue : filterType } : item,
      );
      draft.filter.dirty = true;
    },
    [actions.applyGroupsFilters]: (draft) => {
      draft.filter.dirty = false;
      draft.filter.isApplied = true;
      draft.filter.appliedInputs = draft.filter.inputs;
    },
    [actions.clearGroupsFilters]: (draft) => {
      draft.filter.dirty = true;
      draft.filter.inputs = initialInputsState;
    },
    [actions.deleteGroupsAppliedFilter]: (draft, { payload: { fieldName } }) => {
      draft.filter.appliedInputs = draft.filter.appliedInputs.map((input) =>
        input.name === fieldName ? { ...input, value: input.type === 'date' ? null : '' } : input,
      );
      draft.filter.inputs = draft.filter.inputs.map((input) =>
        input.name === fieldName ? { ...input, value: input.type === 'date' ? null : '' } : input,
      );
    },

    // END OF FILTERS, EVERYTHING ELSE
    [actions.fetchGroupsSuccess]: (draft, { payload: { data, shouldReset } }) => {
      draft.groups = shouldReset ? data.groups : [...draft.groups, ...data.groups];
      draft.pagesAvailable = data.pagesAvailable;
      draft.totalCount = data.totalCount;
      draft.lastPageIndex = data.pageNumber;
    },
    [actions.deleteGroupsSuccess]: (draft, { payload: { groupsIds } }) => {
      draft.groups = draft.groups.filter((group) => !groupsIds.includes(group.groupID));
    },
    [actions.fetchAllGroupsSuccess]: (draft, { payload: { data } }) => {
      draft.allGroups = data;
    },
    [actions.createNewGroupSuccess]: (draft) => {
      draft.shouldResetTableState = true;
    },
    [actions.changeShouldResetGroupsTableBoolState]: (draft, { payload: { state } }) => {
      draft.shouldResetTableState = state;
    },
    [actions.setActiveGroup]: (draft, { payload: { group } }) => {
      draft.activeGroupInView = group;
    },
    [actions.setActiveManagedGroupSession]: (draft, { payload: { session } }) => {
      draft.activeManagedGroupSession = { ...draft.activeManagedGroupSession, ...session };
    },
    [actions.setManagedGroupSelectedRespondents]: (draft, { payload: { respondents } }) => {
      draft.activeManagedGroupSession.selectedRespondents = respondents;
    },
    [actions.resetManagedGroupState]: (draft) => {
      draft.activeManagedGroupSession = { link: null, respondents: [], selectedRespondents: [] };
    },
    [actions.fetchGroupsCommonAssessmentsSuccess]: (draft, { payload: { commonAssessments } }) => {
      draft.commonAssessments = commonAssessments;
    },
    [actions.clearGroupsCommonAssessments]: (draft) => {
      draft.commonAssessments = [];
    },
    [actions.fetchGroupsAssessmentsNormsSuccess]: (draft, { payload: { norms } }) => {
      draft.availableNorms = norms;
    },
    [actions.clearGroupsAssessmentsNorms]: (draft) => {
      draft.availableNorms = [];
    },
    [actions.fetchAvailableGroupsReportsSuccess]: (draft, { payload: { reportSets, reports } }) => {
      const sets = reportSets.map((set) => ({
        ...set,
        type: 'set',
        isSolutionReportSet: Boolean(set.isSolutionSet),
        reports: set.reportIDArray
          .map((reportId) => reports.find((item) => item.reportID === Number(reportId)))
          .filter((report) => report),
      }));
      draft.availableReports = [...sets];
    },
    [actions.clearAvailableGroupsReports]: (draft) => {
      draft.availableReports = [];
    },
  },
  initialState,
);

export default groupsReducer;
