import React from 'react';
import { func, string } from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as Close } from '../../assets/icons/close.svg';

const CloseIcon = ({ clickHandler, className }) => {
  return <StyledIcon onClick={clickHandler} className={className} />;
};

CloseIcon.propTypes = {
  clickHandler: func.isRequired,
  className: string,
};

CloseIcon.defaultProps = {
  className: '',
};

const StyledIcon = styled(Close)`
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
  path {
    transition: all 0.2s;
  }

  path {
    fill: ${(props) => props.theme.colors.darkBlue2};
  }

  :hover {
    path {
      fill: ${(props) => props.theme.colors.mediumBlue};
    }
  }
`;

export default CloseIcon;
