import React, { useState, useEffect } from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { I18n } from 'react-redux-i18n';
import NewModal from '../../reusable/NewModal';
import Steps from '../../reusable/Steps';
import PrepareStep from './ImportSurveyPrepareStep';
import PreviewStep from './ImportSurveyPreviewStep';
import FinishStep from './ImportSurveyFinishStep';
import { fetchCompetenciesAction } from '../../../store/settings/competencies/actions';

import useStep from '../../../hooks/useStep';

const steps = ['prepare', 'preview', 'finish'];

const ImportSessions = ({ onClose, onSuccess }) => {
  const activeProjectInView = useSelector((state) => state.projects.activeProjectInView);
  const { step, goNext } = useStep({ steps });
  const [importedValues, setImportedValues] = useState({
    matrix: [],
    json: null,
  });
  const [importedJson, setImportedJson] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCompetenciesAction());
  }, []);

  const handleClose = (arg) => {
    if (arg === 'create_survey') {
      onSuccess('create_survey', importedJson);
    } else {
      onSuccess();
    }
  };

  return (
    <StyledModal title={I18n.t('Import from CSV')} isVisible onClose={onClose}>
      <Steps steps={steps} stepIndex={step} />
      {step === 0 && (
        <PrepareStep
          goNext={goNext}
          onClose={onClose}
          setImportedValues={setImportedValues}
          projectId={activeProjectInView && activeProjectInView.projectId}
        />
      )}
      {step === 1 && (
        <PreviewStep
          data={importedValues}
          goNext={goNext}
          onClose={onClose}
          setImportedValues={setImportedJson}
          projectId={activeProjectInView && activeProjectInView.projectId}
        />
      )}
      {step === 2 && <FinishStep importeValues={importedJson} onClose={handleClose} />}
    </StyledModal>
  );
};

ImportSessions.propTypes = {
  onClose: func.isRequired,
  onSuccess: func.isRequired,
};

const StyledModal = styled(NewModal)`
  height: 100%;
  max-height: 58rem;
  min-width: 82rem;
  max-width: 110rem;
  display: flex;
  flex-direction: column;
`;

export default ImportSessions;
