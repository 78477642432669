import { createSelector } from 'reselect';
import { groupBy } from 'lodash';
import languagesMap from '../../constants/languages';

const getAvailableNorms = (state) => state.respondents.availableNorms;
const getAvailableProfiles = (state) => state.idealProfiles.idealProfiles;
const getAvailableReports = (state) => state.respondents.availableReports;
const getActiveRespondent = (state) => state.respondents.respondentToEdit;
const getTests = (state) => state.user.tests;
const getBatteries = (state) => state.user.batteries;

// Answer sheet
const getAnswerSheetTests = (state) => state.respondents.answerSheet.tests;
const getAnswerSheetBatteries = (state) => state.respondents.answerSheet.batteries;
const getAnswerSheetLanguages = (state) => state.respondents.answerSheet.languages;

export const selectAvailableNorms = createSelector(getAvailableNorms, (norms) => {
  return groupBy(
    norms.map((norm) => ({ ...norm, value: norm.normID, label: norm.name })),
    'testID',
  );
});

export const selectAvailableIdealProfiles = createSelector(getAvailableProfiles, (profiles) => {
  return groupBy(
    profiles.map((profile) => ({ ...profile, value: profile.idealProfileID, label: profile.name })),
    'testID',
  );
});

export const selectAvailableReports = createSelector([getAvailableReports], (reports) => {
  // const profilesIds = Object.keys(idealProfiles);
  // const reportsIdsToRemove = [];
  // return reports.map((set) => ({
  //   ...set,
  //   reports: set.reports.filter((item) => {
  //     if (item.isIdealProfileReport && !profilesIds.includes(item.ipDependencies)) {
  //       reportsIdsToRemove.push(item.reportID);
  //       return false;
  //     }
  //     return true;
  //   }),
  //   reportIDArray: set.reportIDArray.filter((reportId) => !reportsIdsToRemove.includes(reportId)),
  // }));
  return reports;
});

export const selectRespondentTests = createSelector(
  [getActiveRespondent, getTests, getBatteries],
  (respondent, tests, batteries) => {
    return respondent.doneTests.map((test) => {
      const original = test.testID
        ? tests.find((item) => item.testID === test.testID)
        : batteries.find((item) => item.batteryID === test.batteryID);
      if (!original) return test;
      return { ...test, ...original };
    });
  },
);

// eslint-disable-next-line import/prefer-default-export
export const selectAnswerSheetAssessments = createSelector(
  [getAnswerSheetTests, getAnswerSheetBatteries],
  (tests, batteries) => {
    return [...tests, ...batteries];
  },
);

export const selectAnswerSheetLanguages = createSelector([getAnswerSheetLanguages], (languages) => {
  return languages
    ? languages.map((item) => ({
        code: item.langID,
        value: item.langID,
        label: languagesMap[item.langID] || item.langID,
      }))
    : [];
});
