import { createAction } from 'redux-actions';
import { I18n } from 'react-redux-i18n';
import apiInstance2 from '../../services/apiService';

import defaultTableState from '../../constants/defaultTableState';
import generateQueryString from '../generateQueryString';
import projectsColumns from '../../table/columns/projects';
import assessmentProjectRespondentsColumns from '../../table/columns/assessment-project-respondents';

import createToastNotification from '../../utils/createToastNotification';

export const setActiveProject = createAction('SET_ACTIVE_PROJECT', (project) => ({ project }));

// ----------------- FILTERS ----------------------
export const setProjectsSearchValue = createAction('SET_PROJECTS_SEARCH_VALUE', (searchValue) => ({
  searchValue,
}));

export const setProjectsFilterValue = createAction('SET_PROJECTS_FILTER_VALUE', (fieldName, value) => ({
  fieldName,
  value,
}));

export const setProjectsFilterType = createAction('SET_PROJECTS_FILTER_TYPE', (fieldName, filterType) => ({
  fieldName,
  filterType,
}));

export const deleteProjectsAppliedFilter = createAction('DELETE_PROJECTS_APPLIED_FILTER', (fieldName) => ({
  fieldName,
}));

export const applyProjectsFilters = createAction('APPLY_PROJECTS_FILTERS');
export const clearProjectsFilters = createAction('CLEAR_PROJECTS_FILTERS');
export const resetProjectsTableState = createAction('RESET_PROJECTS_TABLE_STATE');

// ---------  NETWORK RELATED ----------
export const fetchProjectsStart = createAction('FETCH_PROJECTS_START');
export const fetchProjectsSuccess = createAction('FETCH_PROJECTS_SUCCESS', (data, shouldReset) => ({
  data,
  shouldReset,
}));
export const fetchProjectsFailure = createAction('FETCH_PROJECTS_FAILURE');
export const setSelectedProjects = createAction('SET_SELECTED_PROJECTS', (projects) => ({
  projects,
}));

export const fetch360ProjectByIdStart = createAction('FETCH_360_PROJECT_BY_ID_START');
export const fetch360ProjectByIdSuccess = createAction('FETCH_360_PROJECT_BY_ID_SUCCESS');
export const fetch360ProjectByIdFailure = createAction('FETCH_360_PROJECT_BY_ID_FAILURE');

export const fetchAssessmentProjectByIdStart = createAction('FETCH_ASSESSMENT_PROJECT_BY_ID_START');
export const fetchAssessmentProjectByIdSuccess = createAction('FETCH_ASSESSMENT_PROJECT_BY_ID_SUCCESS');
export const fetchAssessmentProjectByIdFailure = createAction('FETCH_ASSESSMENT_PROJECT_BY_ID_FAILURE');

export const deleteProjectsStart = createAction('DELETE_PROJECTS_START');
export const deleteProjectsSuccess = createAction('DELETE_PROJECTS_SUCCESS', (projects) => ({ projects }));
export const deleteProjectsFailure = createAction('DELETE_PROJECTS_FAILURE');

export const addRespondentToAssessmentProjectStart = createAction('ADD_RESPONDENT_TO_ASSESSMENT_PROJECT_START');
export const addRespondentToAssessmentProjectSuccess = createAction('ADD_RESPONDENT_TO_ASSESSMENT_PROJECT_SUCCESS');
export const addRespondentToAssessmentProjectFailure = createAction('ADD_RESPONDENT_TO_ASSESSMENT_PROJECT_FAILURE');

export const addGroupToAssessmentProjectStart = createAction('ADD_GROUP_TO_ASSESSMENT_PROJECT_START');
export const addGroupToAssessmentProjectSuccess = createAction('ADD_GROUP_TO_ASSESSMENT_PROJECT_SUCCESS');
export const addGroupToAssessmentProjectFailure = createAction('ADD_GROUP_TO_ASSESSMENT_PROJECT_FAILURE');

export const fetchAssessmentProjectRespondentsStart = createAction('FETCH_ASSESSMENT_PROJECT_RESPONDENTS_START');
export const fetchAssessmentProjectRespondentsSuccess = createAction(
  'FETCH_ASSESSMENT_PROJECT_RESPONDENTS_SUCCESS',
  (data, shouldReset) => ({
    data,
    shouldReset,
  }),
);
export const fetchAssessmentProjectRespondentsFailure = createAction('FETCH_ASSESSMENT_PROJECT_RESPONDENTS_FAILURE');

export const endProjectStart = createAction('END_ASSESSMENT_PROJECT_START');
export const endProjectSuccess = createAction('END_ASSESSMENT_PROJECT_SUCCESS');
export const endProjectFailure = createAction('END_ASSESSMENT_PROJECT_FAILURE');

export const sendAssessmentProjectReminderStart = createAction('SEND_ASSESSMENT_PROJECT_REMINDER_START');
export const sendAssessmentProjectReminderSuccess = createAction('SEND_ASSESSMENT_PROJECT_REMINDER_SUCCESS');
export const sendAssessmentProjectReminderFailure = createAction('SEND_ASSESSMENT_PROJECT_REMINDER_FAILURE');

export const fetchProjects = (tableState = defaultTableState, requestType, { callback, shouldReset }) => async (
  dispatch,
) => {
  try {
    dispatch(fetchProjectsStart());

    const finalObj = { ...tableState };

    if (finalObj.sortBy) {
      const sortingObj = finalObj.sortBy[0];
      const сolumnIndex = projectsColumns.findIndex(
        (item) =>
          (typeof item.Header === 'function' ? item.stringHeader : item.Header) === sortingObj.id ||
          item.name === sortingObj.id,
      );
      const order = sortingObj.desc ? 1 : 0;
      const columnMeta = projectsColumns[сolumnIndex];
      finalObj.sortBy = {
        ColumnIndex: columnMeta.backendColumnIndex ?? сolumnIndex,
        Order: order,
      };
    }

    // const requestType = tabs.findIndex((tab) => tab.name === activeTab.name);
    const queryString = generateQueryString(finalObj, projectsColumns);
    const response = await apiInstance2.get(`/api/v2/projects?requestType=${requestType}&${queryString}`);

    if (response.status === 200) {
      dispatch(fetchProjectsSuccess(response.data, shouldReset));
      if (callback) callback();
    }
  } catch (e) {
    dispatch(fetchProjectsFailure(e));
    if (callback) callback(e);
  }
};

export const fetch360ProjectById = (projectId, cb) => async (dispatch) => {
  dispatch(fetch360ProjectByIdStart());
  try {
    const response = await apiInstance2.get(`/api/v2/360/projects/${projectId}`);
    if (response.status === 200) {
      dispatch(fetch360ProjectByIdSuccess());
      dispatch(setActiveProject(response.data));
      if (cb) cb(null, response.data);
    }
  } catch (e) {
    dispatch(fetch360ProjectByIdFailure());
    if (cb) cb(e);
  }
};

export const fetchAssessmentProjectById = (projectId, cb) => async (dispatch) => {
  dispatch(fetchAssessmentProjectByIdStart());
  try {
    const response = await apiInstance2.get(`/api/v2/projects/assessments/${projectId}`);
    if (response.status === 200) {
      dispatch(fetchAssessmentProjectByIdSuccess());
      dispatch(setActiveProject(response.data));
      if (cb) cb(null, response.data);
    }
  } catch (e) {
    dispatch(fetchAssessmentProjectByIdFailure());
    if (cb) cb(e);
  }
};

export const deleteProjects = (projects, onSuccess, onFailure) => async (dispatch) => {
  dispatch(deleteProjectsStart());
  try {
    const response = await apiInstance2.delete(`/api/v2/projects`, { data: projects });
    if (response.status === 200) {
      dispatch(deleteProjectsSuccess(projects));
      createToastNotification({
        message: I18n.t(projects.length > 1 ? 'projectsDeletionToast' : 'projectDeletionToast'),
      });
      if (onSuccess) onSuccess();
    }
  } catch (err) {
    dispatch(deleteProjectsFailure(err.message));
    if (onFailure) onFailure(err);
  }
};

// Competency Groups
export const fetchCompetencyGroupsStart = createAction('FETCH_COMPETENCY_GROUPS_START');
export const fetchCompetencyGroupsSuccess = createAction('FETCH_COMPETENCY_GROUPS_SUCCESS', (data) => ({
  data,
}));
export const fetchCompetencyGroupsFailure = createAction('FETCH_COMPETENCY_GROUPS_FAILURE');

export const fetchCompetencyGroups = () => async (dispatch) => {
  dispatch(fetchCompetencyGroupsStart());
  try {
    const response = await apiInstance2.get(`/api/v2/360/competency-groups`);
    if (response.status === 200) {
      dispatch(fetchCompetencyGroupsSuccess(response));
    }
  } catch (err) {
    dispatch(fetchCompetencyGroupsFailure(err.message));
  }
};

// Add Project
export const addProjectStart = createAction('ADD_PROJECT_START');
export const addProjectSuccess = createAction('ADD_PROJECT_SUCCESS', (data) => ({
  data,
}));
export const addProjectFailure = createAction('ADD_PROJECT_FAILURE');

export const addProject = (data, cb) => async (dispatch) => {
  dispatch(addProjectStart());
  try {
    const response = await apiInstance2.post(`/api/v2/360/projects`, data);

    if (response.status === 200) {
      dispatch(addProjectSuccess(response));
      createToastNotification({ message: I18n.t('projectCreationToast') });
      if (cb) cb(response.data);
    }
  } catch (err) {
    dispatch(addProjectFailure(err.message));
    if (cb) cb();
  }
};

export const editProject = (data, cb) => async (dispatch) => {
  dispatch(addProjectStart());
  try {
    const response = await apiInstance2.patch(`/api/v2/360/projects`, data);

    if (response.status === 200) {
      dispatch(addProjectSuccess(response));
      createToastNotification({ message: I18n.t('projectUpdateToast') });
      if (cb) cb(response);
    }
  } catch (err) {
    dispatch(addProjectFailure(err.message));
    if (cb) cb();
  }
};

// Create Assessment Project
export const saveProjectInfoData = createAction('SAVE_PROJECT_INFO_DATA', (values) => ({ values }));

export const saveProjectAssessmentData = createAction('SAVE_PROJECT_ASSESSMENT_DATA', (values) => ({ values }));

export const saveProjectInvitesData = createAction('SAVE_PROJECT_INVITES_DATA', (values) => ({ values }));

export const saveProjectReportsData = createAction('SAVE_PROJECT_REPORTS_DATA', (values) => ({ values }));

export const setAssessmentProjectSelectedRespondents = createAction(
  'SET_ASSESSMENT_PROJECT_SELECTED_RESPONDENTS',
  (respondents) => ({
    respondents,
  }),
);

export const setAssessmentProjectSearchValue = createAction('SET_ASSESSMENT_PROJECT_SEARCH_VALUE', (searchValue) => ({
  searchValue,
}));

export const fetchAssessmentProjectSettingsStart = createAction('FETCH_ASSESSMENT_PROJECT_SETTINGS_START');
export const fetchAssessmentProjectSettingsSuccess = createAction('FETCH_ASSESSMENT_PROJECT_SETTINGS_SUCCESS');
export const fetchAssessmentProjectSettingsFailure = createAction('FETCH_ASSESSMENT_PROJECT_SETTINGS_FAILURE');

export const resetCreateAssessmentProjectData = createAction('RESET_CREATE_ASSESSMENT_PROJECT_DATA');
export const resetAssessmentProjectData = createAction('RESET_ASSESSMENT_PROJECT_DATA');

export const createAssessmentProjectStart = createAction('CREATE_ASSESSMENT_PROJECT_START');
export const createAssessmentProjectSuccess = createAction('CREATE_ASSESSMENT_PROJECT_SUCCESS');
export const createAssessmentProjectFailure = createAction('CREATE_ASSESSMENT_PROJECT_FAILURE');

export const updateAssessmentProjectStart = createAction('UPDATE_ASSESSMENT_PROJECT_START');
export const updateAssessmentProjectSuccess = createAction('UPDATE_ASSESSMENT_PROJECT_SUCCESS');
export const updateAssessmentProjectFailure = createAction('UPDATE_ASSESSMENT_PROJECT_FAILURE');

export const assessmentProjectInviteAllRespondentsStart = createAction(
  'ASSESSMENT_PROJECT_INVITE_ALL_RESPONDENTS_START',
);
export const assessmentProjectInviteAllRespondentsSuccess = createAction(
  'ASSESSMENT_PROJECT_INVITE_ALL_RESPONDENTS_SUCCESS',
);
export const assessmentProjectInviteAllRespondentsFailure = createAction(
  'ASSESSMENT_PROJECT_INVITE_ALL_RESPONDENTS_FAILURE',
);

export const assessmentProjectInviteRespondentsStart = createAction('ASSESSMENT_PROJECT_INVITE_RESPONDENTS_START');
export const assessmentProjectInviteRespondentsSuccess = createAction('ASSESSMENT_PROJECT_INVITE_RESPONDENTS_SUCCESS');
export const assessmentProjectInviteRespondentsFailure = createAction('ASSESSMENT_PROJECT_INVITE_RESPONDENTS_FAILURE');

export const assessmentProjectDeleteRespondentsStart = createAction('ASSESSMENT_PROJECT_DELETE_RESPONDENTS_START');
export const assessmentProjectDeleteRespondentsSuccess = createAction('ASSESSMENT_PROJECT_DELETE_RESPONDENTS_SUCCESS');
export const assessmentProjectDeleteRespondentsFailure = createAction('ASSESSMENT_PROJECT_DELETE_RESPONDENTS_FAILURE');

export const assessmentProjectHashCheckStart = createAction('ASSESSMENT_PROJECT_HASH_CHECK_START');
export const assessmentProjectHashCheckSuccess = createAction('ASSESSMENT_PROJECT_HASH_CHECK_SUCCESS', (hash) => ({
  hash,
}));
export const assessmentProjectHashCheckFailure = createAction('ASSESSMENT_PROJECT_HASH_CHECK_FAILURE');

export const setAssessmentProjectHash = createAction('SET_ASSESSMENT_PROJECT_HASH', (hash) => ({ hash }));

export const createAssessmentProject = (data, cb) => async (dispatch) => {
  dispatch(createAssessmentProjectStart());
  try {
    const response = await apiInstance2.post(`/api/v2/projects/assessments`, data);
    if (response.status === 200) {
      dispatch(createAssessmentProjectSuccess());
      createToastNotification({ message: I18n.t('projectCreationToast') });
      if (cb) cb(null);
    }
  } catch (err) {
    dispatch(createAssessmentProjectFailure());
    if (cb) cb(err);
  }
};

export const updateAssessmentProject = (data, cb) => async (dispatch) => {
  dispatch(updateAssessmentProjectStart());
  try {
    const response = await apiInstance2.patch(`/api/v2/projects/assessments`, data);
    if (response.status === 200) {
      dispatch(updateAssessmentProjectSuccess());
      createToastNotification({ message: I18n.t('projectUpdateToast') });
      if (cb) cb(null);
    }
  } catch (err) {
    dispatch(updateAssessmentProjectFailure());
    if (cb) cb(err);
  }
};

export const addRespondentToAssessmentProject = (projectId, data, cb) => async (dispatch) => {
  dispatch(addRespondentToAssessmentProjectStart());
  try {
    const response = await apiInstance2.post(`/api/v2/projects/assessments/${projectId}/respondents`, data);
    if (response.status === 200) {
      dispatch(addRespondentToAssessmentProjectSuccess());
      dispatch(setAssessmentProjectHash(response.data.hash));
      createToastNotification({ message: I18n.t('Respondent has been added') });
      if (cb) cb(null);
    }
  } catch (err) {
    dispatch(addRespondentToAssessmentProjectFailure());
    if (cb) cb(err);
  }
};

export const addGroupToAssessmentProject = (projectId, data, cb) => async (dispatch) => {
  dispatch(addGroupToAssessmentProjectStart());
  try {
    const response = await apiInstance2.post(`/api/v2/projects/assessments/${projectId}/group`, data, {
      headers: { 'Content-type': 'application/json' },
    });
    if (response.status === 200) {
      dispatch(setAssessmentProjectHash(response.data.hash));
      dispatch(addGroupToAssessmentProjectSuccess());
      createToastNotification({ message: I18n.t('Respondents have been added') });
      if (cb) cb(null);
    }
  } catch (err) {
    dispatch(addGroupToAssessmentProjectFailure());
    if (cb) cb(err);
  }
};

export const fetchAssessmentProjectRespondents = (
  tableState = defaultTableState,
  { callback, shouldReset, projectId },
) => async (dispatch) => {
  try {
    dispatch(fetchAssessmentProjectRespondentsStart());
    const finalObj = { ...tableState };

    if (finalObj.sortBy) {
      const sortingObj = finalObj.sortBy[0];
      const сolumnIndex = assessmentProjectRespondentsColumns.findIndex(
        (item) =>
          (typeof item.Header === 'function' ? item.stringHeader : item.Header) === sortingObj.id ||
          item.name === sortingObj.id,
      );
      const order = sortingObj.desc ? 1 : 0;
      finalObj.sortBy = {
        ColumnIndex: сolumnIndex,
        Order: order,
      };
    }

    const queryString = generateQueryString(finalObj, assessmentProjectRespondentsColumns);
    const response = await apiInstance2.get(`/api/v2/projects/assessments/${projectId}/respondents?${queryString}`);
    if (response.status === 200) {
      dispatch(fetchAssessmentProjectRespondentsSuccess(response.data, shouldReset));
      if (callback) callback();
    }
  } catch (e) {
    dispatch(fetchAssessmentProjectRespondentsFailure(e));
    if (callback) callback(e);
  }
};

export const assessmentProjectInviteAllRespondents = (projectId, cb) => async (dispatch) => {
  dispatch(assessmentProjectInviteAllRespondentsStart());
  try {
    //     AllIncomplete = 0, // invited and not invited, but not complete
    //     AllUninvited = 1,  // not invited and not complete
    //     AlreadyInvited = 2 // invited, but not complete
    const response = await apiInstance2.post(`/api/v2/projects/assessments/${projectId}/invites`, 0, {
      headers: { 'Content-type': 'application/json' },
    });
    if (response.status === 200) {
      dispatch(setAssessmentProjectHash(response.data.hash));
      dispatch(assessmentProjectInviteAllRespondentsSuccess());
      createToastNotification({ message: I18n.t('Respondents have been invited') });
      if (cb) cb(null);
    }
  } catch (err) {
    dispatch(assessmentProjectInviteAllRespondentsFailure(err));
    if (cb) cb(err);
  }
};

export const assessmentProjectInviteRespondents = (projectId, respondentsIds, cb) => async (dispatch) => {
  dispatch(assessmentProjectInviteRespondentsStart());
  try {
    const response = await apiInstance2.post(
      `/api/v2/projects/assessments/${projectId}/invites/respondents`,
      respondentsIds,
    );
    if (response.status === 200) {
      dispatch(setAssessmentProjectHash(response.data.hash));
      dispatch(assessmentProjectInviteRespondentsSuccess(response.data.hash));
      createToastNotification({ message: I18n.t('Respondents invites successfully created') });
      if (cb) cb(null);
    }
  } catch (err) {
    dispatch(assessmentProjectInviteRespondentsFailure(err));
    if (cb) cb(err);
  }
};

export const assessmentProjectDeleteRespondents = (projectId, respondentIds, cb) => async (dispatch) => {
  dispatch(assessmentProjectDeleteRespondentsStart());
  try {
    const response = await apiInstance2.delete(`/api/v2/projects/assessments/${projectId}/respondents`, {
      data: respondentIds,
    });
    if (response.status === 200) {
      dispatch(setAssessmentProjectHash(response.data.hash));
      dispatch(assessmentProjectDeleteRespondentsSuccess());
      createToastNotification({ message: I18n.t('Respondents have been deleted successfully') });
      if (cb) cb(null);
    }
  } catch (err) {
    dispatch(assessmentProjectDeleteRespondentsFailure(err));
    if (cb) cb(err);
  }
};

export const fetchAssessmentProjectSettings = (projectId, cb) => async (dispatch) => {
  dispatch(fetchAssessmentProjectSettingsStart());
  try {
    const response = await apiInstance2.get(`/api/v2/projects/assessments/${projectId}/settings`);
    if (response.status === 200) {
      dispatch(fetchAssessmentProjectSettingsSuccess());
      if (cb) cb(null, response.data);
    }
  } catch (err) {
    dispatch(fetchAssessmentProjectSettingsFailure(err));
    if (cb) cb(err);
  }
};

export const assessmentProjectHashCheck = (projectId, cb) => async (dispatch, getState) => {
  const {
    projects: { assessmentProject },
  } = getState();
  dispatch(assessmentProjectHashCheckStart());
  try {
    const response = await apiInstance2.get(`/api/v2/projects/assessments/${projectId}/respondents/hash`);
    if (response.status === 200) {
      if (assessmentProject.hash && assessmentProject.hash !== response.data && cb) {
        cb(null);
      }
      dispatch(assessmentProjectHashCheckSuccess(response.data));
    }
  } catch (err) {
    dispatch(assessmentProjectHashCheckFailure(err));
    if (cb) cb(err);
  }
};

export const endAssessmentProject = (projectId, cb) => async (dispatch) => {
  try {
    dispatch(endProjectStart());
    const response = await apiInstance2.post(`/api/v2/projects/assessments/${projectId}/close`);
    if (response.status === 200) {
      dispatch(endProjectSuccess());
      if (cb) cb();
    }
  } catch (err) {
    dispatch(endProjectFailure(err));
    if (cb) cb(err);
  }
};

export const end360Project = (projectId, cb) => async (dispatch) => {
  try {
    dispatch(endProjectStart());
    const response = await apiInstance2.post(`/api/v2/360/projects/${projectId}/close`);
    if (response.status === 200) {
      dispatch(endProjectSuccess());
      if (cb) cb();
    }
  } catch (err) {
    dispatch(endProjectFailure(err));
    if (cb) cb(err);
  }
};

export const sendAssessmentProjectReminder = (projectId, inviteIds, cb) => async (dispatch) => {
  try {
    dispatch(sendAssessmentProjectReminderStart());
    const response = await apiInstance2.post(`/api/v2/projects/assessments/${projectId}/remind`, { inviteIds });
    if (response.status === 200) {
      dispatch(sendAssessmentProjectReminderSuccess());
      createToastNotification({ message: I18n.t('The reminder has been sent') });
      if (cb) cb();
    }
  } catch (err) {
    dispatch(sendAssessmentProjectReminderFailure(err));
    if (cb) cb(err);
  }
};

export const updateExpiryProjectInvite = (data, cb) => async (dispatch) => {
  try {
    const response = await apiInstance2.patch('/api/v2/360/projects', data);
    if (response.status === 200) {
      createToastNotification({ message: I18n.t('updateProjectInvitationToast') });

      const response = await apiInstance2.get(
        `/api/v2/projects?requestType=0&PageNumber=0&PageSize=40&ColumnIndex=5&Order=1&`,
      );
      if (response.status === 200) {
        dispatch(fetchProjectsSuccess(response.data, true));
      }

      if (cb) cb();
    }
  } catch (e) {
    cb('Something went wrong', e);
  }
};
