import React from 'react';
import { string, bool } from 'prop-types';
import styled from 'styled-components';

const Avatar = ({ firstName, familyName, invisible, className }) => {
  const firstLetter = firstName ? firstName[0] : '';
  const secondLetter = familyName ? familyName[0] : '';
  return (
    <Circle invisible={invisible} className={className}>
      <span>{firstLetter}</span>
      <span>{secondLetter}</span>
    </Circle>
  );
};

Avatar.propTypes = {
  firstName: string,
  familyName: string,
  invisible: bool,
  className: string,
};

Avatar.defaultProps = {
  firstName: '',
  familyName: '',
  invisible: false,
  className: '',
};

const Circle = styled.div`
  width: 2.8rem;
  height: 2.8rem;
  min-width: 2.8rem;
  min-height: 2.8rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.grey1};
  color: ${(props) => props.theme.colors.blue};
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 1rem;
  visibility: ${(props) => (props.invisible ? 'hidden' : 'visible')};
`;

export default Avatar;
