import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { EMPTY_ACTIVE_TAB, TABS_360, TABS_360_ROUTE_MAPPER, TABS_360_TAB_MAPPER } from '../../constants/SettingsTab';
import TableTabs from '../../../reusable/TableTabs';

const SettingsTabs = () => {
  const dispatch = useDispatch();
  const route = useSelector((state) => state.router.location.pathname);

  const currentRoute = route.split('/').slice(-1)[0];

  const onTabClick = useCallback((tab) => {
    dispatch(push(TABS_360_ROUTE_MAPPER[tab.name]));
  }, []);

  return (
    <TableTabs
      tabs={TABS_360 || []}
      activeTab={TABS_360_TAB_MAPPER[currentRoute] || EMPTY_ACTIVE_TAB}
      clickHandler={onTabClick}
    />
  );
};

export default SettingsTabs;
