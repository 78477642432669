/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';

import CustomButton from '../../reusable/Buttons/Button';

import apiInstance2 from '../../../services/apiService';
import createToastNotification from '../../../utils/createToastNotification';

import LanguagesSelect from '../../reusable/Selects/LanguagesSelect';

function mapResponseToImprtedData(data, response) {
  const mappedDate = data.map((category) => ({ ...category, categories: category.competencies }));

  try {
    response.competencyCategories.forEach((category, idx) => {
      const found = mappedDate[idx];
      category.competencies.forEach((competency, idxx) => {
        const foundd = found.categories[idxx];
        foundd.competencyID = competency.competencyId;
        foundd.competencyType = competency.competencyType;
      });
      found.competencyID = category.categoryId;
      found.competencyType = category.competencyType;
    });
  } catch (err) {
    console.error(err);
  }

  return mappedDate;
}

const ImportSessionsPreviewStep = ({ data, goNext, onClose, setImportedValues }) => {
  const [isLoading, setIsLoading] = useState(false);
  const lanugages = data.matrix.map((entry) => entry.lang);
  const [currentLanguage, setCurrentLanguage] = useState(lanugages[0]);

  const currentData = data.matrix.find((entry) => entry.lang.code === currentLanguage.code);

  const headerRow = currentData.data[0];
  const bodyRows = currentData.data.slice(1);

  const importSessions = async () => {
    try {
      setIsLoading(true);
      const response = await apiInstance2.post('/api/v2/360/bulk-competencies', data.json);
      if (response.status === 200) {
        setIsLoading(false);
        setImportedValues(mapResponseToImprtedData(data.json.competencyCategories, response.data));
        goNext();
      } else {
        setIsLoading(false);
        throw new Error('Survey import error');
      }
    } catch (e) {
      setIsLoading(false);
      createToastNotification({
        title: 'Error',
        message: I18n.t('importSurveyErrorToast'),
        type: 'error',
      });
      onClose();
    }
  };

  const tableStyle = {
    borderCollapse: 'collapse',
    width: '100%',
  };

  const cellStyle = {
    border: '1px solid black',
    padding: '8px',
    textAlign: 'left',
  };

  return (
    <>
      <ContentWrapper>
        <StyledLanugageWrapper>
          <LanguagesSelect
            value={currentLanguage}
            languages={lanugages}
            onChange={(lang) => {
              setCurrentLanguage(lang);
            }}
          />
        </StyledLanugageWrapper>
        <table style={tableStyle}>
          <thead>
            <tr>
              {headerRow.map((header, index) => (
                <th style={cellStyle} key={index}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {bodyRows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td style={cellStyle} key={cellIndex}>
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </ContentWrapper>
      <ButtonsWrapper>
        <StyledButton disabled={isLoading} handler={importSessions}>
          {I18n.t('Import')}
        </StyledButton>
      </ButtonsWrapper>
    </>
  );
};

ImportSessionsPreviewStep.propTypes = {
  goNext: func.isRequired,
  onClose: func.isRequired,
  setImportedValues: func.isRequired,
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  margin-bottom: 1rem;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledButton = styled(CustomButton)`
  width: 12rem;
  text-transform: uppercase;
`;

const StyledLanugageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

export default ImportSessionsPreviewStep;
