import React from 'react';
import { func, bool, string, array, shape } from 'prop-types';
import styled from 'styled-components';

import Filter from './Filter/Filter';
import SearchContainer from './Search/Search';

const AdvancedFilter = ({ searchProps, filterProps, isFilterDisabled, isSearchDisabled }) => {
  if (isSearchDisabled && isFilterDisabled) {
    return '';
  }

  return (
    <Wrapper>
      {!isSearchDisabled && <SearchContainer {...searchProps} />}
      {!isFilterDisabled && <Filter {...filterProps} />}
    </Wrapper>
  );
};

AdvancedFilter.propTypes = {
  isFilterDisabled: bool,
  isSearchDisabled: bool,
  searchProps: shape({
    appliedFilters: array,
    deleteFilter: func,
    onSearchChange: func.isRequired,
    searchValue: string,
  }),
  filterProps: shape({
    inputs: array.isRequired,
    appliedInputs: array.isRequired,
    applyFilters: func.isRequired,
    clearAll: func.isRequired,
    isDirty: bool.isRequired,
  }),
};

AdvancedFilter.defaultProps = {
  isFilterDisabled: false,
  isSearchDisabled: false,
  filterProps: null,
  searchProps: null,
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
  min-height: 35px;
`;

export default AdvancedFilter;
