import React from 'react';
import { toast } from 'react-toastify';
import Notification from '../components/Notification';

const typesMap = {
  success: 'toast-success',
  failure: 'toast-failure',
  info: 'toast-info',
  copy: 'toast-success-copy',
};

const createToastNotification = ({ title, message, type = 'success', options }) => {
  toast(<Notification title={title} body={message} type={type} />, {
    type,
    className: typesMap[type],
    ...options,
  });
};

export default createToastNotification;
