import React, { useState } from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import { format } from 'date-fns';
import { I18n } from 'react-redux-i18n';
import { useNavigate } from '@reach/router';

import NewModal from '../reusable/NewModal';
import CustomButton from '../reusable/Buttons/Button';
import { ReactComponent as EmptyIcon } from '../../assets/icons/empty_state.svg';
import TableTabs from '../reusable/TableTabs';

// eslint-disable-next-line react/prop-types
const Notifications = ({ onClose, onDismiss, recent = [], history = [] }) => {
  const navigate = useNavigate();

  const tabs = [
    {
      name: 'new',
      label: I18n.t('New'),
    },
    {
      name: 'history',
      label: I18n.t('History'),
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const handleTabClick = (newTab) => {
    setActiveTab(newTab);
  };

  const notifications = {
    new: recent,
    history,
  };

  const handleRedirectClick = (e) => {
    e.preventDefault();
    navigate('/settings/activity-log');
    onClose();
  };

  const ListItemNew = ({ item }) => (
    <ListItemContainer key={item.id} type={item.type}>
      <ListItemTitle>
        {item.title}
        <ListItemDate>{format(new Date(item.creationDate).getTime(), 'dd/MM/yyyy')}</ListItemDate>
      </ListItemTitle>
      <ListItemBody dangerouslySetInnerHTML={{ __html: item.description }} />
      <ActionContainer>
        <CustomButton variant="secondary" size="s" onClick={() => onDismiss(item)}>
          {I18n.t('Dismiss')}
        </CustomButton>
      </ActionContainer>
    </ListItemContainer>
  );

  const ListItemHistory = ({ item }) => (
    <ListItemContainerHistory key={item.id} type={item.type}>
      <ListItemTitle>
        {item.title}
        <ListItemDate>{format(new Date(item.creationDate).getTime(), 'dd/MM/yyyy')}</ListItemDate>
      </ListItemTitle>
      <ListItemBody dangerouslySetInnerHTML={{ __html: item.description }} />
    </ListItemContainerHistory>
  );

  return (
    <StyledModal isVisible onClose={onClose} title={I18n.t('Notifications')}>
      <div>
        <TableTabs tabs={tabs} activeTab={activeTab} clickHandler={handleTabClick} />
        {// eslint-disable-next-line react/prop-types
        !notifications[activeTab.name].length ? (
          <EmptyMessageWrapper>
            <EmptyLink>
              {I18n.t('the previous notification area has been moved')}&nbsp;
              <StyledLink onClick={handleRedirectClick} href="/settings/activity-log">
                {I18n.t('here')}.
              </StyledLink>
            </EmptyLink>
            <EmptyIcon />
            <EmptyMessage>{activeTab.name === 'new' ? I18n.t('no new messages') : I18n.t('no messages')}</EmptyMessage>
          </EmptyMessageWrapper>
        ) : (
          <ListWrapper type={activeTab.name}>
            {// eslint-disable-next-line react/prop-types
            notifications[activeTab.name].map((item) =>
              activeTab.name === 'new' ? (
                // eslint-disable-next-line react/prop-types
                <ListItemNew key={item.commonIdentifier} item={item} />
              ) : (
                <ListItemHistory
                  // eslint-disable-next-line react/prop-types
                  key={item.commonIdentifier}
                  item={item}
                />
              ),
            )}
          </ListWrapper>
        )}
      </div>
    </StyledModal>
  );
};

const StyledModal = styled(NewModal)`
  width: 90rem;
  height: 70rem;
  overflow: scroll;
  position: relative;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ type }) => (type === 'new' ? '0 -10px' : '0')};

  > *:not(:first-child) {
    margin-top: 20px;
  }

  img {
    max-width: 100%;
  }

  video {
    max-width: 100%;
  }

  iframe {
    max-width: 100%;
  }

  ul {
    padding-left: 20px;
  }

  ol {
    padding-left: 20px;
  }
`;

const ListItemContainer = styled.div`
  padding: 10px 10px 50px 10px;
  border: 1px solid #f0f0f0;
  border-color: transparent;
  background-color: #fafafa;
  border-radius: 10px;
  position: relative;
`;

const ListItemContainerHistory = styled.div`
  padding: 0px 0px 20px 0px;
  border-color: 'transparent'
  background-color: 'transparent';
  border-radius: 10px;
  position: relative;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  border-radius: 0;
`;

const ListItemTitle = styled.div`
  display: flex;
  align-items: basline;
  font-weight: bold;
  margin-bottom: 10px;
`;

const ListItemDate = styled.span`
  display: inline-block;
  margin-left: auto;
  font-weight: normal;
  opcaity: 0.4;
  font-size: 12px;
`;

const ActionContainer = styled.div`
  font-weight: bold;
  position: absolute;
  right: 8px;
  bottom: 8px;
`;

const ListItemBody = styled.div``;

const EmptyMessageWrapper = styled.div`
  position: absolute;
  min-width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EmptyMessage = styled.p`
  margin: 15px 0 0 0;
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
`;

const EmptyLink = styled.div`
  margin: 15px 0 0 0;
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const StyledLink = styled.a`
  margin: 15px 0 0 0;
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
`;

Notifications.propTypes = {
  onClose: func.isRequired,
};

export default Notifications;
