import { I18n } from 'react-redux-i18n';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/sidebar-edit-project.svg';

import * as actions from '../actionsList';

const assessmentsBulkActions = [
  {
    name: actions.EDIT_CUSTOM_BATTERY,
    label: I18n.t('Edit battery'),
    Icon: EditIcon,
    isDisabled: (assessments) => {
      return (
        assessments.length > 1 ||
        assessments.find((item) => !item.batteryID || item.usesDisabledTest || item.batteryID < 1000000)
      );
    },
  },
  {
    name: actions.DELETE_CUSTOM_BATTERY,
    label: I18n.t('Delete battery'),
    Icon: DeleteIcon,
    isDisabled: (assessments) => {
      return assessments.find((item) => !item.batteryID || item.batteryID < 1000000);
    },
  },
];

export default assessmentsBulkActions;
