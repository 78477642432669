import React from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { addYears, startOfTomorrow } from 'date-fns';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';

import { errorMessages } from '../../../../constants/errorMessages';

import ProjectModalFooter from '../ProjectModalFooter';
import CustomButton from '../../../reusable/Buttons/Button';
import CustomSelect from '../../../reusable/Selects/Select';
import CustomInput from '../../../reusable/FormComponents/Input';
import DateTimeSelect from '../../../reusable/Selects/DateTimeSelect';
import DirectFeedbackReport from '../../../reusable/DirectFeedbackReport';
import Spinner from '../../../reusable/Spinner';
import { ReactComponent as ArrowRight } from '../../../../assets/icons/arrow-right-thin.svg';
import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning-blue.svg';

import { sendingOptions, reminderOptions } from '../../AssessmentProject/components/options';

import { saveProjectInvitesData } from '../../../../store/projects/actions';
import { selectWelcomeTextsOptions } from '../../../../store/settings/welcome-texts/selectors';
import { selectTemplatesAsOptions } from '../../../../store/settings/templates/selectors';

const InvitesStep = ({ goPrev, goNext }) => {
  const initialData = useSelector((state) => state.projects.createAssessmentProject.invites);
  const assessmentData = useSelector((state) => state.projects.createAssessmentProject.assessment);
  const dispatch = useDispatch();

  const welcomeTexts = useSelector(selectWelcomeTextsOptions);
  const templates = useSelector(selectTemplatesAsOptions);

  const { values, setFieldValue, handleSubmit, errors, setFieldError, handleChange } = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      template: initialData.template || (templates.length && templates[0]),
      welcomeText: initialData.welcomeText || (welcomeTexts.length && welcomeTexts[0]),
      expireAfter: initialData.expireAfter || 30,
      reminderType: initialData.reminderType || reminderOptions.find((item) => item.value === 'weekly'),
      sendingType: initialData.sendingType || sendingOptions.find((item) => item.value === 'manually'),
      invitationDate: initialData.invitationDate,
      isDirectReport: initialData.isDirectReport,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      template: Yup.object()
        .nullable()
        .required(errorMessages.inputField),
      welcomeText: Yup.object()
        .nullable()
        .required(errorMessages.inputField),
      expireAfter: Yup.number()
        .min(1, "Can't be lower than 1")
        .max(365, "Can't later than 1 year from now")
        .required(errorMessages.inputField),
      reminderType: Yup.object()
        .nullable()
        .required(errorMessages.inputField),
      sendingType: Yup.object()
        .nullable()
        .required(errorMessages.inputField),
      invitationDate: Yup.string().required(errorMessages.inputField),
      isDirectReport: Yup.bool(),
    }),
    onSubmit: (values) => {
      dispatch(saveProjectInvitesData(values));
      goNext();
    },
  });

  const onSelectChange = (name) => (value) => {
    setFieldValue(name, value);
    setFieldError(name, '');
  };

  const onInputChange = (name) => (e) => {
    handleChange(e);
    setFieldError(name, '');
  };

  const toggleDirectReportStatus = () => setFieldValue('isDirectReport', !values.isDirectReport);

  const isDirectReportAvailable = Boolean(
    assessmentData.assessment.directFeedbackReportLanguagesAvailable?.includes(assessmentData.language.code),
  );

  const isLoading = !welcomeTexts.length || !templates.length;

  return (
    <Container>
      <Spinner isLoading={isLoading} />
      <Form>
        <Title>{I18n.t('Invites')}</Title>
        <Row>
          <StyledSelect
            options={templates}
            placeholder={I18n.t('Email template')}
            selectProps={{ value: values.template, onChange: onSelectChange('template') }}
            error={errors.template}
          />
          <StyledSelect
            options={welcomeTexts}
            placeholder={I18n.t('Welcome text')}
            selectProps={{ value: values.welcomeText, onChange: onSelectChange('welcomeText') }}
            error={errors.welcomeText}
          />
        </Row>
        <Row>
          <ExpireContainer>
            <ExpireInput
              id="expireAfter"
              inputName={I18n.t('Expire after')}
              value={values.expireAfter}
              onChange={onInputChange('expireAfter')}
              error={errors.expireAfter}
              type="number"
            />
            <DaysLabel>{I18n.t('days')}</DaysLabel>
          </ExpireContainer>
          <StyledSelect
            options={reminderOptions}
            placeholder={I18n.t('Remind')}
            selectProps={{ value: values.reminderType, onChange: onSelectChange('reminderType') }}
            error={errors.reminderType}
          />
        </Row>
        <Row>
          <StyledSelect
            options={sendingOptions}
            placeholder={I18n.t('How to send invites')}
            selectProps={{ value: values.sendingType, onChange: onSelectChange('sendingType') }}
            error={errors.sendingType}
          />
          {values.sendingType?.value === 'schedule' && (
            <DateTimeSelect
              inputName={I18n.t('Send invites on')}
              date={values.invitationDate}
              onDateChange={(date) => setFieldValue('invitationDate', date)}
              inputWidth="34rem"
              minDate={startOfTomorrow()}
              maxDate={addYears(new Date(), 1)}
            />
          )}
        </Row>

        {values.sendingType && (
          <InfoContainer>
            <StyledWarningIcon />
            <span>{values.sendingType.description}</span>
          </InfoContainer>
        )}
        {isDirectReportAvailable && (
          <>
            <Title>{I18n.t('Direct feedback report')}</Title>
            <DirectFeedbackReport onChange={toggleDirectReportStatus} isChecked={values.isDirectReport} />
          </>
        )}
      </Form>
      <Footer>
        <Button handler={goPrev} variant="secondary">
          <ArrowLeft />
          {I18n.t('Back')}
        </Button>
        <Button handler={handleSubmit}>
          {I18n.t('Next')} <ArrowRight />
        </Button>
      </Footer>
    </Container>
  );
};

InvitesStep.propTypes = {
  goPrev: func.isRequired,
  goNext: func.isRequired,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Title = styled.h3`
  color: ${(props) => props.theme.colors.mediumBlue};
  font-size: ${(props) => props.theme.fontSizes.normal};
  font-weight: 600;
  margin-bottom: 1.6rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0 2.4rem;
  flex-grow: 1;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const StyledSelect = styled(CustomSelect)`
  width: 34rem;
  margin-bottom: 0;
`;

const ExpireContainer = styled.div`
  position: relative;
`;

const ExpireInput = styled(CustomInput)`
  & > div {
    max-height: 4.2rem;
    height: 4.2rem;
  }
  input {
    padding-right: 4rem;
  }
`;

export const DaysLabel = styled.span`
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: ${(props) => props.theme.fontSizes.small};
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  background-color: #e0f1f7;
  padding: 1.8rem;
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;
  color: ${(props) => props.theme.colors.darkBlue2};

  span {
    margin-left: 1rem;
  }
`;

const Footer = styled(ProjectModalFooter)`
  justify-content: space-between;
`;

const ArrowLeft = styled(ArrowRight)`
  transform: rotate(180deg);
  path {
    fill: ${(props) => props.theme.colors.lightBlue};
  }
`;

const Button = styled(CustomButton)`
  text-transform: uppercase;
  width: 12rem;
  justify-content: space-evenly;
`;

export const StyledWarningIcon = styled(WarningIcon)`
  min-width: 2rem;
  min-height: 2rem;
`;

export default InvitesStep;
