import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as EmptyTableIcon } from '../../assets/icons/empty-state-grey.svg';

const TableEmptyState = ({ title, message, className }) => {
  return (
    <EmptyMessageTableWrapper className={className}>
      <EmptyTableIcon />
      <NoRespondentsMessage>
        <div>{title}</div>
        <div>{message}</div>
      </NoRespondentsMessage>
    </EmptyMessageTableWrapper>
  );
};

TableEmptyState.propTypes = {
  title: string.isRequired,
  message: string,
  className: string,
};

TableEmptyState.defaultProps = {
  message: '',
  className: '',
};

const EmptyMessageTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
`;
const NoRespondentsMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div:first-child {
    color: rgba(0, 0, 0, 0.4);
    font-size: 2rem;
    font-weight: bold;
    margin: 1.6rem 0;
  }

  div:last-child {
    color: rgba(0, 0, 0, 0.4);
    font-size: ${(props) => props.theme.fontSizes.normal};
    line-height: 2.4rem;
    font-weight: 400;
  }
`;

export default TableEmptyState;
