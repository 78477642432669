import { I18n } from 'react-redux-i18n';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/sidebar-edit-project.svg';

import * as actions from '../actionsList';

const templatesBulkActions = [
  {
    name: actions.EDIT_TEMPLATE,
    label: I18n.t('Go to template details'),
    Icon: EditIcon,
    isDisabled: (templates) => templates.length > 1,
  },
  {
    name: actions.DELETE_TEMPLATES,
    label: I18n.t('Delete selected template(s)'),
    Icon: DeleteIcon,
  },
];

export default templatesBulkActions;
