import { format } from 'date-fns';
import { I18n } from 'react-redux-i18n';

const columns = [
  {
    Header: I18n.t('Creation Date'),
    accessor: (data) => format(new Date(data.dateOfUpdate), 'dd/M/y'),
    name: 'dateOfUpdate',
    disableSortBy: true,
  },
  {
    Header: I18n.t('User name'),
    accessor: 'userName',
    name: 'userName',
    disableSortBy: true,
  },
  {
    Header: I18n.t('Update amount'),
    accessor: 'amountRequested',
    name: 'amountRequested',
    disableSortBy: true,
  },
  {
    Header: I18n.t('Purchase ID'),
    accessor: 'updateID',
    name: 'updateID',
    disableSortBy: true,
  },
];

export default columns;
