import { I18n } from 'react-redux-i18n';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/sidebar-edit-project.svg';
import { ReactComponent as DuplicateIcon } from '../../assets/icons/duplicate.svg';

import * as actions from '../actionsList';

const competencyGroupsBulkActions = [
  {
    name: actions.EDIT_COMPETENCY_ITEM,
    label: I18n.t('Edit 360 survey'),
    Icon: EditIcon,
    // isDisabled: (items) => items.length > 1,
    isDisabled: true,
  },
  {
    name: actions.DUPLICATE_COMPETENCY,
    label: I18n.t('Duplicate 360 survey'),
    Icon: DuplicateIcon,
    isDisabled: (items) => items.length > 1,
  },
  {
    name: actions.DELETE_COMPETENCY_GROUPS,
    label: I18n.t('Delete selected 360 survey(s)'),
    Icon: DeleteIcon,
  },
];

export default competencyGroupsBulkActions;
