import React from 'react';
import { func, array, string } from 'prop-types';
import styled from 'styled-components';
import { format, addDays, addWeeks, addMonths, addYears } from 'date-fns';
import { I18n } from 'react-redux-i18n';

import { DATE_FILTER_TYPES } from '../../../../constants/advancedFilterOptions';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';

const formatStr = 'dd/MM/Y';

// TODO refactor
const createDateRange = (startDate, selectObj) => {
  let endDate;
  switch (selectObj.value) {
    case DATE_FILTER_TYPES.ONE_DAY:
      endDate = addDays(startDate, 1);
      break;
    case DATE_FILTER_TYPES.THREE_DAYS:
      endDate = addDays(startDate, 3);
      break;
    case DATE_FILTER_TYPES.ONE_WEEK:
      endDate = addWeeks(startDate, 1);
      break;
    case DATE_FILTER_TYPES.TWO_WEEKS:
      endDate = addWeeks(startDate, 2);
      break;
    case DATE_FILTER_TYPES.ONE_MONTH:
      endDate = addMonths(startDate, 1);
      break;
    case DATE_FILTER_TYPES.ONE_YEAR:
      endDate = addYears(startDate, 1);
      break;
    default:
      endDate = addDays(startDate, 3);
  }
  return `${format(startDate, formatStr)} - ${format(endDate, formatStr)}`;
};

export const checkIfAppliedEmpty = (appliedInputs) => {
  let empty = true;
  for (let i = 0; i < appliedInputs.length; i += 1) {
    const item = appliedInputs[i];
    if (item.value) {
      empty = false;
      break;
    }
  }
  return empty;
};

const SearchContainer = ({ className, appliedFilters, deleteFilter, searchValue, onSearchChange }) => {
  const areAppliedEmpty = checkIfAppliedEmpty(appliedFilters);
  return (
    <AppliedFiltersContainer className={className}>
      <StyledSearchIcon />
      {!areAppliedEmpty && (
        <>
          {appliedFilters.map((input) => {
            if (!input.value) return null;
            if (input.type === 'date') {
              const range = createDateRange(input.value, input.filterType);
              return (
                <AppliedFilterItem key={input.name}>
                  <span>{I18n.t(input.columnName)}:</span>
                  <span>{range}</span>
                  <StyledCloseIcon onClick={() => deleteFilter(input.name)} />
                </AppliedFilterItem>
              );
            }
            if (input.type === 'select') {
              return (
                <AppliedFilterItem key={input.name}>
                  <span>{I18n.t(input.uiSelectName || input.columnName)}:</span>
                  <span>{I18n.t(input.value.label)}</span>
                  <StyledCloseIcon onClick={() => deleteFilter(input.name)} />
                </AppliedFilterItem>
              );
            }
            return (
              <AppliedFilterItem key={input.name}>
                <span>{I18n.t(input.filteringUiName || input.columnName)}</span>
                <span>
                  {I18n.t(input.filterType.label.toLowerCase())}: {input.value}
                </span>
                <StyledCloseIcon onClick={() => deleteFilter(input.name)} />
              </AppliedFilterItem>
            );
          })}
        </>
      )}
      <SearchInput
        placeholder={I18n.t('Search')}
        value={searchValue}
        onChange={(e) => onSearchChange(e.target.value)}
      />
    </AppliedFiltersContainer>
  );
};

SearchContainer.propTypes = {
  appliedFilters: array,
  deleteFilter: func,
  className: string,
  onSearchChange: func.isRequired,
  searchValue: string,
};

SearchContainer.defaultProps = {
  appliedFilters: [],
  deleteFilter: undefined,
  className: '',
  searchValue: '',
};

const AppliedFiltersContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid ${(props) => props.theme.colors.grey2};
  border-radius: 4px;
  padding: 0.5rem 1rem;

  :hover {
    border-color: ${(props) => props.theme.colors.lightBlue};
  }
`;

const SearchInput = styled.input`
  min-width: 15rem;
  flex-grow: 1;
  border: none;
  outline: none;
  height: 2.4rem;
  font-size: ${(props) => props.theme.fontSizes.small};
  color: ${(props) => props.theme.colors.primary};
  font-weight: 500;

  ::placeholder {
    font-weight: 600;
    color: ${(props) => props.theme.colors.grey5};
  }
`;

const AppliedFilterItem = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.lightBlue};
  padding: 0.3rem 0.5rem;
  color: ${(props) => props.theme.colors.primary};
  font-size: 1.2rem;
  border-radius: 0.4rem;
  margin: 0 1rem 0 0;
  color: ${(props) => props.theme.colors.white};

  span:first-child {
    margin-right: 0.4rem;
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  margin-left: 1rem;
  transition: all 0.2s;
  :hover {
    cursor: pointer;
    transform: scale(1.05);
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  margin-right: 10px;
`;

export default SearchContainer;
