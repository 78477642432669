import { I18n } from 'react-redux-i18n';
import { CREATE_GROUP } from '../actionsList';

const groupsTableActions = [
  {
    name: CREATE_GROUP,
    label: I18n.t('Add new group'),
  },
];

export default groupsTableActions;
