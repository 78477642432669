import { useEffect } from 'react';
import { string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { replace } from 'redux-first-history';

import { loginSuccess } from '../store/user/actions';

import storageService from '../services/storageService';

const TokenAutoAuth = ({ accessToken, refreshToken }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (accessToken && refreshToken) {
      storageService.setItem('accessToken', accessToken);
      storageService.setItem('refreshToken', refreshToken);
      dispatch(loginSuccess());
      dispatch(replace('/respondents'));
    }
    if (!accessToken || !refreshToken) dispatch(replace('/login'));
  }, []);
  return null;
};

TokenAutoAuth.propTypes = {
  accessToken: string,
  refreshToken: string,
};

TokenAutoAuth.defaultProps = {
  accessToken: '',
  refreshToken: '',
};

export default TokenAutoAuth;
