import { I18n } from 'react-redux-i18n';
import * as actions from '../actionsList';

const groupsDetailsDropdownActions = [
  {
    name: actions.EDIT_TITLE_COMPETENCY_GROUPS_DETAILS,
    label: I18n.t('Edit title'),
  },
  {
    name: actions.DUPLICATE_COMPETENCY_GROUPS_DETAILS,
    label: I18n.t('Duplicate 360 survey'),
  },
  {
    name: actions.DELETE_COMPETENCY_GROUPS_DETAILS,
    label: I18n.t('Delete 360 survey'),
  },
];

export default groupsDetailsDropdownActions;
