import { I18n } from 'react-redux-i18n';

const columns = [
  {
    Header: I18n.t('Assessment name'),
    accessor: 'testID',
    name: 'assessmentName',
    disableSortBy: true,
  },
  {
    Header: I18n.t('Usage count'),
    accessor: 'usage',
    name: 'usage',
    disableSortBy: true,
  },
];

export default columns;
