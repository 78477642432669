import handleActions from '../immerHandleActions';
import routesList from './initialState';

const initialState = {
  list: routesList,
};

const routesReducer = handleActions({}, initialState);

export default routesReducer;
