const theme = {
  colors: {
    // NEW
    primary: '#375563',
    darkBlue: '#07668A',
    lightBlueHover1: '#B7EBFF',
    lightBlue2: '#40C4FF',
    white: '#FFFFFF',
    grey1: '#F9F9F9',
    grey2: '#CDCDCD',
    grey3: '#ADADAD',
    grey4: '#828282',
    grey5: '#5B5B5B',
    grey6: '#F2F2F2',
    red: '#FF574C',
    green: '#27AE60',
    error: '#B00020',
    black: '#000',
    almostBlack: 'rgba(0,0,0,0.88)',
    halfBlack: 'rgba(0,0,0,0.6)',
    midGrey: 'rgb(55, 85, 99, 0.95)',

    // OLD
    secondary: '#1285B1',
    blue: '#40C4FF',
    text: '#A2ABAB',
    grey: '#E4E6E6',
    bgGrey: '#F1F1F1',
    tdBorder: '#00000012',
    background: '#FEFCFC',
    dark: '#295368',
    yellow: '#FFEC40',
    paleRed: '#FE6C6C',

    // PSYTECH
    menuBg: '#17A9E1',
    mediumBlue: '#008AC0',
    lightBlue: '#17A9E1',
    lightBlue1: '#DFF6FF',
    lightBlueHover: '#3DB7E6',
    darkBlue2: '#295368',
  },
  fontSizes: {
    small: '1.2rem',
    default: '1.4rem',
    normal: '1.6rem',
    medium: '1.8rem',
    large: '2.4rem',
  },
  shadows: {
    default: '0 8px 30px rgba(55, 85, 99, 0.3)',
    tableRow: '0 0 20px rgba(0, 0, 0, 0.18)',
  },
};

export default theme;
