import handleActions from '../immerHandleActions';
import * as actions from './actions';
import ratersColumns from '../../table/columns/raters';
import {
  DATE_FILTER_TYPES,
  datesFilterOptions,
  NUMBER_FILTER_TYPES,
  numberFilterOptions,
  TEXT_FILTER_TYPES,
  textFilterOptions,
} from '../../constants/advancedFilterOptions';

const getInitialFilterValue = (type) => {
  if (type === 'number') return numberFilterOptions.find((item) => item.name === NUMBER_FILTER_TYPES.IS_EQUAL);
  if (type === 'date') return datesFilterOptions.find((item) => item.name === DATE_FILTER_TYPES.THREE_DAYS);
  return textFilterOptions.find((item) => item.name === TEXT_FILTER_TYPES.CONTAINS);
};

const RATERS_FILTER_FIELDS = ratersColumns()
  .filter((item) => item.name !== 'actions')
  .filter((item) => !item.disableFilterBy)
  .map((column) => ({
    name: column.name,
    type: column.type,
    ...(column.options && { options: column.options }),
  }));

const initialInputsState = RATERS_FILTER_FIELDS.map((fieldObj) => {
  return {
    ...fieldObj,
    name: fieldObj.name,
    value: fieldObj.type === 'date' ? null : '',
    type: fieldObj.type,
    options: fieldObj.type === 'select' && fieldObj.options,
    filterType: getInitialFilterValue(fieldObj.type),
  };
});

const initialFilters = {
  isApplied: false,
  dirty: false,
  appliedInputs: initialInputsState,
  inputs: initialInputsState,
};

const initialState = {
  raters: [],
  selectedRaters: [],
  tabs: ['all', 'manager', 'peer', 'directReport', 'other'],
  activeTab: 0,
  search: '',
  pagesAvailable: 0,
  totalCount: 0,
  lastPageIndex: null,
  filter: initialFilters,
};

const ratersReducer = handleActions(
  {
    // FILTERS PART
    [actions.setRatersSearchValue]: (draft, { payload: { searchValue } }) => {
      draft.search = searchValue;
    },
    [actions.setRatersFilterValue]: (draft, { payload: { fieldName, value } }) => {
      draft.filter.inputs = draft.filter.inputs.map((item) => (item.name === fieldName ? { ...item, value } : item));
      draft.filter.dirty = true;
    },
    [actions.setRatersFilterType]: (draft, { payload: { fieldName, filterType } }) => {
      const initValue = fieldName.toLowerCase().includes('date')
        ? datesFilterOptions.find((item) => item.name === DATE_FILTER_TYPES.THREE_DAYS)
        : textFilterOptions.find((item) => item.name === TEXT_FILTER_TYPES.CONTAINS);
      draft.filter.inputs = draft.filter.inputs.map((item) =>
        item.name === fieldName ? { ...item, filterType: filterType === 'initial' ? initValue : filterType } : item,
      );
      draft.filter.dirty = true;
    },
    [actions.applyRatersFilters]: (draft) => {
      draft.filter.dirty = false;
      draft.filter.isApplied = true;
      draft.filter.appliedInputs = draft.filter.inputs;
    },
    [actions.clearRatersFilters]: (draft) => {
      draft.filter.dirty = true;
      draft.filter.inputs = initialInputsState;
    },
    [actions.deleteRatersAppliedFilter]: (draft, { payload: { fieldName } }) => {
      draft.filter.appliedInputs = draft.filter.appliedInputs.map((input) =>
        input.name === fieldName ? { ...input, value: input.type === 'date' ? null : '' } : input,
      );
      draft.filter.inputs = draft.filter.inputs.map((input) =>
        input.name === fieldName ? { ...input, value: input.type === 'date' ? null : '' } : input,
      );
    },
    [actions.resetRatersTableState]: (draft) => {
      draft.search = '';
      draft.filter = { ...initialFilters };
      draft.shouldResetTableState = true;
    },
    [actions.setActiveRatersTab]: (draft, { payload: { tab } }) => {
      draft.activeTab = tab;
    },
    [actions.fetchRatersSuccess]: (draft, { payload: { data, shouldReset } }) => {
      draft.raters = shouldReset ? data.data : [...draft.raters, ...data.data];
      draft.totalCount = data.totalCount;
      draft.pagesAvailable = data.pagesAvailable;
      draft.lastPageIndex = data.pageNumber;
    },
    [actions.setSelectedRaters]: (draft, { payload: { raters } }) => {
      draft.selectedRaters = raters;
    },
    [actions.deleteRatersSuccess]: (draft, { payload: { ratersIds } }) => {
      draft.raters = draft.raters.filter((item) => !ratersIds.includes(item.raterID));
      draft.selectedRaters = [];
    },
  },
  initialState,
);

export default ratersReducer;
