import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { func, object } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { addMonths } from 'date-fns';
import styled, { css } from 'styled-components';
import { I18n } from 'react-redux-i18n';
import Modal from '../../reusable/NewModal';
import Steps from '../../reusable/Steps';
import CustomButton from '../../reusable/Buttons/Button';

import IntroStep from './IntroStep';
import AssessmentStep from '../../Respondents/CreateInvite/AssessmentStep';
import GroupsInvitationStep from './InvitationStep';
import MissingEmailsStep from './MissingEmailsStep';
import { findLanguage, findAssessment } from '../../Respondents/CreateInvite';

import useStep from '../../../hooks/useStep';
import { selectAvailableLanguages, selectAvailableMappedBiodataTemplates } from '../../../store/user/selectors';
import { selectUnsupervisedAssessments, selectCategories } from '../../../store/tests/selectors';
import { createInvite, sendInvite } from '../../../store/invites/actions';
import { selectWelcomeTextsOptions } from '../../../store/settings/welcome-texts/selectors';
import { resolveAssessmentName } from '../../../utils/assessmentsNames';
import { sendInviteOptions } from '../../Respondents/CreateInvite/InvitationStep';
import { selectTemplatesAsOptions } from '../../../store/settings/templates/selectors';

const GroupInvite = ({ onClose, group, successCallback }) => {
  const user = useSelector((state) => state.user.user);
  const groupedAssessments = useSelector(selectUnsupervisedAssessments);
  const categories = useSelector(selectCategories);
  const [activeCategory, setActiveCategory] = useState(categories.length ? categories[0] : null);
  const [assessments, setAssessments] = useState(groupedAssessments ? groupedAssessments[activeCategory] : []);
  const languages = useSelector(selectAvailableLanguages);
  const welcomeTexts = useSelector(selectWelcomeTextsOptions);
  const dispatch = useDispatch();
  const steps = ['intro', 'assessment', 'invitation', 'missing emails'];
  const { step, goNext, resetSteps } = useStep({
    steps,
    initialStep: group.respondentsWithMissingEmailsCount === 0 ? 1 : 0,
  });

  const availableBiodataTemplates = useSelector(selectAvailableMappedBiodataTemplates);

  // TODO rewrite with useReducer

  // Assessment step
  const [welcomeText, setWelcomeText] = useState(welcomeTexts[0]);
  const [language, setLanguage] = useState(findLanguage(languages, user && user.distributorDefaultLanguageID));
  const [assessment, setAssessment] = useState(findAssessment(assessments, language));
  const [expiryDate, setExpiryDate] = useState(addMonths(new Date(), 1));
  const [isLoading, setLoadingState] = useState(false);
  const [createdInvites, setCreatedInvites] = useState([]);
  const hasDefaultTemplate = availableBiodataTemplates.find(
    (template) => template.isDefault === true && template.value !== 0,
  );
  const [biodataTemplate, setBiodataTemplate] = useState(hasDefaultTemplate || availableBiodataTemplates[0]);

  // Invitation step
  const templates = useSelector(selectTemplatesAsOptions);
  const [template, setTemplate] = useState(templates[0]);
  const [invitationType, setInvitationType] = useState(sendInviteOptions[0]);
  const [invitationDate, setInvitationDate] = useState(null);

  useEffect(() => {
    setLanguage(findLanguage(languages, user && user.distributorDefaultLanguageID));
  }, [languages.length]);

  useEffect(() => {
    if (groupedAssessments) {
      const assessments = groupedAssessments[activeCategory];
      setAssessments(assessments);
      setAssessment(findAssessment(assessments, language));
    }
  }, [groupedAssessments, activeCategory, language]);

  useEffect(() => {
    if (categories.length) {
      setActiveCategory(categories[0]);
    }
  }, [categories]);

  const onValueChange = (name, value) => {
    if (name === 'language') setLanguage(value);
    if (name === 'assessment') setAssessment(value);
    if (name === 'expiryDate') setExpiryDate(value);
    if (name === 'welcomeText') setWelcomeText(value);
    if (name === 'category') setActiveCategory(value);
    if (name === 'template') setTemplate(value);
    if (name === 'invitationType') setInvitationType(value);
    if (name === 'invitationDate') setInvitationDate(value);
    if (name === 'biodataTemplate') setBiodataTemplate(value);
  };

  const closeModal = () => {
    onClose();
    setTimeout(() => {
      resetSteps();
      setLanguage(findLanguage(languages, user && user.distributorDefaultLanguageID));
      setActiveCategory(categories.length ? categories[0] : null);
      setAssessment(findAssessment(groupedAssessments[categories[0]], language));
      setExpiryDate(addMonths(new Date(), 1));
      setCreatedInvites([]);
      setWelcomeText(welcomeTexts[0]);
      setTemplate(templates[0]);
      setInvitationType(sendInviteOptions[0]);
      setInvitationDate(null);
    }, 300);
  };

  const isDirectReportAvailable = Boolean(assessment?.directFeedbackReportLanguagesAvailable?.includes(language?.code));

  const [isDirectReportChecked, setDirectReportStatus] = useState(false);
  const toggleDirectReportStatus = () => setDirectReportStatus((prev) => !prev);

  const onCreateInvite = () => {
    const data = {
      groupId: group.groupID,
      testID: assessment.testID ? assessment.testID : null,
      batteryID: assessment.batteryID ? assessment.batteryID : null,
      langID: language.code,
      expiryDate: expiryDate.toISOString(),
      welcomeTextTID: welcomeText.value,
      sendDirectFeedbackReport: isDirectReportChecked,
      biodatatemplateid: biodataTemplate.value,
    };
    setLoadingState(true);
    dispatch(
      createInvite(data, (invites, err) => {
        if (err) return;
        if (successCallback) successCallback();
        setCreatedInvites(invites);
        goNext();
        setLoadingState(false);
      }),
    );
  };

  const onSendInvite = () => {
    const data = {
      inviteType: 0,
      inviteIDs: createdInvites.map((item) => item.inviteID),
      sendInviteDate: invitationType.value === 'immediately' ? null : invitationDate,
      emailTID: template && template.value,
    };
    dispatch(
      sendInvite(data, () => {
        if (group.respondentsWithMissingEmailsCount > 0) goNext();
        else closeModal();
      }),
    );
  };

  const renderStep = (step) => {
    switch (step) {
      case 'intro':
        return <IntroStep totalCount={group.groupSize} missingEmailCount={group.respondentsWithMissingEmailsCount} />;
      case 'assessment':
        return (
          <AssessmentStep
            assessments={assessments}
            activeCategory={activeCategory}
            categories={categories}
            languages={languages}
            language={language}
            assessment={
              assessment && {
                ...assessment,
                label: resolveAssessmentName(assessment),
                value: assessment.batteryID ? assessment.batteryID : assessment.testID,
              }
            }
            onValueChange={onValueChange}
            expiryDate={expiryDate}
            welcomeText={welcomeText}
            welcomeTexts={welcomeTexts}
            biodataTemplate={biodataTemplate}
            biodataTemplates={availableBiodataTemplates}
            isVisible
            isDirectReportAvailable={isDirectReportAvailable}
            isDirectReportChecked={isDirectReportChecked}
            toggleDirectReportStatus={toggleDirectReportStatus}
          />
        );
      case 'invitation':
        return (
          <GroupsInvitationStep
            respondentsCount={group.groupSize}
            respondentsWithoutEmailsCount={group.respondentsWithMissingEmailsCount}
            templates={templates}
            template={template}
            invitationType={invitationType}
            invitationDate={invitationDate}
            onValueChange={onValueChange}
          />
        );
      case 'missing emails':
        return <MissingEmailsStep createdInvites={createdInvites} />;
      default:
        return null;
    }
  };

  const resolveButtonText = (step) => {
    switch (step) {
      case 'intro':
        return I18n.t('Ok, go next');
      case 'assessment':
        return I18n.t('Create invite and go next');
      case 'invitation':
        return I18n.t('Email invites');
      default:
        return I18n.t('Finish');
    }
  };

  const isNextButtonDisabled = useMemo(() => {
    const currentStep = steps[step];
    if (currentStep === 'assessment') return !assessment || !expiryDate;
    if (currentStep === 'invitation') return invitationType.value === 'later' && !invitationDate;
    return false;
  }, [step, assessment, steps]);

  const onNextButtonClick = useCallback(() => {
    const currentStep = steps[step];
    if (currentStep === 'intro') goNext();
    if (currentStep === 'assessment') onCreateInvite();
    if (currentStep === 'invitation') {
      if (group.respondentsWithMissingEmailsCount === group.groupSize) goNext();
      else onSendInvite();
    }
    if (currentStep === 'missing emails') closeModal();
  }, [step, assessment, steps, group]);

  const showTestInfo = (steps[step] === 'assessment' || steps[step] === 'invitation') && assessment;
  return (
    <StyledModal
      isVisible
      onClose={closeModal}
      title={`${I18n.t('Invite respondents')} (${group.groupSize})`}
      isTitleHidden={steps[step] === 'intro'}
    >
      {steps[step] !== 'intro' && <Steps steps={['', ...steps.slice(1)]} stepIndex={step} />}
      <Content>{renderStep(steps[step])}</Content>
      <Footer>
        {showTestInfo ? (
          <FooterInfo>
            <span>{I18n.t('You are sending an invitation for:')}</span>
            <span>
              {assessment.testID || assessment.title} {I18n.t('in')} {language?.label}
            </span>
          </FooterInfo>
        ) : null}
        <Button handler={onNextButtonClick} disabled={isNextButtonDisabled || isLoading}>
          {resolveButtonText(steps[step])}
        </Button>
      </Footer>
    </StyledModal>
  );
};

GroupInvite.propTypes = {
  onClose: func.isRequired,
  group: object.isRequired,
  successCallback: func,
};

GroupInvite.defaultProps = {
  successCallback: undefined,
};

const StyledModal = styled(Modal)`
  width: 110rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem;

  ${(props) =>
    props.isTitleHidden &&
    css`
      width: 57rem;
      height: 23rem;
    `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100% - 200px);
  overflow: auto;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.showTestInfo ? 'space-between' : 'flex-end')};
  position: relative;
`;

const FooterInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  span {
    font-size: 1.4rem;
  }
  span:first-child {
    font-weight: 500;
  }
  span:last-child {
    font-weight: bold;
  }
`;

const Button = styled(CustomButton)`
  text-transform: uppercase;
`;

export default GroupInvite;
