import React, { useRef } from 'react';
import { func, bool, string, number, oneOfType } from 'prop-types';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';

import useCombinedRefs from '../../../hooks/useCombinedRefs';

const CustomInputWithForwardedRef = React.forwardRef(
  ({ inputName, value, onChange, error, disabled, inputWidth, className, onContainerClick, name, ...rest }, ref) => {
    const inputRef = useRef(null);
    const combinedRef = useCombinedRefs(ref, inputRef);
    const onContainerFocus = () => {
      inputRef.current.focus();
    };
    return (
      <Container
        className={className}
        onFocus={onContainerFocus}
        tabIndex="-1"
        onClick={onContainerClick}
        inputWidth={inputWidth}
      >
        <InputWrapper error={error} disabled={disabled}>
          {(value || value === 0) && <AbsoluteLabel>{inputName}</AbsoluteLabel>}
          <Input
            ref={combinedRef}
            value={value}
            onChange={onChange}
            placeholder={I18n.t(inputName)}
            disabled={disabled}
            readOnly={!onChange}
            {...rest}
          />
        </InputWrapper>
        <ErrorMsg>{error}</ErrorMsg>
      </Container>
    );
  },
);

CustomInputWithForwardedRef.propTypes = {
  inputName: string.isRequired,
  name: string,
  value: oneOfType([string, number]),
  onChange: func,
  disabled: bool,
  error: string,
  className: string,
  onContainerClick: func,
  inputWidth: string,
};

CustomInputWithForwardedRef.defaultProps = {
  disabled: false,
  error: '',
  className: '',
  onChange: undefined,
  onContainerClick: undefined,
  value: '',
  name: '',
  inputWidth: '',
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${(props) => props.inputWidth || '34rem'};
`;

const InputWrapper = styled.div`
  position: relative;
  height: 4rem;
  min-height: 4rem;
  max-height: 4rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  border: 1px solid ${(props) => (props.error ? props.theme.colors.red : props.theme.colors.grey2)};
  border-radius: 0.4rem;
  padding: 0 1.2rem;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  &:focus-within {
    border-color: ${(props) => props.theme.colors.lightBlue};
  }

  :hover {
    border-color: ${(props) => props.theme.colors.lightBlue};
  }
`;

const Input = styled.input`
  font-size: 1.4rem;
  font-weight: 500;
  border: none;
  outline: none;
  color: ${(props) => props.theme.colors.darkBlue2};

  ::placeholder {
    color: ${(props) => props.theme.colors.grey5};
  }

  // remove number type arrows
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const AbsoluteLabel = styled.span`
  color: ${(props) => props.theme.colors.mediumBlue};
  font-size: 1rem;
  font-weight: 600;
`;

const ErrorMsg = styled.span`
  position: absolute;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.red};
  font-weight: 600;
  margin-top: 0.5rem;
  min-height: 1.2rem;
  bottom: -1.5rem;
  left: 0;
`;

export default CustomInputWithForwardedRef;
