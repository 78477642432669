import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { I18n } from 'react-redux-i18n';
import apiInstance2 from '../../../services/apiService';
import { selectAvailableLanguages } from '../../../store/user/selectors';
import AddScaleForm from './AddScaleForm';
import Spinner from '../../reusable/Spinner';
import { errorMessages } from '../../../constants/errorMessages';
import createToastNotification from '../../../utils/createToastNotification';

const RESPONSES_COUNT = 2;

const AddResponseScale = ({ responseId }) => {
  const dispatch = useDispatch();

  const languages = useSelector(selectAvailableLanguages);
  const [isLoading, setIsLoading] = useState(!!responseId);

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required(errorMessages.inputField)
      .max(100, errorMessages.tooLong(100)),
    responsesArray: Yup.array().of(
      Yup.object().shape({
        lang: Yup.object()
          .shape({
            value: Yup.string().required(),
            code: Yup.string().required(),
            label: Yup.string().required(),
          })
          .required(errorMessages.selectField),
        responses: Yup.array().of(
          Yup.object().shape({
            title: Yup.string()
              .required(errorMessages.inputField)
              .max(29, errorMessages.tooLong(29)),
          }),
        ),
      }),
    ),
  });

  const generateResponses = (quantity) => {
    return Array(quantity)
      .fill(null)
      .map(() => ({
        title: '',
      }));
  };

  const [formData, setFormData] = useState({
    title: '',
    responsesArray: [
      {
        lang: { value: '', code: '', label: '' },
        responses: generateResponses(RESPONSES_COUNT),
      },
    ],
    responseScaleID: '',
    reverseOrder: false,
  });

  useEffect(() => {
    if (responseId && languages.length) {
      setIsLoading(true);

      apiInstance2
        .get(`/api/v2/360/response-scales/${responseId}`)
        .then(({ data }) => {
          const loadedData = {
            title: data.name,
            responsesArray: data.languages.map((lang) => ({
              lang: languages.find((lan) => lan.code === lang.langID),
              responses: lang.responses.map((res) => ({
                title: res.name,
              })),
            })),
            responseScaleID: data.responseScaleID,
            reverseOrder: data.reverseOrder,
          };

          setFormData(loadedData);
          setIsLoading(false);
        })
        .catch(() => {
          // TODO: Handle Error
          setIsLoading(false);
        });
    }
  }, [responseId, languages]);

  const handleSubmit = useCallback(
    (values) => {
      const data = {
        name: values.title,
        reverseOrder: values.reverseOrder,
        languages: values.responsesArray.map((responsesObject) => ({
          langID: responsesObject.lang.value,
          responses: responsesObject.responses.map((response) => response.title),
        })),
        ...(responseId && { responseScaleID: formData.responseScaleID }),
      };

      apiInstance2[responseId ? 'patch' : 'post'](`/api/v2/360/response-scales`, data)
        .then(() => {
          dispatch(push(`/settings/response-scales`));
          createToastNotification({
            message: I18n.t(responseId ? 'scaleUpdatedToast' : 'scaleCreatedToast'),
          });
        })
        .catch(() => {
          // TODO: Handle Error
        });
    },
    [responseId, formData],
  );

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={formData}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        component={(props) => <AddScaleForm {...props} responseScaleId={responseId} />}
      />
      <Spinner text={I18n.t('Loading...')} isLoading={isLoading} full />
    </>
  );
};

export default AddResponseScale;
