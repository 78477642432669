import axios from 'axios';

import storageService from './storageService';

const token = storageService.getItem('accessToken');
const config = {
  baseURL: window._env_.REACT_APP_API,
};

if (token) config.headers = { Authorization: `Bearer ${token}` };

const apiInstance = axios.create(config);

export default apiInstance;
