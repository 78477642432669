import * as actions from '../actionsList';

const customGroupsTableActions = [
  {
    name: actions.ADD_COMPETENCY_GROUP,
    label: 'Add new 360 survey',
  },
];

export default customGroupsTableActions;
