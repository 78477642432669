const translations = {
  Launch: 'Launch (pt-br)',
  About: 'About (pt-br)',
  Tutorial: 'Tutorial (pt-br)',

  // vars
  LoginSubmit: 'Login',
  'Reset assessment deadline': 'Redefinir prazo',
  Update: 'atualizar',
  updateAssessmentInvitationToast: 'Convites atualizados(s)',
  updateProjectInvitationToast: 'Convites atualizados(s)',
  biodataTooltip:
    'Os modelos de biodados permitem a coleta personalizada de dados biográficos dos entrevistados. Crie modelos de biodados na área Configurações',
  'Default Biodata Template has been updated': 'O modelo padrão de biodados foi atualizado',
  DEFAULT: 'Predefinido',
  'Bio data template': 'Modelo de dados biográficos',
  biodataDefaultTemplateLabel: '** Sem modelo de dados biográficos (todas as categorias incluídas) **',
  defaultCompleteBioTemplate: '** Todos os modelos de biodata (Todas as categorias incluídas) **',
  defaultEmptyBioTemplate: '** Modelo de biodata vazio (Nenhuma categoria incluída) **',
  invitesSentToast: 'Convite(s) foi enviado',
  selectNormForReport: 'Por favor, selecione uma norma',
  visibleNormForReport: 'Usar norma para relatórios',
  hideNormForReport: 'Ocultar norma para relatórios',
  showNormTitle: 'Tornar visível para a lista de solicitações de relatórios',
  showConfirmationMessageTitle: 'Tem certeza de que deseja tornar visível',
  hideNormTitle: 'Ocultar para a lista de solicitações de relatórios',
  hideConfirmationMessageTitle: 'Tem certeza de que deseja remover',
  showhideConfirmationMessageDescription1: 'normas selecionadas para a geração de relatórios?',
  showhideConfirmationMessageDescription2: 'esta norma para a geração de relatórios?',
  visible: 'Visível',
  normsVisibilityToast: 'A visibilidade da norma foi atualizada',
  updateGroupToast: 'O grupo foi atualizado',
  updateGroupErrorToast: 'Erro durante a atualização do grupo',
  'Edit Group Details': 'Editar detalhes do grupo',
  'Duplicate Group': 'Duplicar grupo',
};

export default translations;
