import React, { useContext } from 'react';
import { string, func, object, number, bool } from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { components } from 'react-select';
import { I18n } from 'react-redux-i18n';
import { AsyncPaginate } from 'react-select-async-paginate';
import { ReactComponent as SelectArrow } from '../../../assets/icons/blue-arrow-down-big.svg';

const customStyles = ({ zIndex, theme, error }) => ({
  container: (provided) => {
    return {
      ...provided,
      fontSize: '1.4rem',
      ...(zIndex && { zIndex }),
    };
  },
  control: (provided, state) => {
    const defaultBorderColor = state.isFocused ? theme.colors.lightBlue2 : theme.colors.grey2;
    const borderColor = error ? theme.colors.red : defaultBorderColor;
    const hoverBorderColor = error ? theme.colors.red : theme.colors.lightBlue2;
    return {
      ...provided,
      height: '4rem',
      minHeight: '4rem',
      paddingLeft: '0.2rem',
      backgroundColor: state.isFocused ? theme.colors.lightBlue1 : theme.colors.white,
      borderRadius: '0.4rem',
      borderColor,
      boxShadow: 'none',
      '&:hover': {
        borderColor: hoverBorderColor,
      },
    };
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: (provided) => ({
    ...provided,
    borderRadius: '2rem',
    fontSize: '1.4rem',
    color: theme.colors.grey5,
    fontWeight: 500,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: theme.colors.darkBlue2,
    fontWeight: 500,
    fontSize: '1.4rem',
  }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: theme.colors.white,
      fontWeight: 500,
      fontSize: '1.4rem',
      color: state.isSelected ? theme.colors.darkBlue2 : theme.colors.darkBlue2,
      '&:hover': {
        cursor: 'pointer',
        color: state.isSelected ? theme.colors.lightBlue2 : theme.colors.lightBlue,
      },
      '&:active': {
        backgroundColor: theme.colors.white,
      },
    };
  },
});

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectArrow />
    </components.DropdownIndicator>
  );
};

const SingleValue = ({ children, selectProps, ...props }) => (
  <components.SingleValue {...props}>
    <SelectValueHolder>
      <SelectName>{selectProps.placeholder}</SelectName>
      {children}
    </SelectValueHolder>
  </components.SingleValue>
);

const AsyncSelect = ({ loadOptions, className, selectProps, placeholder, error, zIndex }) => {
  const themeContext = useContext(ThemeContext);
  return (
    <SelectWrapper className={className}>
      <AsyncPaginate
        defaultOptions
        cacheOptions
        loadOptions={loadOptions}
        placeholder={placeholder}
        styles={customStyles({ zIndex, theme: themeContext, error })}
        components={{ DropdownIndicator, SingleValue }}
        maxMenuHeight={170}
        noOptionsMessage={() => I18n.t('No options')}
        {...selectProps}
      />
      <ErrorMsg>{error}</ErrorMsg>
    </SelectWrapper>
  );
};

AsyncSelect.propTypes = {
  loadOptions: func.isRequired,
  placeholder: string.isRequired,
  className: string,
  selectProps: object,
  zIndex: number,
  isDisabled: bool,
  error: string,
};

AsyncSelect.defaultProps = {
  className: '',
  selectProps: {},
  zIndex: 0,
  isDisabled: false,
  error: '',
};

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 20rem;
  margin-bottom: 20px;
  position: relative;
  //z-index: 10;
`;

const SelectValueHolder = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectName = styled.span`
  font-size: 1rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.mediumBlue};
`;

const ErrorMsg = styled.span`
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.red};
  font-weight: 600;
  margin-top: 0.5rem;
`;

export default AsyncSelect;
