const languagesMap = {
  EN: 'English ',
  AF: 'Afrikaans',
  AR: 'العربية / Arabic',
  AZ: 'Azərbaycan dili / Azerbaijani',
  BG: 'Български / Bulgarian',
  CH: '中文(简体) / Chinese (Simplified)',
  'CH-PRC': '中文(简体) / Chinese (Simplified)',
  CHTR: '中文(繁體) / Chinese (Traditional)',
  CHHK: '中文(香港) / Chinese(Traditional) HK',
  CZ: 'Češki / Czech',
  DE: 'Deutsch / German',
  DK: 'Dansk / Danish',
  DU: 'Nederlands / Dutch',
  ES: 'Español / Spanish (Castellano)',
  'ES-LATAM': 'Español / Spanish (Latam)',
  FL: 'Tagalog / Filipino',
  FR: 'Français / French',
  GR: 'ελληνικά / Greek',
  HE: 'עִבְרִית / Hebrew',
  HI: 'हिन्दी/ Hindi',
  IN: 'Bahasa Indonesia / Indonesian',
  IT: 'Italiano / Italian',
  JP: '日本語 / Japanese',
  KO: '한국어 / Korean',
  MK: 'Македонски / Macedonian',
  MY: 'Melayu / Malay',
  NO: 'Norsk / Norwegian',
  PL: 'Polski / Polish',
  PT: 'Português / Portuguese',
  'PT-BR': 'Português Brasileiro / Portuguese Brazil',
  RO: 'Rumunski / Romanian',
  RU: 'Pусский / Russian',
  SR: 'Cрпски / Serbian',
  TH: 'ภาษาไทย / Thai',
  THAI: 'ภาษาไทย / Thai',
  TR: 'Türkçe / Turkish',
  UA: 'Yкраїнська / Ukrainian',
  UR: 'اردو / Urdu',
  VN: 'Tiếng Việt / Vietnamese',
  ENUS: 'English(United States)',
  BS: 'Bosanski / Bosnian',
  ME: 'Crnogorski / Montenegrian',
  HU: 'Mađarski / Hungarian',
  HR: 'Hrvatski / Croatian',
  MN: 'Mongolian / Mongolian',
  SL: 'Slovenački / Slovenian',
};

export default languagesMap;
