import React, { useEffect, useState } from 'react';
import { func, array } from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';

import apiInstance2 from '../../services/apiService';
import NewModal from '../reusable/NewModal';
import CustomInput from '../reusable/FormComponents/Input';
import CustomSelect from '../reusable/Selects/Select';
import CustomButton from '../reusable/Buttons/Button';
import CustomTextArea from '../reusable/FormComponents/TextArea';

import { createIdealProfile } from '../../store/settings/ideal-profiles/actions';
import { resolveAssessmentName } from '../../utils/assessmentsNames';

const validationSchema = Yup.object().shape({
  profileName: Yup.string()
    .trim()
    .required(I18n.t('Ideal profile name is required')),
  description: Yup.string(),
  assessment: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .nullable()
    .required(I18n.t('Assessment is required')),
  norm: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .nullable()
    .required(I18n.t('Norm is required')),
});

const GroupBasedIdealProfile = ({ onClose, groupsIds }) => {
  const dispatch = useDispatch();

  const [assessments, setAssessments] = useState([]);
  const [norms, setNorms] = useState([]);

  useEffect(async () => {
    try {
      const query = groupsIds.reduce((acc, item) => `${acc}groupIds=${item}&`, '');
      const response = await apiInstance2.get(`/api/v2/ideal-profiles/groups/tests?${query}`);
      if (response.status === 200) {
        const data = response.data.map((item) => ({
          ...item,
          label: resolveAssessmentName(item),
          value: item.testID,
          norms: item.norms.map((norm) => ({ ...norm, label: norm.name, value: norm.normID })),
        }));
        setAssessments(data);
      }
    } catch (e) {
      console.log('Error while fetching assessments for groups');
    }
  }, []);

  return (
    <StyledModal isVisible onClose={onClose} title={I18n.t('createIdealProfileBasedOnSselectedGroup')}>
      <Formik
        initialValues={{
          profileName: '',
          description: '',
          assessment: null,
          norm: null,
        }}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={(values) => {
          const data = {
            name: values.profileName,
            description: values.description,
            testID: values.assessment.testID,
            normID: values.norm.normID,
            type: 0,
            groupIDs: groupsIds,
          };
          dispatch(
            createIdealProfile(data, (err, idealProfileId) => {
              onClose();
              if (!err) dispatch(push(`/settings/ideal-profiles/${idealProfileId}`));
            }),
          );
        }}
      >
        {({ values, setFieldValue, setFieldError, errors, isSubmitting, handleSubmit }) => {
          return (
            <FormSection>
              <InputsWrapper>
                <StyledInput
                  value={values.profileName}
                  onChange={(e) => {
                    setFieldValue('profileName', e.target.value);
                    setFieldError('profileName', '');
                  }}
                  inputName="Ideal profile name"
                  error={errors.profileName}
                />
                <StyledTextArea
                  inputName="Description"
                  name="description"
                  value={values.description}
                  onChange={(e) => setFieldValue('description', e.target.value)}
                  error={errors.description}
                />
                <StyledSelect
                  options={assessments}
                  placeholder={I18n.t('Assessment on which the ideal profile is based')}
                  selectProps={{
                    value: values.assessment,
                    onChange: (value) => {
                      setFieldValue('assessment', value);
                      setFieldError('assessment', '');
                      setNorms(value.norms);
                    },
                  }}
                  error={errors.assessment}
                />
                <StyledSelect
                  options={norms}
                  placeholder={values.norm ? I18n.t('Norm') : I18n.t('Select norm')}
                  selectProps={{
                    value: values.norm,
                    onChange: (value) => {
                      setFieldValue('norm', value);
                      setFieldError('norm', '');
                    },
                  }}
                  error={errors.norm}
                />
              </InputsWrapper>
              <ButtonWrapper>
                <StyledButton disabled={isSubmitting} handler={handleSubmit} type="submit">
                  {I18n.t('Save')}
                </StyledButton>
              </ButtonWrapper>
            </FormSection>
          );
        }}
      </Formik>
    </StyledModal>
  );
};

GroupBasedIdealProfile.propTypes = {
  onClose: func.isRequired,
  groupsIds: array.isRequired,
};

const StyledModal = styled(NewModal)`
  display: flex;
  flex-direction: column;
  width: 75rem;
  max-height: 46rem;
  height: 100%;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledInput = styled(CustomInput)`
  width: 50rem;
  margin-bottom: 2rem;
`;

const StyledSelect = styled(CustomSelect)`
  width: 50rem;
  margin-bottom: 2rem;
`;

const StyledTextArea = styled(CustomTextArea)`
  margin-bottom: 2rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledButton = styled(CustomButton)`
  width: 12rem;
  text-transform: uppercase;
`;

export default GroupBasedIdealProfile;
