import { I18n } from 'react-redux-i18n';

export const DEFAULT = 0;
export const RANDOM = 1;
export const CUSTOM = 2;

export const DEFAULT_ORDER = {
  value: DEFAULT,
  label: I18n.t('Default'),
};

export const QUESTIONS_ORDER_LIST = [
  {
    ...DEFAULT_ORDER,
  },
  {
    value: RANDOM,
    label: I18n.t('Random'),
  },
  {
    value: CUSTOM,
    label: I18n.t('Custom'),
  },
];
