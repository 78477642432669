import React from 'react';
import { func, oneOfType, object, instanceOf, string } from 'prop-types';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';

import { datesFilterOptions } from '../../../../constants/advancedFilterOptions';
import CustomSelect from '../../Selects/Select';
import CloseIcon from '../../CloseIcon';

import DateSelect from '../../FormComponents/DateSelect';

const FilterDateInputGroup = ({
  className,
  onDateChange,
  onSelectChange,
  onDelete,
  selectedDate,
  selectValue,
  label,
}) => {
  const translatedOptions = datesFilterOptions.map((item) => ({ ...item, label: I18n.t(item.label) }));

  return (
    <OuterContainer className={className}>
      <InputsContainer>
        <DateSelect onDateChange={onDateChange} selectedDate={selectedDate} label={label} />
        <StyledSelect
          options={translatedOptions}
          selectProps={{ onChange: onSelectChange, value: { ...selectValue, label: I18n.t(selectValue.label) } }}
          placeholder={I18n.t('Date range')}
        />
        <StyledCloseIcon clickHandler={onDelete} />
      </InputsContainer>
    </OuterContainer>
  );
};

FilterDateInputGroup.propTypes = {
  onDateChange: func.isRequired,
  onSelectChange: func.isRequired,
  onDelete: func.isRequired,
  selectedDate: oneOfType([string, instanceOf(Date)]),
  selectValue: object,
  label: string.isRequired,
  className: string,
};

FilterDateInputGroup.defaultProps = {
  selectValue: null,
  selectedDate: null,
  className: '',
};
const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSelect = styled(CustomSelect)`
  width: 15rem;
  margin-bottom: 0;
  margin-right: 1.5rem;
`;

const StyledCloseIcon = styled(CloseIcon)`
  width: 1rem;
  height: 1rem;
`;

// export default FilterDateInputGroup;
export default React.memo(FilterDateInputGroup, (prev, next) => {
  return prev.selectValue === next.selectValue && prev.selectedDate === next.selectedDate;
});
