import React from 'react';
import styled from 'styled-components';
import { func, object, bool } from 'prop-types';

import { I18n } from 'react-redux-i18n';
import { ReactComponent as Check } from '../../../assets/icons/check-white.svg';

import { ReactComponent as ArrowUp } from '../../../assets/icons/blue-arrow-up-big.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/blue-arrow-down-big.svg';

import AnimateHeightWrapper from '../../reusable/AnimateHeight';

export const findIfSolutionDisabled = (report, idealProfiles) => {
  const profilesIds = Object.keys(idealProfiles);
  return report.isIdealProfileReport && !profilesIds.includes(report.ipDependencies);
};

const Solution = ({ solution, onSolutionClick, onSolutionCheckboxClick, onReportClick, compact, idealProfiles }) => {
  const { isOpen, isChecked } = solution;
  const solutionHeight = solution.reports.length > 4 ? 155 : 'auto';
  const isWholeSolutionDisabled = solution.reports.every((item) => findIfSolutionDisabled(item, idealProfiles));

  return (
    <SolutionContainer disabled={isWholeSolutionDisabled}>
      <SolutionVisiblePart
        compact={compact}
        onClick={() => !isWholeSolutionDisabled && onSolutionClick(solution.reportSetID)}
        isSolutionOpen={isOpen}
        disabled={isWholeSolutionDisabled}
      >
        <FlexContentHolder>
          <Checkbox
            onClick={(e) => {
              if (isWholeSolutionDisabled) return;
              onSolutionCheckboxClick(solution.reportSetID);
              e.stopPropagation();
            }}
            isActive={isChecked}
          >
            {isChecked && <CheckIcon />}
          </Checkbox>
          <SolutionName>{solution.name}</SolutionName>
        </FlexContentHolder>
        <FlexContentHolder>
          <CreditsCost>
            {solution.creditsCost} {I18n.t('credits')}
          </CreditsCost>
          {isOpen ? <StyledArrowUp /> : <StyledArrowDown />}
        </FlexContentHolder>
      </SolutionVisiblePart>
      <StyledAnimateHeight height={isOpen ? solutionHeight : 0}>
        <SolutionReportsContainer>
          {solution.reports.map((item) => {
            const isDisabled = findIfSolutionDisabled(item, idealProfiles);

            return (
              <TestReport key={item.reportID} disabled={isDisabled}>
                <Checkbox
                  onClick={() => !isDisabled && onReportClick(solution.reportSetID, item.reportID)}
                  isActive={item.isChecked}
                  disabled={isDisabled}
                >
                  {item.isChecked && <CheckIcon />}
                </Checkbox>
                <span>{item.displayName || item.name}</span>
              </TestReport>
            );
          })}
        </SolutionReportsContainer>
      </StyledAnimateHeight>
    </SolutionContainer>
  );
};

Solution.propTypes = {
  solution: object.isRequired,
  onSolutionClick: func.isRequired,
  onSolutionCheckboxClick: func.isRequired,
  onReportClick: func.isRequired,
  idealProfiles: object.isRequired,
  compact: bool,
};

Solution.defaultProps = {
  compact: false,
};

const FlexContentHolder = styled.div`
  display: flex;
  align-items: center;
`;

const SolutionContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #cccccc;
  border-radius: 7px;
  margin-bottom: 5px;

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const SolutionVisiblePart = styled.div`
  display: flex;
  align-items: center;
  padding: ${(props) => (props.compact ? '10px 7px' : '18px 15px')};
  justify-content: space-between;
  // border-bottom: ${(props) => (props.isSolutionOpen ? '1px solid #cccccc' : 'none')};

  :hover {
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  }
`;

const SolutionName = styled.span`
  color: ${(props) => props.theme.colors.darkBlue2};
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
`;

const CreditsCost = styled.span`
  margin-right: 10px;
  margin-left: 5px;
  color: ${(props) => props.theme.colors.lightBlue};
  font-weight: 600;
  white-space: nowrap;
  font-size: 14px;
  line-height: 20px;
`;

const Checkbox = styled.div`
  width: 17px;
  height: 17px;
  min-width: 17px;
  min-height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  background-color: ${(props) => (props.isActive ? props.theme.colors.primary : props.theme.colors.white)};
  border-radius: 2px;
  margin-right: 1rem;
  transition: all 0.2s;

  :hover {
    cursor: pointer;
  }
`;

const StyledArrowUp = styled(ArrowUp)`
  width: 1.2rem;
  height: 1.2rem;
  path {
    fill: #295368;
  }
`;

const StyledArrowDown = styled(ArrowDown)`
  width: 1.2rem;
  height: 1.2rem;
  path {
    fill: #295368;
  }
`;
const CheckIcon = styled(Check)`
  width: 12px;
  height: 12px;
`;

const SolutionReportsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TestReport = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.primary};
  font-size: 10px;
  font-weight: 500;
  padding: 10px 15px;
  border-bottom: 1px solid #f1f1f1;
  cursor: default;

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const StyledAnimateHeight = styled(AnimateHeightWrapper)`
  overflow: auto !important;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; /* Remove scrollbar space */
  }
`;

export default React.memo(Solution, (prev, next) => {
  if (prev.alwaysUpdate) return false;
  const findLowLevelStates = (reports) => reports.reduce((acc, item) => `${acc}${item.isChecked}`, '');
  return (
    prev.solution.isOpen === next.solution.isOpen &&
    prev.solution.isChecked === next.solution.isChecked &&
    findLowLevelStates(prev.solution.reports) === findLowLevelStates(next.solution.reports)
  );
});
