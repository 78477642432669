import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { push } from 'redux-first-history';
import Table from '../reusable/Tables/Table';
import PageWrapper from '../PageWrapper';
import AdvancedFilter from '../reusable/AdvancedFilter';
import TableTabs from '../reusable/TableTabs';
import AddNewGroup from './AddNewGroup';
import ManageGroupDetails from './ManageGroupDetails';
import GroupBasedIdealProfile from './GroupBasedIdealProfile';
import GroupBasedNormCreation from './GroupBasedNormCreation';
import AdministerManagedGroup from './AdministerManagedGroup';
import ManageActiveLinks from './ManageActiveLinks';
import GroupInvite from './GroupInvite';
import GroupRequestReport from './GroupRequestReport';
import AdministerGroupSession from './AdministerGroupSession';
import BulkActionsSidebar from '../BulkActionsSidebar';

import {
  setSelectedGroups,
  setGroupsSearchValue,
  setGroupsFilterValue,
  setGroupsFilterType,
  applyGroupsFilters,
  clearGroupsFilters,
  deleteGroupsAppliedFilter,
  fetchGroups,
  deleteGroups,
  changeShouldResetGroupsTableBoolState,
} from '../../store/groups/actions';
import groupsColumns from '../../table/columns/groups';
import * as actions from '../../table/actionsList';

import groupsTableActions from '../../table/tableActions/groups';
import groupsBulkActions from '../../table/bulkActions/groups';

import useTableFilter from '../../hooks/useTableFilter';
import useModals from '../../hooks/useModals';
import useBulkSidebarState from '../../hooks/useBulkSidebarState';
import ConfirmationModal from '../reusable/ConfirmationModal';
import { getRespondentsCount } from '../../store/respondents/actions';

const Groups = () => {
  const groups = useSelector((state) => state.groups.groups);
  const user = useSelector((state) => state.user.user);
  const selectedGroups = useSelector((state) => state.groups.selectedGroups);
  const searchValue = useSelector((state) => state.groups.search);
  const filter = useSelector((state) => state.groups.filter);
  const pageCount = useSelector((state) => state.groups.pagesAvailable);
  const totalCount = useSelector((state) => state.groups.totalCount);
  const lastPageIndex = useSelector((state) => state.groups.lastPageIndex);
  const shouldResetTableState = useSelector((state) => state.groups.shouldResetTableState);
  const respondentsTotalCount = useSelector((state) => state.respondents.allRespondentsCount);

  const dispatch = useDispatch();
  const memoizedSetGroupsSearchValue = useCallback((val) => dispatch(setGroupsSearchValue(val)), []);
  const memoizedClearGroupsFilters = useCallback(() => dispatch(clearGroupsFilters()), []);
  const memoizedDeleteGroupsAppliedFilter = useCallback(
    (fieldName) => dispatch(deleteGroupsAppliedFilter(fieldName)),
    [],
  );

  const [renderKey, setRenderKey] = useState(1);
  useEffect(() => {
    if (shouldResetTableState) {
      setRenderKey(Math.random() * 1000);
      dispatch(changeShouldResetGroupsTableBoolState(false));
    }
  }, [shouldResetTableState]);

  useEffect(() => {
    dispatch(getRespondentsCount());
  }, []);

  const [isLoading, setLoadingStatus] = useState(true);
  const fetchData = useCallback(({ tableData, shouldReset, showSpinner, tableCallback }) => {
    if (showSpinner) setLoadingStatus(true);
    dispatch(
      fetchGroups(tableData, {
        callback: () => {
          setLoadingStatus(false);
          tableCallback();
        },
        shouldReset,
      }),
    );
  }, []);

  const onSelectChange = useCallback((data) => {
    dispatch(setSelectedGroups(data));
  }, []);

  // Controlling bulk actions sidebar state
  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedGroups });

  const modals = [
    actions.EDIT_GROUP_DETAILS,
    actions.ADMINISTER_GROUP_SESSION,
    actions.CREATE_GROUP,
    actions.GROUP_CREATE_INVITE,
    actions.GROUP_REQUEST_REPORT,
    actions.DELETE_GROUPS,
    actions.ADMINISTER_MANAGED_GROUP,
    actions.CREATE_PROFILE_BASED_ON_GROUP,
    actions.CREATE_NORM_BASED_ON_GROUP,
    actions.MANAGE_ACTIVE_LINKS,
  ];
  const { modalsState, openModal, closeModal } = useModals(modals);

  const navigateToEdit = (group) => {
    const path = group.hasActiveAdministeredSession
      ? `/groups/administer/${group.groupID}`
      : `/groups/${group.groupID}`;

    dispatch(push(path));
    dispatch(setSelectedGroups([]));
  };

  // Table actions
  const tableHandlers = {
    [actions.CREATE_GROUP]: () => openModal(actions.CREATE_GROUP),
    [actions.IMPORT_GROUP]: () => {},
  };

  const tableActions = useMemo(() => {
    return groupsTableActions.map((item) => ({ ...item, handler: tableHandlers[item.name] }));
  }, []);

  // bulk actions
  const bulkActions = useMemo(() => {
    return groupsBulkActions(user).map((item) => ({
      ...item,
      handler: () => openModal(item.name),
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedGroups, user) : item.isDisabled,
    }));
  }, [selectedGroups, user]);

  // columns settings
  const columns = useMemo(() => {
    return groupsColumns;
  }, []);

  // table filtering
  const setFilterType = (name, value) => dispatch(setGroupsFilterType(name, value));
  const setFilterValue = (name, value) => dispatch(setGroupsFilterValue(name, value));
  const { inputsWithHandlers, appliedFiltersWithColumnNames } = useTableFilter({
    columns,
    filter,
    setFilterType,
    setFilterValue,
  });

  const onFilterApply = () => {
    dispatch(applyGroupsFilters());
  };

  // initial table state
  const initialState = useMemo(() => ({ sortBy: [{ id: 'Creation Date', desc: true }] }), []);
  const rowEqualityFunc = useCallback(
    (prevItem, nextItem) =>
      prevItem.groupSize === nextItem.groupSize &&
      prevItem.hasActiveAdministeredSession === nextItem.hasActiveAdministeredSession,
    [],
  );

  const tabs = useMemo(() => {
    return [
      { name: 'respondents', label: `${I18n.t('List')} (${respondentsTotalCount})` },
      { name: 'groups', label: `${I18n.t('Groups')} (${totalCount})` },
    ];
  }, [totalCount, respondentsTotalCount]);

  const onTabClick = (tab) => {
    if (tab.name === 'respondents') dispatch(push('/respondents'));
  };

  const onGroupsDelete = useCallback(() => {
    dispatch(
      deleteGroups(
        selectedGroups.map((group) => group.groupID),
        () => closeModal(actions.DELETE_GROUPS),
      ),
    );
  }, [selectedGroups]);

  const handleReset = () => {
    setRenderKey(Math.random() * 1000);
    dispatch(
      fetchGroups('', {
        callback: () => {
          setLoadingStatus(true);
        },
        shouldReset: true,
      }),
    );
  };

  return (
    <PageWrapper key={renderKey} title={I18n.t('Respondents')} buttons={tableActions}>
      <TableTabs tabs={tabs} activeTab={tabs[1]} clickHandler={onTabClick} />
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      <AdvancedFilter
        searchProps={{
          appliedFilters: appliedFiltersWithColumnNames,
          deleteFilter: memoizedDeleteGroupsAppliedFilter,
          searchValue,
          onSearchChange: memoizedSetGroupsSearchValue,
        }}
        filterProps={{
          inputs: inputsWithHandlers,
          appliedInputs: filter.appliedInputs,
          applyFilters: onFilterApply,
          clearAll: memoizedClearGroupsFilters,
          isDirty: filter.dirty,
        }}
      />
      <Table
        columns={columns}
        data={groups}
        idAccessor="groupID"
        initialState={initialState}
        onSelectChange={onSelectChange}
        onRowClick={navigateToEdit}
        interceptCheckboxClick
        entityName="group"
        rowEqualityFunc={rowEqualityFunc}
        isLoading={isLoading}
        onFetchData={fetchData}
        totalCount={totalCount}
        searchValue={searchValue}
        appliedFilters={appliedFiltersWithColumnNames}
        pageCount={pageCount}
        lastPageIndex={lastPageIndex}
      />
      {modalsState[actions.EDIT_GROUP_DETAILS] && (
        <ManageGroupDetails
          group={selectedGroups[0]}
          actionType="0"
          onClose={() => {
            closeModal(actions.EDIT_GROUP_DETAILS);
            handleReset();
          }}
        />
      )}
      {modalsState[actions.DUPLICATE_GROUP] && (
        <ManageGroupDetails
          group={selectedGroups[0]}
          actionType="1"
          onClose={() => {
            closeModal(actions.DUPLICATE_GROUP);
            handleReset();
          }}
        />
      )}
      {modalsState[actions.CREATE_GROUP] && <AddNewGroup onClose={() => closeModal(actions.CREATE_GROUP)} />}
      <ConfirmationModal
        isVisible={modalsState[actions.DELETE_GROUPS]}
        title={I18n.t('Delete group')}
        description={I18n.t('Are you sure you want to delete this group(s)')}
        onClose={() => closeModal(actions.DELETE_GROUPS)}
        onConfirm={onGroupsDelete}
        caution
      />
      {modalsState[actions.GROUP_CREATE_INVITE] && (
        <GroupInvite onClose={() => closeModal(actions.GROUP_CREATE_INVITE)} group={selectedGroups[0]} />
      )}
      {modalsState[actions.GROUP_REQUEST_REPORT] && (
        <GroupRequestReport
          onClose={() => closeModal(actions.GROUP_REQUEST_REPORT)}
          group={selectedGroups[0]}
          successCallback={() => dispatch(changeShouldResetGroupsTableBoolState(true))}
        />
      )}
      {modalsState[actions.CREATE_PROFILE_BASED_ON_GROUP] && (
        <GroupBasedIdealProfile
          onClose={() => closeModal(actions.CREATE_PROFILE_BASED_ON_GROUP)}
          groupsIds={selectedGroups.map((item) => item.groupID)}
        />
      )}
      {modalsState[actions.CREATE_NORM_BASED_ON_GROUP] && (
        <GroupBasedNormCreation
          onClose={() => closeModal(actions.CREATE_NORM_BASED_ON_GROUP)}
          groupsIds={selectedGroups.map((item) => item.groupID)}
        />
      )}
      {modalsState[actions.ADMINISTER_MANAGED_GROUP] && (
        <AdministerManagedGroup
          onClose={() => closeModal(actions.ADMINISTER_MANAGED_GROUP)}
          group={selectedGroups[0]}
        />
      )}
      {modalsState[actions.MANAGE_ACTIVE_LINKS] && (
        <ManageActiveLinks onClose={() => closeModal(actions.MANAGE_ACTIVE_LINKS)} />
      )}
      {modalsState[actions.ADMINISTER_GROUP_SESSION] && (
        <AdministerGroupSession
          onClose={() => closeModal(actions.ADMINISTER_GROUP_SESSION)}
          group={selectedGroups[0]}
        />
      )}
    </PageWrapper>
  );
};

export default Groups;
