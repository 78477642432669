import React, { useCallback, useState } from 'react';
import { Bar, BarChart, YAxis } from 'recharts';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import Histogram from './Histogram';

const HistogramCell = ({ row }) => {
  const [isPreview, setIsPreview] = useState(false);
  const data = row.original.frequency
    ? row.original.frequency.split(';').map((f, index) => ({ name: `${I18n.t('Raw Score')} ${index}`, barValue: f }))
    : [];

  const openPreviewHistogram = useCallback(() => {
    if (row.original.frequency) {
      setIsPreview(true);
    }
  }, [row.original.frequency]);

  const frequency = row.original.frequency ? row.original.frequency.split(';') : [];
  const maxDomain = 1.05 * Math.max(...frequency);

  return (
    <BarChartWrappers
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <StyledBarChart onClick={openPreviewHistogram}>
        <BarChart width={112} height={40} data={data}>
          <Bar dataKey="barValue" fill="#40c4ff" background={{ fill: '#f2f2f2' }} />
          <YAxis domain={[0, maxDomain]} hide />
        </BarChart>
      </StyledBarChart>
      {isPreview && (
        <Histogram
          data={data}
          title={row.original.scaleName}
          onClose={() => {
            setIsPreview(false);
          }}
          domain={[0, maxDomain]}
        />
      )}
    </BarChartWrappers>
  );
};

const StyledBarChart = styled.div`
  cursor: pointer !important;
  width: 112px;
  height: 40px;

  svg {
    cursor: pointer !important;
  }
`;

const BarChartWrappers = styled.div`
  position: relative;
  cursor: pointer;
`;

export default HistogramCell;
