import React from 'react';
import styled from 'styled-components';

const Control = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: #a2abab;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const OptionsHolder = styled.div`
  display: flex;
`;

const Label = styled.span`
  font-size: 14px;
  color: ${(props) => props.theme.colors.lightBlue};
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLabel = styled.label`
  box-sizing: content-box;
  position: relative;
  padding-left: 27px;
  margin-right: 17px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.darkBlue};
  font-style: normal;

  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};

  :before {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    left: -1px;
    width: 20px;
    height: 20px;
    border: 2px solid ${(props) => props.theme.colors.darkBlue2};
    border-radius: 50%;
  }

  :hover {
    cursor: pointer;
  }
`;

const Input = styled.input`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;

  :checked + ${StyledLabel} {
    :after {
      content: '';
      display: block;
      position: absolute;
      width: 14px;
      height: 14px;
      background: ${(props) => props.theme.colors.lightBlue};
      top: 4px;
      left: 4px;
      border-radius: 50%;
    }
  }
`;

const Radio = ({ field, label, options, className, form }) => {
  return (
    <Control className={className}>
      {label && <Label>{label}</Label>}
      <OptionsHolder>
        {options.map((option) => {
          const id = `${option.name || field.name}-${Math.random() * 100}`;
          const onChange = () => {
            if (form) form.setFieldValue(field.name, option.value);
            else field.onChange({ target: { value: option.value } });
          };
          return (
            <Row key={id}>
              <Input
                type="radio"
                id={id}
                name={field.name}
                value={option.value}
                disabled={option.disabled}
                checked={field.value === option.value}
                {...field}
                onChange={onChange}
              />
              <StyledLabel key={option.name} htmlFor={id} isDisabled={option.disabled}>
                {option.name}
              </StyledLabel>
            </Row>
          );
        })}
      </OptionsHolder>
    </Control>
  );
};

export default Radio;
