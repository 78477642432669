import { createSelector } from 'reselect';
import { I18n } from 'react-redux-i18n';

const getWelcomeTexts = (state) => state.welcomeTexts.welcomeTexts;
const getWelcomeTextBodyVariables = (state) => state.welcomeTexts.bodyVariables;

const defaultValue = {
  value: null,
  label: I18n.t('**No Welcome Text'),
};

// eslint-disable-next-line import/prefer-default-export
export const selectWelcomeTextsOptions = createSelector(getWelcomeTexts, (welcomeTexts) => {
  const texts = welcomeTexts.map((item) => ({ ...item, value: item.templateID, label: item.name }));
  texts.unshift(defaultValue);
  return texts;
});

export const selectWelcomeTextBodyVariables = createSelector(getWelcomeTextBodyVariables, (variables) =>
  variables.map((item) => ({ value: item, label: item })),
);
