/* eslint-disable */
import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import styled, { css } from 'styled-components';
import { I18n } from 'react-redux-i18n';
import { ReactComponent as SelectArrow } from '../../../assets/icons/blue-arrow-down-big.svg';
import get from 'lodash/get';
import { errorMessages } from '../../../constants/errorMessages';

const LanguagesSelect = ({ label = 'Language', name, value, languages, onChange, errors, touched }) => {
  const [isOpened, setIsOpened] = useState(false);

  const onSelectLanguage = (lan) => {
    setIsOpened(false);
    onChange(lan);
  };

  const isTouched = get(touched, name);
  const error = get(errors, name);

  return (
    <OutsideClickHandler onOutsideClick={() => setIsOpened(false)}>
      <Container>
        <SelectContainer onClick={() => setIsOpened(true)} error={error && isTouched}>
          <Label active={value && value.value}>{I18n.t(label)}</Label>
          {value && value.value && (
            <>
              <SelectDropdownItem>
                {/*<img src={require(`../../../assets/icons/flags/${value.code}.svg`)} alt={`${value.code} flag`} />*/}
                <span>{value.label}</span>
              </SelectDropdownItem>
            </>
          )}
        </SelectContainer>
        <StyledSelectArrow />
        <SelectDropdown hidden={!isOpened}>
          {languages.map((lan) => (
            <SelectDropdownItem
              active={value && value.code === lan.code}
              onClick={() => onSelectLanguage(lan)}
              key={lan.code}
            >
              {/*<img src={require(`../../../assets/icons/flags/${lan.code}.svg`)} alt={`${lan.code} flag`} />*/}
              <span>{lan.label}</span>
            </SelectDropdownItem>
          ))}
        </SelectDropdown>
        {error && isTouched && <ErrorMsg>{errorMessages.selectField}</ErrorMsg>}
      </Container>
    </OutsideClickHandler>
  );
};

const ErrorMsg = styled.span`
  position: absolute;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.red};
  font-weight: 600;
  margin-top: 0.5rem;
  min-height: 1.2rem;
  bottom: -1.5rem;
  left: 0;
`;

const Container = styled.div`
  position: relative;
`;

const SelectDropdownItem = styled.li`
  padding: 15px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.darkBlue2};
  font-size: 14px;

  img {
    width: 28px;
    height: 20px;
    border-radius: 4px;
    margin-right: 10px;
  }

  span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${(props) =>
    props.active &&
    css`
      background-color: ${(props) => props.theme.colors.menuBg};
      color: ${(props) => props.theme.colors.white};
    `}
`;

const SelectDropdown = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 40px;
  max-height: 213px;
  overflow: auto;
  width: 100%;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.grey2};
  z-index: 9;
  border-radius: 4px;

  ${(props) =>
    props.isHidden &&
    css`
      display: none;
    `};

  ${SelectDropdownItem}:hover {
    background-color: ${(props) => props.theme.colors.menuBg};
    color: ${(props) => props.theme.colors.white};
  }
`;

const StyledSelectArrow = styled(SelectArrow)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

const Label = styled.div`
  color: #5f5f5f;
  font-size: 14px;
  margin: 0 0 0 5px;
  font-weight: 500;

  ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.theme.colors.mediumBlue};
      font-size: 10px;
      font-weight: 600;
      position: absolute;
      margin: 0;
      top: 6px;
    `}
`;

const SelectContainer = styled.div`
  height: 4rem;
  max-height: 4rem;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => (props.error ? props.theme.colors.red : props.theme.colors.grey2)};
  width: 20rem;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 25px;

  ${SelectDropdownItem} {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0;

    span {
      position: relative;
      bottom: -6px;
    }
  }
`;

export default LanguagesSelect;
