import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';

import { I18n } from 'react-redux-i18n';
import PageWrapper from '../../PageWrapper';
import SimplifiedTable from '../../reusable/Tables/SimplifiedTable';
import TableTabs from '../../reusable/TableTabs';
import BulkActionsSidebar from '../../BulkActionsSidebar';
import ConfirmationModal from '../../reusable/ConfirmationModal';

import templatesColumns from '../../../table/columns/templates';
import templatesTableActions from '../../../table/tableActions/templates';
import templatesBulkActions from '../../../table/bulkActions/templates';

import useBulkSidebarState from '../../../hooks/useBulkSidebarState';
import * as actions from '../../../table/actionsList';
import useModals from '../../../hooks/useModals';

import templatesTabs from './templatesTabs';
import { fetchTemplates, deleteTemplates } from '../../../store/settings/templates/actions';

const Templates = () => {
  const templates = useSelector((state) => state.templates.templates);
  const dispatch = useDispatch();

  const [isLoading, setLoadingState] = useState(true);

  useEffect(() => {
    dispatch(
      fetchTemplates(() => {
        setLoadingState(false);
      }),
    );
  }, []);

  const [selectedTemplates, setSelectedTemplates] = useState([]);

  const modals = [actions.CREATE_TEMPLATE, actions.DELETE_TEMPLATES];
  const { modalsState, openModal, closeModal } = useModals(modals);

  const goBackToSettings = () => dispatch(push('/settings'));

  const onTemplatesDelete = () => {
    dispatch(
      deleteTemplates(
        selectedTemplates.map((item) => item.templateID),
        () => {
          closeModal(actions.DELETE_TEMPLATES);
        },
      ),
    );
  };

  const onTabClick = (tab) => {
    if (tab.name === 'welcomeTexts') dispatch(push('/settings/welcome-texts'));
  };

  // Controlling bulk actions sidebar state
  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedTemplates });

  const navigateToTemplate = (template) => dispatch(push(`/settings/templates/${template.templateID}`));

  const bulkActionsHandlers = {
    [actions.EDIT_TEMPLATE]: () => navigateToTemplate(selectedTemplates[0]),
    [actions.DELETE_TEMPLATES]: () => openModal(actions.DELETE_TEMPLATES),
  };

  const bulkActions = useMemo(() => {
    return templatesBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedTemplates) : item.isDisabled,
    }));
  }, [selectedTemplates]);

  const tableActions = useMemo(() => {
    return templatesTableActions.map((item) => ({
      ...item,
      handler: () => dispatch(push('/settings/templates/new')),
    }));
  }, []);

  return (
    <PageWrapper backButtonHandler={goBackToSettings} buttons={tableActions} title={I18n.t('Templates')}>
      <BulkActionsSidebar onClose={closeSidebar} isOpen={isSidebarOpen} actions={bulkActions} />
      <TableTabs tabs={templatesTabs} activeTab={templatesTabs[0]} clickHandler={onTabClick} />
      <Table
        columns={templatesColumns}
        data={templates}
        onSelectChange={setSelectedTemplates}
        entityName="template"
        isLoading={isLoading}
        onRowClick={(template) => navigateToTemplate(template)}
        interceptCheckboxClick
        bottomOffset={75}
      />
      <ConfirmationModal
        isVisible={modalsState[actions.DELETE_TEMPLATES]}
        title={I18n.t('Delete templates')}
        description={`${I18n.t('Are you sure you want to delete')} ${
          selectedTemplates.length > 1 ? I18n.t('selected templates?') : I18n.t('this template?')
        }`}
        onClose={() => closeModal(actions.DELETE_TEMPLATES)}
        onConfirm={onTemplatesDelete}
        caution
      />
    </PageWrapper>
  );
};

Templates.propTypes = {};

const Table = styled(SimplifiedTable)`
  td:first-child,
  th:first-child {
    width: 3%;
  }
`;

export default Templates;
