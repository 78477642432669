import React, { useState, useEffect } from 'react';
import { func, object } from 'prop-types';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import createToastNotification from '../../../utils/createToastNotification';

import { createBiodataTemplate, updateBiodataTemplate } from '../../../store/user/actions';

import NewModal from '../../reusable/NewModal';
import CustomButton from '../../reusable/Buttons/Button';
import CustomTextArea from '../../reusable/FormComponents/TextArea';
import Checkbox from '../../reusable/FormComponents/Checkbox';
import BioDataCategoryModal from './BioDataCategoryModal';

import { errorMessages } from '../../../constants/errorMessages';

import {
  ethnicityOptions,
  educationOptions,
  occupationOptions,
  sectorOptions,
  industryOptions,
  languageOptions,
} from '../../../constants/respondentSelectOptions';
import FormikInput from '../../reusable/FormComponents/FormikInput';

const optionsMapper = {
  ethnicity: ethnicityOptions,
  education: educationOptions,
  jobArea: occupationOptions,
  industry: industryOptions,
  firstLanguage: languageOptions,
  sector: sectorOptions,
};

const categoryOrder = {
  ethnicity: 0,
  education: 1,
  jobArea: 2,
  industry: 3,
  firstLanguage: 4,
  sector: 5,
};

const selectorMapper = {
  Ethnicity: 'ethnicity',
  Education: 'education',
  'Job Area': 'jobArea',
  Industry: 'industry',
  Language: 'firstLanguage',
  Sector: 'sector',
};

const getKeyByValue = (value) => {
  return Object.keys(selectorMapper).find((key) => selectorMapper[key] === value);
};

const BioDataTemplateModal = ({ template, onClose, resetPageStatus, onSuccess }) => {
  const mappedBioTemplates = {};
  template.bioOptions.forEach((bioCategory) => {
    if (bioCategory.values[0] !== '') {
      mappedBioTemplates[bioCategory.selector] = bioCategory;
    }
  });
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [biodataValues, setBiodataValues] = useState({ ...mappedBioTemplates });

  const [updatedCategories, setUpdatedCategories] = useState([]);

  // Assign every value.name as available modal
  const availableModals = template.bioOptions.map((item) => ({ state: 0, name: item.selector }));
  const [isModalsVisible, setIsModalsVisible] = useState(availableModals);

  const sortCategories = (categoryArray) => {
    const sortedCategories = [];

    categoryArray.forEach((category) => {
      const categoryIndex = categoryOrder[category.selector];
      sortedCategories[categoryIndex] = { label: category.label, selector: category.selector, values: category.values };
    });

    return sortedCategories;
  };

  const { touched, values, errors, handleSubmit, handleChange } = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: {
      templateName: (template && template.name) || '',
      description: (template && template.description) || '',
    },
    validationSchema: Yup.object().shape({
      templateName: Yup.string()
        .required(errorMessages.inputField)
        .max(80, errorMessages.tooLong(80)),
      description: Yup.string()
        .required(errorMessages.inputField)
        .max(100, errorMessages.tooLong(100)),
    }),
    onSubmit: ({ templateName, description }) => {
      const unmodifiedBiodataOptions = template.bioOptions.filter(
        ({ selector }) => !Object.keys(biodataValues).includes(selector),
      );
      const resetValues = [];
      unmodifiedBiodataOptions.forEach((item) => {
        resetValues.push({
          label: item.label,
          selector: item.selector,
          values: [''],
        });
      });

      const mappedOptions = Object.values(biodataValues);

      if (mappedOptions.length === 0) {
        createToastNotification({ message: 'At least one category must be selected', type: 'info' });
        return;
      }

      if (!template.id) {
        const newDate = new Date();
        const templateData = {
          userId: user.user.userID,
          testId: '',
          batteryId: '',
          name: templateName,
          isDefault: false,
          bioOptions: sortCategories([...mappedOptions, ...resetValues]),
          description,
          creation: newDate.toISOString(),
        };

        const hasModifiedCategories = mappedOptions.length > 0;

        if (!hasModifiedCategories) {
          createToastNotification({ message: 'At least one category must be selected', type: 'info' });
          return;
        }

        dispatch(
          createBiodataTemplate(templateData, () => {
            onClose();
            resetPageStatus();
          }),
        );
        return;
      }
      const updatedTemplateData = {
        id: template.id,
        userId: user.user.userID,
        testId: '',
        batteryId: '',
        name: templateName,
        isDefault: template.isDefault,
        bioOptions: sortCategories([...mappedOptions, ...resetValues]),
        description,
        creation: template.creation,
      };
      dispatch(
        updateBiodataTemplate(updatedTemplateData, () => {
          onSuccess();
          onClose();
          resetPageStatus();
        }),
      );
    },
  });

  const mapSelectedValues = (selector, values) => {
    const mappedValues = [];
    const bioOptions = optionsMapper[selector];
    values.forEach((selectedItem) => {
      if (selector === 'jobArea') {
        bioOptions.forEach((valueItem) => {
          valueItem.options.forEach((optionValue) => {
            if (optionValue.value === selectedItem) {
              mappedValues.push({ label: `${valueItem.label}${optionValue.label}`, value: selectedItem });
            }
          });
        });
      } else {
        bioOptions.forEach((valueItem) => {
          if (valueItem.value === selectedItem) {
            mappedValues.push({ label: valueItem.label, value: selectedItem });
          }
        });
      }
    });

    return mappedValues;
  };
  const mappedSelectorValueCodes = (selector) => {
    const mappedValues = [];
    const bioOptions = optionsMapper[selector];
    if (selector === 'jobArea') {
      bioOptions.forEach((valueItem) => {
        valueItem.options.forEach((optionValue) => {
          mappedValues.push(optionValue.value);
        });
      });
    } else {
      bioOptions.forEach((valueItem) => {
        mappedValues.push(valueItem.value);
      });
    }

    return mappedValues;
  };

  const openAddTagsModal = (categoryName) => {
    const categoryIndex = isModalsVisible.findIndex((item) => item.name === categoryName);

    setIsModalsVisible(() => {
      isModalsVisible[categoryIndex].state = 1;
      return [...isModalsVisible];
    });
  };

  const closeAddTagsModal = (categoryName) => {
    const categoryIndex = isModalsVisible.findIndex((item) => item.name === categoryName);

    setIsModalsVisible(() => {
      isModalsVisible[categoryIndex].state = 0;
      return [...isModalsVisible];
    });
  };

  const onOptionsSave = (selectedValues, categoryLabel, categorySelector) => {
    closeAddTagsModal(categorySelector);
    const categoryCodeValues = selectedValues.map((item) => item.value);

    const currentObject = { ...biodataValues };
    currentObject[categorySelector] = {
      label: getKeyByValue(categorySelector),
      selector: categorySelector,
      values: categoryCodeValues,
    };
    setBiodataValues(currentObject);
  };

  useEffect(() => {
    const unmodifiedBiodataOptions = template.bioOptions.filter(
      ({ selector }) => !Object.keys(biodataValues).includes(selector),
    );
    const resetValues = [];
    unmodifiedBiodataOptions.forEach((item) => {
      resetValues.push({
        label: item.label,
        selector: item.selector,
        values: [''],
      });
    });

    const mappedOptions = Object.values(biodataValues);

    setUpdatedCategories(sortCategories([...mappedOptions, ...resetValues]));
    return () => {};
  }, [biodataValues]);

  const handleBiodataCategoryOption = (category) => {
    const defaultCategoryValues = { ...category };
    defaultCategoryValues.values = mappedSelectorValueCodes(category.selector);
    if (Object.keys(biodataValues).includes(category.selector)) {
      const currentObject = { ...biodataValues };
      delete currentObject[category.selector];
      setBiodataValues(currentObject);
      return;
    }

    setBiodataValues((prevState) => ({ ...prevState, [category.selector]: defaultCategoryValues }));
  };

  const availableOptions = updatedCategories.length > 0 ? updatedCategories : template.bioOptions;

  return (
    <Modal isVisible onClose={onClose} title={I18n.t('Add Bio Data template')}>
      <Form>
        <StyledInput
          inputName={I18n.t('Add Name')}
          name="templateName"
          id="templateName"
          value={values.templateName}
          onChange={handleChange}
          errors={errors}
          touched={touched}
        />
        <StyledCustomTextarea
          inputName={I18n.t('Add description')}
          name="description"
          id="description"
          value={values.description}
          onChange={handleChange}
          errors={errors}
          touched={touched}
        />
      </Form>
      <StyledInstructions>{I18n.t('selectEditCategoryValues')}</StyledInstructions>
      {availableOptions.map((category, index) => (
        <PseudoInput key={category.selector}>
          <Row
            onClick={() => {
              handleBiodataCategoryOption(category);
            }}
          >
            <StyledCheckbox isChecked={Object.keys(biodataValues).includes(category.selector)} />
          </Row>
          <AddButton
            variant="secondary"
            handler={() => openAddTagsModal(category.selector)}
            disabled={!Object.keys(biodataValues).includes(category.selector)}
          >
            {I18n.t(category.label)}
          </AddButton>
          {!!isModalsVisible[index].state && (
            <BioDataCategoryModal
              allOptions={optionsMapper[category.selector]}
              onClose={() => closeAddTagsModal(category.selector)}
              onSave={onOptionsSave}
              categoryName={I18n.t(category.label)}
              categorySelector={category.selector}
              selectedValues={mapSelectedValues(category.selector, category.values)}
            />
          )}
        </PseudoInput>
      ))}
      <ButtonsWrapper>
        <StyledButton variant="secondary" onClick={onClose}>
          {I18n.t('Cancel')}
        </StyledButton>
        <StyledButton type="button" variant="primary" onClick={handleSubmit}>
          {I18n.t('Save')}
        </StyledButton>
      </ButtonsWrapper>
    </Modal>
  );
};

const Modal = styled(NewModal)`
  display: flex;
  flex-direction: column;
  min-height: 40rem;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledButton = styled(CustomButton)`
  margin-left: 1rem;
  width: 12.5rem;
`;

const PseudoInput = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2.4rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  transition: 0.2s all;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const StyledInstructions = styled.span`
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.darkBlue2};
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 20px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 1rem;
`;

const AddButton = styled(CustomButton)`
  width: 100%;
  min-width: 10rem;
  padding: 0.8rem;
  display: flex;
  align-self: flex-start;
`;

const StyledInput = styled(FormikInput)`
  margin-bottom: 2rem;
  width: 100%;
`;

const StyledCustomTextarea = styled(CustomTextArea)`
  margin-bottom: 10px;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

BioDataTemplateModal.propTypes = {
  template: object.isRequired,
  onClose: func.isRequired,
  resetPageStatus: func.isRequired,
  onSuccess: func.isRequired,
};

export default BioDataTemplateModal;
