const accountTabs = [
  {
    name: 'general',
    label: 'General',
    path: '/settings/general',
  },

  {
    name: 'creditHistory',
    label: 'credit updates',
    path: '/settings/credits-history',
  },

  {
    name: 'requestedReports',
    label: 'Reports Stats',
    path: '/settings/reports-usage',
  },

  {
    name: 'assessmentsUsage',
    label: 'Assessments Stats',
    path: '/settings/assessment-usage',
  },

  {
    name: 'activityLog',
    label: 'Activity Log',
    path: '/settings/activity-log',
  },
];

export default accountTabs;
