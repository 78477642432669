import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import { I18n } from 'react-redux-i18n';

import styled from 'styled-components';
import Table from '../../reusable/Tables/Table';

import useModals from '../../../hooks/useModals';
import * as actions from '../../../table/actionsList';
import BioDataTemplateModal from './BioDataTemplateModal';

import accountTabs from './accountTabs';
import PageWrapper from '../../PageWrapper';
import TableTabs from '../../reusable/TableTabs';
import Spinner from '../../reusable/Spinner';
import BulkActionsSidebar from '../../BulkActionsSidebar';
import ConfirmationModal from '../../reusable/ConfirmationModal';

import bioDataTemplateColumns from '../../../table/columns/biodataTemplate';
import biodataTemplatesBulkActions from '../../../table/bulkActions/biodateTemplates';
import useBulkSidebarState from '../../../hooks/useBulkSidebarState';

import { deleteBiodataTemplate, updateDefaultBiodataTemplate, fetchUser } from '../../../store/user/actions';

import {
  ethnicityOptions,
  educationOptions,
  occupationOptions,
  sectorOptions,
  industryOptions,
  languageOptions,
} from '../../../constants/respondentSelectOptions';

const CUSTOM_USER_OPTIONS = [
  {
    label: I18n.t('Add Bio Data template'),
    name: actions.CHANGE_BIODATA_OPTIONS,
  },
];

const RespondentBioDataConfiguration = () => {
  const dispatch = useDispatch();

  const [renderKey, setRenderKey] = useState(Math.round(Math.random() * 1000));

  const availableBiodataTemplates = useSelector((state) => state.user.biotemplates);
  const user = useSelector((state) => state.user);

  const [bioTemplateData, setBioTemplateData] = useState({});
  const [bioModalOption, setBioModalOption] = useState('add');
  const [shouldResetPage, setShouldResetPageStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedBiodataTemplates, setSelectedBiodataTemplates] = useState([]);

  const allOccupationOptions = occupationOptions.reduce((acc, item) => {
    return [...acc, ...item.options];
  }, []);

  useEffect(() => {
    dispatch(
      fetchUser(() => {
        setRenderKey(Math.round(Math.random() * 1000));
        setIsLoading(false);
      }),
    );

    return () => {};
  }, [shouldResetPage]);

  const goBackToSettings = () => dispatch(push('/settings'));
  const onPageTabClick = (tab) => dispatch(push(tab.path));

  const defaultBiodataTemplate = {
    name: '',
    isDefault: false,
    bioOptions: [
      {
        label: 'Ethnicity',
        selector: 'ethnicity',
        values: ethnicityOptions.map((option) => option.value),
      },
      {
        label: 'Education',
        selector: 'education',
        values: educationOptions.map((option) => option.value),
      },
      {
        label: 'Job Area',
        selector: 'jobArea',
        values: allOccupationOptions.map((option) => option.value),
      },
      {
        label: 'Industry',
        selector: 'industry',
        values: industryOptions.map((option) => option.value),
      },
      {
        label: 'Language',
        selector: 'firstLanguage',
        values: languageOptions.map((option) => option.value),
      },
      {
        label: 'Sector',
        selector: 'sector',
        values: sectorOptions.map((option) => option.value),
      },
    ],
    description: '',
    creation: '',
    userID: 123,
  };

  const modals = [actions.CHANGE_BIODATA_OPTIONS, actions.DELETE_BIODATA_TEMPLATE, actions.UPDATE_DEFAULT_BIO_TEMPLATE];
  const { modalsState, openModal, closeModal } = useModals(modals);

  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedBiodataTemplates });

  const tableHandlers = {
    [actions.CHANGE_BIODATA_OPTIONS]: () => {
      setBioTemplateData(defaultBiodataTemplate);
      openModal(actions.CHANGE_BIODATA_OPTIONS);
    },
  };
  const tableActions = useMemo(() => {
    return CUSTOM_USER_OPTIONS.map((item) => ({ ...item, handler: tableHandlers[item.name] }));
  }, []);

  const bulkActionsHandlers = {
    [actions.UPDATE_DEFAULT_BIO_TEMPLATE]: () => openModal(actions.UPDATE_DEFAULT_BIO_TEMPLATE),
    [actions.CHANGE_BIODATA_OPTIONS]: () => openModal(actions.CHANGE_BIODATA_OPTIONS),
    [actions.DELETE_BIODATA_TEMPLATE]: () => openModal(actions.DELETE_BIODATA_TEMPLATE),
  };

  const bulkActions = useMemo(() => {
    return biodataTemplatesBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedBiodataTemplates) : item.isDisabled,
    }));
  }, [selectedBiodataTemplates]);

  const showBioTemplate = (template) => {
    setBioModalOption('edit');
    const { id } = template;
    setBioTemplateData(availableBiodataTemplates.find((item) => item.id === id));
    openModal(actions.CHANGE_BIODATA_OPTIONS);
  };

  const onBiodataTemplateDelete = () => {
    const templateId = selectedBiodataTemplates.map((item) => item.id);
    const bodyParams = {
      userId: user.user.userID,
      biodataFieldConfigurationId: templateId[0],
      testId: '',
      batteryId: '',
    };
    dispatch(
      deleteBiodataTemplate(bodyParams, () => {
        closeModal(actions.DELETE_BIODATA_TEMPLATE);
        setShouldResetPageStatus(true);
        closeSidebar();
      }),
    );
  };

  const onDefaultBiodataTemplateUpdate = () => {
    const selectedTemplate = selectedBiodataTemplates[0];
    const data = {
      userId: selectedTemplate.userId,
      templateId: selectedTemplate.id,
    };
    dispatch(
      updateDefaultBiodataTemplate(data, () => {
        closeModal(actions.UPDATE_DEFAULT_BIO_TEMPLATE);
        setShouldResetPageStatus(true);
        closeSidebar();
      }),
    );
    dispatch(fetchUser(), setIsLoading(true));
  };

  return (
    <PageWrapper
      key={renderKey}
      title={I18n.t('Respondents')}
      backButtonHandler={goBackToSettings}
      buttons={tableActions}
    >
      <Spinner isLoading={isLoading} full text={`${I18n.t('Loading biodata templates')}...`} />
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      <TableTabs tabs={accountTabs} activeTab={accountTabs[0]} clickHandler={onPageTabClick} />
      <StyledTable
        columns={bioDataTemplateColumns}
        data={availableBiodataTemplates}
        onRowClick={(template) => showBioTemplate(template)}
        interceptCheckboxClick
        onSelectChange={setSelectedBiodataTemplates}
        entityName="template"
        shouldResetPage={shouldResetPage}
        setShouldReset={setShouldResetPageStatus}
        totalCount={0}
        localData
      />
      {modalsState[actions.CHANGE_BIODATA_OPTIONS] && (
        <BioDataTemplateModal
          isCreation={bioModalOption === 'add'}
          template={bioModalOption === 'add' ? defaultBiodataTemplate : bioTemplateData}
          onClose={() => closeModal(actions.CHANGE_BIODATA_OPTIONS)}
          resetPageStatus={() => setShouldResetPageStatus(true)}
          onSuccess={() => setShouldResetPageStatus(true)}
        />
      )}
      <ConfirmationModal
        isVisible={modalsState[actions.UPDATE_DEFAULT_BIO_TEMPLATE]}
        title={I18n.t('Update default biodata template')}
        description={`${I18n.t('Are you sure you want to set this template as default?')}`}
        onClose={() => closeModal(actions.UPDATE_DEFAULT_BIO_TEMPLATE)}
        onConfirm={() => onDefaultBiodataTemplateUpdate()}
        caution
      />
      <ConfirmationModal
        isVisible={modalsState[actions.DELETE_BIODATA_TEMPLATE]}
        title={I18n.t('Delete biodata templates')}
        description={`${I18n.t('Are you sure you want to delete')} ${
          selectedBiodataTemplates.length > 1
            ? I18n.t('the selected biodata templates?')
            : I18n.t('this biodata template?')
        }`}
        onClose={() => closeModal(actions.DELETE_BIODATA_TEMPLATE)}
        onConfirm={() => onBiodataTemplateDelete()}
      />
    </PageWrapper>
  );
};

const StyledTable = styled(Table)`
  tr:hover {
    & > div div {
      background: rgba(241, 241, 241, 0.1);
    }
  }
`;

RespondentBioDataConfiguration.propTypes = {};

export default RespondentBioDataConfiguration;
