import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Modal from '../reusable/NewModal';
import Button from '../reusable/Buttons/Button';
import Steps from '../reusable/Steps';
import useStep from '../../hooks/useStep';
import apiInstance2 from '../../services/apiService';
import Set from './Set';
import ReportsCreditsCost from '../reusable/ReportsCreditsCost';
import createToastNotification from '../../utils/createToastNotification';
import { fetchUser } from '../../store/user/actions';
import { selectUserCredits } from '../../store/user/selectors';

const RequestReport360 = ({ onClose, eligibleSessions, projectId, successCallback }) => {
  const dispatch = useDispatch();
  const availableCredits = useSelector(selectUserCredits);
  const steps = ['sessions', 'reports'];
  const [availableReportSets, setAvailableReportSets] = useState([]);
  const [, setCredits] = useState(0);
  const [isSubmitting, setSubmittingState] = useState(false);
  const [applicableCostUnits, setApplicableCostUnits] = useState([]);
  const selectedSets = availableReportSets.filter((set) => set.isChecked);
  const isSingleSession = eligibleSessions.length === 1 ? 1 : 0;

  const { step, goNext, resetSteps } = useStep({ steps });

  const onCloseHandler = () => {
    resetSteps();
    onClose();
  };

  const resetAvailableReports = () => {
    setAvailableReportSets([]);
  };

  const onSetClick = (id) => {
    setAvailableReportSets((previousSets) =>
      previousSets.map((set) => ({ ...set, isOpen: id === set.reportSetID ? !set.isOpen : set.isOpen })),
    );
  };

  const onSetCheckboxClick = (setId) => {
    setAvailableReportSets((previousSets) => {
      return previousSets.map((set) => {
        return set.reportSetID === setId
          ? {
              ...set,
              isChecked: !set.isChecked,
              reports: set.reports.map((report) => ({
                ...report,
                isChecked: !set.isChecked,
              })),
            }
          : set;
      });
    });
  };

  const onSetReportClick = (setId, reportId) => {
    setAvailableReportSets((previousSets) =>
      previousSets.map((set) => {
        if (set.reportSetID !== setId) return set;

        // const wasChildChecked = set.reports.find((report) => report.reportID === reportId).isChecked;
        // const checkedChildren = set.reports.filter((report) => report.isChecked);

        const checkedReports = set.reports.map((report) => {
          return report.reportID === reportId ? { ...report, isChecked: !report.isChecked } : report;
        });
        const allChildrenChecked = checkedReports.some((report) => report.isChecked);

        return {
          ...set,
          // isChecked: !(set.isChecked && wasChildChecked && checkedChildren.length <= 1),
          isChecked: allChildrenChecked,
          reports: checkedReports,
        };
      }),
    );
  };

  useEffect(() => {
    apiInstance2
      .get(`/api/v2/Reports/available?testIds=360D`)
      .then((response) => {
        setAvailableReportSets(
          response.data.reportSets.map((item) => {
            return {
              ...item,
              isOpen: false,
              isChecked: false,
              reports: item.reportIDArray.map((id) => ({
                ...response.data.reports.find((report) => report.reportID === id),
                isChecked: false,
              })),
            };
          }),
        );
      })
      .catch(resetAvailableReports);
    return resetAvailableReports;
  }, []);

  useEffect(() => {
    const eligibleSessionsString = eligibleSessions.reduce((acc, next, index) => {
      const str = index ? `&sessionIds=${next.sessionId}` : `sessionIds=${next.sessionId}`;
      return acc + str;
    }, '');

    apiInstance2.get(`/api/v2/360/projects/${projectId}/sessions/costs?${eligibleSessionsString}`).then((response) => {
      setApplicableCostUnits(response.data);
    });
  }, [projectId, eligibleSessions]);

  useEffect(() => {
    if (availableReportSets.length) {
      const selectedSetsCostAmount = selectedSets.reduce((acc, next) => {
        return acc + next.creditsCost;
      }, 0);

      const applicableCostAmount = applicableCostUnits.reduce((acc, next) => {
        const applicableCostSelf = [next.self].filter((unit) => !unit.paid).length * next.raterCosts;
        const applicableCostRaters = next.raters.filter((unit) => !unit.paid).length * next.raterCosts;

        return acc + applicableCostSelf + applicableCostRaters;
      }, 0);

      setCredits(applicableCostAmount * selectedSetsCostAmount);
    }

    return () => {
      setCredits(0);
    };
  }, [availableReportSets]);

  const requestReports = () => {
    const requestData = {
      reportRequests: availableReportSets.reduce((acc, next) => {
        const requestedReports = next.reports
          .filter((report) => report.isChecked)
          .map((report) => ({ reportID: report.reportID, reportSetID: next.reportSetID }));

        acc.push(...requestedReports);
        return acc;
      }, []),
      sessionIDs: eligibleSessions.map((session) => session.sessionId),
    };
    setSubmittingState(true);
    apiInstance2
      .post(`/api/v2/360/projects/${projectId}/sessions/reportrequests`, requestData)
      .then(() => {
        createToastNotification({ message: I18n.t('createdRequestToast') });
        dispatch(fetchUser());
        onCloseHandler();
        if (successCallback) successCallback();
      })
      .catch(() => {
        onCloseHandler();
        if (successCallback) successCallback();
      })
      .finally(() => setSubmittingState(false));
  };

  const totalCost = useMemo(() => applicableCostUnits.reduce((acc, item) => acc + item.totalSessionCosts, 0), [
    applicableCostUnits,
  ]);

  if (availableReportSets.length === 0) return null;

  const title = isSingleSession
    ? `${I18n.t('Report Request for')} ${
        eligibleSessions[0].self ? eligibleSessions[0].self.firstName : eligibleSessions[0].firstName
      } ${eligibleSessions[0].self ? eligibleSessions[0].self.familyName : eligibleSessions[0].familyName}`
    : I18n.t('Report Request');
  return (
    <StyledModal title={title} isVisible onClose={onCloseHandler}>
      <Body>
        <Steps steps={steps} stepIndex={step} />
        {step === 0 && (
          <Table>
            <thead>
              <tr>
                <th>{I18n.t('Self')}</th>
                <th>{I18n.t('Email')}</th>
              </tr>
            </thead>
            <tbody>
              {eligibleSessions.map((session) => (
                <tr>
                  <td>{`${session.self ? session.self.firstName : session.firstName} ${
                    session.self ? session.self.familyName : session.familyName
                  }`}</td>
                  <td>{session.self ? session.self.email : session.email}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {step === 1 && (
          <div>
            <SelectWrapper>
              <div>
                {availableReportSets.map((set) => (
                  <Set
                    set={set}
                    onSetClick={onSetClick}
                    onSetCheckboxClick={onSetCheckboxClick}
                    onReportClick={onSetReportClick}
                    isWithoutCost
                  />
                ))}
              </div>
            </SelectWrapper>
          </div>
        )}
      </Body>
      <Footer>
        {step === 0 && (
          <>
            <InvisibleBlock />
            <Button onClick={goNext}>{I18n.t('Next')}</Button>
          </>
        )}
        {step === 1 && (
          <>
            <ReportsCreditsCost creditsCost={totalCost} creditsAvailable={availableCredits} />
            <Button onClick={requestReports} disabled={!selectedSets.length || isSubmitting}>
              {I18n.t('Request')}
            </Button>
          </>
        )}
      </Footer>
    </StyledModal>
  );
};

const Table = styled.table`
  width: 100%;

  th,
  td {
    padding: 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    border-bottom: 1px solid #f2f2f2;
  }
  th {
    color: #008ac0;
  }
  td {
    color: #295368;
  }
`;

const InvisibleBlock = styled.div``;

const SelectWrapper = styled.div`
  padding: 15px 0;
`;

const StyledModal = styled(Modal)`
  position: relative;
  padding-bottom: calc(3.6rem + 68px);
`;

const Body = styled.div``;

const Footer = styled.div`
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom-left-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
  position: absolute;
  height: 68px;
  background: #f9f9f9;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default RequestReport360;
