import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import ConfirmationModal from '../reusable/ConfirmationModal';
import competencyGroupsBulkActions from '../../table/bulkActions/competencyGroups';
import BulkActionsSidebar from '../BulkActionsSidebar';
import useBulkSidebarState from '../../hooks/useBulkSidebarState';
import PageWrapper from '../PageWrapper';
import {
  fetchCompetencyGroupsAction,
  restCompetencyGroups,
  deleteAssessments,
  duplicateCompetency,
} from '../../store/settings/competency-groups/actions';
import competencyGroupsSelectors from '../../store/settings/competency-groups/selectors';
import SettingsTabs from './components/SettingsTabs/SettingsTabs';
import competencyGroupsColumns from '../../table/columns/competencyGroups';
import Table from '../reusable/Tables/Table';
import customGroupsTableActions from '../../table/tableActions/customGroups';
import AddCompetencyGroup from '../AddCompetencyGroup/AddCompetencyGroup';
import * as actions from '../../table/actionsList';

import useModals from '../../hooks/useModals';
import createToastNotification from '../../utils/createToastNotification';

const CompetencyGroups = () => {
  const dispatch = useDispatch();

  const [isModal, setIsModal] = useState(false);
  const [selected, setSelected] = useState([]);

  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selected });

  const modals = [actions.DELETE_COMPETENCY_GROUPS, actions.DUPLICATE_COMPETENCY];
  const { modalsState, openModal, closeModal } = useModals(modals);

  const groups = useSelector(competencyGroupsSelectors.selectGroups);
  const isLoading = useSelector(competencyGroupsSelectors.selectIsLoading);

  useEffect(() => {
    dispatch(fetchCompetencyGroupsAction());

    return () => {
      dispatch(restCompetencyGroups());
    };
  }, []);

  const onSelectChange = useCallback((data) => setSelected(data), []);

  const memoizedNavigateToGroupDetails = useCallback((data) => {
    dispatch(push(`/settings/360-assessments/${data.competencyGroupID}`));
  }, []);

  const tableActions = useMemo(() => {
    return customGroupsTableActions.map((item) => ({
      ...item,
      label: I18n.t(item.label),
      handler: () => setIsModal(true),
    }));
  }, []);

  const bulkActionsHandlers = {
    [actions.DUPLICATE_COMPETENCY]: () => openModal(actions.DUPLICATE_COMPETENCY),
    [actions.DELETE_COMPETENCY_GROUPS]: () => {
      const activeAssessments = selected.filter((item) => item.projectsCount > 0);
      if (selected.length === activeAssessments.length) {
        createToastNotification({
          title: I18n.t('Deletion blocked'),
          type: 'error',
          message: I18n.t('surveyDeletionCancelToast'),
        });
        return;
      }
      openModal(actions.DELETE_COMPETENCY_GROUPS);
    },
  };

  const bulkActions = useMemo(() => {
    return competencyGroupsBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selected) : item.isDisabled,
    }));
  }, [selected]);

  const onAssessmentsDelete = () => {
    const inactiveAssessments = selected.filter((item) => item.projectsCount === 0);
    dispatch(
      deleteAssessments(
        inactiveAssessments.map((item) => item.competencyGroupID),
        () => {
          closeModal(actions.DELETE_COMPETENCY_GROUPS);
        },
      ),
    );
  };
  const onAssessmentsDuplicate = () => {
    const newName = `${I18n.t('Copy of')} ${selected[0].name}`;
    dispatch(
      duplicateCompetency(selected[0].competencyGroupID, newName, (err, newCompetencyId) => {
        if (err) closeModal(actions.DUPLICATE_COMPETENCY);
        else memoizedNavigateToGroupDetails({ competencyGroupID: newCompetencyId });
      }),
    );
  };

  const onAddNewGroup = () => {
    dispatch(fetchCompetencyGroupsAction());
    setIsModal(false);
  };

  return (
    <PageWrapper
      title={I18n.t('360 settings')}
      backButtonHandler={() => dispatch(push(`/settings`))}
      buttons={tableActions}
    >
      <SettingsTabs />
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      <Table
        data={groups}
        isLoading={isLoading}
        onFetchData={() => {}}
        columns={competencyGroupsColumns}
        idAccessor="competencyGroupsId"
        scrollContainerHeight="100%"
        entityName="competency group"
        totalCount={groups.length}
        pageCount={1}
        onRowClick={memoizedNavigateToGroupDetails}
        onSelectChange={onSelectChange}
        appliedFilters={[]}
        interceptCheckboxClick
      />
      {isModal && <AddCompetencyGroup onClose={onAddNewGroup} onCancel={() => setIsModal(false)} />}

      <ConfirmationModal
        isVisible={modalsState[actions.DELETE_COMPETENCY_GROUPS]}
        title={I18n.t('Delete assessment')}
        description={`${I18n.t('Are you sure you want to delete')} ${
          selected.length > 1 ? I18n.t('selected assessments') : I18n.t('this assessment')
        }?`}
        onClose={() => closeModal(actions.DELETE_COMPETENCY_GROUPS)}
        onConfirm={onAssessmentsDelete}
        caution
      />
      <ConfirmationModal
        isVisible={modalsState[actions.DUPLICATE_COMPETENCY]}
        title={I18n.t('Duplicate 360 survey')}
        description={`${I18n.t('Are you sure you want to duplicate')} ${selected[0] ? selected[0].name : ''} ${I18n.t(
          '360 survey',
        )}`}
        onClose={() => closeModal(actions.DUPLICATE_COMPETENCY)}
        onConfirm={onAssessmentsDuplicate}
        caution
      />
    </PageWrapper>
  );
};

export default CompetencyGroups;
