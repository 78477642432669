import React from 'react';
import { any, arrayOf, func, shape, string } from 'prop-types';
import styled, { css } from 'styled-components';
import { I18n } from 'react-redux-i18n';

const TableTabs = ({ tabs, activeTab, clickHandler, children, ...props }) => {
  return (
    <Wrapper {...props}>
      <MainPart>
        {tabs.map((item, index) => (
          <Tab
            key={item.name}
            isActive={activeTab.name === item.name}
            onClick={() => !item.isDisabled && clickHandler(item, index)}
            isDisabled={item.isDisabled}
          >
            {I18n.t(item.label)}
          </Tab>
        ))}
      </MainPart>
      <div>{children}</div>
    </Wrapper>
  );
};

const tabShape = {
  label: any.isRequired,
  name: string.isRequired,
};

TableTabs.propTypes = {
  tabs: arrayOf(shape(tabShape)).isRequired,
  activeTab: shape(tabShape).isRequired,
  clickHandler: func.isRequired,
  children: any,
};

TableTabs.defaultProps = {
  children: null,
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #f2f2f2;
  min-height: 35px;

  // Make checkboxes column smaller
  ${(props) =>
    props.withChildren &&
    css`
      justify-content: space-between;
    `}
`;

const MainPart = styled.div`
  display: flex;
  flex-grow: 1;
`;

const Tab = styled.div`
  margin-right: 2rem;
  padding: 1rem 0;
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) => (props.isActive ? props.theme.colors.lightBlue : props.theme.colors.darkBlue2)};
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  transition: all 0.2s;
  border-bottom: 2px solid ${(props) => (props.isActive ? props.theme.colors.lightBlue : 'transparent')};
  :hover {
    cursor: ${(props) => !props.isDisabled && 'pointer'};
    color: ${(props) => !props.isActive && !props.isDisabled && props.theme.colors.lightBlue};
  }
`;

export default TableTabs;
