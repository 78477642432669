import React from 'react';
import { format } from 'date-fns';
import styled, { css, keyframes } from 'styled-components';

import { I18n } from 'react-redux-i18n';
import { caseInsensitiveSortingFunc } from './respondents';
import { ReactComponent as ProgressIcon } from '../../assets/icons/progress.svg';
import { ReactComponent as UnreadIcon } from '../../assets/icons/unread.svg';
import { ReactComponent as CompletedIcon } from '../../assets/icons/check.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg';
import { formatIconsMap } from './reports';
import { findReportFormat } from '../bulkActions/reports';

const statusesMap = [I18n.t('Pending'), I18n.t('Unread'), I18n.t('Viewed'), I18n.t('Error')];
const iconsMap = [ProgressIcon, UnreadIcon, CompletedIcon, WarningIcon];

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
   100% {
     transform: rotate(360deg);
   }
`;

const animation = () =>
  css`
    ${rotate} 2s infinite linear;
  `;

const styleIcon = (Icon, status) => styled(Icon)`
  margin-right: 10px;
  width: 18px;
  height: 18px;
  animation: ${() => (status === 1 ? animation : 'none')};

  .a {
    ${() =>
      status === 4 &&
      css`
        fill: ${(props) => props.theme.colors.red};
        stroke: ${(props) => props.theme.colors.red};
        stroke-width: 0.3px;
      `}
  }
`;

const StatusCell = ({ status, error }) => {
  const finalizedStatus = error ? 4 : Number(status);
  const Icon = styleIcon(iconsMap[finalizedStatus - 1], finalizedStatus);

  return (
    <StatusContainer>
      <Icon />
      <StatusLabel error={error}>
        {finalizedStatus === 4 ? I18n.t('Error. Support ticket raised.') : statusesMap[finalizedStatus - 1]}
      </StatusLabel>
    </StatusContainer>
  );
};

const columns = [
  {
    Header: I18n.t('Name'),
    name: 'questionnaire',
    Cell: ({ cell }) => {
      const Icon = formatIconsMap[findReportFormat(cell.row.original)];
      return (
        <Row>
          {cell.row.original.downloadLink && <Icon />}
          <span>{cell.row.original.questionnaire}</span>
        </Row>
      );
    },
    type: 'text',
    sortType: caseInsensitiveSortingFunc,
  },
  {
    Header: I18n.t('Status'),
    accessor: 'reportType',
    name: 'reportType',
    Cell: ({ cell }) => {
      return <StatusCell status={cell.row.original.reportType} error={cell.row.original.error} />;
    },
    type: 'number',
  },
  {
    Header: I18n.t('Creation Date'),
    accessor: (data) => new Date(data.completedDate),
    Cell: ({ row }) => (
      <span>{row.original.creationDate ? format(new Date(row.original.creationDate), 'dd/M/y') : ''}</span>
    ),
    name: 'creationDate',
    sortType: 'datetime',
    type: 'date',
    filteringName: 'CreationDate',
    width: 100,
  },
];

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StatusLabel = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => (props.error ? 'red' : props.theme.colors.primary)};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

export default columns;
