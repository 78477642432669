import { I18n } from 'react-redux-i18n';
import { ADD_PROJECT } from '../actionsList';

const projectsTableActions = [
  {
    name: ADD_PROJECT,
    label: I18n.t('Add new project'),
  },
];

export default projectsTableActions;
