import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import { I18n } from 'react-redux-i18n';

import NewModal from '../../reusable/NewModal';
import PageWrapper from '../../PageWrapper';

const tutorialsList = [
  {
    id: 'GS2020+Tutorial+1+-+Introduction+and+Overview.mp4',
    url:
      'https://gs2020storage.blob.core.windows.net/gs2020container/GS2020%20Tutorial%201%20-%20Introduction%20and%20Overview(1).mp4',
    title: 'GS2020 Tutorial 1 - Introduction and Overview',
    thumbnail:
      'https://gs2020storage.blob.core.windows.net/gs2020container/GS2020%20Tutorial%201%20-%20IntroductionandOverview.png',
  },
  {
    id: 'GS2020+Tutorial+2+-+Adding+Respondents.mp4',
    url:
      'https://gs2020storage.blob.core.windows.net/gs2020container/GS2020%20Tutorial%202%20-%20Adding%20Respondents.mp4',
    title: 'GS2020 Tutorial 2 - Adding Respondents',
    thumbnail:
      'https://gs2020storage.blob.core.windows.net/gs2020container/GS2020%20Tutorial%202%20-%20AddingRespondents.png',
  },
  {
    id: 'GS2020+Tutorial+3+-+Adminstering+Assessments+to+Individuals.mp4',
    url:
      'https://gs2020storage.blob.core.windows.net/gs2020container/GS2020%20Tutorial%203%20-%20Adminstering%20Assessments%20to%20Individuals.mp4',
    title: 'GS2020 Tutorial 3 - Adminstering Assessments to Individuals',
    thumbnail:
      'https://gs2020storage.blob.core.windows.net/gs2020container/GS2020%20Tutorial%203%20-%20AdminsteringAssessmentstoIndividuals.png',
  },
  {
    id: 'GS2020 Tutorial 4 - Adminstering Assessments to Groups',
    url:
      'https://gs2020storage.blob.core.windows.net/gs2020container/GS2020%20Tutorial%204%20-%20Adminstering%20Assessments%20to%20Groups.mp4',
    title: 'GS2020 Tutorial 4 - Adminstering Assessments to Groups',
    thumbnail:
      'https://gs2020storage.blob.core.windows.net/gs2020container/GS2020%20Tutorial%204%20-%20Adminstering%20Assessments%20to%20Groups.png',
  },
  {
    id: 'GS2020 Tutorial 5 - Email Templates',
    url:
      'https://gs2020storage.blob.core.windows.net/gs2020container/GS2020%20Tutorial%205%20-%20Email%20Templates.mp4',
    title: 'GS2020 Tutorial 5 - Email Templates',
    thumbnail:
      'https://gs2020storage.blob.core.windows.net/gs2020container/GS2020%20Tutorial%205%20-%20Email%20Templates.png',
  },
  {
    id: 'GS2020 Tutorial 6 - Reporting',
    url: 'https://gs2020storage.blob.core.windows.net/gs2020container/GS2020%20Tutorial%206%20-%20Reporting.mp4',
    title: 'GS2020 Tutorial 6 - Reporting',
    thumbnail: 'https://gs2020storage.blob.core.windows.net/gs2020container/GS2020%20Tutorial%206%20-%20Reporting.png',
  },
  {
    id: 'GS2020 Tutorial 7 - Data Management',
    url:
      'https://gs2020storage.blob.core.windows.net/gs2020container/GS2020%20Tutorial%207%20-%20Data%20Management.mp4',
    title: 'GS2020 Tutorial 7 - Data Management',
    thumbnail:
      'https://gs2020storage.blob.core.windows.net/gs2020container/GS2020%20Tutorial%207%20-%20Data%20Management.png',
  },
  {
    id: 'GS2020 Tutorial 8 - Customisation',
    url: 'https://gs2020storage.blob.core.windows.net/gs2020container/GS2020%20Tutorial%208%20-%20Customisation.mp4',
    title: 'GS2020 Tutorial 8 - Customisation',
    thumbnail:
      'https://gs2020storage.blob.core.windows.net/gs2020container/GS2020%20Tutorial%208%20-%20Customisation.png',
  },
  {
    id: 'GS2020 Tutorial 9 - Ideal Profiles',
    url: 'https://gs2020storage.blob.core.windows.net/gs2020container/GS2020%20Tutorial%209%20-%20Ideal%20Profiles.mp4',
    title: 'GS2020 Tutorial 9 - Ideal Profiles',
    thumbnail:
      'https://gs2020storage.blob.core.windows.net/gs2020container/GS2020%20Tutorial%209%20-%20Ideal%20Profiles.png',
  },
  {
    id: 'GS2020 Tutorial 10 Part 1 - 360',
    url: 'https://gs2020storage.blob.core.windows.net/gs2020container/GS2020%20Tutorial%2010%20_360_Part_1.mp4',
    title: 'GS2020 Tutorial 10 Part 1 - 360',
    thumbnail: 'https://gs2020storage.blob.core.windows.net/gs2020container/GS2020%20Tutorial%2010%20-%20360.png',
  },
  {
    id: 'GS2020 Tutorial 10 Part 2 - 360',
    url: 'https://gs2020storage.blob.core.windows.net/gs2020container/GS2020%20Tutorial%2010_360_Part_2.mp4',
    title: 'GS2020 Tutorial 10 Part 2 - 360',
    thumbnail: 'https://gs2020storage.blob.core.windows.net/gs2020container/GS2020%20Tutorial%2010%20-%20360_Part_2.png',
  },
  {
    id: 'GS2020 Tutorial 11 - Assessment Projects',
    url:
      'https://gs2020storage.blob.core.windows.net/gs2020container/GS2020%20Tutorial%2011%20-%20Assessment%20Projects.mp4',
    title: 'GS2020 Tutorial 11 - Assessment Projects',
    thumbnail:
      'https://gs2020storage.blob.core.windows.net/gs2020container/GS2020%20Tutorial%2011%20-%20Assessment%20Projects.png',
  },
  {
    id: 'GS2020 Tutorial 12 - Settings',
    url: 'https://gs2020storage.blob.core.windows.net/gs2020container/GS2020%20Tutorial%2012%20-%20Settings.mp4',
    title: 'GS2020 Tutorial 12 - Settings',
    thumbnail: 'https://gs2020storage.blob.core.windows.net/gs2020container/GS2020%20Tutorial%2012%20-%20Settings.png',
  },
];

const Templates = () => {
  const [selectedTutorial, setSelectedTutorial] = React.useState(null);
  const dispatch = useDispatch();
  const goBackToSettings = () => dispatch(push('/settings'));

  return (
    <PageWrapper backButtonHandler={goBackToSettings} title={I18n.t('Tutorials')}>
      <Container>
        <Body>
          <TutorialsGrid>
            {tutorialsList.map((video) => (
              <TutorialGridItem key={video.id} onClick={() => setSelectedTutorial(video)}>
                <TutorialGridItemContent>
                  <TutorialGridItemThumbnail>
                    <img src={video.thumbnail} alt={video.title} />
                  </TutorialGridItemThumbnail>
                  <TutorialGridItemTitle>{video.title}</TutorialGridItemTitle>
                </TutorialGridItemContent>
              </TutorialGridItem>
            ))}
          </TutorialsGrid>
        </Body>
      </Container>
      {selectedTutorial && (
        <NewModal
          title={selectedTutorial?.title}
          isVisible={!!selectedTutorial}
          onClose={() => setSelectedTutorial(null)}
        >
          <video
            onContextMenu={(e) => e.preventDefault()}
            title="Psytech Tutorials"
            width="1020"
            height="630"
            style={{ border: 0, borderRadius: '4px', overflow: 'hidden' }}
            controls
            controlsList="nodownload"
            src={selectedTutorial.url}
          >
            <track kind="captions" />
            Sorry, your browser doesnt support videos.
          </video>
        </NewModal>
      )}
    </PageWrapper>
  );
};

const Container = styled.div`
  display: flex;
  height: 100%;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  padding: 32px 24px;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  height: calc(100vh - 150px);
`;

const TutorialsGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
  grid-auto-rows: min-content;
  grid-auto-columns: min-content;

  @media (max-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const TutorialGridItem = styled.div`
  cursor: pointer;
`;

const TutorialGridItemContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TutorialGridItemThumbnail = styled.div`
  border-radius: 4px;
  overflow: hidden;
  font-size: 0;
  width: 100%;
  height: auto;

  img {
    width: 100%;
  }
`;

const TutorialGridItemTitle = styled.div`
  font-weight: 700;
  margin-top: 8px;
`;

export default Templates;
