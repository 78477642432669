import React from 'react';
import { any, string } from 'prop-types';
import styled from 'styled-components';

const ProjectModalFooter = ({ children, className }) => {
  return <FooterContainer className={className}>{children}</FooterContainer>;
};

ProjectModalFooter.propTypes = {
  children: any,
  className: string,
};

ProjectModalFooter.defaultProps = {
  children: null,
  className: '',
};

const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${(props) => props.theme.colors.grey6};
  padding: 2.4rem;
`;

export default ProjectModalFooter;
