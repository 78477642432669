import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import copy from 'copy-to-clipboard';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import RatersTableWrapper from '../components/RatersTableWrapper';
import SimplifiedTable from '../../reusable/Tables/SimplifiedTable';
import apiInstance2 from '../../../services/apiService';
import invitesColumns from './columns';
import { deleteInvites } from '../../../store/invites/actions';
import * as actions from '../../../table/actionsList';
import ConfirmationModal from '../../reusable/ConfirmationModal';
import BulkActionsSidebar from '../../BulkActionsSidebar';
import useBulkSidebarState from '../../../hooks/useBulkSidebarState';
import invitesBulkActions from '../../../table/bulkActions/invites';
import Spinner from '../../reusable/Spinner';
import TableEmptyState from '../../reusable/TableEmptyState';
import { createCopiedNotification } from '../../Invites/Invites';

const RatersInvites = ({ projectId, sessionId }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);
  const [selectedInvites, setSelectedInvites] = useState([]);

  const [invites, setInvites] = useState([]);
  const [inviteToDelete, setInviteToDelete] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const deleteInvitesCallback = useCallback((data, cb) => dispatch(deleteInvites(data, cb)), [dispatch]);

  const [isConfirmationModalVisible, setConfirmationModalVisibilityStatus] = useState(false);
  const openConfirmationModal = () => setConfirmationModalVisibilityStatus(true);

  const closeConfirmationModal = () => {
    if (inviteToDelete) setInviteToDelete(null);
    setConfirmationModalVisibilityStatus(false);
  };

  const fetchInvites = () => {
    setLoading(true);
    apiInstance2
      .get(`/api/v2/360/projects/${projectId}/sessions/${sessionId}/invites`)
      .then((response) => {
        setLoading(false);
        setInvites(response.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onDeleteConfirm = () => {
    const data = inviteToDelete
      ? [{ inviteID: inviteToDelete.inviteID, inviteType: inviteToDelete.inviteType }]
      : selectedInvites.map((item) => ({ inviteID: item.inviteID, inviteType: item.inviteType }));

    deleteInvitesCallback(data, () => {
      closeConfirmationModal();
      fetchInvites();
    });
  };

  useEffect(() => {
    fetchInvites();
  }, []);

  // columns settings
  const columns = useMemo(() => {
    return invitesColumns();
  }, [user]);

  const onChange = useCallback((data) => {
    setSelectedInvites(data);
  }, []);

  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedInvites });

  // BULK ACTIONS
  const bulkActionsHandlers = {
    [actions.DELETE_INVITE]: openConfirmationModal,
    [actions.COPY_LINK]: () => {
      copy(selectedInvites[0].downloadLink);
      createCopiedNotification();
    },
  };

  const bulkActions = useMemo(() => {
    return invitesBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedInvites) : item.isDisabled,
    }));
  }, [selectedInvites, user]);

  return (
    <RatersTableWrapper projectId={projectId} sessionId={sessionId} activeTab={1}>
      <BulkActionsSidebar actions={bulkActions} onClose={closeSidebar} isOpen={isSidebarOpen} />
      <TableWrapper>
        <SimplifiedTable
          columns={columns}
          data={invites}
          initialState={{
            sortBy: [{ id: 'Name', desc: true }],
          }}
          onSelectChange={onChange}
          idAccessor="inviteID"
          entityName="invite"
          bottomOffset={80}
          customEmptyMessage={<CustomEmptyState title={I18n.t('There are no invites in this session yet')} />}
        />
      </TableWrapper>
      <ConfirmationModal
        title={I18n.t('Invite Deletion')}
        onClose={closeConfirmationModal}
        isVisible={isConfirmationModalVisible}
        description={`${I18n.t('Are you sure you want to delete')} ${
          selectedInvites.length > 0 ? I18n.t('selected invite(s)?') : I18n.t('chosen invite(s)?')
        }`}
        onConfirm={onDeleteConfirm}
        caution
      />
      {isLoading && <Spinner isLoading text={I18n.t('Loading invites')} full />}
    </RatersTableWrapper>
  );
};

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100vh - 3.2rem - 56px);
`;

const CustomEmptyState = styled(TableEmptyState)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default RatersInvites;
