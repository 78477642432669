import { I18n } from 'react-redux-i18n';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as RequestReportIcon } from '../../assets/icons/request-report.svg';

import * as actions from '../actionsList';

const respondentTestsBulkActions = [
  {
    name: actions.REQUEST_REPORT,
    label: I18n.t('Request report(s)'),
    Icon: RequestReportIcon,
    isDisabled: (tests) => {
      return !tests.length;
    },
  },
  {
    name: actions.DELETE_COMPLETED_TEST,
    label: I18n.t('Delete selected result(s)'),
    Icon: DeleteIcon,
  },
];

export default respondentTestsBulkActions;
