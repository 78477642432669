import React from 'react';
import ReactTooltip from 'react-tooltip';
import { format, isAfter } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import isChineseEnv from '../../utils/isChineseEnv';

import { caseInsensitiveSortingFunc } from './respondents';
import inviteStatuses from '../../constants/inviteStatuses';

import { ReactComponent as WarningIcon } from '../../assets/icons/warning-blue.svg';

const statusOptions = Object.keys(inviteStatuses).map((key) => ({ value: key, label: inviteStatuses[key] }));

const bgStatusColors = {
  0: 'rgba(0,0,0,0.08)',
  1: '#E0F1F7',
  2: '#008AC0',
  3: 'rgba(176, 0, 32, 0,25)',
  4: 'rgba(176, 0, 32, 0,25)',
  6: '#B00020',
  7: '#0B8D00',
};

const statusFontColors = {
  0: 'rgba(0,0,0,0.6)',
  1: '#008AC0',
  2: '#FFF',
  3: '#B00020',
  4: '#B00020',
  6: '#FFF',
  7: '#FFF',
};

const reportStatusesLabels = (reportStatuses) => {
  const availableReportsLength = reportStatuses ? reportStatuses.filter((item) => item.reportStatus === 1).length : 0;
  return {
    0: I18n.t('Unavailable'),
    1: I18n.t('Available'),
    2: I18n.t('Failed'),
    3: I18n.t('Pending'),
    4: `${availableReportsLength}/${reportStatuses?.length} ${I18n.t('Available')}`,
  };
};

const reportStatusesColors = {
  0: 'rgba(0, 0, 0, 0.4)',
  1: '#0B8D00',
  2: '#B00020',
  3: '#E37F09',
  4: '#0B8D00',
};

const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const DateCell = ({ row }) => {
  if (!row.original.expiredDate) return <span>-</span>;
  const hasExpired = isAfter(new Date(), new Date(row.original.expiredDate));
  const color = hasExpired ? '#FFA4A4' : '#40C4FF';
  return (
    <span style={{ color }}>
      {format(utcToZonedTime(new Date(row.original.expiredDate), currentTimezone), 'dd/M/y HH:mm')}
    </span>
  );
};

// public enum AssessmentProjectReportStatus
// {
//   Pending = 0,
//     Succeed=1,
//     Failed=2
// }
const resolveReportStatus = (reportStatuses) => {
  if (!reportStatuses || !reportStatuses.length) return 0;
  if (reportStatuses.every((item) => item.reportStatus === 1)) return 1;
  if (reportStatuses.every((item) => item.reportStatus === 2)) return 2;
  if (reportStatuses.some((item) => item.reportStatus === 0)) return 3;
  if (reportStatuses.some((item) => item.reportStatus === 2)) return 4;
};

const columns = [
  {
    Header: I18n.t('PersonName'),
    accessor: (data) => (isChineseEnv ? `${data.familyName}${data.firstName}` : `${data.firstName} ${data.familyName}`),
    name: 'name',
    sortType: caseInsensitiveSortingFunc,
  },
  {
    Header: I18n.t('Email Address'),
    accessor: 'email',
    name: 'email',
    sortType: caseInsensitiveSortingFunc,
  },
  {
    Header: I18n.t('Invited'),
    accessor: (data) => new Date(data.invitedDate),
    Cell: ({ row }) => (
      <span>
        {row.original.invitedDate ? (
          format(utcToZonedTime(new Date(row.original.invitedDate), currentTimezone), 'dd/M/y HH:mm')
        ) : (
          <InviteNotSent>{I18n.t('Invite not sent')}</InviteNotSent>
        )}
      </span>
    ),
    name: 'invitationDate',
    sortType: 'datetime',
    width: 150,
  },
  {
    Header: I18n.t('Invite expires on'),
    name: 'expiryDate',
    accessor: (data) => new Date(data.expiryDate),
    Cell: (props) => {
      const { row } = props;
      if (row.original.completeStatus === 1) return '-';
      return <DateCell {...props} />;
    },
    sortType: 'datetime',
    width: 150,
  },
  {
    Header: I18n.t('Status'),
    name: 'status',
    Cell: ({ row }) => (
      <StyledStatus bgColor={bgStatusColors[row.original.status]} fontColor={statusFontColors[row.original.status]}>
        {I18n.t(statusOptions[row.original.status].label)}
      </StyledStatus>
    ),
  },
  {
    Header: I18n.t('Reports'),
    name: 'status',
    Cell: ({ row }) => {
      const status = resolveReportStatus(row.original.reportStatuses);
      const labels = reportStatusesLabels(row.original.reportStatuses);
      return (
        <ReportStatus color={reportStatusesColors[status]}>
          <span>{labels[status]}</span>
          {status === 4 && (
            <>
              <RedWarningIcon data-tip data-for="warning" />{' '}
              <StyledTooltip id="warning">
                {`${I18n.t(
                  'Some of the reports may have failed or are unavailable due to incomplete results',
                )}. ${I18n.t('Please check respondent profile for details')}.`}
              </StyledTooltip>
            </>
          )}
        </ReportStatus>
      );
    },
  },
];

const StyledStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  padding: 0.4rem 1.2rem;
  text-transform: uppercase;
  border-radius: 1rem;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1.5px;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.fontColor};
`;

const InviteNotSent = styled.span`
  color: #ffa000;
  font-weight: 600;
  font-size: 14px;
`;

const ReportStatus = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${(props) => props.color || props.theme.colors.primary};
`;

const RedWarningIcon = styled(WarningIcon)`
  margin-left: 0.6rem;
  path {
    fill: ${(props) => props.theme.colors.error};
  }
`;

const StyledTooltip = styled(ReactTooltip)`
  width: 29rem;
`;

export default columns;
