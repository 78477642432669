import { createSelector } from 'reselect';
import { capitalize } from 'lodash';

const getUser = (state) => state.user.user;
const getTests = (state) => state.user.tests;
const getBatteries = (state) => state.user.batteries;
const getCategories = (state) => state.user.categories;

// the only options that are available for Solutions user
export const solutionsCategories = ['FAVOURITES', 'SOLUTIONS'];

export const selectCategories = createSelector([getCategories, getUser], (categories, user) => {
  const filtered =
    user?.userType === 1 ? categories.filter((item) => solutionsCategories.includes(item.name)) : categories;
  return filtered.map((item) => item.name.toLowerCase());
});

export const selectCategoriesOptions = createSelector([selectCategories], (categories) =>
  categories.map((category) => ({ value: category, label: capitalize(category) })),
);

// eslint-disable-next-line import/prefer-default-export
export const selectAllAssessments = createSelector(
  [getTests, getBatteries, selectCategories],
  (tests, batteries, categories) => {
    const categoriesObj = categories.reduce((acc, item) => ({ ...acc, [item.toLowerCase()]: [] }), {});
    const assessments = [...tests, ...batteries];
    const assessmentsWithoutUndefinedCategories = assessments.filter((item) => item.category);
    const list =
      window._env_.REACT_APP_PSEUDO_NODE_ENV === 'production' ? assessmentsWithoutUndefinedCategories : assessments;
    if (assessments.length) {
      list.forEach((item) => {
        const destination = item.category ? categoriesObj[item.category.toLowerCase()] : null;
        if (destination) destination.push(item);
      });
      return categoriesObj;
    }
    return null;
  },
);

export const selectUnsupervisedAssessments = createSelector(
  [getTests, getBatteries, selectCategories],
  (tests, batteries, categories) => {
    const categoriesObj = categories.reduce((acc, item) => ({ ...acc, [item.toLowerCase()]: [] }), {});
    const filteredTests = tests.filter((item) => item.allowUnsupervised);
    const filteredBatteries = batteries.filter((item) => item.langAvailability && item.allowUnsupervised);
    const assessments = [...filteredTests, ...filteredBatteries];
    const assessmentsWithoutUndefinedCategories = assessments.filter((item) => item.category);
    const list =
      window._env_.REACT_APP_PSEUDO_NODE_ENV === 'production' ? assessmentsWithoutUndefinedCategories : assessments;
    if (assessments.length) {
      list.forEach((item) => {
        const destination = item.category ? categoriesObj[item.category.toLowerCase()] : null;
        if (destination) destination.push(item);
      });
      return categoriesObj;
    }
    return null;
  },
);
