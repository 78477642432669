import React from 'react';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import { ReactComponent as Check } from '../../assets/icons/check-white.svg';
import { ReactComponent as ArrowUp } from '../../assets/icons/blue-arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/blue-arrow-down.svg';
import AnimateHeightWrapper from '../reusable/AnimateHeight';

const Set = ({ set, onSetClick, onSetCheckboxClick, onReportClick, isWithoutCost }) => {
  const { isOpen, isChecked } = set;
  const setHeight = set.reports.length > 4 ? 155 : 'auto';

  return (
    <SetContainer>
      <SetVisiblePart onClick={() => onSetClick(set.reportSetID)} isSetOpen={isOpen}>
        <FlexContentHolder>
          <Checkbox
            onClick={(e) => {
              onSetCheckboxClick(set.reportSetID);
              e.stopPropagation();
            }}
            isActive={isChecked}
          >
            {isChecked && <CheckIcon />}
          </Checkbox>
          <SetName>{set.name}</SetName>
        </FlexContentHolder>
        <FlexContentHolder>
          {!isWithoutCost && (
            <CreditsCost>
              {set.creditsCost} {I18n.t('credits')}
            </CreditsCost>
          )}
          {isOpen ? <StyledArrowUp /> : <StyledArrowDown />}
        </FlexContentHolder>
      </SetVisiblePart>
      <StyledAnimateHeight height={isOpen ? setHeight : 0}>
        <SetReportsContainer>
          {set.reports.map((item) => {
            const isDisabled = false;

            return (
              <TestReport key={item.reportID} disabled={isDisabled}>
                <Checkbox
                  onClick={() => {
                    onReportClick(set.reportSetID, item.reportID);
                  }}
                  isActive={item.isChecked}
                  disabled={isDisabled}
                >
                  {item.isChecked && <CheckIcon />}
                </Checkbox>
                <span>{item.displayName || item.name}</span>
              </TestReport>
            );
          })}
        </SetReportsContainer>
      </StyledAnimateHeight>
    </SetContainer>
  );
};

const FlexContentHolder = styled.div`
  display: flex;
  align-items: center;
`;

const SetContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #cccccc;
  border-radius: 7px;
  margin-bottom: 5px;

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const SetVisiblePart = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 15px;
  justify-content: space-between;
  // border-bottom: ${(props) => (props.isSetOpen ? '1px solid #cccccc' : 'none')};

  :hover {
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  }
`;

const SetName = styled.span`
  color: ${(props) => props.theme.colors.dark};
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
`;

const CreditsCost = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 8px;
  background-color: ${(props) => props.theme.colors.blue};
  border-radius: 3px;
  margin-right: 10px;
  margin-left: 5px;
  color: ${(props) => props.theme.colors.white};
  font-weight: 600;
  white-space: nowrap;
  font-size: 10px;
`;

const Checkbox = styled.div`
  width: 17px;
  height: 17px;
  min-width: 17px;
  min-height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cccccc;
  background-color: ${(props) => (props.isActive ? props.theme.colors.primary : props.theme.colors.white)};
  border-radius: 4px;
  margin-right: 20px;
  transition: all 0.2s;

  :hover {
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  }
`;

const StyledArrowUp = styled(ArrowUp)`
  width: 10px;
  height: 10px;
  path {
    fill: #295368;
  }
`;

const StyledArrowDown = styled(ArrowDown)`
  width: 10px;
  height: 10px;
  path {
    fill: #295368;
  }
`;

const CheckIcon = styled(Check)`
  width: 12px;
  height: 12px;
`;

const SetReportsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TestReport = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.primary};
  font-size: 10px;
  font-weight: 500;
  padding: 10px 15px;
  border-bottom: 1px solid #f1f1f1;
  cursor: default;

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const StyledAnimateHeight = styled(AnimateHeightWrapper)`
  overflow: auto !important;
  max-height: 40rem;
`;

export default Set;
