import React from 'react';
import { format } from 'date-fns';

import { I18n } from 'react-redux-i18n';
import { caseInsensitiveSortingFunc } from './respondents';
import groupsTypesMap from '../../constants/groupTypes';

const DateCell = ({ row }) => <span>{format(new Date(row.original.creationDate), 'dd/M/y')}</span>;

const columns = [
  {
    Header: I18n.t('Name'),
    accessor: 'description',
    name: 'description',
    type: 'text',
    sortType: caseInsensitiveSortingFunc,
    filteringName: 'Name',
    width: 200,
  },
  {
    Header: I18n.t('Description'),
    accessor: 'notes',
    name: 'notes',
    type: 'text',
    filteringName: 'Description',
    width: 300,
  },
  {
    Header: I18n.t('Type'),
    accessor: (data) => I18n.t(groupsTypesMap[data.groupType]),
    name: 'groupType',
    type: 'select',
    filteringName: 'GroupType',
  },
  {
    Header: I18n.t('Creation Date'),
    accessor: (data) => new Date(data.creationDate),
    name: 'creationDate',
    Cell: (props) => <DateCell {...props} />,
    sortType: 'datetime',
    type: 'date',
    filteringName: 'Created',
  },
];

export default columns;
