/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { object, func } from 'prop-types';

import { ReactComponent as CheckIcon } from '../../../assets/icons/check-white.svg';

const AnswerStep = ({ questionsSetInfo, setParentAnswers, respondent }) => {
  const data = {
    ...questionsSetInfo,
    columns: questionsSetInfo.columns.map((col) =>
      col.map((question, index) => ({ ...question, innerColumnIndex: index + 1 })),
    ),
  };
  const numberOfColumns = data.columns.length;
  // 10 - space for paddings/margins between columns
  const columnWidth = ((100 - 10) / numberOfColumns).toFixed(2);

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(1);
  const [answers, setAnswers] = useState([]);
  const onAnswer = (itemNumber, answerNumber) => {
    setAnswers((prev) => {
      const alreadyExists = prev.find((answer) => answer.itemNumber === itemNumber);
      if (alreadyExists)
        return prev.map((answer) => (answer.itemNumber === itemNumber ? { ...answer, answerNumber } : answer));
      return [...prev, { itemNumber, answerNumber }];
    });
    setActiveQuestionIndex(itemNumber === data.numberOfItems ? itemNumber : itemNumber + 1);
  };

  const keyListener = (e) => {
    if (e.code === 'ArrowDown') setActiveQuestionIndex((prev) => (prev === data.numberOfItems ? prev : prev + 1));
    if (e.code === 'ArrowUp') setActiveQuestionIndex((prev) => (prev === 1 ? prev : prev - 1));
    if (e.code === 'Backspace') setAnswers((prev) => prev.filter((item) => item.itemNumber !== activeQuestionIndex));

    // in order to navigate between columns we assigned property called 'innerColumnIndex' to each question object; therefore
    // when user tries to navigate to question in another column, we try to find that item by looking an item that have the same
    // index (innerColumnIndex) in target column, so for example we jump from item#2 (with index 2) in first column to item#2 in second
    // column; confusing part is that we determine active item by looking at its itemNumber (not column index), so when
    // jumping between columns we firstly need to find item by its column index, and then read its itemNumber property and update
    // our state with it
    const currentActiveColumnIndex = data.columns.findIndex((col) =>
      col.find((item) => item.itemNumber === activeQuestionIndex),
    );
    const currentColumn = data.columns[currentActiveColumnIndex];
    const currentQuestionInnerColumnIndex = currentColumn.find((item) => item.itemNumber === activeQuestionIndex)
      .innerColumnIndex;

    if (e.code === 'ArrowRight') {
      if (currentActiveColumnIndex === data.columns.length - 1) return;
      const targetColumnIndex = currentActiveColumnIndex + 1;
      const targetQuestionInnerColumnIndex = data.columns[targetColumnIndex].find(
        (item) => currentQuestionInnerColumnIndex === item.innerColumnIndex,
      );
      const targetColumn = data.columns[targetColumnIndex];
      setActiveQuestionIndex(
        targetQuestionInnerColumnIndex
          ? targetQuestionInnerColumnIndex.itemNumber
          : targetColumn[targetColumn.length - 1].itemNumber,
      );
    }
    if (e.code === 'ArrowLeft') {
      if (currentActiveColumnIndex === 0) return;
      const targetColumnIndex = currentActiveColumnIndex - 1;
      const targetQuestionInnerColumnIndex = data.columns[targetColumnIndex].find(
        (item) => currentQuestionInnerColumnIndex === item.innerColumnIndex,
      );
      const targetColumn = data.columns[targetColumnIndex];
      setActiveQuestionIndex(
        targetQuestionInnerColumnIndex
          ? targetQuestionInnerColumnIndex.itemNumber
          : targetColumn[targetColumn.length - 1].itemNumber,
      );
    }

    if (e.key.match(/^\d+$/)) {
      const digit = Number(e.key);
      if (digit === 0) return;
      if (digit <= data.answersCount) onAnswer(activeQuestionIndex, digit);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyListener);
    return () => document.removeEventListener('keydown', keyListener);
  }, [activeQuestionIndex]);

  const activeQuestionRef = useRef(null);

  useEffect(() => {
    activeQuestionRef.current.scrollIntoView({ block: 'nearest' });
  }, [activeQuestionIndex]);

  useEffect(() => {
    setParentAnswers(answers);
  }, [answers]);

  useEffect(() => {
    setAnswers([]);
    setActiveQuestionIndex(1);
  }, [questionsSetInfo, respondent]);

  return (
    <TestQuestionsWrapper>
      <ColumnsWrapper>
        {data.columns.map((col, index) => {
          return (
            <QuestionsColumn width={columnWidth} key={index}>
              {col.map((question) => {
                const isActive = question.itemNumber === activeQuestionIndex;
                return (
                  <QuestionItem answersCount={question.answersCount} key={question.itemNumber} isActive={isActive}>
                    {isActive && <Anchor ref={activeQuestionRef} />}
                    <QuestionNumber answersNum={question.answersCount}>{question.itemNumber}</QuestionNumber>
                    <AnswersWrapper>
                      {new Array(question.answersCount).fill(true).map((answer, i) => {
                        const isSelected =
                          answers.find((item) => item.itemNumber === question.itemNumber)?.answerNumber === i + 1;
                        return (
                          <AnswerItem
                            isSelected={isSelected}
                            key={i}
                            onClick={() => onAnswer(question.itemNumber, i + 1)}
                          >
                            {isSelected ? <CheckIcon /> : i + 1}
                          </AnswerItem>
                        );
                      })}
                    </AnswersWrapper>
                  </QuestionItem>
                );
              })}
            </QuestionsColumn>
          );
        })}
      </ColumnsWrapper>
    </TestQuestionsWrapper>
  );
};

AnswerStep.propTypes = {
  questionsSetInfo: object.isRequired,
  setParentAnswers: func.isRequired,
  respondent: object.isRequired,
};

const TestQuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
`;

const ColumnsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const QuestionsColumn = styled.div`
  width: ${(props) => props.width}%;
  max-width: ${(props) => props.width}%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
`;

const QuestionItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 3px 3px 3px 12px;
  border-radius: 8rem;
  border: 3px solid ${(props) => (props.isActive ? props.theme.colors.lightBlue : props.theme.colors.lightBlue1)};
  background-color: ${(props) => (props.isActive ? props.theme.colors.lightBlueHover1 : props.theme.colors.white)};
  margin-bottom: 1rem;
`;

const QuestionNumber = styled.span`
  font-size: 14px;
  font-weight: bold;
  min-width: 2rem;
  text-align: center;
  color: ${(props) => props.theme.colors.darkBlue2};
  margin-right: 1rem;
`;

const AnswersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const AnswerItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.isSelected ? props.theme.colors.mediumBlue : props.theme.colors.lightBlue1)};
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  margin-right: 5px;
  transition: all 0.2s;

  &:last-child {
    margin-right: 0;
  }

  font-size: 14px;
  font-weight: 600;
  color: ${(props) => (props.isSelected ? props.theme.colors.white : props.theme.colors.darkBlue2)};
  border-radius: 50%;

  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.mediumBlue};
    color: ${(props) => props.theme.colors.white};
  }

  :active {
    background-color: ${(props) => props.theme.colors.darkBlue};
  }
`;

const Anchor = styled.div`
  position: absolute;
  width: 0;
  height: 5rem;
`;

export default AnswerStep;
