import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { I18n } from 'react-redux-i18n';
import PageWrapper from '../../PageWrapper';
import Table from '../../reusable/Tables/Table';
import {
  addNewResponseScale,
  deleteResponseScalesAction,
  fetchResponseScalesAction,
  resetResponseScales,
  setSelectedScales,
} from '../../../store/settings/response-scales/actions';
import responseScalesSelectors from '../../../store/settings/response-scales/selectors';
import * as actions from '../../../table/actionsList';
import responseScalesColumns from '../../../table/columns/responseScales';
import responseScalesTableActions from '../../../table/tableActions/responseScales';
import SettingsTabs from '../components/SettingsTabs/SettingsTabs';
import AddResponseScale from '../AddRespondentScale/AddResponseScale';
import BulkActionsSidebar from '../../BulkActionsSidebar';
import useBulkSidebarState from '../../../hooks/useBulkSidebarState';
import useModals from '../../../hooks/useModals';
import ConfirmationModal from '../../reusable/ConfirmationModal';
import responseScalesBulkActions from './bulkActions';

const ResponseScales = () => {
  const dispatch = useDispatch();

  const [isModalOpened, setIsModalOpened] = useState(false);

  const scales = useSelector(responseScalesSelectors.selectScales);
  const isLoading = useSelector(responseScalesSelectors.selectIsLoading);
  const selectedScales = useSelector((state) => state.responseScales.selectedScales);

  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedScales });

  const modals = [actions.REMOVE_RESPONSE_SCALES];
  const { modalsState, openModal, closeModal } = useModals(modals);

  const columns = useMemo(() => {
    return responseScalesColumns;
  }, []);

  useEffect(() => {
    dispatch(fetchResponseScalesAction());

    return () => {
      dispatch(resetResponseScales());
    };
  }, []);

  const tableActions = useMemo(() => {
    return responseScalesTableActions.map((item) => ({
      ...item,
      handler: () => dispatch(push('add-response-scale')),
    }));
  }, []);

  const onHandleClosePopup = (data) => {
    dispatch(addNewResponseScale(data));
    setIsModalOpened(false);
  };

  const onSelectChange = useCallback((data) => {
    dispatch(setSelectedScales(data));
  }, []);

  const navigateScaleDetails = ({ responseScaleID }) => {
    dispatch(push(`/settings/response-scales/${responseScaleID}`));
  };

  // BULK ACTIONS
  const bulkActionsHandlers = {
    [actions.REMOVE_RESPONSE_SCALES]: () => openModal(actions.REMOVE_RESPONSE_SCALES),
  };

  const bulkActions = useMemo(() => {
    return responseScalesBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
    }));
  }, [selectedScales]);

  const onScaleDelete = useCallback(() => {
    dispatch(
      deleteResponseScalesAction(
        selectedScales.map((scale) => scale.responseScaleID),
        () => {
          closeModal(actions.REMOVE_RESPONSE_SCALES);
        },
      ),
    );
  }, [selectedScales]);

  return (
    <PageWrapper
      title={I18n.t('360 settings')}
      backButtonHandler={() => dispatch(push(`/settings`))}
      buttons={tableActions}
    >
      <SettingsTabs />
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      <Table
        data={scales}
        isLoading={isLoading}
        onFetchData={() => {}}
        columns={columns}
        idAccessor="responseScalesId"
        scrollContainerHeight="100%"
        entityName="response scale"
        totalCount={scales.length}
        pageCount={1}
        appliedFilters={[]}
        onRowClick={navigateScaleDetails}
        onSelectChange={onSelectChange}
        interceptCheckboxClick
      />
      {isModalOpened && <AddResponseScale onClose={onHandleClosePopup} />}
      <ConfirmationModal
        isVisible={modalsState[actions.REMOVE_RESPONSE_SCALES]}
        title={I18n.t('Delete scale(s)')}
        description={I18n.t('Are you sure you want to delete this scale(s)?')}
        onClose={() => closeModal(actions.REMOVE_RESPONSE_SCALES)}
        onConfirm={onScaleDelete}
        caution
      />
    </PageWrapper>
  );
};

export default ResponseScales;
