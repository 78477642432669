import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import copy from 'copy-to-clipboard';
import { I18n } from 'react-redux-i18n';

import invitesColumns from '../../table/columns/invites';

import PageWrapper from '../PageWrapper';
import Table from '../reusable/Tables/Table';
import TableTabs from '../reusable/TableTabs';

import {
  setSelectedInvites,
  fetchInvites,
  setActiveInvitesTab,
  setInvitesSearchValue,
  setInvitesFilterValue,
  setInvitesFilterType,
  applyInvitesFilters,
  clearInvitesFilters,
  deleteInvitesAppliedFilter,
  deleteInvites,
  updateExpiryAssessmentInvites,
} from '../../store/invites/actions';
import { selectTotalInvitesCount, selectTabs, selectInvitesLanguageOptions } from '../../store/invites/selectors';

import * as actions from '../../table/actionsList';
import invitesBulkActions from '../../table/bulkActions/invites';

import AdvancedFilter from '../reusable/AdvancedFilter';
import BulkActionsSidebar from '../BulkActionsSidebar';

import useTableFilter from '../../hooks/useTableFilter';
import useBulkSidebarState from '../../hooks/useBulkSidebarState';
import ConfirmationModal from '../reusable/ConfirmationModal';

import createToastNotification from '../../utils/createToastNotification';
import useModals from '../../hooks/useModals';
import ResetExpiryDate from './ResetExpiryDate';

export const createCopiedNotification = () => {
  createToastNotification({
    message: I18n.t('clipboardCopyToast'),
    options: {
      type: 'success',
      className: 'toast-success-copy',
      position: 'bottom-center',
    },
  });
};

const Invites = ({ location }) => {
  const languageOptions = useSelector(selectInvitesLanguageOptions);
  const invites = useSelector((state) => state.invites.invites);
  const selectedInvites = useSelector((state) => state.invites.selectedInvites);
  const user = useSelector((state) => state.user.user);
  const tabs = useSelector(selectTabs);
  const activeTab = useSelector((state) => state.invites.activeTab);
  const searchValue = useSelector((state) => state.invites.search);
  const filter = useSelector((state) => state.invites.filter);
  const totalCount = useSelector(selectTotalInvitesCount);
  const pageCount = useSelector((state) => state.invites.pagesAvailable);
  const lastPageIndex = useSelector((state) => state.invites.lastPageIndex);
  const dispatch = useDispatch();
  const memoizedSetInvitesSearchValue = useCallback((val) => dispatch(setInvitesSearchValue(val)), []);
  const memoizedClearInvitesFilters = useCallback(() => dispatch(clearInvitesFilters()), []);
  const memoizedDeleteInvitesAppliedFilter = useCallback(
    (fieldName) => dispatch(deleteInvitesAppliedFilter(fieldName)),
    [],
  );

  const [expiryDate, setExpiryDate] = useState(new Date());

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location.pathname]);

  const [isLoading, setLoadingStatus] = useState(true);

  const fetchData = useCallback(({ tableData, shouldReset, showSpinner, tableCallback }) => {
    if (showSpinner) setLoadingStatus(true);
    dispatch(
      fetchInvites(tableData, {
        callback: () => {
          setLoadingStatus(false);
          tableCallback();
        },
        shouldReset,
      }),
    );
  }, []);

  const [shouldResetPage, setShouldResetPageStatus] = useState(false);

  // INVITES DELETIONS
  const [isDeletionModalVisible, setDeletionModalState] = useState(false);
  const openDeletionModal = () => setDeletionModalState(true);
  const closeDeletionModal = () => setDeletionModalState(false);

  const onDeleteInviteConfirmation = () => {
    dispatch(
      deleteInvites(
        selectedInvites.map((item) => ({ inviteID: item.inviteID, inviteType: item.inviteType })),
        () => {
          closeDeletionModal();
        },
      ),
    );
  };

  const onSelectChange = useCallback((data) => {
    dispatch(setSelectedInvites(data));
  }, []);

  // Controlling bulk actions sidebar state
  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedInvites });

  const modals = [actions.RESET_DEADLINE];
  const { modalsState, openModal, closeModal } = useModals(modals);

  // BULK ACTIONS
  const bulkActionsHandlers = {
    [actions.RESET_DEADLINE]: () => openModal(actions.RESET_DEADLINE),
    [actions.COPY_LINK]: () => {
      copy(selectedInvites[0].downloadLink);
      createCopiedNotification();
    },
    [actions.DELETE_INVITE]: openDeletionModal,
  };

  const bulkActions = useMemo(() => {
    return invitesBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedInvites) : item.isDisabled,
    }));
  }, [selectedInvites, user]);

  // columns settings
  const columns = useMemo(() => {
    return invitesColumns(languageOptions);
  }, [user, languageOptions]);

  // Table filtering
  const setFilterType = (name, value) => dispatch(setInvitesFilterType(name, value));
  const setFilterValue = (name, value) => dispatch(setInvitesFilterValue(name, value));
  const { inputsWithHandlers, appliedFiltersWithColumnNames } = useTableFilter({
    filter,
    columns,
    setFilterType,
    setFilterValue,
  });
  const onFilterApply = () => {
    dispatch(applyInvitesFilters());
  };
  const onTabClick = (tab) => {
    setShouldResetPageStatus(true);
    dispatch(setActiveInvitesTab(tab));
  };

  const onValueChange = (value) => {
    setExpiryDate(value);
  };

  useEffect(() => {
    setExpiryDate(selectedInvites[0] ? new Date(selectedInvites[0].expiryDate) : new Date());

    return () => {};
  }, [selectedInvites.length]);

  const handleExpiryDateUpdates = (type, invites) => {
    const data = {
      inviteType: type,
      inviteIDs: invites.map((invite) => invite.inviteID),
      expiryDate: expiryDate.toISOString(),
    };
    dispatch(updateExpiryAssessmentInvites(data), closeModal(actions.RESET_DEADLINE));
  };

  const onExpiryDateUpdates = () => {
    const assessmentInvites = selectedInvites.filter((invitation) => invitation.inviteType === 0);
    const projectInvites = selectedInvites.filter((invitation) => invitation.inviteType === 2);

    const assessmentInviteType = 0;
    const projectInviteType = 2;

    if (assessmentInvites.length > 0) handleExpiryDateUpdates(assessmentInviteType, assessmentInvites);
    if (projectInvites.length > 0) handleExpiryDateUpdates(projectInviteType, projectInvites);
  };

  return (
    <PageWrapper title={I18n.t('Invites')}>
      <BulkActionsSidebar actions={bulkActions} isOpen={isSidebarOpen} onClose={closeSidebar} />
      <TableTabs tabs={tabs} clickHandler={onTabClick} activeTab={activeTab} />
      <AdvancedFilter
        searchProps={{
          appliedFilters: appliedFiltersWithColumnNames,
          deleteFilter: memoizedDeleteInvitesAppliedFilter,
          searchValue,
          onSearchChange: memoizedSetInvitesSearchValue,
        }}
        filterProps={{
          inputs: inputsWithHandlers,
          appliedInputs: filter.appliedInputs,
          applyFilters: onFilterApply,
          clearAll: memoizedClearInvitesFilters,
          isDirty: filter.dirty,
        }}
      />
      <Table
        columns={columns}
        data={invites}
        idAccessor="inviteID"
        onSelectChange={onSelectChange}
        interceptCheckboxClick
        shouldResetPage={shouldResetPage}
        setShouldReset={setShouldResetPageStatus}
        entityName="invite"
        isLoading={isLoading}
        onFetchData={fetchData}
        totalCount={totalCount}
        searchValue={searchValue}
        appliedFilters={appliedFiltersWithColumnNames}
        pageCount={pageCount}
        lastPageIndex={lastPageIndex}
      />
      <ConfirmationModal
        isVisible={isDeletionModalVisible}
        title={I18n.t('Delete invite')}
        description={`${I18n.t('Are you sure you want to delete')} ${
          selectedInvites.length > 1 ? I18n.t('selected invites?') : I18n.t('this invite?')
        }`}
        onClose={closeDeletionModal}
        onConfirm={onDeleteInviteConfirmation}
        caution
      />
      {modalsState[actions.RESET_DEADLINE] && (
        <ResetExpiryDate
          onClose={() => closeModal(actions.RESET_DEADLINE)}
          onConfirm={onExpiryDateUpdates}
          expiryDate={expiryDate}
          onValueChange={onValueChange}
        />
      )}
    </PageWrapper>
  );
};

Invites.propTypes = {
  location: {},
};

Invites.defaultProps = {
  location: {},
};

export default Invites;
