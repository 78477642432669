import React, { useState, useEffect, useMemo } from 'react';
import { func, object } from 'prop-types';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'redux-first-history';

import { I18n } from 'react-redux-i18n';
import ReactTooltip from 'react-tooltip';
import Modal from '../../reusable/NewModal';
import Spinner from '../../reusable/Spinner';
import Checkbox from '../../reusable/FormComponents/Checkbox';

import { selectAllAssessments, selectCategoriesOptions } from '../../../store/tests/selectors';
import { administerGroupSession } from '../../../store/groups/actions';
import createToastNotification from '../../../utils/createToastNotification';

import { selectAvailableLanguages, selectAvailableMappedBiodataTemplates } from '../../../store/user/selectors';

import CustomSelect from '../../reusable/Selects/Select';
import useAvailableTests from '../../../hooks/useAvailableTests';
import CustomButton from '../../reusable/Buttons/Button';
import createTestsOptions from '../../Respondents/CreateInvite/createInviteUtils';
import { findLanguage, findAssessment } from '../../Respondents/CreateInvite';

import useStep from '../../../hooks/useStep';

import { ReactComponent as QuestionIcon } from '../../../assets/icons/question-mark.svg';

const steps = ['assessment', 'confirm'];

const AdministerGroupSession = ({ onClose, group }) => {
  const user = useSelector((state) => state.user.user);
  const languages = useSelector(selectAvailableLanguages);
  const groupedAssessments = useSelector(selectAllAssessments);
  const categories = useSelector(selectCategoriesOptions);
  const [activeCategory, setActiveCategory] = useState(categories.length ? categories[0] : null);
  const [assessments, setAssessments] = useState(groupedAssessments ? groupedAssessments[activeCategory] : []);
  const dispatch = useDispatch();
  const { step, goNext } = useStep({ steps });

  const [language, setLanguage] = useState(findLanguage(languages, user && user.distributorDefaultLanguageID));
  const [assessment, setAssessment] = useState(findAssessment(assessments, language));
  const [skipAuthorization, changeSkipAuthorizationStatus] = useState(false);

  const [isLoading, setLoadingState] = useState(false);

  const availableBiodataTemplates = useSelector(selectAvailableMappedBiodataTemplates);
  const hasDefaultTemplate = availableBiodataTemplates.find(
    (template) => template.isDefault === true && template.value !== 0,
  );
  const [biodataTemplate, setBiodataTemplate] = useState(hasDefaultTemplate || availableBiodataTemplates[0]);

  useEffect(() => {
    if (languages.length) setLanguage(findLanguage(languages, user && user.distributorDefaultLanguageID));
  }, [languages]);

  useEffect(() => {
    if (categories.length) {
      setActiveCategory(categories[0]);
    }
  }, [categories]);
  useEffect(() => {
    if (groupedAssessments) {
      const assessments = groupedAssessments[activeCategory.value];
      setAssessments(assessments);
      setAssessment(findAssessment(assessments, language));
    }
  }, [groupedAssessments, activeCategory, language]);

  const onModalClose = () => {
    onClose();
  };
  const onLanguageChange = (data) => {
    setLanguage(data);
  };

  const onCategoryChange = (option) => {
    setActiveCategory(option);
    setAssessment(findAssessment(assessments, language));
  };
  const onAssessmentValueChange = (item) => {
    setAssessment(item);
  };

  const onBiodataTemplateChange = (item) => {
    setBiodataTemplate(item);
  };

  const onGenerateLink = () => {
    const data = {
      batteryID: assessment.batteryID || -1,
      langID: language.value,
      testID: assessment.testID,
      skipAuthorization,
      biodatatemplateid: biodataTemplate.value,
    };
    setLoadingState(true);
    dispatch(
      administerGroupSession(group.groupID, data, (data, err) => {
        // if (!err) {
        //   setLink(data.url);
        //   goNext();
        // }
        if (err) {
          createToastNotification({
            type: 'error',
            message: `${I18n.t('groupLinkErrorToast')}.`,
          });
        }
        if (!err) {
          createToastNotification({
            type: 'success',
            message: I18n.t('The administered group session has been created'),
          });
          dispatch(push(`/groups/administer/${group.groupID}`));
        }
        setLoadingState(false);
        onClose();
      }),
    );
  };

  const availableAssessments = useAvailableTests({
    language,
    assessments,
  });

  const assessmentsOptions = useMemo(() => {
    return assessments ? createTestsOptions(availableAssessments) : [];
  }, [availableAssessments]);

  const assessmentWithLabel = useMemo(() => {
    if (assessment) {
      return assessment.label ? assessment : createTestsOptions([assessment])[0];
    }
    return null;
  }, [assessment]);

  return (
    <StyledModal
      isVisible
      onClose={onModalClose}
      title={I18n.t('Administer group session')}
      isHeaderHidden={steps[step] === 'confirm'}
      minimized={steps[step] === 'confirm'}
    >
      <Spinner isLoading={isLoading} />
      {steps[step] === 'assessment' ? (
        <AssessmentContainer>
          <Title>{I18n.t('Assessment info')}</Title>

          <Row>
            <StyledSelect
              options={languages}
              placeholder={I18n.t('Language')}
              selectProps={{ value: language, onChange: onLanguageChange }}
            />
            <StyledSelect
              options={categories}
              placeholder={I18n.t('Category')}
              selectProps={{ value: activeCategory, onChange: onCategoryChange }}
            />
          </Row>
          <StyledCustomSelect
            options={assessmentsOptions}
            placeholder={I18n.t(activeCategory.label)}
            selectProps={{
              value: assessmentWithLabel,
              onChange: onAssessmentValueChange,
            }}
          />
          <InfoSelectWrapper>
            <StyledCustomSelect
              options={availableBiodataTemplates}
              placeholder={I18n.t('Bio data template')}
              selectProps={{
                value: biodataTemplate,
                onChange: onBiodataTemplateChange,
              }}
            />
            <StyledQuestionIcon data-tip data-for="biodata" />
            <StyledTooltip id="biodata">{I18n.t('biodataTooltip')}</StyledTooltip>
          </InfoSelectWrapper>
          <Label>
            {I18n.t(
              'To select multiple assessments, please create a battery in settings and select it from within the Favourites category',
            )}
          </Label>
          <CheckboxHolder>
            <Checkbox
              isChecked={skipAuthorization}
              clickHandler={() => changeSkipAuthorizationStatus((prev) => !prev)}
            />
            <span>{I18n.t('Respondent to create new record')}</span>
          </CheckboxHolder>
        </AssessmentContainer>
      ) : (
        <Warning>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          {I18n.t(
            'You are about to launch a managed group session. While the session is active, actions with the group will be limited',
          )}
        </Warning>
      )}

      {steps[step] === 'assessment' ? (
        <ButtonWrapper>
          <Button handler={() => goNext()} disabled={!assessment || !language}>
            {I18n.t('Start')}
          </Button>
        </ButtonWrapper>
      ) : (
        <ButtonWrapper spaceBetween>
          <Button handler={() => onClose()} variant="secondary">
            {I18n.t('Cancel')}
          </Button>
          <Button handler={onGenerateLink} disabled={!assessment || !language}>
            {I18n.t('Ok, got it')}
          </Button>
        </ButtonWrapper>
      )}
    </StyledModal>
  );
};

AdministerGroupSession.propTypes = {
  onClose: func.isRequired,
  group: object.isRequired,
};

const StyledModal = styled(Modal)`
  min-height: ${(props) => (props.minimized ? '20rem' : '40rem')};
  min-width: ${(props) => (props.minimized ? '55rem' : '66rem')};
  width: 75rem;
  display: flex;
  flex-direction: column;
`;

const AssessmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: ${(props) => (props.spaceBetween ? 'space-between' : 'flex-end')};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
`;

const StyledSelect = styled(CustomSelect)`
  width: 48%;
`;

const Button = styled(CustomButton)`
  width: ${(props) => (props.wide ? '16rem' : '14rem')};
  text-transform: uppercase;

  ${(props) =>
    props.isLinkCopied &&
    css`
      border-color: ${(props) => props.theme.colors.green};
      color: ${(props) => props.theme.colors.green};
      :hover {
        border-color: ${(props) => props.theme.colors.green};
        color: ${(props) => props.theme.colors.green};
      }
    `}
`;

const Title = styled.h3`
  font-weight: 600;
  color: ${(props) => props.theme.colors.mediumBlue};
  font-size: ${(props) => props.theme.fontSizes.normal};
  margin-bottom: 2rem;
`;

const Label = styled.span`
  font-weight: 500;
  color: rgba(0, 0, 0, 0.4);
  font-size: ${(props) => props.theme.fontSizes.small};
  padding-left: 0.5rem;
`;

const Warning = styled.p`
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSizes.medium};
  color: rgba(0, 0, 0, 0, 88);
`;
const CheckboxHolder = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;

  span {
    font-size: 14px;
    font-weight: 500;
    margin-left: 1rem;
  }
`;

const InfoSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTooltip = styled(ReactTooltip)`
  width: 30rem;
`;

const StyledCustomSelect = styled(CustomSelect)`
  width: 95%;
`;

const StyledQuestionIcon = styled(QuestionIcon)`
  margin-bottom: 20px;
`;

export default AdministerGroupSession;
