import { createAction } from 'redux-actions';
import { I18n } from 'react-redux-i18n';
import apiInstance2 from '../../services/apiService';
import ratersColumns from '../../table/columns/raters';
import generateQueryString from '../generateQueryString';
import defaultTableState from '../../constants/defaultTableState';
import { fetchSessionById } from '../sessions/actions';
import createToastNotification from '../../utils/createToastNotification';

export const setActiveRatersTab = createAction('SET_ACTIVE_RATERS_TAB', (tab) => ({
  tab,
}));

// ----------------- FILTERS ----------------------
export const setRatersSearchValue = createAction('SET_RATERS_SEARCH_VALUE', (searchValue) => ({
  searchValue,
}));

export const setRatersFilterValue = createAction('SET_RATERS_FILTER_VALUE', (fieldName, value) => ({
  fieldName,
  value,
}));

export const setRatersFilterType = createAction('SET_RATERS_FILTER_TYPE', (fieldName, filterType) => ({
  fieldName,
  filterType,
}));

export const deleteRatersAppliedFilter = createAction('DELETE_RATERS_APPLIED_FILTER', (fieldName) => ({
  fieldName,
}));

export const applyRatersFilters = createAction('APPLY_RATERS_FILTERS');
export const clearRatersFilters = createAction('CLEAR_RATERS_FILTERS');
export const resetRatersTableState = createAction('RESET_RATERS_TABLE_STATE');

export const setSelectedRaters = createAction('SET_SELECTED_RATERS', (raters) => ({
  raters,
}));

// ---------  NETWORK RELATED ----------
export const fetchRatersStart = createAction('FETCH_RATERS_START');
export const fetchRatersSuccess = createAction('FETCH_RATERS_SUCCESS', (data, shouldReset) => ({
  data,
  shouldReset,
}));
export const fetchRatersFailure = createAction('FETCH_RATERS_FAILURE');

export const deleteRatersStart = createAction('DELETE_RATERS_START');
export const deleteRatersSuccess = createAction('DELETE_RATERS_SUCCESS', (ratersIds) => ({ ratersIds }));
export const deleteRatersFailure = createAction('DELETE_RATERS_FAILURE');

export const inviteRatersStart = createAction('INVITE_RATERS_START');
export const inviteRatersSuccess = createAction('INVITE_RATERS_SUCCESS');
export const inviteRatersFailure = createAction('INVITE_RATERS_FAILURE');

export const fetchRaters = (projectId, sessionId, tableState = defaultTableState, { callback, shouldReset }) => async (
  dispatch,
) => {
  try {
    dispatch(fetchRatersStart());
    const finalObj = { ...tableState };
    const columns = ratersColumns();

    if (finalObj.sortBy) {
      const sortingObj = finalObj.sortBy[0];

      const ColumnIndex = columns.findIndex(
        (item) =>
          (typeof item.Header === 'function' ? item.stringHeader : item.Header) === sortingObj.id ||
          item.name === sortingObj.id,
      );

      const Order = sortingObj.desc ? 1 : 0;
      finalObj.sortBy = { ColumnIndex, Order };
    }

    const queryString = generateQueryString(finalObj, columns);
    const response = await apiInstance2.get(
      `/api/v2/360/projects/${projectId}/sessions/${sessionId}/raters?${queryString}`,
    );
    if (response.status === 200) {
      dispatch(fetchRatersSuccess(response.data, shouldReset));
      if (callback) callback();
    }
  } catch (e) {
    dispatch(fetchRatersFailure(e));
    if (callback) callback(e);
  }
};

export const deleteRaters = (projectId, sessionId, ratersIds, callback) => async (dispatch) => {
  dispatch(deleteRatersStart());
  try {
    const response = await apiInstance2.delete(`/api/v2/360/projects/${projectId}/sessions/${sessionId}/raters`, {
      data: ratersIds,
    });
    if (response.status === 200) {
      dispatch(deleteRatersSuccess(ratersIds));
      createToastNotification({ message: I18n.t('ratersDeletionToast') });
      dispatch(fetchSessionById(projectId, sessionId));
      if (callback) callback();
    }
  } catch (err) {
    dispatch(deleteRatersFailure(err.message));
    if (callback) callback(err);
  }
};

export const inviteRaters = (projectId, sessionId, data, callback) => async (dispatch) => {
  dispatch(inviteRatersStart());
  try {
    const response = await apiInstance2.post(
      `/api/v2/360/projects/${projectId}/sessions/${sessionId}/raters/invites`,
      data,
    );

    if (response.status === 200) {
      dispatch(inviteRatersSuccess());
      if (callback) callback();
    }
  } catch (err) {
    dispatch(inviteRatersFailure(err.message));
    if (callback) callback(err);
  }
};
