import React from 'react';
import { string, number, oneOfType } from 'prop-types';
import styled from 'styled-components';

const Logo = ({ marginBottom }) => (
  <LogoContainer marginBottom={marginBottom}>
    <FirstLogoLabel>Psytech</FirstLogoLabel>
    <SecondLogoLabel>GeneSys</SecondLogoLabel>
  </LogoContainer>
);

Logo.propTypes = {
  marginBottom: oneOfType([string, number]),
};

Logo.defaultProps = {
  marginBottom: '',
};

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${(props) => props.marginBottom || '2.5rem'};
  cursor: default;
`;

const FirstLogoLabel = styled.span`
  font-size: 26px;
  color: ${(props) => props.theme.colors.menuBg};
  text-transform: uppercase;
  font-weight: 300;
`;

const SecondLogoLabel = styled.span`
  font-size: 40px;
  line-height: 35px;
  color: ${(props) => props.theme.colors.menuBg};
  text-decoration: underline;
`;

export default Logo;
