/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React from 'react';
import { format } from 'date-fns';

import idealProfileTypes from '../../constants/idealProfileTypes';

export const caseInsensitiveSortingFunc = (prev, next, columnId) => {
  if (prev.original[columnId].toLowerCase() > next.original[columnId].toLowerCase()) return 1;
  if (prev.original[columnId].toLowerCase() < next.original[columnId].toLowerCase()) return -1;
  return 0;
};

const DateCell = ({ row }) => {
  return <span>{format(new Date(row.original.creationDate), 'dd/M/y')}</span>;
};

const columns = [
  {
    id: 'name',
    name: 'name',
    type: 'text',
    accessor: 'name',
    sortType: caseInsensitiveSortingFunc,
    Header: 'Name',
    disableFilters: true,
  },
  {
    id: 'type',
    name: 'type',
    type: 'text',
    accessor: (data) => (idealProfileTypes[data.type] ? idealProfileTypes[data.type] : data.type),
    storType: 'alphanumeric',
    Header: 'Type',
    width: 40,
    filter: (rows, [id], value) => (value ? rows.filter((row) => row.values[id] === value.value) : rows),
  },

  {
    id: 'creationDate',
    name: 'creationDate',
    type: 'date',
    accessor: (data) => new Date(data.creationDate),
    sortType: 'datetime',
    Header: 'Creation Date',
    Cell: (props) => <DateCell {...props} />,
    width: 60,
    disableFilters: true,
  },
  {
    id: 'testID',
    name: 'testID',
    accessor: 'testID',
    sortType: caseInsensitiveSortingFunc,
    Header: 'Test ID',
    isVisible: false,
    filter: (rows, [id], value) =>
      value
        ? rows.filter((row) => {
            return row.values[id].toLowerCase() === value.toLowerCase();
          })
        : rows,
    type: 'select',
    options: [],
    disableFilters: true,
  },
];

export default columns;
