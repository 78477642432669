import v from 'voca';
import { isWithinInterval, addDays, addWeeks, addMonths, addYears } from 'date-fns';
import { I18n } from 'react-redux-i18n';

export const TEXT_FILTER_TYPES = {
  IS_EQUAL: 'isEqual',
  IS_NOT_EQUAL: 'isNotEqual',
  STARTS_WITH: 'startsWith',
  CONTAINS: 'contains',
  DOES_NOT_CONTAIN: 'doesNotContain',
  ENDS_WITH: 'endsWith',
};

export const NUMBER_FILTER_TYPES = {
  IS_EQUAL: 'isEqual',
  LESSER_THAN: 'lesserThan',
  GREATER_THAN: 'greaterThan',
};

export const DATE_FILTER_TYPES = {
  ONE_DAY: 'oneDay',
  THREE_DAYS: 'threeDays',
  ONE_WEEK: 'oneWeek',
  TWO_WEEKS: 'twoWeeks',
  ONE_MONTH: 'oneMonth',
  ONE_YEAR: 'oneYear',
};

export const textFilterOptions = [
  {
    name: TEXT_FILTER_TYPES.IS_EQUAL,
    value: TEXT_FILTER_TYPES.IS_EQUAL,
    label: 'Is equal to',
    resolver: (itemValue, filterValue) => v.lowerCase(itemValue) === v.lowerCase(filterValue),
    backendReference: 2,
  },
  {
    name: TEXT_FILTER_TYPES.IS_NOT_EQUAL,
    value: TEXT_FILTER_TYPES.IS_NOT_EQUAL,
    label: I18n.t('Is not equal to'),
    resolver: (itemValue, filterValue) => v.lowerCase(itemValue) !== v.lowerCase(filterValue),
    backendReference: 3,
  },

  {
    name: TEXT_FILTER_TYPES.STARTS_WITH,
    value: TEXT_FILTER_TYPES.STARTS_WITH,
    label: I18n.t('Starts with'),
    resolver: (itemValue, filterValue) => v.startsWith(v.lowerCase(itemValue), v.lowerCase(filterValue)),
    backendReference: 4,
  },

  {
    name: TEXT_FILTER_TYPES.CONTAINS,
    value: TEXT_FILTER_TYPES.CONTAINS,
    label: 'Contains',
    resolver: (itemValue, filterValue) => v.includes(v.lowerCase(itemValue), v.lowerCase(filterValue)),
    backendReference: 0,
  },

  {
    name: TEXT_FILTER_TYPES.DOES_NOT_CONTAIN,
    value: TEXT_FILTER_TYPES.DOES_NOT_CONTAIN,
    label: I18n.t('Does not contain'),
    resolver: (itemValue, filterValue) => !v.includes(v.lowerCase(itemValue), v.lowerCase(filterValue)),
    backendReference: 1,
  },

  {
    name: TEXT_FILTER_TYPES.ENDS_WITH,
    value: TEXT_FILTER_TYPES.ENDS_WITH,
    label: I18n.t('Ends with'),
    resolver: (itemValue, filterValue) => v.endsWith(v.lowerCase(itemValue), v.lowerCase(filterValue)),
    backendReference: 5,
  },
];

export const numberFilterOptions = [
  {
    name: NUMBER_FILTER_TYPES.IS_EQUAL,
    value: NUMBER_FILTER_TYPES.IS_EQUAL,
    label: I18n.t('Is equal to'),
    resolver: (itemValue, filterValue) => Number(itemValue) === Number(filterValue),
    backendReference: 0,
  },
  {
    name: NUMBER_FILTER_TYPES.LESSER_THAN,
    value: NUMBER_FILTER_TYPES.LESSER_THAN,
    label: I18n.t('Lesser than'),
    resolver: (itemValue, filterValue) => Number(itemValue) < Number(filterValue),
    backendReference: 1,
  },

  {
    name: NUMBER_FILTER_TYPES.GREATER_THAN,
    value: NUMBER_FILTER_TYPES.GREATER_THAN,
    label: I18n.t('Greater than'),
    resolver: (itemValue, filterValue) => Number(itemValue) > Number(filterValue),
    backendReference: 2,
  },
];

export const datesFilterOptions = [
  {
    name: DATE_FILTER_TYPES.ONE_DAY,
    value: DATE_FILTER_TYPES.ONE_DAY,
    label: I18n.t('1 day'),
    resolver: (itemDate, startDate) =>
      isWithinInterval(new Date(itemDate), { start: startDate, end: addDays(startDate, 1) }),
    resolveEndDate: (startDate) => addDays(startDate, 1),
  },
  {
    name: DATE_FILTER_TYPES.THREE_DAYS,
    value: DATE_FILTER_TYPES.THREE_DAYS,
    label: I18n.t('3 days'),
    resolver: (itemDate, startDate) =>
      isWithinInterval(new Date(itemDate), { start: startDate, end: addDays(startDate, 3) }),
    resolveEndDate: (startDate) => addDays(startDate, 3),
  },

  {
    name: DATE_FILTER_TYPES.ONE_WEEK,
    value: DATE_FILTER_TYPES.ONE_WEEK,
    label: I18n.t('1 week'),
    resolver: (itemDate, startDate) =>
      isWithinInterval(new Date(itemDate), { start: startDate, end: addWeeks(startDate, 1) }),
    resolveEndDate: (startDate) => addWeeks(startDate, 1),
  },

  {
    name: DATE_FILTER_TYPES.TWO_WEEKS,
    value: DATE_FILTER_TYPES.TWO_WEEKS,
    label: I18n.t('2 weeks'),
    resolver: (itemDate, startDate) =>
      isWithinInterval(new Date(itemDate), { start: startDate, end: addWeeks(startDate, 2) }),
    resolveEndDate: (startDate) => addWeeks(startDate, 2),
  },

  {
    name: DATE_FILTER_TYPES.ONE_MONTH,
    value: DATE_FILTER_TYPES.ONE_MONTH,
    label: I18n.t('1 month'),
    resolver: (itemDate, startDate) =>
      isWithinInterval(new Date(itemDate), { start: startDate, end: addMonths(startDate, 1) }),
    resolveEndDate: (startDate) => addMonths(startDate, 1),
  },
  {
    name: DATE_FILTER_TYPES.ONE_YEAR,
    value: DATE_FILTER_TYPES.ONE_YEAR,
    label: I18n.t('1 year'),
    resolver: (itemDate, startDate) =>
      isWithinInterval(new Date(itemDate), { start: startDate, end: addYears(startDate, 1) }),
    resolveEndDate: (startDate) => addYears(startDate, 1),
  },
];
