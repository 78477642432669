// create data for displaying selects for choosing norms/profiles based on just "reports" array

export const parseProjectReports = ({ reports = [], normsList, idealProfilesList }) => {
  if (!reports.length || !normsList.length) return { norms: null, idealProfiles: null };
  const norms = {};
  const idealProfiles = {};

  reports.forEach((report) => {
    report.dependencies.forEach((dep) => {
      if (!norms[dep] && report?.normIDs?.length) {
        const reportNorm = report.normIDs.find((norm) => norm.testID === dep);
        const reportNormFullInfo = normsList.find((norm) => norm.normID === reportNorm.normID);
        if (!reportNorm) return;
        norms[dep] = {
          ...reportNorm,
          value: reportNorm.normID,
          label: reportNormFullInfo?.name,
        };
      }
      if (!idealProfiles[dep] && report.idealProfileID) {
        const idealProfileFullInfo = idealProfilesList.find(
          (profile) => profile.idealProfileID === report.idealProfileID,
        );
        idealProfiles[dep] = {
          idealProfileID: report.idealProfileID,
          value: report.idealProfileID,
          label: idealProfileFullInfo?.name,
        };
      }
    });
  });

  return { norms, idealProfiles };
};
