import React, { useState } from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { I18n } from 'react-redux-i18n';
import NewModal from '../../reusable/NewModal';
import Steps from '../../reusable/Steps';
import PrepareStep from './ImportSessionsPrepareStep';
import ReviewStep from './ImportSessionsPreviewStep';
import SuccessfulImportStep from '../../Respondents/ImportRespondents/SuccessfulImportStep';

import useStep from '../../../hooks/useStep';

const steps = ['prepare', 'preview', 'import'];

const ImportSessions = ({ onClose, onSuccess }) => {
  const activeProjectInView = useSelector((state) => state.projects.activeProjectInView);
  const { step, goNext } = useStep({ steps });
  const [importedValues, setImportedValues] = useState([]);
  const [importedAmount, setImportedAmount] = useState(0);

  return (
    <StyledModal title={I18n.t('Import sessions')} isVisible onClose={onClose}>
      <Steps steps={steps} stepIndex={step} />
      {step === 0 && (
        <PrepareStep
          goNext={goNext}
          onClose={onClose}
          setImportedValues={setImportedValues}
          projectId={activeProjectInView && activeProjectInView.projectId}
        />
      )}
      {step === 1 && (
        <ReviewStep
          data={importedValues}
          goNext={goNext}
          onClose={onClose}
          setImportedAmount={setImportedAmount}
          onSuccess={onSuccess}
          projectId={activeProjectInView && activeProjectInView.projectId}
        />
      )}
      {step === 2 && <SuccessfulImportStep onClose={onClose} importedAmount={importedAmount} />}
    </StyledModal>
  );
};

ImportSessions.propTypes = {
  onClose: func.isRequired,
  onSuccess: func.isRequired,
};

const StyledModal = styled(NewModal)`
  height: 100%;
  max-height: 54rem;
  min-width: 82rem;
  max-width: 110rem;
  display: flex;
  flex-direction: column;
`;

export default ImportSessions;
