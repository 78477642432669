import React from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import Avatar from '../../components/reusable/Avatar';
import isChineseEnv from '../../utils/isChineseEnv';
// import ActionsDropdown from '../../components/reusable/ActionsDropdown';

export const caseInsensitiveSortingFunc = (prev, next, columnId) => {
  if (prev.original[columnId].toLowerCase() > next.original[columnId].toLowerCase()) return 1;
  if (prev.original[columnId].toLowerCase() < next.original[columnId].toLowerCase()) return -1;
  return 0;
};

const DateCell = ({ row }) => {
  return <span>{format(new Date(row.original.creationDate), 'dd/M/y')}</span>;
};

// const columns = (rowActions, setSelectedRespondents) => [
const columns = [
  {
    Header: () => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {!isChineseEnv && <Avatar invisible />}
        {I18n.t('PersonName')}
      </div>
    ),
    stringHeader: 'Name',
    accessor: (data) => `${data.firstName} ${data.familyName}`,
    name: 'name',
    id: 'name',
    type: 'text',
    sortType: caseInsensitiveSortingFunc,
    filteringName: 'Name',
    Cell: ({ row }) => (
      <RespondentsCell style={{ display: 'flex', alignItems: 'center' }}>
        {!isChineseEnv && <Avatar firstName={row.original.firstName} familyName={row.original.familyName} />}
        <span>
          {isChineseEnv
            ? `${row.original.familyName}${row.original.firstName}`
            : `${row.original.firstName} ${row.original.familyName}`}
        </span>
      </RespondentsCell>
    ),
    width: 250,
  },
  {
    Header: 'Email Address',
    accessor: 'email',
    name: 'email',
    type: 'text',
    sortType: caseInsensitiveSortingFunc,
    filteringName: 'Email',
  },
  {
    Header: 'Groups',
    name: 'groups',
    accessor: (data) => data.groups.map((group) => group.description).join(', '),
    type: 'text',
    Cell: ({ row }) => {
      return <LabelsWrapper compact>{row.original.groups.map((item) => item.description).join(', ')}</LabelsWrapper>;
    },
    filteringName: 'Groups',
  },
  {
    Header: 'Tags',
    name: 'tags',
    accessor: (data) => data.tags.map((item) => item.name).join(', '),
    type: 'text',
    Cell: ({ row }) => {
      return <LabelsWrapper compact>{row.original.tags.map((item) => item.name).join(', ')}</LabelsWrapper>;
    },
    filteringName: 'Tags',
  },
  {
    Header: 'Assessments Done',
    name: 'doneTests',
    accessor: 'doneTests',
    type: 'text',
    Cell: ({ row }) => {
      if (!row.original.assessmentsDone) return '';
      return <LabelsWrapper>{row.original.assessmentsDone.join(', ')}</LabelsWrapper>;
    },
    filteringName: 'TestsDone',
  },
  {
    Header: 'Creation date',
    accessor: (data) => new Date(data.creationDate),
    name: 'creationDate',
    Cell: (props) => <DateCell {...props} />,
    sortType: 'datetime',
    type: 'date',
    filteringName: 'Created',
    width: 100,
  },
  // {
  //   Header: '',
  //   name: 'actions',
  //   type: 'actions',
  //   id: 'actions',
  //   disableSortBy: true,
  //   Cell: ({ row }) => (
  //     <ActionsDropdown
  //       actions={rowActions.map((item) => ({
  //         ...item,
  //         handler: () => item.handler(row.original),
  //         isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(row.original) : item.isDisabled,
  //       }))}
  //       clickHandler={() => setSelectedRespondents(row.original)}
  //     />
  //   ),
  //   width: 50,
  // },
];

const LabelsWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  padding: 1rem 0;
  min-width: 50px;
  max-width: ${(props) => (props.compact ? '12rem' : '20rem')};
`;

const RespondentsCell = styled.div`
  min-width: 24rem;
`;

export default columns;
