import { createAction } from 'redux-actions';
import { I18n } from 'react-redux-i18n';
import apiInstance2 from '../../../services/apiService';
import createToastNotification from '../../../utils/createToastNotification';

export const fetchTemplatesStart = createAction('FETCH_TEMPLATES_START');
export const fetchTemplatesSuccess = createAction('FETCH_TEMPLATES_SUCCESS', (templates) => ({ templates }));
export const fetchTemplatesFailure = createAction('FETCH_TEMPLATES_FAILURE');

export const deleteTemplatesStart = createAction('DELETE_TEMPLATES_START');
export const deleteTemplatesSuccess = createAction('DELETE_TEMPLATES_SUCCESS', (templatesIds) => ({ templatesIds }));
export const deleteTemplatesFailure = createAction('DELETE_TEMPLATES_FAILURE');

export const setActiveTemplate = createAction('SET_ACTIVE_TEMPLATE', (template) => ({ template }));

export const setDefaultEmailInvitaionTemplate = createAction('SET_DEFAULT_EMAIL_INVITAION_TEMPLATE');

export const fetchTemplatesVariablesStart = createAction('FETCH_TEMPLATES_VARIABLES_START');
export const fetchTemplatesVariablesSuccess = createAction('FETCH_TEMPLATES_VARIABLES_SUCCESS', (variables) => ({
  variables,
}));
export const fetchTemplatesVariablesFailure = createAction('FETCH_TEMPLATES_VARIABLES_FAILURE');

export const fetchTemplateByIdStart = createAction('FETCH_TEMPLATE_BY_ID_START');
export const fetchTemplateByIdSuccess = createAction('FETCH_TEMPLATE_BY_ID_SUCCESS');
export const fetchTemplateByIdFailure = createAction('FETCH_TEMPLATE_BY_ID_FAILURE');

export const createTemplateStart = createAction('CREATE_TEMPLATE_START');
export const createTemplateSuccess = createAction('CREATE_TEMPLATE_SUCCESS');
export const createTemplateFailure = createAction('CREATE_TEMPLATE_FAILURE');

export const editTemplateStart = createAction('EDIT_TEMPLATE_START');
export const editTemplateSuccess = createAction('EDIT_TEMPLATE_SUCCESS', (newTemplate) => ({ newTemplate }));
export const editTemplateFailure = createAction('EDIT_TEMPLATE_FAILURE');

export const fetchTemplates = (cb) => async (dispatch) => {
  dispatch(fetchTemplatesStart());
  try {
    const response = await apiInstance2.get(`/api/v2/templates/email`);
    if (response.status === 200) {
      dispatch(fetchTemplatesSuccess(response.data));
      if (cb) cb();
    }
  } catch (err) {
    dispatch(fetchTemplatesFailure(err.message));
    if (cb) cb(err);
  }
};

export const deleteTemplates = (templatesIds, cb) => async (dispatch) => {
  dispatch(deleteTemplatesStart);
  try {
    const response = await apiInstance2.delete(`/api/v2/templates`, { data: templatesIds });
    if (response.status === 200) {
      dispatch(deleteTemplatesSuccess(templatesIds));
      createToastNotification({ message: I18n.t('templatesDeletionToast') });
      if (cb) cb();
    }
  } catch (err) {
    dispatch(deleteTemplatesFailure(err.message));
    if (cb) cb(err);
  }
};

export const fetchTemplatesVariables = () => async (dispatch) => {
  dispatch(fetchTemplatesVariablesStart());
  try {
    const response = await apiInstance2.get(`/api/v2/templates/email/variables`);
    if (response.status === 200) {
      dispatch(fetchTemplatesVariablesSuccess(response.data));
    }
  } catch (err) {
    dispatch(fetchTemplatesVariablesFailure(err.message));
  }
};

export const fetchTemplateById = (templateId, cb) => async (dispatch) => {
  dispatch(fetchTemplateByIdStart());
  try {
    const response = await apiInstance2.get(`/api/v2/templates/email/${templateId}`);
    if (response.status === 200) {
      dispatch(fetchTemplateByIdSuccess());
      dispatch(setActiveTemplate(response.data));
      if (cb) cb();
    }
  } catch (err) {
    dispatch(fetchTemplateByIdFailure(err.message));
    if (cb) cb(err.message);
  }
};

export const createTemplate = (data, cb) => async (dispatch) => {
  dispatch(createTemplateStart());
  try {
    const response = await apiInstance2.post(`/api/v2/templates/email`, data);
    if (response.status === 200) {
      dispatch(createTemplateSuccess());
      createToastNotification({ message: I18n.t('templateAdditionToast') });
      if (cb) cb();
    }
  } catch (err) {
    dispatch(createTemplateFailure(err.message));
    if (cb) cb(err.message);
  }
};
export const editTemplate = (data, cb) => async (dispatch) => {
  dispatch(editTemplateStart());
  try {
    const response = await apiInstance2.patch(`/api/v2/templates/email`, data);
    if (response.status === 200) {
      dispatch(editTemplateSuccess(response.data));
      createToastNotification({ message: I18n.t('templateUpdateToast') });
      if (cb) cb();
    }
  } catch (err) {
    dispatch(editTemplateFailure(err.message));
    if (cb) cb(err.message);
  }
};

export const fetchDefaultEmailInvitaionTemplate = (params, cb) => async (dispatch) => {
  try {
    const response = await apiInstance2.get('api/v2/user/settings/emailTemplate', {
      params: {
        ...(params.languageId ? { langId: params.languageId } : {}),
        ...(params.accountId ? { accountId: params.accountId } : {}),
        ...(params.distributorId ? { distributorId: params.distributorId } : {}),
      },
    });
    if (response.status === 200) {
      dispatch(setDefaultEmailInvitaionTemplate(response.data));
      if (cb) cb();
    }
  } catch (err) {
    console.error(err);
  }
};
