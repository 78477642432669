import { I18n } from 'react-redux-i18n';
import * as actions from '../actionsList';

const competencyGroupsDetailsTableActions = [
  {
    name: actions.SAVE_COMPETENCY_GROUPS_DETAILS,
    label: I18n.t('Save'),
  },
  {
    name: actions.REVIEW_COMPETENCY_GROUPS_DETAILS,
    label: I18n.t('Review competencies'),
  },
];

export default competencyGroupsDetailsTableActions;
