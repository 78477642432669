import React from 'react';
import styled, { css } from 'styled-components';
import { I18n } from 'react-redux-i18n';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow-right.svg';

const CompetencyGroupNavBar = ({ next }) => {
  return (
    <Content>
      <StyledList>
        <li>
          <Step isActive>{I18n.t('Main info')}</Step>
        </li>
        <li>
          <ArrowWrapper>
            <ArrowIcon />
          </ArrowWrapper>
          <Step isActive={next}>{I18n.t('Competencies')}</Step>
        </li>
      </StyledList>
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  padding-bottom: 16px;
  border-bottom: 1px solid #cdcdcd;
`;

const StyledList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  color: #adadad;

  li {
    display: block;
    text-align: center;
    position: relative;

    & + li {
      padding-left: 50px;
    }
  }
`;

const Step = styled.span`
  text-transform: uppercase;
  ${(props) =>
    props.isActive &&
    css`
      color: #1baae1;
    `};
`;

const ArrowWrapper = styled.span`
  position: absolute;
  left: 25px;
`;

export default CompetencyGroupNavBar;
