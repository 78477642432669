import React from 'react';
import { format } from 'date-fns';
import { I18n } from 'react-redux-i18n';

const columns = [
  {
    Header: I18n.t('Respondent Name'),
    accessor: (data) => `${data.firstName} ${data.familyName}`,
    name: 'name',
    disableSortBy: true,
    width: 200,
  },
  {
    Header: I18n.t('Report'),
    accessor: 'questionnaire',
    name: 'assessment',
    disableSortBy: true,
  },
  {
    Header: I18n.t('Creation Date'),
    name: 'creationDate',
    accessor: (data) => new Date(data.creationDate),
    Cell: ({ row }) => <span>{format(new Date(row.original.creationDate), 'dd/M/y HH:mm')}</span>,
    type: 'date',
    disableSortBy: true,
  },
];

export default columns;
