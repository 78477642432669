import React, { useState } from 'react';
import { func, object, string } from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { I18n } from 'react-redux-i18n';
import NewModal from '../reusable/NewModal';
import CustomInput from '../reusable/FormComponents/Input';
import CustomButton from '../reusable/Buttons/Button';

import { createNewGroup, updateGroupDetails } from '../../store/groups/actions';

const ManageGroupDetails = ({ actionType, onClose, group }) => {
  const ACTION_MAPPER2 = {
    EDIT: '0',
    DUPLICATE: '1',
  };
  const ACTION_MAPPER = {
    0: {
      method: updateGroupDetails,
      title: I18n.t('Edit Group Details'),
    },
    1: { method: createNewGroup, title: I18n.t('Duplicate Group') },
  };

  const [groupName, setGroupName] = useState(group.description);
  const [groupNotes, setGroupNotes] = useState(group.notes);

  const dispatch = useDispatch();

  const onModalClose = () => {
    onClose();
  };

  const onSave = () => {
    const { groupID, groupType, respondents } = group;
    const data = {
      groupID,
      description: String(groupName).trim(),
      notes: String(groupNotes).trim(),
      groupType,
    };

    if (actionType === ACTION_MAPPER2.DUPLICATE) {
      delete data.groupID;
      data.respondentIds = respondents.map((respondent) => respondent.respondentID);
    }
    dispatch(
      ACTION_MAPPER[actionType].method(data, (err) => {
        if (!err) onModalClose();
      }),
    );
  };
  const shouldDisable =
    groupName === '' ||
    groupNotes === '' ||
    (String(groupName).trim() === group.description && String(groupNotes).trim() === group.notes);

  return (
    <Modal isVisible onClose={onModalClose} title={ACTION_MAPPER[actionType].title}>
      <Content>
        <Input inputName={I18n.t('Name')} value={groupName} onChange={(e) => setGroupName(e.target.value)} />
        <Input inputName={I18n.t('Description')} value={groupNotes} onChange={(e) => setGroupNotes(e.target.value)} />
      </Content>
      <ButtonsWrapper>
        <Button disabled={shouldDisable} handler={onSave}>
          {I18n.t('Save')}
        </Button>
      </ButtonsWrapper>
    </Modal>
  );
};

ManageGroupDetails.propTypes = {
  onClose: func.isRequired,
  group: object.isRequired,
  actionType: string.isRequired,
};

ManageGroupDetails.defaultProps = {};

const Modal = styled(NewModal)`
  min-width: 65rem;
  width: 65rem;
  max-height: 31rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Input = styled(CustomInput)`
  width: 100%;
  margin: 1rem 0;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Button = styled(CustomButton)`
  min-width: 12rem;
  text-transform: uppercase;
`;

export default ManageGroupDetails;
