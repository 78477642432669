import React from 'react';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';

const NotesView = ({ description }) => {
  return (
    <StyledGraphicWrapper>
      <PreviewTitle>
        <Column>
          <span>{I18n.t('Description:')}</span>
          <span>{description}</span>
        </Column>
      </PreviewTitle>
    </StyledGraphicWrapper>
  );
};

const StyledGraphicWrapper = styled.div`
  position: relative;
  overflow: auto;
  overflow-x: hidden;
  padding: 2px;
  display: flex;
  flex-wrap: wrap;
  max-width: calc(100vw - 200px);
  max-height: 100vh;
  height: inherit;
`;

const PreviewTitle = styled.div`
  display: block;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #295368;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;

  span:first-child {
    font-size: ${(props) => props.theme.fontSizes.small};
    color: ${(props) => props.theme.colors.darkBlue};
    font-weight: 600;
    margin-bottom: 1rem;
  }

  span {
    font-size: ${(props) => props.theme.fontSizes.small};
    font-weight: 500;
  }

  span:first-child {
    color: ${(props) => props.theme.colors.darkBlue};
    font-weight: 600;
    margin-bottom: 1rem;
  }
`;

export default NotesView;
