import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { push } from 'redux-first-history';
import { I18n } from 'react-redux-i18n';
import PageWrapperForProjects from '../PageWrapperForProjects';
import Table from '../reusable/Tables/Table';
import {
  applySessionsFilters,
  clearSessionsFilters,
  deleteSessionsAppliedFilter,
  fetchSessions,
  setSessionsFilterType,
  setSessionsFilterValue,
  setSessionsSearchValue,
  setSelectedSessions,
  deleteSessions,
} from '../../store/sessions/actions';
import sessionsColumns from '../../table/columns/sessions';
import SessionHeader from './SessionHeader';
import AdvancedFilter from '../reusable/AdvancedFilter';
import useTableFilter from '../../hooks/useTableFilter';
import BulkActionsSidebar from '../BulkActionsSidebar';
import useBulkSidebarState from '../../hooks/useBulkSidebarState';
import sessionsBulkActions from '../../table/bulkActions/sessions';
import breadcrumbs from './sessions.breadcrumbs';
import * as actions from '../../table/actionsList';
import useModals from '../../hooks/useModals';
import { deleteProjects, end360Project, fetch360ProjectById, setSelectedProjects } from '../../store/projects/actions';
import ConfirmationModal from '../reusable/ConfirmationModal';
import AddSessionModal from './AddSessionModal';
import SessionInvite from './SessionInvite';
import RequestReport360 from '../RequestReport360/RequestReport360';
import AddProjectsModal from '../Projects/AddProjectsModal';
import Spinner from '../reusable/Spinner';
import ImportSessions from './ImportSessions';
import CustomButton from '../reusable/Buttons/Button';
import TableEmptyState from '../reusable/TableEmptyState';

import { ReactComponent as ProjectsIcon } from '../../assets/icons/projects.svg';
import { ReactComponent as GearIcon } from '../../assets/icons/gear.svg';
import { SpecifyOwnRatersInvite } from './SpecifyOwnRatersInvite';
import AssessmentProjectStatus from '../Projects/AssessmentProject/components/AssessmentProjectStatus';
import { AP_COMPLETED_STATUS } from '../../constants/assessmentProjectStatuses';

const Sessions = ({ projectId }) => {
  const dispatch = useDispatch();

  const sessions = useSelector((state) => state.sessions.sessions);
  const selectedSessions = useSelector((state) => state.sessions.selectedSessions);
  const pageCount = useSelector((state) => state.sessions.pagesAvailable);
  const totalCount = useSelector((state) => state.sessions.totalCount);
  const filter = useSelector((state) => state.sessions.filter);
  const searchValue = useSelector((state) => state.sessions.search);
  const lastPageIndex = useSelector((state) => state.sessions.lastPageIndex);
  const activeProjectInView = useSelector((state) => state.projects.activeProjectInView);

  const [shouldResetPage, setShouldResetPageStatus] = useState(false);
  const [isLoading, setLoadingStatus] = useState(true);
  // needed for invite sessions modal; it's quite complicated and requires different flags for certain types of behaviour
  const [projectScopeEnabled, setProjectScope] = useState(false);
  const [commandType, setCommandType] = useState(0);
  // Controlling bulk actions sidebar state
  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedSessions });

  const modals = [
    actions.ADD_SESSION,
    actions.DELETE_SESSIONS,
    actions.SESSION_INVITE_RATERS,
    actions.SESSION_INVITE_SPECIFY_RATERS,
    actions.PROJECT_REQUEST_REPORT,
    actions.EDIT_PROJECT_DETAILS,
    actions.IMPORT_SESSIONS,
    actions.DELETE_PROJECTS,
  ];

  const { modalsState, openModal, closeModal } = useModals(modals);
  const completeProject = () => {
    dispatch(
      end360Project(projectId, (err) => {
        if (!err) dispatch(push('/projects'));
      }),
    );
  };
  const onProjectDelete = () => {
    dispatch(
      deleteProjects([{ projectId: Number(projectId), type: 0 }], () => {
        closeModal(actions.DELETE_PROJECTS);
        dispatch(push(`/projects`));
      }),
    );
  };

  const onSessionsDelete = () => {
    dispatch(
      deleteSessions(
        projectId,
        selectedSessions.map((p) => p.sessionId),
        () => {
          closeModal(actions.DELETE_SESSIONS);
          dispatch(fetch360ProjectById(projectId));
        },
      ),
    );
  };

  const fetchData = useCallback(({ tableData, shouldReset, showSpinner, tableCallback }) => {
    if (showSpinner) setLoadingStatus(true);
    const args = [
      tableData,
      {
        callback: () => {
          setLoadingStatus(false);
          tableCallback();
        },
        shouldReset,
      },
    ];

    dispatch(fetchSessions(projectId, ...args));
  }, []);

  const navigateToEdit = (session) => {
    dispatch(push(`/projects/${projectId}/session/${session.sessionId}/session-raters`));
    dispatch(setSelectedProjects([]));
  };

  const columns = useMemo(() => {
    return sessionsColumns(activeProjectInView);
  }, [activeProjectInView]);

  useEffect(() => {
    if (projectId) dispatch(fetch360ProjectById(projectId));
  }, [projectId, shouldResetPage]);

  // Table filtering
  const setFilterType = (name, value) => dispatch(setSessionsFilterType(name, value));
  const setFilterValue = (name, value) => dispatch(setSessionsFilterValue(name, value));
  const { inputsWithHandlers, appliedFiltersWithColumnNames } = useTableFilter({
    filter,
    columns,
    setFilterType,
    setFilterValue,
  });

  const onSelectChange = useCallback((data) => {
    dispatch(setSelectedSessions(data));
  }, []);

  const memoizedSetSessionsSearchValue = useCallback((val) => dispatch(setSessionsSearchValue(val)), []);
  const memoizedDeleteSessionsAppliedFilter = useCallback(
    (fieldName) => dispatch(deleteSessionsAppliedFilter(fieldName)),
    [],
  );
  const memoizedClearSessionsFilters = useCallback(() => dispatch(clearSessionsFilters()), []);

  const onFilterApply = () => {
    dispatch(applySessionsFilters());
  };

  // table actions
  const tableHandlers = {
    [actions.ADD_SESSION]: () => openModal(actions.ADD_SESSION),
    [actions.IMPORT_SESSIONS]: () => openModal(actions.IMPORT_SESSIONS),
  };

  // "commandType":
  //
  // 0 - all incomplete
  //
  // 1 - all uninvited, and not complete
  //
  // 2 - already invited, but not complete

  const tilesActionsHandlers = {
    [actions.PROJECT_INVITE_UNINVITED_SESSIONS]: () => {
      setCommandType(1);
      setProjectScope(true);
      openModal(actions.SESSION_INVITE_RATERS);
    },
    [actions.PROJECT_INVITE_INVITED_SESSIONS]: () => {
      setCommandType(2);
      setProjectScope(true);
      openModal(actions.SESSION_INVITE_RATERS);
    },
    [actions.SESSION_REQUEST_REPORT]: () => {
      setProjectScope(true);
      openModal(actions.SESSION_REQUEST_REPORT);
    },
  };

  const eligibleToRequestReportSessions = projectScopeEnabled
    ? activeProjectInView.sessions.filter((item) => item.completed)
    : selectedSessions.filter((session) => session.self.completeStatus);

  const bulkActions = useMemo(() => {
    return sessionsBulkActions.map((item) => ({
      ...item,
      handler: () => {
        if (item.name === actions.SESSION_INVITE_RATERS) setCommandType(item.commandType);
        openModal(item.name);
      },
      isDisabled:
        typeof item.isDisabled === 'function'
          ? item.isDisabled(selectedSessions, activeProjectInView)
          : item.isDisabled,
    }));
  }, [selectedSessions, activeProjectInView]);
  // initial table state
  const initialState = useMemo(() => ({ sortBy: [{ id: 'Creation Date', desc: true }] }), []);

  const onBackButtonClick = () => {
    setShouldResetPageStatus(true);
    dispatch(push('/projects'));
  };

  const title = activeProjectInView ? activeProjectInView.name : I18n.t('Sessions');

  const Buttons = () => (
    <ButtonsWrapper>
      <StyledButton
        size="s"
        onClick={tableHandlers[actions.IMPORT_SESSIONS]}
        disabled={activeProjectInView?.status === AP_COMPLETED_STATUS}
      >
        {I18n.t('Import sessions')}
      </StyledButton>
      <StyledButton
        size="s"
        width={130}
        onClick={tableHandlers[actions.ADD_SESSION]}
        disabled={activeProjectInView?.status === AP_COMPLETED_STATUS}
      >
        {I18n.t('Add session')}
      </StyledButton>
    </ButtonsWrapper>
  );

  return (
    <PageWrapperForProjects
      breadcrumbs={breadcrumbs(activeProjectInView)}
      title={
        <TitleComponent>
          <ProjectsIcon />
          <span>{title}</span>
          <AssessmentProjectStatus status={Number(activeProjectInView?.status)} />
        </TitleComponent>
      }
      backButtonHandler={onBackButtonClick}
      customButton={
        <SettingsRow>
          <StyledButton
            size="s"
            handler={() => openModal(actions.END_360_PROJECT)}
            disabled={Number(activeProjectInView?.status) === AP_COMPLETED_STATUS}
          >
            {I18n.t('End project')}
          </StyledButton>
          <StyledGearIcon onClick={() => openModal(actions.EDIT_PROJECT_DETAILS)} />
        </SettingsRow>
      }
    >
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      {!!activeProjectInView && <SessionHeader project={activeProjectInView} actions={tilesActionsHandlers} />}
      <TableHeaderRow>
        <TableName>{I18n.t('Sessions list')}</TableName>
        <Buttons />
      </TableHeaderRow>
      <AdvancedFilter
        searchProps={{
          appliedFilters: appliedFiltersWithColumnNames,
          deleteFilter: memoizedDeleteSessionsAppliedFilter,
          searchValue,
          onSearchChange: memoizedSetSessionsSearchValue,
        }}
        filterProps={{
          inputs: inputsWithHandlers,
          appliedInputs: filter.appliedInputs,
          applyFilters: onFilterApply,
          clearAll: memoizedClearSessionsFilters,
          isDirty: filter.dirty,
        }}
      />
      <StyledTable
        data={sessions}
        columns={columns}
        onFetchData={fetchData}
        pageCount={pageCount}
        totalCount={totalCount}
        appliedFilters={appliedFiltersWithColumnNames}
        idAccessor="sessionId"
        entityName="session"
        searchValue={searchValue}
        rowEqualityFunc={() => {
          return false;
        }}
        initialState={initialState}
        lastPageIndex={lastPageIndex}
        onSelectChange={onSelectChange}
        shouldResetPage={shouldResetPage}
        setShouldReset={setShouldResetPageStatus}
        onRowClick={navigateToEdit}
        interceptCheckboxClick
        customEmptyMessage={
          <TableEmptyState
            title={I18n.t('There are no sessions in this project')}
            message={I18n.t('You may start creating sessions via add or import sessions actions')}
          />
        }
      />
      <Spinner isLoading={isLoading} full text={I18n.t('Loading sessions')} />
      <ConfirmationModal
        isVisible={modalsState[actions.DELETE_SESSIONS]}
        title={selectedSessions.length === 1 ? I18n.t('Delete session') : I18n.t('Delete sessions')}
        description={`${I18n.t('Are you sure you want to delete')} ${
          selectedSessions.length === 1 ? I18n.t('this session?') : I18n.t('selected sessions?')
        }`}
        onClose={() => closeModal(actions.DELETE_SESSIONS)}
        onConfirm={onSessionsDelete}
        caution
      />
      {modalsState[actions.ADD_SESSION] && (
        <AddSessionModal
          onClose={() => closeModal(actions.ADD_SESSION)}
          onSuccess={() => setShouldResetPageStatus(true)}
          projectId={projectId}
        />
      )}

      {modalsState[actions.SESSION_REQUEST_REPORT] && (
        <RequestReport360
          onClose={() => closeModal(actions.SESSION_REQUEST_REPORT)}
          eligibleSessions={eligibleToRequestReportSessions}
          projectId={projectId}
          successCallback={() => {
            if (projectScopeEnabled) setProjectScope(false);
          }}
        />
      )}
      {modalsState[actions.SESSION_INVITE_RATERS] && (
        <SessionInvite
          onClose={() => closeModal(actions.SESSION_INVITE_RATERS)}
          itemType="session"
          items={selectedSessions}
          commandType={commandType}
          projectScopeEnabled={projectScopeEnabled}
          successCallback={() => {
            setShouldResetPageStatus(true);
            if (projectScopeEnabled) setProjectScope(false);
          }}
        />
      )}
      {modalsState[actions.SESSION_INVITE_SPECIFY_RATERS] && (
        <SpecifyOwnRatersInvite
          onClose={() => closeModal(actions.SESSION_INVITE_SPECIFY_RATERS)}
          items={selectedSessions}
          onSuccess={() => setShouldResetPageStatus(true)}
        />
      )}

      <ConfirmationModal
        isVisible={modalsState[actions.DELETE_PROJECTS]}
        title={I18n.t('Delete project')}
        description={I18n.t('Are you sure you want to delete this project')}
        onClose={() => closeModal(actions.DELETE_PROJECTS)}
        onConfirm={onProjectDelete}
        caution
      />

      <ConfirmationModal
        isVisible={modalsState[actions.END_360_PROJECT]}
        title={I18n.t('End project')}
        description={I18n.t('You are about to end a project')}
        onClose={() => closeModal(actions.END_360_PROJECT)}
        onConfirm={completeProject}
        caution
      />

      {modalsState[actions.IMPORT_SESSIONS] && (
        <ImportSessions
          onClose={() => closeModal(actions.IMPORT_SESSIONS)}
          onSuccess={() => setShouldResetPageStatus(true)}
        />
      )}

      {activeProjectInView && modalsState[actions.EDIT_PROJECT_DETAILS] && (
        <AddProjectsModal
          onClose={() => closeModal(actions.EDIT_PROJECT_DETAILS)}
          onReset={setShouldResetPageStatus}
          selectedProjects={[activeProjectInView]}
        />
      )}
    </PageWrapperForProjects>
  );
};

const TableHeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;
const TableName = styled.h3`
  font-size: ${(props) => props.theme.fontSizes.normal};
  color: ${(props) => props.theme.colors.darkBlue2};
  font-weight: bold;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(CustomButton)`
  margin-left: 1rem;
`;

const StyledTable = styled(Table)`
  tr:hover {
    & > div div {
      background: rgba(241, 241, 241, 0.1);
    }
  }
`;
const TitleComponent = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 0.5rem;
  }
`;
const StyledGearIcon = styled(GearIcon)`
  &:hover {
    cursor: pointer;
  }
`;

const SettingsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 40%;
  gap: 16px;
`;

export default Sessions;
