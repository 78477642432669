import handleActions from '../../immerHandleActions';
import * as actions from './actions';

const initialState = {
  isLoading: true,
  scales: [],
  selectedScales: [],
};

const responseScalesReducer = handleActions(
  {
    [actions.setActiveScale]: (draft, { payload: { scale } }) => {
      draft.activeScaleInView = scale;
    },
    [actions.setSelectedScales]: (draft, { payload: { scales } }) => {
      draft.selectedScales = scales;
    },
    [actions.fetchResponseScales]: (draft) => {
      return {
        ...draft,
        isLoading: true,
      };
    },
    [actions.fetchResponseScalesSuccess]: (draft, { payload: { data } }) => {
      return {
        ...draft,
        scales: data,
        totalCount: data.totalCount,
        pagesAvailable: data.pagesAvailable,
        lastPageIndex: data.pageNumber,
        isLoading: false,
      };
    },
    [actions.fetchResponseScalesFailure]: (draft) => {
      return {
        ...draft,
        isLoading: false,
      };
    },
    [actions.deleteResponseScalesSuccess]: (draft, { payload: { data } }) => {
      return {
        ...draft,
        scales: draft.scales.filter((scale) => !data.includes(scale.responseScaleID)),
      };
    },
    [actions.addNewResponseScale]: (draft, { payload: { data } }) => {
      return {
        ...draft,
        scales: [...draft.scales, data],
      };
    },
    [actions.resetResponseScales]: () => {
      return {
        ...initialState,
      };
    },
  },
  initialState,
);

export default responseScalesReducer;
