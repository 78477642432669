import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import {
  AP_ACTIVE_STATUS,
  AP_COMPLETED_STATUS,
  AP_INACTIVE_STATUS,
  AP_IN_PROGRESS_STATUS,
} from '../../constants/assessmentProjectStatuses';

export const statusMap = [
  {
    value: AP_INACTIVE_STATUS,
    title: I18n.t('Inactive'),
    background: 'rgba(0,0,0,0.08)',
    color: 'rgba(0,0,0,0.6)',
    borderColor: 'rgba(0,0,0,0.05)',
  },
  {
    value: AP_ACTIVE_STATUS,
    title: I18n.t('Active'),
    background: '#DBF6FF',
    color: '#008AC0',
    borderColor: '#B7EBFF',
  },
  {
    value: AP_IN_PROGRESS_STATUS,
    title: I18n.t('In progress'),
    background: '#DBF6FF',
    color: '#008AC0',
    borderColor: '#B7EBFF',
  },
  {
    value: AP_COMPLETED_STATUS,
    title: I18n.t('Completed'),
    background: '#EAF7EF',
    color: '#27AE60',
    borderColor: '#C3E9D2',
  },
];

const StatusCell = ({ row, className }) => {
  const { status: currentStatus } = row.original;
  const selectRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [activeStatus, setActiveStatus] = useState(() => statusMap.find((s) => s.value === currentStatus));

  useEffect(() => {
    setActiveStatus(statusMap.find((st) => st.value === currentStatus));
  }, [currentStatus]);

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.addEventListener('click', handleClickOutside);
    };
  }, [selectRef]);

  return (
    <StatusItem
      className={className}
      ref={selectRef}
      onClick={toggleDropdown}
      style={{
        backgroundColor: activeStatus.background,
        color: activeStatus.color,
        borderColor: activeStatus.borderColor,
      }}
    >
      {I18n.t(activeStatus.title)}
    </StatusItem>
  );
};

const StatusItem = styled.div`
  display: inline-block;
  min-width: 90px;
  text-align: center;
  background: #f9f9f9;
  border: 1px solid #cdcdcd;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #828282;
  padding: 2px 6px;
  white-space: nowrap;
  transition: all 0.2s;
`;

export default StatusCell;
