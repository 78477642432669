import React, { useContext } from 'react';
import { array, object, string, any, bool, func } from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import styled, { ThemeContext } from 'styled-components';
import { I18n } from 'react-redux-i18n';

const multiSelectStyles = ({ theme }) => ({
  container: (provided) => ({
    ...provided,
    fontSize: '1.4rem',
  }),
  control: (provided, state) => {
    const borderColor = state.isFocused ? theme.colors.lightBlue2 : theme.colors.grey2;
    const hoverBorderColor = theme.colors.lightBlue2;
    return {
      ...provided,
      height: '4rem',
      minHeight: '4rem',
      paddingLeft: '0.2rem',
      backgroundColor: state.isFocused ? theme.colors.lightBlue1 : theme.colors.white,
      borderRadius: '0.4rem',
      borderColor,
      boxShadow: 'none',
      '&:hover': {
        borderColor: hoverBorderColor,
      },
    };
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: (provided) => ({
    ...provided,
    borderRadius: '2rem',
    fontSize: '1.4rem',
    color: theme.colors.grey5,
    fontWeight: 500,
  }),
  singleValue: () => ({
    color: theme.colors.darkBlue2,
    fontWeight: 500,
    fontSize: '1.4rem',
  }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: theme.colors.white,
      fontWeight: 500,
      fontSize: '1.4rem',
      color: state.isSelected ? theme.colors.darkBlue2 : theme.colors.darkBlue2,
      '&:hover': {
        cursor: 'pointer',
        color: state.isSelected ? '#40C4FF' : theme.colors.lightBlue,
      },
      '&:active': {
        backgroundColor: theme.colors.white,
      },
    };
  },
});
const animatedComponents = makeAnimated();
const MultiSelect = ({ label, placeholder, options, value, onChange, className, creatable, ...rest }) => {
  const Component = creatable ? CreatableSelect : Select;
  const themeContext = useContext(ThemeContext);
  const handleChange = (newValue, actionMeta) => {
    let newTags = newValue;
    if (actionMeta.action === 'remove-value' && !newValue) newTags = [];
    onChange(newTags);
  };
  return (
    <Container className={className}>
      <Label>{label}</Label>
      <Component
        isMulti
        components={animatedComponents}
        onChange={handleChange}
        options={options}
        noOptionsMessage={() => I18n.t('No options')}
        value={value}
        placeholder={placeholder}
        styles={multiSelectStyles({ theme: themeContext })}
        maxMenuHeight={170}
        formatCreateLabel={(val) => `${I18n.t('createOption')} ${val}`}
        {...rest}
      />
    </Container>
  );
};

MultiSelect.propTypes = {
  options: array,
  value: object,
  onChange: func.isRequired,
  label: string,
  placeholder: string,
  creatable: bool,
  className: any,
};

MultiSelect.defaultProps = {
  options: [],
  label: '',
  placeholder: `${I18n.t('Select')}...`,
  className: '',
  creatable: false,
  value: null,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  color: ${(props) => props.theme.colors.text};
  font-size: 10px;
  line-height: 13px;
  font-style: italic;
  margin-bottom: 3px;
  padding-left: 5px;
`;

export default MultiSelect;
