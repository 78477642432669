import React, { useEffect, useState } from 'react';
import { bool, string, array, func } from 'prop-types';
import styled from 'styled-components';
import OutsideClickHandler from 'react-outside-click-handler';

import { I18n } from 'react-redux-i18n';
import FilterTextInputGroup from './TextInputGroup';
import FilterDateInputGroup from './DateInputGroup';
import FilterSelectInputGroup from './SelectInputGroup';

import Button from '../../Buttons/Button';
import { checkIfAppliedEmpty } from '../Search/Search';

import { ReactComponent as FilterIcon } from '../../../../assets/icons/filter.svg';

const checkIfUnchanged = (inputs, appliedInputs) => {
  let unchanged = true;
  for (let i = 0; i < inputs.length; i += 1) {
    const item = inputs[i];
    const correspondingAppliedInput = appliedInputs.find((obj) => obj.name === item.name);
    const isSame =
      item.value === correspondingAppliedInput.value &&
      item.filterType?.value === correspondingAppliedInput.filterType?.value;
    if (!isSame) {
      unchanged = false;
      break;
    }
  }
  return unchanged;
};

const Filter = ({ className, inputs, appliedInputs, applyFilters, clearAll, isDirty }) => {
  const [isOpen, setFilterState] = useState(false);
  const toggle = () => setFilterState((prev) => !prev);

  const onApply = () => {
    applyFilters();
    setFilterState(false);
  };

  const listener = (e) => {
    if (e.code === 'Enter' && isOpen) onApply();
  };

  useEffect(() => {
    document.addEventListener('keydown', listener);
    return () => document.removeEventListener('keydown', listener);
  }, [listener, isOpen]);
  const isUnchanged = checkIfUnchanged(inputs, appliedInputs);
  const areAppliedEmpty = checkIfAppliedEmpty(appliedInputs);
  return (
    <OutsideClickHandler onOutsideClick={() => setFilterState(false)}>
      <OuterWrapper>
        <FiltersButton isActive={isOpen} onClick={toggle} tabIndex="-1">
          <span>{I18n.t('Filter')}</span>
          <StyledFilterIcon />
        </FiltersButton>
        <FilterContainer isVisible={isOpen} className={className}>
          {inputs.map((inputObj) => {
            switch (inputObj.type) {
              case 'text':
                return (
                  <StyledTextInputGroup
                    key={inputObj.name}
                    onInputChange={inputObj.onChange}
                    inputValue={inputObj.value}
                    label={inputObj.columnName}
                    selectValue={inputObj.filterType}
                    {...inputObj}
                  />
                );
              case 'date':
                return (
                  <StyledDateInputGroup
                    key={inputObj.name}
                    onDateChange={inputObj.onChange}
                    label={inputObj.columnName}
                    selectedDate={inputObj.value}
                    selectValue={inputObj.filterType}
                    {...inputObj}
                  />
                );
              case 'select':
                return (
                  <StyledSelectInputGroup
                    key={inputObj.name}
                    selectValue={inputObj.value}
                    onSelectChange={inputObj.onChange}
                    label={inputObj.uiSelectName || inputObj.columnName}
                    options={inputObj.options}
                    onDelete={inputObj.onDelete}
                  />
                );
              default:
                return (
                  <StyledTextInputGroup
                    key={inputObj.name}
                    type={inputObj.type}
                    onInputChange={inputObj.onChange}
                    inputValue={inputObj.value}
                    label={inputObj.filteringUiName || inputObj.columnName}
                    selectValue={inputObj.filterType}
                    {...inputObj}
                  />
                );
            }
          })}
          <ButtonsWrapper>
            <ClearButton disabled={isUnchanged && areAppliedEmpty} onClick={clearAll}>
              {I18n.t('Clear all')}
            </ClearButton>
            <Button variant="rounded" disabled={!isDirty || isUnchanged} handler={onApply}>
              {I18n.t('Apply')}
            </Button>
          </ButtonsWrapper>
        </FilterContainer>
      </OuterWrapper>
    </OutsideClickHandler>
  );
};

Filter.propTypes = {
  inputs: array.isRequired,
  appliedInputs: array.isRequired,
  applyFilters: func.isRequired,
  clearAll: func.isRequired,
  className: string,
  isDirty: bool.isRequired,
};

Filter.defaultProps = {
  className: '',
};

const OuterWrapper = styled.div`
  position: relative;
  margin-left: 1rem;
`;

const StyledFilterIcon = styled(FilterIcon)`
  width: 1.4rem;
  min-width: 1.4rem;
  height: 1.4rem;
  min-height: 1.4rem;
  path {
    transition: all 0.2s;
    fill: ${(props) => props.theme.colors.grey5};
  }
`;

const FiltersButton = styled.div`
  outline: none;
  border: 1px solid ${(props) => props.theme.colors.grey2};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.grey5};
  border-radius: 0.4rem;
  padding: 1rem 2rem;
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 600;
  transition: all 0.2s;
  width: 100%;

  span {
    margin-right: 0.5rem;
    min-width: 100%;
    white-space: nowrap;
  }

  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.lightBlue};
    border-color: ${(props) => props.theme.colors.lightBlue};
    color: ${(props) => props.theme.colors.white};

    ${StyledFilterIcon} {
      path {
        fill: ${(props) => props.theme.colors.white};
      }
    }
  }

  :active {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.mediumBlue};
    border-color: ${(props) => props.theme.colors.mediumBlue};
    color: ${(props) => props.theme.colors.white};

    ${StyledFilterIcon} {
      path {
        fill: ${(props) => props.theme.colors.white};
      }
    }
  }
`;

const FilterContainer = styled.div`
  position: absolute;
  z-index: 101;
  top: 4rem;
  right: 0;
  min-width: 465px;
  display: flex;
  flex-direction: column;
  padding: 16px 15px;
  box-shadow: ${(props) => props.theme.shadows.default};
  border-radius: 0.6rem;
  background: ${(props) => props.theme.colors.white};
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  transition: opacity 0.1s;
`;

const StyledTextInputGroup = styled(FilterTextInputGroup)`
  margin-bottom: 10px;
`;
const StyledDateInputGroup = styled(FilterDateInputGroup)`
  margin-bottom: 10px;
`;

const StyledSelectInputGroup = styled(FilterSelectInputGroup)`
  margin-bottom: 10px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.6rem;
`;

const ClearButton = styled.button`
  font-size: ${(props) => props.theme.fontSizes.small};
  color: ${(props) => props.theme.colors.lightBlue2};
  font-weight: 600;
  border: none;
  outline: none;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.2s;
  background-color: ${(props) => props.theme.colors.white};

  :hover {
    color: ${(props) => props.theme.colors.mediumBlue};
  }
`;

export default Filter;
