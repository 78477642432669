import { I18n } from 'react-redux-i18n';
import { ADD_COMPETENCY_CATEGORY } from '../actionsList';

const competenciesTableActions = [
  {
    name: ADD_COMPETENCY_CATEGORY,
    label: I18n.t('Add competency category'),
  },
];

export default competenciesTableActions;
