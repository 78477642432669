import React from 'react';
import styled from 'styled-components';
import { func, object, array, bool } from 'prop-types';
import { intersection } from 'lodash';

import { I18n } from 'react-redux-i18n';
import { ReactComponent as Check } from '../../../assets/icons/check-white.svg';

import { ReactComponent as ArrowUp } from '../../../assets/icons/blue-arrow-up-big.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/blue-arrow-down-big.svg';

import AnimateHeightWrapper from '../../reusable/AnimateHeight';

export const findIfReportIsDisabled = (report, tests, idealProfiles) => {
  const profilesIds = Object.keys(idealProfiles);
  const dependencies = report.dependencies.split(';');
  const matches = intersection(tests, dependencies);
  return (
    matches.length !== dependencies.length ||
    (report.isIdealProfileReport && !profilesIds.includes(report.ipDependencies))
  );
};

const Set = ({ set, onSetClick, onSetCheckboxClick, onReportClick, commonTests, idealProfiles, compact }) => {
  const { isOpen, isChecked } = set;
  const setHeight = set.reports.length > 4 ? 155 : 'auto';
  const isWholeSetDisabled = set.reports.every((item) => findIfReportIsDisabled(item, commonTests, idealProfiles));
  if (isWholeSetDisabled) return null;
  return (
    <SetContainer disabled={isWholeSetDisabled}>
      <SetVisiblePart
        compact={compact}
        onClick={() => !isWholeSetDisabled && onSetClick(set.reportSetID)}
        isSetOpen={isOpen}
        disabled={isWholeSetDisabled}
      >
        <FlexContentHolder>
          <Checkbox
            onClick={(e) => {
              if (isWholeSetDisabled) return;
              onSetCheckboxClick(set.reportSetID);
              e.stopPropagation();
            }}
            isActive={isChecked}
          >
            {isChecked && <CheckIcon />}
          </Checkbox>
          <SetName>{set.name}</SetName>
        </FlexContentHolder>
        <FlexContentHolder>
          <CreditsCost>
            {set.creditsCost} {I18n.t('credits')}
          </CreditsCost>
          {isOpen ? <StyledArrowUp /> : <StyledArrowDown />}
        </FlexContentHolder>
      </SetVisiblePart>
      <StyledAnimateHeight height={isOpen ? setHeight : 0}>
        <SetReportsContainer>
          {set.reports.map((item) => {
            const isDisabled = findIfReportIsDisabled(item, commonTests, idealProfiles);
            return (
              <TestReport key={item.reportID} disabled={isDisabled}>
                <Checkbox
                  onClick={() => !isDisabled && onReportClick(set.reportSetID, item.reportID)}
                  isActive={item.isChecked}
                  disabled={isDisabled}
                >
                  {item.isChecked && <CheckIcon />}
                </Checkbox>
                <span>{item.displayName || item.name}</span>
              </TestReport>
            );
          })}
        </SetReportsContainer>
      </StyledAnimateHeight>
    </SetContainer>
  );
};

Set.propTypes = {
  set: object.isRequired,
  onSetClick: func.isRequired,
  onSetCheckboxClick: func.isRequired,
  onReportClick: func.isRequired,
  commonTests: array.isRequired,
  idealProfiles: object.isRequired,
  compact: bool,
};

Set.defaultProps = {
  compact: false,
};

const FlexContentHolder = styled.div`
  display: flex;
  align-items: center;
`;

const SetContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #cccccc;
  border-radius: 7px;
  margin-bottom: 5px;

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const SetVisiblePart = styled.div`
  display: flex;
  align-items: center;
  padding: ${(props) => (props.compact ? '10px 7px' : '18px 15px')};
  justify-content: space-between;
  // border-bottom: ${(props) => (props.isSetOpen ? '1px solid #cccccc' : 'none')};

  :hover {
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  }
`;

const SetName = styled.span`
  color: ${(props) => props.theme.colors.darkBlue2};
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
`;

const CreditsCost = styled.span`
  margin-right: 10px;
  margin-left: 5px;
  color: ${(props) => props.theme.colors.lightBlue};
  font-weight: 600;
  white-space: nowrap;
  font-size: 14px;
  line-height: 20px;
`;

const Checkbox = styled.div`
  width: 17px;
  height: 17px;
  min-width: 17px;
  min-height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  background-color: ${(props) => (props.isActive ? props.theme.colors.primary : props.theme.colors.white)};
  border-radius: 2px;
  margin-right: 1rem;
  transition: all 0.2s;

  :hover {
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  }
`;

const StyledArrowUp = styled(ArrowUp)`
  width: 1.2rem;
  height: 1.2rem;
  path {
    fill: #295368;
  }
`;

const StyledArrowDown = styled(ArrowDown)`
  width: 1.2rem;
  height: 1.2rem;
  path {
    fill: #295368;
  }
`;

const CheckIcon = styled(Check)`
  width: 12px;
  height: 12px;
`;

const SetReportsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TestReport = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.primary};
  font-size: 10px;
  font-weight: 500;
  padding: 10px 15px;
  border-bottom: 1px solid #f1f1f1;
  cursor: default;

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const StyledAnimateHeight = styled(AnimateHeightWrapper)`
  overflow: auto !important;

  &::-webkit-scrollbar-track {
    border-radius: 20px;
  }
`;

export default React.memo(Set, (prev, next) => {
  if (prev.alwaysUpdate) return false;
  const findLowLevelStates = (reports) => reports.reduce((acc, item) => `${acc}${item.isChecked}`, '');
  const isNormSame = (prev.set.norm && prev.set.norm.value) === (next.set.norm && next.set.norm.value);
  const isIdealProfileSame =
    (prev.set.idealProfile && prev.set.idealProfile.value) === (next.set.idealProfile && next.set.idealProfile.value);
  return (
    prev.set.isOpen === next.set.isOpen &&
    prev.set.isChecked === next.set.isChecked &&
    isNormSame &&
    isIdealProfileSame &&
    findLowLevelStates(prev.set.reports) === findLowLevelStates(next.set.reports)
  );
});
