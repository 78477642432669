import React from 'react';
import { func, object, string } from 'prop-types';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';

import { textFilterOptions, numberFilterOptions } from '../../../../constants/advancedFilterOptions';
import CustomInput from '../../FormComponents/Input';
import CustomSelect from '../../Selects/Select';
import CloseIcon from '../../CloseIcon';

const FilterTextInputGroup = ({
  className,
  onInputChange,
  onSelectChange,
  onDelete,
  inputValue,
  selectValue,
  label,
  type,
}) => {
  const options = type === 'text' ? textFilterOptions : numberFilterOptions;
  const translatedOptions = options.map((item) => ({ ...item, label: I18n.t(item.label) }));

  return (
    <OuterContainer className={className}>
      <InputsContainer>
        <StyledInput value={inputValue} onChange={onInputChange} inputName={label} type={type} />
        <StyledSelect
          options={translatedOptions}
          selectProps={{ onChange: onSelectChange, value: { ...selectValue, label: I18n.t(selectValue.label) } }}
          placeholder={I18n.t('Filter type')}
        />
        <StyledCloseIcon clickHandler={onDelete} />
      </InputsContainer>
    </OuterContainer>
  );
};
FilterTextInputGroup.propTypes = {
  onInputChange: func.isRequired,
  onSelectChange: func.isRequired,
  onDelete: func.isRequired,
  inputValue: string,
  selectValue: object,
  label: string.isRequired,
  className: string,
  type: string.isRequired,
};

FilterTextInputGroup.defaultProps = {
  inputValue: '',
  selectValue: null,
  className: '',
};

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const InputsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInput = styled(CustomInput)`
  width: 25rem;
  margin-right: 1rem;
`;

const StyledSelect = styled(CustomSelect)`
  width: 15rem;
  margin-bottom: 0;
  margin-right: 1.5rem;
`;

const StyledCloseIcon = styled(CloseIcon)`
  width: 1rem;
  height: 1rem;
`;

export default React.memo(FilterTextInputGroup, (prev, next) => {
  return prev.inputValue === next.inputValue && prev.selectValue === next.selectValue;
});
