const isEqualProps = (prev, next, ignoreKeys = [], maxDepth = 5) => {
  if ((!prev && next) || (prev && !next)) {
    return false;
  }

  if (typeof prev === 'function' && typeof next === 'function') {
    return true;
  }

  if (typeof prev === 'object' && typeof next === 'object' && prev !== null && next !== null) {
    if (Object.keys(prev).length !== Object.keys(next).length) {
      return false;
    }

    return Object.keys(next).reduce((isEqual, key) => {
      if (ignoreKeys.includes(key)) {
        return isEqual;
      }

      return maxDepth ? isEqual && isEqualProps(prev[key], next[key], ignoreKeys, maxDepth - 1) : isEqual;
    }, true);
  }

  return prev === next;
};

export default isEqualProps;
