import React from 'react';
import { func, oneOfType, object } from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect } from '@reach/router';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  return isLoggedIn ? <Component {...rest} /> : <Redirect noThrow to="/login" />;
};

PrivateRoute.propTypes = {
  component: oneOfType([func, object]).isRequired,
};

export default PrivateRoute;
