/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import CustomBadge from '../../components/reusable/Badges/CustomBadge';
import { ReactComponent as Ftype } from '../../assets/icons/settings/ftype.svg';
import { ReactComponent as SDtype } from '../../assets/icons/settings/sdtype.svg';
import isChineseEnv from '../../utils/isChineseEnv';

export const caseInsensitiveSortingFunc = (prev, next, columnId) => {
  if (prev.original[columnId].toLowerCase() > next.original[columnId].toLowerCase()) return 1;
  if (prev.original[columnId].toLowerCase() < next.original[columnId].toLowerCase()) return -1;
  return 0;
};

const NormNameCell = ({ row }) => {
  const originalRow = row.original;
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <IconWrapper>
        {originalRow.type === 'F' && <Ftype />}
        {originalRow.type === 'SD' && <SDtype />}
      </IconWrapper>
      <span>{originalRow.name}</span>
      {originalRow.normID > 1000000 && <CustomBadge />}
    </div>
  );
};

const DateCell = ({ row }) => {
  return <span>{format(new Date(row.original.creationDate), 'dd/M/y')}</span>;
};

const columns = [
  {
    id: 'name',
    name: 'name',
    type: 'text',
    accessor: 'name',
    sortType: caseInsensitiveSortingFunc,
    Header: 'Name',
    Cell: NormNameCell,
    disableFilters: true,
  },
  {
    id: 'type',
    name: 'type',
    type: 'select',
    options: [
      {
        value: 'SD',
        label: 'SD',
      },
      {
        value: 'F',
        label: 'F',
      },
    ],
    accessor: 'type',
    storType: 'alphanumeric',
    Header: 'Type',
    width: 40,
    filter: (rows, [id], value) => (value ? rows.filter((row) => row.values[id] === value.value) : rows),
  },
  {
    id: 'sampleSize',
    name: 'sampleSize',
    type: 'number',
    accessor: 'sampleSize',
    sortType: 'basic',
    Header: 'Sample Size',
    disableFilters: true,
    width: 60,
  },
  {
    id: 'language',
    name: 'language',
    accessor: 'language',
    storType: 'alphanumeric',
    Header: 'Language',
    width: 60,
    type: 'select',
    Cell: ({ row }) => (isChineseEnv && row.original.language === 'CH' ? '中文' : row.original.language),
    filter: (rows, [id], value) => (value ? rows.filter((row) => row.values[id] === value.value) : rows),
    options: [
      {
        value: 'EN',
        label: 'EN',
      },
      {
        value: 'CH',
        label: isChineseEnv ? '中文' : 'CH',
      },
    ],
  },
  {
    id: 'countryOfOrigin',
    name: 'countryOfOrigin',
    accessor: 'countryOfOrigin',
    storType: 'alphanumeric',
    Header: 'Country of origin',
    width: 60,
    type: 'select',
    filter: (rows, [id], value) => (value ? rows.filter((row) => row.values[id] === value.value) : rows),
  },
  {
    id: 'creationDate',
    name: 'creationDate',
    type: 'date',
    accessor: (data) => new Date(data.creationDate),
    sortType: 'datetime',
    Header: 'Creation Date',
    Cell: (props) => <DateCell {...props} />,
    width: 60,
    disableFilters: true,
  },
  {
    id: 'visible',
    name: 'visible',
    accessor: (data) => (data.visible ? 'True' : 'False'),
    type: 'select',
    storType: 'alphanumeric',
    Header: 'Visible',
    width: 60,
    filter: (rows, [id], value) => (value ? rows.filter((row) => row.values[id] === value.value) : rows),
    options: [
      {
        value: 'True',
        label: 'True',
      },
      {
        value: 'False',
        label: 'False',
      },
    ],
  },
  {
    id: 'testID',
    name: 'testID',
    accessor: 'testID',
    sortType: caseInsensitiveSortingFunc,
    Header: 'testID',
    isVisible: false,
    filter: (rows, [id], value) =>
      value
        ? rows.filter((row) => {
            return row.values[id].toLowerCase() === value.toLowerCase();
          })
        : rows,
    type: 'select',
    options: [],
    disableFilters: true,
  },
];

const IconWrapper = styled.div`
  width: 28px;
  height: 28px;
  margin-right: 12px;
`;

export default columns;
