import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormikInput from '../../reusable/FormComponents/FormikInput';
import CustomButton from '../../reusable/Buttons/Button';
import competenciesSelectors from '../../../store/settings/competencies/selectors';
import CompetencyGroupsAccordion from '../../reusable/Accordion/CompetencyGroupsAccordion';
import { fetchCompetenciesAction, resetCompetencies } from '../../../store/settings/competencies/actions';
import apiInstance2 from '../../../services/apiService';
import createToastNotification from '../../../utils/createToastNotification';
import { errorMessages } from '../../../constants/errorMessages';
import NewModal from '../../reusable/NewModal';

export default ({ importeValues, onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState({ custom: {}, inbuilt: {}, imported: {} });
  const [groupsByType, setGroupsByType] = useState({ imported: [], custom: [], inbuilt: [] });
  const [isLoading, setIsLoading] = useState(false);

  const list = useSelector(competenciesSelectors.selectData);

  const onSelectCompetency = (name, items) => {
    setSelected({ ...selected, [name]: items.reduce((acc, item) => ({ ...acc, [item.competencyID]: item }), {}) });
  };

  useEffect(() => {
    dispatch(fetchCompetenciesAction());

    const preselect = {};
    importeValues.forEach((category) => {
      category.categories.forEach((competency) => {
        if (competency.competencyID) {
          preselect[competency.competencyID] = competency;
        }
      });
    });
    setSelected({ ...selected, imported: preselect });

    return () => {
      dispatch(resetCompetencies());
    };
  }, []);

  useEffect(() => {
    setGroupsByType({
      imported: importeValues,
      custom: list
        .filter((item) => item.competencyType)
        .filter(
          (category) =>
            !importeValues.find((importedCategory) => importedCategory.competencyID === category.competencyID),
        ),
      inbuilt: list.filter((item) => !item.competencyType),
    });
  }, [list]);

  const addCompetencies = async (values) => {
    setIsLoading(true);
    const result = await apiInstance2.post(`/api/v2/360/competency-groups`, values).catch(() => {
      setIsLoading(false);
    });

    const { inbuilt, custom, imported } = selected;
    const mapped = Object.values({ ...inbuilt, ...custom, ...imported }).map((item) => {
      return {
        competencyID: item.competencyID,
        competencyType: item.competencyType || 0,
      };
    });

    await apiInstance2
      .post(`/api/v2/360/competency-groups/${result.data.competencyGroupID}/competencies`, mapped)
      .catch(() => setIsLoading(false));

    createToastNotification({ message: I18n.t('addedAssessmentToast') });
    onSuccess(`/settings/360-assessments/${result.data.competencyGroupID}`);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(I18n.t('Name is required'))
      .max(100, errorMessages.tooLong(100)),
  });

  return (
    <StyledModal title={I18n.t('Create 360 survey')} isVisible onClose={onClose}>
      <Formik
        initialValues={{
          name: '',
        }}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
        enableReinitialize
        onSubmit={(values) => addCompetencies(values)}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => {
          return (
            <>
              <ContentWrapper>
                <div>
                  <StyledCustomInput
                    inputName={I18n.t('Enter Survey Name')}
                    name="name"
                    id="name"
                    value={values.name}
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                  />
                </div>
                <AccordionWrapper>
                  <CompetencyGroupsAccordion
                    list={groupsByType.imported}
                    selected={selected.imported}
                    label={I18n.t('Imported competencies')}
                    onSelectedChanged={(items) => onSelectCompetency('imported', items)}
                  />
                  <CompetencyGroupsAccordion
                    list={groupsByType.custom}
                    selected={selected.custom}
                    label={I18n.t('Custom competencies')}
                    onSelectedChanged={(items) => onSelectCompetency('custom', items)}
                  />
                  <CompetencyGroupsAccordion
                    list={groupsByType.inbuilt}
                    selected={selected.inbuilt}
                    label={I18n.t('Inbuilt competencies')}
                    onSelectedChanged={(items) => onSelectCompetency('inbuilt', items)}
                  />
                </AccordionWrapper>
              </ContentWrapper>
              <ButtonsWrapper>
                <StyledButton disabled={isLoading} handler={handleSubmit}>
                  {I18n.t('Create')}
                </StyledButton>
              </ButtonsWrapper>
            </>
          );
        }}
      </Formik>
    </StyledModal>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  margin-bottom: 1rem;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const AccordionWrapper = styled.div`
  margin: 20px 0;
`;

const StyledButton = styled(CustomButton)`
  width: 12rem;
  text-transform: uppercase;
`;

const StyledCustomInput = styled(FormikInput)`
  margin-top: 15px;
`;

const StyledModal = styled(NewModal)`
  height: 100%;
  max-height: 54rem;
  min-width: 82rem;
  max-width: 110rem;
  display: flex;
  flex-direction: column;
`;
