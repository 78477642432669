import React from 'react';
import { I18n } from 'react-redux-i18n';

import CustomBadge from '../../components/reusable/Badges/CustomBadge';
import { resolveAssessmentName } from '../../utils/assessmentsNames';

const resolveType = (item) => {
  if (item.batteryID && item.isSolution) return `${I18n.t('Solution')} (${item.uses.split(';').join(',')})`;
  if (item.batteryID && !item.isSolution) return `${I18n.t('Battery')} (${item.uses.split(';').join(',')})`;
  return I18n.t('Assessment');
};

const columns = [
  {
    Header: I18n.t('Name'),
    accessor: (data) => {
      return (
        <>
          <span>{resolveAssessmentName(data)}</span>
          {data.batteryID && data.batteryID > 1000000 && <CustomBadge />}
        </>
      );
    },
    name: 'name',
    type: 'text',
    sortType: (a, b) =>
      resolveAssessmentName(a.original).toLowerCase() > resolveAssessmentName(b.original).toLowerCase() ? 1 : -1,
    width: 250,
  },
  {
    Header: I18n.t('Type'),
    accessor: (data) => resolveType(data),
    name: 'type',
    type: 'text',
  },
  {
    Header: I18n.t('Available languages'),
    // TODO: Refactor
    accessor: (data) =>
      typeof data.langAvailability !== 'undefined' ? data.langAvailability.split(';').join(',') : [],
    name: 'languages',
    type: 'text',
    disableSortBy: true,
  },
];

export default columns;
