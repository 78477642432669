import React, { useState, useEffect } from 'react';
import { string, array, func, bool, any } from 'prop-types';
import styled from 'styled-components';
import { EditorState, ContentState, Modifier, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { I18n } from 'react-redux-i18n';
import CustomSelect from '../Selects/Select';
import ColorPicker from './ColorPicker';

const createContentStateFromHtml = (markup) => {
  const blocksFromHTML = htmlToDraft(markup);
  return ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
};

const createHTMLFromDraft = (editorState) => draftToHtml(convertToRaw(editorState.getCurrentContent()));

const defaultToolbar = {
  options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'history'],
  inline: {
    options: ['bold', 'italic', 'underline'],
  },
  colorPicker: {
    component: ColorPicker,
  },
};

const EditorComponent = ({ initialValue, variables, onChange, error, maxWidth, toolbar }) => {
  const [editorState, setEditorState] = useState(
    initialValue ? EditorState.createWithContent(createContentStateFromHtml(initialValue)) : EditorState.createEmpty(),
  );

  useEffect(() => {
    if (initialValue) setEditorState(EditorState.createWithContent(createContentStateFromHtml(initialValue)));
  }, [initialValue]);

  const onValueChange = (newState) => {
    setEditorState(newState);
    onChange(createHTMLFromDraft(newState));
  };

  const onVariableSelect = (data) => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      data.value,
      editorState.getCurrentInlineStyle(),
    );
    const newEditor = EditorState.push(editorState, contentState, 'insert-characters');
    setEditorState(newEditor);
    onChange(createHTMLFromDraft(newEditor));
  };

  return (
    <Container error={error} maxWidth={maxWidth}>
      <Editor
        editorState={editorState}
        wrapperClassName="wrapper"
        editorClassName="editor"
        onEditorStateChange={onValueChange}
        toolbar={toolbar || defaultToolbar}
        toolbarCustomButtons={
          variables.length
            ? [
                <Select
                  options={variables}
                  placeholder={I18n.t('Insert variable')}
                  selectProps={{ value: null, onChange: onVariableSelect, menuPosition: 'fixed' }}
                  withoutDropdownIndicator
                  zIndex={10}
                />,
              ]
            : []
        }
      />
    </Container>
  );
};

EditorComponent.propTypes = {
  initialValue: string,
  variables: array,
  onChange: func.isRequired,
  error: string,
  maxWidth: bool,
  toolbar: any,
};

EditorComponent.defaultProps = {
  initialValue: '',
  error: '',
  variables: [],
  maxWidth: true,
  toolbar: false,
};

const Container = styled.div`
  .wrapper {
    display: flex;
    flex-direction: column;
  }
  .editor {
    height: 24rem;
    max-width: ${(props) => (props.maxWidth ? `${document.body.clientWidth - 280}px` : 'unset')};
    border: 1px solid ${(props) => (props.error ? props.theme.colors.red : props.theme.colors.grey2)};
  }
  .rdw-editor-toolbar {
    border: 1px solid ${(props) => (props.error ? props.theme.colors.red : props.theme.colors.grey2)};
    border-top: none;
    order: 1;
    padding: 0.6rem 0.5rem 0 0.5rem;
  }
  .rdw-link-modal {
    height: auto;
  }
  .rdw-fontsize-dropdown {
    min-width: 6rem;
  }
  .rdw-block-dropdown {
    min-width: 12rem;
  }
  .rdw-dropdown-optionwrapper {
    width: 100%;
    overflow-y: hidden;
  }
  .DraftEditor-editorContainer,
  .DraftEditor-root,
  .public-DraftEditor-content {
    height: auto;
    width: 100%;
  }
  .DraftEditor-editorContainer {
    padding-left: 1rem;
  }
`;

const Select = styled(CustomSelect)`
  width: 13rem;
  min-width: 13rem;
  margin-bottom: 0.6rem;
`;

export default EditorComponent;
