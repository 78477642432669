import React from 'react';
import { I18n } from 'react-redux-i18n';
import { func, object } from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as GearIcon } from '../../../assets/icons/gear.svg';

import CustomButton from '../../reusable/Buttons/Button';
import { AP_COMPLETED_STATUS } from '../../../constants/assessmentProjectStatuses';

const AssessmentProjectTitleRow = ({ onGearClick, onEndProject, project }) => {
  if (!project) return null;
  return (
    <SettingsRow>
      <StyledButton size="s" handler={onEndProject} disabled={Number(project?.status) === AP_COMPLETED_STATUS}>
        {I18n.t('End project')}
      </StyledButton>
      <GearWrapper onClick={onGearClick}>
        <StyledGearIcon />
      </GearWrapper>
    </SettingsRow>
  );
};

AssessmentProjectTitleRow.propTypes = {
  project: object,
  onGearClick: func.isRequired,
  onEndProject: func.isRequired,
};
AssessmentProjectTitleRow.defaultProps = {
  project: null,
};

const SettingsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 40%;
`;

const GearWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem;
  border-radius: 50%;
  border: 2px solid ${(props) => props.theme.colors.lightBlue};
  :hover {
    cursor: pointer;
  }
`;

const StyledGearIcon = styled(GearIcon)`
  width: 1.5rem;
  height: 1.5rem;
`;

const StyledButton = styled(CustomButton)`
  text-transform: uppercase;
  margin-right: 1rem;
`;

export default AssessmentProjectTitleRow;
