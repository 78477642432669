import React from 'react';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import isEqualProps from '../../../../utils/isEqualProps';
import storageService from '../../../../services/storageService';

const QuestionItem = ({ question, number, count, responses }) => {
  const language = storageService.getItem('psLang');

  const translatedItemName = question?.languages?.[language?.toUpperCase()];

  const translatedCategory = question?.category?.languages?.find((entry) => entry?.langID?.toLowerCase() === language);

  const remapedQuestion = {
    ...question,
    category: {
      ...question.category,
      ...(translatedCategory || {}),
    },
    ...(translatedItemName ? { item: translatedItemName } : {}),
  };

  return (
    <QuestionWrapper>
      <Header>
        <Title valid>
          {I18n.t('Question')} {number} / {count}
        </Title>
        <LabelWrapper>
          <Label colors={remapedQuestion.category.colors}>{remapedQuestion.category.name}</Label>
          <Label colors={remapedQuestion.category.colors}>{I18n.t(remapedQuestion.competencyName)}</Label>
        </LabelWrapper>
      </Header>
      <Description isTranslation={remapedQuestion.isTranslation}>
        {!remapedQuestion.isTranslation && <NoTranslation>{I18n.t('No translation available for')}</NoTranslation>}
        {remapedQuestion.item}
      </Description>
      <ResponseWrapper>
        {!responses.isTranslation && <NoTranslation>{I18n.t('No translation available for')}</NoTranslation>}
        <Responses>
          {responses.data &&
            responses.data.map((item) => {
              const responseName =
                typeof item.name === 'string' && item.name.startsWith('@') ? item.name.slice(1) : item.name;
              return (
                <Response key={item.index}>
                  {item.index}. {responseName}
                </Response>
              );
            })}
        </Responses>
      </ResponseWrapper>
    </QuestionWrapper>
  );
};

const QuestionWrapper = styled.div`
  padding: 10px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  min-width: 120px;
  color: ${(props) => (props.valid ? props.theme.colors.dark : props.theme.colors.red)};
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Label = styled.div`
  border-radius: 4px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-right: 5px;
  padding: 3px 5px;
  border: 1px solid ${(props) => props.colors.border};
  color: ${(props) => props.colors.color};
  background: ${(props) => props.colors.background};
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  margin-top: 8px;
  color: ${(props) => (props.isTranslation ? props.theme.colors.dark : 'rgba(41, 83, 104, 0.5)')};
`;

const ResponseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

const Responses = styled.div`
  display: flex;
`;

const Response = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  margin-right: 8px;
  color: ${(props) => props.theme.colors.grey3};
`;

const NoTranslation = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 5px;
  color: ${(props) => props.theme.colors.grey3};
`;

export default React.memo(QuestionItem, (prevProps, nextProps) => {
  return isEqualProps(prevProps, nextProps);
});
