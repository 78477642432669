import React from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import { push } from 'redux-first-history';
import { useDispatch } from 'react-redux';

import Modal from '../../reusable/NewModal';
import CustomButton from '../../reusable/Buttons/Button';
import { ReactComponent as RightArrow } from '../../../assets/icons/arrow-right-thin.svg';

const NoProjectModal = ({ onClose }) => {
  const dispatch = useDispatch();

  const goToProjectsList = () => dispatch(push('/projects'));

  return (
    <StyledModal isVisible onClose={onClose} isHeaderHidden>
      <Title>{I18n.t('Failed to open project')}</Title>
      <Description>{I18n.t('Project does not exist or URL is incorrect')}</Description>
      <StyledButton handler={goToProjectsList}>
        <BackArrow />
        {I18n.t('back to projects list')}
      </StyledButton>
    </StyledModal>
  );
};

NoProjectModal.propTypes = {
  onClose: func.isRequired,
};

const StyledModal = styled(Modal)`
  min-width: 42rem;
  height: 18rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h3`
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.darkBlue2};
  margin-bottom: 0.8rem;
`;

const Description = styled.p`
  font-size: ${(props) => props.theme.fontSizes.normal};
  line-height: 24px;
  color: ${(props) => props.theme.colors.darkBlue2};
  margin-bottom: 1.6rem;
`;

const BackArrow = styled(RightArrow)`
  transform: rotate(180deg);
  margin-right: 1rem;
`;

const StyledButton = styled(CustomButton)`
  text-transform: uppercase;
`;

export default NoProjectModal;
