import React from 'react';
import { object } from 'prop-types';
import styled from 'styled-components';
import { format } from 'date-fns';
import { I18n } from 'react-redux-i18n';

import groupsTypesMap from '../../constants/groupTypes';

const GroupHeader = ({ group }) => {
  const creationDate = format(new Date(group.creationDate), 'dd/M/y');
  return (
    <GroupHeaderContainer>
      <Description>{group.notes}</Description>
      <Column>
        <span>{I18n.t('Type')}</span>
        <span>{I18n.t(groupsTypesMap[group.groupType])}</span>
      </Column>
      <Column>
        <span>{I18n.t('Creation date')}</span>
        <span>{creationDate}</span>
      </Column>
    </GroupHeaderContainer>
  );
};

GroupHeader.propTypes = {
  group: object.isRequired,
};

const GroupHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 1.5rem 0;
  min-height: 35px;
`;

const Description = styled.div`
  width: 50%;
  font-size: ${(props) => props.theme.fontSizes.small};
  align-items: flex-start;
  height: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;

  span:first-child {
    font-size: ${(props) => props.theme.fontSizes.small};
    color: ${(props) => props.theme.colors.darkBlue};
    font-weight: 600;
    margin-bottom: 1rem;
  }

  span {
    font-size: ${(props) => props.theme.fontSizes.small};
    font-weight: 500;
  }
`;

export default GroupHeader;
