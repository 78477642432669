import { I18n } from 'react-redux-i18n';

export const NOT_SENT_INVITE_STATUS = 0;
export const SCHEDULED_INVITE_STATUS = 1;
export const SENT_INVITE_STATUS = 2;
export const FAILED_INVITE_STATUS = 3;
export const FAILEDV2_INVITE_STATUS = 4;
export const ACTIVE_INVITE_STATUS = 5;
export const EXPIRED_INVITE_STATUS = 6;
export const COMPLETED_INVITE_STATUS = 7;

const inviteStatuses = {
  [NOT_SENT_INVITE_STATUS]: I18n.t('Not sent'),
  [SCHEDULED_INVITE_STATUS]: I18n.t('Scheduled'),
  [SENT_INVITE_STATUS]: I18n.t('Sent'),
  [FAILED_INVITE_STATUS]: I18n.t('Failed'),
  [FAILEDV2_INVITE_STATUS]: I18n.t('Failed status 2'),
  [ACTIVE_INVITE_STATUS]: I18n.t('Active'),
  [EXPIRED_INVITE_STATUS]: I18n.t('Expired'),
  [COMPLETED_INVITE_STATUS]: I18n.t('Completed'),
};

export default inviteStatuses;
