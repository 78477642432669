import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { I18n } from 'react-redux-i18n';
import Modal from '../reusable/NewModal';
import Button from '../reusable/Buttons/Button';
import RadioButton from '../reusable/FormComponents/RadioButton';
import TextArea from '../reusable/FormComponents/TextArea';
import Select from '../reusable/Selects/Select';
import { getTestsWithSystemNorms } from '../../store/settings/norms/actions';
import { addNewNorm, fetchBriefGroups } from '../../store/norms/actions';
import FormikInput from '../reusable/FormComponents/FormikInput';
import { errorMessages } from '../../constants/errorMessages';
import { resolveAssessmentName } from '../../utils/assessmentsNames';

const AddNormModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const testsWithSystemNorms = useSelector((state) => state.settingsNorms.testsWithSystemNorms);
  const groups = useSelector((state) => state.norms.groups);
  const isCreateNormInProgress = useSelector((state) => state.norms.isCreateNormInProgress);

  const availableTests = testsWithSystemNorms.map((assessment) => ({
    label: resolveAssessmentName(assessment),
    value: assessment.testID,
  }));

  const { values, handleChange, errors, touched, setFieldValue, handleSubmit, setFieldError } = useFormik({
    initialValues: {
      name: '',
      sampleSize: '',
      test: '',
      group: '',
      description: '',
      normType: 'F',
    },
    validateOnChange: false,
    onSubmit: ({ description, name, normType, sampleSize, test, group }) => {
      dispatch(
        addNewNorm(
          {
            description,
            name,
            normType,
            testId: test.value,
            ...(sampleSize && { sampleSize }),
            ...(group && { groupIds: [group.value] }),
          },
          (err) => {
            if (typeof err !== 'undefined' && err.errorMessage) {
              setFieldError('group', err.errorMessage);
            }
          },
        ),
      );
      onClose();
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .trim()
        .required(errorMessages.inputField),
      description: Yup.string().required(errorMessages.inputField),
      test: Yup.string().required(errorMessages.inputField),
      normType: Yup.string().required(),
      group: Yup.string().when('normType', {
        is: 'F',
        then: Yup.string().required(errorMessages.inputField),
      }),
      sampleSize: Yup.number().when('normType', {
        is: 'SD',
        then: Yup.number()
          .positive(I18n.t('Sample size must be greater than or equal to 1'))
          .required(errorMessages.inputField),
      }),
    }),
  });

  useEffect(() => {
    dispatch(getTestsWithSystemNorms());
    dispatch(fetchBriefGroups());
  }, []);

  useEffect(() => {
    if (values.normType === 'F') {
      setFieldValue('sampleSize', '');
    }

    if (values.normType === 'SD') {
      setFieldValue('group', '');
    }
  }, [values.normType]);

  const changeType = (event) => {
    setFieldValue('normType', event.target.value);
  };

  return (
    <Modal title={I18n.t('Add New Norm')} lg isVisible onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <FieldWrapper>
          <CustomInput
            inputName={I18n.t('Norm name')}
            errors={errors}
            touched={touched}
            type="text"
            name="name"
            id="name"
            onChange={handleChange}
            value={values.name}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Select
            name="test"
            error={errors.test}
            placeholder={I18n.t('Assessment on which the norm is based')}
            options={availableTests}
            selectProps={{
              onChange: (value) => {
                setFieldValue('test', value);
              },
              value: values.test,
            }}
            touched={touched.test}
          />
        </FieldWrapper>
        <FieldWrapper style={{ display: 'none' }}>
          <GroupLabel>{I18n.t('Type')}</GroupLabel>
          <RadioButtonGroup>
            <StyledLabel>
              <RadioButton isChecked={values.normType === 'F'} value="F" onClick={changeType} readOnly />
              <RadioLabel>{I18n.t('Frequency')}</RadioLabel>
            </StyledLabel>
          </RadioButtonGroup>
        </FieldWrapper>
        {values.normType === 'F' && (
          <FieldWrapper>
            <Select
              name="group"
              error={errors.group}
              placeholder={I18n.t('Select group')}
              options={groups}
              selectProps={{
                onChange: (value) => {
                  setFieldValue('group', value);
                },
                value: values.group,
              }}
              touched={touched.group}
            />
          </FieldWrapper>
        )}
        <FieldWrapper>
          <TextArea
            inputName={I18n.t('Description')}
            errors={errors}
            type="text"
            name="description"
            id="description"
            onChange={handleChange}
            value={values.description}
            touched={touched}
          />
        </FieldWrapper>
        <ModalFooter>
          <Button type="submit" disabled={isCreateNormInProgress}>
            {I18n.t('Add')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

const FieldWrapper = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

const StyledLabel = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const RadioButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const RadioLabel = styled.span`
  padding: 0 12px;
`;

const CustomInput = styled(FormikInput)`
  width: 100%;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const GroupLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #295368;
  margin-bottom: 16px;
`;

export default AddNormModal;
