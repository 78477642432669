import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { I18n } from 'react-redux-i18n';
import NewModal from '../reusable/NewModal';
import apiInstance2 from '../../services/apiService';
import { errorMessages } from '../../constants/errorMessages';
import createToastNotification from '../../utils/createToastNotification';
import { selectAvailableLanguages } from '../../store/user/selectors';
import AddCompetencyItemForm from './AddCompetencyItemForm';

const AddCompetencyItem = ({ category, competency, onCancel, onClose }) => {
  const languages = useSelector(selectAvailableLanguages);
  const [formData, setFormData] = useState({
    languages: [
      {
        lang: { value: '', code: '', label: '' },
        name: '',
        description: '',
      },
    ],
  });

  const isUpdating = competency && competency.competencyID;

  useEffect(() => {
    if (isUpdating && languages && languages.length) {
      const haveLanguages = competency.languages && competency.languages[0]?.langID;

      let languagesObject;

      if (!haveLanguages) {
        languagesObject = [
          {
            lang: languages.find((language) => language.value === competency.langID || 'EN'),
            name: competency.name,
            description: competency.description,
          },
        ];
      } else {
        languagesObject = competency.languages.map((data) => ({
          lang: languages.find((language) => language.value === data.langID),
          name: data.name,
          description: data.description,
        }));
      }

      setFormData({
        languages: languagesObject,
      });
    }
  }, [isUpdating, competency, languages]);

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      setSubmitting(true);

      const languagesObject = values.languages.map((data) => ({
        langID: data.lang.value,
        name: data.name,
        description: data.description,
      }));

      const data = {
        name: languagesObject[0].name,
        description: languagesObject[0].description,
        customLanguages: languagesObject,
      };

      const afterData = data;

      const method = isUpdating
        ? apiInstance2.patch(`/api/v2/360/competencies`, { ...data, competencyID: competency.competencyID })
        : apiInstance2.post(`/api/v2/360/competency-categories/${category.competencyID}/competencies`, data);

      method
        .then(({ data }) => {
          setSubmitting(false);
          createToastNotification({ message: I18n.t(isUpdating ? 'competencyUpdateToast' : 'newCompetencyToast') });
          onClose({ languages: afterData.customLanguages, ...data }, !isUpdating);
        })
        .catch((e) => {
          console.log(e);
          setSubmitting(false);
        });
    },
    [competency, isUpdating],
  );

  const validationSchema = Yup.object().shape({
    languages: Yup.array().of(
      Yup.object().shape({
        lang: Yup.object()
          .shape({
            value: Yup.string().required(),
            code: Yup.string().required(),
            label: Yup.string().required(),
          })
          .required(errorMessages.selectField),
        name: Yup.string()
          .required(errorMessages.inputField)
          .max(100, errorMessages.tooLong(100)),
        description: Yup.string()
          .required(errorMessages.inputField)
          .max(500, errorMessages.tooLong(500)),
      }),
    ),
  });

  return (
    <NewModal
      isVisible
      onClose={onCancel}
      title={I18n.t(`${competency && competency.competencyID ? 'Edit' : 'Add'} competency`)}
    >
      <Formik
        enableReinitialize
        initialValues={formData}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        component={AddCompetencyItemForm}
      />
    </NewModal>
  );
};

export default AddCompetencyItem;
