import { I18n } from 'react-redux-i18n';

// eslint-disable-next-line import/prefer-default-export
export const errorMessages = {
  inputField: I18n.t('Field is required'),
  selectField: I18n.t('Field is required'),
  emailField: I18n.t('Email address is not valid'),
  tooLong: (length) => `The max length of the field is ${length} characters`,
  tooShort: (length) => `The min length of the field is ${length} characters`,
  equals: (length) => `The field must be ${length} characters`,
};

export default errorMessages;
