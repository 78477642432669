const COLORS = [
  {
    border: '#E1BEE7',
    background: '#F3E5F5',
    color: '#A455B1',
  },
  {
    border: '#D1C4E9',
    background: '#EDE7F6',
    color: '#7D5DB7',
  },
  {
    border: '#BBDEFB',
    background: '#E3F2FD',
    color: '#2196F3',
  },
  {
    border: '#B2EBF2',
    background: '#E0F7FA',
    color: '#02AEC3',
  },
  {
    border: '#DCEDC8',
    background: '#F1F8E9',
    color: '#7CB43B',
  },
  {
    border: '#FFE0B2',
    background: '#FFF3E0',
    color: '#FF9800',
  },
  {
    border: '#FFCCBC',
    background: '#FBE9E7',
    color: '#FF5722',
  },
  {
    border: '#F8BBD0',
    background: '#FCE4EC',
    color: '#E4487D',
  },
];

const getColors = (count) => {
  const colorsLength = COLORS.length;
  const result = [];
  let currentColor = 0;

  Array.from(Array(count)).forEach(() => {
    result.push(COLORS[currentColor]);

    if (colorsLength === currentColor + 1) {
      currentColor = 0;
    } else {
      currentColor += 1;
    }
  });

  return result;
};

export default getColors;
