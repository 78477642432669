import React from 'react';
import { format, isAfter } from 'date-fns';
import { I18n } from 'react-redux-i18n';

// import ActionsDropdown from '../../components/reusable/ActionsDropdown';

import inviteStatuses from '../../constants/inviteStatuses';
import inviteTypes from '../../constants/inviteTypes';

const ExpiryDateCell = ({ row }) => {
  if (!row.original.expiryDate) return '';
  const hasExpired = isAfter(new Date(), new Date(row.original.expiryDate));
  const color = hasExpired ? '#FFA4A4' : '#40C4FF';
  return <span style={{ color }}>{format(new Date(row.original.expiryDate), 'dd/M/y')}</span>;
};

// const columns = (rowActions, setSelectedInvites) => [
const columns = [
  {
    Header: I18n.t('Assessment'),
    accessor: 'assessment',
    name: 'assessment',
  },
  {
    Header: I18n.t('Language'),
    accessor: 'langID',
    name: 'langID',
  },
  {
    Header: I18n.t('Type'),
    name: 'type',
    accessor: (data) => I18n.t(inviteTypes[data.inviteType]),
  },
  {
    Header: I18n.t('Invite status'),
    name: 'status',
    accessor: (data) => I18n.t(inviteStatuses[data.invitationStatus]),
  },
  {
    Header: I18n.t('Invitation Date'),
    accessor: (data) => data.invitationDate && new Date(data.invitationDate),
    Cell: ({ row }) =>
      row.original.invitationDate ? <span>{format(new Date(row.original.invitationDate), 'dd/M/y')}</span> : null,
    name: 'invitationDate',
  },
  {
    Header: I18n.t('Expiry Date'),
    name: 'expiryDate',
    accessor: (data) => new Date(data.expiryDate),
    Cell: (props) => <ExpiryDateCell {...props} />,
    sortType: 'datetime',
    type: 'date',
  },
  // {
  //   Header: I18n.t(''),
  //   name: 'actions',
  //   type: 'actions',
  //   id: 'actions',
  //   disableSortBy: true,
  //   Cell: ({ row }) => (
  //     <ActionsDropdown
  //       actions={rowActions.map((item) => ({
  //         ...item,
  //         handler: () => item.handler && item.handler(row.original),
  //         isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(row.original) : item.isDisabled,
  //       }))}
  //       clickHandler={() => setSelectedInvites(row.original)}
  //     />
  //   ),
  //   width: 50,
  // },
];

export default columns;
