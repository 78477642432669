import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ethnicityOptions, ethnicitiesToHideInIt } from '../constants/respondentSelectOptions';

const useEthnicityOptions = () => {
  const locale = useSelector((state) => state.i18n.locale);

  return useMemo(() => {
    if (locale === 'it') return ethnicityOptions.filter((option) => !ethnicitiesToHideInIt.includes(option.value));
    return ethnicityOptions;
  }, [locale]);
};

export default useEthnicityOptions;
