import React, { useState } from 'react';
import styled from 'styled-components';
import CustomBadge from '../../../reusable/Badges/CustomBadge';
import InbuiltBadge from '../../../reusable/Badges/InbuiltBadge';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/blue-arrow-down-big.svg';
import { ReactComponent as ArrowUp } from '../../../../assets/icons/blue-arrow-up-big.svg';
import { ReactComponent as Remove } from '../../../../assets/icons/close.svg';
import { PanelTitle } from '../../../reusable/Accordion/styles';
import AnimateHeightWrapper from '../../../reusable/AnimateHeight';
import isEqualProps from '../../../../utils/isEqualProps';

export const TitleIcon = ({ opened }) => {
  return <IconButton>{opened ? <ArrowUp /> : <ArrowDown />}</IconButton>;
};

const GroupItem = ({ group, onRemoveCompetencies, isAssessmentActive }) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <Group leftBorderColor={group.colors.color}>
      <GroupTitleWrapper root selectable onClick={() => {}}>
        <TitleIconWrapper onClick={() => setIsOpened(!isOpened)}>
          <TitleIcon opened={isOpened} />
        </TitleIconWrapper>
        <AccordionTitleBody>
          <AccordionTitleWrapper>
            <AccordionTitle>{group.name}</AccordionTitle>
            {group.competencyType ? <CustomBadge /> : <InbuiltBadge />}
            {!isAssessmentActive && (
              <RemoveIcon
                onClick={() =>
                  onRemoveCompetencies(
                    group.categories.map((item) => ({
                      competencyType: item.competencyType,
                      competencyID: item.competencyID,
                    })),
                  )
                }
              >
                <StyledRemoveIcon />
              </RemoveIcon>
            )}
          </AccordionTitleWrapper>
          <GroupDescription>{group.description}</GroupDescription>
        </AccordionTitleBody>
      </GroupTitleWrapper>
      {!!group.categories.length && (
        <GroupBody height={isOpened ? 'auto' : 0}>
          {group.categories.map((item) => {
            return (
              <CategoryWrapper key={item.competencyID}>
                <span>{item.name}</span>
                <RemoveIcon
                  onClick={() =>
                    onRemoveCompetencies([
                      {
                        competencyType: item.competencyType,
                        competencyID: item.competencyID,
                      },
                    ])
                  }
                >
                  <StyledRemoveIcon />
                </RemoveIcon>
              </CategoryWrapper>
            );
          })}
        </GroupBody>
      )}
    </Group>
  );
};

const IconButton = styled.span`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.lightBlue1};
  }
`;

const RemoveIcon = styled(IconButton)`
  position: absolute;
`;

const StyledRemoveIcon = styled(Remove)`
  path {
    fill: ${(props) => props.theme.colors.primary};
  }
  width: 1rem;
  height: 1rem;
`;

const CategoryWrapper = styled.div`
  padding: 12px 45px 12px 16px;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  position: relative;

  ${RemoveIcon} {
    top: 5px;
    right: 14px;
  }
`;

const GroupBody = styled(AnimateHeightWrapper)`
  height: 0;

  ${(props) => props.opened && `display: block;`}
  ${(props) => props.opened && `border-top: 1px solid ${props.theme.colors.grey6};`}
  
  ${CategoryWrapper}:last-child {
    padding-bottom: 30px;
  }
`;

const Group = styled.div`
  border: 1px solid #e0e0e0;
  border-left: 6px solid ${(props) => props.leftBorderColor};
  background: ${(props) => props.theme.colors.grey1};
  border-radius: 6px;
`;

const TitleIconWrapper = styled.span`
  position: absolute;
  top: 10px;
  left: 10px;
`;

const GroupDescription = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => props.theme.colors.dark};
  margin-top: 8px;
`;

const GroupTitleWrapper = styled(PanelTitle)`
  ${RemoveIcon} {
    top: 10px;
    right: 10px;
  }
`;

const AccordionTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AccordionTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
`;

const AccordionTitleBody = styled.div`
  flex-grow: 1;
`;

export default React.memo(GroupItem, (prevProps, nextProps) => {
  return isEqualProps(prevProps, nextProps);
});
