import React, { useState, useEffect } from 'react';
import { func, array } from 'prop-types';
import styled from 'styled-components';
import { sortBy, uniqBy, intersectionBy } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { I18n } from 'react-redux-i18n';
import NewModal from '../reusable/NewModal';
import Tag from '../reusable/Label';
import CustomButton from '../reusable/Buttons/Button';
import RadioButton from '../reusable/FormComponents/RadioButton';

import MultiSelect from '../reusable/Selects/MultiSelect';

import { addTagsToRespondents, removeTagsFromRespondents } from '../../store/respondents/actions';

const actionTypes = [
  {
    value: 'add',
    label: 'Add tag to selected respondent(s)',
  },
  {
    value: 'remove',
    label: 'Remove tag from selected respondent(s)',
  },
];

const extractUniqueTagsFromRespondents = (respondents) =>
  respondents
    ? uniqBy(
        respondents.reduce((acc, item) => [...acc, ...item.tags], []),
        'tagID',
      )
    : [];

const resolveTags = (actionType, allTags, respondents) => {
  if (actionType.value === 'add') return allTags;
  if (actionType.value === 'remove') {
    const uniqueRespondentsTags = extractUniqueTagsFromRespondents(respondents);
    // only select those tags that are already assigned to respondents in the list
    return intersectionBy(allTags, uniqueRespondentsTags, 'tagID');
  }
};

const ManageTagsModal = ({ onClose, respondents, onSuccess }) => {
  const allTags = useSelector((state) => state.user.tags);

  const dispatch = useDispatch();

  const [actionType, setActionType] = useState(actionTypes[0]);
  const [tags, setTags] = useState(resolveTags(actionType, allTags, respondents));
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    setTags(resolveTags(actionType, allTags, respondents));
  }, [respondents.length, actionType, allTags.length]);

  const addTags = (newTags) => {
    // give tag object a name if it doesn't have one (manual input case)
    const newTagsWithName = newTags.map((item) => ({ ...item, name: item.name || item.value }));
    // remove potential duplicates
    const withoutDuplicates = newTagsWithName.filter((item) => !selectedTags.find((tag) => tag.name === item.name));
    setSelectedTags((prev) => [...prev, ...withoutDuplicates]);
    // remove tag from potential select options after selection
    if (withoutDuplicates.length)
      setTags((prev) => prev.filter((item) => !newTags.find((tag) => tag.tagID === item.tagID)));
  };

  const removeTag = (tag) => {
    setSelectedTags((prev) => prev.filter((item) => item.name !== tag.name));
    if (tag.tagID) setTags((prev) => [...prev, tag]);
  };

  const onActionTypeClick = (value) => {
    setActionType(value);
    setSelectedTags([]);
  };

  const onSave = () => {
    const data = {
      respondentIDs: respondents.map((item) => item.respondentID),
      tags: selectedTags.map((item) => item.name),
    };
    const handler = actionType.value === 'add' ? addTagsToRespondents : removeTagsFromRespondents;
    dispatch(
      handler(data, (err) => {
        if (!err && onSuccess) onSuccess();
        onClose();
      }),
    );
  };

  const options = sortBy(
    tags.map((item) => ({ ...item, value: item.tagID, label: item.name })),
    'name',
  );

  return (
    <Modal isVisible onClose={onClose} title={I18n.t('Manage respondents tags')}>
      <Content>
        <MultiSelect
          options={options}
          creatable={actionType.value === 'add'}
          onChange={addTags}
          placeholder={I18n.t('Select from existing tags or add new')}
        />
        {Boolean(selectedTags.length) && (
          <TagsWrapper>
            {selectedTags.map((tag) => (
              <Tag value={tag.name} key={tag.tagID || tag.name} onDelete={() => removeTag(tag)} />
            ))}
          </TagsWrapper>
        )}
        <ActionTypesWrapper>
          {actionTypes.map((item) => (
            <ActionType
              key={item.value}
              onClick={() => onActionTypeClick(item)}
              isActive={item.value === actionType.value}
            >
              <StyledRadioButton isChecked={item.value === actionType.value} readOnly />
              <span>{I18n.t(item.label)}</span>
            </ActionType>
          ))}
        </ActionTypesWrapper>
      </Content>
      <Footer>
        <StyledButton variant="secondary" handler={onClose}>
          {I18n.t('Cancel')}
        </StyledButton>
        <StyledButton variant="primary" handler={onSave} disabled={!selectedTags.length}>
          {I18n.t('Save')}
        </StyledButton>
      </Footer>
    </Modal>
  );
};

ManageTagsModal.propTypes = {
  onClose: func.isRequired,
  onSuccess: func,
  respondents: array.isRequired,
};

ManageTagsModal.defaultProps = {
  onSuccess: undefined,
};

const Modal = styled(NewModal)`
  width: 57rem;
  min-height: 31rem;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const TagsWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

const ActionTypesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  margin-top: 2rem;
`;

const ActionType = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  span {
    font-weight: bold;
    color: ${(props) => (props.isActive ? props.theme.colors.lightBlue : props.theme.colors.darkBlue2)};
    font-size: 1.4rem;
  }

  :hover {
    cursor: pointer;
  }
`;

const StyledRadioButton = styled(RadioButton)`
  margin: 0 1rem !important;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledButton = styled(CustomButton)`
  margin-left: 1rem;
  width: 12.5rem;
`;

export default ManageTagsModal;
