import { createAction } from 'redux-actions';
import { subMinutes } from 'date-fns';
import { createReportNotification } from '../reports/actions';
import apiInstance2 from '../../services/apiService';
import { updateRespondentRow, getRespondentById } from '../respondents/actions';

export const fetchAssessmentsChangesStart = createAction('FETCH_ASSESSMENTS_ACTIONS_START');
export const fetchAssessmentsChangesSuccess = createAction('FETCH_ASSESSMENTS_ACTIONS_SUCCESS');
export const fetchAssessmentsChangesFailure = createAction('FETCH_ASSESSMENTS_ACTIONS_FAILURE');

export const fetchAssessmentsChanges = () => async (dispatch) => {
  try {
    dispatch(fetchAssessmentsChangesStart());
    const currentDate = new Date();
    const from = subMinutes(currentDate, 1).toISOString();
    const to = currentDate.toISOString();
    const response = await apiInstance2.get(`/api/v2/Notifications/assessments?fromDateTime=${from}&toDateTime=${to}`);
    if (response.status === 200) {
      dispatch(fetchAssessmentsChangesSuccess());
      response.data.notifications.forEach(async (notification) => {
        const title = 'Assessment has been completed!';
        createReportNotification(notification, { type: 'info', title }, dispatch);
        const respondent = await dispatch(getRespondentById(notification?.respondentID));
        const assessmentDone = notification?.questionnaire;
        if (!assessmentDone || !respondent) return;
        const currentAssessmentsDone = respondent.assessmentsDone;
        if (currentAssessmentsDone.includes(assessmentDone)) return;
        const newState = {
          respondentID: notification.respondentID,
          assessmentsDone: [...currentAssessmentsDone, assessmentDone],
        };
        await dispatch(updateRespondentRow(newState));
      });
    }
  } catch (e) {
    dispatch(fetchAssessmentsChangesFailure(e));
  }
};
