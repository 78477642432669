import handleActions from '../../immerHandleActions';
import * as actions from './actions';

const initialState = {
  categories: [],
  competencies: [],
  isLoading: true,
  list: [],
};

const competenciesReducer = handleActions(
  {
    [actions.fetchCompetencies]: (draft) => {
      return {
        ...draft,
        isLoading: true,
      };
    },
    [actions.fetchCompetenciesSuccess]: (draft, { payload: { categories, data } }) => {
      return {
        ...draft,
        categories,
        competencies: data,
        list: categories.map((category) => ({
          ...category,
          categories: data.filter((item) => item.categoryId === category.competencyID),
        })),
        isLoading: false,
      };
    },
    [actions.addCompetencyCategory]: (draft, { payload: { data } }) => {
      const newCategories = [...draft.categories, data];

      return {
        ...draft,
        categories: newCategories,
        list: newCategories.map((category) => ({
          ...category,
          categories: draft.competencies.filter((item) => item.categoryId === category.competencyID),
        })),
        isLoading: false,
      };
    },
    [actions.updateCompetencyCategory]: (draft, { payload: { data } }) => {
      const newCategories = draft.categories.map((category) => {
        if (category.competencyID !== data.competencyID) return category;

        return {
          ...category,
          ...data,
        };
      });

      return {
        ...draft,
        categories: newCategories,
        list: newCategories.map((category) => ({
          ...category,
          categories: draft.competencies.filter((item) => item.categoryId === category.competencyID),
        })),
      };
    },
    [actions.deleteCompetenciesCategorySuccess]: (draft, { payload: { data } }) => {
      const newCategories = [...draft.categories].filter((category) => !data.includes(category.competencyID));

      return {
        ...draft,
        categories: newCategories,
        list: newCategories.map((category) => ({
          ...category,
          categories: draft.competencies.filter((item) => item.categoryId === category.competencyID),
        })),
      };
    },
    [actions.addCompetencyToCategory]: (draft, { payload: { data } }) => {
      const newCompetencies = [...draft.competencies, data];

      return {
        ...draft,
        competencies: newCompetencies,
        list: draft.categories.map((category) => ({
          ...category,
          categories: newCompetencies.filter((item) => item.categoryId === category.competencyID),
        })),
      };
    },
    [actions.updateCompetencyToCategory]: (draft, { payload: { data } }) => {
      const newCompetencies = draft.competencies.map((competency) => {
        if (competency.competencyID !== data.competencyID) return competency;

        return {
          ...competency,
          name: data.name,
          description: data.description,
        };
      });

      return {
        ...draft,
        competencies: newCompetencies,
        list: draft.categories.map((category) => ({
          ...category,
          categories: newCompetencies.filter((item) => item.categoryId === category.competencyID),
        })),
      };
    },
    [actions.addCompetencyQuestion]: (draft, { payload: { data } }) => {
      const newCompetencies = draft.competencies.map((competency) => {
        if (competency.competencyID !== data.competencyID) return competency;

        return {
          ...competency,
          competencyItems: [...(competency.competencyItems || []), data],
        };
      });

      return {
        ...draft,
        competencies: newCompetencies,
        list: draft.categories.map((category) => ({
          ...category,
          categories: newCompetencies.filter((item) => item.categoryId === category.competencyID),
        })),
      };
    },
    [actions.updateCompetencyQuestion]: (draft, { payload: { data } }) => {
      const newCompetencies = draft.competencies.map((competency) => {
        if (competency.competencyID !== data.competencyID) return competency;

        return {
          ...competency,
          competencyItems: competency.competencyItems.map((question) => {
            if (question.itemID !== data.itemID) return question;

            return {
              ...question,
              ...data,
            };
          }),
        };
      });

      return {
        ...draft,
        competencies: newCompetencies,
        list: draft.categories.map((category) => ({
          ...category,
          categories: newCompetencies.filter((item) => item.categoryId === category.competencyID),
        })),
      };
    },
    [actions.deleteCompetencyQuestionSuccess]: (draft, { payload: { data } }) => {
      const newCompetencies = draft.competencies.map((competency) => {
        if (competency.competencyID !== data.competencyID) return competency;

        return {
          ...competency,
          competencyItems: competency.competencyItems.filter((item) => item.itemID !== data.itemID),
        };
      });

      return {
        ...draft,
        competencies: newCompetencies,
        list: draft.categories.map((category) => ({
          ...category,
          categories: newCompetencies.filter((item) => item.categoryId === category.competencyID),
        })),
      };
    },
    [actions.deleteCompetencySuccess]: (draft, { payload: { data } }) => {
      const newCompetencies = draft.competencies.filter((competency) => !data.includes(competency.competencyID));

      return {
        ...draft,
        competencies: newCompetencies,
        list: draft.categories.map((category) => ({
          ...category,
          categories: newCompetencies.filter((item) => item.categoryId === category.competencyID),
        })),
        isLoading: false,
      };
    },
    [actions.fetchCompetenciesFailure]: (draft) => {
      return {
        ...draft,
        isLoading: false,
      };
    },
    [actions.resetCompetencies]: () => {
      return {
        ...initialState,
      };
    },
  },
  initialState,
);

export default competenciesReducer;
