import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { number, string, oneOfType } from 'prop-types';
import { sortBy } from 'lodash';
import { I18n } from 'react-redux-i18n';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { addYears, startOfTomorrow } from 'date-fns';
import PageWrapper from '../../PageWrapper';
import CustomSelect from '../../reusable/Selects/Select';
import CustomInput from '../../reusable/FormComponents/Input';
import DateTimeSelect from '../../reusable/Selects/DateTimeSelect';
import CustomTextArea from '../../reusable/FormComponents/TextArea';
import DirectFeedbackReport from '../../reusable/DirectFeedbackReport';
import Solution from '../../Respondents/RequestReportModal/Solution';
import ReportsSet, { findIfReportIsDisabled } from '../../Respondents/RequestReportModal/Set';
import breadcrumbs from '../edit-assessment.breadcrubms';

import { updateAssessmentProject, fetchAssessmentProjectSettings } from '../../../store/projects/actions';
import { selectAvailableLanguages } from '../../../store/user/selectors';
import { selectCategoriesOptions, selectUnsupervisedAssessments } from '../../../store/tests/selectors';
import {
  selectAvailableIdealProfiles,
  selectAvailableNorms,
  selectAvailableReports,
} from '../../../store/respondents/selectors';
import { reminderOptions, sendingOptions } from '../AssessmentProject/components/options';
import { selectWelcomeTextsOptions } from '../../../store/settings/welcome-texts/selectors';
import { selectTemplatesAsOptions } from '../../../store/settings/templates/selectors';
import { errorMessages } from '../../../constants/errorMessages';
import createTestsOptions from '../../Respondents/CreateInvite/createInviteUtils';
import createToastNotification from '../../../utils/createToastNotification';

import useAvailableTests from '../../../hooks/useAvailableTests';
import useEditAssessmentForm, { setCheckedStatesForReports } from './useEditAssessmentForm';

import { InfoContainer, DaysLabel, StyledWarningIcon } from '../AddProjectsModal/AssessmentProject/InvitesStep';
import {
  generateReportIdealProfile,
  generateReportNorms,
  modifyNorms,
  parseSolutionNorms,
} from '../../Respondents/RequestReportModal';
import { defineDependencies } from '../../Respondents/RequestReportModal/ReportsList';
import { findLanguage } from '../../Respondents/CreateInvite';
import { fetchAssessmentsNorms, fetchAvailableReports } from '../../../store/respondents/actions';
import { resolveAssessmentId } from '../../../utils/assessmentsNames';
import { parseProjectReports } from './edit-assessment-project.utils';

const EditAssessmentProject = ({ projectId }) => {
  const dispatch = useDispatch();
  const [project, setProject] = useState(null);
  const isEditingDisabled = project?.hasRespondents;
  // assessment
  const groupedAssessments = useSelector(selectUnsupervisedAssessments);
  const languages = useSelector(selectAvailableLanguages);
  const categories = useSelector(selectCategoriesOptions);
  // invites
  const welcomeTexts = useSelector(selectWelcomeTextsOptions);
  const templates = useSelector(selectTemplatesAsOptions);
  // reports
  const reportsLanguages = useSelector((state) => state.reports.availableLanguages);
  const user = useSelector((state) => state.user.user);
  const unmodifiedNormsList = useSelector((state) => state.respondents.availableNorms);
  const unmodifiedIdealProfilesList = useSelector((state) => state.idealProfiles.idealProfiles);
  const norms = modifyNorms(useSelector(selectAvailableNorms));
  const idealProfiles = modifyNorms(useSelector(selectAvailableIdealProfiles));
  const fetchedReports = useSelector(selectAvailableReports);
  // intentionally moved it outside of formik so that we could keep enableReinitialize: true;
  // otherwise (with enableReinitialize: false) we would have to always set our formik state manually
  const [reportsData, setReportsData] = useState([]);

  useEffect(() => {
    if (fetchedReports && project) setReportsData(setCheckedStatesForReports(fetchedReports, project));
  }, [fetchedReports, project]);

  const reportsListRef = useRef(null);

  const fetchProject = useCallback(() => {
    if (projectId)
      dispatch(
        fetchAssessmentProjectSettings(projectId, (err, data) => {
          if (err)
            return createToastNotification({
              title: 'Error',
              message: 'Error occurred during project fetching attempt',
              type: 'error',
            });
          setProject(data);
        }),
      );
  }, [projectId]);

  const goToProject = () => dispatch(push(`/projects/assessment/${projectId}`));

  const { norms: selectedNorms, idealProfiles: selectedIdealProfiles } = parseProjectReports({
    reports: project?.reports,
    normsList: unmodifiedNormsList,
    idealProfilesList: unmodifiedIdealProfilesList,
  });

  const [normsState, setNormsState] = useState(selectedNorms);
  const [idealProfilesState, setIdealProfilesState] = useState(selectedIdealProfiles);
  useEffect(() => {
    setNormsState(selectedNorms);
  }, [selectedNorms && Object.keys(selectedNorms).length]);

  useEffect(() => {
    setIdealProfilesState(selectedIdealProfiles);
  }, [selectedIdealProfiles && Object.keys(selectedIdealProfiles).length]);

  const deps = defineDependencies(reportsData.filter((item) => item.isChecked));
  const normsToShow = deps.normsDependencies.map((key) => (norms[key] ? norms[key] : undefined)).filter((item) => item);
  const profilesToShow = deps.profilesDependencies
    .map((key) => (idealProfiles[key] ? idealProfiles[key] : undefined))
    .filter((item) => item);

  const onSubmit = (submittedValues, { setFieldError, setSubmitting, setValues }) => {
    const areNormsFilled =
      normsToShow.length === 0 ||
      (normsToShow.length > 0 && normsToShow.length === (normsState && Object.keys(normsState))?.length);
    const areIdealProfilesFilled =
      profilesToShow.length === 0 ||
      (profilesToShow.length > 0 &&
        profilesToShow.length === (idealProfilesState && Object.keys(idealProfilesState))?.length);

    if (!areNormsFilled) setFieldError('norms', errorMessages.inputField);
    if (!areIdealProfilesFilled) setFieldError('idealProfiles', errorMessages.inputField);
    if (!areNormsFilled || !areIdealProfilesFilled) return setSubmitting(false);

    const readyToBeSentReports = reportsData.reduce((acc, item) => {
      const items = item.reports
        .filter((item) => item.isChecked)
        .map((reportItem) => ({
          reportID: reportItem.reportID,
          reportSetID: item.reportSetID,
          normIDs: reportItem.isSolutionReport
            ? parseSolutionNorms(reportItem.solutionNorms)
            : generateReportNorms(reportItem, normsState),
          idealProfileID:
            idealProfilesState && Boolean(Object.keys(idealProfilesState).length)
              ? generateReportIdealProfile(reportItem, idealProfilesState)
              : null,
          reportType: 0,
        }));
      return [...acc, ...items];
    }, []);

    const data = {
      projectID: project.projectID,
      name: submittedValues.projectName,
      description: submittedValues.projectDescription,
      langID: submittedValues.language.code,
      ...(submittedValues.assessment.batteryID
        ? { batteryID: submittedValues.assessment.batteryID }
        : { testID: submittedValues.assessment.testID }),
      emailTID: submittedValues.template.value,
      welcomeTextTID: submittedValues.welcomeText.value,
      inviteExpiredAfter: submittedValues.expireAfter,
      inviteExpiredAfterType: 2,
      sendInviteType: submittedValues.sendingType.backendReference,
      ...(submittedValues.sendingType.value === 'schedule' && { sendInviteDate: submittedValues.invitationDate }),
      remindInviteType: submittedValues.reminderType.backendReference,
      sendDirectFeedbackReport: submittedValues.isDirectReport,
      reportLangID: submittedValues.reportsLanguage.code,
      reports: readyToBeSentReports,
    };
    dispatch(
      updateAssessmentProject(data, (err) => {
        const excludeValues = ['mock', 'profilemock', 'normmock'];
        const newValues = Object.fromEntries(
          Object.entries(submittedValues).filter(([key]) => !excludeValues.includes(key)),
        );
        setValues(newValues);
        setSubmitting(false);
        if (!err) {
          dispatch(push(`/projects/assessment/${projectId}`));
        }
      }),
    );
  };

  const {
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    setFieldError,
    errors,
    touched,
    dirty,
    isSubmitting,
  } = useEditAssessmentForm({
    project,
    languages,
    groupedAssessments,
    categories,
    welcomeTexts,
    templates,
    reportsLanguages,
    onSubmit,
  });
  const commonTests = values.assessment?.batteryID ? values.assessment.uses.split(';') : [values.assessment?.testID];

  useEffect(() => {
    fetchProject();
    return () => setProject(null);
  }, []);
  useEffect(() => {
    if (!reportsLanguages.length) return;
    const language = findLanguage(reportsLanguages, user && user.distributorDefaultLanguageID);
    setFieldValue('reportsLanguage', language);
  }, [user?.distributorDefaultLanguageID, reportsLanguages.length]);

  // fetch available reports
  useEffect(() => {
    if (!values.reportsLanguage || !values.assessment || !commonTests.length) return;
    dispatch(fetchAvailableReports(commonTests, values.reportsLanguage, []));
  }, [values.reportsLanguage?.code, resolveAssessmentId(values.assessment), commonTests.length]);

  useEffect(() => {
    if (!commonTests.length || !values.assessment) return;
    dispatch(fetchAssessmentsNorms(commonTests));
  }, [commonTests.length, resolveAssessmentId(values.assessment)]);

  // ASSESSMENT PART
  const onCategoryChange = (category) => {
    if (category.value !== values.category?.value) {
      setFieldValue('assessment', null);
      setReportsData([]);
    }
    setFieldValue('category', category);
    setFieldValue('assessments', groupedAssessments[category.value]);
    setFieldError('category', '');
  };

  const onLanguageChange = (language) => {
    if (language.value !== values.language?.value) {
      setFieldValue('assessment', null);
      setFieldValue('category', null);
      setFieldValue('assessments', []);
    }
    setFieldValue('language', language);
    setFieldError('language', '');
  };

  const onAssessmentChange = (assessment) => {
    if (resolveAssessmentId(assessment) !== resolveAssessmentId(project))
      createToastNotification({
        message: `${I18n.t('There are no reports available for selected test and language')}. ${I18n.t(
          'Please make sure you selected correct language or contact your administrator for help',
        )}`,
        type: 'error',
      });
    setFieldValue('assessment', assessment);
    setNormsState(null);
    setIdealProfilesState(null);
    setFieldError('assessment', '');
  };

  const availableAssessments = useAvailableTests({
    assessments: values.assessments,
    language: values.language,
  });
  const assessmentsOptions = useMemo(() => {
    return createTestsOptions(availableAssessments);
  }, [availableAssessments]);

  // INVITES PART
  const onSelectChange = (name) => (value) => {
    setFieldValue(name, value);
    setFieldError(name, '');
  };

  const onInputChange = (name) => (e) => {
    handleChange(e);
    setFieldError(name, '');
  };

  const isDirectReportAvailable = Boolean(
    values.assessment?.directFeedbackReportLanguagesAvailable?.includes(values.language?.code),
  );

  const toggleDirectReportStatus = () => setFieldValue('isDirectReport', !values.isDirectReport);
  // REPORTS PART

  const onReportsLanguageChange = (lang) => {
    setFieldValue('reportsLanguage', lang);
  };

  const onSetClick = (id) => {
    setReportsData((prevData) =>
      prevData.map((item) => ({ ...item, isOpen: id === item.reportSetID ? !item.isOpen : item.isOpen })),
    );
    const isLast = reportsData[reportsData.length - 1].reportSetID === id;
    if (isLast && !reportsData[reportsData.length - 1].isOpen)
      setTimeout(() => reportsListRef.current.scrollBy({ top: 200, behavior: 'smooth' }), 350);
  };

  const onSetCheckboxClick = (setId) => {
    if (isEditingDisabled) return;
    // to imperatively set "dirty" flag to true; needed because we do not keep reports data in the formik state
    setFieldValue('mock', 'mock');
    setReportsData((prevData) =>
      prevData.map((set) => {
        return set.reportSetID === setId
          ? {
              ...set,
              isChecked: !set.isChecked,
              reports: set.reports.map((item) => ({
                ...item,
                isChecked: findIfReportIsDisabled(item, commonTests, idealProfiles) ? false : !set.isChecked,
              })),
            }
          : set;
      }),
    );
  };
  const onSetReportClick = (setId, reportId) => {
    if (isEditingDisabled) return;
    // to imperatively set "dirty" flag to true; needed because we do not keep reports data in the formik state
    setFieldValue('mock', 'mock');
    setReportsData((prevData) =>
      prevData.map((report) => {
        if (report.reportSetID !== setId) return report;
        const wasChildChecked = report.reports.find((report) => report.reportID === reportId).isChecked;
        const checkedChildren = report.reports.filter((report) => report.isChecked);
        return {
          ...report,
          isChecked: !(report.isChecked && wasChildChecked && checkedChildren.length <= 1),
          reports: report.reports.map((report) =>
            report.reportID === reportId ? { ...report, isChecked: !report.isChecked } : report,
          ),
        };
      }),
    );
  };

  const onNormChange = (norm) => {
    // to imperatively set "dirty" flag to true; needed because we do not keep reports data in the formik state
    setFieldValue('normmock', 'mock');
    setFieldError('norms', '');
    setNormsState((prev) => ({ ...prev, [norm.testID]: norm }));
  };

  const onIdealProfileChange = (idealProfile) => {
    // to imperatively set "dirty" flag to true; needed because we do not keep reports data in the formik state
    setFieldValue('profilemock', 'mock');
    setFieldError('idealProfiles', '');
    setIdealProfilesState((prev) => ({ ...prev, [idealProfile.testID]: idealProfile }));
  };

  const checkedReportSets = reportsData.filter((set) => set.isChecked);
  const reportsToShow = isEditingDisabled ? checkedReportSets : reportsData;

  const costPerRespondent = checkedReportSets.reduce((acc, set) => acc + set.creditsCost, 0);

  const checkedReports = checkedReportSets.reduce(
    (acc, set) => [...acc, ...set.reports.filter((item) => item.isChecked)],
    [],
  );

  const uniqueCheckedReports = checkedReports.filter(
    (item, index, arr) => arr.findIndex((obj) => obj.reportID === item.reportID) === index,
  );
  return (
    <StyledPageWrapper
      breadcrumbs={breadcrumbs(project)}
      title={project?.name || ''}
      backButtonHandler={goToProject}
      buttons={[
        {
          name: 'saveChanges',
          label: I18n.t('Save changes'),
          handler: handleSubmit,
          isDisabled: !dirty || isSubmitting || !uniqueCheckedReports.length,
        },
      ]}
    >
      <Divider />
      <ScrollWrapper>
        <Form>
          <Block>
            <SubTitle>{I18n.t('General information')}</SubTitle>
            <Row>
              <StyledInput
                id="projectName"
                inputName={I18n.t('Project name')}
                value={values.projectName}
                onChange={handleChange}
                error={errors.projectName}
              />
            </Row>
            <StyledTextArea
              inputName={I18n.t('Description')}
              name="projectDescription"
              id="projectDescription"
              value={values.projectDescription}
              onChange={handleChange}
              errors={errors}
              touched={touched}
            />
          </Block>
          <Block>
            <SubTitle>{I18n.t('Assessment')}</SubTitle>
            <Row>
              <StyledSelect
                options={languages}
                placeholder={I18n.t('Language')}
                selectProps={{ value: values.language, onChange: onLanguageChange }}
                error={errors.language}
                isDisabled={isEditingDisabled}
              />
              <StyledSelect
                options={categories}
                placeholder={I18n.t('Category')}
                selectProps={{ value: values.category, onChange: onCategoryChange }}
                error={errors.category}
                isDisabled={!values.language || isEditingDisabled}
              />
            </Row>
            <FullRowSelect
              options={assessmentsOptions}
              placeholder={I18n.t('Assessment')}
              selectProps={{ value: values.assessment, onChange: onAssessmentChange }}
              isDisabled={!values.assessments.length || isEditingDisabled}
              error={errors.assessment}
            />
            <Label>{I18n.t('createAssessmentBattery')}</Label>
          </Block>
          <Block>
            <SubTitle>{I18n.t('Invites')}</SubTitle>
            <Row>
              <StyledSelect
                options={templates}
                placeholder={I18n.t('Email template')}
                selectProps={{ value: values.template, onChange: onSelectChange('template') }}
                error={errors.template}
                isDisabled={isEditingDisabled}
              />
              <StyledSelect
                options={welcomeTexts}
                placeholder={I18n.t('Welcome text')}
                selectProps={{ value: values.welcomeText, onChange: onSelectChange('welcomeText') }}
                error={errors.welcomeText}
                isDisabled={isEditingDisabled}
              />
            </Row>
            <Row>
              <ExpireContainer>
                <ExpireInput
                  id="expireAfter"
                  inputName={I18n.t('Expire after')}
                  value={values.expireAfter}
                  onChange={onInputChange('expireAfter')}
                  error={errors.expireAfter}
                  type="number"
                  disabled={isEditingDisabled}
                />
                <DaysLabel>{I18n.t('days')}</DaysLabel>
              </ExpireContainer>
              <StyledSelect
                options={reminderOptions}
                placeholder={I18n.t('Remind')}
                selectProps={{ value: values.reminderType, onChange: onSelectChange('reminderType') }}
                error={errors.reminderType}
              />
            </Row>
            <Row>
              <StyledSelect
                options={sendingOptions}
                placeholder={I18n.t('How to send invites')}
                selectProps={{ value: values.sendingType, onChange: onSelectChange('sendingType') }}
                error={errors.sendingType}
                isDisabled={isEditingDisabled}
              />
              {values.sendingType?.value === 'schedule' && (
                <DateTimeSelectWrapper>
                  <DateTimeSelect
                    inputName={I18n.t('Send invites on')}
                    date={values.invitationDate}
                    onDateChange={(date) => setFieldValue('invitationDate', date)}
                    minDate={startOfTomorrow()}
                    maxDate={addYears(new Date(), 1)}
                    inputWidth="100%"
                    isDisabled={isEditingDisabled}
                  />
                </DateTimeSelectWrapper>
              )}
            </Row>
            {values.sendingType && (
              <StyledInfoContainer>
                <StyledWarningIcon />
                <span>{values.sendingType.description}</span>
              </StyledInfoContainer>
            )}
            {isDirectReportAvailable && (
              <>
                <SubTitle>{I18n.t('Direct feedback report')}</SubTitle>
                <DirectFeedbackReport
                  isChecked={values.isDirectReport}
                  onChange={toggleDirectReportStatus}
                  isDisabled={isEditingDisabled}
                />
              </>
            )}
          </Block>
          <Block>
            <SubTitle>{I18n.t('Reports')}</SubTitle>
            <Row>
              <StyledSelect
                options={reportsLanguages}
                placeholder={I18n.t('Language')}
                selectProps={{ value: values.reportsLanguage, onChange: onReportsLanguageChange }}
                error={errors.reportsLanguage}
                isDisabled={isEditingDisabled}
              />
              <CostWrapper>
                <span>{I18n.t('Cost per respondent')}</span>
                <span>{costPerRespondent}</span>
              </CostWrapper>
            </Row>
            {(Boolean(normsToShow.length) || Boolean(profilesToShow.length)) && (
              <>
                <OptionsSubTitle>{I18n.t('Options')}</OptionsSubTitle>
                <Row marginBottom="0">
                  {normsToShow.map((norm) => {
                    return (
                      <StyledSelect
                        key={norm.name}
                        options={norm.options}
                        placeholder={`${norm.name} ${I18n.t('Norm')}`}
                        selectProps={{
                          value: normsState && normsState[norm.name],
                          onChange: (value) => onNormChange(value),
                        }}
                        error={errors.norms}
                        isDisabled={isEditingDisabled}
                        marginBottom="2.4rem"
                      />
                    );
                  })}
                </Row>
                <Row marginBottom="0">
                  {profilesToShow.map((idealProfile) => {
                    return (
                      <StyledSelect
                        key={idealProfile.name}
                        options={idealProfile.options}
                        placeholder={`${idealProfile.name} ${I18n.t('Ideal Profile')}`}
                        selectProps={{
                          value: idealProfilesState && idealProfilesState[idealProfile.name],
                          onChange: (idealProfile) => onIdealProfileChange(idealProfile),
                        }}
                        error={errors.idealProfiles}
                        isDisabled={isEditingDisabled}
                        marginBottom="2.4rem"
                      />
                    );
                  })}
                </Row>
              </>
            )}

            <ReportsWrapper>
              <ReportsItemsList ref={reportsListRef} disabled={isEditingDisabled}>
                {sortBy(reportsToShow, (o) => !o.isChecked).map((item) => {
                  return item.isSolutionSet ? (
                    <Solution
                      key={item.reportSetID}
                      solution={item}
                      onSolutionClick={onSetClick}
                      onReportClick={onSetReportClick}
                      onSolutionCheckboxClick={onSetCheckboxClick}
                      compact
                      alwaysUpdate
                      idealProfiles={idealProfiles}
                    />
                  ) : (
                    <ReportsSet
                      key={item.reportSetID}
                      set={item}
                      onReportClick={onSetReportClick}
                      onSetCheckboxClick={onSetCheckboxClick}
                      onSetClick={onSetClick}
                      commonTests={commonTests}
                      compact
                      alwaysUpdate
                      idealProfiles={idealProfiles}
                    />
                  );
                })}
              </ReportsItemsList>
            </ReportsWrapper>
          </Block>
        </Form>
      </ScrollWrapper>
    </StyledPageWrapper>
  );
};

EditAssessmentProject.propTypes = {
  projectId: oneOfType([string, number]).isRequired,
};

const StyledPageWrapper = styled(PageWrapper)`
  padding: 2.6rem 4.8rem 0 4.8rem;
  margin-bottom: 0;
`;

const Divider = styled.div`
  width: 100%;
  margin-top: 1.4rem;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.12);
`;

const SubTitle = styled.h5`
  font-size: ${(props) => props.theme.fontSizes.normal};
  font-weight: 600;
  line-height: 2rem;
  color: ${(props) => props.theme.colors.mediumBlue};
  margin-bottom: 1.6rem;
`;

const ScrollWrapper = styled.section`
  overflow: auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 60%;
  padding-top: 2.4rem;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(props) => props.marginBottom || '2.4rem'};

  flex-wrap: wrap;
`;

const DateTimeSelectWrapper = styled.div`
  width: 45%;
`;

const StyledSelect = styled(CustomSelect)`
  width: 45%;
  margin-bottom: ${(props) => props.marginBottom || 0};
`;

const StyledInput = styled(CustomInput)`
  width: 45%;
`;
const StyledTextArea = styled(CustomTextArea)`
  height: 18rem;
`;

const FullRowSelect = styled(CustomSelect)`
  margin-bottom: 0.5rem;
`;

const Label = styled.span`
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 1rem;
`;

const ExpireContainer = styled.div`
  position: relative;
  width: 45%;
`;

const ExpireInput = styled(CustomInput)`
  width: 100%;
`;

const StyledInfoContainer = styled(InfoContainer)`
  margin-top: 0;
`;

const CostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;

  span:first-of-type {
    color: ${(props) => props.theme.colors.mediumBlue};
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: 0.8rem;
  }

  span:last-of-type {
    color: ${(props) => props.theme.colors.almostBlack};
    font-size: ${(props) => props.theme.fontSizes.small};
    font-weight: 500;
    letter-spacing: 0.4px;
  }
`;

const OptionsSubTitle = styled.h6`
  font-weight: 600;
  color: ${(props) => props.theme.colors.darkBlue2};
  font-size: 1.4rem;
  margin-bottom: 0.8rem;
`;

const ReportsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  transition: width 0.3s;
`;

const ReportsItemsList = styled.div`
  display: block;
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  flex-direction: column;
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; /* Remove scrollbar space */
  }
`;

export default EditAssessmentProject;
