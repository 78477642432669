import { I18n } from 'react-redux-i18n';

// AP - assessment project
export const AP_INACTIVE_STATUS = 0;
export const AP_ACTIVE_STATUS = 1;
export const AP_IN_PROGRESS_STATUS = 2;
export const AP_COMPLETED_STATUS = 4;

const assessmentProjectStatuses = {
  [AP_INACTIVE_STATUS]: I18n.t('Inactive'),
  [AP_ACTIVE_STATUS]: I18n.t('Active'),
  [AP_IN_PROGRESS_STATUS]: I18n.t('In progress'),
  [AP_COMPLETED_STATUS]: I18n.t('Completed'),
};

export default assessmentProjectStatuses;
