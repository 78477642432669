import { useMemo } from 'react';

const availableAssessments = {
  EN: true,
  AF: true,
  AR: true,
  AZ: true,
  BG: true,
  CH: true,
  CHTR: true,
  CHHK: false,
  CZ: true,
  DE: true,
  DK: true,
  DU: false,
  ES: true,
  'ES-LATAM': true,
  FL: true,
  FR: true,
  GR: true,
  HE: true,
  HI: true,
  IN: true,
  IT: true,
  JP: false,
  KO: true,
  MK: true,
  NO: true,
  PL: true,
  PT: false,
  'PT-BR': true,
  RU: true,
  RO: true,
  SR: true,
  TH: true,
  TR: true,
  UA: true,
  UR: true,
  VN: true,
  ENUS: true,
  MY: true,
  BS: true,
  ME: true,
  HU: true,
  MN: true,
  SL: true,
};

const useAvailableTests = ({ language, assessments }) => {
  return useMemo(() => {
    if (!language || !assessments) return [];
    if (typeof availableAssessments[language.code] === 'boolean' && availableAssessments[language.code])
      return assessments.filter(
        (assessment) => assessment.langAvailability && assessment.langAvailability.split(';').includes(language.code),
      );
    if (!availableAssessments[language.code]) return [];
  }, [assessments, language]);
};

export default useAvailableTests;
