import React from 'react';
import { any, string, oneOfType, node, array, func } from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as BackArrow } from '../assets/icons/back-arrow.svg';
import Breadcrumbs from './reusable/Breadcrumbs';

import CustomButton from './reusable/Buttons/Button';

const PageWrapperForProjects = ({ title, buttons, backButtonHandler, breadcrumbs, customButton, children }) => {
  return (
    <Container>
      {Boolean(breadcrumbs.length) && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      <Header>
        <TitleWrapper>
          {backButtonHandler && (
            <BackButtonWrapper onClick={backButtonHandler}>
              <BackArrow />
            </BackButtonWrapper>
          )}
          <Title>{title}</Title>
        </TitleWrapper>
        <ButtonsWrapper>
          {buttons.map((item) => (
            <StyledButton
              key={item.name}
              handler={item.handler}
              disabled={item.isDisabled}
              variant="rounded"
              type="button"
            >
              {item.label}
            </StyledButton>
          ))}
        </ButtonsWrapper>
        {customButton}
      </Header>
      {children}
    </Container>
  );
};

PageWrapperForProjects.propTypes = {
  buttons: array,
  title: oneOfType([string, node]).isRequired,
  customButton: node,
  backButtonHandler: func,
  children: any.isRequired,
  breadcrumbs: array,
};

PageWrapperForProjects.defaultProps = {
  buttons: [],
  breadcrumbs: [],
  backButtonHandler: undefined,
  customButton: null,
};
const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem 4.4rem;

  .infinite-scroll-component__outerdiv {
    padding-bottom: 0;
    height: calc(100% - 35rem);
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.5rem;
  min-height: 36px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 2.2rem;
  line-height: 2.8rem;
  font-weight: bold;
  margin: 0;
  color: ${(props) => props.theme.colors.darkBlue2};

  ${({ theme }) => theme.screenSizes.lg`
    font-size: 1.8rem;
    line-height: 2rem;
  `}
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(CustomButton)`
  margin-left: 2rem;
  min-width: 12rem;
`;

const BackButtonWrapper = styled.button`
  outline: none;
  border: none;
  background-color: ${(props) => props.theme.colors.white};
  min-width: 3.6rem;
  height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s;

  :hover {
    transform: scale(1.05);
    cursor: pointer;
  }

  :active {
    transform: scale(0.95);
  }
`;

export default PageWrapperForProjects;
