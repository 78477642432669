import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { I18n } from 'react-redux-i18n';
import styled from 'styled-components';

import PageWrapper from '../../PageWrapper';
import TableTabs from '../../reusable/TableTabs';

import Table from '../../reusable/Tables/Table';
import Spinner from '../../reusable/Spinner';
import { fetchNotifications, resetNotificationsState } from '../../../store/notifications/actions';

import notificationsResultsColumns from '../../../table/columns/notificationsResults';
import notificationsReportsColumns from '../../../table/columns/notificationsReports';

import accountTabs from './accountTabs';

const generateTabs = (user) => [
  {
    name: 'results',
    label: I18n.t('tests completed'),
  },
  ...(user?.userType === 3
    ? []
    : [
        {
          name: 'reports',
          label: I18n.t('reports requested'),
        },
      ]),
];

const ActivityLog = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const goBackToSettings = () => dispatch(push('/settings'));

  const onPageTabClick = (tab) => dispatch(push(tab.path));

  const pageTabs =
    user?.userType === 3
      ? accountTabs.filter((item) => item.name === 'general' || item.name === 'assessmentsUsage')
      : accountTabs;

  const notifications = useSelector((state) => state.notifications.notifications);
  const pageCount = useSelector((state) => state.notifications.pageCount);
  const totalCount = useSelector((state) => state.notifications.totalCount);
  const lastPageIndex = useSelector((state) => state.notifications.lastPageIndex);
  const [isLoading, setLoadingState] = useState(false);

  const tabs = generateTabs(user);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [shouldResetPage, setShouldReset] = useState(false);

  const fetchData = useCallback(
    ({ tableData, shouldReset, tableCallback }) => {
      setLoadingState(true);
      dispatch(
        fetchNotifications(tableData, {
          shouldReset,
          notificationType: tabs.findIndex((tab) => tab.name === activeTab.name),
          callback: () => {
            setLoadingState(false);
            tableCallback();
          },
        }),
      );
    },
    [activeTab],
  );

  const onTabClick = (newTab) => {
    setActiveTab(newTab);
    dispatch(resetNotificationsState());
    setShouldReset(true);
  };

  return (
    <PageWrapper title={I18n.t('Account')} backButtonHandler={goBackToSettings}>
      <TableTabs tabs={pageTabs} activeTab={accountTabs[4]} clickHandler={onPageTabClick} />
      <StyledTableTabs tabs={tabs} activeTab={activeTab} clickHandler={onTabClick} />
      <Spinner isLoading={isLoading} />
      <Table
        onFetchData={fetchData}
        columns={activeTab.name === 'results' ? notificationsResultsColumns : notificationsReportsColumns}
        data={notifications}
        disableSelect
        entityName="log"
        pageCount={pageCount}
        totalCount={totalCount}
        shouldResetPage={shouldResetPage}
        setShouldReset={setShouldReset}
        lastPageIndex={lastPageIndex}
        scrollContainerHeight="55rem"
        debounceTimeInMs={200}
      />
    </PageWrapper>
  );
};

const StyledTableTabs = styled(TableTabs)`
  border-bottom: none;

  > div {
    justify-content: center;
  }
`;

ActivityLog.propTypes = {};

export default ActivityLog;
