import React, { useState, useEffect } from 'react';
import { LocationProvider } from '@reach/router';
import styled, { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';

import { useSelector } from 'react-redux';
import { merge } from 'lodash';
import theme from './theme';
import screenSizes from './screenSizes';

import './App.css';
import 'rodal/lib/rodal.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-toastify/dist/ReactToastify.css';
import 'rc-slider/assets/index.css';

import { reachHistory } from './store';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import ContentRouter from './components/ContentRouter';
import storageService from './services/storageService';

const AppInner = () => {
  const partnerInfo = useSelector((state) => state.user.partnerInfo);
  const [customTheme, setCustomTheme] = useState(theme);

  useEffect(() => {
    let localstorageFlag = false;
    const storageCustomColors = storageService.getItem('customColors');

    if (storageCustomColors && !localstorageFlag) {
      const newTheme = merge({ ...customTheme }, { colors: JSON.parse(storageCustomColors) });
      setCustomTheme(newTheme);
      localstorageFlag = true;
    }

    if (!localstorageFlag && partnerInfo?.colors) {
      const newTheme = merge({ ...customTheme }, { colors: { ...partnerInfo?.colors } });
      setCustomTheme(newTheme);
    }

    return () => {};
  }, [partnerInfo]);

  return (
    <>
      <StyledToastContainer autoClose={3000} position="bottom-right" hideProgressBar />
      <LocationProvider history={reachHistory}>
        <ThemeProvider theme={{ ...theme, screenSizes }}>
          <ErrorBoundary>
            <ContentRouter />
          </ErrorBoundary>
        </ThemeProvider>
      </LocationProvider>
    </>
  );
};

const StyledToastContainer = styled(ToastContainer)`
  background-color: transparent;
`;

export default AppInner;
