export const TABS_360 = [
  { name: 'competencies', label: 'Competencies' },
  { name: '360-assessments', label: '360 Surveys' },
  { name: 'responseScales', label: 'Response Scales' },
];

export const TABS_360_ROUTE_MAPPER = {
  responseScales: 'response-scales',
  '360-assessments': '360-assessments',
  competencies: 'custom-competencies',
};

export const TABS_360_TAB_MAPPER = {
  'response-scales': { name: 'responseScales', label: 'Response Scales' },
  '360-assessments': { name: '360-assessments', label: '360 Survey' },
  'custom-competencies': { name: 'competencies', label: 'Competencies' },
};

export const EMPTY_ACTIVE_TAB = {
  name: '',
  label: '',
};
