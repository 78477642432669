import { ReactComponent as RespondentsIcon } from '../../assets/icons/sidebar-respondents.svg';
import { ReactComponent as ProjectsIcon } from '../../assets/icons/sidebar-projects.svg';
import { ReactComponent as InvitesIcon } from '../../assets/icons/sidebar-invites.svg';
import { ReactComponent as ReportsIcon } from '../../assets/icons/sidebar-reports.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/sibebar-settings.svg';

const routesList = [
  {
    id: 'respondents',
    label: 'Respondents',
    to: '/respondents',
    Icon: RespondentsIcon,
  },
  {
    id: 'projects',
    label: 'Projects',
    to: '/projects',
    Icon: ProjectsIcon,
  },
  {
    id: 'invites',
    label: 'Invites',
    to: '/invites',
    Icon: InvitesIcon,
  },
  {
    id: 'reports',
    label: 'Reports',
    to: '/reports',
    Icon: ReportsIcon,
  },
  {
    id: 'settings',
    label: 'Settings',
    to: '/settings',
    Icon: SettingsIcon,
  },
];

export default routesList;
