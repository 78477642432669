import React from 'react';
import { func, number } from 'prop-types';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';

import { ReactComponent as BigCheckIcon } from '../../../assets/icons/big-check.svg';
import CustomButton from '../../reusable/Buttons/Button';

const SuccessfulImportStep = ({ onClose, importedAmount }) => {
  return (
    <>
      <ContentWrapper>
        <BigCheckIcon />
        <Title>{I18n.t('Import complete')}</Title>
        <Label>
          {I18n.t('Records imported')}: {importedAmount}
        </Label>
      </ContentWrapper>
      <ButtonsWrapper>
        <StyledButton handler={onClose}>{I18n.t('Finish')}</StyledButton>
      </ButtonsWrapper>
    </>
  );
};

SuccessfulImportStep.propTypes = {
  onClose: func.isRequired,
  importedAmount: number.isRequired,
};

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-direction: column;
`;

const Title = styled.h3`
  font-size: ${(props) => props.theme.fontSizes.large};
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

const Label = styled.span`
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;
  color: ${(props) => props.theme.colors.darkBlue2};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledButton = styled(CustomButton)`
  width: 12rem;
  text-transform: uppercase;
`;

export default SuccessfulImportStep;
