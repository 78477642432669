import { SET_LOCALE } from 'react-redux-i18n';

import storageService from '../../services/storageService';

const changeLocale = () => (next) => (action) => {
  const result = next(action);

  if (action.type === SET_LOCALE) {
    storageService.setItem('psLang', action.locale);
  }

  return result;
};

export default changeLocale;
