import { I18n } from 'react-redux-i18n';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/sidebar-edit-project.svg';

import * as actions from '../actionsList';

const biodataTemplatesBulkActions = [
  {
    name: actions.UPDATE_DEFAULT_BIO_TEMPLATE,
    label: I18n.t('biodataDefaultTemplate'),
    Icon: EditIcon,
    isDisabled: (biodataTemplates) => biodataTemplates.length > 1,
  },
  {
    name: actions.DELETE_BIODATA_TEMPLATE,
    label: I18n.t('Delete selected biodata template(s)'),
    Icon: DeleteIcon,
    isDisabled: (biodataTemplates) => biodataTemplates.length > 1,
  },
];

export default biodataTemplatesBulkActions;
