import { createAction } from 'redux-actions';
import { I18n } from 'react-redux-i18n';
import { fetchUser } from '../user/actions';
import apiInstance2 from '../../services/apiService';
import defaultTableState from '../../constants/defaultTableState';
import generateQueryString from '../generateQueryString';
import groupsColumns from '../../table/columns/groups';
import respondentsColumns from '../../table/columns/respondents';
import createToastNotification from '../../utils/createToastNotification';

import modifyWithColumnNames from '../../utils/modifyAppliedInputsWithColumnNames';

export const setSelectedGroups = createAction('SET_SELECTED_GROUPS', (groups) => ({ groups }));

export const setActiveGroup = createAction('SET_ACTIVE_GROUP', (group) => ({ group }));
export const setActiveManagedGroupSession = createAction('SET_ACTIVE_MANAGED_GROUP_SESSION', (session) => ({
  session,
}));

// ---------------- FILTERS -----------------
export const setGroupsSearchValue = createAction('SET_GROUPS_SEARCH_VALUE', (searchValue) => ({
  searchValue,
}));

export const setGroupsFilterValue = createAction('SET_GROUPS_FILTER_VALUE', (fieldName, value) => ({
  fieldName,
  value,
}));

export const setGroupsFilterType = createAction('SET_GROUPS_FILTER_TYPE', (fieldName, filterType) => ({
  fieldName,
  filterType,
}));

export const deleteGroupsAppliedFilter = createAction('DELETE_GROUPS_APPLIED_FILTER', (fieldName) => ({
  fieldName,
}));

export const applyGroupsFilters = createAction('APPLY_GROUPS_FILTERS');
export const clearGroupsFilters = createAction('CLEAR_GROUPS_FILTERS');

// ---------  NETWORK RELATED ----------

export const fetchGroupsStart = createAction('FETCH_GROUPS_START');
export const fetchGroupsSuccess = createAction('FETCH_GROUPS_SUCCESS', (data, shouldReset) => ({ data, shouldReset }));
export const fetchGroupsFailure = createAction('FETCH_GROUPS_FAILURE');

export const fetchAllGroupsStart = createAction('FETCH_ALL_GROUPS_START');
export const fetchAllGroupsSuccess = createAction('FETCH_ALL_GROUPS_SUCCESS', (data) => ({ data }));
export const fetchAllGroupsFailure = createAction('FETCH_ALL_GROUPS_FAILURE');

export const createNewGroupStart = createAction('CREATE_NEW_GROUP_START');
export const createNewGroupSuccess = createAction('CREATE_NEW_GROUP_SUCCESS');
export const createNewGroupFailure = createAction('CREATE_NEW_GROUP_FAILURE');

export const createNewGroupFromBulkSelectionStart = createAction('CREATE_NEW_GROUP_FROM_BULK_SELECTION_START');
export const createNewGroupFromBulkSelectionSuccess = createAction('CREATE_NEW_GROUP_FROM_BULK_SELECTION_SUCCESS');
export const createNewGroupFromBulkSelectionFailure = createAction('CREATE_NEW_GROUP_FROM_BULK_SELECTION_FAILURE');

export const fetchGroupByIdStart = createAction('FETCH_GROUP_BY_ID_START');
export const fetchGroupByIdSuccess = createAction('FETCH_GROUP_BY_ID_SUCCESS');
export const fetchGroupByIdFailure = createAction('FETCH_GROUP_BY_ID_FAILURE');

export const deleteGroupsStart = createAction('DELETE_GROUPS_START');
export const deleteGroupsSuccess = createAction('DELETE_GROUPS_SUCCESS', (groupsIds) => ({ groupsIds }));
export const deleteGroupsFailure = createAction('DELETE_GROUPS_FAILURE');

export const fetchGroupsCommonAssessmentsStart = createAction('FETCH_GROUPS_COMMON_ASSESSMENTS_START');
export const fetchGroupsCommonAssessmentsSuccess = createAction(
  'FETCH_GROUPS_COMMON_ASSESSMENTS_SUCCESS',
  (commonAssessments) => ({ commonAssessments }),
);
export const fetchGroupsCommonAssessmentsFailure = createAction('FETCH_GROUPS_COMMON_ASSESSMENTS_FAILURE');
export const clearGroupsCommonAssessments = createAction('CLEAR_GROUPS_COMMON_ASSESSMENTS');

export const fetchGroupsAssessmentsNormsStart = createAction('FETCH_GROUPS_ASSESSMENTS_NORMS_START');
export const fetchGroupsAssessmentsNormsSuccess = createAction('FETCH_GROUPS_ASSESSMENTS_NORMS_SUCCESS', (norms) => ({
  norms,
}));
export const fetchGroupsAssessmentsNormsFailure = createAction('FETCH_GROUPS_ASSESSMENTS_NORMS_FAILURE');
export const clearGroupsAssessmentsNorms = createAction('CLEAR_GROUPS_ASSESSMENTS_NORMS');

export const fetchAvailableGroupsReportsStart = createAction('FETCH_AVAILABLE_GROUPS_REPORTS_START');
export const fetchAvailableGroupsReportsSuccess = createAction(
  'FETCH_AVAILABLE_GROUPS_REPORTS_SUCCESS',
  (reportSets, reports) => ({ reportSets, reports }),
);
export const fetchAvailableGroupsReportsFailure = createAction('FETCH_AVAILABLE_GROUPS_REPORTS_FAILURE');
export const clearAvailableGroupsReports = createAction('CLEAR_AVAILABLE_GROUPS_REPORTS');

export const requestGroupsReportsStart = createAction('REQUEST_GROUPS_REPORTS_START');
export const requestGroupsReportsSuccess = createAction('REQUEST_GROUPS_REPORTS_SUCCESS');
export const requestGroupsReportsFailure = createAction('REQUEST_GROUPS_REPORTS_FAILURE');

export const generateGroupLinkStart = createAction('GENERATE_GROUP_LINK_START');
export const generateGroupLinkSuccess = createAction('GENERATE_GROUP_LINK_SUCCESS');
export const generateGroupLinkFailure = createAction('GENERATE_GROUP_LINK_FAILURE');

export const administerGroupSessionStart = createAction('ADMINISTER_GROUP_SESSION_START');
export const administerGroupSessionFailure = createAction('ADMINISTER_GROUP_SESSION_FAILURE');
export const administerGroupSessionSuccess = createAction('ADMINISTER_GROUP_SESSION_SUCCESS');

export const fetchManagedGroupSessionStart = createAction('FETCH_MANAGED_GROUP_SESSION_START');
export const fetchManagedGroupSessionSuccess = createAction('FETCH_MANAGED_GROUP_SESSION_SUCCESS');
export const fetchManagedGroupSessionFailure = createAction('FETCH_MANAGED_GROUP_SESSION_FAILURE');

export const endManagedGroupSessionStart = createAction('END_MANAGED_GROUP_SESSION_START');
export const endManagedGroupSessionSuccess = createAction('END_MANAGED_GROUP_SESSION_SUCCESS');
export const endManagedGroupSessionFailure = createAction('END_MANAGED_GROUP_SESSION_FAILURE');

export const authorizeRespondentsStart = createAction('AUTHORIZE_RESPONDENTS_START');
export const authorizeRespondentsSuccess = createAction('AUTHORIZE_RESPONDENTS_SUCCESS');
export const authorizeRespondentsFailure = createAction('AUTHORIZE_RESPONDENTS_FAILURE');

export const checkManagedGroupHashStart = createAction('CHECK_MANAGED_GROUP_HASH_START');
export const checkManagedGroupHashSuccess = createAction('CHECK_MANAGED_GROUP_HASH_SUCCESS');
export const checkManagedGroupHashFailure = createAction('CHECK_MANAGED_GROUP_HASH_FAILURE');

export const setManagedGroupSelectedRespondents = createAction(
  'SET_MANAGED_GROUP_SELECTED_RESPONDENTS',
  (respondents) => ({ respondents }),
);
export const resetManagedGroupState = createAction('RESET_MANAGED_GROUP_STATE');

export const changeShouldResetGroupsTableBoolState = createAction(
  'CHANGE_SHOULD_RESET_GROUPS_TABLE_BOOL_STATE',
  (state) => ({ state }),
);

export const fetchGroups = (tableState = defaultTableState, { callback, shouldReset }) => async (dispatch) => {
  try {
    dispatch(fetchGroupsStart());
    if (tableState.sortBy) {
      const sortingObj = tableState.sortBy[0];
      const ColumnIndex = groupsColumns.findIndex(
        (item) => item.Header === sortingObj.id || item.name === sortingObj.id,
      );
      const Order = sortingObj.desc ? 1 : 0;
      tableState.sortBy = { ColumnIndex, Order };
    }
    const queryString = generateQueryString(tableState, groupsColumns);
    const response = await apiInstance2.get(`/api/v2/groups?withRespondents=true&${queryString}`);
    if (response.status === 200) {
      dispatch(fetchGroupsSuccess(response.data, shouldReset));
      if (callback) callback();
    }
  } catch (e) {
    dispatch(fetchGroupsFailure(e));
    if (callback) callback(e);
  }
};

export const fetchAllGroups = (cb) => async (dispatch) => {
  try {
    dispatch(fetchAllGroupsStart());
    const response = await apiInstance2.get(`/api/v2/brief-groups`);
    if (response.status === 200) {
      dispatch(fetchAllGroupsSuccess(response.data));
      if (cb) cb();
    }
  } catch (e) {
    dispatch(fetchAllGroupsFailure());
    if (cb) cb(e);
  }
};

export const createNewGroup = (data, cb) => async (dispatch) => {
  dispatch(createNewGroupStart());
  try {
    const response = await apiInstance2.post(`/api/v2/groups`, data);
    if (response.status === 200) {
      dispatch(createNewGroupSuccess());
      createToastNotification({ message: I18n.t('groupCreationToast') });
      cb(null, response.data.groupID);
    }
  } catch (e) {
    dispatch(createNewGroupFailure());
    createToastNotification({ message: I18n.t('groupCreationErrorToast'), type: 'error' });
    cb(e);
  }
};

export const updateGroupDetails = (data, cb) => async (dispatch) => {
  dispatch(createNewGroupStart());
  try {
    const response = await apiInstance2.patch(`/api/v2/groups`, data);
    if (response.status === 200) {
      createToastNotification({ message: I18n.t('updateGroupToast') });
      cb(null, response.data.groupID);
    }
  } catch (e) {
    createToastNotification({ message: I18n.t('updateGroupErrorToast'), type: 'error' });
    cb(e);
  }
};

export const createNewGroupFromBulkSelection = (data, cb) => async (dispatch, getState) => {
  dispatch(createNewGroupFromBulkSelectionStart());
  const { filter, search } = getState().respondents;
  const tableState = { appliedFilters: modifyWithColumnNames(filter.appliedInputs, respondentsColumns), search };
  try {
    const queryString = generateQueryString(tableState, respondentsColumns);
    const response = await apiInstance2.post(`/api/v2/respondents/groups?${queryString}`, data);
    if (response.status === 200) {
      dispatch(createNewGroupFromBulkSelectionSuccess());
      createToastNotification({ message: I18n.t('groupCreationToast') });
      cb(null, response.data.groupID);
    }
  } catch (e) {
    dispatch(createNewGroupFromBulkSelectionFailure());
    createToastNotification({ message: I18n.t('groupCreationErrorToast'), type: 'error' });
    cb(e);
  }
};

export const fetchGroupsById = (groupId) => async (dispatch) => {
  dispatch(fetchGroupByIdStart());
  try {
    const response = await apiInstance2.get(`/api/v2/groups/${groupId}`);
    if (response.status === 200) {
      dispatch(fetchGroupByIdSuccess());
      dispatch(setActiveGroup(response.data));
    }
  } catch (e) {
    dispatch(fetchGroupByIdFailure());
  }
};

export const deleteGroups = (groupsIds, cb) => async (dispatch) => {
  dispatch(deleteGroupsStart());
  try {
    const response = await apiInstance2.delete(`/api/v2/groups`, { data: groupsIds });
    if (response.status === 200) {
      dispatch(deleteGroupsSuccess(groupsIds));
      createToastNotification({ message: I18n.t('groupsDeletionToast') });
      if (cb) cb();
    }
  } catch (e) {
    dispatch(deleteGroupsFailure());
    if (cb) cb();
  }
};

export const fetchGroupsCommonAssessments = (groupId, cb) => async (dispatch) => {
  dispatch(fetchGroupsCommonAssessmentsStart());
  try {
    const response = await apiInstance2.get(`/api/v2/groups/${groupId}/reports/common-tests`);
    if (response.status === 200) {
      dispatch(fetchGroupsCommonAssessmentsSuccess(response.data));
      if (cb) cb();
    }
  } catch (err) {
    dispatch(fetchGroupsCommonAssessmentsFailure(err.message));
    if (cb) cb();
  }
};

export const fetchGroupsAssessmentsNorms = (testIds) => async (dispatch) => {
  dispatch(fetchGroupsAssessmentsNormsStart());
  try {
    const query = testIds.reduce(
      (acc, item, i) => `${acc}testIds=${encodeURIComponent(item)}${i === testIds.length - 1 ? '' : '&'}`,
      '',
    );
    const response = await apiInstance2.get(`/api/v2/tests/norms?${query}`);
    if (response.status === 200) {
      dispatch(fetchGroupsAssessmentsNormsSuccess(response.data));
    }
  } catch (err) {
    dispatch(fetchGroupsAssessmentsNormsFailure(err.message));
  }
};

export const fetchAvailableGroupsReports = ({ tests, language, groupId }, cb) => async (dispatch) => {
  dispatch(fetchAvailableGroupsReportsStart());
  const testsQueryPart = tests.reduce(
    (acc, item, i) => `${acc}testIds=${encodeURIComponent(item)}${i === tests.length - 1 ? '' : '&'}`,
    '',
  );
  const query = `${testsQueryPart}&langId=${language ? language.code : 'EN'}`;
  try {
    const response = await apiInstance2.get(`/api/v2/groups/${groupId}/reports/available?${query}`);
    if (response.status === 200) {
      dispatch(fetchAvailableGroupsReportsSuccess(response.data.reportSets, response.data.reports));
      cb();
    }
  } catch (err) {
    dispatch(fetchAvailableGroupsReportsFailure(err.message));
    cb();
  }
};

export const requestGroupsReports = (groupId, data, cb) => async (dispatch) => {
  try {
    dispatch(requestGroupsReportsStart());
    const response = await apiInstance2.post(`/api/v2/groups/${groupId}/reports/requests`, data);
    if (response.status === 200) {
      dispatch(requestGroupsReportsSuccess());
      createToastNotification({ message: I18n.t('reportsGenerationToast') });
      dispatch(fetchUser());
      cb();
    }
  } catch (e) {
    dispatch(requestGroupsReportsFailure(e));
    cb(e);
  }
};

export const generateGroupLink = (groupId, data, cb) => async (dispatch) => {
  try {
    dispatch(generateGroupLinkStart());
    const response = await apiInstance2.post(`/api/v2/groups/${groupId}/administer`, data);
    if (response.status === 200) {
      dispatch(generateGroupLinkSuccess());
      if (cb) cb(response.data);
    }
  } catch (e) {
    dispatch(generateGroupLinkFailure(e));
    if (cb) cb(null, e);
  }
};

export const administerGroupSession = (groupId, data, cb) => async (dispatch) => {
  try {
    dispatch(administerGroupSessionStart());
    const response = await apiInstance2.post(`/api/v2/groups/${groupId}/administered-group-session-link`, data);
    if (response.status === 201) {
      dispatch(administerGroupSessionSuccess());
      if (cb) cb(response.data);
    }
  } catch (e) {
    dispatch(administerGroupSessionFailure(e));
    if (cb) cb(null, e);
  }
};

export const fetchManagedGroupSession = (groupId, cb) => async (dispatch) => {
  dispatch(fetchManagedGroupSessionStart());
  try {
    const response = await apiInstance2.get(`/api/v2/groups/${groupId}/administered-group-session/respondents`);
    if (response.status === 200) {
      dispatch(fetchManagedGroupSessionSuccess());
      dispatch(setActiveManagedGroupSession(response.data));
      if (cb) cb();
    }
  } catch (e) {
    dispatch(fetchManagedGroupSessionFailure());
    if (cb) cb(e);
  }
};

export const endManagedGroupSession = (groupId, cb) => async (dispatch) => {
  dispatch(endManagedGroupSessionStart());
  try {
    const response = await apiInstance2.delete(`/api/v2/groups/${groupId}/administered-group-session-link`);
    if (response.status === 200) {
      dispatch(endManagedGroupSessionSuccess());
      if (cb) cb();
    }
  } catch (e) {
    dispatch(endManagedGroupSessionFailure());
    if (cb) cb(e);
  }
};

export const authorizeRespondents = (groupId, respondentsIds, cb) => async (dispatch) => {
  dispatch(authorizeRespondentsStart());
  try {
    const response = await apiInstance2.post(
      `/api/v2/groups/${groupId}/administered-group-session/respondents/authorize`,
      respondentsIds,
    );
    if (response.status === 200) {
      dispatch(authorizeRespondentsSuccess());
      if (cb) cb();
    }
  } catch (e) {
    dispatch(authorizeRespondentsFailure());
    if (cb) cb(e);
  }
};

export const checkManagedGroupHash = (groupId, accessCode) => async (dispatch, getState) => {
  const {
    groups: { activeManagedGroupSession },
  } = getState();
  dispatch(checkManagedGroupHashStart());
  try {
    const response = await apiInstance2.get(
      `/api/v2/groups/${groupId}/administered-group-session/respondents/hash?accessCode=${accessCode}`,
    );
    if (response.status === 200) {
      if (activeManagedGroupSession.hash !== response.data) {
        dispatch(fetchManagedGroupSession(groupId));
      }
      dispatch(checkManagedGroupHashSuccess());
    }
  } catch (e) {
    dispatch(checkManagedGroupHashFailure());
  }
};
