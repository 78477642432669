/* eslint-disable prefer-destructuring */
import React, { useContext, useState, useMemo, useEffect, useRef } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Slider from 'rc-slider';
import Form15fqPlusData from './15fq+Form.json';
import FormOppData from './oppForm.json';

const calculateScale = (selectedIndex, itemScale, accScale = {}) => {
  // eslint-disable-next-line prefer-const
  let [name, range] = itemScale.split(':');
  range = range.split(',').map((item) => Number(item));
  const currentValue = typeof accScale[name] === 'number' ? accScale[name] : 0;
  const addValue = typeof range[selectedIndex] === 'number' ? range[selectedIndex] : 0;
  accScale[name] = currentValue + addValue;
  return accScale;
};

const calculateScaleValues = (scaleName, scaleValue, scales, accSaleValues = []) => {
  const foundScale = scales.find((scale) => scale.shortName === scaleName);
  if (foundScale) {
    const sequence = foundScale.sequence;
    accSaleValues.push({ scaleSequence: sequence, value: scaleValue });
    return accSaleValues;
  }
  // eslint-disable-next-line no-param-reassign
  accSaleValues = accSaleValues.sort((a, b) => a.scaleSequence - b.scaleSequence);
  return accSaleValues;
};

const forms = {
  '15FQ+': Form15fqPlusData,
  OPP: FormOppData,
};

const defaultSelection = (questions) => {
  return questions.reduce(
    (acc, question) => ({
      ...acc,
      [question.number]: 0,
    }),
    {},
  );
};

const savedSelection = (savedValues, questions) => {
  const savedValuesNext = savedValues.split(',');
  return questions.reduce(
    (acc, question, idx) => ({
      ...acc,
      [question.number]: savedValuesNext[idx],
    }),
    {},
  );
};

const CustomForm = ({ data, questionnaireType, onUpdate }) => {
  const themeContext = useContext(ThemeContext);
  const questionnaire = useMemo(() => forms[questionnaireType], [questionnaireType]);

  const responses = useMemo(() => questionnaire.questionnaire.responses.reverse(), [questionnaire]);
  const questions = useMemo(() => questionnaire.questionnaire.questions, [questionnaire]);

  const [formData, setFormData] = useState(defaultSelection(questions));
  const firsTime = useRef(true);

  const scale = useMemo(
    () =>
      Object.entries(formData).reduce(
        (acc, entry) => calculateScale(entry[1], questions[Number(entry[0]) - 1].scale, acc),
        {},
      ),
    [formData],
  );

  useEffect(() => {
    if (data.customFormValues && firsTime.current) {
      firsTime.current = false;
      const test = savedSelection(data.customFormValues, questions);
      setFormData(test);
    }
  }, [data]);

  const scaleValues = useMemo(() => {
    if (data) {
      return Object.entries(scale).reduce(
        (acc, entry) => calculateScaleValues(entry[0], entry[1], data.scales, acc),
        [],
      );
    }

    return [];
  }, [data, scale]);

  const onSliderChange = (newValue, question) => {
    setFormData((prev) => {
      const test = {
        ...prev,
        [question.number]: !newValue && newValue !== 0 ? null : newValue,
      };

      return test;
    });
  };

  useEffect(() => {
    onUpdate({
      customFormValues: Object.values(formData).join(','),
      scaleValues,
    });
  }, [formData]);

  return (
    <StyledContainer>
      <StyledContainerInner>
        {questions.map((question, idx) => (
          <StyledRow>
            <ItemColumn>
              <StyledNumber>{idx + 1}.</StyledNumber>
              {question.item}
            </ItemColumn>
            <RateColumn>
              <StyledSlider
                min={0}
                max={responses.length - 1}
                step={1}
                value={!formData[question.number] && formData[question.number] !== 0 ? null : formData[question.number]}
                defaultValue={
                  !formData[question.number] && formData[question.number] !== 0 ? null : formData[question.number]
                }
                dots
                included={false}
                railStyle={{ backgroundColor: '#CDCDCD' }}
                dotStyle={{
                  width: '0.8rem',
                  height: '0.8rem',
                  backgroundColor: '#CDCDCD',
                  borderColor: '#CDCDCD',
                }}
                handleStyle={{
                  width: '1.8rem',
                  height: '1.8rem',
                  marginTop: '-0.7rem',
                  boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25), 0px 2px 6px rgba(55, 85, 99, 0.5)',
                  border: '4px solid #fff',
                  backgroundColor: themeContext.colors.mediumBlue,
                }}
                onChange={(data) => onSliderChange(data, question)}
              />
              <StyledValue>
                <span>{responses[formData?.[question.number]]?.text || ''}</span>
              </StyledValue>
            </RateColumn>
          </StyledRow>
        ))}
      </StyledContainerInner>
    </StyledContainer>
  );
};

export const calculateDefaultValues = (testID, scales) => {
  const formData = forms[testID];
  const questions = formData?.questionnaire?.questions;
  const customFormValues = defaultSelection(questions);

  const defaultScale = Object.entries(customFormValues).reduce(
    (acc, entry) => calculateScale(entry[1], questions[Number(entry[0]) - 1].scale, acc),
    {},
  );

  const scaleValues = Object.entries(defaultScale).reduce(
    (acc, entry) => calculateScaleValues(entry[0], entry[1], scales, acc),
    [],
  );

  return {
    customFormValues: Object.values(customFormValues).join(','),
    scaleValues,
  };
};

const StyledContainer = styled.div`
  height: 100%;
  overflow: auto;
`;

const StyledContainerInner = styled.div`
  max-width: 880px;
  margin: 0 auto;
  padding: 0 40px;
`;

const ItemColumn = styled.div`
  position: relative;
  font-size: 18px;
`;

const StyledNumber = styled.span`
  position: absolute;
  top: 3px;
  left: 0;
  transform: translateX(-100%);
  padding-right: 5px;
  color: gray;
  opacity: 0.7;
  font-size: 14px;
`;

const StyledRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  padding: 2rem 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey6};
`;

const RateColumn = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 200px 80px;
  grid-column-gap: 20px;
  justify-items: end;
  justify-self: end;
  align-items: center;
`;

const StyledValue = styled.div`
  width: 70px;
  font-weight: bold;
  min-height: 60px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #295368;
`;

const StyledSlider = styled(Slider)`
  max-width: 250px;
`;

export default CustomForm;
