import React, { useState } from 'react';
import { func, array, object } from 'prop-types';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { I18n } from 'react-redux-i18n';
import NewModal from '../../reusable/NewModal';
import CustomButton from '../../reusable/Buttons/Button';
import CustomSelect from '../../reusable/Selects/Select';

import { changeReportFormat } from '../../../store/user/actions';

const ChangeReportFormat = ({ onClose, options, currentOption }) => {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const [reportFormat, setReportFormat] = useState(currentOption);

  const disabled = reportFormat === currentOption;

  const handleChange = (e) => {
    setReportFormat(e);
  };

  const handleSubmit = () => {
    dispatch(
      changeReportFormat(
        {
          userId: user.userID,
          reportFormat: reportFormat.value,
        },
        () => {
          onClose();
        },
      ),
    );
  };

  return (
    <Modal isVisible onClose={onClose} title={I18n.t('Change report format')}>
      <Form>
        <CustomSelect
          selectProps={{
            value: reportFormat,
            onChange: handleChange,
          }}
          placeholder={I18n.t('Report format')}
          options={options}
        />
      </Form>
      <ButtonsWrapper>
        <StyledButton disabled={disabled} onClick={handleSubmit}>
          {I18n.t('Change')}
        </StyledButton>
      </ButtonsWrapper>
    </Modal>
  );
};

ChangeReportFormat.propTypes = {
  onClose: func.isRequired,
  options: array.isRequired,
  currentOption: object.isRequired,
};

const Modal = styled(NewModal)`
  display: flex;
  flex-direction: column;
  min-height: 40rem;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledButton = styled(CustomButton)`
  text-transform: uppercase;
`;

export default ChangeReportFormat;
