const selectData = (state) => state.competencies.list;
const selectOriginalData = (state) => state.competencies.competencies;
const selectIsLoading = (state) => state.competencies.isLoading;

const competenciesSelectors = {
  selectData,
  selectIsLoading,
  selectOriginalData,
};

export default competenciesSelectors;
