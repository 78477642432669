import { I18n } from 'react-redux-i18n';
import * as actions from '../actionsList';

const assessmentsTableActions = [
  {
    name: actions.ADD_CUSTOM_BATTERY,
    label: I18n.t('Add custom battery'),
  },
];

export default assessmentsTableActions;
