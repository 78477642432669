import React, { useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { I18n } from 'react-redux-i18n';
import apiInstance2 from '../../../../services/apiService';
import NewModal from '../../../reusable/NewModal';
import Spinner from '../../../reusable/Spinner';
import CustomButton from '../../../reusable/Buttons/Button';
import FormikInput from '../../../reusable/FormComponents/FormikInput';
import { errorMessages } from '../../../../constants/errorMessages';
import createToastNotification from '../../../../utils/createToastNotification';

const EditTitle = ({ name, groupId, onClose, onConfirm }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { handleChange, values, errors, touched, handleSubmit } = useFormik({
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    initialValues: {
      name,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required(errorMessages.inputField)
        .max(100, errorMessages.tooLong(100)),
    }),
    onSubmit: (values) => {
      setIsLoading(true);

      apiInstance2
        .patch(`/api/v2/360/competency-groups/${groupId}/name`, { name: values.name })
        .then(() => {
          onConfirm(values.name);
          createToastNotification({ message: I18n.t('competencyTitleUpdateToast') });
        })
        .catch(() => setIsLoading(false));
    },
  });

  return (
    <NewModal title={I18n.t('Edit 360 survey')} onClose={onClose} isVisible>
      <StyledModalBody>
        <StyledCustomInput
          inputName={I18n.t('Edit Name')}
          name="name"
          id="name"
          value={values.name}
          onChange={handleChange}
          errors={errors}
          touched={touched}
        />
      </StyledModalBody>
      <ButtonWrapper>
        <StyledCustomButton type="button" size="m" width={120} variant="primary" onClick={handleSubmit}>
          {isLoading ? <Spinner isLoading /> : I18n.t('Save')}
        </StyledCustomButton>
      </ButtonWrapper>
    </NewModal>
  );
};

const StyledModalBody = styled.div`
  overflow: auto;
`;

const StyledCustomInput = styled(FormikInput)`
  margin-top: 15px;
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: flex-end;
  align-self: center;
  width: 100%;
`;

const StyledCustomButton = styled(CustomButton)`
  min-height: 44px;
  position: relative;
`;

export default EditTitle;
