import React, { useState, useEffect } from 'react';
import { func, array } from 'prop-types';
import styled from 'styled-components';
import { sortBy } from 'lodash';
import { I18n } from 'react-redux-i18n';
import NewModal from '../reusable/NewModal';
import Tag from '../reusable/Label';
import CustomButton from '../reusable/Buttons/Button';

import MultiSelect from '../reusable/Selects/MultiSelect';

const AddTagModal = ({ onClose, allTags, onSave }) => {
  const [tags, setTags] = useState(allTags);
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    setTags(allTags);
  }, [allTags.length]);

  const addTags = (newTags) => {
    // give tag object a name if it doesn't have one (manual input case)
    const newTagsWithName = newTags.map((item) => ({ ...item, name: item.name || item.value }));
    // remove potential duplicates
    const withoutDuplicates = newTagsWithName.filter((item) => !selectedTags.find((tag) => tag.name === item.name));
    setSelectedTags((prev) => [...prev, ...withoutDuplicates]);
    // remove tag from potential select options after selection
    if (withoutDuplicates.length)
      setTags((prev) => prev.filter((item) => !newTags.find((tag) => tag.tagID === item.tagID)));
  };

  const removeTag = (tag) => {
    setSelectedTags((prev) => prev.filter((item) => item.name !== tag.name));
    if (tag.tagID) setTags((prev) => [...prev, tag]);
  };

  const options = sortBy(
    tags.map((item) => ({ ...item, value: item.tagID, label: item.name })),
    'name',
  );

  return (
    <Modal isVisible onClose={onClose} title={I18n.t('Add tags')}>
      <Content>
        <MultiSelect options={options} creatable onChange={addTags} placeholder={I18n.t('Select tags')} />
        <TagsWrapper>
          {selectedTags.map((tag) => (
            <Tag value={tag.name} key={tag.tagID || tag.name} onDelete={() => removeTag(tag)} />
          ))}
        </TagsWrapper>
      </Content>
      <Footer>
        <StyledButton variant="secondary" handler={onClose}>
          {I18n.t('Cancel')}
        </StyledButton>
        <StyledButton variant="primary" handler={() => onSave(selectedTags)} disabled={!selectedTags.length}>
          {I18n.t('Save')}
        </StyledButton>
      </Footer>
    </Modal>
  );
};

AddTagModal.propTypes = {
  onClose: func.isRequired,
  onSave: func.isRequired,
  allTags: array,
};

AddTagModal.defaultProps = {
  allTags: [],
};

const Modal = styled(NewModal)`
  width: 57rem;
  min-height: 31rem;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const TagsWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledButton = styled(CustomButton)`
  margin-left: 1rem;
  width: 12.5rem;
`;

export default AddTagModal;
