import { I18n } from 'react-redux-i18n';

export const reminderOptions = [
  {
    value: 'daily',
    label: I18n.t('Daily'),
    backendReference: 1,
  },
  {
    value: 'weekly',
    label: I18n.t('Weekly'),
    backendReference: 2,
  },
  {
    value: 'monthly',
    label: I18n.t('Monthly'),
    backendReference: 3,
  },
  {
    value: 'noRemind',
    label: I18n.t('Do not remind'),
    backendReference: 0,
  },
];

export const sendingOptions = [
  {
    value: 'manually',
    label: I18n.t('Manually'),
    longLabel: I18n.t('Invites are sent manually'),
    hint: I18n.t('Use button on the right to manually send invites'),
    description: I18n.t('manualInviteDesc'),
    backendReference: 0,
  },
  {
    value: 'auto',
    label: I18n.t('Automatically'),
    longLabel: I18n.t('Invites are sent automatically'),
    hint: I18n.t('Invites are automatically sent when you add respondents to the project'),
    description: I18n.t('autoInviteDesc'),
    backendReference: 1,
  },
  {
    value: 'schedule',
    label: I18n.t('Schedule'),
    longLabel: I18n.t('Invites are scheduled'),
    hint: I18n.t('Invites will be automatically sent on selected date'),
    description: I18n.t('scheduledInviteDesc'),
    backendReference: 2,
  },
];
