import { useState } from 'react';

const useStep = ({ steps, initialStep = 0 }) => {
  const [step, setStep] = useState(initialStep);
  const goNext = () => setStep((prev) => (prev === steps.length ? prev : prev + 1));
  const goPrev = () => setStep((prev) => (prev === 0 ? 0 : prev - 1));
  const resetSteps = () => setStep(0);
  return {
    step,
    goNext,
    goPrev,
    resetSteps,
    setStep,
  };
};

export default useStep;
