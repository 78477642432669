import qs from 'qs';
import { I18n } from 'react-redux-i18n';
/* eslint-disable import/no-cycle */
import { createAction } from 'redux-actions';

import apiInstance2 from '../../services/apiService';
import storageService from '../../services/storageService';
import createToastNotification from '../../utils/createToastNotification';

// LOGIN
export const loginStart = createAction('LOGIN_START');
export const loginSuccess = createAction('LOGIN_SUCCESS');
export const loginFailure = createAction('LOGIN_FAILED');

export const resetTokensStart = createAction('RESET_TOKENS_START');
export const resetTokensSuccess = createAction('RESET_TOKENS_SUCCESS');
export const resetTokensFailure = createAction('RESET_TOKENS_FAILURE');

// FETCH CURRENT USER AND ALL THE DATA ATTACHED TO IT
export const fetchUserStart = createAction('FETCH_USER_START');
export const fetchUserSuccess = createAction('FETCH_USER_SUCCESS', (user) => ({ user }));
export const fetchUserFailure = createAction('FETCH_USER_FAILURE');

export const fetchUserRegionStart = createAction('FETCH_USER_REGION_START');
export const fetchUserRegionSuccess = createAction('FETCH_USER_REGION_SUCCESS');
export const fetchUserRegionFailure = createAction('FETCH_USER_REGION_FAILURE');

export const fetchUserBioTemplatesSuccess = createAction('FETCH_USER_BIOTEMPLATES_SUCCESS', (biodataTemplates) => ({
  biodataTemplates,
}));

export const fetchTestsAndLanguagesStart = createAction('FETCH_TESTS_AND_LANGUAGES_START');
export const fetchTestsAndLanguagesSuccess = createAction('FETCH_TESTS_AND_LANGUAGES_SUCCESS', (data) => ({ data }));
export const fetchTestsAndLanguagesFailure = createAction('FETCH_TESTS_AND_LANGUAGES_FAILURE');

export const fetchTagsStart = createAction('FETCH_TAGS_START');
export const fetchTagsSuccess = createAction('FETCH_TAGS_SUCCESS', (tags) => ({ tags }));
export const fetchTagsFailure = createAction('FETCH_TAGS_FAILURE');

export const fetchLanguagesStart = createAction('FETCH_LANGUAGES_START');
export const fetchLanguagesSuccess = createAction('FETCH_LANGUAGES_SUCCESS', (languages) => ({ languages }));
export const fetchLanguagesFailure = createAction('FETCH_LANGUAGES_FAILURE');

export const fetchUserPartnerInfo = createAction('FETCH_USER_CUSTOM_INFORMATION', (userPartnerInfo) => ({
  userPartnerInfo,
}));

// LOGOUT
export const logoutStart = createAction('LOGOUT_START');
export const logoutSuccess = createAction('LOGOUT_SUCCESS');
export const logoutFailure = createAction('LOGOUT_FAILURE');

// CREDITS MANAGEMENT
export const addCreditsStart = createAction('ADD_CREDITS_START');
export const addCreditsSuccess = createAction('ADD_CREDITS_SUCCESS', (amount) => ({ amount }));
export const addCreditsFailure = createAction('ADD_CREDITS_FAILURE');

// BATTERIES
export const createNewBatteryStart = createAction('CREATE_NEW_BATTERY_START');
export const createNewBatterySuccess = createAction('CREATE_NEW_BATTERY_SUCCESS');
export const createNewBatteryFailure = createAction('CREATE_NEW_BATTERY_FAILURE');

export const editBatteryStart = createAction('EDIT_BATTERY_START');
export const editBatterySuccess = createAction('EDIT_BATTERY_SUCCESS');
export const editBatteryFailure = createAction('EDIT_BATTERY_FAILURE');

export const deleteBatteriesStart = createAction('DELETE_BATTERIES_START');
export const deleteBatteriesSuccess = createAction('DELETE_BATTERIES_SUCCESS', (batteriesIds) => ({ batteriesIds }));
export const deleteBatteriesFailure = createAction('DELETE_BATTERIES_FAILURE');

export const fetchCreditsHistoryStart = createAction('FETCH_CREDITS_HISTORY_START');
export const fetchCreditsHistorySuccess = createAction('FETCH_CREDITS_HISTORY_SUCCESS', (creditsHistory) => ({
  creditsHistory,
}));
export const fetchCreditsHistoryFailure = createAction('FETCH_CREDITS_HISTORY_FAILURE');

export const fetchRequestedReportsStart = createAction('FETCH_REQUESTED_REPORTS_START');
export const fetchRequestedReportsSuccess = createAction('FETCH_REQUESTED_REPORTS_SUCCESS', (requestedReports) => ({
  requestedReports,
}));
export const fetchRequestedReportsFailure = createAction('FETCH_REQUESTED_REPORTS_FAILURE');

export const fetchUsedAssessmentsStart = createAction('FETCH_USED_ASSESSMENTS_START');
export const fetchUsedAssessmentsSuccess = createAction('FETCH_USED_ASSESSMENTS_SUCCESS', (assessments) => ({
  assessments,
}));
export const fetchUsedAssessmentsFailure = createAction('FETCH_USED_ASSESSMENTS_FAILURE');

export const changeEmailReceivingStateStart = createAction('CHANGE_EMAIL_RECEIVING_STATE_START');
export const changeEmailReceivingStateSuccess = createAction('CHANGE_EMAIL_RECEIVING_STATE_SUCCESS', (newState) => ({
  newState,
}));
export const changeEmailReceivingStateFailure = createAction('CHANGE_EMAIL_RECEIVING_STATE_FAILURE');

export const changePasswordStart = createAction('CHANGE_PASSWORD_START');
export const changePasswordSuccess = createAction('CHANGE_PASSWORD_SUCCESS');
export const changePasswordFailure = createAction('CHANGE_PASSWORD_FAILURE');

export const changePinStart = createAction('CHANGE_PIN_START');
export const changePinSuccess = createAction('CHANGE_PIN_SUCCESS', (newPin) => ({ newPin }));
export const changePinFailure = createAction('CHANGE_PIN_FAILURE');

export const changeReportFormatSuccess = createAction('CHANGE_REPORT_FORMAT_SUCCESS', (newReportFormat) => ({
  newReportFormat,
}));

export const setResetEmailStart = createAction('SEND_RESET_EMAIL_START');
export const setResetEmailSuccess = createAction('SEND_RESET_EMAIL_SUCCESS');
export const setResetEmailFailure = createAction('SEND_RESET_EMAIL_FAILURE');

export const validateResetPasswordTokenStart = createAction('VALIDATE_RESET_PASSWORD_TOKEN_START');
export const validateResetPasswordTokenSuccess = createAction('VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS');
export const validateResetPasswordTokenFailure = createAction('VALIDATE_RESET_PASSWORD_TOKEN_FAILURE');

export const resetPasswordStart = createAction('RESET_PASSWORD_START');
export const resetPasswordSuccess = createAction('RESET_PASSWORD_SUCCESS');
export const resetPasswordFailure = createAction('RESET_PASSWORD_FAILURE');

// RESET USER REDUCER
export const resetUserState = createAction('RESET_USER_STATE');

// RESET GLOBAL REDUX STATE
export const resetStore = createAction('RESET_STORE');

export const saveUserNotifications = createAction('SAVE_NOTIFICATIONS');
export const updateUserNotification = createAction('UPDATE_USER_NOTIFICATION');

export const loginUser = ({ userEmail, password }, cb) => async (dispatch) => {
  try {
    dispatch(loginStart());
    const data = {
      userName: userEmail,
      password,
    };
    const response = await apiInstance2.post(`/api/v2/authorize`, data, { skipAuthRefresh: true });
    if (response.status === 200) {
      storageService.setItem('accessToken', response.data.token);
      storageService.setItem('refreshToken', response.data.refreshToken);
      dispatch(loginSuccess());
      cb();
    }
  } catch (e) {
    dispatch(loginFailure());
    if (e.message.includes('401')) return cb('Invalid credentials');
    cb('Something went wrong');
  }
};

export const fetchUserRegion = ({ email }, cb) => async (dispatch) => {
  try {
    dispatch(fetchUserRegionStart());
    storageService.removeItem('regionUrl');
    const data = {
      username: email,
    };
    const response = await apiInstance2.post(`/api/v2/user/region`, data, { skipAuthRefresh: true });
    if (response.status === 200) {
      storageService.setItem('regionUrl', response.data.regionUrl);
      dispatch(fetchUserRegionSuccess());

      dispatch(fetchUserPartnerInfo(response.data));
      if (cb) cb();
    }
  } catch (e) {
    dispatch(fetchUserRegionFailure());
    if (e.message.includes('404')) {
      storageService.removeItem('regionUrl');
      return cb('Invalid login');
    }
    cb('Something went wrong');
  }
};

export const fetchUserBiodataTemplates = ({ userId }, cb) => async (dispatch) => {
  try {
    const bodyParams = {
      params: {
        userId,
      },
    };
    const response = await apiInstance2.get(`/api/v2/user/settings/biodata`, bodyParams);
    if (response.status === 200) {
      dispatch(fetchUserBioTemplatesSuccess(response.data));
    }
  } catch (e) {
    cb('Something went wrong', e);
  } finally {
    if (cb) cb();
  }
};

export const createBiodataTemplate = (data, cb) => async () => {
  try {
    const response = await apiInstance2.post(`/api/v2/user/settings/biodata`, data);
    if (response.status === 200) {
      createToastNotification({ message: I18n.t('Biodata Template has been created') });
      if (cb) cb();
    }
  } catch (e) {
    cb('Something went wrong', e);
  }
};

export const updateBiodataTemplate = (data, cb) => async () => {
  try {
    const response = await apiInstance2.put(`/api/v2/user/settings/biodata`, data);
    if (response.status === 200) {
      createToastNotification({ message: I18n.t('Biodata Template has been updated') });
      if (cb) cb();
    }
  } catch (e) {
    cb('Something went wrong', e);
  }
};

export const deleteBiodataTemplate = (templateInfo, cb) => async () => {
  const { userId, biodataFieldConfigurationId } = templateInfo;
  try {
    const response = await apiInstance2.delete(
      `/api/v2/user/settings/biodata?userId=${userId}&biodataFieldConfigurationId=${biodataFieldConfigurationId}`,
    );
    if (response.status === 200) {
      createToastNotification({ message: I18n.t('Biodata template(s) deleted') });
      if (cb) cb();
    }
  } catch (e) {
    cb('Something went wrong', e);
  }
};

export const updateDefaultBiodataTemplate = (templateInfo, cb) => async () => {
  try {
    const { userId, templateId } = templateInfo;
    const response = await apiInstance2.put(`/api/v2/user/settings/biodata/default/${templateId}?userId=${userId}`);
    if (response.status === 200) {
      createToastNotification({ message: I18n.t('Default Biodata Template has been updated') });
      if (cb) cb();
    }
  } catch (e) {
    cb('Something went wrong', e);
  } finally {
    if (cb) cb();
  }
};

export const fetchUserNotifications = (params = {}) => async (dispatch) => {
  try {
    const allNotificationFetchers = [];
    if (params.userId) {
      allNotificationFetchers.push(
        apiInstance2.get(`/api/v2/system/notifications`, {
          params: {
            userId: params.userId,
            langId: params.langId,
          },
        }),
      );
    }

    if (params.accountId) {
      allNotificationFetchers.push(
        apiInstance2.get(`/api/v2/system/notifications`, {
          params: {
            accountId: params.accountId,
            langId: params.langId,
          },
        }),
      );
    }

    if (params.distributorId) {
      allNotificationFetchers.push(
        apiInstance2.get(`/api/v2/system/notifications`, {
          params: {
            distributorId: params.distributorId,
            langId: params.langId,
          },
        }),
      );
    }

    let notifications = (await Promise.allSettled(allNotificationFetchers)).reduce(
      (acc, promise) => (promise.value?.data ? acc.concat(...promise.value.data) : acc),
      [],
    );

    notifications = notifications.sort((x, y) => {
      const xTimestamp = x.creationDate ? new Date(x.creationDate).getTime() : 0;
      const yTimestamp = y.creationDate ? new Date(y.creationDate).getTime() : 0;
      return yTimestamp - xTimestamp;
    });

    dispatch(saveUserNotifications(notifications));
  } catch (e) {
    console.warn(e);
  }
};

export const dismissUserNotification = (notification) => async (dispatch) => {
  const payload = {
    ...notification,
    acknowledged: true,
  };
  try {
    const response = await apiInstance2.put(`/api/v2/system/notifications`, null, {
      params: { notificationId: notification.id },
    });
    if (response.status === 200) {
      dispatch(updateUserNotification(payload));
    }
  } catch (e) {
    console.warn(e);
  }
};

export const fetchUser = (cb) => async (dispatch) => {
  try {
    dispatch(fetchUserStart());
    const response = await apiInstance2.get(`/api/v2/user`);
    if (response.status === 200) {
      dispatch(fetchUserSuccess(response.data));

      const params = {
        distributorId: response.data.distributorOwnerID,
        accountId: response.data.accountID,
        userId: response.data.userID,
        langId: 'en',
      };
      dispatch(fetchUserBiodataTemplates({ userId: response.data.userID }));
      fetchUserNotifications(params)(dispatch);

      if (cb) cb();
    }
  } catch (e) {
    dispatch(fetchUserFailure());
    if (cb) cb(e);
  }
};

export const fetchTags = () => async (dispatch) => {
  try {
    dispatch(fetchTagsStart());
    const response = await apiInstance2.get(`/api/v2/user/tags`);
    if (response.status === 200) {
      dispatch(fetchTagsSuccess(response.data));
    }
  } catch (e) {
    dispatch(fetchTagsFailure());
  }
};

export const fetchLanguages = () => async (dispatch) => {
  try {
    dispatch(fetchLanguagesStart());
    const response = await apiInstance2.get(`/api/v2/user/languages`);
    if (response.status === 200) {
      dispatch(fetchLanguagesSuccess(response.data));
    }
  } catch (e) {
    dispatch(fetchLanguagesFailure());
  }
};

export const addCredits = (data, cb) => async (dispatch) => {
  try {
    dispatch(addCreditsStart());
    const response = await apiInstance2.post(`/api/v2/user/credits`, data);
    if (response.status === 200) {
      dispatch(addCreditsSuccess(response.data.currentCredits));
      cb(response.data);
    }
  } catch (e) {
    dispatch(addCreditsFailure());
    cb(null, e.msg || e.message || e);
  }
};

export const logout = () => (dispatch) => {
  dispatch(logoutStart());
  storageService.removeItem('accessToken');
  storageService.removeItem('refreshToken');
  storageService.removeItem('regionUrl');
  storageService.removeItem('customColors');
  storageService.removeItem('customLogos');
  apiInstance2.defaults.headers.common.Authorization = ``;
  dispatch(resetStore());
  dispatch(logoutSuccess());
};

export const fetchTestsAndLanguages = (cb) => async (dispatch) => {
  try {
    dispatch(fetchTestsAndLanguagesStart());
    const response = await apiInstance2.get(`/api/v2/Tests`);
    if (response.status === 200) {
      dispatch(fetchTestsAndLanguagesSuccess(response.data));
      if (cb) cb();
    }
  } catch (e) {
    dispatch(fetchTestsAndLanguagesFailure(e));
    if (cb) cb(e);
  }
};

export const createNewBattery = (data, cb) => async (dispatch) => {
  try {
    dispatch(createNewBatteryStart());
    const response = await apiInstance2.post(`/api/v2/batteries`, data);
    if (response.status === 200) {
      dispatch(createNewBatterySuccess());
      createToastNotification({ message: I18n.t('batteryAdditionToast') });
      dispatch(fetchTestsAndLanguages());
      if (cb) cb();
    }
  } catch (e) {
    dispatch(createNewBatteryFailure(e));
    if (cb) cb(e);
  }
};

export const editBattery = (data, cb) => async (dispatch) => {
  try {
    dispatch(editBatteryStart());
    const response = await apiInstance2.patch(`/api/v2/batteries`, data);
    if (response.status === 200) {
      dispatch(editBatterySuccess());
      createToastNotification({ message: I18n.t('batteryUpdateToast') });
      dispatch(fetchTestsAndLanguages());
      if (cb) cb();
    }
  } catch (e) {
    dispatch(editBatteryFailure(e));
    if (cb) cb(e);
  }
};

export const deleteBatteries = (batteriesIds, cb) => async (dispatch) => {
  try {
    dispatch(deleteBatteriesStart());
    const response = await apiInstance2.delete(`/api/v2/batteries`, { data: batteriesIds });
    if (response.status === 200) {
      dispatch(deleteBatteriesSuccess(batteriesIds));
      createToastNotification({
        message: I18n.t(batteriesIds.length > 1 ? 'batteriesDeletionToast' : 'batteryDeletionToast'),
      });
      if (cb) cb();
    }
  } catch (e) {
    dispatch(deleteBatteriesFailure(e));
    if (cb) cb(e);
  }
};

export const fetchCreditsHistory = (data, cb) => async (dispatch) => {
  try {
    dispatch(fetchCreditsHistoryStart());
    const query = qs.stringify(data);
    const response = await apiInstance2.get(`/api/v2/user/statistic/credits?${query}`);
    if (response.status === 200) {
      dispatch(fetchCreditsHistorySuccess(response.data));
      if (cb) cb();
    }
  } catch (e) {
    dispatch(fetchCreditsHistoryFailure(e));
    if (cb) cb(e);
  }
};

export const fetchRequestedReports = (data, cb) => async (dispatch) => {
  try {
    dispatch(fetchRequestedReportsStart());
    const query = qs.stringify(data);
    const response = await apiInstance2.get(`/api/v2/user/statistic/reports-usage?${query}`);
    if (response.status === 200) {
      dispatch(fetchRequestedReportsSuccess(response.data));
      if (cb) cb();
    }
  } catch (e) {
    dispatch(fetchRequestedReportsFailure(e));
    if (cb) cb(e);
  }
};

export const fetchUsedAssessments = (data, cb) => async (dispatch) => {
  try {
    dispatch(fetchUsedAssessmentsStart());
    const query = qs.stringify(data);
    const response = await apiInstance2.get(`/api/v2/user/statistic/tests-usage?${query}`);
    if (response.status === 200) {
      dispatch(fetchUsedAssessmentsSuccess(response.data));
      if (cb) cb();
    }
  } catch (e) {
    dispatch(fetchUsedAssessmentsFailure(e));
    if (cb) cb(e);
  }
};

export const changeEmailReceivingState = (newState, cb) => async (dispatch) => {
  try {
    dispatch(changeEmailReceivingStateStart());
    const response = await apiInstance2.patch(`/api/v2/user/receive-email-notifications`, {
      receiveEmailNotifications: newState,
    });
    if (response.status === 200) {
      dispatch(changeEmailReceivingStateSuccess(newState));
      if (cb) cb();
    }
  } catch (e) {
    dispatch(changeEmailReceivingStateFailure(e));
    if (cb) cb(e);
  }
};

export const changePassword = (newPassword, cb) => async (dispatch) => {
  try {
    dispatch(changePasswordStart());
    const response = await apiInstance2.patch(`/api/v2/user/password`, {
      password: newPassword,
    });
    if (response.status === 200) {
      dispatch(changePasswordSuccess(response.data));
      createToastNotification({ message: I18n.t('passwordUpdateSuccessToast') });
      if (cb) cb();
    }
  } catch (e) {
    dispatch(changePasswordFailure(e));
    if (cb) cb(e);
  }
};

export const changePin = (newPin, cb) => async (dispatch) => {
  try {
    dispatch(changePinStart());
    const response = await apiInstance2.patch(`/api/v2/user/pin`, {
      pin: newPin,
    });
    if (response.status === 200) {
      dispatch(changePinSuccess(newPin));
      createToastNotification({ message: I18n.t('pinUpdateSuccessToast') });
      if (cb) cb();
    }
  } catch (e) {
    dispatch(changePinFailure(e));
    if (cb) cb(e);
  }
};

export const changeReportFormat = (data, cb) => async (dispatch) => {
  try {
    const response = await apiInstance2.put(`/api/v2/user/settings/reportFormat`, null, {
      params: {
        ...data,
      },
    });
    if (response.status === 200) {
      if (response.data === 'Report format not supported') {
        throw new Error(response.data);
      }
      dispatch(changeReportFormatSuccess(data.reportFormat));
      createToastNotification({ message: I18n.t('reportFormatUpdateSuccessToast') });
      if (cb) cb();
    }
  } catch (e) {
    createToastNotification({ type: 'error', message: I18n.t('reportFormatUpdateFailureToast') });
    if (cb) cb(e);
  }
};

export const sendResetEmail = (email, cb) => async (dispatch) => {
  try {
    dispatch(setResetEmailStart());
    const response = await apiInstance2.post(`/api/v2/user/forgot-password/ask`, {
      email,
    });
    if (response.status === 200) {
      dispatch(setResetEmailSuccess());
      if (cb) cb();
    }
  } catch (e) {
    dispatch(setResetEmailFailure(e));
    if (cb) cb(e);
  }
};

export const resetPassword = (data, cb) => async (dispatch) => {
  try {
    dispatch(resetPasswordStart());
    const response = await apiInstance2.post(`/api/v2/user/forgot-password/${data.code}`, { password: data.password });
    if (response.status === 200) {
      dispatch(resetPasswordSuccess());
      if (cb) cb();
    }
  } catch (e) {
    dispatch(resetPasswordFailure(e));
    if (cb) cb(e);
  }
};

export const validateResetPasswordToken = (token, cb) => async (dispatch) => {
  try {
    dispatch(validateResetPasswordTokenStart());
    const response = await apiInstance2.options(`/api/v2/user/forgot-password/${token}`);
    if (response.status === 200) {
      dispatch(validateResetPasswordTokenSuccess());
      if (cb) cb();
    }
  } catch (e) {
    dispatch(validateResetPasswordTokenFailure(e));
    if (cb) cb(e);
  }
};
