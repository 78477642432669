import React from 'react';
import { func, array } from 'prop-types';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import MultiSelect from '../../reusable/Selects/MultiSelect';
import CustomButton from '../../reusable/Buttons/Button';

const GroupsTab = ({ allGroups, onAddGroup, selectedGroups, onRemoveGroup }) => {
  return (
    <Container>
      <MultiSelect options={allGroups} onChange={onAddGroup} placeholder={I18n.t('Select groups')} />
      {Boolean(selectedGroups.length) && (
        <GroupsWrapper>
          <GroupsHeader>{I18n.t('Group name')}</GroupsHeader>
          {selectedGroups.map((group) => (
            <GroupsItem key={group.groupID || group.name}>
              <span>{group.name}</span>
              <CustomButton variant="secondary" size="s" handler={() => onRemoveGroup(group)}>
                {I18n.t('Remove')}
              </CustomButton>
            </GroupsItem>
          ))}
        </GroupsWrapper>
      )}
    </Container>
  );
};

GroupsTab.propTypes = {
  allGroups: array.isRequired,
  selectedGroups: array.isRequired,
  onAddGroup: func.isRequired,
  onRemoveGroup: func.isRequired,
};

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const GroupsWrapper = styled.div`
  //margin-top: 2rem;
  display: flex;
  flex-direction: column;
  height: 40rem;
  overflow: auto;
`;

const GroupsHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 600;
  color: ${(props) => props.theme.colors.darkBlue};
  padding: 1.5rem 1.2rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};
`;

const GroupsItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;
  color: ${(props) => props.theme.colors.primary};
  padding: 1.5rem 1.2rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};
`;

export default GroupsTab;
