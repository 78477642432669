import React from 'react';
import { func, object, string, array, oneOfType } from 'prop-types';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';

import CustomSelect from '../../Selects/Select';
import CloseIcon from '../../CloseIcon';

const FilterSelectInputGroup = ({ className, onSelectChange, onDelete, selectValue, label, options }) => {
  const translatedOptions = options.map((item) => ({ ...item, label: I18n.t(item.label) }));
  return (
    <OuterContainer className={className}>
      <InputsContainer>
        <StyledSelect
          options={translatedOptions}
          selectProps={{ onChange: onSelectChange, value: selectValue }}
          placeholder={label}
        />
        <StyledCloseIcon clickHandler={onDelete} />
      </InputsContainer>
    </OuterContainer>
  );
};
FilterSelectInputGroup.propTypes = {
  onSelectChange: func.isRequired,
  onDelete: func.isRequired,
  options: array.isRequired,
  selectValue: oneOfType([object, string]),
  label: string.isRequired,
  className: string,
};

FilterSelectInputGroup.defaultProps = {
  selectValue: null,
  className: '',
};

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSelect = styled(CustomSelect)`
  margin-bottom: 0;
  margin-right: 15px;
  flex-grow: 1;
`;

const StyledCloseIcon = styled(CloseIcon)`
  width: 1rem;
  height: 1rem;
`;

export default React.memo(FilterSelectInputGroup, (prev, next) => {
  return prev.inputValue === next.inputValue && prev.selectValue === next.selectValue && prev.options === next.options;
});
