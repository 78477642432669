import { I18n } from 'react-redux-i18n';
import * as actions from '../actionsList';

export const competencyCategoryRowActions = [
  {
    name: actions.ADD_COMPETENCY,
    label: I18n.t('Add competency'),
  },
  {
    name: actions.EDIT_COMPETENCY_CATEGORY,
    label: I18n.t('Edit competency category'),
  },
  {
    name: actions.DELETE_COMPETENCY_CATEGORY,
    label: I18n.t('Delete competency category'),
  },
];

export const competencyItemRowActions = [
  {
    name: actions.ADD_QUESTION,
    label: I18n.t('Add question'),
  },
  {
    name: actions.EDIT_COMPETENCY_ITEM,
    label: I18n.t('Edit competency'),
  },
  {
    name: actions.DELETE_COMPETENCY_ITEM,
    label: I18n.t('Delete competency'),
  },
];

export const competencyQuestionRowActions = [
  {
    name: actions.EDIT_QUESTION,
    label: I18n.t('Edit question'),
  },
  {
    name: actions.DELETE_QUESTION,
    label: I18n.t('Delete question'),
  },
];
