import { I18n } from 'react-redux-i18n';
import React, { useState, useEffect, useMemo } from 'react';
import ReusableAdvancedFilter from '../../reusable/AdvancedFilter';
import useTableFilter from '../../../hooks/useTableFilter';
import {
  DATE_FILTER_TYPES,
  datesFilterOptions,
  NUMBER_FILTER_TYPES,
  numberFilterOptions,
  TEXT_FILTER_TYPES,
  textFilterOptions,
} from '../../../constants/advancedFilterOptions';
import CustomSelect from '../../reusable/Selects/Select';

const getInitialFilterValue = (type) => {
  if (type === 'number') return numberFilterOptions.find((item) => item.name === NUMBER_FILTER_TYPES.IS_EQUAL);
  if (type === 'date') return datesFilterOptions.find((item) => item.name === DATE_FILTER_TYPES.THREE_DAYS);
  return textFilterOptions.find((item) => item.name === TEXT_FILTER_TYPES.CONTAINS);
};

const AdvancedFilter = ({ columns, filterOptions, setFilters, setGlobalFilter }) => {
  const initialInputsState = columns.reduce((reducer, fieldObj) => {
    if (fieldObj.disableFilters) {
      return reducer;
    }
    return [
      ...reducer,
      {
        id: fieldObj.id,
        name: fieldObj.name,
        value: fieldObj.type === 'date' ? null : '',
        type: fieldObj.type,
        filterType: getInitialFilterValue(fieldObj.type),
      },
    ];
  }, []);

  const addColumnOptions = (columns, options) => {
    return columns.map((col) => {
      if (col?.type === 'select' && options[col.id]) {
        return {
          ...col,
          options: options[col.id],
        };
      }
      return col;
    });
  };

  const filterColumns = useMemo(() => {
    return addColumnOptions(columns, filterOptions);
  }, [columns]);

  const [filterIsApplied, setFilterIsApplied] = useState(false);
  const [filterDirty, setFilterDirty] = useState(false);
  const [filterInputs, setFilterInputs] = useState(initialInputsState);
  const [filterSearchValue, setFilterSearchValue] = useState('');
  const [filterAppliedInputs, setFilterAppliedInputs] = useState(initialInputsState);

  const filter = {
    isApplied: filterIsApplied,
    dirty: filterDirty,
    appliedInputs: filterAppliedInputs,
    inputs: filterInputs,
  };

  const onFilterTypeChange = (name, value) => {
    setFilterInputs((inputs) => inputs.map((item) => (item.name === name ? { ...item, filterType: value } : item)));
    setFilterDirty(true);
  };

  const onFilterValueInput = (name, value) => {
    setFilterInputs((inputs) => inputs.map((item) => (item.name === name ? { ...item, value } : item)));
    setFilterDirty(true);
  };

  const onApplyFilters = () => {
    setFilterDirty(false);
    setFilterIsApplied(true);
    setFilterAppliedInputs(filterInputs);
  };

  const onFilterClearAll = () => {
    setFilterDirty(true);
    setFilterInputs(initialInputsState);
  };

  const onFilterDelete = (name) => {
    setFilterAppliedInputs((inputs) => {
      return inputs.map((input) =>
        input.name === name ? { ...input, value: input.type === 'date' ? null : '' } : input,
      );
    });
    setFilterInputs((inputs) => {
      return inputs.map((input) =>
        input.name === name ? { ...input, value: input.type === 'date' ? null : '' } : input,
      );
    });
  };

  const onSearchChange = (val) => {
    setFilterSearchValue(val);
  };

  const { inputsWithHandlers, appliedFiltersWithColumnNames } = useTableFilter({
    filter,
    columns: filterColumns,
    setFilterType: onFilterTypeChange,
    setFilterValue: onFilterValueInput,
  });

  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(filterOptions.testID[0]);

  const questionnairesOnChangeHandler = (val) => {
    setSelectedQuestionnaire(val);
  };

  useEffect(() => {
    setGlobalFilter(filterSearchValue);
  }, [filterSearchValue]);

  useEffect(() => {
    const testFilter = {
      id: 'testID',
      value: selectedQuestionnaire.value,
    };

    setFilters([testFilter, ...filterAppliedInputs]);
  }, [filterAppliedInputs, selectedQuestionnaire]);

  return (
    <div>
      <ReusableAdvancedFilter
        searchProps={{
          searchValue: filterSearchValue,
          onSearchChange,
          appliedFilters: appliedFiltersWithColumnNames,
          deleteFilter: onFilterDelete,
        }}
        filterProps={{
          inputs: inputsWithHandlers,
          appliedInputs: filter.appliedInputs,
          applyFilters: onApplyFilters,
          clearAll: onFilterClearAll,
          isDirty: filter.dirty,
        }}
        isFilterDisabled
      />
      <CustomSelect
        options={filterOptions.testID}
        placeholder={I18n.t('Questionnaires')}
        selectProps={{
          value: selectedQuestionnaire,
          onChange: questionnairesOnChangeHandler,
        }}
      />
    </div>
  );
};

export default AdvancedFilter;
