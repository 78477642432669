import React from 'react';
import styled from 'styled-components';
import { FieldArray } from 'formik';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import LanguagesSelect from '../reusable/Selects/LanguagesSelect';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { selectAvailableLanguages } from '../../store/user/selectors';
import CustomButton from '../reusable/Buttons/Button';
import CustomTextArea from '../reusable/FormComponents/TextArea';
import Spinner from '../reusable/Spinner';
import FormikInput from '../reusable/FormComponents/FormikInput';

const AddCompetencyQuestionForm = ({
  values,
  errors,
  touched,
  setFieldValue,
  handleChange,
  handleSubmit,
  isSubmitting,
}) => {
  const languages = useSelector(selectAvailableLanguages);

  const usedLanguagesCodes = values.languages.map((item) => item.lang.code);
  // filter out those languages that are already used
  const filteredLanguages = languages.filter((lang) => !usedLanguagesCodes.includes(lang.code));

  return (
    <>
      <StyledBody>
        <FieldArray
          name="languages"
          render={(arrayHelpers) => (
            <ListWrapper>
              {values.languages.map((language, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Languages key={index}>
                  <AddLanguageWrapper>
                    <LanguagesSelect
                      name={`languages.${index}.lang`}
                      value={values.languages[index].lang}
                      languages={filteredLanguages}
                      errors={errors}
                      touched={touched}
                      onChange={(lang) => {
                        setFieldValue(`languages.${index}.lang`, lang);
                      }}
                    />
                    {values.languages.length > 1 && (
                      <LanguageCloseButton onClick={() => arrayHelpers.remove(index)}>
                        <CloseIcon />
                      </LanguageCloseButton>
                    )}
                  </AddLanguageWrapper>
                  <StyledCustomInput
                    inputName={I18n.t('Enter Name')}
                    name={`languages.${index}.name`}
                    id={`languages.${index}.name`}
                    value={values.languages[index].name}
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                  />
                  <StyledCustomTextarea
                    inputName={I18n.t('Add description for the category')}
                    name={`languages.${index}.description`}
                    id={`languages.${index}.description`}
                    value={values.languages[index].description}
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                  />
                </Languages>
              ))}
              <AddResponse
                onClick={() =>
                  arrayHelpers.push({
                    lang: { value: '', code: '', label: '' },
                    name: '',
                  })
                }
              >
                {`+ ${I18n.t('Add another language')}`}
              </AddResponse>
            </ListWrapper>
          )}
        />
      </StyledBody>
      <ButtonWrapper>
        <StyledCustomButton type="button" size="m" width={120} variant="primary" onClick={handleSubmit}>
          {isSubmitting ? <Spinner isLoading /> : I18n.t('Save')}
        </StyledCustomButton>
      </ButtonWrapper>
    </>
  );
};

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Languages = styled.div`
  padding-bottom: 7px;
  display: ${(props) => (props.isHidden ? 'none' : 'block')};

  & + & {
    margin-top: 15px;
  }
`;

const StyledBody = styled.div`
  overflow: auto;
  max-height: 550px;
  padding-bottom: 60px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 3rem;
`;

const AddResponse = styled.span`
  display: inline-block;
  color: ${(props) => props.theme.colors.lightBlue};
  font-size: 12px;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
`;

const AddLanguageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const StyledCustomButton = styled(CustomButton)`
  min-height: 44px;
  position: relative;
  justify-content: center;
`;

const LanguageCloseButton = styled.div`
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-top: 0;
  background-color: ${(props) => props.theme.colors.lightBlue1};
  margin-left: 20px;

  svg {
    width: 1rem;
    height: 1rem;
    path {
      fill: ${(props) => props.theme.colors.primary};
    }
  }
`;

const StyledCustomInput = styled(FormikInput)`
  width: 100%;
`;
const StyledCustomTextarea = styled(CustomTextArea)`
  margin-top: 20px;
`;

export default AddCompetencyQuestionForm;
