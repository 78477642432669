import React, { useState } from 'react';
import styled from 'styled-components';
import { FieldArray } from 'formik';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import RadioButton from '../reusable/FormComponents/RadioButton';
import LanguagesSelect from '../reusable/Selects/LanguagesSelect';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { selectAvailableLanguages } from '../../store/user/selectors';
import CustomButton from '../reusable/Buttons/Button';
import CustomTextArea from '../reusable/FormComponents/TextArea';
import Spinner from '../reusable/Spinner';
import Editor from '../reusable/TextEditor/Editor';
import NewModal from '../reusable/NewModal';

const editorToolbar = {
  options: ['inline', 'blockType', 'list', 'textAlign'],
  inline: {
    options: ['bold', 'italic', 'underline'],
  },
};

const AddCompetencyQuestionForm = ({
  values,
  errors,
  touched,
  setFieldValue,
  handleChange,
  handleSubmit,
  isSubmitting,
}) => {
  const languages = useSelector(selectAvailableLanguages);

  const usedLanguagesCodes = values.languages.map((item) => item.lang.code);
  // filter out those languages that are already used
  const filteredLanguages = languages.filter((lang) => !usedLanguagesCodes.includes(lang.code));

  const [initEditorState, setInitEditorState] = useState({ body: '', field: '' });
  const [editorBody, setEditorBody] = useState('');
  const [editorIsVisible, setEditorIsVisible] = useState(false);

  const openEditor = (initState) => {
    setInitEditorState(initState);
    setEditorIsVisible(true);
  };

  const closeEditor = () => {
    setFieldValue(initEditorState.field, editorBody);
    setEditorIsVisible(false);
  };

  const onEditorBodyChange = (value) => {
    setEditorBody(value);
  };

  return (
    <>
      <StyledBody>
        <RadioContainer>
          <RadioWrapper>
            <RadioButton isChecked={values.type === '0'} value="0" name="type" onChange={handleChange} />
            <span>{I18n.t('Multiple choice')}</span>
          </RadioWrapper>
          <RadioWrapper>
            <RadioButton isChecked={values.type === '1'} value="1" name="type" onChange={handleChange} />
            <span>{I18n.t('Free field')}</span>
          </RadioWrapper>
        </RadioContainer>
        <FieldArray
          name="languages"
          render={(arrayHelpers) => (
            <ListWrapper>
              {values.languages.map((language, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Languages key={index}>
                  <AddLanguageWrapper>
                    <LanguagesSelect
                      name={`languages.${index}.lang`}
                      value={values.languages[index].lang}
                      languages={filteredLanguages}
                      errors={errors}
                      touched={touched}
                      onChange={(lang) => {
                        setFieldValue(`languages.${index}.lang`, lang);
                      }}
                    />
                    {values.languages.length > 1 && (
                      <LanguageCloseButton onClick={() => arrayHelpers.remove(index)}>
                        <CloseIcon />
                      </LanguageCloseButton>
                    )}
                  </AddLanguageWrapper>
                  {values.type === '1' ? (
                    <EditorResponseTitleWrapper
                      onClick={() =>
                        openEditor({
                          body: values.languages[index].instruction || '',
                          field: `languages.${index}.instruction`,
                        })
                      }
                    >
                      <EditorResponseTitle
                        inputName={I18n.t('Additional instruction')}
                        name={`languages.${index}.instruction`}
                        id={`languages.${index}.instruction`}
                        value={values.languages[index].instruction}
                        isHidden={values.languages[index]?.instruction?.length}
                      />
                      {values.languages[index]?.instruction?.length ? (
                        <EditorPreview>
                          <EditorPreviewInner
                            dangerouslySetInnerHTML={{ __html: values.languages[index].instruction }}
                          />
                        </EditorPreview>
                      ) : (
                        ''
                      )}
                    </EditorResponseTitleWrapper>
                  ) : (
                    ''
                  )}
                  <ResponseTitleWrapper>
                    <ResponseTitle
                      inputName={I18n.t('Item text')}
                      name={`languages.${index}.question`}
                      id={`languages.${index}.question`}
                      value={values.languages[index].question}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </ResponseTitleWrapper>
                </Languages>
              ))}
              <AddResponse
                onClick={() =>
                  arrayHelpers.push({
                    lang: { value: '', code: '', label: '' },
                    question: '',
                  })
                }
              >
                {`+ ${I18n.t('Add another language')}`}
              </AddResponse>
            </ListWrapper>
          )}
        />
      </StyledBody>
      <ButtonWrapper>
        <StyledCustomButton type="button" size="m" width={120} variant="primary" onClick={handleSubmit}>
          {isSubmitting ? <Spinner isLoading /> : I18n.t('Save')}
        </StyledCustomButton>
      </ButtonWrapper>
      <NewModal isVisible={editorIsVisible} onClose={closeEditor} title={I18n.t('Add additional instructions')}>
        {editorIsVisible ? (
          <Editor
            initialValue={initEditorState.body}
            onChange={onEditorBodyChange}
            maxWidth={false}
            toolbar={editorToolbar}
          />
        ) : (
          ''
        )}
        <ButtonWrapper>
          <StyledCustomButton type="button" size="m" width={120} variant="primary" onClick={closeEditor}>
            {I18n.t('Add')}
          </StyledCustomButton>
        </ButtonWrapper>
      </NewModal>
    </>
  );
};

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Languages = styled.div`
  padding-bottom: 7px;
  display: ${(props) => (props.isHidden ? 'none' : 'block')};

  & + & {
    margin-top: 15px;
  }
`;

const StyledBody = styled.div`
  overflow: auto;
  max-height: 550px;
  padding-bottom: 60px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 3rem;
`;

const AddResponse = styled.span`
  display: inline-block;
  color: ${(props) => props.theme.colors.lightBlue};
  font-size: 12px;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
`;

const ResponseTitle = styled(CustomTextArea)`
  width: 100%;
  margin-right: 10px;
`;

const ResponseTitleWrapper = styled.div`
  display: ${(props) => (props.isHidden ? 'none' : 'flex')};
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
`;

const AddLanguageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const StyledCustomButton = styled(CustomButton)`
  min-height: 44px;
  position: relative;
  justify-content: center;
`;

const LanguageCloseButton = styled.div`
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-top: 0;
  background-color: ${(props) => props.theme.colors.lightBlue1};
  margin-left: 20px;

  svg {
    width: 1rem;
    height: 1rem;
    path {
      fill: ${(props) => props.theme.colors.primary};
    }
  }
`;

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.dark};
  font-weight: bold;

  span {
    margin-left: 6px;
  }

  & + & {
    margin-left: 20px;
  }
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;
const EditorResponseTitle = styled(CustomTextArea)`
  width: 100%;
  margin-right: 10px;
  height: ${(props) => (props.isHidden ? '16rem' : '')};

  textarea {
    opacity: ${(props) => (props.isHidden ? '0' : '1')};
  }
`;

const EditorResponseTitleWrapper = styled.div`
  display: ${(props) => (props.isHidden ? 'none' : 'flex')};
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
`;

const EditorPreview = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 10px);
  height: 100%;
  overflow: auto;
`;

const EditorPreviewInner = styled.div`
  padding-top: 3rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
`;

export default AddCompetencyQuestionForm;
