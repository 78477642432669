import { I18n } from 'react-redux-i18n';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as InviteNotInvitedIcon } from '../../assets/icons/sidebar-invite-not-invited.svg';
import { ReactComponent as InviteInvitedIcon } from '../../assets/icons/sidebar-invite-invited.svg';
import { ReactComponent as RequestReportIcon } from '../../assets/icons/request-report.svg';

import * as actions from '../actionsList';
import getRatersByStatus from '../../utils/getRatersByStatus';
import { AP_COMPLETED_STATUS } from '../../constants/assessmentProjectStatuses';

const sessionsBulkActions = [
  // {
  //   name: actions.SESSION_INVITE_RATERS,
  //   label: 'Send invite to raters who have not yet completed',
  //   Icon: InviteNotCompletedIcon,
  //   isDisabled: (sessions) => {
  //     const allRaters = sessions.reduce((acc, session) => [...acc, ...session.raters, session.self], []);
  //     const { notCompleted } = getRatersByStatus(allRaters);
  //     return notCompleted.length === 0;
  //   },
  //   commandType: 0,
  // },
  {
    name: actions.SESSION_INVITE_RATERS,
    label: I18n.t('Send invite'),
    Icon: InviteNotInvitedIcon,
    isDisabled: (sessions) => {
      const allRaters = sessions.reduce((acc, session) => [...acc, ...session.raters, session.self], []);
      const { notInvited } = getRatersByStatus(allRaters);
      return notInvited.length === 0;
    },
    commandType: 1,
  },
  {
    name: actions.SESSION_INVITE_RATERS,
    label: I18n.t('Send reminder'),
    Icon: InviteInvitedIcon,
    isDisabled: (sessions, project) => {
      if (project?.status === AP_COMPLETED_STATUS) {
        return true;
      }
      const allRaters = sessions.reduce((acc, session) => [...acc, ...session.raters, session.self], []);
      const { invitedNotCompleted } = getRatersByStatus(allRaters);
      return invitedNotCompleted.length === 0;
    },
    commandType: 2,
  },
  {
    name: actions.SESSION_INVITE_SPECIFY_RATERS,
    label: I18n.t('Invite to specify own raters'),
    Icon: InviteNotInvitedIcon,
    isDisabled: (_sessions, project) => {
      return project?.status === AP_COMPLETED_STATUS;
    },
  },
  {
    name: actions.SESSION_REQUEST_REPORT,
    label: I18n.t('Request report(s)'),
    Icon: RequestReportIcon,
    isDisabled: (sessions) => !sessions.filter((session) => session.self.completeStatus).length,
  },
  {
    name: actions.DELETE_SESSIONS,
    label: I18n.t('Delete Session(s)'),
    Icon: DeleteIcon,
  },
];

export default sessionsBulkActions;
