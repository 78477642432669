import getColors from '../../../constants/categoryColors';
import handleActions from '../../immerHandleActions';
import * as actions from './actions';

const initialState = {
  initLoading: false,
  updateLoading: false,
  groups: [],
};

const reviewCompetenciesReducer = handleActions(
  {
    [actions.fetchCompetenciesStart]: (draft) => {
      return {
        ...draft,
        initLoading: true,
      };
    },
    [actions.fetchCompetenciesSuccess]: (draft, { payload: { categories, competencies } }) => {
      const categoriesColors = getColors(categories.length);
      const categoriesWithColors = categories.map((item, index) => ({ ...item, colors: categoriesColors[index] }));

      return {
        ...draft,
        groups: categoriesWithColors.map((category) => ({
          ...category,
          categories: competencies.filter((item) => item.categoryId === category.competencyID),
        })),
        initLoading: false,
      };
    },
    [actions.fetchCompetenciesFailure]: (draft) => {
      return {
        ...draft,
        initLoading: false,
      };
    },
    [actions.updateCompetenciesStart]: (draft) => {
      return {
        ...draft,
        updateLoading: true,
      };
    },
    [actions.updateCompetenciesSuccess]: (draft) => {
      return {
        ...draft,
        updateLoading: false,
      };
    },
    [actions.updateCompetenciesFailure]: (draft) => {
      return {
        ...draft,
        updateLoading: false,
      };
    },
    [actions.resetReviewCompetencies]: () => {
      return {
        ...initialState,
      };
    },
  },
  initialState,
);

export default reviewCompetenciesReducer;
