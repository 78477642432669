/* eslint-disable no-param-reassign */

import * as actions from './actions';
import handleActions from '../immerHandleActions';

import invitesColumns from '../../table/columns/invites';
import {
  TEXT_FILTER_TYPES,
  DATE_FILTER_TYPES,
  textFilterOptions,
  datesFilterOptions,
} from '../../constants/advancedFilterOptions';

const INVITES_FILTER_FIELDS = invitesColumns()
  .filter((item) => item.name !== 'actions' && item.name !== 'type')
  .map((column) => ({ name: column.name, type: column.type, options: column.options }));

const resolveFilterType = (type) => {
  if (type === 'select') return null;
  return type === 'date'
    ? datesFilterOptions.find((item) => item.name === DATE_FILTER_TYPES.THREE_DAYS)
    : textFilterOptions.find((item) => item.name === TEXT_FILTER_TYPES.CONTAINS);
};

const initialInputsState = INVITES_FILTER_FIELDS.map((fieldObj) => {
  const defaultValue = fieldObj.type === 'date' ? null : '';
  return {
    name: fieldObj.name,
    value: defaultValue,
    type: fieldObj.type,
    filterType: resolveFilterType(fieldObj.type),
    ...fieldObj,
  };
});

const tabs = [
  {
    name: 'all',
    label: 'All',
  },
  {
    name: '360',
    label: '360 Appraisal',
  },
  {
    name: 'assessments',
    label: 'IAssessments',
  },
];

const initialState = {
  invites: [],
  selectedInvites: [],
  tabs,
  pagesAvailable: 0,
  totalCount: 0,
  total360: 0,
  totalAssessments: 0,
  lastPageIndex: 0,
  activeTab: tabs[0],
  search: '',
  filter: {
    isApplied: false,
    dirty: false,
    appliedInputs: initialInputsState,
    inputs: initialInputsState,
  },
  lastTableParams: null,
};

const invitesReducer = handleActions(
  {
    // FILTERS PART
    [actions.setInvitesSearchValue]: (draft, { payload: { searchValue } }) => {
      draft.search = searchValue;
    },
    [actions.setInvitesFilterValue]: (draft, { payload: { fieldName, value } }) => {
      draft.filter.inputs = draft.filter.inputs.map((item) => (item.name === fieldName ? { ...item, value } : item));
      draft.filter.dirty = true;
    },
    [actions.setInvitesFilterType]: (draft, { payload: { fieldName, filterType } }) => {
      const initValue = fieldName.toLowerCase().includes('date')
        ? datesFilterOptions.find((item) => item.name === DATE_FILTER_TYPES.THREE_DAYS)
        : textFilterOptions.find((item) => item.name === TEXT_FILTER_TYPES.CONTAINS);
      draft.filter.inputs = draft.filter.inputs.map((item) =>
        item.name === fieldName ? { ...item, filterType: filterType === 'initial' ? initValue : filterType } : item,
      );
      draft.filter.dirty = true;
    },
    [actions.applyInvitesFilters]: (draft) => {
      draft.filter.dirty = false;
      draft.filter.isApplied = true;
      draft.filter.appliedInputs = draft.filter.inputs;
    },
    [actions.clearInvitesFilters]: (draft) => {
      draft.filter.dirty = true;
      draft.filter.inputs = initialInputsState;
    },
    [actions.deleteInvitesAppliedFilter]: (draft, { payload: { fieldName } }) => {
      draft.filter.appliedInputs = draft.filter.appliedInputs.map((input) =>
        input.name === fieldName ? { ...input, value: input.type === 'date' ? null : '' } : input,
      );
      draft.filter.inputs = draft.filter.inputs.map((input) =>
        input.name === fieldName ? { ...input, value: input.type === 'date' ? null : '' } : input,
      );
    },

    // END OF FILTERS, EVERYTHING ELSE
    [actions.fetchInvitesSuccess]: (draft, { payload: { data, tableParams, shouldReset } }) => {
      draft.invites = shouldReset ? data.data : [...draft.invites, ...data.data];
      draft.pagesAvailable = data.pagesAvailable;
      draft.totalCount = data.totalCount;
      draft.total360 = data.total360;
      draft.totalAssessments = data.totalAssessment;
      draft.lastPageIndex = data.pageNumber;
      draft.lastTableParams = tableParams;
    },
    [actions.setActiveInvitesTab]: (draft, { payload: { tab } }) => {
      draft.activeTab = tab;
    },
    [actions.setSelectedInvites]: (draft, { payload: { invites } }) => {
      draft.selectedInvites = invites;
    },
    [actions.deleteInvitesSuccess]: (draft, { payload: { deletedIds } }) => {
      draft.invites = draft.invites.filter((item) => !deletedIds.includes(item.inviteID));
    },
  },
  initialState,
);

export default invitesReducer;
