import { shuffle, sortBy } from 'lodash';
import getColors from '../../../constants/categoryColors';
import { DEFAULT_LANGUAGE, DEFAULT_SCALE_ID } from '../../../components/Settings/constants/ResponseScale';
import { DEFAULT, CUSTOM, RANDOM } from '../../../components/Settings/constants/QuestionsOrder';

export const makeUniqKey = (item) => `${item.competencyType}${item.competencyID}`;

export const groupQuestionsByCompetencyType = (questions) => {
  return questions.reduce(
    (acc, item) => {
      if (item.competencyType === 1) {
        acc.custom.push(item);
      } else {
        acc.standard.push(item);
      }

      return acc;
    },
    { standard: [], custom: [] },
  );
};

export const sortQuestionsByType = Object.freeze({
  [DEFAULT]: (questions) => {
    const { standard, custom } = groupQuestionsByCompetencyType(questions);

    return [...sortBy(standard, (item) => item.defaultSequence), ...sortBy(custom, (item) => item.itemID)];
  },
  [RANDOM]: (questions) => shuffle(questions),
  [CUSTOM]: (questions, customOrderSequence) => {
    if (customOrderSequence === null) {
      return questions;
    }

    const orderByIds = new Map();
    const otherQuestions = [];
    const reordered = [];

    customOrderSequence.split(',').forEach((item) => orderByIds.set(item, null));

    questions.forEach((item) => {
      const id = String(item.itemID);
      const customId = `@${item.itemID}`;

      if (orderByIds.has(customId)) {
        orderByIds.set(customId, item);
        return;
      }

      if (orderByIds.has(id)) {
        orderByIds.set(id, item);
        return;
      }

      otherQuestions.push(item);
    }, []);

    [...orderByIds.keys()].forEach((key) => {
      const value = orderByIds.get(key);

      if (!value) {
        return;
      }

      reordered.push(value);
    });

    return [...reordered, ...otherQuestions];
  },
});

const createCollectionOfLanguages = (languages) => {
  return languages.reduce((acc, item) => {
    return {
      ...acc,
      [item.langID]: item,
    };
  }, {});
};

export const getResponses = (scaleId, langId, scales, scalesDefault) => {
  let languages = {};
  let data = [];

  if (scaleId !== DEFAULT_SCALE_ID) {
    const scale = scales.find((item) => item.responseScaleID === scaleId);
    languages = createCollectionOfLanguages(scale.languages);
  } else {
    languages = createCollectionOfLanguages(scalesDefault.languages);
  }

  const isTranslation = Boolean(languages[langId]);

  if (isTranslation) {
    data = languages[langId].responses;
  } else {
    data = languages[DEFAULT_LANGUAGE] ? languages[DEFAULT_LANGUAGE].responses : [];
  }

  return {
    isTranslation,
    data,
  };
};

export const createGroupsAndQuestions = (competencyGroups, langID) => {
  const categoriesColors = getColors(competencyGroups.categories.length);
  const listCategories = competencyGroups.categories.reduce((acc, item, index) => {
    return {
      ...acc,
      [makeUniqKey(item)]: {
        ...item,
        colors: categoriesColors[index],
      },
    };
  }, {});

  const data = competencyGroups.competencies.reduce(
    (acc, item) => {
      const categoryUniqId = `${item.competencyType}${item.categoryId}`;

      if (!listCategories[categoryUniqId]) {
        return acc;
      }

      if (!acc.groups[categoryUniqId]) {
        acc.groups[categoryUniqId] = {
          ...listCategories[categoryUniqId],
          uniqId: categoryUniqId,
          categories: [],
        };
      }

      acc.groups[categoryUniqId].categories.push({ ...item, uniqId: makeUniqKey(item) });
      acc.questions.push(
        ...item.competencyItems.map((question) => {
          const translation = question.languages[langID] || '';
          const defaultTranslation = question.languages[DEFAULT_LANGUAGE] || '';

          return {
            ...question,
            competencyName: item.name,
            item: translation || defaultTranslation,
            isTranslation: Boolean(translation),
            category: { ...acc.groups[categoryUniqId] },
            parentUniqId: makeUniqKey(item),
          };
        }),
      );

      return acc;
    },
    { groups: {}, questions: [] },
  );

  return {
    groups: Object.values(data.groups),
    questions: data.questions,
  };
};
