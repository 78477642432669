import React, { useState } from 'react';
import { func, string, array } from 'prop-types';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import NewModal from '../../reusable/NewModal';
import CustomButton from '../../reusable/Buttons/Button';

import MultiSelectv2 from '../../reusable/Selects/MultiSelectCheckboxV2';

const BioDataCategoryModal = ({ onClose, allOptions, onSave, categoryName, categorySelector, selectedValues }) => {
  let options = [];
  const customOptions = selectedValues.length > 0 ? selectedValues : allOptions;
  const [selectedOptions, setSelectedOptions] = useState([...customOptions]);

  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };

  if (categorySelector === 'jobArea') {
    const concatedOptions = [];
    allOptions.forEach((categoryGroup) => {
      const currentOptions = categoryGroup.options.map((item) => ({ ...item, label: item.label, value: item.value }));
      concatedOptions.push(...currentOptions);
    });
    options = concatedOptions;
  } else {
    options = allOptions.map((item) => ({ ...item, label: item.label, value: item.value }));
  }

  return (
    <Modal isVisible onClose={onClose} title={I18n.t('Add values to category') + categoryName}>
      <Content>
        <MultiSelectv2 key="example_id" options={options} onChange={handleChange} value={selectedOptions} isSelectAll />
      </Content>
      <Footer>
        <StyledButton variant="secondary" handler={onClose}>
          {I18n.t('Cancel')}
        </StyledButton>
        <StyledButton
          variant="primary"
          handler={() => onSave(selectedOptions, categoryName, categorySelector)}
          disabled={!selectedOptions.length}
        >
          {I18n.t('Save')}
        </StyledButton>
      </Footer>
    </Modal>
  );
};

BioDataCategoryModal.propTypes = {
  onClose: func.isRequired,
  allOptions: array,
  selectedValues: array,
  onSave: func.isRequired,
  categoryName: string,
  categorySelector: string,
};

BioDataCategoryModal.defaultProps = {
  allOptions: [],
  selectedValues: [],
  categoryName: '',
  categorySelector: '',
};

const Modal = styled(NewModal)`
  width: 57rem;
  min-height: 41rem;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledButton = styled(CustomButton)`
  margin-left: 1rem;
  width: 12.5rem;
`;

export default BioDataCategoryModal;
