import { I18n } from 'react-redux-i18n';
import * as actions from '../actionsList';

const templatesTableActions = [
  {
    name: actions.CREATE_TEMPLATE,
    label: I18n.t('Add new template'),
  },
];

export default templatesTableActions;
