import React from 'react';
import { number } from 'prop-types';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';

const IntroStep = ({ totalCount, missingEmailCount }) => {
  return (
    <Wrapper>
      <span>
        {I18n.t('You have chosen a group of')} <Message color="mediumBlue">{totalCount}</Message>{' '}
        {I18n.t('respondents')}
      </span>
      <span>
        <Message color="red">{missingEmailCount}</Message> {I18n.t('have email address missing')}
      </span>
    </Wrapper>
  );
};

IntroStep.propTypes = {
  totalCount: number.isRequired,
  missingEmailCount: number.isRequired,
};

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.6rem;
  flex-direction: column;
`;

const Message = styled.span`
  color: ${(props) => (props.color ? props.theme.colors[props.color] : props.theme.colors.darkBlue2)};
`;

export default IntroStep;
