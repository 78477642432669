import React, { useState, useCallback, useMemo } from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { ReactComponent as EmptyIcon } from '../../assets/icons/empty_state.svg';

import SimplifiedTable from '../reusable/Tables/SimplifiedTable';
import BulkActionsSidebar from '../BulkActionsSidebar';
import respondentTestsColumns from '../../table/columns/respondentTests';
import ConfirmationModal from '../reusable/ConfirmationModal';
import RequestReportModal from '../Respondents/RequestReportModal';
import { selectRespondentTests } from '../../store/respondents/selectors';

import * as actions from '../../table/actionsList';
import respondentTestsBulkActions from '../../table/bulkActions/respondentTests';

import useBulkSidebarState from '../../hooks/useBulkSidebarState';
import useModals from '../../hooks/useModals';

const RespondentTests = ({ deleteTests }) => {
  const [selectedTests, setSelectedTests] = useState([]);
  const respondentToEdit = useSelector((state) => state.respondents.respondentToEdit);
  const tests = useSelector(selectRespondentTests);

  const onChange = useCallback((data) => {
    setSelectedTests(data);
  }, []);

  const modals = [actions.DELETE_COMPLETED_TEST, actions.REQUEST_REPORT];
  const { modalsState, openModal, closeModal } = useModals(modals);

  const onDeleteConfirm = () => {
    deleteTests(
      selectedTests.map((item) => item.resultID),
      () => {
        closeModal(actions.DELETE_COMPLETED_TEST);
      },
    );
  };

  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedTests });

  // BULK ACTIONS
  const bulkActionsHandlers = {
    [actions.REQUEST_REPORT]: () => openModal(actions.REQUEST_REPORT),
    [actions.DELETE_COMPLETED_TEST]: () => openModal(actions.DELETE_COMPLETED_TEST),
  };
  const bulkActions = useMemo(() => {
    return respondentTestsBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedTests) : item.isDisabled,
    }));
  }, [selectedTests]);

  // columns settings
  const columns = useMemo(() => {
    return respondentTestsColumns;
  }, []);

  if (!tests.length)
    return (
      <EmptyMessageWrapper>
        <EmptyIcon />
        <EmptyMessage>{I18n.t('Sorry, we could not find any tests')}</EmptyMessage>
      </EmptyMessageWrapper>
    );
  return (
    <RespondentTestsContainer>
      <BulkActionsSidebar actions={bulkActions} onClose={closeSidebar} isOpen={isSidebarOpen} />
      <SimplifiedTable
        columns={columns}
        data={tests}
        initialState={{ sortBy: [{ id: 'Date', desc: true }] }}
        onSelectChange={onChange}
        idAccessor="resultID"
      />
      <ConfirmationModal
        title={I18n.t('Test deletion')}
        onClose={() => closeModal(actions.DELETE_COMPLETED_TEST)}
        isVisible={modalsState[actions.DELETE_COMPLETED_TEST]}
        description={I18n.t('Are you sure you want to delete selected test?')}
        onConfirm={onDeleteConfirm}
        caution
      />
      {modalsState[actions.REQUEST_REPORT] && (
        <RequestReportModal
          onClose={() => closeModal(actions.REQUEST_REPORT)}
          respondents={[{ ...respondentToEdit, doneTests: selectedTests }]}
        />
      )}
    </RespondentTestsContainer>
  );
};

RespondentTests.propTypes = {
  deleteTests: func.isRequired,
};

const RespondentTestsContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

const EmptyMessageWrapper = styled.div`
  position: absolute;
  min-width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EmptyMessage = styled.p`
  margin: 15px 0 0 0;
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
`;

export default RespondentTests;
