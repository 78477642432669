import { createAction } from 'redux-actions';
import qs from 'qs';

import defaultTableState from '../../constants/defaultTableState';
import apiInstance2 from '../../services/apiService';

export const fetchNotificationsStart = createAction('FETCH_NOTIFICATIONS_START');
export const fetchNotificationsSuccess = createAction(
  'FETCH_NOTIFICATIONS_SUCCESS',
  (data, tableState, shouldReset) => ({
    data,
    tableState,
    shouldReset,
  }),
);
export const fetchNotificationsFailure = createAction('FETCH_NOTIFICATIONS_FAILURE');

export const resetNotificationsState = createAction('RESET_NOTIFICATIONS_STATE');

export const fetchNotifications = (
  tableState = defaultTableState,
  { callback, shouldReset, notificationType },
) => async (dispatch) => {
  try {
    dispatch(fetchNotificationsStart());
    const queryString = qs.stringify(tableState.pagination);
    const response = await apiInstance2.get(
      `/api/v2/Notifications?notificationType=${notificationType}&${queryString}`,
    );
    if (response.status === 200) {
      dispatch(fetchNotificationsSuccess(response.data, tableState, shouldReset));
      if (callback) callback();
    }
  } catch (e) {
    dispatch(fetchNotificationsFailure(e));
    if (callback) callback(e);
  }
};
