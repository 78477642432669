import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { push } from 'redux-first-history';

import { I18n } from 'react-redux-i18n';
import accountTabs from './accountTabs';
import { changeEmailReceivingState } from '../../../store/user/actions';

import PageWrapper from '../../PageWrapper';
import TableTabs from '../../reusable/TableTabs';
import Checkbox from '../../reusable/FormComponents/Checkbox';

import ChangePasswordModal from './ChangePassword';
import ChangePinModal from './ChangePin';
import ChangeReportFormatModal from './ChangeReportFormat';
import useModals from '../../../hooks/useModals';

const CHANGE_PASSWORD = 'changePassword';
const CHANGE_PIN = 'changePin';
const CHANGE_REPORT_FORMAT = 'changeReportFormat';

const reportFormatOptions = [
  { value: 'pdf', label: 'PDF' },
  { value: 'Doc', label: 'DOC' },
];

const GeneralInfo = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  const currentReportFormatOption = reportFormatOptions.find((el) => el.value === (user?.reportFormat || 'Doc'));

  const goBackToSettings = () => dispatch(push('/settings'));
  const onTabClick = (tab) => dispatch(push(tab.path));
  const [isCheckboxBlocked, setCheckboxBlockedState] = useState(false);

  const modals = [CHANGE_PASSWORD, CHANGE_PIN, CHANGE_REPORT_FORMAT];
  const { modalsState, openModal, closeModal } = useModals(modals);

  const onEmailReceivingStateChange = (state) => {
    setCheckboxBlockedState(true);
    dispatch(
      changeEmailReceivingState(state, () => {
        setTimeout(() => {
          setCheckboxBlockedState(false);
        }, 1000);
      }),
    );
  };

  const tabs =
    user?.userType === 3
      ? accountTabs.filter((item) => item.name === 'general' || item.name === 'assessmentsUsage')
      : accountTabs;

  return (
    <PageWrapper title={I18n.t('Account')} backButtonHandler={goBackToSettings}>
      <TableTabs tabs={tabs} activeTab={accountTabs[0]} clickHandler={onTabClick} />
      <PseudoInput>
        <Label>{I18n.t('User name')}</Label>
        <Value>{user && user.userName}</Value>
      </PseudoInput>
      <PseudoInput>
        <Label>{I18n.t('Password')}</Label>
        <Value>************</Value>
        <Button onClick={() => openModal(CHANGE_PASSWORD)}>{I18n.t('Change password')}</Button>
      </PseudoInput>
      <PseudoInput>
        <Label>{I18n.t('Pin')}</Label>
        <Value>{user && user.pin}</Value>
        <Button onClick={() => openModal(CHANGE_PIN)}>{I18n.t('Change pin')}</Button>
      </PseudoInput>
      {user && (
        <PseudoInput>
          <Row
            onClick={() => {
              if (isCheckboxBlocked) return;
              onEmailReceivingStateChange(!user.receiveSystemEmailNotifications);
            }}
          >
            <StyledCheckbox isChecked={user.receiveSystemEmailNotifications} />
            <Value>{I18n.t('Receive email notifications')}</Value>
          </Row>
        </PseudoInput>
      )}
      <PseudoInput>
        <Label>{I18n.t('Report format')}</Label>
        <Value>{currentReportFormatOption.label}</Value>
        <Button onClick={() => openModal(CHANGE_REPORT_FORMAT)}>{I18n.t('Change report format')}</Button>
      </PseudoInput>

      {modalsState[CHANGE_PASSWORD] && <ChangePasswordModal onClose={() => closeModal(CHANGE_PASSWORD)} />}
      {modalsState[CHANGE_PIN] && <ChangePinModal onClose={() => closeModal(CHANGE_PIN)} />}
      {modalsState[CHANGE_REPORT_FORMAT] && (
        <ChangeReportFormatModal
          currentOption={currentReportFormatOption}
          options={reportFormatOptions}
          onClose={() => closeModal(CHANGE_REPORT_FORMAT)}
        />
      )}
    </PageWrapper>
  );
};

GeneralInfo.propTypes = {};

const Label = styled.span`
  font-size: 1rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.mediumBlue};
`;

const Value = styled.span`
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.darkBlue2};
  font-weight: 600;
`;

const Button = styled.span`
  color: ${(props) => props.theme.colors.lightBlue};
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: bold;
  text-decoration: underline;
  transition: 0.2s all;
  cursor: default;
  margin-top: 0.5rem;

  :hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.lightBlueHover};
  }
`;

const PseudoInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.4rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  transition: 0.2s all;

  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 1rem;
`;

export default GeneralInfo;
