import React from 'react';
import { func, number, bool } from 'prop-types';
import styled, { css } from 'styled-components';
import { I18n } from 'react-redux-i18n';
import projectList from './projectList';
import { ReactComponent as AppraisalLogo } from '../../../assets/icons/360p.svg';
import { ReactComponent as AssessmentLogo } from '../../../assets/icons/assesment.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right-thin.svg';

import ProjectModalFooter from './ProjectModalFooter';
import Button from '../../reusable/Buttons/Button';

const logos = {
  '360 Appraisal': AppraisalLogo,
  Assessment: AssessmentLogo,
};

const ChooseProjectType = ({ projectType, setProjectType, goToNextStep, isAssessmentProjectDisabled }) => {
  const transformedProjectsList = projectList.map((item) =>
    item.name === 'Assessment' ? { ...item, isDisabled: isAssessmentProjectDisabled } : item,
  );
  return (
    <>
      <CardList>
        {transformedProjectsList.map((item, index) => {
          const Logo = logos[item.name];

          return (
            <Card
              key={item.name}
              selected={index === projectType}
              onClick={() => {
                if (item.isDisabled) return;
                setProjectType(index);
              }}
              isDisabled={item.isDisabled}
            >
              <LogoWrapper>
                <Logo />
              </LogoWrapper>
              <Title>{I18n.t(item.name)}</Title>
              <Info>{I18n.t(item.description)}</Info>
            </Card>
          );
        })}
      </CardList>
      <ProjectModalFooter>
        <StyledButton type="button" onClick={goToNextStep} disabled={projectType === -1}>
          {I18n.t('Continue')}
          <ArrowRight />
        </StyledButton>
      </ProjectModalFooter>
    </>
  );
};

ChooseProjectType.propTypes = {
  projectType: number.isRequired,
  setProjectType: func.isRequired,
  goToNextStep: func.isRequired,
  isAssessmentProjectDisabled: bool.isRequired,
};

const CardList = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;
const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3.2rem;
`;
const Title = styled.div`
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.8rem;
  color: #375563;
  margin-bottom: 0.8rem;
`;
const Card = styled.div`
  display: block;
  width: 100%;
  text-align: left;
  padding: 4rem 2.4rem;
  border: 2px solid #cdcdcd;
  margin: 0 20px;
  border-radius: 8px;
  position: relative;
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};

  ${(props) =>
    !props.isDisabled &&
    css`
      &:hover {
        cursor: pointer;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.25), 0 8px 30px rgba(55, 85, 99, 0.3);

        path,
        ellipse {
          stroke: #1baae1;
          fill: #1baae1;
        }
      }
    `}

  ${(props) =>
    props.selected &&
    css`
      border: 2px solid #1baae1;

      ${Title} {
        color: #008ac0;
      }

      path,
      ellipse {
        stroke: #1baae1;
        fill: #1baae1;
      }
    `}
`;

const Info = styled.div`
  font-weight: 500;
  font-size: ${(props) => props.theme.fontSizes.small};
  line-height: 2rem;
  color: #295368;
`;

const StyledButton = styled(Button)`
  min-width: 14rem;
  height: 4.6rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-transform: uppercase;
`;

export default ChooseProjectType;
