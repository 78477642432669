import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { push } from 'redux-first-history';
import { number, oneOfType, string } from 'prop-types';
import { I18n } from 'react-redux-i18n';
import copy from 'copy-to-clipboard';
import PageWrapperForProjects from '../../PageWrapperForProjects';
import Table from '../../reusable/Tables/Table';
import {
  fetchAssessmentProjectRespondents,
  setAssessmentProjectSelectedRespondents,
  setAssessmentProjectSearchValue,
  fetchAssessmentProjectById,
  setActiveProject,
  assessmentProjectInviteAllRespondents,
  assessmentProjectInviteRespondents,
  assessmentProjectDeleteRespondents,
  resetAssessmentProjectData,
  assessmentProjectHashCheck,
  endAssessmentProject,
  sendAssessmentProjectReminder,
} from '../../../store/projects/actions';
import { fetchUser } from '../../../store/user/actions';
import assessmentProjectRespondentsColumns from '../../../table/columns/assessment-project-respondents';
import AssessmentProjectHeader from './AssessmentProjectHeader';
import AdvancedFilter from '../../reusable/AdvancedFilter';
import BulkActionsSidebar from '../../BulkActionsSidebar';
import useBulkSidebarState from '../../../hooks/useBulkSidebarState';
import assessmentProjectRespondentsBulkActions from '../../../table/bulkActions/assessmentProjectRespondents';
import breadcrumbs from '../assessment-projects.breadcrumbs';
import * as actions from '../../../table/actionsList';
import useModals from '../../../hooks/useModals';
import ConfirmationModal from '../../reusable/ConfirmationModal';
import ImportRespondentsModal from '../../Respondents/ImportRespondents';
import AssessmentProjectAddRespondentsModal from './AssessmentProjectAddRespondents';
import AssessmentProjectTitleRow from './AssessmentProjectTitleRow';
import NoProjectModal from './NoProjectModal';
import CustomButton from '../../reusable/Buttons/Button';
import AssessmentProjectStatus from './components/AssessmentProjectStatus';

import {
  downloadZippedAssessmentProjectReports,
  downloadAssessmentProjectRespondentReports,
  downloadAssessmentProjectInvites,
} from '../../Reports/reports.utils';
import { ReactComponent as ProjectsIcon } from '../../../assets/icons/projects.svg';
import { ReactComponent as EmptyTableIcon } from '../../../assets/icons/empty-state-grey.svg';
import { ReactComponent as NoResultsIcon } from '../../../assets/icons/empty_state.svg';
import { createCopiedNotification } from '../../Invites/Invites';
import { AP_COMPLETED_STATUS } from '../../../constants/assessmentProjectStatuses';

const AssessmentProject = ({ projectId }) => {
  const dispatch = useDispatch();

  const respondents = useSelector((state) => state.projects.assessmentProject.respondents);
  const selectedRespondents = useSelector((state) => state.projects.assessmentProject.selectedRespondents);
  const pageCount = useSelector((state) => state.projects.assessmentProject.pagesAvailable);
  const totalCount = useSelector((state) => state.projects.assessmentProject.totalCount);
  const searchValue = useSelector((state) => state.projects.assessmentProject.search);
  const lastPageIndex = useSelector((state) => state.projects.assessmentProject.lastPageIndex);
  const activeProjectInView = useSelector((state) => state.projects.activeProjectInView);
  const [shouldResetPage, setShouldResetPageStatus] = useState(false);
  const [isTableLoading, setTableLoadingStatus] = useState(true);
  const [isInfoLoading, setInfoLoadingStatus] = useState(true);

  // Controlling bulk actions sidebar state
  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedRespondents });

  const modals = [
    actions.ASSESSMENT_PROJECT_ADD_RESPONDENT,
    actions.ASSESSMENT_PROJECT_IMPORT_RESPONDENTS,
    actions.ASSESSMENT_PROJECT_DELETE_RESPONDENTS,
    actions.ASSESSMENT_PROJECT_INVITE_ALL_RESPONDENTS,
    actions.ASSESSMENT_PROJECT_INVITE_RESPONDENTS,
    actions.NO_PROJECT_MODAL,
    actions.END_ASSESSMENT_PROJECT,
    actions.ASSESSMENT_PROJECT_SEND_REMINDERS,
  ];

  const { modalsState, openModal, closeModal } = useModals(modals);

  const fetchData = useCallback(
    ({ tableData, shouldReset, showSpinner, tableCallback }) => {
      if (!projectId) return;
      if (showSpinner) setTableLoadingStatus(true);
      const args = [
        tableData,
        {
          callback: () => {
            setTableLoadingStatus(false);
            tableCallback();
          },
          shouldReset,
          projectId,
        },
      ];

      dispatch(fetchAssessmentProjectRespondents(...args));
    },
    [projectId],
  );

  const columns = useMemo(() => {
    return assessmentProjectRespondentsColumns;
  }, []);

  const fetchProject = useCallback(() => {
    if (projectId)
      dispatch(
        fetchAssessmentProjectById(projectId, (e) => {
          if (e && e.message.includes('404')) openModal(actions.NO_PROJECT_MODAL);
          setInfoLoadingStatus(false);
        }),
      );
  }, [projectId]);

  useEffect(() => {
    fetchProject();
    return () => dispatch(setActiveProject(null));
  }, []);

  useEffect(() => {
    if (!projectId) return;
    const intervalId = setInterval(() => {
      dispatch(
        assessmentProjectHashCheck(projectId, (err) => {
          if (!err) {
            fetchProject();
            setShouldResetPageStatus(true);
          }
        }),
      );
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const onSelectChange = useCallback((data) => {
    dispatch(setAssessmentProjectSelectedRespondents(data));
  }, []);

  const memoizedSetAssessmentProjectSearchValue = useCallback(
    (val) => dispatch(setAssessmentProjectSearchValue(val)),
    [],
  );

  const inviteAllUninvitedRespondents = () =>
    dispatch(
      assessmentProjectInviteAllRespondents(projectId, () => {
        closeModal(actions.ASSESSMENT_PROJECT_INVITE_ALL_RESPONDENTS);
        setShouldResetPageStatus(true);
        fetchProject();
      }),
    );

  const inviteRespondents = () =>
    dispatch(
      assessmentProjectInviteRespondents(
        projectId,
        selectedRespondents.map((item) => item.respondentID),
        () => {
          closeModal(actions.ASSESSMENT_PROJECT_INVITE_RESPONDENTS);
          setShouldResetPageStatus(true);
          fetchProject();
        },
      ),
    );

  const removeRespondents = () => {
    dispatch(
      assessmentProjectDeleteRespondents(
        projectId,
        selectedRespondents.map((resp) => resp.id),
        () => {
          closeModal(actions.ASSESSMENT_PROJECT_DELETE_RESPONDENTS);
          dispatch(fetchUser());
          fetchProject();
          setShouldResetPageStatus(true);
        },
      ),
    );
  };

  const completeProject = () => {
    dispatch(
      endAssessmentProject(projectId, (err) => {
        if (!err) dispatch(push('/projects'));
      }),
    );
  };
  const sendReminders = () => {
    const respondentsToInvite = selectedRespondents.filter((r) => !r.inviteID).map((item) => item.respondentID);
    if (respondentsToInvite.length > 0) {
      dispatch(
        assessmentProjectInviteRespondents(projectId, respondentsToInvite, () => {
          closeModal(actions.ASSESSMENT_PROJECT_SEND_REMINDERS);
          setShouldResetPageStatus(true);
          fetchProject();
        }),
      );
    }

    const respondentsToRemind = selectedRespondents.filter((r) => r.inviteID).map((item) => item.inviteID);
    if (respondentsToRemind.length > 0) {
      dispatch(
        sendAssessmentProjectReminder(projectId, respondentsToRemind, () => {
          closeModal(actions.ASSESSMENT_PROJECT_SEND_REMINDERS);
        }),
      );
    }
  };

  const goToSettings = () => dispatch(push(`/projects/assessment/${projectId}/settings`));

  const tilesActionsHandlers = {
    [actions.ASSESSMENT_PROJECT_INVITE_ALL_RESPONDENTS]: () =>
      openModal(actions.ASSESSMENT_PROJECT_INVITE_ALL_RESPONDENTS),
    [actions.ASSESSMENT_PROJECT_DOWNLOAD_REPORTS]: () => downloadZippedAssessmentProjectReports(projectId),
    [actions.ASSESSMENT_PROJECT_DOWNLOAD_INVITES_CSV]: () => downloadAssessmentProjectInvites(activeProjectInView),
  };

  const bulkActionsHandlers = {
    [actions.ASSESSMENT_PROJECT_COPY_INVITE_LINK]: () => {
      copy(selectedRespondents[0].invitationLink);
      createCopiedNotification();
    },
    [actions.ASSESSMENT_PROJECT_DOWNLOAD_RESPONDENT_REPORTS]: () => {
      downloadAssessmentProjectRespondentReports(projectId, selectedRespondents[0].respondentID);
    },
    [actions.ASSESSMENT_PROJECT_GO_TO_RESPONDENT_PROFILE]: () =>
      window.open(
        `${window._env_.REACT_APP_MANAGEMENT_URL}/respondents/${selectedRespondents[0].respondentID}`,
        '_blank',
      ),
  };

  const bulkActions = useMemo(() => {
    return assessmentProjectRespondentsBulkActions(activeProjectInView).map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name] || (() => openModal(item.name)),
      hint: typeof item.hint === 'function' ? item.hint(selectedRespondents) : item.hint,
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedRespondents) : item.isDisabled,
    }));
  }, [selectedRespondents, activeProjectInView]);

  // initial table state
  const initialState = useMemo(() => ({ sortBy: [{ id: 'Invited', desc: true }] }), []);

  const onBackButtonClick = () => {
    dispatch(resetAssessmentProjectData());
    dispatch(push('/projects'));
  };

  const title = activeProjectInView ? activeProjectInView.name : I18n.t('Assessment project');
  const isProjectCompleted = Number(activeProjectInView?.status) === AP_COMPLETED_STATUS;

  const Buttons = () => (
    <ButtonsWrapper>
      <StyledButton
        size="s"
        disabled={isProjectCompleted}
        onClick={() => openModal(actions.ASSESSMENT_PROJECT_ADD_RESPONDENT)}
      >
        {I18n.t('Add respondents')}
      </StyledButton>
      <StyledButton
        size="s"
        disabled={isProjectCompleted}
        onClick={() => openModal(actions.ASSESSMENT_PROJECT_IMPORT_RESPONDENTS)}
      >
        {I18n.t('Import')}
      </StyledButton>
    </ButtonsWrapper>
  );

  const EmptyTableMessage = () => (
    <EmptyMessageTableWrapper>
      {searchValue ? <StyledNoResultsIcon /> : <EmptyTableIcon />}
      {searchValue ? (
        <EmptyMessageTitle minimize={searchValue}>
          {I18n.t("Sorry, we couldn't find any respondents")}
        </EmptyMessageTitle>
      ) : (
        <NoRespondentsMessage>
          <div>{I18n.t('There are no respondents added')}</div>
          <div>{I18n.t('You may start adding respondents via add or import respondents actions')}</div>
        </NoRespondentsMessage>
      )}
    </EmptyMessageTableWrapper>
  );

  const projectCost =
    activeProjectInView?.status === AP_COMPLETED_STATUS
      ? activeProjectInView?.realProjectCost
      : activeProjectInView?.totalCreditsCost;

  return (
    <PageWrapperForProjects
      breadcrumbs={breadcrumbs(activeProjectInView)}
      title={
        <TitleComponent>
          <ProjectsIcon />
          <span>{title}</span>
          <AssessmentProjectStatus status={Number(activeProjectInView?.status)} />
        </TitleComponent>
      }
      backButtonHandler={onBackButtonClick}
      customButton={
        <AssessmentProjectTitleRow
          project={activeProjectInView}
          onGearClick={goToSettings}
          onEndProject={() => openModal(actions.END_ASSESSMENT_PROJECT)}
        />
      }
    >
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      {!!activeProjectInView && (
        <AssessmentProjectHeader
          project={activeProjectInView}
          isLoading={isInfoLoading}
          onInvite={tilesActionsHandlers[actions.ASSESSMENT_PROJECT_INVITE_ALL_RESPONDENTS]}
          onDownload={tilesActionsHandlers[actions.ASSESSMENT_PROJECT_DOWNLOAD_REPORTS]}
          onInvitesDownload={tilesActionsHandlers[actions.ASSESSMENT_PROJECT_DOWNLOAD_INVITES_CSV]}
        />
      )}
      <TableHeaderRow>
        <RowWrapper>
          <TableName>
            {I18n.t('Respondents')} ({totalCount})
          </TableName>
          <ProjectCost>
            <span>
              {activeProjectInView?.status === AP_COMPLETED_STATUS
                ? I18n.t('Project cost')
                : I18n.t('Estimated project cost')}
              :
            </span>
            <span>
              {projectCost} {I18n.t('credits')}
            </span>
          </ProjectCost>
        </RowWrapper>
        <Buttons />
      </TableHeaderRow>
      <AdvancedFilter
        isFilterDisabled
        searchProps={{
          searchValue,
          onSearchChange: memoizedSetAssessmentProjectSearchValue,
        }}
      />
      <StyledTable
        isLoading={isTableLoading}
        rowEqualityFunc={(prevItem, nextItem) => prevItem.status === nextItem.status}
        data={respondents}
        columns={columns}
        onFetchData={fetchData}
        pageCount={pageCount}
        totalCount={totalCount}
        idAccessor="id"
        entityName={I18n.t('respondent')}
        initialState={initialState}
        searchValue={searchValue}
        lastPageIndex={lastPageIndex}
        onSelectChange={onSelectChange}
        shouldResetPage={shouldResetPage}
        setShouldReset={setShouldResetPageStatus}
        interceptCheckboxClick
        customEmptyMessage={<EmptyTableMessage />}
        scrollContainerHeight="85%"
        showSpinnerDuringInfiniteLoading
      />
      <ConfirmationModal
        isVisible={modalsState[actions.ASSESSMENT_PROJECT_INVITE_ALL_RESPONDENTS]}
        title={I18n.t('Invite respondents')}
        description={`${I18n.t('You are about to send an invite to all respondents in the project')}. ${I18n.t(
          'Press “Yes” to proceed with the action',
        )}.`}
        onClose={() => closeModal(actions.ASSESSMENT_PROJECT_INVITE_ALL_RESPONDENTS)}
        onConfirm={inviteAllUninvitedRespondents}
        caution
      />

      <ConfirmationModal
        isVisible={modalsState[actions.ASSESSMENT_PROJECT_INVITE_RESPONDENTS]}
        title={I18n.t('Invite respondent(s)')}
        description={`${I18n.t('You are about to send invites to selected respondents')}. ${I18n.t(
          'Press “Yes” to continue',
        )}.`}
        onClose={() => closeModal(actions.ASSESSMENT_PROJECT_INVITE_RESPONDENTS)}
        onConfirm={inviteRespondents}
        caution
      />

      <ConfirmationModal
        isVisible={modalsState[actions.ASSESSMENT_PROJECT_DELETE_RESPONDENTS]}
        title={I18n.t('Remove respondents')}
        description={I18n.t('Are you sure you want to remove selected respondents?')}
        onClose={() => closeModal(actions.ASSESSMENT_PROJECT_DELETE_RESPONDENTS)}
        onConfirm={removeRespondents}
        caution
      />

      <ConfirmationModal
        isVisible={modalsState[actions.END_ASSESSMENT_PROJECT]}
        title={I18n.t('End project')}
        description={`${I18n.t('You are about to end a project')} ${I18n.t(
          'All active invites will be deactivated and unused credits released',
        )} ${I18n.t('It will be not possible to revert the action')}`}
        onClose={() => closeModal(actions.END_ASSESSMENT_PROJECT)}
        onConfirm={completeProject}
        caution
        confirmText={I18n.t('Continue')}
        cancelText={I18n.t('Cancel')}
      />

      <ConfirmationModal
        isVisible={modalsState[actions.ASSESSMENT_PROJECT_SEND_REMINDERS]}
        title={I18n.t('Send reminder')}
        description={`${I18n.t('You are about to send reminder to the selected respondents')}. ${I18n.t(
          'Press “Yes” to continue',
        )}.`}
        onClose={() => closeModal(actions.ASSESSMENT_PROJECT_SEND_REMINDERS)}
        onConfirm={sendReminders}
        caution
        confirmText={I18n.t('Yes')}
        cancelText={I18n.t('Cancel')}
      />

      {modalsState[actions.ASSESSMENT_PROJECT_ADD_RESPONDENT] && (
        <AssessmentProjectAddRespondentsModal
          onClose={() => closeModal(actions.ASSESSMENT_PROJECT_ADD_RESPONDENT)}
          projectId={projectId}
          onSuccess={() => {
            setShouldResetPageStatus(true);
            fetchProject();
          }}
          costPerRespondent={activeProjectInView?.creditsPerRespondent}
        />
      )}
      {modalsState[actions.ASSESSMENT_PROJECT_IMPORT_RESPONDENTS] && (
        <ImportRespondentsModal
          onClose={() => closeModal(actions.ASSESSMENT_PROJECT_IMPORT_RESPONDENTS)}
          onSuccess={() => {
            setShouldResetPageStatus(true);
            fetchProject();
          }}
          isAssessmentProject
          projectId={projectId}
        />
      )}
      {modalsState[actions.NO_PROJECT_MODAL] && <NoProjectModal onClose={() => closeModal(actions.NO_PROJECT_MODAL)} />}
    </PageWrapperForProjects>
  );
};

AssessmentProject.propTypes = {
  projectId: oneOfType([string, number]).isRequired,
};

const TableHeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const TableName = styled.h3`
  font-size: 20px;
  color: ${(props) => props.theme.colors.darkBlue2};
  font-weight: bold;
  margin-right: 1.6rem;
`;

const ProjectCost = styled.div`
  color: ${(props) => props.theme.colors.darkBlue2};
  font-size: 14px;
  font-weight: 500;

  span:last-of-type {
    font-weight: 600;
    margin-left: 0.5rem;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(CustomButton)`
  margin-left: 1rem;
`;

const StyledTable = styled(Table)`
  tr:hover {
    & > div div {
      background: rgba(241, 241, 241, 0.1);
    }
  }
`;
const TitleComponent = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 0.5rem;
  }
`;

const EmptyMessageTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
`;

const EmptyMessageTitle = styled.h5`
  font-size: ${(props) => (props.minimize ? '1.8rem' : '2.2rem')};
  font-weight: bold;
  margin: 1.5rem 0;
`;

const StyledNoResultsIcon = styled(NoResultsIcon)`
  width: 8rem;
  height: 8rem;
`;

const NoRespondentsMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div:first-child {
    color: rgba(0, 0, 0, 0.4);
    font-size: 2rem;
    font-weight: bold;
    margin: 1.6rem 0;
  }

  div:last-child {
    color: rgba(0, 0, 0, 0.4);
    font-size: ${(props) => props.theme.fontSizes.normal};
    line-height: 2.4rem;
    font-weight: 400;
  }
`;

export default AssessmentProject;
