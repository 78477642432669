import React from 'react';
import { Bar, BarChart, Label, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';

const HistogramView = ({ norms }) => {
  return (
    <StyledHistogramWrapper>
      {norms
        .map((norm) => {
          if (!norm.frequency) {
            return;
          }
          const frequency = norm.frequency.split(';');
          const maxDomain = 1.05 * Math.max(...frequency);
          const data = frequency.map((f, index) => ({ name: `${I18n.t('Raw Score')} ${index}`, barValue: f }));

          return (
            <HistogramWrapper key={norm.scaleName}>
              <PreviewTitle>{norm.scaleName}</PreviewTitle>
              <ResponsiveContainer width="100%" aspect={1.44}>
                <BarChart data={data}>
                  <Bar dataKey="barValue" fill="#40c4ff" background={{ fill: '#eff3F9' }} />
                  <XAxis stroke="#eff3F9" tick={{ stroke: '#295368' }}>
                    <Label value={I18n.t('RAW SCORE')} offset={-5} position="insideBottom" fill="#295368" />
                  </XAxis>
                  <YAxis stroke="#eff3F9" tick={{ stroke: '#295368' }} domain={[0, maxDomain]} scale="linear">
                    <Label value={I18n.t('FREQUENCY')} angle={-90} fill="#295368" position="insideBottomLeft" />
                  </YAxis>
                </BarChart>
              </ResponsiveContainer>
            </HistogramWrapper>
          );
        })
        .filter((item) => item)}
    </StyledHistogramWrapper>
  );
};

const HistogramWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  margin-right: 24px;
  margin-bottom: 24px;
  padding: 16px 12px;
  max-width: calc(33% - 24px);
  width: 100%;
`;

const StyledHistogramWrapper = styled.div`
  position: relative;
  overflow: auto;
  overflow-x: hidden;
  padding: 2px;
  display: flex;
  flex-wrap: wrap;
  max-width: calc(100vw - 200px);
  max-height: 100vh;
  height: auto;
`;

const PreviewTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #295368;
`;

export default HistogramView;
