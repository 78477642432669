import { I18n } from 'react-redux-i18n';
import * as actions from '../actionsList';

const idealProfilesTableActions = [
  {
    name: actions.ADD_NEW_IDEAL_PROFILE,
    label: I18n.t('Add ideal profile'),
  },
];

export default idealProfilesTableActions;
