import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import RatersTableWrapper from '../components/RatersTableWrapper';
import SimplifiedTable from '../../reusable/Tables/SimplifiedTable';
import apiInstance2 from '../../../services/apiService';
import Spinner from '../../reusable/Spinner';
import BulkActionsSidebar from '../../BulkActionsSidebar';

import useBulkSidebarState from '../../../hooks/useBulkSidebarState';
import ratersReportsColumns from '../../../table/columns/ratersReports';
import reportsBulkActions from '../../../table/bulkActions/reports';

import * as actions from '../../../table/actionsList';
import useModals from '../../../hooks/useModals';
import ConfirmationModal from '../../reusable/ConfirmationModal';

import { deleteReports, markReportsAsViewed } from '../../../store/reports/actions';
import downloadZippedArchiveFromReports from '../../Reports/reports.utils';
import TableEmptyState from '../../reusable/TableEmptyState';

const RatersReports = ({ projectId, sessionId }) => {
  const dispatch = useDispatch();
  const [reports, setReports] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const [selectedReports, setSelectedReports] = useState([]);

  useEffect(() => {
    setLoading(true);
    apiInstance2
      .get(`/api/v2/360/projects/${projectId}/sessions/${sessionId}/reports`)
      .then((response) => {
        setLoading(false);
        setReports(response.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedReports });

  const modals = [actions.DELETE_REPORT];
  const { modalsState, openModal, closeModal } = useModals(modals);

  const onDownloadClick = (reports) => {
    const items = selectedReports.length ? selectedReports : reports;
    if (!items.length) return;
    if (items.length > 1) {
      downloadZippedArchiveFromReports(
        items.filter((item) => item.reportType !== 1),
        () => dispatch(markReportsAsViewed(items.map((item) => item.notificationID))),
      );
    } else {
      items.forEach((report) => {
        const link = document.createElement('a');
        link.href = report.downloadLink;
        link.download = report.downloadLink.substr(report.downloadLink.lastIndexOf('/') + 1);
        link.click();
      });
      dispatch(markReportsAsViewed(items.map((item) => item.notificationID)));
    }
  };

  const openMultipleReports = (reports) => {
    const items = selectedReports.length ? selectedReports : reports;
    if (!items.length) return;
    items.forEach((report) => {
      window.open(report.downloadLink, '_blank');
    });
  };

  const onDeleteConfirm = () => {
    const data = selectedReports.map((report) => ({
      reportType: report.reportType,
      notificationID: report.notificationID || null,
      requestID: report.reportRequestID || null,
    }));
    dispatch(
      deleteReports(data, () => {
        closeModal(actions.DELETE_REPORT);
        setReports((prev) => prev.filter((report) => !data.find((item) => item.requestID === report.reportRequestID)));
      }),
    );
  };
  // BULK ACTIONS
  const bulkActionsHandlers = {
    [actions.OPEN_REPORT]: openMultipleReports,
    [actions.DOWNLOAD_REPORT]: onDownloadClick,
    [actions.DELETE_REPORT]: () => openModal(actions.DELETE_REPORT),
  };
  const bulkActions = useMemo(() => {
    return reportsBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedReports) : item.isDisabled,
    }));
  }, [selectedReports]);

  return (
    <RatersTableWrapper projectId={projectId} sessionId={sessionId} activeTab={3}>
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      <TableWrapper>
        <SimplifiedTable
          columns={ratersReportsColumns}
          data={reports}
          initialState={{ sortBy: [{ id: 'Creation Date', desc: true }] }}
          onSelectChange={setSelectedReports}
          idAccessor="reportID"
          entityName="report"
          bottomOffset={80}
          customEmptyMessage={<CustomEmptyState title={I18n.t('There are no reports in this session yet')} />}
        />
      </TableWrapper>
      <ConfirmationModal
        title={I18n.t('Report deletion')}
        onClose={() => closeModal(actions.DELETE_REPORT)}
        isVisible={modalsState[actions.DELETE_REPORT]}
        description={I18n.t('Are you sure you want to delete selected report?')}
        onConfirm={onDeleteConfirm}
        caution
      />
      {isLoading && <Spinner isLoading text={I18n.t('Loading reports')} full />}
    </RatersTableWrapper>
  );
};

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100vh - 3.2rem - 56px);
`;

const CustomEmptyState = styled(TableEmptyState)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default RatersReports;
