import React, { useMemo } from 'react';
import { func, array, object } from 'prop-types';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';

import CustomSelect from '../../reusable/Selects/Select';
import createTestsOptions from '../CreateInvite/createInviteUtils';
import useAvailableTests from '../../../hooks/useAvailableTests';

const AssessmentSelectionStep = ({ assessments, assessment, onValueChange, languages, language }) => {
  const onLanguageChange = (data) => {
    onValueChange('language', data);
  };

  const onAssessmentValueChange = (item) => {
    onValueChange('assessment', item);
  };

  const availableAssessments = useAvailableTests({
    language,
    assessments,
  });
  const assessmentsOptions = useMemo(() => {
    return assessments ? createTestsOptions(availableAssessments) : [];
  }, [availableAssessments]);

  return (
    <AssessmentContainer>
      <StyledSelect
        options={languages}
        placeholder={I18n.t('Language')}
        selectProps={{ value: language, onChange: onLanguageChange }}
      />
      <StyledSelect
        options={assessmentsOptions}
        placeholder={I18n.t('Assessment')}
        selectProps={{
          value: assessment,
          onChange: onAssessmentValueChange,
        }}
      />
    </AssessmentContainer>
  );
};

AssessmentSelectionStep.propTypes = {
  languages: array.isRequired,
  language: object,
  onValueChange: func.isRequired,
  assessment: object,
  assessments: array,
};

AssessmentSelectionStep.defaultProps = {
  assessment: null,
  assessments: [],
  language: null,
};

const AssessmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  flex-grow: 1;
`;

const StyledSelect = styled(CustomSelect)`
  padding: 0 1px;
`;

export default AssessmentSelectionStep;
