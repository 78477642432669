import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import RatersTableWrapper from './components/RatersTableWrapper';
import AdvancedFilter from '../reusable/AdvancedFilter';
import {
  applyRatersFilters,
  clearRatersFilters,
  deleteRaters,
  deleteRatersAppliedFilter,
  fetchRaters,
  setRatersFilterType,
  setRatersFilterValue,
  setRatersSearchValue,
  setSelectedRaters,
} from '../../store/raters/actions';
import useTableFilter from '../../hooks/useTableFilter';
import ratersColumns from '../../table/columns/raters';
import Table from '../reusable/Tables/Table';
import BulkActionsSidebar from '../BulkActionsSidebar';
import useBulkSidebarState from '../../hooks/useBulkSidebarState';
import ratersBulkActions from '../../table/bulkActions/raters';
import * as actions from '../../table/actionsList';
import useModals from '../../hooks/useModals';
import ConfirmationModal from '../reusable/ConfirmationModal';
import Spinner from '../reusable/Spinner';
import SessionInvite from '../Sessions/SessionInvite';
import getRatersByStatus from '../../utils/getRatersByStatus';
import TableEmptyState from '../reusable/TableEmptyState';

const Raters = ({ projectId, sessionId }) => {
  const dispatch = useDispatch();

  const [isLoading, setLoadingStatus] = useState(true);
  const [shouldResetPage, setShouldResetPageStatus] = useState(false);

  // Props from State
  const activeProjectInView = useSelector((state) => state.projects.activeProjectInView);
  const selectedRaters = useSelector((state) => state.raters.selectedRaters);
  const searchValue = useSelector((state) => state.raters.search);
  const filter = useSelector((state) => state.raters.filter);
  const raters = useSelector((state) => state.raters.raters);
  const totalCount = useSelector((state) => state.raters.totalCount);
  const lastPageIndex = useSelector((state) => state.raters.lastPageIndex);
  const activeSessionInView = useSelector((state) => state.sessions.activeSessionInView);
  const sessionRaters = activeSessionInView ? [...activeSessionInView.raters, activeSessionInView.self] : [];

  // Controlling bulk actions sidebar state
  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedRaters });

  // Modals
  const modals = [actions.DELETE_RATERS, actions.SEND_INVITE_TO_RATERS];
  const { modalsState, openModal, closeModal } = useModals(modals);

  const fetchData = useCallback(({ tableData, shouldReset, showSpinner, tableCallback }) => {
    if (showSpinner) setLoadingStatus(true);
    dispatch(
      fetchRaters(projectId, sessionId, tableData, {
        callback: () => {
          setLoadingStatus(false);
          tableCallback();
        },
        shouldReset,
      }),
    );
  }, []);

  const columns = useMemo(() => {
    return ratersColumns(activeProjectInView);
  }, [activeProjectInView]);

  // Table filtering
  const setFilterType = (name, value) => dispatch(setRatersFilterType(name, value));
  const setFilterValue = (name, value) => dispatch(setRatersFilterValue(name, value));
  const { inputsWithHandlers, appliedFiltersWithColumnNames } = useTableFilter({
    filter,
    columns,
    setFilterType,
    setFilterValue,
  });

  const memoizedSetRatersSearchValue = useCallback((val) => dispatch(setRatersSearchValue(val)), []);
  const memoizedDeleteRatersAppliedFilter = useCallback(
    (fieldName) => dispatch(deleteRatersAppliedFilter(fieldName)),
    [],
  );
  const memoizedClearRatersFilters = useCallback(() => dispatch(clearRatersFilters()), []);

  const onFilterApply = () => {
    dispatch(applyRatersFilters());
  };

  const onSelectChange = useCallback((data) => {
    dispatch(setSelectedRaters(data));
  }, []);

  // initial table state
  const initialState = useMemo(() => ({ sortBy: [{ id: 'Role', desc: true }] }), []);

  // Dropdown Actions
  const [commandType, setCommandType] = useState(0);

  // Bulk Actions
  const bulkActions = useMemo(() => {
    return ratersBulkActions.map((item) => ({
      ...item,
      handler: () => {
        if (item.name === actions.SEND_INVITE_TO_RATERS) setCommandType(item.commandType);
        openModal(item.name);
      },
      isDisabled: item.isDisabled && item.isDisabled(selectedRaters, activeProjectInView),
    }));
  }, [selectedRaters, activeProjectInView]);

  // Actions
  const onDelete = () => {
    const ids = selectedRaters.map((item) => item.raterID);
    dispatch(
      deleteRaters(activeProjectInView.projectId, activeSessionInView.sessionId, ids, () => {
        closeModal(actions.DELETE_RATERS);
      }),
    );
  };

  // session raters filtered by selected command type
  const filteredSessionRaters = useMemo(() => {
    if (!activeSessionInView) return [];
    const { notCompleted, notInvited, invitedNotCompleted } = getRatersByStatus(sessionRaters);
    const byTypes = { 0: notCompleted, 1: notInvited, 2: invitedNotCompleted };
    return byTypes[commandType];
  }, [activeSessionInView, commandType, sessionRaters]);

  return (
    <RatersTableWrapper
      projectId={projectId}
      sessionId={sessionId}
      activeTab={0}
      setShouldResetPageStatus={setShouldResetPageStatus}
    >
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      <AdvancedFilter
        searchProps={{
          appliedFilters: appliedFiltersWithColumnNames,
          deleteFilter: memoizedDeleteRatersAppliedFilter,
          searchValue,
          onSearchChange: memoizedSetRatersSearchValue,
        }}
        filterProps={{
          inputs: inputsWithHandlers,
          appliedInputs: filter.appliedInputs,
          applyFilters: onFilterApply,
          clearAll: memoizedClearRatersFilters,
          isDirty: filter.dirty,
        }}
      />
      <StyledTable
        data={raters}
        columns={columns}
        onFetchData={fetchData}
        totalCount={totalCount}
        appliedFilters={appliedFiltersWithColumnNames}
        scrollContainerHeight="100%"
        idAccessor="raterId"
        entityName="rater"
        searchValue={searchValue}
        rowEqualityFunc={() => {
          return false;
        }}
        initialState={initialState}
        lastPageIndex={lastPageIndex}
        onSelectChange={onSelectChange}
        shouldResetPage={shouldResetPage}
        setShouldReset={setShouldResetPageStatus}
        customEmptyMessage={<TableEmptyState title={I18n.t('There are no raters in this session yet')} />}
      />
      <Spinner isLoading={isLoading} full text={I18n.t('Loading raters')} />
      <ConfirmationModal
        isVisible={modalsState[actions.DELETE_RATERS]}
        onClose={() => closeModal(actions.DELETE_RATERS)}
        title={I18n.t('Delete raters')}
        description={`${I18n.t('Are you sure you want to delete')} ${
          selectedRaters.length > 1 ? I18n.t('selected raters?') : I18n.t('this rater?')
        }`}
        onConfirm={onDelete}
      />
      {modalsState[actions.SEND_INVITE_TO_RATERS] && (
        <SessionInvite
          onClose={() => closeModal(actions.SEND_INVITE_TO_RATERS)}
          itemType={selectedRaters.length ? 'rater' : 'session'}
          items={selectedRaters.length ? selectedRaters : filteredSessionRaters}
          commandType={commandType}
          successCallback={setShouldResetPageStatus ? () => setShouldResetPageStatus(true) : () => {}}
          sessionId={selectedRaters.length ? '' : activeSessionInView && activeSessionInView.sessionId}
        />
      )}
    </RatersTableWrapper>
  );
};

const StyledTable = styled(Table)`
  tr:hover {
    & > div div {
      background: rgba(241, 241, 241, 0.1);
    }
  }
`;

export default Raters;
