import handleActions from '../../immerHandleActions';
import * as actions from './actions';

const initialState = {
  welcomeTexts: [],
  activeWelcomeText: null,
  bodyVariables: [],
};

const welcomeTextsReducer = handleActions(
  {
    [actions.fetchWelcomeTextsSuccess]: (draft, { payload: { welcomeTexts } }) => {
      draft.welcomeTexts = welcomeTexts;
    },
    [actions.deleteWelcomeTextsSuccess]: (draft, { payload: { welcomeTextsIds } }) => {
      draft.welcomeTexts = draft.welcomeTexts.filter(
        (welcomeText) => !welcomeTextsIds.includes(welcomeText.templateID),
      );
    },
    [actions.fetchWelcomeTextsVariablesSuccess]: (draft, { payload: { variables } }) => {
      draft.bodyVariables = variables;
    },
    [actions.setActiveWelcomeText]: (draft, { payload: { welcomeText } }) => {
      draft.activeWelcomeText = welcomeText;
    },
  },
  initialState,
);

export default welcomeTextsReducer;
