import React from 'react';
import { number } from 'prop-types';
import { I18n } from 'react-redux-i18n';

import styled from 'styled-components';
import assessmentProjectStatuses, {
  AP_ACTIVE_STATUS,
  AP_COMPLETED_STATUS,
  AP_INACTIVE_STATUS,
  AP_IN_PROGRESS_STATUS,
} from '../../../../constants/assessmentProjectStatuses';

const bgStatusColors = {
  [AP_INACTIVE_STATUS]: 'rgba(0,0,0,0.08)',
  [AP_ACTIVE_STATUS]: '#E0F1F7',
  [AP_IN_PROGRESS_STATUS]: '#E0F1F7',
  [AP_COMPLETED_STATUS]: '#0B8D00',
};

const statusFontColors = {
  [AP_INACTIVE_STATUS]: 'rgba(0,0,0,0.6)',
  [AP_ACTIVE_STATUS]: '#008AC0',
  [AP_IN_PROGRESS_STATUS]: '#008AC0',
  [AP_COMPLETED_STATUS]: '#FFF',
};

const AssessmentProjectStatus = ({ status }) => {
  return (
    <StyledStatus bgColor={bgStatusColors[status]} fontColor={statusFontColors[status]}>
      <span>
        {assessmentProjectStatuses[status]
          ? I18n.t(assessmentProjectStatuses[status])
          : assessmentProjectStatuses[status]}{' '}
      </span>
    </StyledStatus>
  );
};

AssessmentProjectStatus.propTypes = {
  status: number.isRequired,
};

export const StyledStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12rem;
  margin-left: 1.6rem;
  padding: 0.4rem 1.2rem;
  text-transform: uppercase;
  border-radius: 1rem;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1.5px;
  max-height: 2rem;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.fontColor};

  ${({ theme }) => theme.screenSizes.xl`
    font-size: 1rem;
    margin-left: 0.6rem;
    width: 14rem;
  `}
`;

export default AssessmentProjectStatus;
