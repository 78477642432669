import React from 'react';
import { format } from 'date-fns';

import { I18n } from 'react-redux-i18n';
import { caseInsensitiveSortingFunc } from './respondents';
// import ActionsDropdown from '../../components/reusable/ActionsDropdown';
import Members from '../../components/reusable/Members';
import groupsTypesMap from '../../constants/groupTypes';

const DateCell = ({ row }) => <span>{format(new Date(row.original.creationDate), 'dd/M/y')}</span>;

const groupOptions = [
  {
    value: 0,
    label: groupsTypesMap[0],
  },
  {
    value: 1,
    label: groupsTypesMap[1],
  },
  // {
  //   value: 2,
  //   label: 'Type 2',
  // },
];

// const columns = (rowActions, setSelectedGroups) => [
const columns = [
  {
    Header: I18n.t('Name'),
    accessor: 'description',
    name: 'description',
    type: 'text',
    sortType: caseInsensitiveSortingFunc,
    filteringName: 'Name',
    width: 200,
  },
  {
    Header: I18n.t('Description'),
    accessor: 'notes',
    name: 'notes',
    type: 'text',
    filteringName: 'Description',
    width: 300,
  },
  {
    Header: I18n.t('Type'),
    accessor: (data) => I18n.t(groupsTypesMap[data.groupType]),
    name: 'groupType',
    type: 'select',
    options: groupOptions,
    filteringName: 'GroupType',
  },
  {
    Header: I18n.t('Members'),
    accessor: 'members',
    name: 'members',
    type: 'number',
    filteringUiName: 'Members count',
    filteringName: 'GroupSize',
    Cell: ({ row }) => <Members members={row.original.respondents} />,
  },
  {
    Header: I18n.t('Creation Date'),
    accessor: (data) => new Date(data.creationDate),
    name: 'creationDate',
    Cell: (props) => <DateCell {...props} />,
    sortType: 'datetime',
    type: 'date',
    filteringName: 'Created',
  },
  // {
  //   Header: '',
  //   name: 'actions',
  //   type: 'actions',
  //   id: 'actions',
  //   disableSortBy: true,
  //   Cell: ({ row }) => (
  //     <ActionsDropdown
  //       actions={rowActions.map((item) => ({
  //         ...item,
  //         handler: () => item.handler(row.original),
  //         isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(row.original) : item.isDisabled,
  //       }))}
  //       clickHandler={() => setSelectedGroups(row.original)}
  //     />
  //   ),
  //   width: 50,
  // },
];

export default columns;
