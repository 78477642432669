import { createAction } from 'redux-actions';
import { I18n } from 'react-redux-i18n';
import apiInstance2 from '../../../services/apiService';
import createToastNotification from '../../../utils/createToastNotification';

export const fetchCompetencyGroups = createAction('[COMPETENCY_GROUPS] FETCH_DATA');
export const fetchCompetencyGroupsSuccess = createAction('[COMPETENCY_GROUPS] FETCH_DATA_SUCCESS', (data) => ({
  data,
}));
export const fetchCompetencyGroupsFailure = createAction('[COMPETENCY_GROUPS] FETCH_DATA_FAILURE');
export const addCompetencyGroup = createAction('[COMPETENCY_GROUPS] ADD_NEW_GROUP', (data) => ({ data }));
export const restCompetencyGroups = createAction('[COMPETENCY_GROUPS] RESET');

export const deleteAssessmentsStart = createAction('[COMPETENCY_GROUPS] DELETE_ASSESSMENTS_START');
export const deleteAssessmentsSuccess = createAction('[COMPETENCY_GROUPS] DELETE_ASSESSMENTS_SUCCESS', (data) => ({
  data,
}));
export const deleteAssessmentsFailure = createAction('[COMPETENCY_GROUPS] DELETE_ASSESSMENTS_FAILURE');

export const duplicateCompetencyStart = createAction('DUPLICATE_COMPETENCY_START');
export const duplicateCompetencySuccess = createAction('DUPLICATE_COMPETENCY_SUCCESS');
export const duplicateCompetencyFailure = createAction('DUPLICATE_COMPETENCY_FAILURE');

export const fetchCompetencyGroupsAction = () => async (dispatch) => {
  dispatch(fetchCompetencyGroups());

  try {
    const response = await apiInstance2.get(`/api/v2/360/competency-groups`);

    if (response.status === 200) {
      dispatch(fetchCompetencyGroupsSuccess(response.data));
    }
  } catch (err) {
    dispatch(fetchCompetencyGroupsFailure());
  }
};

export const deleteAssessments = (data, onSuccess, onFailure) => async (dispatch) => {
  dispatch(deleteAssessmentsStart());

  try {
    const response = await apiInstance2.delete(`/api/v2/360/competency-groups`, { data });

    if (response.status === 200) {
      dispatch(deleteAssessmentsSuccess(data));
      createToastNotification({ message: I18n.t('assessmentsDeletionToast') });
      if (onSuccess) onSuccess();
    }
  } catch (err) {
    dispatch(deleteAssessmentsFailure(err.message));
    if (onFailure) onFailure(err);
  }
};

export const duplicateCompetency = (competencyId, competencyName, cb) => async (dispatch) => {
  dispatch(duplicateCompetencyStart());

  try {
    const response = await apiInstance2.post(`/api/v2/360/competency-groups/${competencyId}/clones`, {
      name: competencyName,
    });

    if (response.status === 200) {
      dispatch(duplicateCompetencySuccess());
      createToastNotification({ message: I18n.t('Copy of 360 survey has been created') });
      if (cb) cb(null, response.data.competencyGroupID);
    }
  } catch (err) {
    dispatch(duplicateCompetencyFailure(err.message));
    if (cb) cb(err);
  }
};
