import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { I18n } from 'react-redux-i18n';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus-icon.svg';
import { ReactComponent as MinusIcon } from '../../assets/icons/minus-icon.svg';

const Quantity = ({ onChange, defaultQuantity = 2, label = I18n.t('Quantity'), min = 1, max = 10 }) => {
  const [quantity, setQuantity] = useState(defaultQuantity);

  const onQuantityChanged = (add) => {
    if (add && quantity < max) {
      setQuantity(quantity + 1);
      return;
    }

    if (!add && quantity > min) {
      setQuantity(quantity - 1);
    }
  };

  useEffect(() => {
    onChange(quantity);
  }, [quantity]);

  useEffect(() => {
    setQuantity(defaultQuantity);
  }, [defaultQuantity]);

  return (
    <Content>
      <Label>{label}</Label>
      <Buttons>
        <RoundButton type="button" onClick={() => onQuantityChanged(false)}>
          <MinusIcon />
        </RoundButton>
        <Value>{quantity}</Value>
        <RoundButton ml={35} type="button" onClick={() => onQuantityChanged(true)}>
          <PlusIcon />
        </RoundButton>
      </Buttons>
    </Content>
  );
};

const Label = styled.span`
  font-weight: bold;
  font-size: 14px;
  margin-right: 10px;
  color: ${(props) => props.theme.colors.darkBlue2};
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const Buttons = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Value = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  display: inline-block;
`;

const RoundButton = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.lightBlue1};
  border: none;
  outline: none;
  ${(props) =>
    props.ml &&
    css`
      margin-left: ${props.ml}px;
    `}
`;

export default Quantity;
