import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { I18n } from 'react-redux-i18n';
import NewModal from '../reusable/NewModal';
import Spinner from '../reusable/Spinner';
import CustomButton from '../reusable/Buttons/Button';
import apiInstance2 from '../../services/apiService';
import CompetencyGroupNavBar from './components/CompetencyGroupNavbar';
import competenciesSelectors from '../../store/settings/competencies/selectors';
import { fetchCompetenciesAction, resetCompetencies } from '../../store/settings/competencies/actions';
import CompetencyGroupsAccordion from '../reusable/Accordion/CompetencyGroupsAccordion';
import { errorMessages } from '../../constants/errorMessages';
import FormikInput from '../reusable/FormComponents/FormikInput';
import createToastNotification from '../../utils/createToastNotification';

const AddCompetencyGroup = ({ onCancel, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [next, setNext] = useState(false);
  const [groupsByType, setGroupsByType] = useState({ custom: [], inbuilt: [] });
  const [selected, setSelected] = useState({ custom: {}, inbuilt: {} });

  const list = useSelector(competenciesSelectors.selectData);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCompetenciesAction());

    return () => {
      dispatch(resetCompetencies());
    };
  }, []);

  useEffect(() => {
    setGroupsByType({
      custom: list.filter((item) => item.competencyType),
      inbuilt: list.filter((item) => !item.competencyType),
    });
  }, [list]);

  const onSelectCompetency = (name, items) => {
    setSelected({ ...selected, [name]: items.reduce((acc, item) => ({ ...acc, [item.competencyID]: item }), {}) });
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(I18n.t('Name is required'))
      .max(100, errorMessages.tooLong(100)),
  });

  return (
    <Formik
      initialValues={{
        name: '',
      }}
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur
      enableReinitialize
      onSubmit={(values) => {
        setIsLoading(true);

        apiInstance2
          .post(`/api/v2/360/competency-groups`, values)
          .then(({ data }) => {
            setIsLoading(false);

            const { inbuilt, custom } = selected;
            const mapped = Object.values({ ...inbuilt, ...custom }).map((item) => {
              return {
                competencyID: item.competencyID,
                competencyType: item.competencyType || 0,
              };
            });

            apiInstance2
              .post(`/api/v2/360/competency-groups/${data.competencyGroupID}/competencies`, mapped)
              .then(() => {
                createToastNotification({ message: I18n.t('addedAssessmentToast') });
                onClose(data);
              })
              .catch(() => setIsLoading(false));
          })
          .catch(() => {
            setIsLoading(false);
          });
      }}
    >
      {({ values, errors, touched, handleChange, validateForm, setFieldError, setFieldTouched, handleSubmit }) => {
        // TODO add translations for "Add 360 survey"?
        return (
          <NewModal isVisible title={I18n.t('Add 360 survey')} onClose={onCancel}>
            <StyledModalBody>
              <CompetencyGroupNavBar step={2} next={next} />
              {!next && (
                <StyledCustomInput
                  inputName={I18n.t('Enter Name')}
                  name="name"
                  id="name"
                  value={values.name}
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              )}
              {next && (
                <AccordionWrapper>
                  <CompetencyGroupsAccordion
                    list={groupsByType.custom}
                    selected={selected.custom}
                    label={I18n.t('Custom competencies')}
                    onSelectedChanged={(items) => onSelectCompetency('custom', items)}
                  />
                  <CompetencyGroupsAccordion
                    list={groupsByType.inbuilt}
                    selected={selected.inbuilt}
                    label={I18n.t('Inbuilt competencies')}
                    onSelectedChanged={(items) => onSelectCompetency('inbuilt', items)}
                  />
                </AccordionWrapper>
              )}
            </StyledModalBody>
            <ButtonWrapper>
              {!next && (
                <StyledCustomButton
                  type="button"
                  size="m"
                  width={120}
                  variant="primary"
                  disabled={!list.length}
                  onClick={() => {
                    validateForm().then((errors) => {
                      if (errors.name) {
                        setFieldTouched('name');
                        setFieldError('name', errors.name);
                      } else {
                        setNext(true);
                      }
                    });
                  }}
                >
                  {I18n.t('Next')}
                </StyledCustomButton>
              )}
              {next && (
                <StyledCustomButton type="button" size="m" width={120} variant="primary" onClick={handleSubmit}>
                  {isLoading ? <Spinner isLoading /> : I18n.t('Add')}
                </StyledCustomButton>
              )}
            </ButtonWrapper>
          </NewModal>
        );
      }}
    </Formik>
  );
};

const StyledModalBody = styled.div`
  overflow: auto;
  max-height: 60rem;
  max-width: 120rem;
`;

const AccordionWrapper = styled.div`
  margin: 20px 0;
`;

const StyledCustomInput = styled(FormikInput)`
  margin-top: 15px;
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: flex-end;
  width: 100%;
`;

const StyledCustomButton = styled(CustomButton)`
  min-height: 44px;
  position: relative;
`;

export default AddCompetencyGroup;
