import React, { useMemo, useEffect, useState } from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';

import { I18n } from 'react-redux-i18n';
import PageWrapper from '../../PageWrapper';
import BulkActionsSidebar from '../../BulkActionsSidebar';
import ConfirmationModal from '../../reusable/ConfirmationModal';
import Spinner from '../../reusable/Spinner';
import AddIdealProfileModal from './AddIdealProfile';

import useModals from '../../../hooks/useModals';
import * as actions from '../../../table/actionsList';
import useBulkSidebarState from '../../../hooks/useBulkSidebarState';

import idealProfilesTableActions from '../../../table/tableActions/idealProfiles';
import idealProfilesBulkActions from '../../../table/bulkActions/idealProfiles';
import {
  fetchIdealProfiles,
  fetchIdealProfilesTests,
  deleteIdealProfile,
} from '../../../store/settings/ideal-profiles/actions';
import { selectIdealProfileTestsOptions } from '../../../store/settings/ideal-profiles/selectors';
import TableTabs from '../../reusable/TableTabs';
import Table from '../../reusable/Tables/Table';
import idealProfilesColumns from '../../../table/columns/idealProfiles';
import AdvancedFilter from './AdvancedFilter';

const IdealProfiles = () => {
  const dispatch = useDispatch();

  const idealProfiles = useSelector((state) => state.idealProfiles.idealProfiles);
  const idealProfileTests = useSelector(selectIdealProfileTestsOptions);

  const modals = [actions.ADD_NEW_IDEAL_PROFILE, actions.DELETE_IDEAL_PROFILES];
  const { modalsState, openModal, closeModal } = useModals(modals);

  const [selectedIdealProfiles, setSelectedIdealProfiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchIdealProfilesTests());
    dispatch(
      fetchIdealProfiles(() => {
        setIsLoading(false);
      }),
    );
  }, []);

  const onIdealProfilesDelete = () => {
    dispatch(
      deleteIdealProfile(selectedIdealProfiles, () => {
        closeModal(actions.DELETE_IDEAL_PROFILES);
        setSelectedIdealProfiles([]);
      }),
    );
  };

  const navigateToNorm = (idealProfileID) => {
    dispatch(push(`/settings/ideal-profiles/${idealProfileID}`));
  };

  // Controlling bulk actions sidebar state
  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedIdealProfiles });

  const bulkActionsHandlers = {
    [actions.EDIT_IDEAL_PROFILE]: () => navigateToNorm(selectedIdealProfiles[0]),
    [actions.DELETE_IDEAL_PROFILES]: () => openModal(actions.DELETE_IDEAL_PROFILES),
  };

  const bulkActions = useMemo(() => {
    return idealProfilesBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedIdealProfiles) : item.isDisabled,
    }));
  }, [selectedIdealProfiles]);

  const tableActions = useMemo(() => {
    return idealProfilesTableActions.map((item) => ({
      ...item,
      handler: () => openModal(item.name),
    }));
  }, []);

  const tabs = [
    { name: 'assessments-and-batteries', label: 'Assessment and Batteries' },
    { name: 'ideal-profiles', label: 'Ideal Profiles' },
    { name: 'norms', label: 'Norms' },
  ];

  const [globalFilter, setGlobalFilter] = useState([]);
  const [filters, setFilters] = useState([]);

  const filterOptions = useSelector((state) => {
    return state.idealProfiles.filteringOptions;
  });

  const filterOptionsReady = useMemo(() => {
    return !!Object.keys(filterOptions).length;
  }, [filterOptions]);

  const tableColumns = useMemo(() => {
    return idealProfilesColumns;
  }, []);

  const rowCheckedOnChangeHandler = (rows) => {
    setSelectedIdealProfiles(rows.map((item) => item.idealProfileID));
  };

  const handleRowClick = ({ idealProfileID }) => {
    navigateToNorm(idealProfileID);
  };

  return (
    <PageWrapper
      title={I18n.t('Assessment settings')}
      backButtonHandler={() => dispatch(push(`/settings`))}
      buttons={tableActions}
    >
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      <TableTabs
        tabs={tabs}
        activeTab={tabs[1]}
        clickHandler={(tab) => {
          dispatch(push(tab.name));
        }}
      />
      {idealProfiles.length && filterOptionsReady ? (
        <AdvancedFilter
          columns={tableColumns}
          filterOptions={filterOptions}
          setFilters={setFilters}
          setGlobalFilter={setGlobalFilter}
        />
      ) : (
        ''
      )}
      {idealProfiles.length && filterOptionsReady ? (
        <Table
          columns={tableColumns}
          data={idealProfiles}
          totalCount={idealProfiles.length}
          manualSortBy={false}
          onRowClick={handleRowClick}
          onSelectChange={rowCheckedOnChangeHandler}
          interceptCheckboxClick
          withFilters={{ value: filters }}
          withGlobalFilter={{ value: globalFilter }}
          localData
        />
      ) : (
        ''
      )}
      <ConfirmationModal
        isVisible={modalsState[actions.DELETE_IDEAL_PROFILES]}
        title={I18n.t('Delete ideal profiles')}
        description={`${I18n.t('Are you sure you want to delete')} ${
          selectedIdealProfiles.length > 1 ? I18n.t('selected ideal profiles?') : I18n.t('this ideal profile?')
        }`}
        onClose={() => closeModal(actions.DELETE_IDEAL_PROFILES)}
        onConfirm={onIdealProfilesDelete}
        caution
      />
      {modalsState[actions.ADD_NEW_IDEAL_PROFILE] && (
        <AddIdealProfileModal onClose={() => closeModal(actions.ADD_NEW_IDEAL_PROFILE)} tests={idealProfileTests} />
      )}

      <Spinner isLoading={isLoading} full />
    </PageWrapper>
  );
};

export default IdealProfiles;
