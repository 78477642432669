const translations = {
  About: 'Інформація',
  Launch: 'Запуск',
  Tutorial: 'Інструкції',

  // Login Page (Login.js)
  Email: 'Електронна пошта',
  Submit: 'Надіслати',
  LoginNext: 'Логін',
  LoginSubmit: 'Увійти',
  SwitchUser: 'Змінити користувача',
  ForgotPassword: 'Забули пароль',
  Password: 'Пароль',
  'Email is required': 'Необхідно ввести адресу електронної пошти',
  'Password is required': 'Необхідний пароль',
  'Should be a proper email address': 'Невірний формат електронної пошти',

  // (AddCompetencyCategory.js)
  Save: 'Зберегти',
  'Enter Name': "Введіть ім'я",
  'Add competency category': 'Додати категорію компетенцій',
  'Add description for the category': 'Додайте опис до категорії',

  // (CompetencyGroupNavbar.js)
  'Main info': 'ОСНОВНА ІНФОРМАЦІЯ',

  // (AddCompetencyGroup.js)
  Add: 'Додати',
  Next: 'Далі',
  'Name is required': 'Вкажіть ім’я',
  'Add 360 assessment': 'Додати 360 опитування',
  'Custom competencies': 'Додані компетенції',
  'Inbuilt competencies': 'Стандартні компетенції',

  // (AddCompetencyItem.js)
  Edit: 'Редагувати',
  'Competency description': 'Опис компетенції',

  // (AddCompetencyQuestion.js)
  'Add question': 'Додати запитання',

  // (AddCompetencyQuestionForm.js)
  'Item text': 'Текст пункту',
  'Free field': 'Вільне поле',
  'Multiple choice': 'Вибір з кількох варіантів',
  'Add another language': 'Додати іншу мову',

  // (AddCreditsFailureModal.js)
  'Credits addition error': 'Помилка додавання кредитів',
  'Try again': 'Спробуйте ще раз',

  //  (AddCreditsSuccessModal.js)
  Done: 'Готово',
  'Adding credits': 'Додавання кредитів',
  'Credits successfully added!': 'Кредити успішно додано!',
  'Now your credit balance is:': 'Ваш новий кредитний баланс:',

  // (AddCredits.js)
  Cancel: 'Скасувати',
  'Add credits': 'Додати кредити',
  'Your credits balance': 'Кредитний баланс',
  'How many credits do you want to add?': 'Скільки кредитів ви хочете додати?',
  'Purchase ID': 'Ідентифікатор покупки',
  'Error occurred during an attempt to add credits to your account':
    'Сталася помилка під час спроби додавання кредитів',
  'Credits amount': 'Сума кредитів',

  // Edit Respondent (Index.js)
  Groups: 'Групи',
  Invites: 'Запрошення',
  Reports: 'Звіти',
  Assessments: 'Результати',
  IAssessments: 'Результати',
  SAssessments: 'Результати',
  'Respondent Information': 'Інформація про респондента',

  // Edit Respondent (AddTagModal.js)
  'Add tags': 'Додайте теги',
  'Select tags': 'Виберіть з наявних тегів, або додайте новий',

  // Edit Respondent (Information.js)
  Sex: 'Стать',
  BioTitle: 'Посада',
  Title: 'Назва',
  Sector: 'Сектор',
  sector: 'Сектор',
  Company: 'Компанія',
  Industry: 'Сфера діяльності',
  industry: 'Сфера діяльності',
  Education: 'Освіта',
  education: 'Освіта',
  Reference: 'Додаткова інформація',
  Ethnicity: 'Етнічна приналежність',
  ethnicity: 'Етнічна приналежність',
  'Job area': 'Вид діяльності',
  jobArea: 'Вид діяльності',
  'First Name': "Ім'я",
  'Family Name': 'Прізвище',
  'Save changes': 'Зберегти зміни',
  'Date of Birth': 'Дата народження',
  'First Language': 'Перша мова',
  'Additional Info': 'Додаткова інформація',
  'Main Information': 'Основна інформація',

  // Edit Respondent (RespondentGroups.js)
  group: 'групи',
  'Remove from groups': 'Видалити з груп(-и)',
  'Are you sure you want to remove respondent from selected group(s)':
    'Ви впевнені, що хочете видалити респондента з вибраних груп',

  // Edit Respondent (RespondentInvites.js)
  invite: 'запрошення',
  'Invite Deletion': 'Видалити запрошення',

  // Edit Respondent (RespondentReports.js)
  'Report deletion': 'Видалити звіт',
  'Are you sure you want to delete selected report?': 'Ви впевнені, що хочете видалити вибраний звіт?',

  // Edit Respondent (RespondentTests.js)
  'Test deletion': 'Видалити тест',
  'Are you sure you want to delete selected test?': 'Ви впевнені, що хочете видалити вибраний тест?',

  // Edit Respondent (TagsManagement.js)
  Tags: 'Теги',
  '+ Add tag': '+ Додайте теги',

  // Groups (AddNewGroup.js)
  Create: 'Створити',
  'Group name': 'Назва групи',
  'Add new group': 'Додати групу',
  'Group description': 'Опис групи',

  // Groups (GroupHeader.js)
  Type: 'Тип',
  'Creation date': 'Дата створення',
  'Members count': 'Число учасників',
  member: 'учасник',
  members: 'учасників',

  // Groups (Groups.js)
  List: 'Список',
  Respondents: 'Респонденти',
  'Delete group': 'Видалити групу',
  'Are you sure you want to delete this group(s)': 'Ви впевнені, що хочете видалити цю групу(-и)',

  // Groups request report
  'Select common assessment / battery': 'Вибрати спільний опитник/батарею',
  reports: 'звіти',

  // Groups generate open group link
  link: 'посилання',
  Generate: 'Згенерувати',
  'The link has been successfully generated': 'Посилання успішно згенеровано',

  // Invites (Invites.js)
  'Delete invite': 'Видалити запрошення',
  'selected invites?': 'вибрані запрошення?',
  'this invite?': 'це запрошення?',

  // LaunchScreen (LaunchScreen.js)
  Language: 'Мова',
  language: 'Мова',

  // Notifications (Notifications.js)
  Results: 'Результати',
  notification: 'повідомлення',
  Notifications: 'Повідомлення',

  // Projects (AddProjectsModal.js)
  Min: 'Мін.',
  Info: 'Інформація',
  Raters: 'Оцінювачі',
  Deadline: 'Кінцева дата',
  'Peer Alias': 'Псевдонім колеги',
  Description: 'Опис',
  'Project Name': 'Назва проєкту',
  'All standard': 'Всі стандартні',
  'Edit Project': 'Редагувати проєкт',
  'Manager Alias': 'Псевдонім керівника',
  'Project Alias': 'Псевдоніми проєкту',
  'Direct Report Alias': 'Псевдонім підлеглого',
  'Choose Project Type': 'Вибрати тип проєкту',
  '360 assessment has to be fully supported in at least 1 language':
    'Оцінка 360 повинна повністю підтримуватися принаймні 1 мовою',
  '360 Survey': '360 опитування',
  '360 Surveys': '360 опитування',
  'Other Alias': 'Інша роль',

  // Projects (projectList.js)
  'Add Assessment Project': 'Додати проєкт для проведення сесій опитування',
  'Add 360 Appraisal Project': 'Додати проєкт для 360 оцінки',
  'Manage your assessment projects here. Plan and implement the right assessment approach and tests. Effectively and consistently administrate, communicate and manage the whole assessment process using this user friendly tool.':
    'Керувати своїми проєктами з оцінки. Сплануйте та застосуйте вірний підхід до оцінки та тестування.  Ефективно та послідовно адмініструйте, комунікуйте та керуйте цілим процесом оцінки, використовуючи цей зручний інструмент.',
  'Manage your entire 360 appraisal process here. Easy to use, allowing appraisals to be set up in minutes and results generated instantly. Choose from Psytech&apos;s existing competency framework or fully customise your 360 project.':
    'Керуйте процесом оцінки 360 градусів. Простий у використанні інтерфейс дозволяє сформувати процес оцінки за лічені хвилини та миттєво знегерувати результати. Ви можете вибрати компетенції з існуючої системи компетенцій Psytech, або повністю налаштувати власні компетенції.',

  // Projects (Projects.js)
  All: 'Усі',
  Project: 'Проект',
  Projects: 'Проєкти',
  'this project?': 'цей проект?',

  '360 Appraisal': 'Оцінка 360',
  'Delete project': 'Видалити проект',
  'selected projects?': 'обрані проекти?',
  'Are you sure you want to delete': 'Ви впевнені, що хочете видалити',

  // Raters (Raters.js)
  rater: 'респондент',
  'Delete raters': 'Видалити респондентів',
  'Loading raters': 'Завантаження елементів',
  'selected raters?': 'обраних респондентів?',
  'this rater?': 'цього респондента?',
  Session: 'Сесія',
  'Session for': 'Сесія для',

  // Raters (AddRatersModal.js)
  Role: 'Роль',
  Other: 'Інші',
  Peers: 'Колеги',
  Confirm: 'Підтвердити',
  Managers: 'Керівники',
  'No email': 'Немає електронної пошти',
  'Add raters': 'Додайте респондентів',
  'Direct reports': 'Підлеглі',
  'Search existing records': 'Шукати в наявних записах',
  'Make sure selected record has a valid email address added':
    'Переконайтесь, що для вибраного запису додано дійсну адресу електронної пошти',

  // Raters Invites (columns.js)
  Name: "Ім'я",
  PersonName: "Ім'я",
  'Expiry Date': 'Термін дії',
  'Invite status': 'Статус запрошення',
  'Email Address': 'Електронна адреса',
  'Hide expired': 'Термін дії прихованої форми закінчився',

  // Raters Invites (Invites.js)
  'chosen invite(s)?': 'вибране(-і) запрошення?',
  'selected invite(s)?': 'обране(-і) запрошення?',
  'Loading invites': 'Завантаження запрошень',
  missingEmailError: 'адреса електронної пошти відсутня або недійсна у записі(-ах) респондентів',

  // Raters Reports (Reports.js)
  Pending: 'В очікуванні',
  Unread: 'Непрочитано',
  Viewed: 'Переглянуто',
  Error: 'Помилка',
  'Support ticket raised': 'Помилка. Створена заявка до служби підтримки',
  'Loading reports': 'Завантаження звітів',

  // Raters Results (Results.js)
  result: 'результат',

  // Reports Reports (Reports.js)
  'Are you sure you want to delete selected report(s)?': 'Ви впевнені, що хочете видалити вибрані звіти?',

  // RequestReport360 (RequestReport360.js)
  'Report Request for': 'Згенерувати звіт для',
  'Report Request': 'Згенерувати звіт',
  credits: 'кредити',
  Request: 'Запит',
  Self: 'Сам',

  // Respondents AddRespondentModal (AddRespondentForm.js)
  'Company Name': 'Назва компанії',
  'Job Area': 'Вид діяльності',
  Remove: 'Видалити',

  // Respondents AddRespondentModal (AddRespondentModal.js)
  'Add respondent': 'Додати респондента',
  'Main Details': 'Основні дані',
  'Extra Details': 'Додаткові дані',
  'Group Membership': 'Додати в групу',

  // Respondents AddRespondentModal (GroupsTab.js)
  'Select groups': 'Виберіть зі списку наявних груп',

  // Respondents AdministerAssessment (index.js)
  Start: 'Розпочати',
  Administer: 'Адмініструвати',
  to: 'для',
  questionnaires: 'опитник',
  reasoning: 'критерії',
  solutions: 'рішення',
  batteries: 'батареї',
  favourites: 'обрані',

  // Respondents (AnswersStep.js)
  'Test:': 'Тест:',

  // Respondents AnswerSheet (index.js)
  Previous: 'Попередній',
  'Administer answer sheet for': 'Заповнити відповіді з бланку для',
  answers: 'відповіді',
  Respondent: 'Респондент',

  // Respondents (AssessmentStep.js)
  'Welcome text': 'Вступний текст',
  'Expiry date': 'Термін дії',

  // Invite (index.js)
  'Invite respondents': 'Запросити респондентів',
  Invite: 'Запросити',
  'Create invite and go next': 'Створити запрошення',
  Finish: 'Закінчити',
  'You are sending an invitation for:': 'Ви надсилаєте запрошення:',
  in: 'в',
  invitation: 'запрошення',
  OR: 'АБО',
  other: 'інше',

  // Invite (InvitationStep.js)
  Immediately: 'Негайно',
  Later: 'Пізніше',
  'Email address is missing or not valid in respondent record':
    'Адреса електронної пошти відсутня або недійсна в записі респондента',
  'Copy link': 'Копіювати посилання',
  Copied: 'Скопійовано',
  'Copy and share generated link': 'Скопіювати та поділитися згенерованим посиланням',
  'Send invitation link via email to:': 'Надіслати посилання на запрошення за адресою:',
  'Email template': 'Шаблон листа',
  'When do you want to send an invite?': 'Коли потрібно надіслати запрошення?',
  'Email invite': 'Надіслати запрошення листом',
  'Email sent': 'Лист відправлено',

  // RequestReportModal (index.js)
  'Request report': 'Згенерувати звіт(-и)',
  'Request report for': 'Згенерувати звіт(-и) для',
  for: 'для',
  'Request Reports': 'Згенерувати звіт(-и)',
  'Common tests: ': 'Загальні тести',
  'At least 1 assessment must be common': 'Принаймні 1 виконаний тест повинен бути спільним',
  'Selected respondents': 'Вибрані респонденти',
  tests: 'Тести',
  'Assessment/Battery': 'Опитник/Батарея',
  'Total credits': 'Загальна сума кредитів',

  // RequestReportModal (ReportsList.js)
  'Available Reports': 'Доступні звіти',
  Norm: 'Нормативна група',
  Options: 'Опції',
  'Ideal Profile': 'Ідеальний профіль',

  // Respondents (AddToGroup.js)
  'Add to group': 'Додати до наявної групи',
  'Add respondent(s) to group': 'Додати до наявної групи',

  // Respondents (ManageTagsModal.js)
  'Manage respondents tags': 'Оновити теги',
  'Select from existing tags or add new': 'Виберіть з наявних тегів, або додайте новий',
  'Add tag to selected respondent(s)': 'Додати тег для виділених респондентів',
  'Remove tag from selected respondent(s)': 'Видалити тег для виділених респондентів',

  // Respondents  (RespondentDropdown.js)
  'Add new respondent': 'Додати респондента',
  'Import respondents from file': 'Імпортувати респондентів із файлу',
  'Edit respondent': 'Змінити респондента',
  'Delete respondent': 'Видалити респондента',
  'All respondents are selected': 'Всі респонденти вибрані (%{respondentsCount})',
  'Clear selection': 'Очистити вибір',
  'All respondents on this page are selected': 'Всі респонденти на цій сторінці вибрані (%{respondentsCount})',
  'Select all respondents in this result': 'Вибрати всіх (%{respondentsCount}) респондентів за цими критеріями',

  // Respondents  (Respondents.js)
  'Delete respondents from group': 'Видалити респондентів із групи',
  'Loading respondents': 'Завантаження респондентів',
  'Are you sure you want to remove': 'Ви впевнені, що хочете видалити',
  'from the group?': ' із групи?',

  // Reusable Accordion (Accordion.js)
  INBUILT: 'Вбудовані',

  // Reusable Accordion (CompetencyGroupsAccordion.js)
  'Collapse all': 'Згорнути все',
  'Expand all': 'Розгорнути все',

  // Reusable  Filter (DateInputGroup.js)
  'Date range': 'Часовий проміжок',

  // Reusable  Filter (Filter.js)
  Filter: 'Фільтр',
  'Clear all': 'Очистити все',
  Apply: 'Застосувати',

  // Reusable Filter (TextInputGroup.js)
  'Filter type': 'Тип фільтру',

  // Reusable Filter (Search.js)
  Search: 'Пошук',

  // Reusable Badges (CustomBadge.js)
  CUSTOM: 'Додана вручну',

  // Reusable TextEditor (Editor.js)
  'Insert variable': 'Вставити змінну',

  // Reusable  (ConfirmationModal.js)
  No: 'Ні',
  Yes: 'Так',

  // Reusable  (DatePicker.js)
  'Select the date': 'Виберіть дату',

  // Reusable  (DateTimeSelect.js)
  'Time:': 'Час:',

  // Reusable  (ErrorMessage.js)
  'Oops, something went wrong': 'Упс, щось пішло не так',
  'Please try again': 'Будь ласка, спробуйте ще раз',
  Ok: 'Добре',

  // Reusable (Quantity.js)
  Quantity: 'Кількість',

  // Reusable (StatusCell.js)
  'In Progress': 'В прогресі',
  'On Hold': 'В очікуванні',
  Canceled: 'Скасовано',
  Completed: 'Завершено',

  // Session SessionInvite (index.js)
  'Send invite to raters': 'Надіслати запрошення',
  'Send invite to raters who have not yet completed': 'Надіслати нагадування всім, хто не надав оцінку',
  'Send invite to raters who have not yet been invited': 'Надіслати запрошення незапрошеним',
  'Send invite to raters who have already been invited but haven&apos;t yet completed': 'Надіслати нагадування',
  'Send invite to self with a request to specify their own raters': 'Запросити визначити респондентів',
  'Welcome text for Self Raters': 'Вступний текст для респондента',
  'Email template for Self Raters': 'Шаблон листа для респондента',
  'Welcome text for Other Raters': 'Вступний текст для респондентів',
  'Email template for Other Raters': 'Шаблон листа для респондентів',
  Send: 'Надіслати',

  // Session SessionInvite (SessionInvitationStep.js)
  'Email body': 'Текст листа',
  'Email subject': 'Тема листа',
  'Invitation date': 'Дата запрошення',
  'Email body for Self Raters': 'Шаблон листа для респондента',
  'Email body for Other Raters': 'Шаблон листа для респондентів',
  'Email subject for Other Raters': 'Тема листа для респондентів',
  'Email subject for Self Raters': 'Тема листа для респондента',

  // Session (AddSessionModal.js)
  'Sorry, session with selected respondent already exists.': 'Вибачте, сесія із вибранним респондентом вже існує',
  'Add session': 'Додати сесію',
  'Session SELF': 'Сесія Сам',
  'Search existing record to select session SELF': 'Пошук існуючого запису для вибору сесії Сам',

  // Session (SessionHeader.js)
  'All Standard': 'Всі стандартні',
  '360 Assessment:': 'Оцінка 360',
  'Type:': 'Тип:',
  'Deadline:': 'Час закінчення:',

  // Session (Sessions.js)
  Sessions: 'Сесії',
  session: 'сесія',
  'Loading sessions': 'Завантаження сесій',
  'Delete session': 'Видалити',
  'Delete sessions': 'Видалити',
  'this session?': 'цю сесію?',
  'Are you sure you want to delete this project': 'Ви впевнені, що хочете видалити цей проект',
  'Sessions list': 'Список сесій',

  // Session header (SessionHeader.js)
  'days left': 'днів залишилось',
  'Send invites': 'Надіслати запрошення',
  'Outstanding ratings': 'Очікування оцінок',
  'Send reminder': 'Надіслати нагадування',
  'Sessions completed': 'Сесій завершено',
  'Send invite': 'Надіслати запрошення',

  // Settings AddResponseScale (AddResponseScale.js)
  'Loading...': 'Завантаження…',

  // Settings (AddScaleForm.js)
  'Add response scale': 'Додати шкалу відповідей',
  'Edit response scale': 'Редагувати',

  // Settings Components   (EditTitle.js)
  'Edit Name': "Редагувати ім'я",
  'Edit 360 assessment': 'Редагувати',

  // Settings Components  (QuestionItem.js)
  Question: 'Питання',
  'No translation available for': 'Немає у наявності перекладу для',

  // Settings Components  (ReviewCompetencies.js)
  'Loading competencies': 'Завантаження компетенцій',
  Competencies: 'Компетенції',

  // Settings utils (ResponseScale.util.js)
  'Name is the required field': "Ім'я є обов'язковим полем",
  'All response scales should be filled': 'Усі шкали відповідей повинні бути заповнені',

  // Settings (CustomCompetencies.js)
  'Are you sure you want to remove selected': 'Ви впевнені, що хочете видалити вибраний',
  Collapse: 'Згорнути',
  Expand: 'Розгорнути',
  '360 settings': 'Налаштування 360 проектів',

  // Settings (Settings.js)
  Settings: 'Налаштування',

  // (BulkActionsSidebar.js)
  'Bulk actions': 'Основні дії',

  // 360 surveys
  'Add new 360 survey': 'Додати нове 360 опитування',
  'Add 360 survey': 'Додати 360 опитування',
  'Edit 360 survey': 'Редагувати 360 опитування ',
  'Duplicate 360 survey': 'Копіювати 360 опитування',
  'Delete selected 360 survey(s)': 'Видалити вибрані 360 опитування',
  Sorting: 'Сортування',

  // Sidebar.js
  'Are you sure you want to log out?': 'Ви впевнені, що хочете вийти із системи?',
  'Credits balance:': 'Кредитний баланс:',

  // Settings  (Settings.const.js)
  'Assessments and batteries': 'Інструменти оцінки та батареї',
  'Ideal Profiles': 'Ідеальні профілі',
  Norms: 'Нормативні групи',
  '360 Assessments': '360 опитування',
  'Response Scales': 'Шкали відповідей',
  Templates: 'Шаблони',
  'Email templates': 'Шаблони електронного листа',
  Account: 'Аккаунт',
  'General info': 'Загальна інформація',
  'Credits History': 'Історія вискористання кредитів',
  'Reports Usage': 'Використання звітів',
  'Assessments Stats': 'Використання інструментів оцінки',

  // Settings (AssessmentsAndBatteries.js)
  assessment: 'інструмент оцінки',
  'this battery?': 'ця батарея?',
  'Delete battery': 'Видалити',
  'Delete batteries': 'Видалити',
  'Assessment settings': 'Налаштування опитника',
  'selected batteries?': 'вибрані батареї',
  // Settings (AddNormMode.js)
  'Add New Norm': 'Додати нову нормативну группу',
  'Norm name': 'Назва нормативної групи',
  'Assessment on which the norm profile is based': 'Інструмент оцінки даної нормативної групи',
  Frequency: 'Частота',
  SD: 'Стандартне відхилення',
  'Sample Size': 'Розмір вибірки',
  'Sample size must be greater than or equal to 1': 'Розмір вибірки повинен бути більшим або рівним 1',
  'Select group': 'Вибрати групу',
  'Assessment on which the norm is based': 'Інструмент оцінки даної нормативної групи',

  // Settings (CompetencyGroups.js)
  'Copy of': 'Копія',
  'Competency Group': 'Група компетенцій',
  'Delete assessment': 'Видалити опитник',
  'selected assessments': 'вибрані опитники',
  'this assessment': 'цей опитник',
  'Duplicate assessment': 'Створити копію',
  'Are you sure you want to duplicate': 'Ви впевнені, що хочете створити копію',

  // Settings (CompetencyGroupsDetails.js)
  Structure: 'Структура',
  'Response scale': 'Шкала відповідей',
  'Loading Competency Details': 'Завантаження даних про компетенцію',
  'Categories:': 'Категорії:',
  'Competencies:': 'Компетенції:',
  'Numbers of items': 'Кількість запитань',
  'Multiple choice:': 'Кілька відповідей:',
  'Free text:': 'Довільний текст',
  'Estimated time': 'Орієнтовна тривалість',
  minutes: 'хвилин',
  'Available languages': 'Доступні мови',
  'Make sure at least one language is fully supported in assessment': 'Текст не підтримується жодною мовою повністю ',
  Questions: 'Питання',
  'Competency deletion': 'Видалення компетенціі',
  'this competencies?': 'ці компетенції?',
  'this competency?': 'цю компетенцію?',
  'Assessment deletion': 'Видалення опитника',
  'Are you sure you want to deleted selected the assessment?': 'Ви впевнені, що хочете видалити вибраний опитник',
  // Settings Templates (WelcomeText.js)
  'Welcome texts': 'Вступні тексти',
  'selected welcome texts?': 'вибрані вступні тексти?',
  'this welcome text?': 'цей вступний текст?',

  // Settings Templates (WelcomeTextScreen.js)
  'Add new welcome text': 'Додати вступний текст',
  'Template name': 'Назва шаблону',
  'welcome text': 'вступний текст',
  'Delete welcome texts': 'Видалити',

  // Settings Templates (templatesTabs.js)
  'Assessment welcome texts': 'Вступні тексти',
  template: 'шаблон',
  'Delete templates': 'Видалити',
  'selected templates?': 'вибрані шаблони?',
  'this template?': 'цей шаблон?',

  // Settings Templates (TemplatesScreen.js)
  'Field is required': "Обов'язкове поле для заповнення",
  'Template subject': 'Тема шаблону',

  // Settings ResponseScales (ResponseScales.js)
  'Response Scale': 'Шкала відповідей',
  'Delete scale(s)': 'Видалити',
  'Are you sure you want to delete this scale(s)?': 'Ви впервнені, що хочете видалити обрану(-і) шкал(-и)?',
  Responses: 'Відповіді',
  Languages: 'Мови',
  'Score right to left': 'Оцінка справа наліво',
  indexedResponse: 'Відповідь %{index}',

  // Settings ResponseScales (bulkActions.js)
  'Delete selected response scale(s)': 'Видалити',
  // Settings Norms (NormsHeader.js)
  'Norm Type:': 'Тип нормативної групи:',
  'Creation Date:': 'Дата створення:',

  // Settings Norms (NormsScales.js)
  'Norm Table': 'Таблиця нормативної групи',
  'Frequency Histograms': 'Гістограма частоти',
  'Bio Data': 'Особисті дані',
  Stenine: 'Стенайн',
  Stentable: 'Стенайн таблиця',

  // Settings Norms (Norms.js)
  'Add new norm': 'Додати нормативну групу',
  'Delete norm(s)': 'Видалити',
  'Assessment and Batteries': 'Опитники та батареї',
  'selected norms?': 'вибрані нормативні групи?',
  'this norm?': 'ця нормативна група?',
  'Creation Date': 'Дата створення',

  // Settings Norms (HistogramView.js)
  FREQUENCY: 'ЧАСТОТНІСТЬ',
  'RAW SCORE': 'НЕОБРОБЛЕНА ОЦІНКА',
  'Raw Score': 'Необроблена оцінка',

  // Settings ManageBattery (index.js)
  'Battery name': 'Назва батареї',
  'Search existing assessments': 'Доступні опитники',
  'Add battery': 'Додати батарею',

  // Settings IdealProfile (IdealProfileScales.js)
  'Ideal profile type:': 'Тип ідеального профілю:',
  'Unsaved data': 'Зміни не збережено',
  'The current ideal profile has not been saved. Would you like to save it?': 'Чи бажаєте ви зберегти внесені зміни?',

  addIdealProfileGroups: 'Групи',

  // Sidebar.js
  Logout: 'Вихід із системи',

  // Settings IdealProfile (IdealProfile.js)
  'Delete ideal profiles': 'Видалити',
  'selected ideal profiles?': 'вибрані ідеальні профілі?',
  'this ideal profile?': 'цей ідеальний профіль?',

  // Settings AddIdealProfile.js
  'Add ideal profile': 'Додати ідеальний профіль',
  'Ideal profile name': 'Назва ідеального профілю',
  'I will manually select the values': 'Я виберу значення вручну',
  'I will use respondent data to determine the values': 'На основі даних респондентів',
  Test: 'Тест',
  // (advancedFilterOptions.js)
  'Is equal to': 'Дорівнює',
  'Is not equal to': 'Не дорівнює',
  'Starts with': 'Починається з',
  Contains: 'Містить',
  'Does not contain': 'Не містить',
  'Ends with': 'Закінчується на',
  'Lesser than': 'Менш ніж',
  'Greater than': 'Більш ніж',
  '1 day': '1 день',
  '3 days': '3 дні',
  '1 week': '1 тиждень',
  '2 weeks': '2 тижні',
  '1 month': '1 місяць',
  '1 year': '1 рік',

  // groupsTypes.js
  Unsupervised: 'Без присутності адміністратора',
  Supervised: 'У присутності адміністратора',
  // idealProfileTypes.js
  'Manually configured': 'Сформований вручну',
  'Based on respondent data': 'Базується на даних респондента',

  // inviteCreationCategories.js
  'Personality tests': 'Особистісні опитники',
  'Interests Values Attitudes': 'Інтереси Цінності Стосунки',
  Reasoning: 'Тести здібностей',
  Value: 'Значення',
  'Preferences/Styles': 'Уподобання/Стилі',
  'Custom battery': 'Кастомізована батарея',
  'General Solutions': 'Загальні рішення',
  'Screening Solutions': 'Скринінгові рішення',
  Solution: 'Рішення',
  Battery: 'Батарея',
  Assessment: 'Опитник',
  'Every 4 days': 'Кожні 4 дні',
  'Every 3 days': 'Кожні 3 дні',
  'Every 2 days': 'Кожні 2 дні',
  'Every day': 'Кожного дня',
  "Don't send": 'Не надсилайте',
  Immediate: 'Негайно',

  // inviteStatuses.js
  'Not sent': 'Не віправлено',
  Scheduled: 'Заплановано',
  Sent: 'Відправлено',
  Failed: 'Помилка надсилання',

  // raterRoles.js
  Manager: 'Менеджер',
  Peer: 'Колега',
  'Direct report': 'Підлеглий',

  // respondentSelectOptions.js
  'No Qualification': 'Освіта відсутня',
  'Secondary School': 'Середня школа',
  'Industry or Trade Training': ' Навчання у галузі виробництва або торгівлі',
  'Professional Qualification': ' Професійна кваліфікація',
  'Tertiary Institute Diploma/Certificate': ' Свідоцтво про середню освіту',
  'University Bachelor Degree': ' Бакалавр',
  'Postgraduate Diploma/Certificate': 'Магістр ',
  'Bachelor Honours': 'Диплом бакалавра з відзнакою',
  'Masters Degree': 'Ступінь магістра',
  'Doctorate Degree': 'Кандитат/доктор наук',
  'African American': 'Афро-американець',
  Aborigine: 'Абориген',
  Algerian: 'Алжирець',
  African: ' Африканець',
  Afrikaner: 'Африканер',
  Albanian: 'Албанець',
  American: 'Американець',
  Argentinian: ' Аргентинець',
  'Australian Aborigine': 'Австралійський абориген',
  Arab: 'Араб',
  Asian: 'Азіат',
  Austrian: 'Австрієць',
  Australian: 'Австралієць',
  Bangladeshi: 'Бангладешець',
  Balinese: 'Балієць',
  'Black British': 'Афро-британець',
  'Black Canadian': 'Афро-канадієць',
  Belgian: 'Бельгієць',
  'Black Carribean': ' Чорношкірий житель Карибського регіону',
  'Black Indian': 'Афро-індієць',
  'Black African': 'Чорношкірі африканці',
  Bolivian: 'Болівієць',
  Bengali: 'Бенгалець',
  'Black Other': 'Інші чорношкірі',
  Bosnian: ' Боснієць',
  Brazilian: ' Бразилець',
  British: ' Британець',
  Belarusian: ' Білорус',
  Bulgarian: ' Болгарин',
  Burmese: 'Бірманець',
  Canadian: 'Канадієць',
  Castilian: ' Кастілець',
  Catalan: 'Каталонець',
  Celtic: 'Кельт',
  Chinese: ' Китаєць',
  'Channel Islander': 'Житель Нормандських островів',
  Cossack: 'Козак',
  Chilean: ' Чилієць',
  Cambodian: 'Камбоджієць',
  Chechen: 'Чеченець',
  Colombian: ' Колумбієць',
  'Costa Rican': 'Костаріканець',
  Croatian: 'Хорват',
  Corsican: 'Корсиканець',
  Cantonese: 'Житель Кантона (Гуанчжоу)',
  Cuban: 'Кубинець',
  Cypriot: 'Кіпріот',
  'Cypriot Greek': ' Грек- кіпріот',
  Czech: 'Чех',
  Danish: 'Данець',
  Dutch: 'Голландець',
  Eurasian: ' Євразієць',
  Ecuadorian: 'Еквадорець',
  Egyptian: 'Єгиптянин',
  English: 'Англієць',
  Estonian: 'Естонець',
  Ethiopian: 'Ефіоп',
  European: 'Європеєць',
  Fijian: 'Фіджіець',
  Filipino: 'Філіппінець',
  Flemish: 'Фламандець',
  Finnish: 'Фінн',
  French: 'Француз',
  Gaelic: 'Гел',
  Georgian: 'Грузин',
  German: 'Німець',
  Greek: ' Грек',
  Hungarian: 'Угорець',
  Hispanic: 'Житель США іспанського або латино-американського походження',
  'Hong Kong Chinese': 'Житель Гонконгу китайського походження',
  Icelandic: 'Ісландець',
  Indonesian: 'Індонезієць',
  Irani: 'Іранець',
  Indian: 'Індієць',
  'Iranian/Persian': 'Іранець/перс',
  Iraqi: 'Житель Іраку',
  Irish: 'Ірландець',
  'Iranian Arab': 'Іранець арабського походження',
  Iranian: 'Іранець',
  Italian: 'Італієць',
  Jamaican: 'Житель Ямайки',
  Jewish: ' Єврей',
  'Jewish/Israeli': ' Єврей/Ізраїльтянин',
  Jordanian: ' Йорданець',
  Japanese: ' Японець',
  Kenyan: ' Кенієць',
  Kiwi: 'Новозеландець',
  Kongo: ' Конголезець',
  Korean: ' Кореєць',
  Latin: 'Латин',
  Lebanese: ' Ліванець',
  Lithuanian: 'Литовець',
  Libyan: ' Лівієць',
  'Latin American': 'Латиноамериканець',
  Latino: 'Житель США латиноамериканського походження',
  Luxembourger: 'Люксембуржець',
  Latvian: ' Латвієць',
  Macedonian: 'Македонець',
  'Middle Eastern': 'Житель Близького Сходу',
  Malaitian: 'Житель Малайзії',
  Maori: 'Маорі',
  Monacan: 'Житель Монако',
  Moroccan: 'Черногорець',
  Maltese: 'Марокканець',
  Mexican: 'Мальтієць',
  Malay: 'Мексиканець',
  'Mixed Race': 'Малаєць',
  'New Zealand European': 'Новозеландець європейського походження',
  Nigerian: 'Нігерієць',
  Norwegian: 'Норвежець',
  Nepalese: 'Непалець',
  'New Zealander': ' Новозеландець',
  'Other Ethnicity': 'Представник іншої етнічної чи расової приналежності',
  Paraguayan: 'Парагваєць',
  'Pacific Islander': 'Американець з тихоокеанських островів',
  Persian: 'Перс',
  Peruvian: ' Перуанець',
  'Persian/Iranian': 'Перс/ Іранець',
  Pakistani: ' Пакистанець',
  Palestinian: ' Палестинець',
  Polish: ' Поляк',
  'Papua New Guinean': 'Житель Папуа Нової Гвінеї',
  'Puerto Rican': 'Пуерторіканець',
  Portuguese: 'Португалець',
  Polynesian: ' Полінізієць',
  Romanian: 'Румун',
  Russian: 'Росіянин',
  Samoan: 'Самоанець',
  'South African Coloured': 'Південноафриканець',
  Scottish: 'Шотландець',
  Swede: ' Швед',
  'South African European': 'Південно-африканець європейського походження',
  Serbian: 'Серб',
  Swedish: 'Швед',
  'South American Indian': 'Південно-мериканець індійського походження',
  Sicilian: 'Словак',
  Slovak: "Слов'янин",
  Slav: 'Словен',
  Spanish: 'Іспанец',
  'Sri Lankan': 'Житель Шрі Ланки',
  'Southeast Asian': 'Житель Південної Азії',
  Swiss: ' Швейцарець',
  Swahili: ' Суахілі',
  Swazi: ' Свазі',
  Taiwanese: 'Мешканець Тайваню',
  Thai: 'Таєць',
  Turkish: 'Турок',
  Tamil: 'Таміл',
  Tongan: 'Тонганець',
  Rwandan: 'Руандер',
  Tunisian: 'Тунісець',
  Udi: 'Уді',
  Ugandan: 'Угандієць',
  Ukrainian: ' Українець',
  Uruguayan: 'Уругваєць',
  Vietnamese: "В'єтнамець",
  Welsh: 'Валлієць',
  White: 'Білий',
  'West Indian': 'Житель Вест-Індії',
  Zimbabwean: 'Зімбабвієць',
  Zulu: 'Зулус',
  '- Chief executive': '- Голова виконавчої влади, виконавчий директор',
  '- Administrative or commercial manager': '- Адміністративний або комерційний менеджер',
  '- Production or specialized services manager': '- Керівник з виробництва або спеціалізованих послуг',
  '- Hospitality, retail or other services manager':
    '- Менеджер у сфері готельного господарства, роздрібної торгівлі або інших послуг',
  '- Senior manager': '- Менеджер вищої ланки',
  '- Middle manager': '- Менеджер середньої ланки',
  '- Junior manager/supervisor': '- Менеджер нижчої ланки/ супервайзер',
  '- Other': '- інше',
  Professional: 'Кваліфікований спеціаліст',
  '- Science or engineering professional': '- Наука і технології',
  '- Health professional': "- Кваліфікований фахівець в галузі охорони здоров'я",
  '- Teaching professional': '- Кваліфікований фахівець з навчання',
  '- Business or administration professional': '- Кваліфікований фахівець в галузі управління бізнесом',
  '- Information and communications technology professional':
    '- Фахівець в галузі інформаційних і комунікаційних технологій',
  '- Legal, social or cultural professional':
    '- Кваліфікований фахівець в галузі юриспруденції,соціальної сфери чи культури',
  'Technician or associate professional': 'Технік або технічний спеціаліст',
  '- Science or engineering associate professional': '- Наука і технології',
  '- Health associate professional': "- Кваліфікований фахівець в галузі охорони здоров'я",
  '- Business or administration associate professional': '- Бізнес і менеджмент',
  '- Legal, social, cultural or related associate professional':
    '- Юриспруденція, соціальна сфера, культура або суміжні сфери',
  '- Information and communications technician': "- Технічний фахівець в галузі інформації та зв'язку",
  'Clerical support worker': 'Офісний працівник',
  '- General or keyboard clerk': "- Офісний працівник або складач текстів на комп'ютері",
  '- Customer services clerk': '- Спеціаліст з обслуговування клієнтів',
  '- Numerical or material recording clerk': '- Обліковець',
  'Service or sales worker': 'Працівник сфери обслуговування або продажів',
  '- Personal service worker': '- Робочий в сфері побутових послуг',
  '- Sales worker': '- Працівник відділу продажів',
  '- Personal care worker': '- Доглядальниця',
  '- Protective services worker': 'Охоронець',
  'Skilled agricultural, forestry or fishery worker':
    'Кваліфікований робітник сільського, лісового або рибного господарства',
  '- Market-oriented skilled agricultural worker': '- Кваліфікований робітник сільського господарства',
  '- Market-oriented skilled forestry, fishing or hunting worker':
    '- Кваліфікований робітник у сфері лісового господарства, рибальства чи мисливства',
  '- Subsistence farmer, fisher, hunter or gatherer':
    '- Фермер, рибалка, мисливець або збирач (наприклад, грибів та ягід)',
  'Craft or related trades worker': 'Майстер ремесла ',
  '- Building or related trades worker, excluding electrician':
    "- Робочий будівельної або пов'язаної з будівництвом спеціальності, за винятком електрика",
  '- Metal, machinery or related trades worker':
    "- Робочий у сфері металургії, машинобудування або пов'язаних з ними сферах",
  '- Handicraft or printing worker': '- Ремісник або друкар',
  '- Electrical or electronic trades worker': '- Електрик або фахівець з електронної торгівлі',
  '- Food processing, wood working, garment or other craft or related trades':
    "- Технолог виробництва харчових продуктів, деревообробки, пошиття одягу або пов'язаних з цим занять",
  'Plant or machine operator, or assembler': 'Верстатник або робітник-збиральник',
  '- Stationary plant or machine operator': '- Оператор стаціонарної установки або робітник-верстатник',
  '- Assembler': '- Робочий-збиральник',
  '- Driver or mobile plant operator': '- Водій або оператор мобільної установки',
  'Elementary occupation': '- Некваліфікована робота/рід занять',
  '- Cleaner or helper': '- Прибиральник або помічник',
  '- Agricultural, forestry or fishery labourer': '- Робочий в галузі сільського, лісового або рибного господарства',
  '- Labourer in mining, construction, manufacturing or transport': '- Робітник різних галузей',
  '- Food preparation assistant': '- Помічник кухаря',
  '- Street or related sales or service worker': '- Вуличний/Роздрібний торговець або працівник сфери послуг',
  '- Refuse worker or other elementary worker': '- Прибиральник або інший некваліфікований робітник',
  'Armed forces occupation': ' Військовослужбовець',
  '- Commissioned armed forces officer': '- Офіцер Збройних Сил',
  '- Non-commissioned armed forces officer':
    '- Військовослужбовець у званні або сержанта, або старшини, або прапорщика',
  '- Armed forces occupation, other rank': '- Військовослужбовці, інше звання',
  'Local Government/Authority': 'Місцева влада',
  'Private, Corporate ': 'Приватний, корпоративний',
  'Public service, Fed/State Govt': 'Державна служба, федеральна/державна влада',
  'State Owned Corporation': 'Державна корпорація',
  Unknown: 'Невідомий',
  'Administrative and Support Service Activities': 'Адміністративна діяльність',
  'Agriculture, Forestry and Fishing': 'Сільське, лісове та рибне господарство',
  'Arts, Entertainment and Recreation': 'Мистецтво, розваги та відпочинок',
  'Banking & Finance': 'Банківська справа та фінанси',
  'Business Services': 'Бізнес-послуги',
  'Architecture and Construction': 'Архітектура і будівництво',
  'Education and Training': 'Освіта і навчання',
  'Utilities (Gas, Water, Electric, etc)': 'Комунальні послуги (газ, вода, електрика і т.д.)',
  Engineering: 'Інженерна діяльність (інжиніринг)',
  'Hospitality and Tourism': 'Сфера туризму',
  'Human Resources': 'Управління персоналом',
  'Health Sector': "Сектор охорони здоров'я",
  'Information and Technology': 'Інформаційні технології',
  Insurance: 'Страхування',
  'Law Enforcement': 'Правоохоронці',
  Manufacturing: 'Виробництво',
  'Mining and Quarrying': "Гірнича промисловість і кар'єрні роботи",
  'Military & Defence': 'Збройні сили та оборонна промисловість',
  'Oil and Gas/Energy Exploration': 'Розробка нафтових і газових родовищ',
  'Pharmaceutical Industry': 'Фармацевтична промисловість',
  'Public Administration': 'Державне управління',
  'Real Estate Activities': 'Робота з нерухомістю',
  'Scientific and Technical Activities': 'Науково-технічна діяльність',
  Security: 'Безпека',
  'Social Work': 'Соціальна робота',
  Telecommunications: ' Телекомунікації',
  'Transportation and Storage': ' Перевезення і складське господарство',
  Media: 'Засоби інформації',
  'Wholesale and Retail Trade': 'Оптова та роздрібна торгівля',
  Arabic: 'Арабська',
  'Brazilian Portuguese': 'Бразильська португальська',
  'Chinese-Simplified': 'Китайська - спрощена',
  'Chinese-Traditional': 'Китайська - традиційна',
  Hebrew: ' Іврит',
  'Hindi-Urdu': 'Хінді-урду',
  isiNdebele: 'Ндебелє',
  isiXhosa: 'Кхоса',
  isiZulu: 'Зулу',
  'Latin American Spanish': 'Латиноамериканська іспанська',
  Malayalam: 'Малайська',
  Punjabi: 'Панджабі',
  Sepedi: 'Сепеді',
  'Southern Thai': ' Южно-тайська',
  Sesotho: 'Сесото',
  Setswana: 'Сетсвана',
  siSwati: 'Сваті',
  'Tagalog (Filipino)': 'Тагальська (філіппінська)',
  Turkmen: 'Туркменська',
  Tshivenda: 'Венда',
  Xitsonga: 'Тсонга',
  Male: 'Чоловіча',
  Female: 'Жіноча',
  'Not Specified': 'Не вказано',

  // errorMessages.js
  'Email address is not valid': 'адреса електронної пошти невірна',

  // table bulkActions assessments.js
  'Edit battery': 'Редагувати',
  // table singleRowActions competency.js
  'Add competency': 'Додати компетенцію',
  'Edit competency category': 'Редагувати ',
  'Delete competency category': 'Видалити',
  'Edit competency': 'Редагувати',
  'Delete competency': 'Видалити',
  'Edit question': 'Редагувати',
  'Delete question': 'Видалити',

  // table singleRowActions competencyGroup.js
  'Delete competency group': 'Видалити',
  'Go to competency groups details': 'Переглянути деталі',

  // table singleRowActions  group.js
  'Invite respondents to questionnaire': 'Запросити пройти опитування',
  'Administer answer sheet': 'Заповнити бланк відповідей',
  'Administer managed group': 'Провести сесію оцінки для групи',
  'Create an ideal profile based on selected group': 'Створити ідеальний профіль',
  'Create a Norm based on selected group': 'Створити нормативну групу',
  'View or edit': 'Переглянути деталі',
  Delete: 'Видалити',

  // table singleRowActions invites.js
  Remind: 'Нагадати',

  // table singleRowActions projects.js
  'Add to favorites': 'Додати в обране',
  View: 'Переглянути',
  'Delete Project': 'Видалити проєкт',

  // table singleRowActions raters.js
  'Delete rater': 'Видалити респондента',

  // table singleRowActions reports.jsAdminister questionnaire
  Open: 'Відкрити',
  Download: 'Завантажити',

  // table singleRowActions respondents.js
  'Administer questionnaire': 'Адмініструвати сесію оцінки',
  'Invite to questionnaire': 'Запросити пройти опитування',
  'Add tag': 'Додати тег',

  // table singleRowActions session.js
  'Invite raters who have not yet complete': 'Надіслати запрошення ',
  'Invite who have not yet been invited': 'Надіслати запрошення ',
  'Send reminder to raters who have already been invited but have not yet completed': 'Надіслати нагадування ',
  'Invite to specify own rater': 'Запросити визначити респондентів',
  'Send invitation to self': 'Надіслати запрошення',
  'Edit session': 'Редагувати сесію',

  // table bulkActions assessments.js
  'Add custom battery': 'Створити батарею',
  'Edit assessment': 'Редагувати ',
  'Delete Assessment(s)': 'Видалити',

  // table bulkActions groups.js
  'Create a Norm based on the selected group(s)': 'Створити нормативну групу',
  'Create an Ideal profile based on the selected group(s)': 'Створити ідеальний профіль',
  'Delete selected group(s)': 'Видалити',
  'Generate an open group link': 'Згенерувати відкрите посилання',

  // table bulkActions idealProfiles.js
  'Edit ideal profile': 'Редагувати ідеальний профіль',

  // table bulkActions invites.js
  'Copy invite link to clipboard': 'Скопіювати посилання-запрошення',
  'Delete invites': 'Видалити запрошення',

  // table bulkActions projects.js
  'Edit project details': 'Редагувати дані проєкту',
  'Delete projects(s)': 'Видалити',
  // table bulkActions raters.js
  'Send invite to selected rater(s)': 'Надіслати запрошення',
  'Remove selected rater(s) from session': 'Видалити з сесії',

  // table bulkActions reports.js
  Actions: 'Дії',
  'Delete selected report(s)': 'Видалити',
  'Download selected report(s)': 'Завантажити',
  'View selected report(s)': 'Переглянути',

  // table bulkActions respondentGroups.js
  'Remove respondent from selected group(s)': 'Видалити з виділених груп',
  // table bulkActions respondents.js
  'Administer assessment': 'Провести сесію оцінки',
  'Administer assessment for': 'Сесія оцінки для %{respondentName}',
  'Invite respondent(s) to assessment': 'Надіслати запрошення ',
  'Request report(s)': 'Згенерувати звіт(-и)',
  'Go to respondent details': 'Переглянути деталі',
  'Manage tags for selected respondent(s)': 'Оновити теги',
  'Add new group from selection': 'Створити групу із вибірки',
  'Add to existing group': 'Додати до наявної групи',
  'Delete selected respondents from group': 'Видалити з групи',
  'Delete selected respondents': 'Видалити',
  'this respondent': 'цього респондента',
  'selected respondents': 'вибрані респонденти',

  // table bulkActions respondentsTest.js
  'Delete selected result(s)': 'Видалити',

  // table bulkActions sessions.js
  'Invite to specify own raters': 'Запросити визначити респондентів',
  'Delete Session(s)': 'Видалити',

  // table bulkActions templates.js
  'Go to template details': 'Переглянути деталі',
  'Delete selected template(s)': 'Видалити',
  // table bulkActions welcomeText.js
  'Go to welcome text details': 'Переглянути деталі',
  'Delete selected welcome text(s)': 'Видалити',
  // table entitiesDropdownActions groupDetails.js
  'Edit title': 'Редагувати назву',
  'Duplicate 360 assessment': 'Дублювати 360 опитник',
  'Delete 360 assessment': 'Видалити 360 опитник',
  // table entitiesDropdownActions sessions.js
  'Invite raters who have not yet completed': 'Надіслати запрошення',

  // table tableActions competencyGroupsDetails.js
  'Review competencies': 'Переглянути компетенції',

  // table tableActions customGroups.js
  'Add new 360 assessment': 'Створити новий 360 опитник',

  // table tableActions raters.js
  'Add rater': 'Додати респондента',
  // table tableActions respondents.js
  'Import respondents': 'Імпортувати респондентів',

  // table tableActions templates.js
  'Add new template': 'Створити новий шаблон',
  'Edit template': 'Редагувати шаблон',
  'Edit welcome text': 'Редагувати вступний текст',
  // QuestionOrder.js
  Default: 'За замовчуванням',
  Custom: 'Задане вручну',
  Random: 'Випадкове',
  // tables columns sessions.js
  Invited: 'Запрошення надіслане',
  'Invite Not Sent': 'Запрошення не надіслане',

  'Show expired': 'Показати неактивні',
  'Sorry we could not find any': 'Вибачте, ми не знайшли жодного %{entityName}',
  'Sorry, we could not find any respondents': 'На жаль, ми не знайшли жодного респондента',
  'Sorry, we could not find any reports': 'На жаль, ми не знайшли жодного звіту',
  'Sorry, we could not find any results': 'На жаль, ми не знайшли жодного результату',
  'Sorry, we could not find any invites': 'На жаль, ми не знайшли жодного запрошення',
  'Sorry, we could not find any records': 'На жаль, ми не знайшли жодного запису',
  'Sorry, we could not find any projects': 'На жаль, ми не знайшли жодного проєкту',
  'Sorry, we could not find any tests': 'На жаль, ми не знайшли жодного тесту',
  'Sorry, we could not find any groups': 'На жаль, ми не знайшли жодної групи',
  'Sorry, we could not find any notifications': 'На жаль, ми не знайшли жодного сповіщення',
  'Sorry, we could not find any raters': 'На жаль, ми не знайшли жодного респондента',
  'Sorry, we could not find any templates': 'На жаль, ми не знайшли жодного шаблону',
  'Sorry, we could not find any sessions': 'На жаль, ми не знайшли жодного сеансу',
  'Sorry, we could not find any welcome texts': 'На жаль, ми не знайшли вступних текстів',
  'Sorry, we could not find any competency groups': 'На жаль, ми не знайшли жодної групи компетенцій',
  'Sorry, we could not find any norms': 'На жаль, ми не знайшли жодної норми',
  'Sorry, we could not find any response scales': 'На жаль, ми не знайшли жодної шкали відповідей',
  'Number of items': 'Кількість пунктів',
  Categories: 'Категорії',
  'User name': "Ім'я користувача",
  'Update amount': 'Оновити кількість',
  Members: 'Учасники',
  'Invitation Date': 'Дата запрошення',
  'Respondent Name': "Ім'я респондента",
  Report: 'Звіт',
  'Date submitted': 'Дата відправки',
  Status: 'Статус',
  'Not invited': 'Запрошення  не надіслане',
  'Not completed': 'Не закінчено',
  'Completed Date': 'Дата закінчення',
  'Direct Report': 'Підлеглий',
  Progress: 'Прогрес',
  'Assessments Done': 'Проведені оцінки',
  'Add new project': 'Додати новий проєкт',
  Date: 'Дата',
  'Loading entities': 'Звавантаження %{entityName}',
  Created: 'Дата створення',
  'No options': 'Варіантів немає',
  createOption: 'Створити',
  '**Default': '**За замовчуванням',
  // Settings Account ChangePassword.js

  'Passwords must match': 'Паролі повинні співпадати',
  'Change password': 'Змінити пароль',
  'Confirm new password': 'Підтвердити новий пароль',
  'New password': ' Новий пароль',
  Change: 'Змінити',
  'Pins must match': 'Пін-коди повинні співпадати',
  'Change pin': 'Змінити пін-код',
  'Confirm new pin': 'Підтвердити новий пін-код',
  'New pin': 'Новий пін-код',
  'Total:': 'Всього:',
  'Loading credits history...': 'Завантаження історії кредитів',
  'From date': 'З',
  'To date': 'До',
  Pin: 'Пін-код ',
  'Receive email notifications': 'Отримувати сповіщення на електронну пошту',
  'Complete Status': 'Статус проходження опитника',
  'Invite Status': 'Статус розсилки запрошень',
  'Not Invited': 'Запрошення не відправлені',
  'Sessions count': 'Кількість сесій',

  // ImportSessions
  'Import sessions': 'Імпортувати сесії',
  prepare: 'підготувати',
  preview: 'переглянути',
  import: 'імпортувати',
  'Prepare a csv file yourself or': 'Підготувати csv файл самостійно або',
  'download the sample import file': 'Завантажте зразок імпортованого файлу',
  'Make sure the data order is set out correctly': 'Переконайтесь, що дані введені в правильному порядку',
  'Upload the file': 'Завантажте файл',
  'Drag and drop file here or browse the file': 'Перетягнути файл сюди або вибрати у браузері',
  'Upload the csv file to import respondents': 'Завантажте csv файл для імпорту респондентів',
  'The first line contains field headers': 'Перший рядок містить заголовки поля',
  'Choose file': 'Виберіть файл',

  // AccountTabs
  general: 'загальний',
  'credit updates': 'оновлення кредитів',
  'Reports Stats': 'згенеровані звіти',
  'assessments stats': 'використання опитників',

  // ReportUsage
  total: 'всього: %{total}',
  'Report name': 'Назва звіту',
  'Usage count': 'Кількість використань',
  'Assessment name': 'Назва інструменту оцінки',
  Total: 'Загальна кількість',
  'Loading requested reports': 'Завантаження',
  'Loading used assessments': 'Завантаження',

  // Toasts & system notifications
  'Tag(s) have been deleted': 'Тег(-и) видалено',
  'Loading items': 'Завантаження елементів',
  'Saving results': 'Збереження результатів',
  'Ideal profile has been created': 'Ідеальний профіль створено',
  'Copy of 360 survey has been created': 'Копію 360 опитування було створено',
  'Tag(s) have been updated': 'Тег(-и) оновлені',
  'Welcome text has been saved': 'Вступний текст збережений',
  'Welcome text has been updated': 'Вступний текст оновлено',

  // TODO об'єднати в один переклад
  'It looks like you have been editing something': 'Схоже, ви щось редагували',
  'Would you like to save your changes before leaving': 'Бажаєте зберегти зміни перед виходом',
  Warning: 'Увага',

  // Toasts
  groupCreationToast: 'Групу створено',
  respondentsDeletionToast: 'Респондента(-ів) видалено',
  resultsSuccessToast: 'Результати для %{respondentInfo} успішно додані',
  surveyDeletionCancelToast: '360 опитування, використані у проєкті, неможливо видалити',
  creditBalanceUpdateToast: 'Баланс кредитів оновлено',
  correctDataToast: 'Переконайтеся, що всі дані додано правильно',
  downloadLimitToast: 'Упс! Ви не можете завантажити більше 50 звітів одночасно',
  categoryUpdateToast: 'Категорію оновлено',
  newCategoryToast: 'Нову категорію додано',
  createdRequestToast: 'Запит створено',
  respondentCreationToast: 'Респондента додано',
  respondentUpdateToast: 'Інформацію оновлено',
  clipboardCopyToast: 'Успішно скопійовано в буфер обміну',
  addedAssessmentToast: 'Опитник додано',
  deletedAssessmentToast: 'Опитник видалено',
  completedAssessmentToast: '%{respondentName} завершив %{assessment}',
  importRespondentsErrorToast: 'Щось пішло не так під час імпорту респондентів',
  importSessionsErrorToast: 'Щось пішло не так під час імпорту сесії',
  competencyTitleUpdateToast: 'Шаблон оновлено',
  competencyUpdateToast: 'Компетенцію оновлено',
  newCompetencyToast: 'Компетенція додана',
  questionUpdateToast: 'Питання оновлено',
  questionAdditionToast: 'Питання додане',
  groupLinkErrorToast: 'Щось пішло не так під час створення групового посилланя, будь ласка повторіть ',
  reportsDeletionToast: 'Звіт(-и) видалено',
  resultsSavingErrorToast: 'Помилка збереження результатів для %{respondentName} %{assessment}',
  reportRequestCreationToast: 'Звіт створено',
  assessmentDataFailToast: 'Не вдалося завантажити дані опитника',
  scaleUpdatedToast: 'Шкалу відповідей оновлено',
  scaleCreatedToast: 'Шкалу відповідей збережено',
  scaleDeletionToast: 'Шкала(и) відповідей видалено',
  surveyUpdatedToast: '360 опитник оновлено',
  normsDeletionToast: 'Норми видалені',
  'Deletion blocked': 'Видалення заблоковано',
  groupCreationErrorToast: 'Помилка при створенні групи',
  groupsDeletionToast: 'Групу видалено',
  reportsGenerationToast: 'Звіти запрошені успішно',
  invitesDeletionToast: 'Запрошення видалено',
  normCreationToast: 'Норми створено',
  normCreationErrorToast: 'Неможливо створити норму, спробуйте ще раз',
  projectDeletionToast: 'Проєкт видалено',
  projectsDeletionToast: 'Проєкти видалено',
  projectCreationToast: 'Проєкт створено',
  projectUpdateToast: 'Проєкт оновлено',
  ratersDeletionToast: 'Респондента(-ів) видалено',
  categoryDeletionToast: 'Категорія компетенцій видалена',
  resultsDeletionToast: 'Результат(-и) видалені',
  tagsAdditionToast: 'Теги додані',
  addedToGroupToast: 'Додано в групу успішно',
  questionnaireSessionErrorToast: 'Спроба створити сесію оцінки невдала, спробуйте будь ласка пізніше',
  sessionAdditionToast: 'Сесію додано',
  ratersAdditionToast: 'Сесія респондента додана',
  competencyDeletionToast: 'Компетенцію видалено',
  questionDeletionToast: 'Питання видалено',
  assessmentsDeletionToast: 'Опитник видалено',
  profilesDeletionToast: 'Ідеальний профіль видалено',
  profileUpdateToast: 'Ідеальний профіль оновлено',
  templatesDeletionToast: 'Шаблон(-и) видалено',
  templateAdditionToast: 'Шаблон збережено',
  templateUpdateToast: 'Шаблон оновлено',
  welcomeTextsDeletionToast: 'Вступний текст видалено',
  batteryAdditionToast: 'Батарею створено',
  batteryUpdateToast: 'Батарею оновлено',
  batteriesDeletionToast: 'Батареї видалено',
  batteryDeletionToast: 'Батарею видалено',
  passwordUpdateSuccessToast: 'Пароль успішно оновлено',
  pinUpdateSuccessToast: 'Пін успішно оновлено',
  'Reset assessment deadline': 'Скидання терміну оцінювання',
  Update: 'обновлять',
  updateAssessmentInvitationToast: 'Актуальные приглашения',
  updateProjectInvitationToast: 'Актуальные приглашения',
  biodataTooltip:
    'Шаблоны биоданных позволяют собирать персонализированные биографические данные респондентов. Создавайте шаблоны биоданных в области «Настройки».',
  'Default Biodata Template has been updated': 'Шаблон биоданных по умолчанию обновлен.',
  DEFAULT: 'по умолчанию',
  'Bio data template': 'Биографический шаблон данных',
  biodataDefaultTemplateLabel: '** Нет шаблона для биографических данных (все категории включены) **',
  'Add Bio Data template': 'Добавить шаблон биографических данных',
  'Loading biodata templates': 'Загрузка шаблонов биографических данных.',
  'Add Name': 'Добавить имя',
  'Add description': 'Добавить описание',
  selectEditCategoryValues: 'Выберите или отредактируйте значения:',
  'Add values to category': 'Добавить значения категории ',
  'Select values for this category': 'Выберите значения для этой категории',
  'Select all': 'Выбрать все',
  'Biodata Template has been created': 'Шаблон биографических данных создан',
  'Biodata Template has been updated': 'Шаблон биографических данных был обновлен',
  'Biodata template(s) deleted': 'Шаблон(ы) биографических данных удален(ы)',
  biodataDefaultTemplate: 'Установлено по умолчанию',
  'Delete selected biodata template(s)': 'Удалить выбранный(ые) шаблон(ы) биографических данных',
  'Delete biodata templates': 'Удалить шаблоны биографических данных',
  'the selected biodata templates?': 'Выбранные шаблоны биографических данных?',
  'this biodata template?': 'Этот шаблон биографических данных?',
  'Update default biodata template': 'Обновить шаблон биографических данных по умолчанию',
  'Are you sure you want to set this template as default?':
    'Вы уверены, что хотите установить этот шаблон по умолчанию?',
  'Biodata Configuration': 'Шаблоны биографических данных',
  defaultCompleteBioTemplate: '** Усі шаблони біоданих (Включені всі категорії) **',
  defaultEmptyBioTemplate: '** Порожній шаблон біоданих (Категорії не включені) **',
  invitesSentToast: 'Запрошення надіслано',
  selectNormForReport: 'Будь ласка, виберіть норму',
  visibleNormForReport: 'Використовувати норму для звітів',
  hideNormForReport: 'Приховати норму для звітів',
  showNormTitle: 'Зробити видимим для списку запитів на звіти',
  showConfirmationMessageTitle: 'Ви впевнені, що хочете зробити видимим',
  hideNormTitle: 'Приховати для списку запитів на звіти',
  hideConfirmationMessageTitle: 'Ви впевнені, що хочете видалити',
  showhideConfirmationMessageDescription1: 'вибрані норми для генерації звітів?',
  showhideConfirmationMessageDescription2: 'цю норму для генерації звітів?',
  visible: 'Видимий',
  normsVisibilityToast: 'Видимість норми оновлено',
  updateGroupToast: 'Групу оновлено',
  updateGroupErrorToast: 'Помилка під час оновлення групи',
  'Edit Group Details': 'Редагувати деталі групи',
  'Duplicate Group': 'Дублювати групу',
};
export default translations;
