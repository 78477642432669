import { I18n } from 'react-redux-i18n';

const raterRoles = (project) => [
  {
    value: 'self',
    label: (project && project.selfAlias) || I18n.t('Self'),
    backendValueReference: 0,
  },
  {
    value: 'manager',
    label: (project && project.managerAlias) || I18n.t('Manager'),
    backendValueReference: 1,
  },
  {
    value: 'peer',
    label: (project && project.peerAlias) || I18n.t('Peer'),
    backendValueReference: 2,
  },
  {
    value: 'direct',
    label: (project && project.directReportAlias) || I18n.t('Direct report'),
    backendValueReference: 3,
  },
  {
    value: 'other',
    label: (project && project.otherAlias) || I18n.t('Other'),
    backendValueReference: 4,
  },
];

export default raterRoles;
