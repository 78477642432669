import React from 'react';
import { Bar, BarChart, Label, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';

const Histogram = ({ data, onClose, domain, title }) => {
  return (
    <>
      <StyledPreviewChart>
        <PreviewTitle>{title}</PreviewTitle>
        <StyledCloseIcon onClick={onClose} />
        <BarChart width={280} height={170} data={data}>
          <Bar dataKey="barValue" fill="#40c4ff" background={{ fill: '#f2f2f2' }} />
          <XAxis>
            <Label value={I18n.t('RAW SCORE')} offset={0} position="insideBottom" />
          </XAxis>
          <YAxis domain={domain} scale="linear">
            <Label value={I18n.t('FREQUENCY')} angle={-90} fill="#295368" position="insideBottomLeft" />
          </YAxis>
        </BarChart>
      </StyledPreviewChart>
      <StyledOverlay
        onClick={(event) => {
          event.stopPropagation();
          onClose(false);
        }}
      />
    </>
  );
};

const PreviewTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #295368;
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 12px;
  right: 16px;
  cursor: pointer;
  width: 1rem;
  height: 1rem;

  path {
    fill: ${(props) => props.theme.colors.primary};
  }
`;

const StyledOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  //background: rgba(255, 255, 255, 0.7);
  background: transparent;
  z-index: 100;
  left: 0;
  top: 0;
`;

const StyledBarChart = styled.div`
  cursor: pointer !important;
  width: 112px;
  height: 40px;

  svg {
    cursor: pointer !important;
  }
`;

const StyledPreviewChart = styled(StyledBarChart)`
  position: absolute;
  width: 334px;
  height: 220px;
  background: #ffffff;
  border-radius: 6px;
  padding: 16px 12px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25), 0px 8px 30px rgba(55, 85, 99, 0.3);
  top: 0;
  left: -334px;
  z-index: 101;
`;

export default Histogram;
