import React from 'react';
import styled from 'styled-components';

import { I18n } from 'react-redux-i18n';

const statusesStyles = {
  0: {
    title: I18n.t('Unauthorized'),
    background: '#E0F1F7',
    color: '#008AC0',
  },
  1: {
    title: I18n.t('Authorized'),
    background: '#008AC0',
    color: '#fff',
  },
  2: {
    title: I18n.t('Completed'),
    background: '#0B8D00',
    color: '#fff',
  },
  3: {
    title: I18n.t('Unknown'),
    background: 'rgba(0, 0, 0, 0.14)',
    color: 'rgba(0, 0, 0, 0.4)',
  },
};

const StatusCell = ({ row }) => {
  const styles = statusesStyles[row.original.statusId];
  return <StyledStatus style={styles && { ...styles }}>{I18n.t(styles.title)}</StyledStatus>;
};

const columns = [
  {
    Header: I18n.t('Name'),
    accessor: (data) => `${data.firstName} ${data.familyName}`,
    name: 'name',
    type: 'text',
  },
  {
    Header: I18n.t('Email Address'),
    accessor: 'email',
    name: 'email',
    type: 'text',
  },
  {
    Header: I18n.t('Status'),
    name: 'status',
    Cell: (props) => <StatusCell {...props} />,
  },
  {
    Header: I18n.t('Id'),
    accessor: 'respondentId',
    name: 'id',
    type: 'number',
  },
];

const StyledStatus = styled.div`
  display: flex;
  max-width: 14rem;
  justify-content: center;
  align-items: center;
  min-width: 8rem;
  padding: 0.2rem 0.6rem;
  border-radius: 1rem;
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.5px;
`;

export default columns;
