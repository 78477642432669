import React, { useState } from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import NewModal from '../../reusable/NewModal';
import AnimateHeightWrapper from '../../reusable/AnimateHeight';
import CustomSelect from '../../reusable/Selects/Select';
import DateTimeSelect from '../../reusable/Selects/DateTimeSelect';

import linksData from './mockData';

import { ReactComponent as ArrowRight } from '../../../assets/icons/black-arrow-right.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/black-arrow-down.svg';

const ManageActiveLinks = ({ onClose }) => {
  const [data, setData] = useState(linksData.map((item) => ({ ...item, isOpen: false })));

  const [isOverflowVisible, setOverflowState] = useState(false);

  const onRowClick = (id) => {
    setData((prev) => prev.map((item) => (item.id === id ? { ...item, isOpen: !item.isOpen } : item)));
  };

  const onAnimationEnd = ({ newHeight }) => {
    if (newHeight !== 0) setOverflowState(true);
  };

  const onAnimationStart = ({ newHeight }) => {
    if (newHeight === 0) setOverflowState(false);
  };

  return (
    <StyledModal title={I18n.t('Manage active group links')} isVisible onClose={onClose}>
      <StyledBody>
        {data.map((linkObj) => {
          return (
            <LinkWrapper key={linkObj.id}>
              <VisiblePart onClick={() => onRowClick(linkObj.id)}>
                <IconBox>{linkObj.isOpen ? <ArrowDown /> : <ArrowRight />}</IconBox>
                <span>{linkObj.assessment}</span>
              </VisiblePart>
              <StyledAnimateHeight
                isOpen={linkObj.isOpen}
                height={linkObj.isOpen ? 120 : 0}
                animateOpacity
                onAnimationEnd={onAnimationEnd}
                onAnimationStart={onAnimationStart}
                isOverflowVisible={isOverflowVisible}
              >
                <Row>
                  <StyledSelect
                    options={[]}
                    placeholder={I18n.t('Language')}
                    selectProps={{ value: { value: linkObj.language, label: linkObj.language }, onChange: null }}
                    isDisabled
                  />
                  <StyledSelect
                    options={[]}
                    placeholder={I18n.t('Category')}
                    selectProps={{ value: { value: linkObj.category, label: linkObj.category }, onChange: null }}
                    isDisabled
                  />
                </Row>
                <Row>
                  <StyledDateTimeSelect
                    date={linkObj.creationDate}
                    onDateChange={() => {}}
                    inputName={I18n.t('Created on')}
                    minDate={new Date()}
                    inputWidth="auto"
                    disabled
                  />
                  <StyledDateTimeSelect
                    date={linkObj.expirationDate}
                    onDateChange={() => {}}
                    inputName={I18n.t('Expiration date')}
                    minDate={new Date()}
                    inputWidth="auto"
                  />
                </Row>
              </StyledAnimateHeight>
            </LinkWrapper>
          );
        })}
      </StyledBody>
    </StyledModal>
  );
};

ManageActiveLinks.propTypes = {
  onClose: func.isRequired,
};

const StyledModal = styled(NewModal)`
  min-width: 80rem;
  max-height: 52rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

const StyledBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
`;

const VisiblePart = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.darkBlue2};
  font-weight: 600;
  font-size: 14px;

  padding: 1.2rem;

  &:hover {
    cursor: pointer;
  }
`;

const IconBox = styled.div`
  min-width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const StyledSelect = styled(CustomSelect)`
  width: 30rem;
`;

const StyledDateTimeSelect = styled(DateTimeSelect)`
  width: 30rem;
`;

const StyledAnimateHeight = styled(AnimateHeightWrapper)`
  padding: 0 1.2rem;
  overflow: ${(props) => (props.isOverflowVisible ? 'visible' : 'hidden')} !important;
`;

export default ManageActiveLinks;
