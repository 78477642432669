import React from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { I18n } from 'react-redux-i18n';
import NewModal from '../../reusable/NewModal';
import CustomInput from '../../reusable/FormComponents/Input';
import CustomButton from '../../reusable/Buttons/Button';

import { errorMessages } from '../../../constants/errorMessages';
import { changePassword } from '../../../store/user/actions';

const ChangePasswordModal = ({ onClose }) => {
  const dispatch = useDispatch();

  const { setFieldValue, setFieldError, values, errors, handleSubmit, resetForm } = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: Yup.object().shape({
      newPassword: Yup.string()
        .required(errorMessages.inputField)
        .min(8, errorMessages.tooShort(8))
        .max(20, errorMessages.tooLong(20)),
      confirmNewPassword: Yup.string()
        .required(errorMessages.inputField)
        .min(8, errorMessages.tooShort(8))
        .max(20, errorMessages.tooLong(20))
        .oneOf([Yup.ref('newPassword'), null], I18n.t('Passwords must match')),
    }),
    onSubmit: (values) => {
      dispatch(
        changePassword(values.newPassword, () => {
          onClose();
          resetForm();
        }),
      );
    },
  });

  return (
    <Modal isVisible onClose={onClose} title={I18n.t('Change password')}>
      <Form>
        <StyledInput
          inputName={I18n.t('New password')}
          value={values.newPassword}
          onChange={(e) => {
            setFieldValue('newPassword', e.target.value);
            setFieldError('newPassword', '');
          }}
          name="newPassword"
          error={errors.newPassword}
          type="password"
        />
        <StyledInput
          inputName={I18n.t('Confirm new password')}
          value={values.confirmNewPassword}
          onChange={(e) => {
            setFieldValue('confirmNewPassword', e.target.value);
            setFieldError('confirmNewPassword', '');
          }}
          name="confirmNewPassword"
          error={errors.confirmNewPassword}
          type="password"
        />
      </Form>
      <ButtonsWrapper>
        <StyledButton onClick={handleSubmit}>{I18n.t('Change')}</StyledButton>
      </ButtonsWrapper>
    </Modal>
  );
};

ChangePasswordModal.propTypes = {
  onClose: func.isRequired,
};

const Modal = styled(NewModal)`
  display: flex;
  flex-direction: column;
  min-height: 40rem;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
const StyledInput = styled(CustomInput)`
  margin-bottom: 2rem;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledButton = styled(CustomButton)`
  text-transform: uppercase;
`;

export default ChangePasswordModal;
