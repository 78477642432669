import { I18n } from 'react-redux-i18n';
import { ReactComponent as EditProjectIcon } from '../../assets/icons/sidebar-edit-project.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar_white.svg';

import * as actions from '../actionsList';

const projectsBulkActions = [
  {
    name: actions.RESET_DEADLINE,
    label: I18n.t('Reset assessment deadline'),
    Icon: CalendarIcon,
    isDisabled: (projects) => projects.length > 1,
  },
  {
    name: actions.EDIT_PROJECT_DETAILS,
    label: I18n.t('Edit project details'),
    Icon: EditProjectIcon,
    isDisabled: (projects) => projects.length > 1,
  },
  {
    name: actions.DELETE_PROJECTS,
    label: I18n.t('Delete projects(s)'),
    Icon: DeleteIcon,
  },
];

export default projectsBulkActions;
