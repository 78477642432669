import React from 'react';
import { format } from 'date-fns';
import DefaultBadge from '../../components/reusable/Badges/DefaultBagde';

const BiodataNameCell = ({ row }) => {
  const originalRow = row.original;
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>{originalRow.name}</span>
      {originalRow.isDefault && <DefaultBadge />}
    </div>
  );
};

const DateCell = ({ date }) => {
  return date ? <span>{format(new Date(date), 'dd/M/y')}</span> : null;
};

const columns = [
  {
    Header: 'Template name',
    accessor: 'name',
    name: 'name',
    Cell: BiodataNameCell,
    disableSortBy: true,
  },
  {
    Header: 'Description',
    accessor: 'description',
    name: 'description',
    disableSortBy: true,
  },
  {
    Header: 'Creation Date',
    accessor: (data) => new Date(data.creation),
    Cell: ({ row }) => <DateCell date={row.original.creation} />,
    name: 'creation',
    type: 'date',
    disableSortBy: true,
  },
];

export default columns;
