/* eslint-disable no-nested-ternary */
import { createSelector } from 'reselect';

import { I18n } from 'react-redux-i18n';
import languagesMap from '../../constants/languages';
import storageService from '../../services/storageService';

const getLanguages = (state) => state.user.assessmentsLanguages;
const getTests = (state) => state.user.tests;
const getBatteries = (state) => state.user.batteries;
const getUserCredits = (state) => state.user.user?.credits;
const getUserFrozenCredits = (state) => state.user.user?.totalFrozenCredits;
const getBiodataTemplates = (state) => state.user.biotemplates;

// eslint-disable-next-line import/prefer-default-export
export const selectAvailableLanguages = createSelector([getLanguages], (languages) => {
  const language = storageService.getItem('psLang');

  return languages
    ? languages
        .map((item) => ({
          code: item.langID,
          value: item.langID,
          label: languagesMap[item.langID] || item.langID,
        }))
        .sort((x, y) =>
          x.code.toLocaleLowerCase() === language ? -1 : y.code.toLocaleLowerCase() === language ? 1 : 0,
        )
    : [];
});

export const selectAssessments = createSelector([getTests, getBatteries], (tests, batteries) => [
  ...tests.filter((test) => !test.isBatteryOnly),
  ...batteries,
]);

export const selectUserCredits = createSelector(
  [getUserCredits, getUserFrozenCredits],
  (credits = 0, frozenCredits = 0) => credits - frozenCredits,
);

// helper func
export const calculateCredits = (credits = 0, frozenCredits = 0) => credits - frozenCredits;

const defaultCompleteBioTemplate = { label: I18n.t('defaultCompleteBioTemplate'), value: 0, isDefault: true };
const defaultEmptyBioTemplate = { label: I18n.t('defaultEmptyBioTemplate'), value: -1, isDefault: false };

export const selectAvailableMappedBiodataTemplates = createSelector([getBiodataTemplates], (templates) => {
  const biotemplates = templates.map((templateItem) => ({
    label: templateItem.name,
    value: templateItem.id,
    isDefault: templateItem.isDefault,
  }));
  biotemplates.unshift(defaultEmptyBioTemplate);
  biotemplates.unshift(defaultCompleteBioTemplate);
  return biotemplates;
});
