import { I18n } from 'react-redux-i18n';
import { ReactComponent as ViewReportsIcon } from '../../assets/icons/request-report.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/sidebar-download-report.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import * as actions from '../actionsList';

export const findReportFormat = (report) => {
  if (report.downloadLink) {
    const match = new URL(report.downloadLink).pathname.match(/\.(\w+$)/);
    return match ? match[1].toLowerCase() : '';
  }
  return '';
};

const reportsBulkActions = [
  {
    name: actions.OPEN_REPORT,
    label: I18n.t('View selected report(s)'),
    Icon: ViewReportsIcon,
    isDisabled: (reports) => reports.length !== 1 || findReportFormat(reports[0]) !== 'pdf',
  },
  {
    name: actions.DOWNLOAD_REPORT,
    label: I18n.t('Download selected report(s)'),
    Icon: DownloadIcon,
    isDisabled: (reports) => {
      if (
        (reports.length === 1 && findReportFormat(reports[0]) === 'pdf') ||
        reports.every((item) => item.reportType === 1)
      )
        return true;
    },
  },
  {
    name: actions.DELETE_REPORT,
    label: I18n.t('Delete selected report(s)'),
    Icon: DeleteIcon,
  },
];

export default reportsBulkActions;
