import React, { forwardRef, useRef } from 'react';

import Checkbox from './FormComponents/Checkbox';

const MultiSelectCheckbox = forwardRef(({ indeterminate, checked, enableClickHandler, ...rest }, ref) => {
  const defaultRef = useRef(null);
  const resolvedRef = ref || defaultRef;
  React.useEffect(() => {
    if (resolvedRef.current) resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);
  return (
    <>
      <Checkbox isChecked={checked} clickHandler={enableClickHandler ? (e) => rest.onChange(e) : undefined} {...rest} />
    </>
  );
});

export default MultiSelectCheckbox;
