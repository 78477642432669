import React from 'react';
import { bool, number, oneOfType, string } from 'prop-types';
import styled, { keyframes, css } from 'styled-components';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = ({ isLoading, size, text, full }) =>
  isLoading ? (
    <Container full={full}>
      <RotatingBlock size={size} />
      {text && <span>{text}</span>}
    </Container>
  ) : null;

Spinner.propTypes = {
  isLoading: bool.isRequired,
  size: oneOfType([string, number]),
  text: string,
  full: bool,
};

Spinner.defaultProps = {
  size: 24,
  text: '',
  full: false,
};

const Container = styled.div`
  position: absolute;
  top: 0;
  z-index: 100;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.25);
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  font-weight: 600;

  ${(props) =>
    props.full &&
    css`
      background: white;
      position: fixed;
      left: auto;
      right: 0;
      width: calc(100% - 20rem);
    `}
`;

const RotatingBlock = styled.div`
  animation: ${rotate360} 1.5s linear infinite;
  transform: translateZ(0);

  border-top: 3px solid ${(props) => props.theme.colors.blue};
  border-right: 3px solid ${(props) => props.theme.colors.blue};
  border-bottom: 3px solid ${(props) => props.theme.colors.blue};
  border-left: 3px solid ${(props) => props.theme.colors.white};
  background: transparent;
  width: ${(props) => (typeof props.size === 'number' ? `${props.size}px` : props.size)};
  height: ${(props) => (typeof props.size === 'number' ? `${props.size}px` : props.size)};
  border-radius: 50%;
`;

export default Spinner;
