import { I18n } from 'react-redux-i18n';
import { ReactComponent as CopyLinkIcon } from '../../assets/icons/sidebar-copy-link.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar_white.svg';

import * as actions from '../actionsList';

const invitesBulkActions = [
  {
    name: actions.RESET_DEADLINE,
    label: I18n.t('Reset assessment deadline'),
    Icon: CalendarIcon,
  },
  {
    name: actions.COPY_LINK,
    label: I18n.t('Copy invite link to clipboard'),
    Icon: CopyLinkIcon,
    isDisabled: (invites) => invites.length > 1,
  },
  {
    name: actions.DELETE_INVITE,
    label: I18n.t('Delete invites'),
    Icon: DeleteIcon,
  },
];

export default invitesBulkActions;
