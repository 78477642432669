import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { I18n } from 'react-redux-i18n';

const NormsHeader = ({ data: { type, creationDate, sampleSize, testID } }) => {
  const date = new Date(creationDate).toGMTString();

  return (
    <NormHeaderContainer>
      <Column>
        <span>{I18n.t('Test:')}</span>
        <span>{testID}</span>
      </Column>
      <Column>
        <span>{I18n.t('Sample Size:')}</span>
        <span>{sampleSize}</span>
      </Column>
      <Column>
        <span>{I18n.t('Norm Type:')}</span>
        <span>{type}</span>
      </Column>
      <Column>
        <span>{I18n.t('Creation Date:')}</span>
        {creationDate && <span>{format(new Date(date), 'dd/M/y')}</span>}
      </Column>
    </NormHeaderContainer>
  );
};

const NormHeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 1.5rem 0 32px;
  min-height: 35px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 12.5%;

  span:first-child {
    font-size: ${(props) => props.theme.fontSizes.small};
    color: ${(props) => props.theme.colors.darkBlue};
    font-weight: 600;
    margin-bottom: 1rem;
  }

  span {
    font-size: ${(props) => props.theme.fontSizes.small};
    font-weight: 500;
  }

  span:first-child {
    color: ${(props) => props.theme.colors.darkBlue};
    font-weight: 600;
    margin-bottom: 1rem;
  }
`;

export default NormsHeader;
