import React, { useState, useCallback, useMemo } from 'react';
import { object } from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';

import { ReactComponent as EmptyIcon } from '../../assets/icons/empty_state.svg';

import SimplifiedTable from '../reusable/Tables/SimplifiedTable';
import BulkActionsSidebar from '../BulkActionsSidebar';
import ConfirmationModal from '../reusable/ConfirmationModal';

import groupsColumns from '../../table/columns/respondentGroups';
import useBulkSidebarState from '../../hooks/useBulkSidebarState';

import { unassignRespondentFromGroups } from '../../store/respondents/actions';

import * as actions from '../../table/actionsList';
import respondentGroupsBulkActions from '../../table/bulkActions/respondentGroups';

const RespondentGroups = ({ respondent }) => {
  const [selectedGroups, setSelectedGroups] = useState([]);
  const dispatch = useDispatch();
  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedGroups });

  const [isRemoveFromGroupsModalOpen, setRemoveFromGroupsModalState] = useState(false);

  const onRemoveConfirm = () => {
    dispatch(
      unassignRespondentFromGroups(
        respondent.respondentID,
        selectedGroups.map((group) => group.groupID),
        () => {
          setRemoveFromGroupsModalState(false);
        },
      ),
    );
  };

  const onChange = useCallback((data) => {
    setSelectedGroups(data);
  }, []);

  // BULK ACTIONS
  const bulkActionsHandlers = {
    [actions.REMOVE_RESPONDENT_FROM_GROUPS]: () => setRemoveFromGroupsModalState(true),
  };
  const bulkActions = useMemo(() => {
    return respondentGroupsBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
    }));
  }, [selectedGroups]);

  // columns settings
  const columns = useMemo(() => {
    return groupsColumns;
  }, []);

  const { groups } = respondent;
  if (!groups.length)
    return (
      <EmptyMessageWrapper>
        <EmptyIcon />
        <EmptyMessage>{I18n.t('Sorry, we could not find any groups')}</EmptyMessage>
      </EmptyMessageWrapper>
    );

  return (
    <RespondentGroupsContainer>
      <BulkActionsSidebar actions={bulkActions} onClose={closeSidebar} isOpen={isSidebarOpen} />
      <SimplifiedTable
        columns={columns}
        data={groups}
        initialState={{ sortBy: [{ id: 'Created', desc: true }] }}
        onSelectChange={onChange}
        idAccessor="groupID"
        entityName="group"
      />
      <ConfirmationModal
        title={I18n.t('Remove from groups')}
        onClose={() => setRemoveFromGroupsModalState(false)}
        isVisible={isRemoveFromGroupsModalOpen}
        description={I18n.t('Are you sure you want to remove respondent from selected group(s)')}
        onConfirm={onRemoveConfirm}
        caution
      />
    </RespondentGroupsContainer>
  );
};

RespondentGroups.propTypes = {
  respondent: object.isRequired,
};

const RespondentGroupsContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-bottom: 8rem;
  margin-top: 10px;
`;

const EmptyMessageWrapper = styled.div`
  position: absolute;
  min-width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EmptyMessage = styled.p`
  margin: 15px 0 0 0;
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
`;

export default RespondentGroups;
