export const resolveBatteryName = (battery) => {
  if (!battery) return '';
  if (battery.batteryName) return `${battery.batteryName} (${battery.title})`;
  return battery.title;
};

export const resolveTestName = (test) => `${test.name} (${test.testID})`;

export const resolveAssessmentName = (assessment) => {
  if (!assessment) return '';
  return assessment.batteryID ? resolveBatteryName(assessment) : resolveTestName(assessment);
};

export const resolveAssessmentId = (assessment) => assessment?.batteryID || assessment?.testID;
