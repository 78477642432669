import React from 'react';
import { array, number } from 'prop-types';
import styled from 'styled-components';
import { Translate } from 'react-redux-i18n';

import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';

const Steps = ({ steps, stepIndex, stepsToExclude }) => {
  return (
    <StepsWrapper>
      {steps.map((step, i) => {
        if (!step || stepsToExclude.includes(step)) return null;
        return (
          <div key={step}>
            <StepLabel isVisited={i <= stepIndex}>
              <Translate value={step} />
            </StepLabel>
            {i !== steps.length - 1 && <Arrow />}
          </div>
        );
      })}
    </StepsWrapper>
  );
};

Steps.propTypes = {
  steps: array.isRequired,
  stepsToExclude: array,
  stepIndex: number.isRequired,
};

Steps.defaultProps = {
  stepsToExclude: [],
};

const StepsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 1.6rem;
`;

const StepLabel = styled.span`
  font-size: 1.4rem;
  letter-spacing: 1.5px;
  font-weight: 600;
  margin-right: 3rem;
  text-transform: uppercase;
  color: ${(props) => (props.isVisited ? props.theme.colors.mediumBlue : props.theme.colors.grey3)};
`;

const Arrow = styled(ArrowRight)`
  margin-right: 3rem;
`;

export default Steps;
