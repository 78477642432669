import React from 'react';
import styled from 'styled-components';
import { BarChart, Bar, Legend, ResponsiveContainer, CartesianGrid, YAxis, XAxis, Tooltip } from 'recharts';
import { I18n } from 'react-redux-i18n';
import { ReactComponent as EyeShow } from '../../../assets/icons/eye-show.svg';
import useModals from '../../../hooks/useModals';
import * as actions from '../../../table/actionsList';
import StatsModal from './StatsModal';

const BarChartGraph = ({ categorie, rawBioDataColumns }) => {
  const modals = [actions.SHOW_BIODATA_DETAILS];
  const { modalsState, openModal, closeModal } = useModals(modals);
  const AGE_GROUPS = {
    0: '<20',
    1: '20-29',
    2: '30-39',
    3: '40-49',
    4: '50-59',
    5: '>=60',
    6: 'UDC',
  };

  const setBarChartSignificantData = ({ values }) => {
    const significantData = [];

    if (values) {
      values.forEach((itemPair, index) => {
        const [age] = itemPair.split(':');
        return significantData.push({
          name: AGE_GROUPS[index],
          value: age,
        });
      });
    }
    return significantData;
  };

  const setStatstSignificantData = ({ values }) => {
    const significantData = [];

    if (values) {
      values.forEach((itemPair, index) => {
        const [age, percentage] = itemPair.split(':');
        return significantData.push({
          name: AGE_GROUPS[index],
          value: parseFloat(age),
          percent: parseFloat(percentage),
        });
      });
    }
    return significantData;
  };

  const mappedData = setBarChartSignificantData({ values: categorie.values });
  const mappedStatsData = setStatstSignificantData({ values: categorie.values });

  return (
    <GraphicsWrapper key={categorie.name}>
      <PreviewTitle>
        <PreviewItem>{I18n.t(categorie.name).toUpperCase()}</PreviewItem>
        <PreviewItem>
          <BackButtonWrapper onClick={() => openModal(actions.SHOW_BIODATA_DETAILS)}>
            <EyeShow />
          </BackButtonWrapper>
        </PreviewItem>
      </PreviewTitle>
      <StyledResponsiveContainer height="auto" aspect={1.2}>
        <BarChart
          data={mappedData}
          width={450}
          height={500}
          barGap={0}
          barSize={100}
          margin={{ top: 0, right: 0, bottom: parseInt(mappedData.length, 10) * 10, left: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tick={{ fontSize: '11px' }} />
          <YAxis />
          <Bar barSize={30} dataKey="value" stackId="ageValue" fill="#8884d8" />
          <Legend height={36} align="left" wrapperStyle={{ fontSize: '11.5px' }} />
          <Tooltip />
        </BarChart>
      </StyledResponsiveContainer>
      <CustomLegenItem>{I18n.t('Undeclared')}</CustomLegenItem>
      {modalsState[actions.SHOW_BIODATA_DETAILS] && (
        <StatsModal
          categorieName={I18n.t(categorie.name)}
          rawDataColumns={rawBioDataColumns}
          rawData={mappedStatsData}
          onClose={() => closeModal(actions.SHOW_BIODATA_DETAILS)}
        />
      )}
    </GraphicsWrapper>
  );
};

const GraphicsWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 25px 12px;
  max-height: 450px;
`;

const PreviewTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #295368;
`;

const PreviewItem = styled.p`
  color: #295368;
`;

const CustomLegenItem = styled.p`
  color: #295368;
  font-size: 1.2rem;
  margin-left: 1.2rem;
  margin-top: -5rem;
`;

const BackButtonWrapper = styled.button`
  outline: none;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  min-width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  transition: all 0.2s;

  :hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
`;

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  width: 34rem !important;
  margin: 15px;
  margin-top: 50px;

  .recharts-default-legend {
    margin-top: 15px !important;
  }
`;

export default BarChartGraph;
