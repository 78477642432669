import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import NewModal from '../../reusable/NewModal';
import projectList from './projectList';
import {
  fetchCompetencyGroups,
  resetCreateAssessmentProjectData,
  fetch360ProjectById,
} from '../../../store/projects/actions';

import Spinner from '../../reusable/Spinner';
import ChooseProjectType from './ChooseProjectType';
import Add360ProjectForm from './360/Add360ProjectForm';
import AssessmentProject from './AssessmentProject';

const initOnBeforeUnload = (shouldInit) => {
  if (shouldInit) {
    window.onbeforeunload = (event) => {
      // Show prompt based on state
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    };
  } else {
    window.onbeforeunload = null;
  }
};

const AddProjectsModal = ({ onClose, onReset, selectedProjects, isAssessmentProjectDisabled }) => {
  const dispatch = useDispatch();
  const competencyGroups = useSelector((state) => state.projects.competencyGroups);
  // TODO set to initial
  const [step, setStep] = useState(0);
  const [projectType, setProjectType] = useState(0);
  const [projectInView, setProjectInView] = useState({});
  const [isLoading, setLoadingState] = useState(false);

  const isProjectInview = !!Object.keys(projectInView).length;

  useEffect(() => {
    initOnBeforeUnload(true);

    return () => initOnBeforeUnload(false);
  }, []);

  useEffect(() => {
    dispatch(fetchCompetencyGroups());
  }, []);
  useEffect(() => {
    if (selectedProjects.length) {
      const projectId = selectedProjects[0]?.projectId;
      setLoadingState(true);
      dispatch(
        fetch360ProjectById(projectId, (error, project) => {
          setProjectInView(project);
          setProjectType(project.type);
          setLoadingState(false);
        }),
      );
      setStep(1);
    }
  }, [selectedProjects.length]);

  const onModalClose = useCallback(() => {
    if (projectType === 1) dispatch(resetCreateAssessmentProjectData());
    onClose();
  }, [projectType]);

  // eslint-disable-next-line
  const title = isProjectInview
    ? `${I18n.t('Edit Project')} - ${projectInView.name}`
    : step === 1
    ? projectList[projectType].title
    : I18n.t('Choose Project Type');

  const AssessmentForm = useMemo(() => {
    if (step !== 1) return null;
    return projectType === 0 ? (
      <Add360ProjectForm
        competencyGroups={competencyGroups}
        onClose={onModalClose}
        onReset={onReset}
        projectInView={projectInView}
      />
    ) : (
      <AssessmentProject goBackToProjectSelection={() => setStep(0)} onClose={onModalClose} onReset={onReset} />
    );
  }, [step, projectType, projectInView]);

  return (
    <Modal isVisible onClose={onModalClose} title={title} isPaddingless>
      <Spinner isLoading={isLoading} />
      <Container>
        {step === 0 && (
          <ChooseProjectType
            projectType={projectType}
            setProjectType={setProjectType}
            goToNextStep={() => setStep((prev) => prev + 1)}
            isAssessmentProjectDisabled={isAssessmentProjectDisabled}
          />
        )}
        {AssessmentForm}
      </Container>
    </Modal>
  );
};

const Modal = styled(NewModal)`
  width: 85rem;
  max-height: 60rem;
  height: 100%;
  display: flex;
`;

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export default AddProjectsModal;
