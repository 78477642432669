import { createSelector } from 'reselect';
import { I18n } from 'react-redux-i18n';

const getActiveFilter = (state) => state.reports.activeTab;
const getTotalCount = (state) => state.reports.totalCount;
const getPendingCount = (state) => state.reports.totalPending;
const getUnreadCount = (state) => state.reports.totalUnread;
const getViewedCount = (state) => state.reports.totalViewed;
const getTabs = (state) => state.reports.tabs;

const selectReportsAmountsByStatus = createSelector(
  [getTotalCount, getPendingCount, getUnreadCount, getViewedCount],
  (totalCount, pendingCount, unreadCount, viewedCount) => ({
    all: totalCount,
    pending: pendingCount,
    unread: unreadCount,
    viewed: viewedCount,
  }),
);

export const selectReportsTabs = createSelector([getTabs, selectReportsAmountsByStatus], (tabs, amounts) => {
  return tabs.map((item) => ({ ...item, label: `${I18n.t(item.label)} (${amounts[item.name]})` }));
});

export const selectNumberOfUnreadReports = createSelector(getUnreadCount, (unreadCount) => unreadCount);

export const selectTotalReportsCount = createSelector(
  [getActiveFilter, getTotalCount, getPendingCount, getUnreadCount, getViewedCount],
  (activeTab, totalCount, pendingCount, unreadCount, viewedCount) => {
    if (activeTab.name === 'all') return totalCount;
    if (activeTab.name === 'pending') return pendingCount;
    if (activeTab.name === 'unread') return unreadCount;
    return viewedCount;
  },
);
