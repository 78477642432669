import { I18n } from 'react-redux-i18n';

const breadcrumbs = (project) => [
  {
    label: I18n.t('Projects'),
    path: '/projects',
  },
  {
    label: project ? project.name : `${I18n.t('Loading')}...`,
    path: `/projects/assessment/${project?.projectID}`,
  },
  {
    label: I18n.t('Project settings'),
  },
];

export default breadcrumbs;
