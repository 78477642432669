import { I18n } from 'react-redux-i18n';
import styled from 'styled-components';

import {
  SENT_INVITE_STATUS,
  ACTIVE_INVITE_STATUS,
  EXPIRED_INVITE_STATUS,
  COMPLETED_INVITE_STATUS,
} from '../../constants/inviteStatuses';
import { AP_COMPLETED_STATUS } from '../../constants/assessmentProjectStatuses';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as SendInviteIcon } from '../../assets/icons/sidebar-invite-not-invited.svg';
import { ReactComponent as CopyLinkIcon } from '../../assets/icons/sidebar-copy-link.svg';
import { ReactComponent as SendRemindersIcon } from '../../assets/icons/sidebar-invite-invited.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { ReactComponent as GoToDetailsIcon } from '../../assets/icons/sidebar-respondent-details.svg';

import * as actions from '../actionsList';

const styleIcon = (Icon) => styled(Icon)`
  width: 1.8rem !important;
  height: 1.8rem !important;
  max-width: 1.8rem !important;
  max-height: 1.8rem !important;
  min-height: 1.8rem !important;
  min-width: 1.8rem !important;
`;

const assessmentProjectRespondentsBulkActions = (project) => [
  ...(project?.sendInviteType === 0
    ? [
        {
          name: actions.ASSESSMENT_PROJECT_INVITE_RESPONDENTS,
          label: I18n.t('Send invites'),
          Icon: styleIcon(SendInviteIcon),
          hint: I18n.t(
            'Selected respondent(s) can’t be invited, since one or several of them have already been invited',
          ),
          isDisabled: (respondents) => {
            if (project?.status === AP_COMPLETED_STATUS) {
              return true;
            }

            // if at least one has already been invited
            return respondents.some((item) =>
              [SENT_INVITE_STATUS, ACTIVE_INVITE_STATUS, EXPIRED_INVITE_STATUS, COMPLETED_INVITE_STATUS].includes(
                item.status,
              ),
            );
          },
        },
      ]
    : []),
  {
    name: actions.ASSESSMENT_PROJECT_COPY_INVITE_LINK,
    label: I18n.t('Copy invite link'),
    Icon: styleIcon(CopyLinkIcon),
    hint: (respondents) => {
      if (project?.status === AP_COMPLETED_STATUS) {
        return I18n.t('Invite link can be copied only for not completed projects');
      }

      if (respondents.length > 1) {
        return I18n.t('Invite link can be copied for 1 selected respondent at a time');
      }

      if (respondents.length && !respondents[0].invitationLink) {
        return I18n.t('Invite link is not available, please send or download invites in the csv first');
      }
    },
    isDisabled: (respondents) => {
      if (project?.status === AP_COMPLETED_STATUS) {
        return true;
      }

      if (respondents.length !== 1) {
        return true;
      }

      if (respondents.length && !respondents[0].invitationLink) {
        return true;
      }

      return false;
    },
  },
  {
    name: actions.ASSESSMENT_PROJECT_SEND_REMINDERS,
    label: I18n.t('Send reminders'),
    Icon: styleIcon(SendRemindersIcon),
    hint: I18n.t('One or several selected respondents haven’t been invited yet'),
    isDisabled: (respondents) => {
      if (project?.status === AP_COMPLETED_STATUS) {
        return true;
      }

      if (respondents.every((respondent) => respondent.status === COMPLETED_INVITE_STATUS)) {
        return true;
      }

      return false;
    },
  },
  {
    name: actions.ASSESSMENT_PROJECT_DOWNLOAD_RESPONDENT_REPORTS,
    label: I18n.t('Download reports'),
    Icon: styleIcon(DownloadIcon),
    hint: (respondents) =>
      respondents.length > 1
        ? I18n.t('Download report option can be available for 1 selected respondent at a time')
        : I18n.t('One or several selected respondents don’t have available reports'),
    isDisabled: (respondents) => respondents.length > 1 || !respondents.every((item) => item.status === 7),
  },
  {
    name: actions.ASSESSMENT_PROJECT_GO_TO_RESPONDENT_PROFILE,
    label: I18n.t('Go to respondent profile'),
    Icon: styleIcon(GoToDetailsIcon),
    hint: () => I18n.t('Go to respondent profile option can be available for 1 selected respondent at a time'),
    isDisabled: (respondents) => respondents.length !== 1,
  },
  {
    name: actions.ASSESSMENT_PROJECT_DELETE_RESPONDENTS,
    label: I18n.t('Remove respondent(s)'),
    Icon: styleIcon(DeleteIcon),
    hint: I18n.t("Selected respondents can't be deleted since one or several of them already passed the assessment"),
    isDisabled: (respondents) => respondents.some((item) => item.status === 7),
  },
];

export default assessmentProjectRespondentsBulkActions;
