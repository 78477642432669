import React, { useContext, useMemo } from 'react';
import { string, array, object, number, bool } from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import Select, { components } from 'react-select';
import { I18n } from 'react-redux-i18n';
import { ReactComponent as SelectArrow } from '../../../assets/icons/blue-arrow-down-big.svg';

const customStyles = ({ zIndex, theme, error }) => ({
  container: (provided, state) => {
    return {
      ...provided,
      fontSize: '1.4rem',
      opacity: state.isDisabled ? 0.5 : 1,
      ...(zIndex && { zIndex }),
    };
  },
  control: (provided, state) => {
    const defaultBorderColor = state.isFocused ? theme.colors.lightBlue2 : theme.colors.grey2;
    const borderColor = error ? theme.colors.red : defaultBorderColor;
    const hoverBorderColor = error ? theme.colors.red : theme.colors.lightBlue2;
    return {
      ...provided,
      minHeight: '4rem',
      paddingLeft: '0.2rem',
      backgroundColor: state.isFocused ? theme.colors.lightBlue1 : theme.colors.white,
      borderRadius: '0.4rem',
      borderColor,
      boxShadow: 'none',
      '&:hover': {
        borderColor: hoverBorderColor,
      },
      '& > div': {
        height: '4rem',
      },
    };
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: (provided) => ({
    ...provided,
    borderRadius: '2rem',
    fontSize: '1.4rem',
    color: theme.colors.grey5,
    fontWeight: 500,
  }),
  singleValue: () => ({
    color: theme.colors.darkBlue2,
    fontWeight: 500,
    fontSize: '1.4rem',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    overflow: 'hidden',
  }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: theme.colors.white,
      fontWeight: 500,
      fontSize: '1.4rem',
      color: state.isSelected ? theme.colors.darkBlue2 : theme.colors.darkBlue2,
      '&:hover': {
        cursor: 'pointer',
        color: state.isSelected ? theme.colors.lightBlue2 : theme.colors.lightBlue,
      },
      '&:active': {
        backgroundColor: theme.colors.white,
      },
      opacity: state.isDisabled ? 0.5 : 1,
    };
  },
  menu: (provided) => ({
    ...provided,
    minWidth: '20rem',
    marginLeft: '1px',
    zIndex: 10,
  }),
  groupHeading: (provided) => ({
    ...provided,
    color: theme.colors.darkBlue2,
    fontSize: theme.fontSizes.normal,
    fontWeight: 'bold',
  }),
});

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectArrow />
    </components.DropdownIndicator>
  );
};

const SingleValue = ({ children, selectProps, ...props }) => (
  <components.SingleValue {...props}>
    <SelectValueHolder>
      <SelectName>{selectProps.placeholder}</SelectName>
      <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{children}</div>
    </SelectValueHolder>
  </components.SingleValue>
);

const CustomSelect = ({
  options,
  className,
  selectProps,
  isDisabled,
  placeholder,
  error,
  zIndex,
  withoutDropdownIndicator,
  allowSearch,
}) => {
  const themeContext = useContext(ThemeContext);

  const translatedOptions = useMemo(() => options.map((entry) => ({ ...entry, label: I18n.t(entry.label) })), [
    options,
  ]);

  const translatetedSelectProps = useMemo(
    () => ({
      ...selectProps,
      value: translatedOptions.find((entry) => entry?.value === selectProps?.value?.value) || selectProps.value,
    }),
    [selectProps],
  );

  return (
    <SelectWrapper className={className}>
      <Select
        isSearchable={allowSearch}
        placeholder={I18n.t(placeholder)}
        options={translatedOptions}
        styles={customStyles({ zIndex, theme: themeContext, error })}
        components={{ DropdownIndicator: withoutDropdownIndicator ? null : DropdownIndicator, SingleValue }}
        isDisabled={isDisabled}
        maxMenuHeight={170}
        noOptionsMessage={() => I18n.t('No options')}
        // isOptionDisabled={(option) => option.isDisabled}
        {...translatetedSelectProps}
      />
      {error && <ErrorMsg>{error}</ErrorMsg>}
    </SelectWrapper>
  );
};

CustomSelect.propTypes = {
  options: array.isRequired,
  placeholder: string.isRequired,
  className: string,
  selectProps: object,
  zIndex: number,
  isDisabled: bool,
  error: string,
  withoutDropdownIndicator: bool,
  allowSearch: bool,
};

CustomSelect.defaultProps = {
  className: '',
  selectProps: {},
  zIndex: 0,
  isDisabled: false,
  error: '',
  withoutDropdownIndicator: false,
  allowSearch: false,
};

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 20rem;
  margin-bottom: 20px;
  position: relative;
  //z-index: 10;
`;

const SelectValueHolder = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const SelectName = styled.span`
  font-size: 1rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.mediumBlue};
  text-transform: capitalize;
`;

const ErrorMsg = styled.span`
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.red};
  font-weight: 600;
  margin-top: 0.5rem;
`;

export default CustomSelect;
