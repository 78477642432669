import React from 'react';
import { object } from 'prop-types';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';

import Checkbox from '../../../reusable/FormComponents/Checkbox';

const ReportsSetSummaryBlock = ({ set }) => {
  return (
    <Container>
      <HeaderRow>
        <TestName>{set.name}</TestName>
        <CreditsAmount>
          {set.creditsCost} {I18n.t('credits')}
        </CreditsAmount>
      </HeaderRow>
      <MainContent>
        <ReportsList>
          {set.reports.map((report) => {
            return (
              <ReportItem key={report.reportID}>
                <Checkbox isChecked disabled />
                <span>{report.name}</span>
              </ReportItem>
            );
          })}
        </ReportsList>
      </MainContent>
    </Container>
  );
};

ReportsSetSummaryBlock.propTypes = {
  set: object.isRequired,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.2rem 1.6rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);
  max-height: 16rem;
  margin-bottom: 1rem;
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

const TestName = styled.span`
  font-size: 14px;
  font-weight: 600;
  ${(props) => props.theme.colors.darkBlue2};
`;

const CreditsAmount = styled.span`
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;
  ${(props) => props.theme.colors.darkBlue2};
`;

const MainContent = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: auto;
`;

const ReportsList = styled.div`
  display: flex;
  flex-direction: column;
`;

const ReportItem = styled.div`
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;
  color: ${(props) => props.theme.colors.almostBlack};
  margin-bottom: 1rem;
  span {
    margin-left: 1rem;
  }
`;

export default ReportsSetSummaryBlock;
