import React, { useState, useEffect } from 'react';
import { func, array, object } from 'prop-types';
import styled from 'styled-components';
import { startOfTomorrow } from 'date-fns';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { push } from 'redux-first-history';
import { I18n } from 'react-redux-i18n';
import Input from '../../../reusable/FormComponents/Input';
import Counter from '../../../reusable/Counter';
import TableTabs from '../../../reusable/TableTabs';
import FormikInput from '../../../reusable/FormComponents/FormikInput';
import Select from '../../../reusable/Selects/Select';
import TextArea from '../../../reusable/FormComponents/TextArea';
import { errorMessages } from '../../../../constants/errorMessages';
import { addProject, editProject } from '../../../../store/projects/actions';
import DateTimeSelect from '../../../reusable/Selects/DateTimeSelect';

import ProjectModalFooter from '../ProjectModalFooter';
import Button from '../../../reusable/Buttons/Button';

const tabs = [
  { name: 'Info', label: I18n.t('Info') },
  { name: 'Raters', label: I18n.t('Raters') },
];

const Add360ProjectForm = ({ onClose, onReset, competencyGroups, projectInView }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [isSubmitting, setSubmittingState] = useState(false);

  const isProjectInview = !!Object.keys(projectInView).length;

  const editCallbackHandler = () => {
    setSubmittingState(false);
    onClose();
    onReset(true);
  };
  const addCallbackHandler = (data) => {
    if (typeof data !== 'undefined' && data.projectId) {
      dispatch(push(`/projects/360/${data.projectId}`));
    } else {
      editCallbackHandler();
    }
  };
  const { handleSubmit, handleChange, values, errors, touched, setFieldValue, setFieldError } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      description: '',
      competencyGroupID: { label: I18n.t('All standard'), value: '', languages: ['mock'] },
      deadline: null,
      managerAlias: '',
      peerAlias: '',
      directReportAlias: '',
      otherAlias: '',
      minManager: 0,
      minPeer: 0,
      minDirectReport: 0,
      minOther: 0,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required(I18n.t('This field is required'))
        .max(300, errorMessages.tooLong(300)),
      competencyGroupID: Yup.object().shape({
        languages: Yup.array()
          .min(1, I18n.t('360 survey has to be fully supported in at least 1 language'))
          .required(I18n.t('360 survey has to be fully supported in at least 1 language')),
      }),
      deadline: Yup.date()
        .nullable()
        .required(I18n.t('This field is required')),
      description: Yup.string().max(1000, errorMessages.tooLong(1000)),
      managerAlias: Yup.string().max(100, errorMessages.tooLong(100)),
      peerAlias: Yup.string().max(100, errorMessages.tooLong(100)),
      directReportAlias: Yup.string().max(100, errorMessages.tooLong(100)),
      otherAlias: Yup.string().max(100, errorMessages.tooLong(100)),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      const data = {
        ...values,
        competencyGroupID: values.competencyGroupID.value ? values.competencyGroupID.value : null,
      };
      setSubmittingState(true);
      if (isProjectInview) {
        dispatch(editProject({ ...data, projectId: projectInView.projectId }, editCallbackHandler));
      } else {
        dispatch(addProject({ ...data }, addCallbackHandler));
      }
    },
  });

  useEffect(() => {
    if (Object.keys(errors).length) {
      setActiveTab(tabs[0]);
    }
  }, [errors, setActiveTab, tabs]);

  useEffect(() => {
    if (Object.keys(projectInView).length) {
      setFieldValue('name', projectInView.name || '');
      setFieldValue('description', projectInView.description || '');
      setFieldValue(
        'competencyGroupID',
        projectInView.competencyGroupID
          ? competencyGroups.find((item) => item.value === projectInView.competencyGroupID)
          : { label: I18n.t('All standard'), value: '', languages: ['mock'] },
      );
      setFieldValue('deadline', (projectInView.deadline && new Date(projectInView.deadline)) || null);
      setFieldValue('managerAlias', projectInView.managerAlias || '');
      setFieldValue('peerAlias', projectInView.peerAlias || '');
      setFieldValue('directReportAlias', projectInView.directReportAlias || '');
      setFieldValue('otherAlias', projectInView.otherAlias || '');
      setFieldValue('minManager', projectInView.minManager || 0);
      setFieldValue('minPeer', projectInView.minPeer || 0);
      setFieldValue('minDirectReport', projectInView.minDirectReport || 0);
      setFieldValue('minOther', projectInView.minOther || 0);
    }
  }, [projectInView, competencyGroups.length]);
  const onInputChange = (name) => (e) => {
    setFieldError(name, '');
    handleChange(e);
  };

  const onDeadlineChange = (date) => {
    setFieldValue('deadline', date);
    setFieldError('deadline', '');
  };

  const onCompetencyGroupChange = (group) => {
    setFieldValue('competencyGroupID', group);
    setFieldError('competencyGroupID', '');
  };

  const buttonText = isProjectInview ? I18n.t('Save') : I18n.t('Add');

  return (
    <>
      <Form>
        <StyledTabs tabs={tabs} activeTab={activeTab} clickHandler={setActiveTab} />
        {activeTab === tabs[0] && (
          <div>
            <InputsWrapper>
              <StyledInput
                inputName={I18n.t('Project Name')}
                errors={errors}
                touched={touched}
                type="text"
                name="name"
                id="name"
                onChange={onInputChange('name')}
                value={values.name}
              />
              <StyledSelect
                error={errors.competencyGroupID?.languages}
                placeholder={I18n.t('360 Survey')}
                options={[{ label: I18n.t('All standard'), value: '', languages: ['mock'] }, ...competencyGroups]}
                isDisabled={projectInView.totalCount > 0}
                selectProps={{
                  onChange: onCompetencyGroupChange,
                  value: values.competencyGroupID,
                  menuPosition: 'fixed',
                }}
              />
              <StyledTextArea
                inputName={I18n.t('Description')}
                error={errors.description}
                name="description"
                id="description"
                onChange={handleChange}
                value={values.description}
              />
              <DateTimeSelect
                inputName={I18n.t('Deadline')}
                date={values.deadline}
                onDateChange={onDeadlineChange}
                error={errors.deadline}
                minDate={startOfTomorrow()}
                popperPlacement="top-end"
              />
            </InputsWrapper>
          </div>
        )}
        {activeTab === tabs[1] && (
          <div>
            <HeaderContainer>
              <Header>{I18n.t('Project Alias')}</Header>
              <SubHeader>{I18n.t('Min')}</SubHeader>
            </HeaderContainer>
            <InputCounterRow>
              <Input
                inputName={I18n.t('Manager Alias')}
                errors={errors}
                touched={touched}
                type="text"
                name="managerAlias"
                id="managerAlias"
                onChange={handleChange}
                value={values.managerAlias}
              />
              <Counter value={values.minManager} setValue={(num) => setFieldValue('minManager', num)} />
            </InputCounterRow>
            <InputCounterRow>
              <Input
                inputName={I18n.t('Peer Alias')}
                errors={errors}
                touched={touched}
                type="text"
                name="peerAlias"
                id="peerAlias"
                onChange={handleChange}
                value={values.peerAlias}
              />
              <Counter value={values.minPeer} setValue={(num) => setFieldValue('minPeer', num)} />
            </InputCounterRow>
            <InputCounterRow>
              <Input
                inputName={I18n.t('Direct Report Alias')}
                errors={errors}
                touched={touched}
                type="text"
                name="directReportAlias"
                id="directReportAlias"
                onChange={handleChange}
                value={values.directReportAlias}
              />
              <Counter value={values.minDirectReport} setValue={(num) => setFieldValue('minDirectReport', num)} />
            </InputCounterRow>
            <InputCounterRow>
              <Input
                inputName={I18n.t('Other Alias')}
                errors={errors}
                touched={touched}
                type="text"
                name="otherAlias"
                id="otherAlias"
                onChange={handleChange}
                value={values.otherAlias}
              />
              <Counter value={values.minOther} setValue={(num) => setFieldValue('minOther', num)} />
            </InputCounterRow>
          </div>
        )}
      </Form>
      <ProjectModalFooter>
        <StyledButton handler={handleSubmit} disabled={isSubmitting}>
          {buttonText}
        </StyledButton>
      </ProjectModalFooter>
    </>
  );
};

Add360ProjectForm.propTypes = {
  onClose: func.isRequired,
  onReset: func.isRequired,
  competencyGroups: array.isRequired,
  projectInView: object,
};

Add360ProjectForm.defaultProps = {
  projectInView: null,
};

const Form = styled.form`
  padding: 0 2.4rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const HeaderContainer = styled.div`
  display: flex;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const InputCounterRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2.8rem;
`;

const StyledTabs = styled(TableTabs)`
  border-bottom: 1px solid #c4c4c4;
`;

const Header = styled.div`
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSizes.medium};
  line-height: 2.8rem;
  color: #5b5b5b;
  margin-bottom: 1.8rem;
  width: 34rem;
`;

const SubHeader = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2.2rem;
  display: flex;
  justify-content: center;
  width: 11.4rem;
`;

const StyledInput = styled(FormikInput)`
  margin-bottom: 2.8rem;
`;

const StyledSelect = styled(Select)`
  margin-bottom: 2.8rem;
`;

const StyledTextArea = styled(TextArea)`
  margin-bottom: 2.8rem;
`;

const StyledButton = styled(Button)`
  min-width: 12rem;
  height: 4.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Add360ProjectForm;
