import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

const InfiniteTableScroll = ({ dataLength, fetchData, hasMore, children, loadingText, ...props }) => {
  return (
    <InfiniteScroll
      dataLength={dataLength || 100000}
      next={fetchData}
      hasMore={hasMore}
      hasChildren
      scrollThreshold={0.5}
      {...props}
    >
      {children}
    </InfiniteScroll>
  );
};

export default InfiniteTableScroll;
