const modifyWithColumnNames = (inputs, columns) => {
  return inputs.map((input) => {
    const column = columns.find((item) => item.name === input.name);
    const finalObj = {
      ...input,
      columnName: typeof column.Header === 'function' ? column.stringHeader : column.Header,
    };
    if (input.type === 'select') finalObj.uiSelectName = column.uiSelectName;
    return finalObj;
  });
};

export default modifyWithColumnNames;
