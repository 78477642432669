import { I18n } from 'react-redux-i18n';

const getResponsesLength = (data) => {
  let length;

  try {
    length = data.languages[0].responses.length;
  } catch (err) {
    length = 0;
  }

  return length;
};

const columns = [
  {
    Header: I18n.t('Name'),
    accessor: 'name',
    name: 'name',
    type: 'text',
    disableSortBy: true,
  },
  {
    Header: I18n.t('Responses'),
    accessor: (data) => getResponsesLength(data),
    name: 'responses',
    type: 'number',
    disableSortBy: true,
  },
  {
    Header: I18n.t('Languages'),
    accessor: (data) => data.languages.map((language) => language.langID).join(', '),
    name: 'languages',
    type: 'text',
    disableSortBy: true,
  },
];

export default columns;
