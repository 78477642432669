const createStorageService = () => {
  const setItem = (name, value) => {
    sessionStorage.setItem(name, value);
  };

  const getItem = (name) => {
    return sessionStorage.getItem(name);
  };

  const removeItem = (name) => {
    return sessionStorage.removeItem(name);
  };

  return { setItem, getItem, removeItem };
};

const storageService = createStorageService();

export default storageService;
