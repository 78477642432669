import React from 'react';
import { format } from 'date-fns';
import { I18n } from 'react-redux-i18n';

import { resolveTestName } from '../../utils/assessmentsNames';

const DateCell = ({ row }) => <span>{format(new Date(row.original.completionDate), 'dd/M/y HH:mm')}</span>;

// const columns = (rowActions, setSelectedTests) => [
const columns = [
  {
    Header: I18n.t('Assessment'),
    accessor: (data) => resolveTestName(data),
    name: 'test',
    type: 'text',
    // sortType: caseInsensitiveSortingFunc,
  },
  {
    Header: I18n.t('Language'),
    accessor: 'langID',
    name: 'language',
  },
  {
    Header: I18n.t('Date'),
    width: 35,
    name: 'completionDate',
    accessor: (data) => new Date(data.completionDate),
    Cell: (props) => <DateCell {...props} />,
    sortType: 'datetime',
  },
  // {
  //   Header: I18n.t(''),
  //   name: 'actions',
  //   type: 'actions',
  //   id: 'actions',
  //   disableSortBy: true,
  //   Cell: ({ row }) => (
  //     <ActionsDropdown
  //       actions={rowActions.map((item) => ({
  //         ...item,
  //         handler: () => item.handler && item.handler(row.original),
  //         isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(row.original) : item.isDisabled,
  //       }))}
  //       clickHandler={() => setSelectedTests(row.original)}
  //     />
  //   ),
  //   width: 50,
  // },
];

export default columns;
