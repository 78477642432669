import React from 'react';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import Badge from './styles';

const InbuiltBadge = () => <Inbuilt>{I18n.t('INBUILT')}</Inbuilt>;

const Inbuilt = styled(Badge)`
  background-color: #e0e0e0;
  color: ${(props) => props.theme.colors.darkBlue2};
  font-weight: 600;
  font-size: 1rem;
`;

export default InbuiltBadge;
