import React, { useMemo, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { array, func, oneOfType, string, number, bool } from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import storageService from '../../../services/storageService';

import SimplifiedTable from '../../reusable/Tables/SimplifiedTable';
import CustomButton from '../../reusable/Buttons/Button';
import ImportEmptyMessage from '../../reusable/ImportEmptyMessage';

import importedRespondentsColumns from '../../../table/columns/importedRespondents';
import { setAssessmentProjectHash } from '../../../store/projects/actions';
import apiInstance2 from '../../../services/apiService';

import createToastNotification from '../../../utils/createToastNotification';

const ReviewStep = ({
  data,
  groupId,
  goNext,
  onClose,
  setImportedAmount,
  projectId,
  isAssessmentProject,
  onSuccess,
}) => {
  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = useState([]);
  const language = storageService.getItem('psLang');

  const dataMapped = useMemo(() => {
    return data.map((entry) => ({
      ...entry,
      isValid: true,
    }));
  }, data);

  const importRespondents = async () => {
    try {
      const validRespondents = selectedData
        .filter((item) => item.isValid)
        .map((item) => {
          const mapping = {
            default: {
              csvImportLanugageMappingSexMale: 'M',
              csvImportLanugageMappingSexFemale: 'F',
            },
            ch: {
              csvImportLanugageMappingSexMale: '男',
              csvImportLanugageMappingSexFemale: '女',
            },
          };

          let sexMapped = item.model.sex;
          if (mapping[language]) {
            const match = Object.entries(mapping[language]).find((entry) => entry[1] === sexMapped);
            if (match) sexMapped = mapping.default[match[0]];
          }

          return { ...item.model, sex: sexMapped };
        });

      const respondentsTableUrl = groupId
        ? `/api/v2/groups/${groupId}/import/respondents`
        : '/api/v2/respondents/import/respondents';
      const assessmentProjectUrl = `/api/v2/projects/assessments/${projectId}/respondents/import/respondents`;
      const url = isAssessmentProject ? assessmentProjectUrl : respondentsTableUrl;

      const response = await apiInstance2.post(url, validRespondents);
      if (response.status === 200) {
        goNext();
        setImportedAmount(isAssessmentProject ? response.data.data.length : response.data.length);
        if (isAssessmentProject) dispatch(setAssessmentProjectHash(response.data.hash));
        onSuccess();
      } else {
        throw new Error('Respondents import error');
      }
    } catch (e) {
      createToastNotification({
        title: 'Error',
        message: I18n.t('importRespondentsErrorToast'),
        type: 'error',
      });
      onClose();
    }
  };
  return (
    <>
      <ContentWrapper>
        <SimplifiedTable
          data={dataMapped}
          onSelectChange={setSelectedData}
          columns={importedRespondentsColumns}
          customEmptyMessage={<ImportEmptyMessage />}
        />
      </ContentWrapper>
      <ButtonsWrapper>
        <StyledButton handler={importRespondents} disabled={selectedData.filter((item) => item.isValid) <= 0}>
          {I18n.t('Import')}
        </StyledButton>
      </ButtonsWrapper>
    </>
  );
};

ReviewStep.propTypes = {
  data: array.isRequired,
  goNext: func.isRequired,
  onClose: func.isRequired,
  setImportedAmount: func.isRequired,
  onSuccess: func.isRequired,
  groupId: oneOfType([string, number]),
  isAssessmentProject: bool,
  projectId: oneOfType([string, number]),
};

ReviewStep.defaultProps = {
  groupId: null,
  projectId: null,
  isAssessmentProject: false,
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  margin-bottom: 1rem;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledButton = styled(CustomButton)`
  width: 12rem;
  text-transform: uppercase;
`;

export default ReviewStep;
