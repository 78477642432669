import { createSelector } from 'reselect';
import { groupBy } from 'lodash';

const getCommonAssessments = (state) => state.groups.commonAssessments;
const getAvailableGroupsNorms = (state) => state.groups.availableNorms;
const getAvailableGroupsReports = (state) => state.groups.availableReports;

export const selectGroupsCommonAssessments = createSelector(getCommonAssessments, (assessments) =>
  assessments.map((item) => ({ ...item, label: item.testIDs.join(', ') })),
);

export const selectAvailableGroupsNorms = createSelector(getAvailableGroupsNorms, (norms) => {
  return groupBy(
    norms.map((norm) => ({ ...norm, value: norm.normID, label: norm.name })),
    'testID',
  );
});

export const selectAvailableGroupsReports = createSelector([getAvailableGroupsReports], (reports) => {
  // const profilesIds = Object.keys(idealProfiles);
  // const reportsIdsToRemove = [];
  // return reports.map((set) => ({
  //   ...set,
  //   reports: set.reports.filter((item) => {
  //     if (item.isIdealProfileReport && !profilesIds.includes(item.ipDependencies)) {
  //       reportsIdsToRemove.push(item.reportID);
  //       return false;
  //     }
  //     return true;
  //   }),
  //   reportIDArray: set.reportIDArray.filter((reportId) => !reportsIdsToRemove.includes(reportId)),
  // }));
  return reports;
});
