const translations = {
  About: 'Info',
  Launch: 'Avvio',
  Tutorial: 'Tutorial',

  // Login Page (Login.js)
  Email: 'Email',
  Submit: 'Entra',
  LoginNext: 'Vai al login',
  LoginSubmit: 'Continua',
  SwitchUser: 'Cambia user',
  ForgotPassword: 'Password dimenticata?',
  Password: 'Password',
  'Email is required': 'Email mancante',
  'Password is required': 'Password mancante',
  'Should be a proper email address': 'Questo non è un indirizzo email corretto',
  'Forgot password?': 'Hai dimenticato la password?',
  'Reset password': 'Reset password',
  'Request a reset link': 'Richiedi un link per il reset',
  'Back to login page': 'Torna alla pagina di login',
  'You should receive your reset link soon at': 'Riceverai il link di reset su',
  "Can't find the email?": "Non hai ricevuto l'email?",
  'You should also check your spam folder': 'Controlla la casella di posta indesiderata oppure lo spam',

  // (AddCompetencyCategory.js)
  Save: 'Salva',
  'Enter Name': 'Inserisci Nome',
  'Add competency category': 'Aggiungi categoria competenza',
  'Add description for the category': 'Aggiungi descrizione categoria',

  // (CompetencyGroupNavbar.js)
  'Main info': 'INFO PRINCIPALI',

  // (AddCompetencyGroup.js)
  Add: 'Aggiungi',
  'Name is required': 'Inserire Nome',
  'Add 360 assessment': 'Aggiungi 360 assessment',
  'Custom competencies': 'Competenze personalizzate',
  'Inbuilt competencies': 'Competenze predefinite',

  // (AddCompetencyItem.js)
  Edit: 'Modifica',

  // (AddCompetencyQuestion.js)
  'Add question': 'Aggiungi domanda',
  'Competency description': 'Descrizione competenze',

  // (AddCompetencyQuestionForm.js)
  'Item text': 'Testo Item',
  'Free field': 'Domanda aperta',
  'Multiple choice': 'Scelta multipla',
  'Add another language': 'Aggiungi altra lingua',

  // (AddCreditsFailureModal.js)
  'Credits addition error': 'Errore di inserimento crediti',
  'Try again': 'Prova di nuovo',

  //  (AddCreditsSuccessModal.js)
  Done: 'Fatto',
  'Adding credits': 'Aggiungo crediti',
  'Credits successfully added!': 'Crediti aggiunti con successo!',
  'Now your credit balance is:': 'Ora il saldo dei crediti è:',

  // (AddCredits.js)
  Cancel: 'Annulla',
  'Add credits': 'Aggiungere crediti',
  'Your credits balance': 'Saldo attuale crediti',
  'How many credits do you want to add?': 'Quanti crediti vuoi aggiungere?',
  'Purchase ID': 'ID Acquisto',
  'Error occurred during an attempt to add credits to your account':
    'Si è verificato un errore durante il tentativo di aggiungere crediti al tuo account',
  'Credits amount': 'Inserisci quantità crediti',

  // Edit Respondent (Index.js)
  Groups: 'Gruppi',
  Invites: 'Inviti',
  Reports: 'Report',
  Assessments: 'Assessment',
  IAssessments: 'Assessment',
  SAssessments: 'Assessment',
  'Respondent Information': 'Informazioni Soggetto',

  // Edit Respondent (AddTagModal.js)
  'Add tags': 'Aggiungi tag',
  'Select tags': 'Seleziona tag',

  // Edit Respondent (Information.js)
  Sex: 'Genere',
  gender: 'Genere',
  Title: 'Titolo',
  BioTitle: 'Appellativo',
  Sector: 'Settore',
  sector: 'Settore',
  occupation: 'occupazione',
  Company: 'Azienda',
  Industry: 'Settore industriale',
  industry: 'Settore industriale',
  Education: 'Titolo di studio',
  education: 'Titolo di studio',
  Reference: 'Referimento',
  Ethnicity: 'Nazionalità',
  ethnicity: 'Nazionalità',
  jobArea: 'Area di lavoro',
  'Job area': 'Area di lavoro',
  'First Name': 'Nome',
  'Family Name': 'Cognome',
  'Save changes': 'Salva modifiche',
  'Date of Birth': 'Data di Nascita',
  'First Language': 'Lingua madre',
  'Additional Info': 'Info Aggiuntive',
  'Main Information': 'Informazioni Principali',
  'first language': 'First language',
  firstLanguage: 'First language',

  // Edit Respondent (RespondentGroups.js)
  group: 'gruppo',
  'Remove from groups': 'Rimuovi da gruppo',
  'Are you sure you want to remove respondent from selected group(s)':
    'Sei sicuro di voler rimuovere il soggetto dal gruppo selezionato?',

  // Edit Respondent (RespondentInvites.js)
  invite: 'invito',
  'Invite Deletion': 'Elimina Invito',

  // Edit Respondent (RespondentReports.js)
  'Report deletion': 'Cancella Report',
  'Are you sure you want to delete selected report?': 'Sei sicuro di voler eliminare questo report?',

  // Edit Respondent (RespondentTests.js)
  'Test deletion': 'Elimina Test',
  'Are you sure you want to delete selected test?': 'Sei sicuro di voler eliminare il test selezionato?',

  // Edit Respondent (TagsManagement.js)
  Tags: 'Tag',
  '+ Add tag': '+ Aggiungi tag',

  // Groups (AddNewGroup.js)
  Create: 'Crea',
  'Group name': 'Nome gruppo',
  'Add new group': 'Aggiungi nuovo gruppo',
  'Group description': 'Descrizione Gruppo',

  // Groups (GroupHeader.js)
  Type: 'Tipologia',
  'Creation date': 'Data Creazione',
  'Members count': 'Numero componenti gruppo',
  member: 'componente',
  members: 'componenti',

  // Groups (Groups.js)
  List: 'Elenco',
  Respondents: 'Soggetti',
  'Delete group': 'Cancella Gruppo',
  'Are you sure you want to delete this group(s)': 'Sei sicuro di voler eliminare questo gruppo(i)?',
  'Administer Managed Group Session': 'Amministra Sessione di Gruppo Assistita',
  'Generate Open Group Link': 'Genera Link di Gruppo Aperto',
  'Create Norm from a Group': 'Creare Norma dal Gruppo',
  'Create an Ideal Profile from a Group': 'Creare Profilo Ideale dal Gruppo',
  'Invite Respondents for Assessment': "Invita soggetti all'assessment",
  'Delete selected Group': 'Cancella Gruppo selezionato',
  'Respondent to create new record': 'Soggetto rispondente crea il nuovo record',

  'Administer group session': 'Amministrare la sessione di gruppo',
  'You are about to launch a managed group session. While the session is active, actions with the group will be limited.':
    'Stai per lanciare una sessione di gruppo assistita. Mentre la sessione è attiva, le azioni compiute nel gruppo saranno limitate.',
  'Ok, Got it': 'Ok, ho capito',
  'Session link': 'Link della Sessione',
  'End session': 'Fine sessione',
  'Managed group session in progress': 'Sessione di gruppo assistita in corso',
  'Do you want to close the session? Assessments that have started may still be completed but no new respondents may be added.':
    'Vuoi chiudere la sessione? Le prove che sono già avviate potranno ancora essere portate a termine, ma non potranno essere aggiunti nuovi partecipanti.',
  Unknown: 'Sconosciuto',
  Authorize: 'Autorizza',
  Authorized: 'Autorizzato',
  Unauthorized: 'NonAutorizzato',
  'Authorize selected respondent(s) to commence the assessment?': "Vuoi Autorizzare per dare avvio all'assessment?",

  // Groups request report
  'Select common assessment / battery': "Selezionare l'Assessment/Batteria comune",
  reports: 'report',

  // Groups generate open group link
  link: 'link',
  Generate: 'Genera',
  'The link has been successfully generated': 'Il link è stato generato con successo',

  // Invites (Invites.js)
  'Delete invite': 'Cancella invito',
  'selected invites?': 'gli inviti selezionati?',
  'this invite?': 'questo invito?',

  // LaunchScreen (LaunchScreen.js)
  Language: 'Lingua',
  language: 'Lingua',

  // Notifications (Notifications.js)
  Results: 'Test Ricevuti',
  notification: 'notifica',
  Notifications: 'Notifiche',

  // Projects (AddProjectsModal.js)
  Min: 'Min',
  Info: 'Info',
  Raters: 'Valutatori',
  Deadline: 'Scadenza',
  'Peer Alias': 'Pari Grado Alias',
  Description: 'Descrizione',
  'Project Name': 'Nome Progetto',
  'All standard': 'Tutti standard',
  'Edit Project': 'Modifica Progetto',
  'Manager Alias': 'Manager Alias',
  'Project Alias': 'Progetto Alias',
  'Direct Report Alias': 'Diretto Superiore Alias',
  'Choose Project Type': 'Scegliere Tipologia di Progetto',
  '360 assessment has to be fully supported in at least 1 language':
    'Un assessment a 360° deve essere interamente basato su almeno 1 lingua',
  '360 Survey': 'Questionario 360',
  '360 Surveys': 'Questionari 360',
  'Other Alias': 'Altri',

  // Projects (projectList.js)
  'Add Assessment Project': 'Aggiungi Progetto di Assessment',
  'Add 360 Appraisal Project': 'Aggiungi un progetto di valutazione a 360',
  'Manage your assessment projects here. Plan and implement the right assessment approach and tests. Effectively and consistently administrate, communicate and manage the whole assessment process using this user friendly tool.':
    "Gestite qui i vostri progetti di assessment. Pianificate e implementate il sistema di valutazione e i test più adatti. Amministrate, comunicate e gestite l'intero processo di valutazione in modo efficace e coerente utilizzando questo strumento di facile utilizzo.",
  'Manage your entire 360 appraisal process here. Easy to use, allowing appraisals to be set up in minutes and results generated instantly. Choose from Psytech&apos;s existing competency framework or fully customise your 360 project.':
    "Gestite da qui l'intero processo di valutazione a 360 gradi. Di facile utilizzo, consente di impostare le varie sessioni in pochi minuti e di ottenere i risultati in modo istantaneo. Si può scegliere di usare il modello di competenze predefinito di Psytech oppure potete personalizzare completamente il vostro progetto a 360 gradi.",

  // Projects (Projects.js)
  All: 'Tutti',
  Project: 'Progetto',
  Projects: 'Progetti',
  'this project?': 'questo progetto?',

  '360 Appraisal': 'Valutazione a 360',
  'Delete project': 'Cancella progetto',
  'selected projects?': 'i progetti selezionati?',
  'Are you sure you want to delete': 'Sei sicuro di voler eliminare',

  // Raters (Raters.js)
  rater: 'valutatore',
  'Delete raters': 'Cancellare Valutatori',
  'Loading raters': 'Caricamento Valutatori',
  'selected raters?': 'i valutatori selezionati?',
  'this rater?': 'questo valutatore?',
  Session: 'Sessione',
  'Session for': 'Sessione per',

  // Raters (AddRatersModal.js)
  Role: 'Ruolo',
  Other: 'Altro',
  Peers: 'Pari grado',
  Confirm: 'Conferma',
  Managers: 'Manager',
  'No email': 'Manca email',
  'Add raters': 'Aggiungi valutatori',
  'Direct reports': 'Riporti diretti',
  'Search existing records': 'Ricerca nei record esistenti',
  'Make sure selected record has a valid email address added':
    'Assicurati che il record selezionato abbia un indirizzo e-mail valido',

  // Raters Invites (columns.js)
  Name: 'Nome',
  PersonName: 'Nome',
  'Expiry Date': 'Data Scadenza',
  'Invite status': 'Status invito',
  'Email Address': 'Indirizzo Email',
  'Hide expired': 'Nascondi scaduti',

  // Raters Invites (Invites.js)
  'chosen invite(s)?': 'invito(i) prescelto(i)?',
  'selected invite(s)?': 'invito(i) selezionato(i)?',
  'Loading invites': 'Caricamento inviti',

  // Raters Reports (Reports.js)
  Pending: 'In Corso',
  Unread: 'Non letto',
  Viewed: 'Visualizzato',
  Error: 'Errore',
  'Support ticket raised': "Errore. Già inviato ticket all'assistenza.",
  'Loading reports': 'Caricamento Report',

  // Raters Results (Results.js)
  result: 'risultato',

  // Reports Reports (Reports.js)
  'Are you sure you want to delete selected report(s)?':
    'Sei sicuro di voler cancellare il/i rapporto/i selezionato/i?',

  // RequestReport360 (RequestReport360.js)
  'Report Request for': 'Richiedi Report per',
  'Report Request': 'Richiesta Report',
  credits: 'crediti',
  'Total Credits:': 'Totale Crediti:',
  Request: 'Richiesta',
  Self: 'Self',

  // Respondents AddRespondentModal (AddRespondentForm.js)
  'Company Name': 'Nome Azienda',
  'Job Area': 'Area di lavoro',
  Remove: 'Cancellare',

  // Respondents AddRespondentModal (AddRespondentModal.js)
  'Add respondent': 'Aggiungi soggetto',
  'Main Details': 'Info Principali',
  'Extra Details': 'Dettagli aggiuntivi',
  'Group Membership': 'Membership Gruppo',

  // Respondents AddRespondentModal (GroupsTab.js)
  'Select groups': 'Seleziona gruppo',

  // Respondents AdministerAssessment (index.js)
  Start: 'Avvia',
  Administer: 'Somministra',
  to: 'a',
  questionnaires: 'questionari',
  reasoning: 'ragionamento',
  solutions: 'solutions',
  batteries: 'batterie',
  favourites: 'preferiti',

  // Respondents (AnswersStep.js)
  'Test:': 'Test:',

  // Respondents AnswerSheet (index.js)
  Previous: 'Indietro',
  'Administer answer sheet for': 'Somministrare il foglio di risposta per',
  answers: 'risposte',
  Respondent: 'Soggetto',

  // Respondents (AssessmentStep.js)
  'Welcome text': 'Testo di benvenuto',
  'Expiry date': 'Data scadenza',

  // Invite (index.js)
  'Invite respondents': 'Invita soggetti',
  Invite: 'Invita',
  'Create invite and go next': 'Crea invito e vai avanti',
  Finish: 'Finish',
  'You are sending an invitation for:': 'Stai inviando un invito a:',
  in: 'in',
  invitation: 'invito',
  OR: 'OPPURE',
  other: 'Immediatamente',

  // Invite (InvitationStep.js)
  Immediately: 'Immediatamente',
  Later: 'Dopo',
  'Email address is missing or not valid in respondent record':
    "L'indirizzo e-mail non è presente o non è valido nel registro dei soggetti inseriti",
  'Copy link': 'Copia link',
  Copied: 'Copiato',
  'Copy and share generated link': 'Copia e condividi il link generato',
  'Send invitation link via email to:': "Spedire il link dell'invito via e-mail a:",
  'Email template': 'Email template',
  'When do you want to send an invite?': "Quando vuoi inviare l'invito?",
  'Email invite': 'Invia Email',
  'Email sent': 'Email inviata',

  // RequestReportModal (index.js)
  'Request report': 'Richiedi report',
  'Request report for': 'Richiedi report di',
  for: 'di',
  'Request Reports': 'Richiedi i Report',
  'Common tests: ': 'Test in comune: ',
  'At least 1 assessment must be common': 'Deve essere in comune almeno 1 assessment',
  'Selected respondents': 'Soggetti selezionati',
  tests: 'tests',
  'Assessment/Battery': 'Assessment/Batteria',
  'Total credits': 'Prelievo crediti',

  // RequestReportModal (ReportsList.js)
  'Available Reports': 'Report disponibili',
  Norm: 'Norme',
  Options: 'Opzioni',
  'Ideal Profile': 'Profilo Ideale',

  // Respondents (AddToGroup.js)
  'Add to group': 'Aggiungi al gruppo',
  'Add respondent(s) to group': 'Aggiungi soggetto(i) al gruppo',

  // Respondents (ManageTagsModal.js)
  'Manage respondents tags': 'Gestire tag soggetti',
  'Select from existing tags or add new': 'Scegliere tra i tag esistenti o aggiungere nuovi tag',
  'Add tag to selected respondent(s)': 'Aggiungi tag a soggetto(i) selezionato(i)',
  'Remove tag from selected respondent(s)': 'Rimuovi tag da soggetto(i) selezionato(i)',

  // Respondents  (RespondentDropdown.js)
  'Add new respondent': 'Aggiungi nuovo soggetto',
  'Import respondents from file': 'Importa soggetti da file',
  'Edit respondent': 'Modifica soggetto',
  'Delete respondent': 'Elimina soggetto',
  'All respondents are selected': 'Tutti i soggetti sono selezionati',
  'Clear selection': 'Annulla la selezione',
  'All respondents on this page are selected': 'Tutti i soggetti di questa pagina sono selezionati',
  'Select all respondents in this result': 'Seleziona tutti i soggetti in questo elenco',

  // Respondents  (Respondents.js)
  'Delete respondents from group': 'Cancella soggetti dal gruppo',
  'Loading respondents': 'Caricamento soggetti',
  'Are you sure you want to remove': 'Sei sicuro di voler eliminare',
  'from the group?': 'dal gruppo?',

  // Reusable Accordion (Accordion.js)
  INBUILT: 'INBUILT',

  // Reusable Accordion (CompetencyGroupsAccordion.js)
  'Collapse all': 'Restringi tutto',
  'Expand all': 'Espandi tutto',

  // Reusable  Filter (DateInputGroup.js)
  'Date range': 'Intervallo di Date',

  // Reusable  Filter (Filter.js)
  Filter: 'Filtro',
  'Clear all': 'Pulisci tutto',
  Apply: 'Applica',

  // Reusable Filter (TextInputGroup.js)
  'Filter type': 'Tipo di Filtro',

  // Reusable Filter (Search.js)
  Search: 'Ricerca',

  // Reusable Badges (CustomBadge.js)
  CUSTOM: 'CUSTOM',

  // Reusable TextEditor (Editor.js)
  'Insert variable': 'Inserire variabile',

  // Reusable  (ConfirmationModal.js)
  No: 'No',
  Yes: 'Sì',

  // Reusable  (DatePicker.js)
  'Select the date': 'Selezionare la data',

  // Reusable  (DateTimeSelect.js)
  'Time:': 'Tempo:',

  // Reusable  (ErrorMessage.js)
  'Oops, something went wrong.': 'Oops, qualche cosa è andato storto.',
  'Please try again.': 'Si prega di provare di nuovo.',
  Ok: 'Ok',

  // Reusable (Quantity.js)
  Quantity: 'Quantità',

  // Reusable (StatusCell.js)
  'In Progress': 'In Progress',
  'On Hold': 'In Attesa',
  Canceled: 'Cancellata',
  Completed: 'Completato',

  // Session SessionInvite (index.js)
  'Send invite to raters': 'Spedire Invito ai valutatori',
  'Send invite to raters who have not yet completed': "Inviare l'invito ai valutatori che non hanno ancora risposto",
  'Send invite to raters who have not yet been invited':
    "Inviare l'invito ai valutatori che non sono ancora stati invitati",
  'Send invite to raters who have already been invited but haven&apos;t yet completed':
    "Inviare l'invito ai valutatori che sono già stati invitati ma non hanno ancora risposto",
  'Send invite to self with a request to specify their own raters':
    'Inviare un invito al Self con la richiesta di specificare i propri valutatori',
  'Welcome text for Self Raters': 'Messaggio Benvenuto per il Self',
  'Email template for Self Raters': 'Email template per il Self',
  'Welcome text for Other Raters': 'Messaggio Benvenuto per Altri Valutatori',
  'Email template for Other Raters': 'Email template per altri Valutatori',
  Send: 'Salva',

  // Session SessionInvite (SessionInvitationStep.js)
  'Email body': 'Corpo Email',
  'Email subject': 'Oggetto Email',
  'Invitation date': 'Data Invito',
  'Email body for Self Raters': 'Corpo Email per i Self',
  'Email body for Other Raters': 'Corpo Email per altri Valutatori',
  'Email subject for Other Raters': 'Oggetto Email per altri Valutatori',
  'Email subject for Self Raters': 'Oggetto Email per i Self',

  // Session (AddSessionModal.js)
  'Sorry, session with selected respondent already exists.':
    'Spiacenti, la sessione con la persona selezionata esiste già.',
  'Add session': 'Aggiungi sessione',
  'Session SELF': 'Sessione SELF',
  'Search existing record to select session SELF': 'Ricerca nei record esistenti per selezionare la sessione SELF',

  // Session (SessionHeader.js)
  'All Standard': 'Tutti Standard',
  '360 Assessment:': '360 Assessment:',
  'Type:': 'Tipologia:',
  'Status:': 'Status:',
  'Deadline:': 'Scadenza:',

  // Session (Sessions.js)
  Sessions: 'Sessioni',
  session: 'sessione',
  'Loading sessions': 'Caricamento sessioni',
  'Delete session': 'Elimina sessione',
  'Delete sessions': 'Elimina sessioni',
  'this session?': 'questa sessione?',
  'Are you sure you want to delete this project': 'Sei sicuro di voler cancellare questo progetto',

  // Session header (SessionHeader.js)
  'days left': 'giorni rimanenti',
  'Send invites': 'Invia inviti',
  'Outstanding ratings': 'In attesa di risposta',
  'Send reminder': 'Invia sollecito',
  'Sessions completed': 'Sessioni completate',
  'Send invite': 'Invia invito',

  // Settings AddResponseScale (AddResponseScale.js)
  'Loading...': 'Caricamento...',

  // Settings (AddScaleForm.js)
  'Add response scale': 'Aggiungi Scala di Risposta',
  'Edit response scale': 'Modifica Scala di Risposta',

  // Settings Components   (EditTitle.js)
  'Edit Name': 'Modifica Nome',
  'Edit 360 assessment': 'Modifica assessment 360',

  // Settings Components  (QuestionItem.js)
  Question: 'Domanda',
  'No translation available for': 'Nessuna traduzione disponibile per',

  // Settings Components  (ReviewCompetencies.js)
  'Loading competencies': 'Caricamento Competenze',
  Competencies: 'Competenze',
  CONFIRM: 'CONFERMA',

  // Settings utils (ResponseScale.util.js)
  'Name is the required field': 'Il Nome è un campo obbligatorio',
  'All response scales should be filled': 'Tutte le scale di risposta devono essere compilate',

  // Settings (CustomCompetencies.js)
  'Are you sure you want to remove selected': 'Sei sicuro di voler rimuovere le selezionate',
  Collapse: 'Restringi',
  Expand: 'Espandi',
  '360 settings': 'impostazioni 360',

  // Settings (Settings.js)
  Settings: 'Impostazioni',

  // (BulkActionsSidebar.js)
  'Bulk actions': 'Azioni in blocco',

  'Add new 360 survey': 'Aggiungi nuovo questionario 360',
  'Add 360 survey': 'Aggiungi questionario 360',
  'Edit 360 survey': 'Modifica questionario 360',
  'Duplicate 360 survey': 'Duplica questionario 360',
  'Delete selected 360 survey(s)': 'Cancella questionario(i) 360 selezionato',
  Sorting: 'Ordinamento',

  // Sidebar.js
  'Are you sure you want to log out?': 'Sei sicuro di voler uscire?',
  'Credits balance:': 'Crediti:',

  // Settings  (Settings.const.js)
  'Assessments and batteries': 'Assessments e batterie',
  'Ideal Profiles': 'Profili Ideali',
  Norms: 'Norme',
  '360 Assessments': '360 Assessments',
  'Response Scales': 'Scale di Risposta',
  Templates: 'Templates',
  'Email templates': 'Email templates',
  Account: 'Account',
  'General info': 'Info Generali',
  General: 'Generali',
  'Credits History': 'Storico Crediti',
  'Reports Usage': 'Report Richiesti',
  'Credit Updates': 'Aggiornameti Crediti',
  'Reports Stats': 'Statistiche Report',
  'Assessments Stats': 'Statistiche Assessment',
  'Activity Log': 'Registro delle attività',

  // Settings (AssessmentsAndBatteries.js)
  assessment: 'assessment',
  'this battery?': 'questa batteria?',
  'Delete battery': 'Elimina batteria',
  'Delete batteries': 'Elimina batterie',
  'Assessment settings': 'Impostazioni assessments',
  'selected batteries?': 'le batterie selezionate?',
  // Settings (AddNormMode.js)
  'Add New Norm': 'Aggiungi Nuova Norma',
  'Norm Name': 'Nome Norma',
  'Assessment on which the norm profile is based': 'Valutazione su cui si basa il profilo normativo',
  Frequency: 'Frequenza',
  SD: 'DS',
  'Sample Size': 'Dimensione del campione',
  'Sample size must be greater than or equal to 1': 'La dimensione del campione deve essere maggiore o uguale a 1',
  'Select group': 'Seleziona un gruppo',

  // Settings (CompetencyGroups.js)
  'Copy of': 'Copia da',
  'Competency Group': 'Gruppo Competenze',
  'Delete assessment': 'Cancella assessment',
  'selected assessments': 'assessment Selezionati',
  'this assessment': 'questo assessment',
  'Duplicate assessment': 'Duplica assessment',
  'Are you sure you want to duplicate': 'Sei sicuro di voler duplicare',

  // Settings (CompetencyGroupsDetails.js)
  Structure: 'Struttura',
  'Response scale': 'Scala Risposta',
  'Loading Competency Details': 'Caricamento Dettagli Competenze',
  'Categories:': 'Categorie:',
  'Competencies:': 'Competenze:',
  'Numbers of items': 'Numero di items',
  'Multiple choice:': 'Scelta multipla:',
  'Free text:': 'Domanda aperta:',
  'Estimated time': 'Tempo stimato',
  minutes: 'minuti',
  'Available languages': 'Lingue disponibili',
  'Make sure at least one language is fully supported in assessment':
    'Assicurarsi che almeno una lingua sia pienamente supportata nel processo di valutazione',
  Questions: 'Domande',
  'Competency deletion': 'Elimina Competenze',
  'competencies?': 'competenze?',
  'competency?': 'competenza?',
  'Assessment deletion': 'Elimina Assessment',
  'Are you sure you want to deleted selected the assessment?':
    "Sei sicuro di voler cancellare l'assessment selezionato?",
  // Settings Templates (WelcomeText.js)
  'Welcome texts': 'Testo di Benvenuto',
  'selected welcome texts?': 'i testi di benvenuto selezionati?',
  'this welcome text?': 'questo testo di benvenuto?',

  // Settings Templates (WelcomeTextScreen.js)
  'Add new welcome text': 'Aggiungi nuovo testo benvenuto',
  'Template name': 'Nome Template',
  'welcome text': 'testo benvenuto',
  'Delete welcome texts': 'Cancella testi benvenuto',

  // Settings Templates (templatesTabs.js)
  'Assessment welcome texts': 'Testi benventuto Assessment',
  template: 'template',
  'Delete templates': 'Elimina template',
  'selected templates?': 'i templates selezionati?',
  'this template?': 'questo template?',

  // Settings Templates (TemplatesScreen.js)
  'Field is required': 'Campo obbligatorio',
  'Template subject': 'Oggetto Template',

  // Settings ResponseScales (ResponseScales.js)
  'Response Scale': 'Scala di Risposta',
  'Delete scale(s)': 'Elimina scala(e)',
  'Are you sure you want to delete this scale(s)?': 'Sei sicuro di voler cancellare questa(e) scala(e)?',
  Responses: 'Risposte',
  Languages: 'Lingue',
  'Score right to left': 'Punteggio da destra a sinistra',

  // Settings ResponseScales (bulkActions.js)
  'Delete selected response scale(s)': 'Cancellare la/le scala(e) di risposta selezionate',
  // Settings Norms (NormsHeader.js)
  'Norm Type:': 'Norme:',
  'Creation Date:': 'Data Creazione:',

  // Settings Norms (NormsScales.js)
  'Norm Table': 'Tabella Norme',
  'Frequency Histograms': 'Istogrammi di Frequenza',
  'Bio Data': 'Dati biografici',
  Stenine: 'Stenine',
  Stentable: 'Stentable',

  // Settings Norms (Norms.js)
  'Add new norm': 'Aggiungi nuova norma',
  'Delete norm(s)': 'Elimina norma(e)',
  'Assessment and Batteries': 'Assessment e Batterie',
  'selected norms?': 'norme selezionate?',
  'this norm?': 'questa norma?',
  'Creation Date': 'Data Creazione',

  // Settings Norms (HistogramView.js)
  FREQUENCY: 'FREQUENZA',
  'RAW SCORE': 'P. GREZZO',
  'Raw Score': 'P. Grezzo',

  // Settings ManageBattery (index.js)
  'Battery name': 'Nome batteria',
  'Search existing assessments': 'Cerca gli assessment esistenti',
  'Add battery': 'Aggiungi batteria',

  // Settings IdealProfile (IdealProfileScales.js)
  'Ideal profile type:': 'Profilo Ideale:',
  'Unsaved data': 'Dati non salvati',
  'The current ideal profile has not been saved. Would you like to save it?':
    "L'attuale profilo ideale non è stato salvato. Vorresti salvarlo?",

  addIdealProfileGroups: 'Gruppi',

  // Sidebar.js
  Logout: 'Esci',

  // Settings IdealProfile (IdealProfile.js)
  'Delete ideal profiles': 'Elimina profili ideali',
  'selected ideal profiles?': 'i profili ideali selezionati?',
  'this ideal profile?': 'questo profilo ideale?',

  // Settings AddIdealProfile.js
  'Add ideal profile': 'Aggiungi profilo ideale',
  'Ideal profile name': 'Nome profilo ideale',
  'I will manually select the values': 'Sceglierò manualmente i punteggi',
  'I will use respondent data to determine the values':
    'Utilizzerò i dati dei soggetti in archivio per determinare i punteggi',
  Test: 'Test',
  // (advancedFilterOptions.js)
  'Is equal to': "E' uguale a",
  'Is not equal to': 'Non è uguale a',
  'Starts with': 'Comincia con',
  Contains: 'Contiene',
  'Does not contain': 'Non contiene',
  'Ends with': 'Finisce con',
  'Lesser than': 'Inferiore a',
  'Greater than': 'Superiore a',
  '1 day': '1 giorno',
  '3 days': '3 giorni',
  '1 week': '1 settimana',
  '2 weeks': '2 settimane',
  '1 month': '1 mese',
  '1 year': '1 anno',

  // groupsTypes.js
  Unsupervised: 'SenzaSupervisione',
  Supervised: 'Supervisionato',
  // idealProfileTypes.js
  'Manually configured': 'Configurato Manualmente',
  'Based on respondent data': 'Basato sui dati dei soggetti',

  // inviteCreationCategories.js
  'Personality tests': 'Test Personalità',
  'Interests Values Attitudes': 'Interessi Valori Atteggiamenti',
  Reasoning: 'Ragionamento',
  Value: 'Valori',
  'Preferences/Styles': 'Preferenze/Stili',
  'Custom battery': 'Batteria Customizzata',
  'General Solutions': 'General Solutions',
  'Screening Solutions': 'Screening Solutions',
  Solution: 'Solution',
  Battery: 'Batteria',
  Assessment: 'Assessment',
  'Every 4 days': 'Ogni 4 giorni',
  'Every 3 days': 'Ogni 3 giorni',
  'Every 2 days': 'Ogni 2 giorni',
  'Every day': 'Ogni giorno',
  "Don't send": 'Non inviare',
  Immediate: 'Immediato',

  // inviteStatuses.js
  'Not sent': 'Non inviato',
  Scheduled: 'Programmato',
  Sent: 'Spedito',
  Failed: 'Fallito',

  // raterRoles.js
  Manager: 'Manager',
  Peer: 'Pari grado',
  'Direct report': 'Superiore Diretto',

  // respondentSelectOptions.js
  'No Qualification': 'Nessun titolo di studio',
  'Secondary School': 'Licenza Media',
  'Industry or Trade Training': 'Industria o Formazione Commerciale',
  'Professional Qualification': 'Diploma professionale (3 anni)',
  'Tertiary Institute Diploma/Certificate': 'Diploma Superiore (5 anni)',
  'University Bachelor Degree': 'Laurea specialistica (3 + 2/3 anni)',
  'Postgraduate Diploma/Certificate': 'Diploma Post Laurea/Specialistica',
  'Bachelor Honours': '',
  'Masters Degree': 'Master',
  'Doctorate Degree': 'Dottorato di Ricerca',
  'African American': '',
  Aborigine: '',
  Algerian: 'Algerina',
  African: '',
  Afrikaner: '',
  Albanian: 'Albanese',
  American: '',
  Argentinian: 'Argentina',
  'Australian Aborigine': '',
  Arab: '',
  Asian: '',
  Austrian: 'Austrica',
  Australian: 'Australiana',
  Bangladeshi: 'Bangladesh',
  Balinese: 'Balinese',
  'Black British': '',
  'Black Canadian': '',
  Belgian: 'Belga',
  'Black Carribean': '',
  'Black Indian': '',
  'Black African': '',
  Bolivian: 'Boliviana',
  Bengali: 'Bengalese',
  'Black Other': '',
  Bosnian: 'Bosniaca',
  Brazilian: 'Brasiliana',
  British: 'Britannica',
  Belarusian: 'Bielorussa',
  Bulgarian: 'Bulgara',
  Burmese: 'Birmana',
  Canadian: 'Canadese',
  Castilian: '',
  Catalan: '',
  Celtic: '',
  Chinese: 'Cinese',
  'Channel Islander': 'Isole del Canale',
  Cossack: '',
  Chilean: 'Cilena',
  Cambodian: 'Cambogiana',
  Chechen: 'Cecena',
  Colombian: 'Colombiana',
  'Costa Rican': 'Costaricana',
  Croatian: 'Croata',
  Corsican: 'Corsa',
  Cantonese: 'Cantonese',
  Cuban: 'Cubana',
  Cypriot: 'Cipriota',
  'Cypriot Greek': '',
  Czech: 'Ceca',
  Danish: 'Danese',
  Dutch: 'Olandese',
  Eurasian: '',
  Ecuadorian: 'Ecuadoriana',
  Egyptian: 'Egiziana',
  English: 'Inglese',
  Estonian: 'Estone',
  Ethiopian: 'Etiope',
  European: '',
  Fijian: 'Fijiana',
  Filipino: 'Filippina',
  Flemish: '',
  Finnish: 'Finlandese',
  French: 'Frencese',
  Gaelic: 'Gaelica',
  Georgian: 'Georgiana',
  German: 'Tedesca',
  Greek: 'Greca',
  Hungarian: 'Ungherese',
  Hispanic: 'Ispanica',
  'Hong Kong Chinese': 'Hong Kong Cinese',
  Icelandic: 'Islandese',
  Indonesian: 'Indonesiana',
  Irani: 'Irani',
  Indian: 'Indiana',
  'Iranian/Persian': 'Iraniana/Persiana',
  Iraqi: 'Irachena',
  Irish: 'Irlandese',
  'Iranian Arab': '',
  Iranian: 'Iraniana',
  Italian: 'Italiana',
  Jamaican: 'Jamaicana',
  Jewish: '',
  'Jewish/Israeli': 'Ebraica/Israeliana',
  Jordanian: 'Giordana',
  Japanese: 'Giapponese',
  Kenyan: 'Keniota',
  Kiwi: 'Kiwi',
  Kongo: 'Kongo',
  Korean: 'Coreana',
  Latin: '',
  Lebanese: 'Libanese',
  Lithuanian: 'Lituana',
  Libyan: 'Libica',
  'Latin American': '',
  Latino: '',
  Luxembourger: 'Lussemburgese',
  Latvian: 'Lettone',
  Macedonian: 'Macedone',
  'Middle Eastern': '',
  Malaitian: 'Malaitiana',
  Maori: 'Maori',
  Monacan: 'Monacan',
  Moroccan: 'Marocchina',
  Maltese: 'Maltese',
  Mexican: 'Messicana',
  Malay: 'Malese',
  'Mixed Race': '',
  'New Zealand European': '',
  Nigerian: 'Nigeriana',
  Norwegian: 'Norvegese',
  Nepalese: 'Nepalese',
  'New Zealander': 'Neo Zelandese',
  'Other Ethnicity': '',
  Paraguayan: 'Paraguaiana',
  'Pacific Islander': 'Isole Pacifico',
  Persian: '',
  Peruvian: 'Peruviana',
  'Persian/Iranian': 'Persiana/Iraniana',
  Pakistani: 'Pachistana',
  Palestinian: 'Palestinese',
  Polish: 'Polacca',
  'Papua New Guinean': 'Papua Nuova Guinea',
  'Puerto Rican': 'Portoricana',
  Portuguese: 'Portoghese',
  Polynesian: 'Polinesiana',
  Romanian: 'Rumena',
  Russian: 'Russa',
  Samoan: 'Samoan',
  'South African Coloured': '',
  Scottish: 'Scozzese',
  Swede: 'Swede',
  'South African European': '',
  Serbian: 'Serba',
  Swedish: 'Svedese',
  'South American Indian': '',
  Sicilian: '',
  Slovak: 'Slovacca',
  Slav: '',
  Spanish: 'Spagnola',
  'Sri Lankan': 'Sri Lankan',
  'Southeast Asian': 'Sudest asiatica',
  Swiss: 'Svizzera',
  Swahili: 'Swahili',
  Swazi: 'Swazi',
  Taiwanese: 'Taiwanese',
  Thai: 'Thai',
  Turkish: 'Turca',
  Tamil: 'Tamil',
  Tongan: 'Tongan',
  Rwandan: 'Rwandan',
  Tunisian: 'Tunisina',
  Udi: 'Udi',
  Ugandan: 'Ugandese',
  Ukrainian: 'Ucraina',
  Uruguayan: 'Uruguaiana',
  Vietnamese: 'Vietnamita',
  Welsh: 'Gallese',
  White: '',
  'West Indian': '',
  Zimbabwean: 'Zimbabwe',
  Zulu: 'Zulu',
  '- Chief executive':
    '- Direttori, dirigenti superiori della pubblica amministrazione, membri dell’esecutivo e dei corpi legislativi',
  '- Administrative or commercial manager': '- Dirigenti amministrativi e commerciali',
  '- Production or specialized services manager': '- Dirigenti nei servizi di produzione e specializzati',
  '- Hospitality, retail or other services manager': '- Dirigenti nei servizi alberghieri, nel commercio ed assimilati',
  '- Senior manager': '- Senior Manager (Direttore Generale, Amministratore Delegato, ecc.)',
  '- Middle manager': '- Quadro Intermedio',
  '- Junior manager/supervisor': '- Junior Manager/Supervisore Junior',
  '- Other': '- Altro',
  Professional: 'Professioni intellettuali e scientifiche',
  '- Science or engineering professional': '- Scienze e ingegneria',
  '- Health professional': '- Specialisti della salute',
  '- Teaching professional': '- Specialisti dell’educazione',
  '- Business or administration professional': '- Specialisti delle scienze commerciali e dell’amministrazione',
  '- Information and communications technology professional':
    '- Specialisti delle tecnologie dell’informazione e della comunicazione',
  '- Legal, social or cultural professional': '- Specialisti in scienze giuridiche, sociali e culturali',
  'Technician or associate professional': 'Professioni tecniche intermedie',
  '- Science or engineering associate professional': '- Scienze e ingegneria',
  '- Health associate professional': '- Professioni intermedie nelle scienze della salute',
  '- Business or administration associate professional': '- Scienze commerciali e dell’amministrazione',
  '- Legal, social, cultural or related associate professional':
    '- Scienze giuridiche, sociali e culturali e attività professionali affini',
  '- Information and communications technician':
    '- Specialisti delle tecnologie dell’informazione e della comunicazione',
  'Clerical support worker': 'Impiegati di ufficio',
  '- General or keyboard clerk': '- Impiegati con compiti generali e operatori su macchine di ufficio',
  '- Customer services clerk': '- Impiegati a contatto diretto con il pubblico',
  '- Numerical or material recording clerk':
    '- Impiegati addetti ai servizi contabili e finanziari e alla registrazione dei materiali',
  'Service or sales worker': 'Professioni nelle attività commerciali e nei servizi',
  '- Personal service worker': '- Professioni nei servizi alle persone',
  '- Sales worker': '- Professioni nelle attività commerciali',
  '- Personal care worker': '- Professioni nell’assistenza alle persone',
  '- Protective services worker': '- Professioni nei servizi di sicurezza, custodia e vigilanza',
  'Skilled agricultural, forestry or fishery worker':
    'Personale specializzato addetto all’agricoltura, alle foreste e alla pesca',
  '- Market-oriented skilled agricultural worker': '- Agricoltori e operai agricoli specializzati',
  '- Market-oriented skilled forestry, fishing or hunting worker':
    '- Lavoratori forestali specializzati, pescatori e cacciatori',
  '- Subsistence farmer, fisher, hunter or gatherer':
    '- Lavoratori dell’agricoltura, della caccia, della pesca e del raccolto di sussistenza',
  'Craft or related trades worker': 'Artigiani e operai specializzati',
  '- Building or related trades worker, excluding electrician':
    '- Operai specializzati dell’edilizia ed assimilati, ad eccezione degli elettricisti',
  '- Metal, machinery or related trades worker': '- Operai metalmeccanici specializzati ed assimilati',
  '- Handicraft or printing worker': '- Artigiani e operai specializzati delle attività tipografiche',
  '- Electrical or electronic trades worker':
    '- Artigiani e operai specializzati delle attrezzature elettriche ed elettroniche',
  '- Food processing, wood working, garment or other craft or related trades':
    '- Artigiani e operai specializzati delle lavorazioni alimentari, del legno, del tessile ed assimilati',
  'Plant or machine operator, or assembler': 'Conduttori di impianti e macchinari e addetti al montaggio',
  '- Stationary plant or machine operator': '- Conduttori di impianti e macchinari fissi',
  '- Assembler': '- Assemblatori',
  '- Driver or mobile plant operator': '- Conduttori di veicoli e di macchinari mobili',
  'Elementary occupation': 'Professioni non qualificate',
  '- Cleaner or helper': '- Addetti alle pulizie e collaboratori',
  '- Agricultural, forestry or fishery labourer': "- Addetto all'agricoltura, alle foreste o alla pesca",
  '- Labourer in mining, construction, manufacturing or transport': '- Operaio in vari settori',
  '- Food preparation assistant': '- Personale non qualificato addetto alla ristorazione',
  '- Street or related sales or service worker': '- Commercianti ambulanti di manufatti o di servizi',
  '- Refuse worker or other elementary worker':
    '- Addetti alla raccolta dei rifiuti e altre professioni non qualificate',
  'Armed forces occupation': 'Forze Armate',
  '- Commissioned armed forces officer': '- Ufficiali delle forze armate',
  '- Non-commissioned armed forces officer': '- Sottufficiali delle forze armate',
  '- Armed forces occupation, other rank': '- Professioni relative alle forze armate, altri gradi',
  'Local Government/Authority': 'Amministrazione/Enti Pubblici Locali',
  'Private, Corporate ': 'Settore Aziendale Privato ',
  'Public service, Fed/State Govt': 'Amministrazione/Enti Pubblici Nazionali',
  'State Owned Corporation': 'Aziende di proprietà dello Stato',
  'Administrative and Support Service Activities': 'Attività Amministrative e Servizio di Supporto',
  'Agriculture, Forestry and Fishing': 'Agricoltura, Silvicoltura e Pesca',
  'Arts, Entertainment and Recreation': 'Attività Artistiche, di Intrattenimento e Divertimento',
  'Banking & Finance': 'Servizi Bancari e Finanziari',
  'Business Services': 'Servizi alla Produzione',
  'Architecture and Construction': 'Attività Architettoniche e Costruzioni',
  'Education and Training': 'Istruzione e Formazione',
  'Utilities (Gas, Water, Electric, etc)': 'Servizi (Gas, Acqua, Elettricità, ecc.)',
  Engineering: 'Attività Ingegneristiche',
  'Hospitality and Tourism': 'Servizi Alberghieri e Turismo in genere',
  'Human Resources': 'Risorse Umane',
  'Health Sector': 'Settore Sanitario',
  'Information and Technology': 'IT Informatica',
  Insurance: 'Servizi Assicurativi',
  'Law Enforcement': 'Settore Legislativo, Legale',
  Manufacturing: 'Settore Manifatturiero',
  'Mining and Quarrying': 'Settore Minerario ed Estrattivo',
  'Military & Defence': 'Difesa e Forze Armate',
  'Oil and Gas/Energy Exploration': 'Settore Petrolchimico, Energetico e Gas',
  'Pharmaceutical Industry': 'Industria Farmaceutica',
  'Public Administration': 'Amministrazione Pubblica',
  'Real Estate Activities': 'Attività Immobiliari',
  'Scientific and Technical Activities': 'Attività Scientifiche e Tecniche',
  Security: 'Settore Pensionistico',
  'Social Work': 'Assistenza Sociale',
  Telecommunications: 'Telecomunicazioni',
  'Transportation and Storage': 'Trasporto e Stoccaggio',
  Media: 'Mezzi di Comunicazione',
  'Wholesale and Retail Trade': "Commercio all'Ingrosso e al Dettaglio",
  Arabic: 'Arabic',
  'Brazilian Portuguese': 'Brazilian Portuguese',
  'Chinese-Simplified': 'Chinese-Simplified',
  'Chinese-Traditional': 'Chinese-Traditional',
  Hebrew: 'Hebrew',
  'Hindi-Urdu': 'Hindi-Urdu',
  isiNdebele: 'isiNdebele',
  isiXhosa: 'isiXhosa',
  isiZulu: 'isiZulu',
  'Latin American Spanish': 'Latin American Spanish',
  Malayalam: 'Malayalam',
  Punjabi: 'Punjabi',
  Sepedi: 'Sepedi',
  'Southern Thai': 'Southern Thai',
  Sesotho: 'Sesotho',
  Setswana: 'Setswana',
  siSwati: 'siSwati',
  'Tagalog (Filipino)': 'Tagalog (Filipino)',
  Turkmen: 'Turkmen',
  Tshivenda: 'Tshivenda',
  Xitsonga: 'Xitsonga',
  Male: 'Maschio',
  Female: 'Femmmina',
  'Not Specified': 'Non specificato',

  // errorMessages.js
  'Email address is not valid': "L'indirizzo e-mail non è valido",

  // table bulkActions assessments.js
  'Edit battery': 'Modifica batteria',
  // table singleRowActions competency.js
  'Add competency': 'Aggiungi competenza',
  'Edit competency category': 'Modifica categoria competenza',
  'Delete competency category': 'Elimina categoria competenza',
  'Edit competency': 'Modifica competenza',
  'Delete competency': 'Elimina competenza',
  'Edit question': 'Modifica domanda',
  'Delete question': 'Elimina domanda',

  // table singleRowActions competencyGroup.js
  'Delete competency group': 'Elimina gruppo competenze',
  'Go to competency groups details': 'Vai ai particolari dei gruppi di competenza',

  // table singleRowActions  group.js
  'Invite respondents to questionnaire': 'Invita i partecipanti al questionario',
  'Administer answer sheet': 'Aggiungi Foglio Risposte',
  'Administer managed group': 'Gestire sessioni di Gruppo',
  'Create an ideal profile based on selected group': 'Creare un profilo ideale in base al gruppo selezionato',
  'Create a Norm based on selected group': 'Creare una norma in base al gruppo selezionato',
  'View or edit': 'Visualizza o modifica',
  Delete: 'Elimina',

  // table singleRowActions invites.js
  Remind: 'Remind',

  // table singleRowActions projects.js
  'Add to favorites': 'Aggiungi ai preferiti',
  View: 'Vedi',
  'Delete Project': 'Elimina Progetto',

  // table singleRowActions raters.js
  'Delete rater': 'Elimina valutatore',

  // table singleRowActions reports.jsAdminister questionnaire
  Open: 'Apri',
  Download: 'Download',

  // table singleRowActions respondents.js
  'Administer questionnaire': 'Somministrare questionario',
  'Invite to questionnaire': 'Invitare al questionario',
  'Add tag': 'Aggiungi tag',

  // table singleRowActions session.js
  'Invite raters who have not yet complete': 'Invita i valutatori che non hanno ancora risposto',
  'Invite who have not yet been invited': 'Invita chi non è stato ancora invitato',
  'Send reminder to raters who have already been invited but have not yet completed':
    'Inviare un promemoria ai valutatori che sono già stati invitati ma non hanno ancora risposto',
  'Invite to specify own rater': 'Invita uno specifico valutatore',
  'Send invitation to self': 'Invia invito al Self',
  'Edit session': 'Modifica sessione',

  // table bulkActions assessments.js
  'Add custom battery': 'Aggiungi batteria personalizzata',
  'Edit assessment': 'Modifica assessment',
  'Delete Assessment(s)': 'Elimina Assessment',

  // table bulkActions groups.js
  'Create a Norm based on the selected group(s)': 'Creare una norma in base al gruppo(i) selezionati',
  'Create an Ideal profile based on the selected group(s)':
    'Creare un Profilo Ideale in base al gruppo/i selezionato/i',
  'Delete selected group(s)': 'Elimina gruppo/i selezionato/i',
  'Generate an open group link': 'Genera un collegamento di gruppo aperto',

  // table bulkActions idealProfiles.js
  'Edit ideal profile': 'Modifica profilo ideale',

  // table bulkActions invites.js
  'Copy invite link to clipboard': "Copiare il link dell'invito negli appunti",
  'Delete invites': 'Elimina inviti',

  // table bulkActions projects.js
  'Edit project details': 'Modifica dettagli progetto',
  'Delete projects(s)': 'Elimina progetto(i)',
  // table bulkActions raters.js
  'Send invite to selected rater(s)': 'Invia invito al valutatore(i) selezionato(i)',
  'Remove selected rater(s) from session': 'Rimuovere il valutatore(i) selezionato(i) dalla sessione',

  // table bulkActions reports.js
  Actions: 'Azioni:',
  'Delete selected report(s)': 'Elimina report selezionato(i)',
  'Download selected report(s)': 'Download report selezionato(i)',
  'View selected report(s)': 'Visualizza report selezionato(i)',

  // table bulkActions respondentGroups.js
  'Remove respondent from selected group(s)': 'Rimuovi il soggetto da gruppo/i selezionato/i',
  // table bulkActions respondents.js
  'Administer assessment': 'Somministra assessment',
  'Administer assessment for': 'Somministra assessment a %{respondentName}',
  'Invite respondent(s) to assessment': "Invita soggetto(i) all'assessment",
  'Request report(s)': 'Richiedi report',
  'Go to respondent details': 'Vai ai dettagli del soggetto',
  'Manage tags for selected respondent(s)': 'Gestisci tag per soggetto(i) selezionato(i) ',
  'Add new group from selection': 'Aggiungi un nuovo gruppo dalla selezione',
  'Add to existing group': 'Aggiungi a gruppo esistente ',
  'Delete selected respondents from group': 'Elimina i soggetti selezionati dal gruppo',
  'Delete selected respondents': 'Elimina i soggetti selezionati',
  'this respondent': 'questo soggetto',
  'selected respondents': 'soggetti selezionati',

  // table bulkActions respondentsTest.js
  'Delete selected result(s)': 'Elimina risultato/i selezionato/i',

  // table bulkActions sessions.js
  'Invite to specify own raters': 'Invita uno specifico valutatore',
  'Delete Session(s)': 'Elimina Sessione(i)',

  // table bulkActions templates.js
  'Go to template details': 'Vai ai dettagli del template',
  'Delete selected template(s)': 'Elimina template selezionato',
  // table bulkActions welcomeText.js
  'Go to welcome text details': 'Vai al testo di benvenuto',
  'Delete selected welcome text(s)': 'Elimina testo(i) di benvenuto selezionato(i)',
  // table entitiesDropdownActions groupDetails.js
  'Edit title': 'Modifica titolo',
  'Duplicate 360 assessment': 'Duplica assessment 360',
  'Delete 360 assessment': 'Elimina assessment 360',
  // table entitiesDropdownActions sessions.js
  'Invite raters who have not yet completed': 'Invita i valutatori che non hanno ancora risposto',

  // table tableActions competencyGroupsDetails.js
  'Review competencies': 'Revisione competenze',

  // table tableActions customGroups.js
  'Add new 360 assessment': 'Aggiungi nuovo assessment 360',

  // table tableActions raters.js
  'Add rater': 'Aggiungi valutatore',
  // table tableActions respondents.js
  'Import respondents': 'Importa soggetti',

  // table tableActions templates.js
  'Add new template': 'Aggiungi nuovo template',
  'Edit template': 'Modifica template',
  'Edit welcome text': 'Modifica Messaggio Benvenuto',
  // QuestionOrder.js
  Default: 'Predefinito',
  Custom: 'Customizzato',
  Random: 'Casuale',
  // tables columns sessions.js
  Invited: 'Invitato',
  'Invite Not Sent': 'Invito Non Spedito',

  'Show expired': 'Mostra scaduti',
  'Sorry we could not find any': 'Spiacenti, non siamo riusciti a trovare %{entityName}',
  'Sorry, we could not find any respondents': 'Non abbiamo trovato nessun soggetto',
  'Sorry, we could not find any reports': 'Non abbiamo trovato nessun report',
  'Sorry, we could not find any results': 'Non abbiamo trovato nessun risultato',
  'Sorry, we could not find any invites': 'Non abbiamo trovato nessun invito',
  'Sorry, we could not find any records': 'Non abbiamo trovato nessun record',
  'Sorry, we could not find any projects': 'Non abbiamo trovato nessu progetto',
  'Sorry, we could not find any tests': 'Non abbiamo trovato nessun test',
  'Sorry, we could not find any groups': 'Non abbiamo trovato nessun gruppo',
  'Sorry, we could not find any notifications': 'Non abbiamo trovato nessuna notifica',
  'Sorry, we could not find any raters': 'Non abbiamo trovato nessun valutatore',
  'Sorry, we could not find any templates': 'Non abbiamo trovato nessun template',
  'Sorry, we could not find any sessions': 'Non abbiamo trovato nessuna sessione',
  'Sorry, we could not find any welcome texts': 'Non abbiamo trovato nessun messaggio di benvenuto',
  'Sorry, we could not find any competency groups': 'Non abbiamo trovato nessun gruppo di comptetenze',
  'Sorry, we could not find any norms': 'Non abbiamo trovato nessuna norma',
  'Sorry, we could not find any response scales': 'Non abbiamo trovanto nessuna scala di risposta',
  'Number of items': 'Numero di items',
  Categories: 'Categorie',
  'User name': 'Nome utente',
  'Update amount': 'Quantità',
  Members: 'Members',
  'Invitation Date': 'Date Invito',
  'Respondent Name': 'Nome Soggetto',
  Report: 'Report',
  'Date submitted': 'Data ricezione',
  Status: 'Status',
  'Not invited': 'Non invitato',
  'Not completed': 'Non completato',
  'Completed Date': 'Data Conclusione',
  'Direct Report': 'Report Immediato',
  Progress: 'Avanzamento',
  'Assessments Done': 'Assessment Eseguito',
  'Add new project': 'Aggiungi nuovo progetto',
  Date: 'Data',
  'Loading entities': 'Caricamento %{entityName}',
  Created: 'Creato il',
  'No options': 'Non ci sono opzioni',
  createOption: 'Crea',
  '**Default': '**Predefinito',

  // Settings Account ChangePassword.js

  'Passwords must match': 'Le password devono corrispondere',
  'Change password': 'Cambiare la password',
  'Confirm new password': 'Conferma nuova password',
  'New password': 'Nuova password',
  Change: 'Cambiare',
  'Pins must match': 'I Pin devono corrispondere',
  'Change pin': 'Cambiare pin',
  'Confirm new pin': 'Conferma nuovo Pin',
  'New pin': 'Nuovo Pin',
  'Total:': 'Totale:',
  'Loading credits history...': 'Caricamento storico dei crediti...',
  'From date': 'Dalla data',
  'To date': 'Alla data',
  Pin: 'Pin',
  'Receive email notifications': 'Ricezione notifiche via e-mail',
  'Complete Status': 'Complete Status',
  'Invite Status': 'Invite Status',
  'Not Invited': 'Non Invitato',
  'Sessions count': 'Sessions count',

  // ImportSessions
  'Import sessions': 'Importa sessioni',
  prepare: 'prepara',
  preview: 'preview',
  import: 'importa',
  'Prepare a csv file yourself or': 'Prepara un file csv per conto tuo oppure',
  'download the sample import file': 'scarica il file import di esempio',
  'Make sure the data order is set out correctly': "Assicurarsi che l'ordine dei dati sia impostato correttamente",
  'Upload the file': 'Upload il file',
  'Drag and drop file here or browse the file': 'Trascinare e rilasciare qui il file o cercare il file',
  'Upload the csv file to import respondents': 'Upload il file csv per importare i soggetti rispondenti',
  'The first line contains field headers': 'La prima riga contiene le intestazioni dei campi',
  'Choose file': 'Scegli il file',
  Next: 'Avanti',
  'respondent(s)': 'soggetto(i)',

  // AccountTabs
  general: 'generale',
  'credit updates': 'aggiornameti crediti',
  'reports stats': 'statistiche report',
  'assessments stats': 'statistiche assessment',

  // ReportUsage
  total: 'Totale: %{total}',
  'Report name': 'Nome report',
  'Usage count': 'Conteggio utilizzo',
  'Assessment name': 'Nome Assessment',
  'Loading requested reports': 'Caricamento report richiesti',
  'Loading used assessments': 'Caricamento Assessment',

  // Toasts & system notifications
  'Tag(s) have been deleted': 'Tag eliminato(i)',
  'Loading items': 'Caricamento item',
  'Saving results': 'Salvataggio risultati',
  'Ideal profile has been created': 'Profilo ideale creato',
  'Copy of 360 survey has been created': 'Una copia del questionario 360 è stata creata',
  'Tag(s) have been updated': 'Tag aggiornato',
  'Welcome text has been saved': 'Messaggio di benvenuto è stato salvato',
  'Welcome text has been updated': 'Messaggio di benvenuto è stato aggiornato',
  'The administered group session has been created': 'La sessione di gruppo è stata creata',
  'Link copied successfully': 'Link copiato con successo',

  'It looks like you have been editing something': 'Sembra che tu abbia modificato qualcosa',
  'Would you like to save your changes before leaving': 'Vuoi salvare le modifiche prima di uscire',
  Warning: 'Avvertimento',

  // Toasts
  groupCreationToast: 'Il gruppo è stato creato',
  respondentsDeletionToast: 'Il soggetto(i) è stato cancellato',
  resultsSuccessToast: 'Risultati per aggiunto con successo',
  surveyDeletionCancelToast: 'Il questionario 360 utilizzato in questo progetto non può essere cancellato',
  creditBalanceUpdateToast: 'Il saldo dei crediti è stato aggiornato',
  correctDataToast: 'Assicurati che tutti i dati siano inseriti correttamente',
  downloadLimitToast: 'Ops! Non puoi scaricare più di 50 report simultaneamente',
  categoryUpdateToast: 'La categoria è stata aggiornata',
  newCategoryToast: 'È stata aggiunta una nuova categoria',
  createdRequestToast: 'Richiesta di report eseguita',
  respondentCreationToast: 'Il soggetto è stato aggiunto',
  respondentUpdateToast: 'Il soggetto è stato aggiornato',
  clipboardCopyToast: 'Copiato con successo negli appunti',
  addedAssessmentToast: 'Assessment aggiunto',
  deletedAssessmentToast: 'Assessment cancellato',
  completedAssessmentToast: '%{respondentName} ha completato %{assessment}',
  importRespondentsErrorToast: 'Qualcosa è andato storto durante il processo di importazione dei soggetti rispondenti',
  importSessionsErrorToast: 'Qualcosa è andato storto durante il processo di importazione delle sessioni',
  competencyTitleUpdateToast: 'Il titolo è stato aggiornato',
  competencyUpdateToast: 'La competenza è stata aggiornata',
  newCompetencyToast: 'La competenza è stata aggiunta',
  questionUpdateToast: 'La domanda è stata aggiornata',
  questionAdditionToast: 'La domanda è stata aggiunta',
  groupLinkErrorToast: 'Qualcosa è andato storto durante la generazione del link di gruppo, riprova per favore',
  reportsDeletionToast: 'Report cancellato(i)',
  resultsSavingErrorToast: 'Errore durante il salvataggio riferito a %{respondentName} %{assessment}',
  reportRequestCreationToast: 'Richiesta di report generata',
  assessmentDataFailToast: "Impossibile caricare i dati dell'assessment",
  scaleUpdatedToast: 'La scala di risposta è stata aggiornata',
  scaleCreatedToast: 'La scala di risposta è stata salvata',
  scaleDeletionToast: 'Scala di risposta cancellata(e)',
  surveyUpdatedToast: 'Il questionario 360 è stato aggiornato',
  normsDeletionToast: 'Norma(e) cancellata(e)',
  'Deletion blocked': 'Eliminazione bloccata',
  groupCreationErrorToast: 'Errore durante la creazione del gruppo',
  groupsDeletionToast: 'Gruppo(i) cancellato(i)',
  reportsGenerationToast: 'Report richiesto(i) con successo',
  invitesDeletionToast: "L'invito o gli inviti sono stati cancellati",
  normCreationToast: 'La norma è stata creata',
  normCreationErrorToast: 'Impossibile creare la norma, riprova per favore',
  projectDeletionToast: 'Il progetto è stato cancellato',
  projectsDeletionToast: 'I progetti sono stati cancellati',
  projectCreationToast: 'Il progetto è stato generato',
  projectUpdateToast: 'Il progetto è stato aggiornato',
  ratersDeletionToast: 'Valutatore(i) è stato cancellato(i)',
  categoryDeletionToast: 'La categoria di competenza è stata cancellata',
  resultsDeletionToast: 'I risultati sono stati cancellati',
  tagsAdditionToast: 'I tag sono stati aggiunti',
  addedToGroupToast: 'Aggiunto con successo al gruppo',
  questionnaireSessionErrorToast: 'Impossibile creare la sessione del questionario, riprova più tardi',
  sessionAdditionToast: 'La sessione è stata aggiunta',
  ratersAdditionToast: 'Sono stati aggiunti i valutatori per la sessione.',
  competencyDeletionToast: 'La competenza è stata cancellata',
  questionDeletionToast: 'La domanda è stata cancellata',
  assessmentsDeletionToast: 'Assessment cancellato(i)',
  profilesDeletionToast: 'Il profilo o i profili ideali sono stati cancellati',
  profileUpdateToast: 'Il profilo ideale è stato aggiornato',
  templatesDeletionToast: 'Il template è stato cancellato(i)',
  templateAdditionToast: 'Il template è stato salvato',
  templateUpdateToast: 'Il template è stato aggiornato',
  welcomeTextsDeletionToast: 'Il testo di benvenuto è stato cancellato',
  batteryAdditionToast: 'La batteria è stata generata',
  batteryUpdateToast: 'La batteria è stata aggiornata',
  batteriesDeletionToast: 'Le batterie sono state eliminate',
  batteryDeletionToast: 'La batteria è stata cancellata',
  passwordUpdateSuccessToast: 'La password è stata aggiornata con successo',
  pinUpdateSuccessToast: 'Il pin è stato aggiornato con successo',

  // Notifications
  'no messages': 'Nessun messaggio',
  'no new messages': 'Nessun nuovo messaggio',
  'tests completed': 'Test completati',
  'reports requested': 'Report richiesti',
  'the previous notification area has been moved': "L'area di notifica precedente è stata spostata",
  here: 'qui',

  // General
  New: 'Nuovo',
  History: 'Storico',
  'Reset assessment deadline': 'Ripristina la scadenza',
  Update: 'aggiornare',
  updateAssessmentInvitationToast: 'Inviti aggiornati',
  updateProjectInvitationToast: 'Inviti aggiornati',
  biodataTooltip:
    'I modelli di dati anagrafici consentono la raccolta personalizzata di dati anagrafici degli intervistati. Crea modelli di dati anagrafici nell area Impostazioni',
  'Default Biodata Template has been updated': 'Il modello di dati anagrafici predefinito è stato aggiornato',
  DEFAULT: 'Predefinito',
  'Bio data template': 'Modelli di dati anagrafici',
  biodataDefaultTemplateLabel: '** Senza modelli di dati anagrafici (Tutte le categorie incluse) **',
  'Add Bio Data template': 'Aggiungi modello di dati biografici',
  'Biodata Configuration': 'Modello di dati biografici',
  'Loading biodata templates': 'Caricamento dei modelli di dati biografici',
  'Add Name': 'Aggiungi Nome',
  'Add description': 'Aggiungi descrizione',
  selectEditCategoryValues: 'Seleziona o modifica i valori ',
  'Add values to category': 'Aggiungi valori alla categoria ',
  'Select values for this category': 'Seleziona valori per questa categoria',
  'Select all': 'Seleziona tutto',
  'Biodata Template has been created': 'Il modello di dati biografici è stato creato',
  'Biodata Template has been updated': 'Il modello di dati biografici è stato aggiornato',
  'Biodata template(s) deleted': 'Il/i modello/i di dati biografici è/sono stato/i eliminato/i',
  biodataDefaultTemplate: 'Impostato come predefinito',
  'Delete selected biodata template(s)': 'Elimina il/i modello/i di dati biografici selezionato/i',
  'Delete biodata templates': 'Elimina modelli di dati biografici',
  'the selected biodata templates?': 'I modelli di dati biografici selezionati?',
  'this biodata template?': 'Questo modello di dati biografici?',
  'Update default biodata template': 'Aggiorna il modello di dati biografici predefinito',
  'Are you sure you want to set this template as default?':
    'Sei sicuro di voler impostare questo modello come predefinito?',
  'Send immediate feedback report to respondent': 'Invia un rapporto di feedback immediato al rispondente',
  'Send immediate feedback info':
    "I rapporti di feedback immediato devono essere inviati via email al rispondente non appena la valutazione è stata completata. Questi rapporti sono personalizzati per la situazione specifica con l'obiettivo di seguire le linee guida professionali per il feedback.Si prega di verificare con il proprio fornitore quali costi si applicheranno.",
  defaultCompleteBioTemplate: '** Tutti i modelli di biodati (Tutte le categorie incluse) **',
  defaultEmptyBioTemplate: '** Modello di biodati vuoto (Nessuna categoria inclusa) **',
  invitesSentToast: 'Invito(i) è stato inviato',
  selectNormForReport: 'Seleziona una norma per il rapporto',
  visibleNormForReport: 'Usa la norma per i rapporti',
  hideNormForReport: 'Nascondi la norma per i rapporti',
  showNormTitle: "Rendi visibile per l'elenco delle richieste di rapporti",
  showConfirmationMessageTitle: 'Sei sicuro di voler rendere visibile',
  hideNormTitle: "Nascondi per l'elenco delle richieste di rapporti",
  hideConfirmationMessageTitle: 'Sei sicuro di voler rimuovere',
  showhideConfirmationMessageDescription1: 'norme selezionate per la generazione dei rapporti?',
  showhideConfirmationMessageDescription2: 'questa norma per la generazione dei rapporti?',
  visible: 'Visibile',
  normsVisibilityToast: 'La visibilità della norma è stata aggiornata',
  updateGroupToast: 'Il gruppo è stato aggiornato',
  updateGroupErrorToast: "Errore durante l'aggiornamento del gruppo",
  'Edit Group Details': 'Modifica dettagli del gruppo',
  'Duplicate Group': 'Duplica gruppo',
};
export default translations;
