import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import styled, { css } from 'styled-components';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ReactTooltip from 'react-tooltip';
import { I18n } from 'react-redux-i18n';
import EditTitle from './components/EditTitle/EditTitle';
import ReviewCompetencies from './components/ReviewCompetencies/ReviewCompetencies';
import { QUESTIONS_ORDER_LIST } from './constants/QuestionsOrder';
import { DEFAULT_SCALE } from './constants/ResponseScale';
import ConfirmationModal from '../reusable/ConfirmationModal';
import round5 from './utils/round5';
import Spinner from '../reusable/Spinner';
import competencyGroupsDetailsSelectors from '../../store/settings/competency-groups-details/selectors';
import QuestionItem from './components/QuestionItem/QuestionItem';
import CustomSelect from '../reusable/Selects/Select';
import GroupItem from './components/GroupItem/GroupItem';
import {
  fetchCompetencyGroupsDetailsAction,
  resetCompetencyGroupsDetails,
  selectCompetencyGroupsDetailsScale,
  sortQuestions,
  removeCompetencies,
  removeAssessment,
  setCustomOrderSequence,
  updateTitle,
  saveDetails,
  setSelectedScale,
  setSelectedOrder,
} from '../../store/settings/competency-groups-details/actions';
import groupsDetailsDropdownActions from '../../table/entitiesDropdownActions/groupsDetails';
import groupsDetailsTableActions from '../../table/tableActions/competencyGroupsDetails';
import * as actionsList from '../../table/actionsList';
import PageWrapper from '../PageWrapper';
import DraggableDotsSvg from '../../assets/icons/draggable-dots.svg';
import dragAdnDropUtils from '../reusable/AccordionDND/utils';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';
import { ReactComponent as LockIcon } from '../../assets/icons/lock.svg';
import createToastNotification from '../../utils/createToastNotification';
import { duplicateCompetency } from '../../store/settings/competency-groups/actions';

const mapQuestions = (question) => {
  if (question.competencyType) {
    return `@${question.itemID}`;
  }
  return question.itemID.toString();
};

const CompetencyGroupsDetails = ({ groupId }) => {
  const dispatch = useDispatch();

  const [isDeletionModalVisible, setDeletionModalStatus] = useState(false);
  const [isDeletionAssessmentModal, setIsDeletionAssessmentModal] = useState(false);
  const [isDuplicateModalVisible, setDuplicateModalStatus] = useState(false);
  const [removedCompetencies, setRemovedCompetencies] = useState([]);
  const [isReviewModal, setIsReviewModal] = useState(false);
  const [isEditTitleModal, setIsEditTitleModal] = useState(false);

  const isLoading = useSelector(competencyGroupsDetailsSelectors.selectIsLoading);
  const summary = useSelector(competencyGroupsDetailsSelectors.selectSummary);
  const groups = useSelector(competencyGroupsDetailsSelectors.selectLocalizedGroups);
  const scales = useSelector(competencyGroupsDetailsSelectors.selectScales);
  const responses = useSelector(competencyGroupsDetailsSelectors.selectResponses);
  const questions = useSelector(competencyGroupsDetailsSelectors.selectQuestions);
  const customOrderSequence = useSelector(competencyGroupsDetailsSelectors.selectCustomOrderSequence);
  const selectedScale = useSelector(competencyGroupsDetailsSelectors.selectSelectedScale);
  const selectedOrder = useSelector(competencyGroupsDetailsSelectors.selectSelectedOrder);
  const initialValues = useSelector(competencyGroupsDetailsSelectors.selectInitialValues);
  const userLanguageID = useSelector((state) => state.i18n.locale);
  const hasDataChanged = useMemo(() => {
    if (!initialValues.selectedOrder || !initialValues.selectedScale) return false;
    const initialQuestions = initialValues.questions.map(mapQuestions).join('');
    const currentQuestions = questions.map(mapQuestions).join('');
    return (
      selectedOrder.value !== initialValues.selectedOrder.value ||
      selectedScale.value !== initialValues.selectedScale.value ||
      initialQuestions !== currentQuestions
    );
  }, [selectedOrder, selectedScale, questions, initialValues]);

  const isAssessmentActive = summary && summary.projectsCount > 0;

  useEffect(() => {
    if (userLanguageID) {
      dispatch(fetchCompetencyGroupsDetailsAction(groupId, userLanguageID));
    }
    return () => {
      dispatch(resetCompetencyGroupsDetails());
    };
  }, [userLanguageID, groupId]);

  useEffect(() => {
    if (selectedScale !== null) {
      dispatch(selectCompetencyGroupsDetailsScale(selectedScale, userLanguageID));
    }
  }, [userLanguageID, selectedScale]);

  useEffect(() => {
    if (selectedOrder !== null) {
      dispatch(sortQuestions(selectedOrder));
    }
  }, [selectedOrder]);

  const actions = {
    [actionsList.REVIEW_COMPETENCY_GROUPS_DETAILS]: () => setIsReviewModal(true),
    [actionsList.SAVE_COMPETENCY_GROUPS_DETAILS]: () => {
      dispatch(
        saveDetails({
          competencyGroupID: Number(groupId),
          userID: summary.userID,
          name: summary.name,
          responseScaleID: selectedScale.value === DEFAULT_SCALE.value ? null : selectedScale.value,
          orderType: selectedOrder.value,
          customOrderSequence,
        }),
      );
    },
    [actionsList.EDIT_TITLE_COMPETENCY_GROUPS_DETAILS]: () => setIsEditTitleModal(true),
    [actionsList.DUPLICATE_COMPETENCY_GROUPS_DETAILS]: () => setDuplicateModalStatus(true),
    [actionsList.DELETE_COMPETENCY_GROUPS_DETAILS]: () => setIsDeletionAssessmentModal(true),
  };

  const memoizedRemoveCancel = useCallback(() => {
    setRemovedCompetencies([]);
    setDeletionModalStatus(false);
  }, []);

  const memoizedRemoveCompetencies = useCallback(
    (ids) => {
      setRemovedCompetencies(ids);
      setDeletionModalStatus(true);
    },
    [setRemovedCompetencies, setDeletionModalStatus, isAssessmentActive],
  );

  const memoizedEditTitleConfirm = useCallback(
    (newName) => {
      dispatch(updateTitle(newName));
      setIsEditTitleModal(false);
    },
    [groupId, setIsEditTitleModal],
  );

  const memoizedEditTitleClose = useCallback(() => {
    setIsEditTitleModal(false);
  }, [setIsEditTitleModal]);

  const memoizedRemoveAssessmentConfirm = useCallback(() => {
    dispatch(removeAssessment(groupId));
    setIsDeletionAssessmentModal(false);
  }, [groupId, setIsReviewModal]);

  const memoizedRemoveAssessmentCancel = useCallback(() => {
    setIsDeletionAssessmentModal(false);
  }, [setIsReviewModal]);

  const memoizedConfirmReviewCompetencies = useCallback(() => {
    setIsReviewModal(false);
    dispatch(resetCompetencyGroupsDetails());
    dispatch(fetchCompetencyGroupsDetailsAction(groupId, userLanguageID));
    createToastNotification({ message: I18n.t('surveyUpdatedToast') });
  }, [groupId, userLanguageID, setIsReviewModal]);

  const memoizedRemoveConfirm = useCallback(() => {
    dispatch(
      removeCompetencies(groupId, removedCompetencies, () => {
        setRemovedCompetencies([]);
        memoizedConfirmReviewCompetencies();
      }),
    );
    setDeletionModalStatus(false);
  }, [groupId, setDeletionModalStatus, removedCompetencies, setRemovedCompetencies]);

  const memoizedCancelReviewCompetencies = useCallback(() => {
    setIsReviewModal(false);
  }, [setIsReviewModal]);

  const memoizedNavigateToGroupDetails = useCallback((competencyGroupID) => {
    dispatch(push(`/settings/360-assessments/${competencyGroupID}`));
  }, []);

  const onAssessmentsDuplicate = () => {
    const newName = `Copy of ${summary.name}`;
    dispatch(
      duplicateCompetency(summary.competencyGroupID, newName, (err, newCompetencyId) => {
        if (err) setDuplicateModalStatus(false);
        else memoizedNavigateToGroupDetails(newCompetencyId);
        setDuplicateModalStatus(false);
      }),
    );
  };

  const dropdownActions = useMemo(() => {
    return groupsDetailsDropdownActions.map((item) => ({
      ...item,
      handler: () => actions[item.name](),
      isDisabled: item.name === actionsList.DELETE_COMPETENCY_GROUPS_DETAILS && isAssessmentActive,
    }));
  }, [isAssessmentActive]);

  const tableActions = useMemo(() => {
    return groupsDetailsTableActions.map((item) => ({
      ...item,
      handler: actions[item.name],
      isDisabled: (() => {
        if (item.name === actionsList.SAVE_COMPETENCY_GROUPS_DETAILS) return isAssessmentActive || !hasDataChanged;
        return isAssessmentActive;
      })(),
    }));
  }, [groupId, customOrderSequence, summary, selectedOrder, selectedScale, isAssessmentActive, hasDataChanged]);

  const scalesSelectorData = useMemo(() => {
    const data = [DEFAULT_SCALE];
    data.push(...scales.map((item) => ({ value: item.responseScaleID, label: item.name })));
    return data;
  }, [scales]);

  const isDragAndDropDisabled = !selectedOrder || !selectedOrder.value || selectedOrder.value !== 2;

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    const data = questions.map(mapQuestions);
    const newOrder = dragAdnDropUtils.reorder(data, source.index, destination.index).join();
    if (!isDragAndDropDisabled) {
      dispatch(setCustomOrderSequence(newOrder));
      dispatch(sortQuestions(selectedOrder, true));
    }
  };

  if (isLoading || !userLanguageID) {
    return <Spinner isLoading text={I18n.t('Loading Competency Details')} full />;
  }

  const getTitle = () => {
    const Node = (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{summary.name}</span>
        <Lock data-tip data-for="warning" />
        <ReactTooltip id="warning">
          This 360 survey is used by one or more project, therefore can not be edited or deleted.
        </ReactTooltip>
      </div>
    );
    const def = summary.name;
    return isAssessmentActive ? Node : def;
  };
  return (
    <PageWrapper
      title={summary ? getTitle() : ''}
      backButtonHandler={() => dispatch(push(`/settings/360-assessments`))}
      buttons={tableActions}
      dropdownActions={dropdownActions}
    >
      <HeaderWrapper>
        <HeaderItem>
          <Label>{I18n.t('Structure')}</Label>
          <Value>
            <div>
              {I18n.t('Categories:')} {summary ? summary.categoriesCount : 0}
            </div>
            <div>
              {I18n.t('Competencies:')} {summary ? summary.competenciesCount : 0}
            </div>
          </Value>
        </HeaderItem>
        <HeaderItem>
          <Label>{I18n.t('Numbers of items')}</Label>
          <Value>
            <div>
              {I18n.t('Multiple choice:')} {summary ? summary.itemsCount : 0}
            </div>
            <div>
              {I18n.t('Free text:')} {summary ? summary.freeTextItemsCount : 0}
            </div>
          </Value>
        </HeaderItem>
        <HeaderItem>
          <Label>{I18n.t('Estimated time')}</Label>
          <Value>
            <div>
              {summary ? round5((summary.itemsCount * 30 + summary.freeTextItemsCount * 180) / 60) : 0}{' '}
              {I18n.t('minutes')}
            </div>
          </Value>
        </HeaderItem>
        <HeaderItem>
          <Label>{I18n.t('Available languages')}</Label>
          <Value>
            <div>
              {summary && Boolean(summary.languages.length) ? (
                summary.languages.join(', ')
              ) : (
                <ErrorBlock>
                  <StyledErrorIcon />
                  <span>{I18n.t('Make sure at least one language is fully supported in assessment')}</span>
                </ErrorBlock>
              )}
            </div>
          </Value>
        </HeaderItem>
      </HeaderWrapper>
      <ContentWrapper>
        <ContentScrollWrapper>
          <LeftColumn>
            <TitleWrapper>
              <Title>{I18n.t('Competencies')}</Title>
            </TitleWrapper>
            {groups.map((item) => (
              <ItemWrapper key={item.competencyID}>
                <GroupItem
                  group={item}
                  onRemoveCompetencies={memoizedRemoveCompetencies}
                  isAssessmentActive={isAssessmentActive}
                />
              </ItemWrapper>
            ))}
          </LeftColumn>
          <RightColumn>
            <TitleWrapper>
              <Title>{I18n.t('Questions')}</Title>
              <QuestionsDropDownWrapper>
                <CustomSelect
                  options={scalesSelectorData}
                  selectProps={{
                    value: selectedScale,
                    onChange: (data) => dispatch(setSelectedScale(data)),
                  }}
                  placeholder={I18n.t('Response scale')}
                  isDisabled={isAssessmentActive}
                />
                <SortingDropdown
                  options={QUESTIONS_ORDER_LIST}
                  selectProps={{
                    value: selectedOrder,
                    onChange: (data) => dispatch(setSelectedOrder(data)),
                  }}
                  placeholder={I18n.t('Sorting')}
                  isDisabled={isAssessmentActive}
                />
              </QuestionsDropDownWrapper>
            </TitleWrapper>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="questions" isDropDisabled={isDragAndDropDisabled}>
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {questions.map((item, index) => (
                      <Draggable
                        draggableId={`${item.itemID}`}
                        index={index}
                        key={`${item.itemID}`}
                        isDragDisabled={isDragAndDropDisabled}
                      >
                        {(provided, snapshot) => (
                          <ItemWrapper
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            isDragging={snapshot.isDragging}
                            draggable={!isDragAndDropDisabled}
                            key={item.itemID}
                          >
                            <QuestionItem
                              question={item}
                              number={index + 1}
                              count={questions.length}
                              responses={responses}
                            />
                          </ItemWrapper>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </RightColumn>
        </ContentScrollWrapper>
      </ContentWrapper>

      {isEditTitleModal && (
        <EditTitle
          groupId={groupId}
          name={summary.name}
          onConfirm={memoizedEditTitleConfirm}
          onClose={memoizedEditTitleClose}
        />
      )}

      {isReviewModal && (
        <ReviewCompetencies
          groupId={groupId}
          onConfirm={memoizedConfirmReviewCompetencies}
          onCancel={memoizedCancelReviewCompetencies}
        />
      )}

      <ConfirmationModal
        title={I18n.t('Competency deletion')}
        onClose={memoizedRemoveCancel}
        isVisible={isDeletionModalVisible}
        description={`${I18n.t('Are you sure you want to delete')} ${
          removedCompetencies && removedCompetencies.length > 1
            ? I18n.t('this competencies?')
            : I18n.t('this competency?')
        }`}
        onConfirm={memoizedRemoveConfirm}
        caution
      />

      <ConfirmationModal
        title={I18n.t('Assessment deletion')}
        onClose={memoizedRemoveAssessmentCancel}
        isVisible={isDeletionAssessmentModal}
        description={I18n.t('Are you sure you want to deleted selected the assessment?')}
        onConfirm={memoizedRemoveAssessmentConfirm}
        caution
      />
      <ConfirmationModal
        isVisible={isDuplicateModalVisible}
        title="Duplicate 360 survey"
        description="Are you sure you want to duplicate this 360 survey?"
        onClose={() => setDuplicateModalStatus(false)}
        onConfirm={onAssessmentsDuplicate}
        caution
      />
    </PageWrapper>
  );
};

const Lock = styled(LockIcon)`
  padding: 1rem;
  min-width: 3.5rem;
  min-height: 3.5rem;
  width: 3.5rem;
  height: 3.5rem;
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 20px 0;
  border-bottom: 1px solid #e0e0e0;
`;

const HeaderItem = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 160px;
  margin-right: 24px;
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => props.theme.colors.darkBlue};
`;

const Value = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin-top: 8px;
  color: ${(props) => props.theme.colors.darkBlue};

  div + div {
    margin-top: 5px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  position: relative;
  height: calc(100% - 160px);

  &:before {
    content: '';
    position: absolute;
    right: 50%;
    top: 0;
    height: 100%;
    border-right: 1px solid #e0e0e0;
  }
`;

const ContentScrollWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding-top: 5px;

  color: ${(props) => props.theme.colors.darkBlue};
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 27px 30px 0 0;

  ${TitleWrapper} {
    margin-bottom: 22px;
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 27px 0 0 30px;
  width: 50%;

  ${TitleWrapper} {
    margin-bottom: 14px;
    min-height: 35px;
  }
`;

const ItemWrapper = styled.div`
  margin-bottom: 20px;
  
  &:before {
    content: '';
    position: absolute;
    left: -15px;
    top: 14px;
    width: 9px;
    height: 28px;
    background-image: url("${DraggableDotsSvg}");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: none;
  }

  ${(props) => props.draggable && `background-color: ${props.theme.colors.white};`}
  ${(props) =>
    props.isDragging &&
    css`
      background-color: ${props.theme.colors.lightBlue1};
      &:before {
        display: block;
      }

      & > div {
        border: 1px solid ${props.theme.colors.lightBlue2};
      }
    `}
`;

const QuestionsDropDownWrapper = styled.div`
  display: flex;
`;

const SortingDropdown = styled(CustomSelect)`
  width: 15rem;
  min-width: 15rem;
  margin-left: 1rem;
`;

const ErrorBlock = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.fontSizes.small};
  color: ${(props) => props.theme.colors.red};
`;

const StyledErrorIcon = styled(ErrorIcon)`
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  min-height: 3rem;
  margin-right: 1rem;
`;

export default CompetencyGroupsDetails;
