import { I18n } from 'react-redux-i18n';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/sidebar-edit-project.svg';

import * as actions from '../actionsList';

const idealProfilesBulkActions = [
  {
    name: actions.EDIT_IDEAL_PROFILE,
    label: I18n.t('Edit ideal profile'),
    Icon: EditIcon,
    isDisabled: (idealProfiles) => idealProfiles.length > 1,
  },
  {
    name: actions.DELETE_IDEAL_PROFILES,
    label: I18n.t('Delete ideal profiles'),
    Icon: DeleteIcon,
  },
];

export default idealProfilesBulkActions;
