import { format } from 'date-fns';
import isChineseEnv from '../../utils/isChineseEnv';

const columns = [
  {
    Header: 'PersonName',
    accessor: (data) => data.model.firstName,
    name: 'firstName',
  },
  ...(isChineseEnv
    ? []
    : [
        {
          Header: 'Family name',
          accessor: (data) => data.model.familyName,
          name: 'familyName',
        },
      ]),
  {
    Header: 'DOB',
    accessor: (data) => data.model.dob && format(new Date(data.model.dob), 'dd/M/y'),
    name: 'dob',
  },
  {
    Header: 'Sex',
    accessor: (data) => data.model.sex,
    name: 'sex',
  },
  {
    Header: 'Title',
    accessor: (data) => data.model.title,
    name: 'title',
  },
  {
    Header: 'Email',
    accessor: (data) => data.model.email,
    name: 'email',
  },
  {
    Header: 'Company',
    accessor: (data) => data.model.company,
    name: 'company',
  },
];
export default columns;
