import React from 'react';
import { array, number } from 'prop-types';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';

import Avatar from './Avatar';
import isChineseEnv from '../../utils/isChineseEnv';

const AVATAR_OFFSET = 16;

// TODO: Move to utils
const maybePluralize = (count, noun, suffix = 's') => `${count} ${I18n.t(`${noun}${count !== 1 ? suffix : ''}`)}`;

const Members = ({ members = [], projectId }) => {
  const items = members.slice(0, 6);

  return (
    <Container>
      <MembersNum>{I18n.t(maybePluralize(members.length, 'member'))}</MembersNum>
      {!isChineseEnv && Boolean(members.length) && (
        <AvatarsWrapper>
          {items.map((item, index) => (
            <StyledAvatar
              key={`${projectId}_${item.respondentId || item.respondentID}`}
              firstName={item.firstName}
              familyName={item.familyName}
              left={index === 0 ? 0 : index * AVATAR_OFFSET}
              zIndex={index}
            />
          ))}
        </AvatarsWrapper>
      )}
    </Container>
  );
};

Members.propTypes = {
  members: array.isRequired,
  projectId: number,
};

Members.defaultProps = {
  projectId: null,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MembersNum = styled.span`
  font-size: 1rem;
  font-weight: 500;
`;

const AvatarsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  height: 2.8rem;
`;

const StyledAvatar = styled(Avatar)`
  position: absolute;
  top: 0;
  left: ${(props) => `${props.left}px`};
  z-index: ${(props) => `${props.zIndex}`};
  border: 1px solid ${(props) => props.theme.colors.lightBlue1};
`;

export default Members;
