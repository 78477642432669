import { createSelector } from 'reselect';

const getProjectsTotalCount = (state) => state.projects.totalCount;
const getProjectsAssessmentCount = (state) => state.projects.totalAssessment;
const getProjects360Count = (state) => state.projects.total360;

export const selectProjectsTabsCounts = createSelector(
  [getProjectsTotalCount, getProjectsAssessmentCount, getProjects360Count],
  (totalCount, totalAssessment, total360) => ({
    totalCount,
    totalAssessment,
    total360,
  }),
);
