import styled from 'styled-components';

const Badge = styled.span`
  padding: 0 12px;
  height: 20px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  font-weight: 600;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-bottom-width: 10px;
    border-top-width: 10px;
    border-left: 5px solid ${(props) => props.theme.colors.grey1};
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-bottom-width: 10px;
    border-top-width: 10px;
    border-right: 5px solid ${(props) => props.theme.colors.grey1};
  }
`;

export default Badge;
