import { createAction } from 'redux-actions';
import { I18n } from 'react-redux-i18n';
import apiInstance2 from '../../../services/apiService';
import createToastNotification from '../../../utils/createToastNotification';

export const fetchWelcomeTextsStart = createAction('FETCH_WELCOME_TEXTS_START');
export const fetchWelcomeTextsSuccess = createAction('FETCH_WELCOME_TEXTS_SUCCESS', (welcomeTexts) => ({
  welcomeTexts,
}));
export const fetchWelcomeTextsFailure = createAction('FETCH_WELCOME_TEXTS_FAILURE');

export const deleteWelcomeTextsStart = createAction('DELETE_WELCOME_TEXTS_START');
export const deleteWelcomeTextsSuccess = createAction('DELETE_WELCOME_TEXTS_SUCCESS', (welcomeTextsIds) => ({
  welcomeTextsIds,
}));
export const deleteWelcomeTextsFailure = createAction('DELETE_WELCOME_TEXTS_FAILURE');

export const fetchWelcomeTextsVariablesStart = createAction('FETCH_WELCOME_TEXTS_VARIABLES_START');
export const fetchWelcomeTextsVariablesSuccess = createAction('FETCH_WELCOME_TEXTS_VARIABLES_SUCCESS', (variables) => ({
  variables,
}));
export const fetchWelcomeTextsVariablesFailure = createAction('FETCH_WELCOME_TEXTS_VARIABLES_FAILURE');

export const setActiveWelcomeText = createAction('SET_ACTIVE_WELCOME_TEXT', (welcomeText) => ({ welcomeText }));

export const fetchWelcomeTextByIdStart = createAction('FETCH_WELCOME_TEXT_BY_ID_START');
export const fetchWelcomeTextByIdSuccess = createAction('FETCH_WELCOME_TEXT_BY_ID_SUCCESS');
export const fetchWelcomeTextByIdFailure = createAction('FETCH_WELCOME_TEXT_BY_ID_FAILURE');

export const createWelcomeTextStart = createAction('CREATE_WELCOME_TEXT_START');
export const createWelcomeTextSuccess = createAction('CREATE_WELCOME_TEXT_SUCCESS');
export const createWelcomeTextFailure = createAction('CREATE_WELCOME_TEXT_FAILURE');

export const editWelcomeTextStart = createAction('EDIT_WELCOME_TEXT_START');
export const editWelcomeTextSuccess = createAction('EDIT_WELCOME_TEXT_SUCCESS');
export const editWelcomeTextFailure = createAction('EDIT_WELCOME_TEXT_FAILURE');

export const fetchWelcomeTexts = (cb) => async (dispatch) => {
  dispatch(fetchWelcomeTextsStart());
  try {
    const response = await apiInstance2.get(`/api/v2/templates/welcome`);
    if (response.status === 200) {
      dispatch(fetchWelcomeTextsSuccess(response.data));
      if (cb) cb();
    }
  } catch (err) {
    dispatch(fetchWelcomeTextsFailure(err.message));
    if (cb) cb(err);
  }
};

export const deleteWelcomeTexts = (welcomeTextsIds, cb) => async (dispatch) => {
  dispatch(deleteWelcomeTextsStart);
  try {
    const response = await apiInstance2.delete(`/api/v2/templates`, { data: welcomeTextsIds });
    if (response.status === 200) {
      dispatch(deleteWelcomeTextsSuccess(welcomeTextsIds));
      createToastNotification({ message: I18n.t('welcomeTextsDeletionToast') });
      if (cb) cb();
    }
  } catch (err) {
    dispatch(deleteWelcomeTextsFailure(err.message));
    if (cb) cb(err);
  }
};

export const fetchWelcomeTextsVariables = () => async (dispatch) => {
  dispatch(fetchWelcomeTextsVariablesStart());
  try {
    const response = await apiInstance2.get(`/api/v2/templates/welcome/variables`);
    if (response.status === 200) {
      dispatch(fetchWelcomeTextsVariablesSuccess(response.data));
    }
  } catch (err) {
    dispatch(fetchWelcomeTextsVariablesFailure(err.message));
  }
};

export const fetchWelcomeTextById = (welcomeTextId, cb) => async (dispatch) => {
  dispatch(fetchWelcomeTextByIdStart());
  try {
    const response = await apiInstance2.get(`/api/v2/templates/welcome/${welcomeTextId}`);
    if (response.status === 200) {
      dispatch(fetchWelcomeTextByIdSuccess());
      dispatch(setActiveWelcomeText(response.data));
      if (cb) cb();
    }
  } catch (err) {
    dispatch(fetchWelcomeTextByIdFailure(err.message));
    if (cb) cb(err.message);
  }
};

export const createWelcomeText = (data, cb) => async (dispatch) => {
  dispatch(createWelcomeTextStart());
  try {
    const response = await apiInstance2.post(`/api/v2/templates/welcome`, data);
    if (response.status === 200) {
      dispatch(createWelcomeTextSuccess());
      createToastNotification({ message: I18n.t('Welcome text has been saved') });
      if (cb) cb();
    }
  } catch (err) {
    dispatch(createWelcomeTextFailure(err.message));
    if (cb) cb(err.message);
  }
};

export const editWelcomeText = (data, cb) => async (dispatch) => {
  dispatch(editWelcomeTextStart());
  try {
    const response = await apiInstance2.patch(`/api/v2/templates/welcome`, data);
    if (response.status === 200) {
      dispatch(editWelcomeTextSuccess());
      createToastNotification({ message: I18n.t('Welcome text has been updated') });
      if (cb) cb();
    }
  } catch (err) {
    dispatch(editWelcomeTextFailure(err.message));
    if (cb) cb(err.message);
  }
};
