const getRatersByStatus = (raters) => {
  return {
    completed: raters.filter((item) => item.completeStatus === 1),
    notCompleted: raters.filter((item) => item.completeStatus === 0),
    invited: raters.filter((item) => item.inviteStatus === 1),
    notInvited: raters.filter((item) => item.inviteStatus === 0),
    invitedNotCompleted: raters.filter((item) => item.inviteStatus === 1 && item.completeStatus === 0),
  };
};

export default getRatersByStatus;
