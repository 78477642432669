import React from 'react';
import styled, { css } from 'styled-components';
import { format } from 'date-fns';
import { I18n } from 'react-redux-i18n';
import isChineseEnv from '../../utils/isChineseEnv';
// import inviteTypes from '../../utils/inviteTypes';
// import ActionsDropdown from '../../components/reusable/ActionsDropdown';

const selfStatuses = {
  invited: {
    title: I18n.t('Invited'),
    background: '#DFF6FF',
    color: '#008AC0',
  },
  completed: {
    title: I18n.t('Completed'),
    background: '#ECF6EF',
    color: '#27AE60',
  },
  notSent: {
    title: I18n.t('Invite Not Sent'),
    background: '#F9F9F9',
    color: '#5B5B5B',
  },
};

const StyledStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
  height: 14px;
  width: 110px;
  font-weight: 600;
  font-size: 8px;
  text-transform: uppercase;
  border-radius: 4px;
`;

const StyledStatusTitle = styled.div`
  margin-bottom: 5px;
`;

const SelfCell = ({ row }) => {
  const { firstName, familyName, completeStatus, inviteStatus } = row.original.self;

  // eslint-disable-next-line
  const status = selfStatuses[completeStatus ? 'completed' : inviteStatus ? 'invited' : 'notSent'];

  return (
    <>
      <StyledStatusTitle>{isChineseEnv ? `${familyName}${firstName}` : `${firstName} ${familyName}`}</StyledStatusTitle>
      <StyledStatus style={{ color: status.color, backgroundColor: status.background }}>
        {I18n.t(status.title)}
      </StyledStatus>
    </>
  );
};
const DateCell = ({ row }) => {
  return <span>{format(new Date(row.original.creationDate), 'dd/M/y')}</span>;
};

const RatersProgressCell = ({ row, raterType }) => {
  const raters = row.original.raters.filter((r) => r.role === raterType);
  const invited = raters.filter((r) => r.inviteStatus === 1);
  const completed = raters.filter((r) => r.completeStatus === 1);

  const isZeroRaters = raters.length === 0;
  const isAllCompleted = completed.length === raters.length;
  const isAllInvited = invited.length === raters.length;

  return (
    <>
      <ProgressCellItem isZeroRaters={isZeroRaters} isAllInvited={isAllInvited}>
        {I18n.t('Invited')} <span>{`${invited.length}/${raters.length}`}</span>
      </ProgressCellItem>
      <ProgressCellItem isZeroRaters={isZeroRaters} isAllCompleted={isAllCompleted}>
        {I18n.t('Completed')} <span>{`${completed.length}/${raters.length}`}</span>
      </ProgressCellItem>
    </>
  );
};

const ProgressCell = ({ row }) => {
  let color = 0;
  const colorsMap = ['#FF574C', '#F3A055', '#FFDF6D', '#56CCF2', '#27AEA6', '#6FCF97'];
  const { progress } = row.original;

  switch (true) {
    case progress === 1:
      color = 5;
      break;
    case progress > 0.75:
      color = 4;
      break;
    case progress > 0.5:
      color = 3;
      break;
    case progress > 0.25:
      color = 2;
      break;
    case progress > 0:
      color = 1;
      break;
    default:
      color = 0;
  }

  return (
    <StyledCell>
      <StyledCircle color={colorsMap[color]} />
      <span>{`${parseInt(progress, 10)} %`}</span>
    </StyledCell>
  );
};

const columns = (activeProjectInView) => [
  {
    Header: I18n.t('Self'),
    accessor: (data) =>
      isChineseEnv ? `${data.self.familyName}${data.self.firstName}` : `${data.self.firstName} ${data.self.familyName}`,
    Cell: (props) => <SelfCell {...props} />,
    name: 'self',
    type: 'text',
    filteringName: 'Self',
  },
  {
    Header:
      activeProjectInView && activeProjectInView.managerAlias ? activeProjectInView.managerAlias : I18n.t('Manager'),
    accessor: (data) => data.raters.filter((r) => r.role === 1),
    Cell: (props) => <RatersProgressCell {...props} raterType={1} />,
    name: 'manager',
    disableSortBy: true,
    disableFilterBy: true,
    id: 'manager',
  },
  {
    Header: activeProjectInView && activeProjectInView.peerAlias ? activeProjectInView.peerAlias : I18n.t('Peer'),
    accessor: (data) => data.raters.filter((r) => r.role === 2),
    Cell: (props) => <RatersProgressCell {...props} raterType={2} />,
    name: 'peer',
    disableSortBy: true,
    disableFilterBy: true,
    id: 'peer',
  },
  {
    Header:
      activeProjectInView && activeProjectInView.directReportAlias
        ? activeProjectInView.directReportAlias
        : I18n.t('Direct Report'),
    accessor: (data) => data.raters.filter((r) => r.role === 3),
    Cell: (props) => <RatersProgressCell {...props} raterType={3} />,
    name: 'directReport',
    disableSortBy: true,
    disableFilterBy: true,
    id: 'directReport',
  },
  {
    Header: activeProjectInView && activeProjectInView.otherAlias ? activeProjectInView.otherAlias : I18n.t('Other'),
    accessor: (data) => data.raters.filter((r) => r.role === 4),
    Cell: (props) => <RatersProgressCell {...props} raterType={4} />,
    name: 'other',
    disableSortBy: true,
    disableFilterBy: true,
    id: 'other',
  },
  {
    Header: I18n.t('Progress'),
    accessor: (data) => `${data.progress}%`,
    Cell: (props) => <ProgressCell {...props} />,
    type: 'number',
    name: 'progress',
    filteringName: 'Progress',
  },
  {
    Header: I18n.t('Creation Date'),
    accessor: (data) => new Date(data.creationDate),
    Cell: (props) => <DateCell {...props} />,
    name: 'creationDate',
    type: 'date',
    sortType: 'datetime',
    filteringName: 'Created',
    width: 120,
  },
  // {
  //   Header: I18n.t(''),
  //   name: 'actions',
  //   type: 'actions',
  //   id: 'actions',
  //   disableSortBy: true,
  //   Cell: ({ row }) => (
  //     <ActionsDropdown
  //       actions={rowActions.map((item) => ({
  //         ...item,
  //         handler: () => item.handler(row.original),
  //         isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(row.original) : item.isDisabled,
  //       }))}
  //       clickHandler={() => setSelectedSessions(row.original)}
  //     />
  //   ),
  //   width: 50,
  // },
];

const ProgressCellItem = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #295368;
  
  span {
    color: #FF574C;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding-left: 8px;
    letter-spacing: 0.1em;
    
    ${(props) =>
      props.isAllInvited &&
      css`
        color: #27ae60;
      `}
    
    ${(props) =>
      props.isAllCompleted &&
      css`
        color: #27ae60;
      `}
    
    ${(props) =>
      props.isZeroRaters &&
      css`
        color: #cdcdcd;
      `}
  }
`;

const StyledCell = styled.div`
  display: flex;
`;

const StyledCircle = styled.i`
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-right: 8px;
`;

export default columns;
