import React from 'react';
import { push } from 'redux-first-history';
import { FieldArray } from 'formik';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import PageWrapper from '../../PageWrapper';
import Quantity from '../../reusable/Quantity';
import LanguagesSelect from '../../reusable/Selects/LanguagesSelect';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { selectAvailableLanguages } from '../../../store/user/selectors';
import FormikInput from '../../reusable/FormComponents/FormikInput';
import Checkbox from '../../reusable/FormComponents/Checkbox';

const AddScaleForm = ({
  handleChange,
  values,
  errors,
  touched,
  handleSubmit,
  setFieldValue,
  setValues,
  responseScaleId,
}) => {
  const dispatch = useDispatch();
  const languages = useSelector(selectAvailableLanguages);

  const generateResponses = (quantity) => {
    return Array(quantity)
      .fill(null)
      .map(() => ({
        title: '',
      }));
  };

  const updateResponsesQuantity = (quantity) => {
    const prevLength = values.responsesArray[0].responses.length;

    if (quantity > prevLength) {
      values.responsesArray.forEach((responsesObject) => {
        responsesObject.responses.push({ title: '' });
      });
    } else if (quantity < prevLength) {
      values.responsesArray.forEach((responsesObject) => {
        responsesObject.responses.pop();
      });
    }
    setValues(values);
  };

  const availableLanguages = languages.filter(
    (language) => values.responsesArray.map((response) => response.lang.value).indexOf(language.value) === -1,
  );

  return (
    <PageWrapper
      title={responseScaleId ? I18n.t('Edit response scale') : I18n.t('Add response scale')}
      backButtonHandler={() => dispatch(push(`/settings/response-scales`))}
      buttons={[
        {
          label: I18n.t('Save'),
          handler: handleSubmit,
        },
      ]}
    >
      <ContentWrapper>
        <ResponseName
          inputName={I18n.t('Title')}
          name="title"
          id="title"
          value={values.title}
          onChange={handleChange}
          errors={errors}
          touched={touched}
        />
        <CheckboxHolder>
          <Checkbox
            isChecked={values.reverseOrder}
            clickHandler={() => setFieldValue('reverseOrder', !values.reverseOrder)}
          />
          <span>{I18n.t('Score right to left')}</span>
        </CheckboxHolder>
        <QuantityWrapper>
          <Label>{I18n.t('Responses')}</Label>
          <Quantity
            onChange={updateResponsesQuantity}
            min={2}
            defaultQuantity={values.responsesArray[0].responses.length}
            label={I18n.t('Quantity')}
          />
        </QuantityWrapper>
        <ScrollWrapper>
          <FieldArray
            name="responsesArray"
            render={(arrayHelpers) =>
              values.responsesArray.map((responses, index) => {
                const isLast = index === values.responsesArray.length - 1;
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Languages key={index}>
                    <AddLanguageWrapper>
                      <LanguagesSelect
                        name={`responsesArray.${index}.lang`}
                        value={values.responsesArray[index].lang}
                        languages={availableLanguages}
                        errors={errors}
                        touched={touched}
                        onChange={(lang) => {
                          setFieldValue(`responsesArray.${index}.lang`, lang);
                        }}
                      />
                      {values.responsesArray.length > 1 && (
                        <LanguageCloseButton onClick={() => arrayHelpers.remove(index)}>
                          <CloseIcon />
                        </LanguageCloseButton>
                      )}
                    </AddLanguageWrapper>
                    <FieldArray
                      name="responses"
                      render={() => {
                        return values.responsesArray[index].responses.map((response, responseIndex) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <ResponseTitleWrapper key={responseIndex}>
                            <ResponseTitle
                              inputName={I18n.t('indexedResponse', { index: responseIndex + 1 })}
                              name={`responsesArray.${index}.responses.${responseIndex}.title`}
                              id={`responsesArray.${index}.responses.${responseIndex}.title`}
                              value={values.responsesArray[index].responses[responseIndex].title}
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </ResponseTitleWrapper>
                        ));
                      }}
                    />
                    {isLast && (
                      <AddResponse
                        onClick={() =>
                          arrayHelpers.push({
                            lang: { value: '', code: '', label: '' },
                            responses: generateResponses(values.responsesArray[0].responses.length),
                          })
                        }
                      >
                        {`+ ${I18n.t('Add another language')}`}
                      </AddResponse>
                    )}
                  </Languages>
                );
              })
            }
          />
        </ScrollWrapper>
      </ContentWrapper>
    </PageWrapper>
  );
};

const QuantityWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ScrollWrapper = styled.div`
  overflow: auto;
  height: calc(100vh - 240px);
`;

const ContentWrapper = styled.div`
  width: 100%;
  margin-top: 35px;
  max-width: 540px;
`;

const Label = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-right: 10px;
  color: ${(props) => props.theme.colors.darkBlue2};
`;

const Languages = styled.div`
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 7px;
  display: ${(props) => (props.isHidden ? 'none' : 'block')};

  & + & {
    margin-top: 15px;
  }
`;

const ResponseName = styled(FormikInput)`
  margin-bottom: 20px;
`;

const AddResponse = styled.span`
  display: inline-block;
  color: ${(props) => props.theme.colors.lightBlue};
  font-size: 12px;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
`;

const ResponseTitle = styled(FormikInput)`
  width: 100%;
  margin-right: 10px;
`;

const ResponseTitleWrapper = styled.div`
  display: ${(props) => (props.isHidden ? 'none' : 'flex')};
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const AddLanguageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const LanguageCloseButton = styled.div`
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.lightBlue1};
  margin-left: 20px;

  svg {
    width: 1rem;
    height: 1rem;
    path {
      fill: ${(props) => props.theme.colors.primary};
    }
  }
`;

const CheckboxHolder = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  span {
    font-size: 14px;
    font-weight: 500;
    margin-left: 0.5rem;
  }
`;

export default AddScaleForm;
