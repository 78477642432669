import React, { useEffect, useState } from 'react';
import { func, array } from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';

import apiInstance2 from '../../services/apiService';
import NewModal from '../reusable/NewModal';
import CustomInput from '../reusable/FormComponents/Input';
import CustomSelect from '../reusable/Selects/Select';
import CustomButton from '../reusable/Buttons/Button';
import CustomTextArea from '../reusable/FormComponents/TextArea';

import { addNewNorm } from '../../store/norms/actions';

const validationSchema = Yup.object().shape({
  normName: Yup.string()
    .trim()
    .required(I18n.t('Norm name is required')),
  description: Yup.string(),
  assessment: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .nullable()
    .required(I18n.t('Assessment is required')),
});

const GroupBasedNormCreation = ({ onClose, groupsIds }) => {
  const dispatch = useDispatch();

  const [assessments, setAssessments] = useState([]);

  useEffect(async () => {
    try {
      const query = groupsIds.reduce(
        (acc, item, i) => `${acc}groupIds=${item}${i !== groupsIds.length - 1 ? '&' : ''}`,
        '',
      );
      const response = await apiInstance2.get(`/api/v2/norms/groups/tests?${query}`);
      if (response.status === 200) {
        const data = response.data.map((item) => ({
          ...item,
          label: item.name,
          value: item.testID,
        }));
        setAssessments(data);
      }
    } catch (e) {
      console.log('Error while fetching assessments for groups');
    }
  }, []);

  return (
    <StyledModal isVisible onClose={onClose} title={I18n.t('Create norm based on the selected group(s))')}>
      <Formik
        initialValues={{
          normName: '',
          description: '',
          assessment: null,
        }}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={(values, { setSubmitting }) => {
          const data = {
            name: values.normName,
            description: values.description,
            testId: values.assessment.testID,
            groupIds: groupsIds,
            normType: 'F',
          };
          dispatch(
            addNewNorm(data, (err) => {
              if (err) setSubmitting(false);
              else onClose();
            }),
          );
        }}
      >
        {({ values, setFieldValue, setFieldError, errors, isSubmitting, handleSubmit }) => {
          return (
            <FormSection>
              <InputsWrapper>
                <StyledInput
                  value={values.normName}
                  onChange={(e) => {
                    setFieldValue('normName', e.target.value);
                    setFieldError('normName', '');
                  }}
                  inputName="Norm name"
                  error={errors.normName}
                />
                <StyledTextArea
                  inputName="Description"
                  name="description"
                  value={values.description}
                  onChange={(e) => setFieldValue('description', e.target.value)}
                  error={errors.description}
                />
                <StyledSelect
                  options={assessments}
                  placeholder="Assessment on which the norm is based"
                  selectProps={{
                    value: values.assessment,
                    onChange: (value) => {
                      setFieldValue('assessment', value);
                      setFieldError('assessment', '');
                    },
                  }}
                  error={errors.assessment}
                />
              </InputsWrapper>
              <ButtonWrapper>
                <StyledButton disabled={isSubmitting} handler={handleSubmit} type="submit">
                  {I18n.t('Save')}
                </StyledButton>
              </ButtonWrapper>
            </FormSection>
          );
        }}
      </Formik>
    </StyledModal>
  );
};

GroupBasedNormCreation.propTypes = {
  onClose: func.isRequired,
  groupsIds: array.isRequired,
};

const StyledModal = styled(NewModal)`
  display: flex;
  flex-direction: column;
  width: 75rem;
  min-height: 46rem;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledInput = styled(CustomInput)`
  width: 50rem;
  margin-bottom: 2rem;
`;

const StyledSelect = styled(CustomSelect)`
  width: 50rem;
  margin-bottom: 2rem;
`;

const StyledTextArea = styled(CustomTextArea)`
  margin-bottom: 2rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledButton = styled(CustomButton)`
  width: 12rem;
  text-transform: uppercase;
`;

export default GroupBasedNormCreation;
