import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as ErrorIcon } from '../assets/icons/error.svg';
import { ReactComponent as CheckIcon } from '../assets/icons/check-green.svg';
import { ReactComponent as BellIcon } from '../assets/icons/bell.svg';

const iconsMap = {
  success: CheckIcon,
  error: ErrorIcon,
  info: BellIcon,
};

const Notification = ({ body, type }) => {
  const Icon = iconsMap[type];
  return (
    <StyledContainer>
      <Content>
        <Body>
          <IconHolder>
            <Icon />
          </IconHolder>
          <span>{body}</span>
        </Body>
      </Content>
    </StyledContainer>
  );
};

Notification.propTypes = {
  body: string.isRequired,
  type: string.isRequired,
};

const StyledContainer = styled.div`
  font-family: Montserrat;
  display: flex;
`;

const IconHolder = styled.div`
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
  min-height: 4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  background-color: #ffffff;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

const Body = styled.div`
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
`;

export default Notification;
