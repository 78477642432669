import { format, isAfter } from 'date-fns';
import React from 'react';
import { utcToZonedTime } from 'date-fns-tz';
import styled from 'styled-components';

import { I18n } from 'react-redux-i18n';
import { caseInsensitiveSortingFunc } from './respondents';
// import ActionsDropdown from '../../components/reusable/ActionsDropdown';
import inviteTypes from '../../constants/inviteTypes';
import inviteStatuses from '../../constants/inviteStatuses';
import isChineseEnv from '../../utils/isChineseEnv';

const statusOptions = Object.keys(inviteStatuses).map((key) => ({ value: key, label: inviteStatuses[key] }));

const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const DateCell = ({ row }) => {
  if (!row.original.expiryDate) return '';
  const hasExpired = isAfter(new Date(), new Date(row.original.expiryDate));
  const color = hasExpired ? '#FFA4A4' : '#40C4FF';
  return (
    <span style={{ color }}>
      {format(utcToZonedTime(new Date(row.original.expiryDate), currentTimezone), 'dd/M/y HH:mm')}
    </span>
  );
};

const statusesStyles = {
  0: {
    title: 'Not sent',
    background: '#F9F9F9',
    color: '#828282',
    borderColor: '#CDCDCD',
  },
  1: {
    title: 'Scheduled',
    background: '#DBF6FF',
    color: '#008AC0',
    borderColor: '#B7EBFF',
  },
  2: {
    title: 'Sent',
    background: '#EAF7EF',
    color: '#27AE60',
    borderColor: '#c3e9d2',
  },
  3: {
    title: 'Failed',
    background: '#FFECEB',
    color: '#FF574C',
    borderColor: '#FFB1AB',
  },
};

const StatusCell = ({ row }) => {
  const styles = statusesStyles[row.original.invitationStatus];
  const backupText = inviteStatuses[row.original.invitationStatus];
  return (
    <StyledStatus style={styles && { ...styles }}>{styles ? I18n.t(styles.title) : I18n.t(backupText)}</StyledStatus>
  );
};

const columns = (languageOptions) => [
  {
    Header: I18n.t('PersonName'),
    accessor: (data) => (isChineseEnv ? `${data.familyName}${data.firstName}` : `${data.firstName} ${data.familyName}`),
    name: 'name',
    type: 'text',
    sortType: caseInsensitiveSortingFunc,
    filteringName: 'Name',
    isSimplified: true,
  },
  {
    Header: I18n.t('Email Address'),
    accessor: 'email',
    name: 'email',
    type: 'text',
    sortType: caseInsensitiveSortingFunc,
    filteringName: 'Email',
    isSimplified: true,
  },
  {
    Header: I18n.t('Assessment'),
    accessor: 'assessment',
    name: 'assessment',
    type: 'text',
    sortType: caseInsensitiveSortingFunc,
    filteringName: 'Assessment',
  },
  {
    Header: I18n.t('Language'),
    accessor: 'langID',
    name: 'langID',
    filteringName: 'Language.Value',
    type: 'select',
    options: languageOptions,
    Cell: ({ row }) => (isChineseEnv && row.original.langID === 'CH' ? '简体中文' : row.original.langID),
  },
  {
    Header: I18n.t('Type'),
    name: 'type',
    accessor: (data) => I18n.t(inviteTypes[data.inviteType]),
  },
  {
    Header: I18n.t('Invite status'),
    name: 'status',
    type: 'select',
    options: statusOptions,
    Cell: (props) => <StatusCell {...props} />,
    filteringName: 'InvitationStatus.Value',
    isSimplified: true,
  },
  {
    Header: I18n.t('Invitation Date'),
    accessor: (data) => new Date(data.invitationDate),
    Cell: ({ row }) => (
      <span>
        {row.original.invitationDate
          ? format(utcToZonedTime(new Date(row.original.invitationDate), currentTimezone), 'dd/M/y HH:mm')
          : ''}
      </span>
    ),
    name: 'invitationDate',
    sortType: 'datetime',
    type: 'date',
    filteringName: 'InvitationDate',
    width: 100,
  },
  {
    Header: I18n.t('Expiry Date'),
    uiSelectName: I18n.t('Hide expired'),
    name: 'expiryDate',
    accessor: (data) => new Date(data.expiryDate),
    Cell: (props) => <DateCell {...props} />,
    sortType: 'datetime',
    type: 'select',
    options: [
      { value: false, label: I18n.t('No') },
      { value: true, label: I18n.t('Yes') },
    ],
    filteringName: 'HideExpired.Value',
    width: 100,
    isSimplified: true,
  },
  // {
  //   Header: I18n.t(''),
  //   name: 'actions',
  //   type: 'actions',
  //   id: 'actions',
  //   disableSortBy: true,
  //   Cell: ({ row }) => (
  //     <ActionsDropdown
  //       actions={rowActions.map((item) => ({
  //         ...item,
  //         handler: () => item.handler && item.handler(row.original),
  //         isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(row.original) : item.isDisabled,
  //       }))}
  //       clickHandler={() => setSelectedInvites(row.original)}
  //     />
  //   ),
  //   width: 50,
  //   isSimplified: true,
  // },
];

const StyledStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 8rem;
  width: auto;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;
  border: 1px solid;
`;

export default columns;
