import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { setLocale, loadTranslations, syncTranslationWithStore } from 'react-redux-i18n';
import { reachify } from 'redux-first-history';
import thunk from 'redux-thunk';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import apiInstance from '../services/apiService';
import storageService from '../services/storageService';

import rootReducer, { routerMiddleware, createReduxHistory } from './rootReducer';
import { logout, resetTokensFailure, resetTokensStart, resetTokensSuccess } from './user/actions';

import translations from '../i18n/translations';
import changeLocale from './i18n/changeLocale';

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(routerMiddleware, changeLocale, thunk)),
);

syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));

const preselectedLang = storageService.getItem('psLang');
store.dispatch(setLocale(preselectedLang || 'en'));

const refreshAuthLogic = async (failedRequest) => {
  const refreshToken = storageService.getItem('refreshToken');
  const accessToken = storageService.getItem('accessToken');
  const data = { token: accessToken, refreshToken };
  const { user } = store.getState();
  store.dispatch(resetTokensStart());
  try {
    if (!user.isLoggedIn) return Promise.resolve();
    const response = await apiInstance.post(`/api/v2/refresh-token`, data, {
      skipAuthRefresh: true,
    });
    if (response?.status === 200) {
      storageService.setItem('accessToken', response.data.token);
      storageService.setItem('refreshToken', response.data.refreshToken);
      apiInstance.defaults.headers.Authorization = `Bearer ${response.data.token}`;
      failedRequest.response.config.headers.Authorization = `Bearer ${response.data.token}`;
      store.dispatch(resetTokensSuccess());
      return Promise.resolve();
    }
  } catch (err) {
    if (err.response.status === 401 && user.isLoggedIn) {
      store.dispatch(resetTokensFailure());
      store.dispatch(logout());
      return Promise.reject(new Error('Refresh token has expired'));
    }
  }
};

createAuthRefreshInterceptor(apiInstance, refreshAuthLogic);

// check for newest access token before every request; otherwise network request flow during token refresh crashes
apiInstance.interceptors.request.use((request) => {
  const token = storageService.getItem('accessToken');
  if (token) request.headers.Authorization = `Bearer ${storageService.getItem('accessToken')}`;
  return request;
});

export const history = createReduxHistory(store);
export const reachHistory = reachify(history);
