import { createSelector } from 'reselect';
import { resolveAssessmentName } from '../../../utils/assessmentsNames';

const getIdealProfilesTests = (state) => state.idealProfiles.availableTests;
const getIdealProfilesGroups = (state) => state.idealProfiles.availableGroups;

export const selectIdealProfileTestsOptions = createSelector(getIdealProfilesTests, (tests) =>
  tests.map((item) => ({ ...item, value: item.testID, label: resolveAssessmentName(item), norms: item.norms || [] })),
);

export const selectIdealProfileGroupsOptions = createSelector(getIdealProfilesGroups, (groups) =>
  groups.map((item) => ({ ...item, value: item.groupID, label: item.name })),
);
