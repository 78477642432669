import { I18n } from 'react-redux-i18n';
import * as actions from '../actionsList';

const welcomeTextsTableActions = [
  {
    name: actions.CREATE_WELCOME_TEXT,
    label: I18n.t('Add new welcome text'),
  },
];

export default welcomeTextsTableActions;
