import { DEFAULT_ORDER, QUESTIONS_ORDER_LIST } from '../../../components/Settings/constants/QuestionsOrder';
import { DEFAULT_SCALE } from '../../../components/Settings/constants/ResponseScale';
import {
  sortQuestionsByType,
  createGroupsAndQuestions,
  getResponses,
  groupQuestionsByCompetencyType,
  makeUniqKey,
} from './utils';
import handleActions from '../../immerHandleActions';
import * as actions from './actions';

const initialState = {
  isLoading: true,
  summary: null,
  groups: [],
  questions: [],
  scales: [],
  scalesDefault: [],
  responses: {},
  selectedScale: null,
  selectedOrder: null,
  customOrderSequence: null,
  initialValues: {
    selectedScale: null,
    selectedOrder: null,
    questions: [],
  },
};

const competencyGroupsDetailsReducer = handleActions(
  {
    // [actions.fetchCompetencyGroupsDetails]: (draft) => {
    //   return {
    //     ...draft,
    //     isLoading: true,
    //   };
    // },
    [actions.fetchCompetencyGroupsDetailsSuccess]: (draft, { payload: { summary, scales, scalesDefault, langID } }) => {
      const { groups, questions } = createGroupsAndQuestions(summary, langID);
      const { responseScaleID = null, orderType = null, customOrderSequence = null } = summary;

      let selectedOrder = DEFAULT_ORDER;
      let selectedScale = DEFAULT_SCALE;

      if (orderType !== null) {
        selectedOrder = QUESTIONS_ORDER_LIST.find((item) => item.value === orderType) || DEFAULT_ORDER;
      }

      if (responseScaleID !== null) {
        const savedScale = scales.find((item) => item.responseScaleID === responseScaleID);
        if (savedScale) {
          selectedScale = {
            label: savedScale.name,
            value: savedScale.responseScaleID,
          };
        }
      }

      return {
        ...draft,
        summary,
        groups,
        questions,
        scales,
        scalesDefault,
        selectedScale,
        selectedOrder,
        customOrderSequence,
        isLoading: false,
        initialValues: {
          selectedScale,
          selectedOrder,
          questions,
        },
      };
    },
    [actions.fetchCompetencyGroupsDetailsFailure]: (draft) => {
      return {
        ...draft,
        isLoading: false,
      };
    },
    [actions.selectCompetencyGroupsDetailsScale]: (draft, { payload: { scale, langID } }) => {
      return {
        ...draft,
        responses: getResponses(scale.value, langID, draft.scales, draft.scalesDefault),
        isLoading: false,
      };
    },
    [actions.removeCompetenciesSuccess]: (draft, { payload: { data } }) => {
      const removedCollection = data.reduce((acc, item) => {
        return {
          ...acc,
          [makeUniqKey(item)]: item,
        };
      }, {});

      return {
        ...draft,
        questions: [...draft.questions.filter((item) => !removedCollection[item.parentUniqId])],
        initialValues: {
          ...draft.initialValues,
          questions: [...draft.questions.filter((item) => !removedCollection[item.parentUniqId])],
        },
        groups: draft.groups.reduce((acc, item) => {
          const newGroup = { ...item };

          newGroup.categories = newGroup.categories.filter((competency) => !removedCollection[competency.uniqId]);

          if (newGroup.categories.length) {
            acc.push(newGroup);
          }

          return acc;
        }, []),
      };
    },
    [actions.calculateSummary]: (draft) => {
      const { standard, custom } = groupQuestionsByCompetencyType(draft.questions);

      return {
        ...draft,
        summary: {
          ...draft.summary,
          categoriesCount: draft.groups.length,
          competenciesCount: draft.groups.reduce((acc, item) => item.categories.length + acc, 0),
          itemsCount: standard.length,
          freeTextItemsCount: custom.length,
        },
      };
    },
    [actions.sortQuestions]: (draft, { payload: { order, isCustom } }) => {
      if (sortQuestionsByType[order.value]) {
        return {
          ...draft,
          selectedOrder: order,
          questions: sortQuestionsByType[order.value](draft.questions, draft.customOrderSequence),
          initialValues: {
            ...draft.initialValues,
            questions: isCustom
              ? draft.initialValues.questions
              : sortQuestionsByType[order.value](draft.questions, draft.customOrderSequence),
          },
        };
      }

      return {
        ...draft,
      };
    },
    [actions.updateTitle]: (draft, { payload: { data } }) => {
      return {
        ...draft,
        summary: {
          ...draft.summary,
          name: data,
        },
      };
    },
    [actions.setCustomOrderSequence]: (draft, { payload: { customOrderSequence } }) => {
      return {
        ...draft,
        customOrderSequence,
      };
    },
    [actions.setSelectedScale]: (draft, { payload: { data } }) => {
      return {
        ...draft,
        selectedScale: data,
      };
    },
    [actions.setSelectedOrder]: (draft, { payload: { data } }) => {
      return {
        ...draft,
        selectedOrder: data,
      };
    },
    [actions.saveDetailsSuccess]: (draft) => {
      return {
        ...draft,
        initialValues: {
          selectedOrder: draft.selectedOrder,
          selectedScale: draft.selectedScale,
          questions: draft.questions,
        },
        saveLoading: false,
      };
    },
    [actions.saveDetailsFailure]: (draft) => {
      return {
        ...draft,
        saveLoading: false,
      };
    },
    [actions.resetCompetencyGroupsDetails]: () => {
      return {
        ...initialState,
      };
    },
  },
  initialState,
);

export default competencyGroupsDetailsReducer;
