import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import Checkbox from '../../reusable/FormComponents/Checkbox';
import { ReactComponent as Up } from '../../../assets/icons/settings/up.svg';
import { ReactComponent as Down } from '../../../assets/icons/blue-arrow-down-big.svg';
import AnimateHeightWrapper from '../../reusable/AnimateHeight';

const ImportSessionsAccordion = ({
  columns,
  groupHeaderColumns,
  headersRows,
  items,
  itemID,
  groupedItems,
  selectedItems,
  setSelectedItems,
  onRowClick,
  customEmptyMessage,
}) => {
  const [openPanels, setOpenPanels] = useState([]);
  const toggleOpenPanels = useCallback(
    (key) => {
      const index = openPanels.indexOf(key);

      if (index === -1) {
        setOpenPanels([...openPanels, key]);
      } else {
        setOpenPanels([...openPanels.slice(0, index), ...openPanels.slice(index + 1)]);
      }
    },
    [openPanels],
  );
  const validHeaderRows = headersRows
    ? Object.keys(headersRows)
        .filter((key) => headersRows[key].isValid)
        .reduce((acc, key) => ({ ...acc, [key]: headersRows[key] }), {})
    : null;

  const toggleAllItems = useCallback(() => {
    const isChecked =
      !!items.length &&
      items.filter((item) => {
        if (validHeaderRows && !validHeaderRows[item.parentId]) return false;
        return item.isValid !== undefined && item.isValid;
      }).length === selectedItems.length;

    if (isChecked) {
      setSelectedItems([]);
    } else {
      setSelectedItems(
        items
          .filter((item) => {
            if (validHeaderRows && !validHeaderRows[item.parentId]) return false;
            return item.isValid !== undefined && item.isValid;
          })
          .map((item) => item[itemID]),
      );
    }
  }, [items, selectedItems]);

  const toggleGroup = (group, isChecked) => {
    const groupIDs = groupedItems[group]
      .filter((item) => item.isValid !== undefined && item.isValid)
      .map((item) => item[itemID]);
    const filteredItems = selectedItems.filter((item) => groupIDs.indexOf(item) === -1);
    if (isChecked) {
      setSelectedItems(filteredItems);
    } else {
      setSelectedItems([...filteredItems, ...groupIDs]);
    }
  };

  const toggleItem = useCallback(
    (normID) => {
      const index = selectedItems.indexOf(normID);

      if (index === -1) {
        setSelectedItems([...selectedItems, normID]);
      } else {
        setSelectedItems([...selectedItems.slice(0, index), ...selectedItems.slice(index + 1)]);
      }
    },
    [selectedItems],
  );

  return (
    <>
      <AccordionHeader>
        <StyledCheckbox
          isChecked={
            !!items.length &&
            items.filter((item) => {
              if (validHeaderRows && !validHeaderRows[item.parentId]) return false;
              return item.isValid !== undefined && item.isValid;
            }).length === selectedItems.length
          }
          clickHandler={toggleAllItems}
        />
        {columns.map((column) => (
          <CellContainerStyled key={column.title}>{column.title}</CellContainerStyled>
        ))}
      </AccordionHeader>
      <AccordionWrapper>
        {Object.keys(groupedItems).length
          ? Object.keys(groupedItems).map((key) => {
              const isGroupChecked = groupedItems[key].some((item) => selectedItems.indexOf(item[itemID]) !== -1);
              const isPanelOpened = openPanels.indexOf(key) !== -1;
              const isInvalid = headersRows && headersRows[key].isValid !== undefined && !headersRows[key].isValid;
              return (
                <PanelWrapper key={key}>
                  <Panel disabled={isInvalid}>
                    <PanelTitle>
                      <StyledCheckbox
                        clickHandler={() => {
                          if (isInvalid) return;
                          toggleGroup(key, isGroupChecked);
                        }}
                        isChecked={isGroupChecked}
                      />
                      {groupHeaderColumns ? (
                        groupHeaderColumns.map((column) => (
                          <CellContainer groupHeader key={column.title}>
                            {column.Cell ? column.Cell({ row: headersRows[key], column }) : null}
                          </CellContainer>
                        ))
                      ) : (
                        <span>{key}</span>
                      )}
                    </PanelTitle>
                    <OpenIcon onClick={() => toggleOpenPanels(key)}>{isPanelOpened ? <Down /> : <Up />}</OpenIcon>
                  </Panel>
                  <AnimateHeightWrapper height={!isPanelOpened ? 'auto' : 0}>
                    {groupedItems[key].map((item) => {
                      const isChecked = selectedItems.indexOf(item[itemID]) !== -1;
                      const isInvalid = item.isValid !== undefined && !item.isValid;
                      const isParentInvalid =
                        headersRows && headersRows[key].isValid !== undefined && !headersRows[key].isValid;
                      return (
                        <PanelBody
                          onClick={() => {
                            if (isInvalid || isParentInvalid) return;
                            return onRowClick ? onRowClick(item[itemID]) : toggleItem(item[itemID]);
                          }}
                          key={item[itemID]}
                          disabled={isInvalid}
                          isParentInvalid={isParentInvalid}
                        >
                          <StyledCheckbox
                            clickHandler={(event) => {
                              if (isInvalid) return;
                              event.stopPropagation();
                              toggleItem(item[itemID]);
                            }}
                            isChecked={isChecked}
                          />
                          {columns.map((column) => (
                            <CellContainer key={column.title} isParentInvalid={isParentInvalid}>
                              {column.Cell ? column.Cell({ row: item, column }) : item[column.accessor]}
                            </CellContainer>
                          ))}
                        </PanelBody>
                      );
                    })}
                  </AnimateHeightWrapper>
                </PanelWrapper>
              );
            })
          : customEmptyMessage}
      </AccordionWrapper>
    </>
  );
};

const AccordionWrapper = styled.div`
  overflow: auto;
`;

const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 8px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 10px;
  cursor: pointer;
  border-color: #acacac;
`;

const CellContainer = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  font-weight: ${(props) => (props.groupHeader ? 'bold' : '500')};
  color: ${(props) => {
    if (props.groupHeader) return props.theme.colors.darkBlue2;
    return props.isParentInvalid ? props.theme.colors.grey3 : props.theme.colors.primary;
  }}!important;
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => props.theme.colors.primary};
`;

const CellContainerStyled = styled(CellContainer)`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #07668a;
`;

const PanelWrapper = styled.div`
  margin-bottom: 8px;
`;

const Panel = styled.div`
  position: relative;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => (props.disabled ? '#FFD8D8' : '#f9f9f9')};
`;

const PanelTitle = styled.div`
  display: flex;
  width: 100%;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => props.theme.colors.darkBlue2};
`;

const OpenIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
  background: #ffffff;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const PanelBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
  border-bottom: 1px solid #f2f2f2;
  cursor: pointer;

  background-color: ${(props) => (props.disabled && !props.isParentInvalid ? '#FFD8D8' : props.theme.colors.white)};
`;

export default ImportSessionsAccordion;
