const reorder = (list, startIndex, endIndex) => {
  const newList = [...list];
  const removedElem = list[startIndex];
  newList.splice(startIndex, 1);
  newList.splice(endIndex, 0, removedElem);
  return newList;
};

const getIdByType = (value, type) => value.toString().replace(`${type}DND`, '');

const dragAdnDropUtils = {
  reorder,
  getIdByType,
};

export default dragAdnDropUtils;
