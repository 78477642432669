import React, { useMemo } from 'react';
import { func } from 'prop-types';
import styled, { css } from 'styled-components';

import useStep from '../../../../hooks/useStep';
import Steps from '../../../reusable/Steps';

import RulesStep from './RulesStep';
import InfoStep from './InfoStep';
import AssessmentStep from './AssessmentStep';
import InvitesStep from './InvitesStep';
import ReportsStep from './ReportsStep';
import SummaryStep from './SummaryStep';

const steps = ['rules', 'general', 'assessment', 'invites', 'reports', 'summary'];

const AssessmentProject = ({ goBackToProjectSelection, onClose, onReset }) => {
  const { step, goNext, goPrev, setStep } = useStep({ steps, initialStep: 0 });

  const content = useMemo(() => {
    switch (steps[step]) {
      case 'rules':
        return <RulesStep goBackToProjectSelection={goBackToProjectSelection} goNext={goNext} />;
      case 'general':
        return <InfoStep goBackToProjectSelection={goBackToProjectSelection} goPrev={goPrev} goNext={goNext} />;
      case 'assessment':
        return <AssessmentStep goNext={goNext} goPrev={goPrev} />;
      case 'invites':
        return <InvitesStep goNext={goNext} goPrev={goPrev} />;
      case 'reports':
        return <ReportsStep goNext={goNext} goPrev={goPrev} />;
      case 'summary':
        return <SummaryStep goPrev={goPrev} setStep={setStep} onClose={onClose} onReset={onReset} />;
      default:
        return null;
    }
  }, [step]);

  return (
    <Container>
      <StepsWrapper isHidden={steps[step] === 'rules'}>
        <Steps stepIndex={step} steps={steps} stepsToExclude={['rules']} />
      </StepsWrapper>
      {content}
    </Container>
  );
};

AssessmentProject.propTypes = {
  goBackToProjectSelection: func.isRequired,
  onClose: func.isRequired,
  onReset: func.isRequired,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
const StepsWrapper = styled.div`
  padding: 0 2.4rem;
  margin-bottom: 1rem;

  ${(props) =>
    props.isHidden &&
    css`
      display: none;
    `}
`;

export default AssessmentProject;
