import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import competencyGroupsDetailsSelectors from '../../../../store/settings/competency-groups-details/selectors';
import reviewCompetenciesSelectors from '../../../../store/settings/review-competencies/selectors';
import {
  fetchCompetencies,
  resetReviewCompetencies,
  updateCompetencies,
} from '../../../../store/settings/review-competencies/actions';
import NewModal from '../../../reusable/NewModal';
import Spinner from '../../../reusable/Spinner';
import CustomButton from '../../../reusable/Buttons/Button';
import CompetencyGroupsAccordion from '../../../reusable/Accordion/CompetencyGroupsAccordion';

const ReviewCompetencies = ({ groupId, onCancel, onConfirm }) => {
  const [groupsByType, setGroupsByType] = useState({ custom: [], inbuilt: [] });
  const [selected, setSelected] = useState({ custom: {}, inbuilt: {} });
  const dispatch = useDispatch();

  const groups = useSelector(reviewCompetenciesSelectors.selectGroups);
  const detailsGroups = useSelector(competencyGroupsDetailsSelectors.selectGroups);
  const initLoading = useSelector((state) => state.reviewCompetencies.initLoading);
  const updateLoading = useSelector((state) => state.reviewCompetencies.updateLoading);

  useEffect(() => {
    dispatch(fetchCompetencies());

    return () => {
      dispatch(resetReviewCompetencies());
    };
  }, []);

  useEffect(() => {
    const { custom, inbuilt } = detailsGroups.reduce(
      (acc, item) => {
        item.categories.forEach((category) => {
          if (item.competencyType) {
            acc.custom[category.competencyID] = category;
          } else {
            acc.inbuilt[category.competencyID] = category;
          }
        });

        return acc;
      },
      { custom: {}, inbuilt: {} },
    );

    setSelected({ custom, inbuilt });
  }, [detailsGroups]);

  useEffect(() => {
    setGroupsByType({
      custom: groups.filter((item) => item.competencyType),
      inbuilt: groups.filter((item) => !item.competencyType),
    });
  }, [groups]);

  const onConfirmClick = () => {
    const allSelected = Object.values({ ...selected.custom, ...selected.inbuilt });
    const updateData = allSelected.map(({ competencyID, competencyType }) => ({ competencyID, competencyType }));

    dispatch(updateCompetencies(groupId, updateData, () => onConfirm()));
  };

  const onSelectCompetency = (name, items) => {
    setSelected({ ...selected, [name]: items.reduce((acc, item) => ({ ...acc, [item.competencyID]: item }), {}) });
  };

  return (
    <NewModal isVisible title={I18n.t('Competencies')} onClose={onCancel}>
      {initLoading ? (
        <Spinner isLoading text={I18n.t('Loading competencies')} />
      ) : (
        <>
          <StyledModalBody>
            <AccordionWrapper>
              {groupsByType.custom.length ? (
                <CompetencyGroupsAccordion
                  selected={{ ...selected.custom }}
                  list={groupsByType.custom}
                  label={I18n.t('Custom competencies')}
                  onSelectedChanged={(checked, items) => onSelectCompetency('custom', checked, items)}
                />
              ) : null}
              {groupsByType.inbuilt.length ? (
                <CompetencyGroupsAccordion
                  selected={{ ...selected.inbuilt }}
                  list={groupsByType.inbuilt}
                  label={I18n.t('Inbuilt competencies')}
                  onSelectedChanged={(items) => onSelectCompetency('inbuilt', items)}
                />
              ) : null}
            </AccordionWrapper>
          </StyledModalBody>
          <ButtonWrapper>
            <StyledCustomButton type="button" size="m" width={120} variant="primary" onClick={onConfirmClick}>
              {updateLoading ? <Spinner isLoading /> : I18n.t('Confirm')}
            </StyledCustomButton>
          </ButtonWrapper>
        </>
      )}
    </NewModal>
  );
};

const StyledModalBody = styled.div`
  max-height: 600px;
  overflow: auto;
`;

const AccordionWrapper = styled.div`
  margin: 20px 0;
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: flex-end;
  width: 100%;
`;

const StyledCustomButton = styled(CustomButton)`
  min-height: 44px;
  position: relative;
  text-transform: uppercase;
`;

export default ReviewCompetencies;
