import React from 'react';
import styled from 'styled-components';

import { I18n } from 'react-redux-i18n';
import Modal from '../../reusable/NewModal';

import SimplifiedTable from '../../reusable/Tables/SimplifiedTable';

const StatsModal = ({ categorieName, rawDataColumns, rawData, onClose }) => {
  return (
    <StyledModal title={I18n.t('Details for', { categorieName })} lg isVisible onClose={onClose}>
      <PreviewTitle>
       <PreviewItem>{I18n.t('RawDetails')}</PreviewItem>
      </PreviewTitle>
      <Table columns={rawDataColumns} data={rawData} onSelectChange={() => {}} disableSelect bottomOffset={170} />
    </StyledModal>
  );
};

const Table = styled(SimplifiedTable)`
  border: 1px solid #80808029;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
`;

const StyledModal = styled(Modal)`
  overflow: auto;
  border-radius: 5px;
`;

const PreviewTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #295368;
`;

const PreviewItem = styled.p`
  color: #295368;
`;

export default StatsModal;
