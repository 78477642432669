import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import CustomButton from '../../reusable/Buttons/Button';
import { fetchCompetenciesAction, resetCompetencies } from '../../../store/settings/competencies/actions';

export default ({ onClose }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCompetenciesAction());

    return () => {
      dispatch(resetCompetencies());
    };
  }, []);

  return (
    <>
      <ContentWrapper>
        <div style={{ textAlign: 'center' }}>
          <div style={{ color: '#27AE60', fontSize: '120%', fontWeight: 'bold', marginTop: '30px' }}>
            {I18n.t('Competencies were created successfully')}
          </div>
          <h3 style={{ marginTop: '15px', marginBottom: '30px' }}>
            {I18n.t('Would you like to create a 360 survey?')}
          </h3>
          <div>
            <ButtonsWrapper>
              <StyledButton
                style={{ whiteSpace: 'nowrap', marginRight: '10px', width: 'unset' }}
                handler={() => onClose('create_survey')}
                variant="secondary"
              >
                {I18n.t('Create Survey')}
              </StyledButton>
              <StyledButton handler={onClose}>{I18n.t('Close')}</StyledButton>
            </ButtonsWrapper>
          </div>
        </div>
      </ContentWrapper>
    </>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  margin-bottom: 1rem;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled(CustomButton)`
  width: 12rem;
  text-transform: uppercase;
`;
