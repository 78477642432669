import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { I18n } from 'react-redux-i18n';
import { caseInsensitiveSortingFunc } from './respondents';
import { ReactComponent as AppraisalLogo } from '../../assets/icons/360p.svg';
import { ReactComponent as AssessmentLogo } from '../../assets/icons/assesment.svg';
import StatusCell, { statusMap } from '../../components/reusable/StatusCell';

const projectTypesMap = {
  0: I18n.t('360 Appraisal'),
  1: I18n.t('Assessment'),
};

const statusOptions = statusMap.map(({ value, title }) => ({ value, label: I18n.t(title) }));

const DateCell = ({ date }) => {
  return date ? <span>{format(new Date(date), 'dd/M/y')}</span> : null;
};

const ProjectCellStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;

  svg {
    height: 18px;
    width: auto;
    margin-right: 8px;

    path {
      fill: #1baae1;
    }
  }
`;

const ProjectCell = ({ row }) => {
  return (
    <ProjectCellStyled>
      {row.original.type === 0 ? <AppraisalLogo /> : <AssessmentLogo />}
      <span>{I18n.t(projectTypesMap[row.original.type])}</span>
    </ProjectCellStyled>
  );
};

// const columns = (rowActions, setSelectedProjects) => [
const columns = [
  {
    Header: I18n.t('Name'),
    accessor: 'name',
    name: 'name',
    type: 'text',
    backendColumnIndex: 0,
    filteringName: 'Name',
    sortType: caseInsensitiveSortingFunc,
    width: 150,
  },
  {
    Header: I18n.t('Description'),
    accessor: 'description',
    name: 'description',
    type: 'text',
    width: 300,
    backendColumnIndex: 1,
    filteringName: 'Description',
    sortType: caseInsensitiveSortingFunc,
  },
  {
    Header: I18n.t('Type'),
    accessor: (data) => I18n.t(projectTypesMap[data.type]),
    Cell: (props) => <ProjectCell {...props} />,
    name: 'type',
    type: 'select',
    backendColumnIndex: 2,
    filteringName: 'Type',
    disableFilterBy: true,
    width: '12%',
  },
  {
    Header: I18n.t('Status'),
    accessor: 'status',
    Cell: ({ row }) => <StatusCell row={row} />,
    name: 'status',
    type: 'select',
    options: statusOptions,
    backendColumnIndex: 4,
    filteringName: 'Status.Value',
    width: '8%',
  },
  {
    Header: I18n.t('Sessions'),
    // accessor: (data) => data.sessions.length,
    accessor: 'sessions',
    Cell: ({ row }) => <div>{row.original.totalCount}</div>,
    name: 'sessions',
    type: 'number',
    backendColumnIndex: 3,
    filteringUiName: 'Sessions count',
    filteringName: 'Sessions',
    width: '8%',
  },
  {
    Header: I18n.t('Creation Date'),
    accessor: (data) => new Date(data.creationDate),
    Cell: ({ row }) => <DateCell date={row.original.creationDate} />,
    name: 'creationDate',
    type: 'date',
    sortType: 'datetime',
    backendColumnIndex: 5,
    filteringName: 'Created',
    width: 100,
  },
  {
    Header: I18n.t('Deadline'),
    accessor: (data) => new Date(data.deadline),
    Cell: ({ row }) => <DateCell date={row.original.deadline} />,
    name: 'deadline',
    type: 'date',
    sortType: 'datetime',
    filteringName: 'DeadLine',
    backendColumnIndex: 6,
    width: 140,
  },
];

export default columns;
