import React from 'react';
import { any, bool, func, oneOf, string, number } from 'prop-types';
import styled from 'styled-components';
import Spinner from '../Spinner';

const paddingSizesMap = {
  xl: '2rem 3rem',
  l: '1.6rem 3rem',
  m: '1.4rem 2rem',
  s: '0.8rem 1rem',
};

const fontSizesMap = (themeFontSizes) => ({
  xl: themeFontSizes.medium,
  l: themeFontSizes.normal,
  m: '1.4rem',
  s: themeFontSizes.small,
});

const BaseButton = styled.button`
  position: relative;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  transition: all 0.2s;
  ${(props) => !!props.width && `width: ${props.width}px`};
  background-color: ${(props) => props.disabled && props.theme.colors.grey2} !important;
  border-radius: 4px;
  :hover {
    cursor: ${(props) => (props.disabled ? 'normal' : 'pointer')};
  }

  :active {
    transform: scale(0.98);
  }

  ${({ theme }) => theme.screenSizes.xl`
    padding: 1rem 1.6rem !important;
    font-size: ${theme.fontSizes.small} !important;
  `}
`;

const PrimaryButton = styled(BaseButton)`
  background-color: ${(props) => props.theme.colors.mediumBlue};
  color: ${(props) => props.theme.colors.white};
  font-weight: bold;
  font-size: ${(props) => fontSizesMap(props.theme.fontSizes)[props.size]};
  padding: ${(props) => paddingSizesMap[props.size]};

  :hover {
    background-color: ${(props) => props.theme.colors.mediumBlue};
  }

  :active {
    background-color: ${(props) => props.theme.colors.darkBlue};
  }
`;

const SecondaryButton = styled(BaseButton)`
  background-color: ${(props) => props.theme.colors.white};
  border: 2px solid ${(props) => props.theme.colors.lightBlue};
  color: ${(props) => props.theme.colors.lightBlue};
  font-weight: bold;
  font-size: ${(props) => fontSizesMap(props.theme.fontSizes)[props.size]};
  padding: ${(props) => paddingSizesMap[props.size]};

  :hover {
    color: ${(props) => props.theme.colors.mediumBlue};
    border-color: ${(props) => props.theme.colors.mediumBlue};
  }

  :active {
    color: ${(props) => props.theme.colors.darkBlue};
    border-color: ${(props) => props.theme.colors.darkBlue};
  }

  :disabled {
    border-color: ${(props) => props.theme.colors.grey2};
    color: ${(props) => props.theme.colors.grey2};
    background-color: ${(props) => props.theme.colors.white} !important;
  }
`;

const RoundedButton = styled(BaseButton)`
  background-color: ${(props) => props.theme.colors.mediumBlue};
  color: ${(props) => props.theme.colors.white};
  font-weight: 600;
  padding: 1rem 2rem;
  :hover {
    background-color: ${(props) => props.theme.colors.mediumBlue};
  }

  :active {
    background-color: ${(props) => props.theme.colors.darkBlue};
  }
`;

const types = ['primary', 'secondary', 'rounded'];

const Components = {
  primary: PrimaryButton,
  secondary: SecondaryButton,
  rounded: RoundedButton,
};

const CustomButton = ({ handler, variant, size, disabled, loading, className, children, width, ...rest }) => {
  const Button = Components[variant];
  return (
    <Button onClick={handler} size={size} disabled={disabled} width={width} className={className} {...rest}>
      <Label isLoading={loading}>{children}</Label>
      {loading ? <Spinner isLoading /> : ''}
    </Button>
  );
};

CustomButton.propTypes = {
  children: any.isRequired,
  handler: func,
  size: string,
  disabled: bool,
  loading: bool,
  variant: oneOf(types),
  className: string,
  width: number,
};

CustomButton.defaultProps = {
  size: 'm',
  disabled: false,
  loading: false,
  variant: 'primary',
  className: '',
  handler: undefined,
  width: 0,
};

const Label = styled.span`
  opacity: ${(props) => (props.isLoading ? '0' : '1')};
`;

export default CustomButton;
