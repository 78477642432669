import React, { useEffect, useMemo, useRef } from 'react';
import { array, object, func, string, oneOfType, bool, node, number } from 'prop-types';
import { useTable, useSortBy, useRowSelect } from 'react-table';
import styled, { css } from 'styled-components';
import { I18n } from 'react-redux-i18n';
import { ReactComponent as ArrowUp } from '../../../assets/icons/blue-arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/blue-arrow-down.svg';
import { ReactComponent as EmptyIcon } from '../../../assets/icons/empty_state.svg';

import TableRow from '../MultiSelectTableRow';
import MultiSelectCheckbox from '../MultiSelectCheckbox';
import Spinner from '../Spinner';

const SimplifiedTable = ({
  columns,
  data,
  initialState,
  highlightRowOptions,
  onSelectChange,
  className,
  onRowClick,
  interceptCheckboxClick,
  idAccessor,
  rowEqualityFunc,
  entityName,
  isLoading,
  disableSelect,
  customEmptyMessage,
  bottomOffset,
}) => {
  const memoizedColumns = useMemo(() => columns, [columns]);
  // Use the state and functions returned from useMultiSelectTable to build your UI
  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
    rows,
  } = useTable(
    {
      columns: memoizedColumns,
      data,
      initialState: {
        ...initialState,
        pageSize: 20,
      },
      manualRowSelectedKey: 'isSelected',
      autoResetPage: false,
      // autoResetSelectedRows: false,
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      if (!disableSelect) {
        hooks.allColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <CheckboxHolder>
                <MultiSelectCheckbox {...getToggleAllRowsSelectedProps()} enableClickHandler />
              </CheckboxHolder>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => {
              const toggleProps = row.getToggleRowSelectedProps();
              return (
                <CheckboxHolder
                  onClick={(e) => {
                    if (interceptCheckboxClick) e.stopPropagation();
                    toggleProps.onChange(e);
                  }}
                >
                  <MultiSelectCheckbox
                    {...(row.original.isValid !== undefined && !row.original.isValid ? {} : { ...toggleProps })}
                    disabled={row.original.isValid !== undefined && !row.original.isValid}
                  />
                </CheckboxHolder>
              );
            },
          },
          ...columns,
        ]);
      }
    },
  );
  useEffect(() => {
    if (onSelectChange) onSelectChange(selectedFlatRows.map((item) => ({ ...item.original })));
  }, [selectedFlatRows.length]);

  const scrollWrapperRef = useRef(null);

  const NoContent = customEmptyMessage || (
    <EmptyMessageWrapper>
      <EmptyIcon />
      <EmptyMessage>{I18n.t(`Sorry, we could not find any ${entityName}s`)}</EmptyMessage>
    </EmptyMessageWrapper>
  );
  return (
    <TableStyles className={className} selectDisabled={disableSelect}>
      <ScrollWrapper ref={scrollWrapperRef} bottomOffset={bottomOffset}>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      title=""
                      style={{ width: `${column.width}px` }}
                    >
                      <ThInnerDiv>
                        {column.render((props) => {
                          return typeof column.Header === 'string' ? (
                            I18n.t(column.Header)
                          ) : (
                            <column.Header {...props} />
                          );
                        })}

                        {column.id !== 'selection' && !column.disableSortBy && (
                          <ArrowsContainer>
                            <StyledArrowUp isactive={(column.isSorted && !column.isSortedDesc).toString()} />
                            <StyledArrowDown isactive={(column.isSorted && column.isSortedDesc).toString()} />
                          </ArrowsContainer>
                        )}
                      </ThInnerDiv>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const resolvedAccessor = typeof idAccessor === 'function' ? idAccessor(row) : idAccessor;
              return (
                <TableRow
                  key={row.id}
                  row={row}
                  isInvalid={row.original.isValid !== undefined && !row.original.isValid}
                  rowEqualityFunc={rowEqualityFunc}
                  isActive={Object.keys(selectedRowIds).includes(row.id)}
                  highlightRowOptions={highlightRowOptions}
                  resolvedAccessor={resolvedAccessor}
                  selectRow={
                    onRowClick ||
                    (() => {
                      if (row.original.isValid !== undefined && !row.original.isValid) return;
                      if (!disableSelect) row.toggleRowSelected(!row.isSelected);
                    })
                  }
                />
              );
            })}
          </tbody>
        </table>
        {!data.length && !isLoading && NoContent}
        {isLoading && <Spinner isLoading text={I18n.t('Loading items')} />}
      </ScrollWrapper>
    </TableStyles>
  );
};

SimplifiedTable.propTypes = {
  columns: array.isRequired,
  data: array.isRequired,
  initialState: object,
  onSelectChange: func,
  onRowClick: func,
  className: string,
  idAccessor: oneOfType([string, func]),
  rowEqualityFunc: func,
  highlightRowOptions: object,
  interceptCheckboxClick: bool,
  entityName: string,
  isLoading: bool,
  disableSelect: bool,
  customEmptyMessage: node,
  bottomOffset: number,
};

SimplifiedTable.defaultProps = {
  initialState: {},
  className: '',
  idAccessor: 'id',
  rowEqualityFunc: undefined,
  highlightRowOptions: {},
  onSelectChange: undefined,
  onRowClick: undefined,
  interceptCheckboxClick: false,
  entityName: '',
  isLoading: false,
  disableSelect: false,
  customEmptyMessage: null,
  bottomOffset: 25,
};

const TableStyles = styled.div`
  position: relative;
  height: 100%;
  table {
    table-layout: fixed;
    word-break: break-word;
    border-spacing: 0;
    color: ${(props) => props.theme.colors.primary};
    font-weight: 500;
    font-size: ${(props) => props.theme.fontSizes.small};
    width: auto;
    min-width: 100%;
    thead {
      user-select: none;
      th {
        position: sticky;
        top: 0;
        z-index: 8;
        color: ${(props) => props.theme.colors.darkBlue};

        text-align: left;
        :hover {
          background-color: ${(props) => props.theme.colors.lightBlue1};
        }
        &:last-child {
          word-wrap: initial;
          word-break: initial;
          width: 65px !important;
          max-width: 65px !important;
        }
      }
      // Make checkboxes column smaller
      ${(props) =>
        !props.selectDisabled &&
        css`
          tr th:first-child {
            min-width: 20px !important;
            width: 20px !important;
            max-width: 20px !important;
            & > div > div {
              width: 100%;
            }
          }
        `}
    }

    th {
      color: ${(props) => props.theme.colors.secondary};
      border-bottom: 1px solid ${(props) => props.theme.colors.grey6};
      padding: 1rem;
      font-weight: 600;
      background: ${(props) => props.theme.colors.white};
    }
    td {
      word-break: break-word;
      margin: 0;
      padding: 1rem;
      font-size: 12px;
      cursor: default;
      border-bottom: 1px solid ${(props) => props.theme.colors.grey6};
    }

    tr td:first-child,
    th:first-child {
      padding: ${(props) => (props.selectDisabled ? '2rem 1rem' : 0)};
    }

    tbody tr td {
      cursor: pointer;
      word-wrap: break-word;
      &:last-child {
        word-wrap: initial;
        word-break: initial;
      }
    }
    tbody tr td:last-child {
      word-wrap: initial;
      word-break: initial;
    }
  }
`;

const ScrollWrapper = styled.div`
  position: relative;
  overflow-x: clip;
  overflow-y: auto;
  height: calc(100% - ${(props) => `${props.bottomOffset}px`});
`;

const ThInnerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
`;

const StyledArrowUp = styled(ArrowUp)`
  width: 8px;
  height: 8px;
  path {
    fill: ${(props) => (props.isactive === 'true' ? props.theme.colors.blue : '#e4e6e6')};
  }
`;
const StyledArrowDown = styled(ArrowDown)`
  width: 8px;
  height: 8px;
  path {
    fill: ${(props) => (props.isactive === 'true' ? props.theme.colors.blue : '#e4e6e6')};
  }
`;

const CheckboxHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  min-height: 55px;
`;

const EmptyMessageWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EmptyMessage = styled.p`
  margin: 15px 0 0 0;
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
`;

export default SimplifiedTable;
