import { createAction } from 'redux-actions';
import { I18n } from 'react-redux-i18n';
import apiInstance2 from '../../../services/apiService';
import createToastNotification from '../../../utils/createToastNotification';

export const fetchResponseScales = createAction('[RESPONSE_SCALES] FETCH_DATA');
export const fetchResponseScalesSuccess = createAction('[RESPONSE_SCALES] FETCH_DATA_SUCCESS', (data) => ({
  data,
}));
export const fetchResponseScalesFailure = createAction('[RESPONSE_SCALES] FETCH_DATA_FAILURE');

export const deleteResponseScales = createAction('[RESPONSE_SCALES] DELETE_ITEM');
export const deleteResponseScalesSuccess = createAction('[RESPONSE_SCALES] DELETE_ITEM_SUCCESS', (data) => ({
  data,
}));
export const deleteResponseScalesFailure = createAction('[RESPONSE_SCALES] DELETE_ITEM_FAILURE');

export const addNewResponseScale = createAction('[RESPONSE_SCALES] ADD_NEW_RESPONSE_SCALE', (data) => ({ data }));
export const resetResponseScales = createAction('[RESPONSE_SCALES] RESET');

export const fetchResponseScalesAction = () => async (dispatch) => {
  dispatch(fetchResponseScales());

  try {
    const response = await apiInstance2.get(`/api/v2/360/response-scales`);

    if (response.status === 200) {
      dispatch(fetchResponseScalesSuccess(response.data));
    }
  } catch (err) {
    dispatch(fetchResponseScalesFailure());
  }
};

export const deleteResponseScalesAction = (data, cb) => async (dispatch) => {
  dispatch(deleteResponseScales());
  try {
    const response = await apiInstance2.delete(`/api/v2/360/response-scales`, { data });

    if (response.status === 200) {
      dispatch(deleteResponseScalesSuccess(data));
      createToastNotification({ message: I18n.t('scaleDeletionToast') });
      if (cb) cb();
    }
  } catch (err) {
    dispatch(deleteResponseScalesFailure());
    if (cb) cb();
  }
};

export const setSelectedScales = createAction('SET_SELECTED_SCALES', (scales) => ({
  scales,
}));
export const setActiveScale = createAction('SET_ACTIVE_SCALE', (scale) => ({ scale }));
