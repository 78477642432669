import { I18n } from 'react-redux-i18n';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/sidebar-edit-project.svg';

import * as actions from '../actionsList';

const welcomeTextsBulkActions = [
  {
    name: actions.EDIT_WELCOME_TEXT,
    label: I18n.t('Go to welcome text details'),
    Icon: EditIcon,
  },
  {
    name: actions.DELETE_WELCOME_TEXTS,
    label: I18n.t('Delete selected welcome text(s)'),
    Icon: DeleteIcon,
  },
];

export default welcomeTextsBulkActions;
