import React from 'react';
import { func, bool } from 'prop-types';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { I18n } from 'react-redux-i18n';
import Checkbox from './FormComponents/Checkbox';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning-blue.svg';

const DirectFeedbackReport = ({ isChecked, onChange, isDisabled }) => {
  return (
    <CheckboxHolder onClick={() => !isDisabled && onChange()} isDisabled={isDisabled}>
      <Checkbox isChecked={isChecked} disabled={isDisabled} />
      <span>{I18n.t('Send immediate feedback report to respondent')}</span>
      <WarningIcon data-tip data-for="info" />
      <StyledTooltip id="info">{I18n.t('Send immediate feedback info')}</StyledTooltip>
    </CheckboxHolder>
  );
};

DirectFeedbackReport.propTypes = {
  isChecked: bool.isRequired,
  onChange: func.isRequired,
  isDisabled: bool,
};

DirectFeedbackReport.defaultProps = {
  isDisabled: false,
};

const CheckboxHolder = styled.div`
  display: flex;
  align-items: center;
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  span {
    margin: 0 1rem;
    font-size: 1.4rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.darkBlue2};
  }

  :hover {
    cursor: ${(props) => !props.isDisabled && 'pointer'};
  }
`;

const StyledTooltip = styled(ReactTooltip)`
  width: 30rem;
`;

export default DirectFeedbackReport;
