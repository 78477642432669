import React, { useEffect, useState, useMemo } from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import PageWrapper from '../PageWrapper';
import TableTabs from '../reusable/TableTabs';
import SimplifiedTable from '../reusable/Tables/SimplifiedTable';
import Spinner from '../reusable/Spinner';
import BulkActionsSidebar from '../BulkActionsSidebar';
import ManageBatteryModal from './ManageBattery';
import ConfirmationModal from '../reusable/ConfirmationModal';

import useBulkSidebarState from '../../hooks/useBulkSidebarState';
import useModals from '../../hooks/useModals';

import { fetchTestsAndLanguages, deleteBatteries } from '../../store/user/actions';
import { selectAssessments } from '../../store/user/selectors';

import assessmentsColumns from '../../table/columns/assessments';
import assessmentsTableActions from '../../table/tableActions/assessments';
import assessmentsBulkActions from '../../table/bulkActions/assessments';
import * as actions from '../../table/actionsList';

const AssessmentsAndBatteries = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [isLoading, setLoadingStatus] = useState(true);
  const data = useSelector(selectAssessments);
  const [selectedAssessments, setSelectedAssessments] = useState([]);
  const allTests = useSelector((state) => state.user.tests);
  useEffect(() => {
    dispatch(fetchTestsAndLanguages(() => setLoadingStatus(false)));
  }, []);
  const tabs = [
    { name: 'assessments-and-batteries', label: I18n.t('Assessment and Batteries') },
    ...(user?.userType === 3 ? [] : [{ name: 'ideal-profiles', label: I18n.t('Ideal Profiles') }]),
    ...(user?.userType === 1 || user?.userType === 3 ? [] : [{ name: 'norms', label: I18n.t('Norms') }]),
  ];

  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedAssessments });

  const modals = [actions.ADD_CUSTOM_BATTERY, actions.DELETE_CUSTOM_BATTERY, actions.EDIT_CUSTOM_BATTERY];

  const { modalsState, openModal, closeModal } = useModals(modals);

  const bulkActionsHandlers = {
    [actions.EDIT_CUSTOM_BATTERY]: () => openModal(actions.EDIT_CUSTOM_BATTERY),
    [actions.DELETE_CUSTOM_BATTERY]: () => openModal(actions.DELETE_CUSTOM_BATTERY),
  };

  const onBatteriesDelete = () => {
    dispatch(
      deleteBatteries(
        selectedAssessments.map((item) => item.batteryID),
        () => closeModal(actions.DELETE_CUSTOM_BATTERY),
      ),
    );
  };

  const bulkActions = useMemo(() => {
    return assessmentsBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedAssessments) : item.isDisabled,
    }));
  }, [selectedAssessments]);

  const tableActions = assessmentsTableActions.map((item) => ({
    ...item,
    handler: () => {
      openModal(actions.ADD_CUSTOM_BATTERY);
    },
  }));

  return (
    <PageWrapper
      title={I18n.t('Assessment settings')}
      backButtonHandler={() => dispatch(push(`/settings`))}
      buttons={user?.userType !== 1 ? tableActions : []}
    >
      <Spinner isLoading={isLoading} full />
      <BulkActionsSidebar actions={bulkActions} isOpen={isSidebarOpen} onClose={closeSidebar} />
      <TableTabs
        tabs={tabs}
        activeTab={tabs[0]}
        clickHandler={(tab) => {
          dispatch(push(tab.name));
        }}
      />
      <Table
        data={data}
        columns={assessmentsColumns}
        entityName="assessment"
        onSelectChange={setSelectedAssessments}
        initialState={{ sortBy: [{ id: 'Name', desc: false }] }}
        idAccessor="batteryID"
        bottomOffset={70}
      />
      {modalsState[actions.ADD_CUSTOM_BATTERY] && (
        <ManageBatteryModal onClose={() => closeModal(actions.ADD_CUSTOM_BATTERY)} assessments={allTests} />
      )}
      {modalsState[actions.EDIT_CUSTOM_BATTERY] && (
        <ManageBatteryModal
          onClose={() => closeModal(actions.EDIT_CUSTOM_BATTERY)}
          assessments={allTests}
          battery={selectedAssessments.length ? selectedAssessments[0] : null}
        />
      )}

      <ConfirmationModal
        isVisible={modalsState[actions.DELETE_CUSTOM_BATTERY]}
        title={selectedAssessments.length === 1 ? I18n.t('Delete battery') : I18n.t('Delete batteries')}
        description={`${I18n.t('Are you sure you want to delete')} ${
          selectedAssessments.length === 1 ? I18n.t('this battery?') : I18n.t('selected batteries?')
        }`}
        onClose={() => closeModal(actions.DELETE_CUSTOM_BATTERY)}
        onConfirm={onBatteriesDelete}
      />
    </PageWrapper>
  );
};

const Table = styled(SimplifiedTable)`
  //padding-bottom: 7rem;
`;

export default AssessmentsAndBatteries;
