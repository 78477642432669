import React from 'react';
import styled from 'styled-components';

const Counter = ({ value, setValue }) => {
  return (
    <CounterWrapper>
      <Button
        type="button"
        onClick={() => {
          if (value > 0) {
            setValue(value - 1);
          }
        }}
      >
        -
      </Button>
      <CounterValue>{value}</CounterValue>
      <Button
        type="button"
        onClick={() => {
          setValue(value + 1);
        }}
      >
        +
      </Button>
    </CounterWrapper>
  );
};

const CounterValue = styled.div`
  min-width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CounterWrapper = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-left: 20px;
`;

const Button = styled.button`
  background: #dff6ff;
  width: 28px;
  height: 28px;
  line-height: 28px;
  border: none;
  border-radius: 50%;
  color: #375563;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  outline: none;

  &:hover {
    cursor: pointer;
  }
`;

export default Counter;
