import React from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import { ReactComponent as ArrowRight } from '../../../../assets/icons/arrow-right-thin.svg';
import ProjectModalFooter from '../ProjectModalFooter';
import CustomButton from '../../../reusable/Buttons/Button';

const RulesStep = ({ goBackToProjectSelection, goNext }) => {
  return (
    <Container>
      <Title>{I18n.t('Project rules')}</Title>
      <RulesWrapper>
        <RuleBlock>
          <SubTitle>{I18n.t('Invites sending')}</SubTitle>
          <Paragraph>
            {I18n.t('Select assessment, language and email template you want to use')}.{' '}
            {I18n.t(
              'Choose whether invites should go out on the scheduled date, get sent once respondent gets added to the project or manually with pre-selected test and template',
            )}
            .
          </Paragraph>
        </RuleBlock>
        <RuleBlock>
          <SubTitle>{I18n.t('Reports generation')}</SubTitle>
          <Paragraph>
            {I18n.t(
              'Reports selected during project creation will be automatically generated after respondent submits their results',
            )}
            .{' '}
            {I18n.t(
              'Direct feedback report may be activated, if available for the selected test too (check for details with your local distributor)',
            )}
            .
          </Paragraph>
        </RuleBlock>
        <RuleBlock>
          <SubTitle>{I18n.t('Credits usage')}</SubTitle>
          <Paragraph>
            {I18n.t(
              'To ensure you have enough credits to get reports generated automatically, we are freezing the required cost per respondent once they get added to the project',
            )}
            .
          </Paragraph>
        </RuleBlock>
      </RulesWrapper>
      <Footer>
        <Button handler={goBackToProjectSelection} variant="secondary">
          <ArrowLeft />
          {I18n.t('Back')}
        </Button>
        <Button handler={goNext}>
          {I18n.t('Continue')} <ArrowRight />
        </Button>
      </Footer>
    </Container>
  );
};

RulesStep.propTypes = {
  goBackToProjectSelection: func.isRequired,
  goNext: func.isRequired,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Title = styled.h3`
  margin-left: 2.4rem;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  color: ${(props) => props.theme.colors.mediumBlue};
  letter-spacing: 1.5px;
`;

const RulesWrapper = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 2.4rem;
  flex-grow: 1;
`;

const RuleBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const SubTitle = styled.h5`
  font-size: ${(props) => props.theme.fontSizes.normal};
  font-weight: 600;
  color: ${(props) => props.theme.colors.darkBlue2};
  margin-bottom: 0.8rem;
`;

const Paragraph = styled.p`
  font-size: 14px;
  color: ${(props) => props.theme.colors.halfBlack};
`;

const Footer = styled(ProjectModalFooter)`
  justify-content: space-between;
`;

const ArrowLeft = styled(ArrowRight)`
  transform: rotate(180deg);
  path {
    fill: ${(props) => props.theme.colors.lightBlue};
  }
`;

const Button = styled(CustomButton)`
  text-transform: uppercase;
  width: 14rem;
  justify-content: space-evenly;
`;

export default RulesStep;
