import { I18n } from 'react-redux-i18n';
import { ADD_RESPONDENT, IMPORT_RESPONDENTS } from '../actionsList';

const respondentsTableActions = [
  {
    name: ADD_RESPONDENT,
    label: I18n.t('Add new respondent'),
  },
  {
    name: IMPORT_RESPONDENTS,
    label: I18n.t('Import respondents'),
  },
];

export default respondentsTableActions;
