import React from 'react';
import styled from 'styled-components';
import { bool, func } from 'prop-types';

import { ReactComponent as CheckIcon } from '../../../assets/icons/check-white.svg';

const Checkbox = ({ isChecked, clickHandler, disabled, ...props }) => {
  return (
    <>
      <Styled
        isActive={isChecked}
        onClick={disabled || !clickHandler ? undefined : clickHandler}
        disabled={disabled}
        {...props}
      >
        {isChecked && <CheckIcon />}
      </Styled>
    </>
  );
};

Checkbox.propTypes = {
  isChecked: bool.isRequired,
  clickHandler: func,
  disabled: bool,
};

Checkbox.defaultProps = {
  disabled: false,
  clickHandler: undefined,
};

const Styled = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.isActive ? props.theme.colors.primary : props.theme.colors.white)};
  border: 1px solid ${(props) => (props.isActive ? 'transparent' : props.theme.colors.grey4)};
  border-radius: 2px;
  transition: all 0.2s;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  :hover {
    border-color: ${(props) => !props.disabled && !props.isActive && props.theme.colors.mediumBlue};
    cursor: pointer;
  }
`;

export default Checkbox;
