import React, { memo, useEffect } from 'react';
import { useFormik } from 'formik';
import { object, func, bool } from 'prop-types';
import * as Yup from 'yup';
import styled from 'styled-components';
import { formatISO } from 'date-fns';
import { I18n } from 'react-redux-i18n';
import CustomInput from '../reusable/FormComponents/Input';
import CustomSelect from '../reusable/Selects/Select';
import DateSelect from '../reusable/FormComponents/DateSelect';
import CustomButton from '../reusable/Buttons/Button';
import ConfirmationModal from '../reusable/ConfirmationModal';
import isChineseEnv from '../../utils/isChineseEnv';

import TagsManagement from './TagsManagement';

import useEthnicityOptions from '../../hooks/useEthnicityOptions';

import {
  educationOptions,
  occupationOptions,
  sectorOptions,
  industryOptions,
  languageOptions,
  genderOptions,
} from '../../constants/respondentSelectOptions';
import { errorMessages } from '../../constants/errorMessages';
import createToastNotification from '../../utils/createToastNotification';

const allOccupationOptions = occupationOptions.reduce((acc, item) => {
  return [...acc, ...item.options];
}, []);

const InformationFormScheme = Yup.object().shape({
  firstName: Yup.string().required(errorMessages.inputField),
  ...(isChineseEnv ? {} : { familyName: Yup.string().required(errorMessages.inputField) }),
  title: Yup.string().max(20),
});

const OptimizedInput = memo(CustomInput, (prevProps, nextProps) => {
  return prevProps.value === nextProps.value && prevProps.error === nextProps.error;
});

const OptimizedSelect = memo(CustomSelect, (prevProps, nextProps) => {
  return prevProps.selectProps.value === nextProps.selectProps.value;
});

const initValues = {
  firstName: '',
  familyName: '',
  sex: 'U',
  email: '',
  title: '',
  dob: null,
  reference: '',
  company: '',
  education: '',
  ethnicity: '',
  jobArea: '',
  sector: '',
  industry: '',
  language: '',
};

const findInitValue = (options, respValue) => {
  const correspondingObj = options.find((item) => item.value === respValue);
  return respValue && respValue !== 'null' && correspondingObj ? correspondingObj.value : null;
};

const prepareDataToSubmit = (values) => ({
  ...values,
  dob: values.dob ? formatISO(values.dob, { representation: 'date' }) : null,
  education: values.education || '',
  ethnicity: values.ethnicity || '',
  industry: values.industry || '',
  jobArea: values.jobArea || '',
  firstLanguage: values.firstLanguage || '',
  sector: values.sector || '',
});

const Information = ({
  respondent,
  editRespondent,
  dirtyStateHandler,
  isConfirmModalVisible,
  proceedTabNavigation,
  closeConfirmModal,
}) => {
  const ethnicityOptions = useEthnicityOptions();

  const initialValues = {
    ...initValues,
    firstName: isChineseEnv ? `${respondent.familyName}${respondent.firstName}` : respondent.firstName,
    familyName: isChineseEnv ? '' : respondent.familyName,
    sex:
      respondent.sex && respondent.sex !== 'N/A'
        ? genderOptions.find((item) => item.value === respondent.sex).value
        : 'U',
    dob: respondent.dob ? new Date(respondent.dob) : null,
    title: respondent.title,
    email: respondent.email,
    reference: respondent.reference,
    company: respondent.company,
    education: findInitValue(educationOptions, respondent.education),
    ethnicity: findInitValue(ethnicityOptions, respondent.ethnicity),
    jobArea: findInitValue(allOccupationOptions, respondent.jobArea),
    sector: findInitValue(sectorOptions, respondent.sector),
    industry: findInitValue(industryOptions, respondent.industry),
    firstLanguage: findInitValue(languageOptions, respondent.firstLanguage),
  };
  const { handleChange, values, errors, touched, setFieldValue, dirty, resetForm, handleSubmit, isValid } = useFormik({
    validationSchema: InformationFormScheme,
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues,
    onSubmit: (submittedValues) => {
      const preparedData = prepareDataToSubmit(submittedValues);

      editRespondent(respondent.respondentID, preparedData, (e) => {
        if (!e) resetForm(submittedValues);
      });
    },
  });
  useEffect(() => {
    dirtyStateHandler(dirty);
    return () => dirtyStateHandler(false);
  }, [dirty]);

  const onInputChange = (e) => {
    handleChange(e);
  };

  const onSelectChange = (fieldName) => (data) => {
    setFieldValue(fieldName, data.value);
  };

  const onConfirm = () => {
    if (!isValid) {
      createToastNotification({ type: 'error', message: `${I18n.t('correctDataToast')}.` });
      return closeConfirmModal();
    }
    const preparedData = prepareDataToSubmit(values);
    editRespondent(respondent.respondentID, preparedData, (e) => {
      if (!e) {
        proceedTabNavigation();
        closeConfirmModal();
      }
    });
  };
  const onCancel = () => {
    proceedTabNavigation();
    closeConfirmModal();
  };

  return (
    <Container>
      <FormContainer>
        <ConfirmationModal
          isVisible={isConfirmModalVisible}
          title={I18n.t('Warning')}
          description={`${I18n.t('It looks like you have been editing something')}. ${I18n.t(
            'Would you like to save your changes before leaving',
          )}?`}
          onConfirm={onConfirm}
          onClose={onCancel}
        />
        <StyledForm onSubmit={handleSubmit}>
          <ColumnTitle>{I18n.t('Main Information')}</ColumnTitle>
          <StyledInput
            inputName={I18n.t('PersonName')}
            isRequired
            error={errors.firstName}
            touched={touched}
            value={values.firstName}
            name="firstName"
            id="firstName"
            onChange={onInputChange}
          />
          {!isChineseEnv && (
            <StyledInput
              inputName={I18n.t('Family Name')}
              isRequired
              error={errors.familyName}
              touched={touched}
              value={values.familyName}
              onChange={onInputChange}
              name="familyName"
              id="familyName"
            />
          )}
          <GenderSelect
            placeholder={I18n.t('Sex')}
            options={genderOptions}
            selectProps={{
              onChange: onSelectChange('sex'),
              value: genderOptions.find((item) => item.value === values.sex) || null,
              menuPosition: 'fixed',
            }}
          />
          <StyledInput
            inputName={I18n.t('BioTitle')}
            value={values.title}
            onChange={onInputChange}
            name="title"
            id="title"
            error={errors.title}
            touched={touched}
          />
          <StyledInput
            inputName={I18n.t('Email')}
            error={errors.email}
            touched={touched}
            value={values.email}
            onChange={onInputChange}
            name="email"
            id="email"
          />
          <StyledDatepicker
            label={I18n.t('Date of Birth')}
            selectedDate={values.dob}
            onDateChange={(date) => setFieldValue('dob', date)}
          />
          <StyledInput
            inputName={I18n.t('Reference')}
            value={values.reference}
            onChange={onInputChange}
            name="reference"
            id="reference"
            error={errors.reference}
            touched={touched}
          />
          <ColumnTitle>{I18n.t('Additional Info')}</ColumnTitle>
          <StyledInput
            inputName={I18n.t('Company')}
            value={values.company}
            onChange={onInputChange}
            name="company"
            id="company"
            error={errors.company}
            touched={touched}
          />
          <StyledSelect
            placeholder={I18n.t('Education')}
            options={educationOptions}
            selectProps={{
              onChange: onSelectChange('education'),
              value: educationOptions.find((item) => item.value === values.education) || null,
              menuPosition: 'fixed',
            }}
          />
          {!isChineseEnv && (
            <StyledSelect
              options={ethnicityOptions}
              placeholder={I18n.t('Ethnicity')}
              selectProps={{
                onChange: onSelectChange('ethnicity'),
                value: ethnicityOptions.find((item) => item.value === values.ethnicity) || null,
                menuPosition: 'fixed',
              }}
            />
          )}
          <StyledSelect
            options={allOccupationOptions}
            placeholder={I18n.t('Job area')}
            selectProps={{
              onChange: onSelectChange('jobArea'),
              value: allOccupationOptions.find((item) => item.value === values.jobArea) || null,
              menuPosition: 'fixed',
            }}
          />
          <StyledSelect
            options={sectorOptions}
            placeholder={I18n.t('Sector')}
            selectProps={{
              onChange: onSelectChange('sector'),
              value: sectorOptions.find((item) => item.value === values.sector) || null,
              menuPosition: 'fixed',
            }}
          />

          <StyledSelect
            options={industryOptions}
            placeholder={I18n.t('Industry')}
            selectProps={{
              onChange: onSelectChange('industry'),
              value: industryOptions.find((item) => item.value === values.industry) || null,
              menuPosition: 'fixed',
            }}
          />
          {!isChineseEnv && (
            <StyledSelect
              options={languageOptions}
              placeholder={I18n.t('First Language')}
              selectProps={{
                onChange: onSelectChange('firstLanguage'),
                value: languageOptions.find((item) => item.value === values.firstLanguage) || null,
                menuPosition: 'fixed',
              }}
            />
          )}
        </StyledForm>
        <StyledTagsSection tags={respondent.tags} respondent={respondent} />
      </FormContainer>
      <FormControlButtons>
        <CustomButton onClick={handleSubmit} disabled={!dirty} type="submit">
          {I18n.t('Save changes')}
        </CustomButton>
      </FormControlButtons>
    </Container>
  );
};

Information.propTypes = {
  respondent: object.isRequired,
  editRespondent: func.isRequired,
  dirtyStateHandler: func.isRequired,
  isConfirmModalVisible: bool.isRequired,
  proceedTabNavigation: func.isRequired,
  closeConfirmModal: func.isRequired,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  margin-bottom: 3rem;
`;

const FormContainer = styled.div`
  display: flex;
  overflow: auto;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
`;

const ColumnTitle = styled.h2`
  font-size: ${(props) => props.theme.fontSizes.normal};
  margin: ${(props) => props.marginTop || 0} 0 20px 0;
  font-weight: bold;
  color: ${(props) => props.theme.colors.darkBlue2};
  padding-bottom: 1.6rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey5};
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 48%;
  width: 100%;
`;

const StyledInput = styled(OptimizedInput)`
  margin-bottom: 2.5rem;
  width: 70%;
  min-height: 4rem;
`;

const StyledSelect = styled(OptimizedSelect)`
  margin-bottom: 2.5rem;
  width: 70%;
  min-height: 4rem;
`;

const GenderSelect = styled(StyledSelect)`
  width: 40%;
`;

const StyledDatepicker = styled(DateSelect)`
  margin-bottom: 2.5rem;
  min-height: 4rem;
`;

const FormControlButtons = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTagsSection = styled(TagsManagement)`
  width: 48%;
`;

export default Information;
