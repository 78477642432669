import { useState, useEffect } from 'react';

const useBulkSidebarState = ({ items }) => {
  const [isSidebarOpen, setSidebarState] = useState(false);
  const openSidebar = () => setSidebarState(true);
  const closeSidebar = () => setSidebarState(false);
  useEffect(() => {
    if (items.length > 0 && !isSidebarOpen) openSidebar();
    if (items.length < 1 && isSidebarOpen) closeSidebar();
  }, [items.length]);
  return { isSidebarOpen, closeSidebar };
};

export default useBulkSidebarState;
