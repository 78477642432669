import React from 'react';
import styled from 'styled-components';
import { ReactComponent as WarningIcon } from '../../assets/icons/no-files-for-import-warning.svg';

const ImportEmptyMessage = () => {
  return (
    <EmptyMessageWrapper>
      <WarningIcon />
      <EmptyMessageTitle>No records found</EmptyMessageTitle>
      <span>Please make sure file is populated with data</span>
    </EmptyMessageWrapper>
  );
};

const EmptyMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;
  color: ${(props) => props.theme.colors.darkBlue2};
`;

const EmptyMessageTitle = styled.h5`
  font-size: ${(props) => props.theme.fontSizes.medium};
  font-weight: bold;
  color: ${(props) => props.theme.colors.mediumBlue};
  margin-top: 1.5rem;
  margin-bottom: 1rem;
`;

export default ImportEmptyMessage;
