import handleActions from '../immerHandleActions';
import * as actions from './actions';
import sessionsColumns from '../../table/columns/sessions';
import {
  DATE_FILTER_TYPES,
  datesFilterOptions,
  NUMBER_FILTER_TYPES,
  numberFilterOptions,
  TEXT_FILTER_TYPES,
  textFilterOptions,
} from '../../constants/advancedFilterOptions';

const getInitialFilterValue = (type) => {
  if (type === 'number') return numberFilterOptions.find((item) => item.name === NUMBER_FILTER_TYPES.IS_EQUAL);
  if (type === 'date') return datesFilterOptions.find((item) => item.name === DATE_FILTER_TYPES.THREE_DAYS);
  return textFilterOptions.find((item) => item.name === TEXT_FILTER_TYPES.CONTAINS);
};

const SESSIONS_FILTER_FIELDS = sessionsColumns()
  .filter((item) => item.name !== 'actions')
  .filter((item) => !item.disableFilterBy)
  .map((column) => ({ name: column.name, type: column.type }));

const initialInputsState = SESSIONS_FILTER_FIELDS.map((fieldObj) => ({
  ...fieldObj,
  name: fieldObj.name,
  value: fieldObj.type === 'date' ? null : '',
  type: fieldObj.type,
  filterType: getInitialFilterValue(fieldObj.type),
}));

const initialFilters = {
  isApplied: false,
  dirty: false,
  appliedInputs: initialInputsState,
  inputs: initialInputsState,
};

const initialState = {
  sessions: [],
  selectedSessions: [],
  search: '',
  pagesAvailable: 0,
  totalCount: 0,
  lastPageIndex: null,
  filter: initialFilters,
  languages: [],
};

const sessionsReducer = handleActions(
  {
    // FILTERS PART
    [actions.setSessionsSearchValue]: (draft, { payload: { searchValue } }) => {
      draft.search = searchValue;
    },
    [actions.setSessionsFilterValue]: (draft, { payload: { fieldName, value } }) => {
      draft.filter.inputs = draft.filter.inputs.map((item) => (item.name === fieldName ? { ...item, value } : item));
      draft.filter.dirty = true;
    },
    [actions.setSessionsFilterType]: (draft, { payload: { fieldName, filterType } }) => {
      const initValue = getInitialFilterValue(
        sessionsColumns().find((item) => item.name.toLowerCase() === fieldName.toLowerCase()).type,
      );
      draft.filter.inputs = draft.filter.inputs.map((item) =>
        item.name === fieldName ? { ...item, filterType: filterType === 'initial' ? initValue : filterType } : item,
      );
      draft.filter.dirty = true;
    },
    [actions.applySessionsFilters]: (draft) => {
      draft.filter.dirty = false;
      draft.filter.isApplied = true;
      draft.filter.appliedInputs = draft.filter.inputs;
    },
    [actions.clearSessionsFilters]: (draft) => {
      draft.filter.dirty = true;
      draft.filter.inputs = initialInputsState;
    },
    [actions.deleteSessionsAppliedFilter]: (draft, { payload: { fieldName } }) => {
      draft.filter.appliedInputs = draft.filter.appliedInputs.map((input) =>
        input.name === fieldName ? { ...input, value: input.type === 'date' ? null : '' } : input,
      );
      draft.filter.inputs = draft.filter.inputs.map((input) =>
        input.name === fieldName ? { ...input, value: input.type === 'date' ? null : '' } : input,
      );
    },
    [actions.resetSessionsTableState]: (draft) => {
      draft.search = '';
      draft.filter = { ...initialFilters };
      draft.shouldResetTableState = true;
    },
    [actions.fetchSessionsSuccess]: (draft, { payload: { data, shouldReset } }) => {
      draft.sessions = shouldReset ? data.data : [...draft.sessions, ...data.data];
      draft.totalCount = data.totalCount;
      draft.pagesAvailable = data.pagesAvailable;
      draft.lastPageIndex = data.pageNumber;
    },
    [actions.setSelectedSessions]: (draft, { payload: { sessions } }) => {
      draft.selectedSessions = sessions;
    },
    [actions.deleteSessionsSuccess]: (draft, { payload: { sessions } }) => {
      draft.sessions = draft.sessions.filter((resp) => sessions.indexOf(resp.sessionId) === -1);
    },
    [actions.setActiveSession]: (draft, { payload: { session } }) => {
      draft.activeSessionInView = session;
    },
    [actions.setSessionSelf]: (draft, { payload: { respondent } }) => {
      draft.activeSelfInView = respondent;
    },
    [actions.fetchSessionInviteLanguagesSuccess]: (draft, { payload: { languages } }) => {
      draft.languages = languages;
    },
  },
  initialState,
);

export default sessionsReducer;
