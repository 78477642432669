import React, { useState, useEffect } from 'react';
import { func, oneOfType, string, number } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import AddRespondentForm from './AddRespondentForm';
import NewModal from '../../reusable/NewModal';
import TableTabs from '../../reusable/TableTabs';
import { fetchAllGroups } from '../../../store/groups/actions';

const tabs = [
  {
    name: 'mainDetails',
    label: I18n.t('Main Details'),
  },
  {
    name: 'extraDetails',
    label: I18n.t('Extra Details'),
  },
  {
    name: 'tags',
    label: I18n.t('Tags'),
  },
  {
    name: 'groups',
    label: I18n.t('Group Membership'),
  },
];

const AddRespondentModal = ({ onClose, groupId }) => {
  const allTags = useSelector((state) => state.user.tags);
  const allGroups = useSelector((state) => state.groups.allGroups);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllGroups());
    setActiveTab(tabs[0]);
  }, []);

  const onTabClick = (tab) => setActiveTab(tab);

  return (
    <StyledModal onClose={onClose} isVisible title={I18n.t('Add respondent')}>
      <TableTabs tabs={tabs} activeTab={activeTab} clickHandler={onTabClick} />
      <AddRespondentForm
        onSubmit={onClose}
        closeModal={onClose}
        activeTab={activeTab}
        allTags={allTags}
        allGroups={allGroups}
        groupId={groupId}
        resetToInitialTab={() => setActiveTab(tabs[0])}
      />
    </StyledModal>
  );
};

AddRespondentModal.propTypes = {
  onClose: func.isRequired,
  groupId: oneOfType([string, number]),
};

AddRespondentModal.defaultProps = {
  groupId: '',
};

const StyledModal = styled(NewModal)`
  display: flex;
  flex-direction: column;
  width: 80rem;
  max-height: 70rem;
  height: 100%;
  overflow: auto;
`;

export default AddRespondentModal;
