import { format } from 'date-fns';

import apiInstance2 from '../../services/apiService';

const processResponse = (response, callback) => {
  if (response.status === 200) {
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    const date = format(new Date(), 'yyyy_MM_dd_HH_mm_ss');
    const name = `psytech_genesys_export_${date}.zip`;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
    if (callback) callback();
  }
};

const processCsvResponse = (response, { name: projectName, assessmentName }) => {
  if (response.status === 200) {
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    const date = format(new Date(), 'yyyy_MM_dd');
    const name = `genesys2020_${projectName.toLowerCase()}_${assessmentName.toLowerCase()} invites_${date}.csv`;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
};

const downloadZippedArchiveFromReports = async (reports, cb) => {
  try {
    const ids = reports.map((report) => (report.reportType === 1 ? report.requestID : report.reportRequestID));
    const query = ids.reduce((acc, id) => `${acc}requestIds=${id}&`, '');
    const response = await apiInstance2.get(`/api/v2/Reports/download?${query}`, { responseType: 'blob' });
    processResponse(response, cb);
  } catch (e) {
    if (cb) cb(null, e);
  }
};

export const downloadZippedAssessmentProjectReports = async (projectId, cb) => {
  try {
    const response = await apiInstance2.get(`/api/v2/projects/assessments/${projectId}/reports/download`, {
      responseType: 'blob',
    });
    processResponse(response, cb);
  } catch (e) {
    if (cb) cb(null, e);
  }
};

export const downloadAssessmentProjectInvites = async (project) => {
  try {
    const response = await apiInstance2.get(`/api/v2/projects/assessments/download-csv/${project.projectId}`, {
      responseType: 'blob',
    });
    processCsvResponse(response, project);
  } catch (e) {
    console.error('Error during invites import');
  }
};

export const downloadAssessmentProjectRespondentReports = async (projectId, respondentId, cb) => {
  try {
    const response = await apiInstance2.get(
      `/api/v2/projects/assessments/${projectId}/respondents/${respondentId}/reports/download`,
      {
        responseType: 'blob',
      },
    );
    processResponse(response, cb);
  } catch (e) {
    if (cb) cb(null, e);
  }
};

export default downloadZippedArchiveFromReports;
