import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { array, bool, func, string, any } from 'prop-types';
import styled from 'styled-components';

import CustomButton from './Buttons/Button';
import { ReactComponent as DotsSvg } from '../../assets/icons/dots.svg';

const ActionsDropdown = ({
  actions,
  clickHandler,
  disabled,
  color,
  bigVariant,
  customTrigger,
  smallSize,
  className,
}) => {
  const [isOpen, changeState] = useState(false);
  const toggleState = () => changeState((prev) => !prev);

  const onDotsClick = (e) => {
    e.stopPropagation();
    if (disabled) return;
    toggleState();
    if (clickHandler) clickHandler();
  };

  const DefaultTrigger = ({ disabled }) => (
    <Dots smallSize={smallSize} disabled={disabled} onClick={onDotsClick}>
      <Dot dotColor={color} />
      <Dot dotColor={color} />
      <Dot dotColor={color} />
    </Dots>
  );

  const ButtonTrigger = ({ disabled }) => (
    <CustomButton disabled={disabled} handler={onDotsClick}>
      <DotsSvg />
    </CustomButton>
  );

  const ResolvedDefaultTrigger = bigVariant ? ButtonTrigger : DefaultTrigger;
  const Trigger = customTrigger || ResolvedDefaultTrigger;

  return (
    <OutsideClickHandler onOutsideClick={() => changeState(false)}>
      <Wrapper className={className}>
        <Trigger disabled={disabled} handler={onDotsClick} isOpen={isOpen} />
        {isOpen && (
          <ActionsContainer bigVariant={bigVariant} smallSize={smallSize}>
            {actions.map((item) => {
              return (
                <Action
                  key={`${item.name}${item.label}`}
                  borderBottom={item.borderBottom}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (item.isDisabled) return;
                    if (item.handler) item.handler();
                    changeState(false);
                  }}
                  isDisabled={item.isDisabled}
                >
                  <span> {item.label}</span>
                </Action>
              );
            })}
          </ActionsContainer>
        )}
      </Wrapper>
    </OutsideClickHandler>
  );
};

ActionsDropdown.propTypes = {
  actions: array.isRequired,
  clickHandler: func,
  color: string,
  disabled: bool,
  bigVariant: bool,
  smallSize: bool,
  className: string,
  customTrigger: any,
};

ActionsDropdown.defaultProps = {
  disabled: false,
  color: 'mediumBlue',
  bigVariant: false,
  smallSize: false,
  clickHandler: undefined,
  className: '',
  customTrigger: null,
};

const Wrapper = styled.div`
  position: relative;
`;

const Dot = styled.div`
  width: 0.4rem;
  height: 0.4rem;
  min-width: 0.4rem;
  min-height: 0.4rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors[props.dotColor]};
  margin-bottom: 0.2rem;
  transition: all 0.2s;
`;

const Dots = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: ${(props) => (!props.smallSize ? '2.8rem' : '28px')};
  height: ${(props) => (!props.smallSize ? '2.8rem' : '28px')};
  border-radius: 50%;
  transition: all 0.2s;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  :hover {
    background-color: ${(props) => props.theme.colors.lightBlue1};
  }
  :active {
    background-color: ${(props) => props.theme.colors.darkBlue};
    ${Dot} {
      background-color: ${(props) => props.theme.colors.white};
    }
  }
`;

const ActionsContainer = styled.ul`
  position: absolute;
  width: 34rem;
  z-index: 10;
  top: ${(props) => (props.bigVariant ? '4.5rem' : '3rem')};
  right: 1rem;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  border-radius: 0.6rem;
  box-shadow: ${(props) => props.theme.shadows.default};
`;

const Action = styled.li`
  list-style: none;
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 600;
  padding: 0.8rem 0;
  :hover {
    color: ${(props) => props.theme.colors.lightBlue};
    cursor: pointer;
  }

  border-bottom: ${(props) => (props.borderBottom ? `1px solid ${props.theme.colors.grey}` : 'none')};
  span {
    opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  }
`;

export default ActionsDropdown;
