import { I18n } from 'react-redux-i18n';
import * as actions from '../../../table/actionsList';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';

const responseScalesBulkActions = [
  {
    name: actions.REMOVE_RESPONSE_SCALES,
    label: I18n.t('Delete selected response scale(s)'),
    Icon: DeleteIcon,
  },
];

export default responseScalesBulkActions;
