import React from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { I18n } from 'react-redux-i18n';
import NewModal from '../../reusable/NewModal';
import CustomInput from '../../reusable/FormComponents/Input';
import CustomButton from '../../reusable/Buttons/Button';

import { errorMessages } from '../../../constants/errorMessages';
import { changePin } from '../../../store/user/actions';

const ChangePinModal = ({ onClose }) => {
  const dispatch = useDispatch();

  const { setFieldValue, setFieldError, values, errors, handleSubmit } = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: {
      newPin: '',
      confirmNewPin: '',
    },
    validationSchema: Yup.object().shape({
      newPin: Yup.string()
        .required(errorMessages.inputField)
        .length(4, errorMessages.equals(4)),
      confirmNewPin: Yup.string()
        .required(errorMessages.inputField)
        .length(4, errorMessages.equals(4))
        .oneOf([Yup.ref('newPin'), null], I18n.t('Pins must match')),
    }),
    onSubmit: (values) => {
      dispatch(
        changePin(values.newPin, () => {
          onClose();
        }),
      );
    },
  });

  return (
    <Modal isVisible onClose={onClose} title={I18n.t('Change pin')}>
      <Form>
        <StyledInput
          inputName={I18n.t('New pin')}
          value={values.newPin}
          onChange={(e) => {
            setFieldValue('newPin', e.target.value);
            setFieldError('newPin', '');
          }}
          name="newPin"
          error={errors.newPin}
          type="number"
        />
        <StyledInput
          inputName={I18n.t('Confirm new pin')}
          value={values.confirmNewPin}
          onChange={(e) => {
            setFieldValue('confirmNewPin', e.target.value);
            setFieldError('confirmNewPin', '');
          }}
          name="confirmNewPin"
          error={errors.confirmNewPin}
          type="number"
        />
      </Form>
      <ButtonsWrapper>
        <StyledButton onClick={handleSubmit}>{I18n.t('Change')}</StyledButton>
      </ButtonsWrapper>
    </Modal>
  );
};

ChangePinModal.propTypes = {
  onClose: func.isRequired,
};

const Modal = styled(NewModal)`
  display: flex;
  flex-direction: column;
  min-height: 40rem;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
const StyledInput = styled(CustomInput)`
  margin-bottom: 2rem;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledButton = styled(CustomButton)`
  text-transform: uppercase;
`;

export default ChangePinModal;
