import { sortBy } from 'lodash';

import { resolveAssessmentName } from '../../../utils/assessmentsNames';

const createTestsOptions = (assessments) => {
  const labeledList = assessments.map((assessment) => {
    const label = resolveAssessmentName(assessment);
    const disabled = assessment.usesDisabledTest || assessment.disabledTest;
    return {
      ...assessment,
      label: disabled ? `${label} (Flash only)` : label,
      value: assessment.batteryID ? assessment.batteryID : assessment.testID,
      isDisabled: disabled,
    };
  });
  return sortBy(labeledList, 'label');
};

export default createTestsOptions;
