import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export default {
  init: () => {
    Sentry.init({
      dsn: 'https://bac53693eb5240e69c1dbbf444e7e103@o508797.ingest.sentry.io/4504835313369088',
      integrations: [new BrowserTracing()],
      tracesSampleRate: process.env.REACT_APP_PSEUDO_NODE_ENV === 'production' ? 0.01 : 1.0,
      autoSessionTracking: true,
      environment: process.env.REACT_APP_PSEUDO_NODE_ENV,
    });
  },
};
