import { useReducer } from 'react';

const reducer = (state, action) => {
  switch (action.type) {
    case 'changeModalState':
      return { ...state, [action.name]: action.state };
    default:
      return state;
  }
};

const useModals = (list) => {
  const initialState = Array.isArray(list)
    ? list.reduce((acc, item) => {
        acc[item] = false;
        return acc;
      }, {})
    : list;
  const [state, dispatch] = useReducer(reducer, initialState);

  const openModal = (name) => {
    dispatch({ type: 'changeModalState', name, state: true });
  };
  const closeModal = (name) => {
    dispatch({ type: 'changeModalState', name, state: false });
  };

  return {
    openModal,
    closeModal,
    modalsState: state,
  };
};

export default useModals;
