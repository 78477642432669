import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
// import { useDispatch } from 'react-redux';
import RatersTableWrapper from '../components/RatersTableWrapper';
import SimplifiedTable from '../../reusable/Tables/SimplifiedTable';
import Spinner from '../../reusable/Spinner';
import BulkActionsSidebar from '../../BulkActionsSidebar';

import apiInstance2 from '../../../services/apiService';
import * as actions from '../../../table/actionsList';

import ratersResultsColumns from '../../../table/columns/ratersResults';
import ratersResultsBulkActions from '../../../table/bulkActions/ratersResults';
// import { deleteRespondentResults } from '../../../store/respondents/actions';

import useBulkSidebarState from '../../../hooks/useBulkSidebarState';
import useModals from '../../../hooks/useModals';
import ConfirmationModal from '../../reusable/ConfirmationModal';
import TableEmptyState from '../../reusable/TableEmptyState';

const RatersResults = ({ projectId, sessionId }) => {
  // const dispatch = useDispatch();
  const [results, setResults] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [selectedResults, setSelectedResults] = useState([]);

  useEffect(() => {
    setLoading(true);
    apiInstance2
      .get(`/api/v2/360/projects/${projectId}/sessions/costs?sessionIds=${sessionId}`)
      .then((response) => {
        setLoading(false);
        const results = [
          ...[response.data[0].self].filter((item) => item.completedDate).map((item) => ({ ...item, role: 0 })),
          ...response.data[0].raters.filter((item) => item.completedDate),
        ];
        setResults(results);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const modals = [actions.DELETE_COMPLETED_TEST, actions.REQUEST_REPORT];
  const { modalsState, openModal, closeModal } = useModals(modals);

  // const onDeleteConfirm = () => {
  //   dispatch(
  //     deleteRespondentResults(
  //       selectedResults.map((item) => item.resultID),
  //       null,
  //       () => {
  //         closeModal(actions.DELETE_COMPLETED_TEST);
  //       },
  //     ),
  //   );
  // };

  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedResults });

  // BULK ACTIONS
  const bulkActionsHandlers = {
    [actions.REQUEST_REPORT]: () => openModal(actions.REQUEST_REPORT),
    [actions.DELETE_COMPLETED_TEST]: () => openModal(actions.DELETE_COMPLETED_TEST),
  };
  const bulkActions = useMemo(() => {
    return ratersResultsBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedResults) : item.isDisabled,
    }));
  }, [selectedResults]);

  return (
    <RatersTableWrapper projectId={projectId} sessionId={sessionId} activeTab={2}>
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      <TableWrapper>
        <SimplifiedTable
          columns={ratersResultsColumns}
          data={results}
          initialState={{ sortBy: [{ id: 'Completed Date', desc: true }] }}
          onSelectChange={setSelectedResults}
          idAccessor="resultID"
          entityName="result"
          bottomOffset={80}
          customEmptyMessage={<CustomEmptyState title={I18n.t('There are no results in this session yet')} />}
        />
      </TableWrapper>
      <ConfirmationModal
        title="Result deletion"
        onClose={() => closeModal(actions.DELETE_COMPLETED_TEST)}
        isVisible={modalsState[actions.DELETE_COMPLETED_TEST]}
        description="Are you sure you want to delete selected result?"
        onConfirm={() => closeModal(actions.DELETE_COMPLETED_TEST)}
        caution
      />
      {isLoading && <Spinner isLoading text="Loading results" full />}
    </RatersTableWrapper>
  );
};

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100vh - 3.2rem - 56px);
`;

const CustomEmptyState = styled(TableEmptyState)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export default RatersResults;
