import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import { I18n } from 'react-redux-i18n';

const ButtonGroup = ({ groups, activeGroup, setActiveGroup }) => {
  return (
    <div>
      {groups.map((button, index) => (
        <ButtonStyled
          type="button"
          key={button.name}
          className={clsx({
            active: activeGroup.name === button.name,
            first: index === 0,
            last: index === groups.length - 1,
          })}
          onClick={() => {
            setActiveGroup(button);
          }}
        >
          {I18n.t(button.title)}
        </ButtonStyled>
      ))}
    </div>
  );
};

const ButtonStyled = styled.button`
  background: #ffffff;
  border: 2px solid #008ac0;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #008ac0;
  padding: 8px 12px;
  cursor: pointer;
  outline: none;

  &.first {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    border-right: none;
  }

  &.last {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
  }

  &.active {
    background: #008ac0;
    color: #ffffff;
  }
`;

export default ButtonGroup;
