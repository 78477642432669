const breadcrumbs = (project) => [
  {
    label: 'Projects',
    path: '/projects',
  },
  {
    label: project ? project.name : '',
  },
];

export default breadcrumbs;
