import React, { useState } from 'react';
import { func, string } from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as PenIcon } from '../../../../assets/icons/pen.svg';

const SummarySubHeader = ({ title, editHandler }) => {
  const [isEditIconVisible, setEditIconVisibility] = useState(false);

  return (
    <SubHeader onMouseEnter={() => setEditIconVisibility(true)} onMouseLeave={() => setEditIconVisibility(false)}>
      <h4>{title}</h4>
      {editHandler && isEditIconVisible && <StyledPenIcon onClick={editHandler} />}
    </SubHeader>
  );
};

SummarySubHeader.propTypes = {
  title: string.isRequired,
  editHandler: func,
};

SummarySubHeader.defaultProps = {
  editHandler: undefined,
};

const SubHeader = styled.div`
  display: flex;
  align-items: center;
  min-height: 1.6rem;
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.darkBlue2};
  h4 {
    margin-right: 1rem;
  }

  margin-bottom: 1.6rem;
`;

const StyledPenIcon = styled(PenIcon)`
  &:hover {
    cursor: pointer;
  }
`;

export default SummarySubHeader;
