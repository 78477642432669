import React, { useState } from 'react';
import { func, string, object, array } from 'prop-types';
import styled from 'styled-components';

import { I18n } from 'react-redux-i18n';
import CustomSelect from '../../reusable/Selects/Select';
import DateTimeSelect from '../../reusable/Selects/DateTimeSelect';
import RadioButton from '../../reusable/FormComponents/RadioButton';

import { sendInviteOptions } from '../../Respondents/CreateInvite/InvitationStep';

const SessionInvitationStep = ({ welcomeTexts, templates, languages, onValueChange, values, itemType }) => {
  const [invitationType, setInvitationType] = useState(sendInviteOptions[0]);
  return (
    <InvitationStepContainer>
      <Select
        options={languages}
        placeholder={I18n.t('Language')}
        selectProps={{ value: values.language, onChange: (data) => onValueChange('language', data) }}
      />
      <DateTimePicker
        onDateChange={(date) => onValueChange('expiryDate', date)}
        inputName={I18n.t('Expiry date')}
        minDate={new Date()}
        date={values.expiryDate}
      />
      {itemType === 'session' && (
        <Select
          options={welcomeTexts}
          placeholder={I18n.t('Welcome text for Self Raters')}
          selectProps={{ value: values.welcomeTextSelf, onChange: (data) => onValueChange('welcomeTextSelf', data) }}
        />
      )}
      {itemType === 'session' && (
        <Select
          options={templates}
          placeholder={I18n.t('Email template for Self Raters')}
          selectProps={{ value: values.templateSelf, onChange: (data) => onValueChange('templateSelf', data) }}
        />
      )}
      <Select
        options={welcomeTexts}
        placeholder={itemType === 'session' ? I18n.t('Welcome text for Other Raters') : I18n.t('Welcome text')}
        selectProps={{ value: values.welcomeTextRaters, onChange: (data) => onValueChange('welcomeTextRaters', data) }}
      />
      <Select
        options={templates}
        placeholder={itemType === 'session' ? I18n.t('Email template for Other Raters') : I18n.t('Email template')}
        selectProps={{ value: values.templateRaters, onChange: (data) => onValueChange('templateRaters', data) }}
      />
      <Label>{I18n.t('When do you want to send an invite?')}</Label>
      <InvitationTypesWrapper>
        {sendInviteOptions.map((item) => (
          <RadioOption
            key={item.value}
            isActive={item.value === invitationType.value}
            onClick={() => {
              setInvitationType(item);
              if (item.value === 'immediately') onValueChange('invitationDate', null);
            }}
          >
            <RadioButton isChecked={item.value === invitationType.value} readOnly />
            <span>{I18n.t(item.label)}</span>
          </RadioOption>
        ))}
      </InvitationTypesWrapper>
      {invitationType.value === 'later' && (
        <DateTimeSelect
          onDateChange={(date) => onValueChange('invitationDate', date)}
          inputName={I18n.t('Invitation date')}
          minDate={new Date()}
          date={values.invitationDate}
        />
      )}
    </InvitationStepContainer>
  );
};

SessionInvitationStep.propTypes = {
  welcomeTexts: array.isRequired,
  templates: array.isRequired,
  languages: array.isRequired,
  onValueChange: func.isRequired,
  values: object.isRequired,
  itemType: string.isRequired,
};

const InvitationStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-top: 2rem;
  width: 75%;
`;

const DateTimePicker = styled(DateTimeSelect)`
  margin-bottom: 1.5rem;
`;

const Select = styled(CustomSelect)`
  margin-bottom: 1.5rem;
`;

const Label = styled.span`
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 2rem;
`;

const InvitationTypesWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
`;

const RadioOption = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2rem;
  user-select: none;

  :hover {
    cursor: pointer;
  }

  span {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: bold;
    margin-left: 0.5rem;
    color: ${(props) => (props.isActive ? props.theme.colors.lightBlue : props.theme.colors.darkBlue2)};
  }
`;

export default SessionInvitationStep;
