import React, { useContext, useState, useEffect } from 'react';
import { string } from 'prop-types';
import { format } from 'date-fns';
import styled, { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import Slider from 'rc-slider';
import deepEqual from 'deep-equal';

import { I18n } from 'react-redux-i18n';
import PageWrapper from '../../PageWrapper';
import Spinner from '../../reusable/Spinner';
import ConfirmationModal from '../../reusable/ConfirmationModal';

import idealProfileScalesTableActions from '../../../table/tableActions/idealProfileScales';
import idealProfileTypes from '../../../constants/idealProfileTypes';

import {
  fetchIdealProfileScales,
  editIdealProfile,
  setActiveIdealProfile,
} from '../../../store/settings/ideal-profiles/actions';

import CustomForm from './CustomForm';

const IdealProfileScales = ({ idealProfileId }) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const activeIdealProfile = useSelector((state) => state.idealProfiles.activeIdealProfile);

  const navigateToProfiles = () => dispatch(push('/settings/ideal-profiles'));

  const [initialData, setInitialData] = useState(activeIdealProfile);
  const [data, setData] = useState(activeIdealProfile);
  const [isLoading, setLoadingState] = useState(true);
  const [isUnsavedDataModalVisible, setUnsavedDataModalState] = useState(false);
  const [formKey, setFormKey] = useState(0);

  useEffect(() => {
    dispatch(fetchIdealProfileScales(idealProfileId, () => setLoadingState(false)));
  }, []);
  const hasDataChanged = !deepEqual(initialData, data);

  useEffect(() => {
    setData(activeIdealProfile);
    if (activeIdealProfile) setInitialData(activeIdealProfile);
  }, [activeIdealProfile]);

  useEffect(() => {
    return () => dispatch(setActiveIdealProfile(null));
  }, []);

  const onSliderChange = (newValue, scaleSequence) => {
    setData((prevData) => ({
      ...prevData,
      scaleValues: prevData.scaleValues.map((scale) =>
        scale.scaleSequence === scaleSequence ? { ...scale, value: newValue } : scale,
      ),
    }));
  };

  const onBackButtonClick = () => {
    if (hasDataChanged) setUnsavedDataModalState(true);
    else navigateToProfiles();
  };

  const onEditSubmit = () => {
    const body = {
      idealProfileID: Number(idealProfileId),
      scaleValues: data.scaleValues,
      name: data.name,
      ...(data.customFormValues ? { customFormValues: data.customFormValues } : {}),
    };
    setLoadingState(true);
    dispatch(
      editIdealProfile(body, (success) => {
        setLoadingState(false);
        if (isUnsavedDataModalVisible) setUnsavedDataModalState(false);
        if (success) {
          setInitialData(data);
        } else {
          setFormKey((prevKey) => prevKey + 1);
        }
      }),
    );
  };

  const buttons = idealProfileScalesTableActions.map((item) => ({
    ...item,
    isDisabled: !hasDataChanged,
    handler: onEditSubmit,
  }));

  const handleCustomFormUpdate = (data) => {
    setData((prev) => ({
      ...prev,
      scaleValues: data.scaleValues,
      customFormValues: data.customFormValues,
    }));
  };

  return (
    <PageWrapper
      title={data ? data.name : ''}
      backButtonHandler={onBackButtonClick}
      buttons={buttons}
      dropdownActions={[]}
    >
      <DescriptionRow>
        <span>{I18n.t('Ideal profile type:')}</span>
        <span>{data && idealProfileTypes[data.type]}</span>
      </DescriptionRow>
      <DescriptionRow>
        <span>{`${I18n.t('Created')}:`}</span>
        <span>{data && format(new Date(data.creationDate), 'dd/M/y')}</span>
      </DescriptionRow>
      {data?.type === 2 ? (
        <CustomForm key={formKey} data={data} onUpdate={handleCustomFormUpdate} questionnaireType={data?.testID} />
      ) : (
        <ScalesWrapper>
          {data &&
            data.scales.map((scale) => {
              const currentValue = data.scaleValues.find((item) => item.scaleSequence === scale.sequence).value;
              return (
                <ScaleItem key={scale.sequence}>
                  <ScaleText>{scale.scoreValueTexts[currentValue - 1].text}</ScaleText>
                  <ScaleEdit>
                    <ScaleTitle>{scale.shortName}</ScaleTitle>
                    <ScaleRanges>
                      <span>{scale.lowName}</span>
                      <span>{scale.highName}</span>
                    </ScaleRanges>
                    <StyledSlider
                      min={1}
                      max={scale.scoreValueTexts.length}
                      step={1}
                      defaultValue={currentValue}
                      dots
                      included={false}
                      railStyle={{ backgroundColor: '#CDCDCD' }}
                      dotStyle={{
                        width: '0.8rem',
                        height: '0.8rem',
                        backgroundColor: '#CDCDCD',
                        borderColor: '#CDCDCD',
                      }}
                      handleStyle={{
                        width: '1.8rem',
                        height: '1.8rem',
                        marginTop: '-0.7rem',
                        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25), 0px 2px 6px rgba(55, 85, 99, 0.5)',
                        border: '4px solid #fff',
                        backgroundColor: themeContext.colors.mediumBlue,
                      }}
                      onChange={(data) => onSliderChange(data, scale.sequence)}
                    />
                  </ScaleEdit>
                </ScaleItem>
              );
            })}
        </ScalesWrapper>
      )}
      <ConfirmationModal
        onConfirm={onEditSubmit}
        onReject={navigateToProfiles}
        title={I18n.t('Unsaved data')}
        description={I18n.t('The current ideal profile has not been saved. Would you like to save it?')}
        isVisible={isUnsavedDataModalVisible}
        onClose={() => setUnsavedDataModalState(false)}
      />
      <Spinner isLoading={isLoading} full />
    </PageWrapper>
  );
};

IdealProfileScales.propTypes = {
  idealProfileId: string.isRequired,
};

const DescriptionRow = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSizes.small};
  margin-bottom: 1rem;

  span:first-child {
    color: ${(props) => props.theme.colors.darkBlue};
    margin-right: 0.5rem;
  }
  span:last-child {
    color: ${(props) => props.theme.colors.darkBlue2};
  }
`;

const ScalesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  overflow: auto;
  margin-top: 2rem;
  width: 100%;
  padding: 2rem 6.4rem 2rem 0;
  align-self: center;
  border-top: 1px solid ${(props) => props.theme.colors.grey6};
`;

const ScaleItem = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey6};
  width: 100%;
`;

const ScaleText = styled.p`
  margin-right: 3.5rem;
  display: flex;
  align-items: center;
  max-width: 43rem;
  width: 100%;
  height: 9rem;
  max-height: 9rem;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 600;
`;

const ScaleEdit = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ScaleTitle = styled.h4`
  font-size: ${(props) => props.theme.fontSizes.medium};
  font-weight: bold;
  color: ${(props) => props.theme.colors.darkBlue2};
  margin-bottom: 1rem;
`;

const ScaleRanges = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  color: ${(props) => props.theme.colors.darkBlue2};
  font-size: ${(props) => props.theme.fontSizes.small};
  margin-bottom: 1rem;
  text-transform: capitalize;
  width: 48rem;
`;

const StyledSlider = styled(Slider)`
  margin: 0 0.5rem;
  width: 48rem;
`;

export default IdealProfileScales;
