import React from 'react';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';

import { ReactComponent as EmptyIcon } from '../../../assets/icons/empty_state.svg';

import PieChartGraph from './PieChartGraph';
import PieChartGraphBON from './PieChartGraphBON';
import BarChartGraph from './BarChartGraph';

const BioDataView = ({ bioData }) => {
  const pieChartCategories = bioData.filter(
    (categorie) =>
      categorie.graghType === 'pieChart' && categorie.values !== '' && categorie.FOLLOW_BOOK_OF_NORMS_FORMAT === false,
  );
  const pieChartCategoriesBON = bioData.filter(
    (categorie) =>
      categorie.graghType === 'pieChart' && categorie.values !== '' && categorie.FOLLOW_BOOK_OF_NORMS_FORMAT === true,
  );
  const barChartCategoriesBON = bioData.filter(
    (categorie) => categorie.graghType === 'barChart' && categorie.values !== '',
  );

  const hasBioData =
    pieChartCategories.length === 0 && pieChartCategoriesBON.length === 0 && barChartCategoriesBON.length === 0;

  const rawBioDataColumns = [
    { Header: 'Name', name: 'biodataCategorie', accessor: 'name' },
    { Header: 'Value', name: 'value', accessor: 'value' },
    { Header: 'Percent', name: 'percent', accessor: 'percent' },
  ];

  const NoFoundContent = (
    <EmptyMessageWrapper>
      <EmptyIcon />
      <EmptyMessage>{I18n.t(`Sorry, we could not find any biodata information`)}</EmptyMessage>
    </EmptyMessageWrapper>
  );

  return (
    <StyledGraphicWrapper>
      {barChartCategoriesBON && (
        <>
          {barChartCategoriesBON.map((categorie) => {
            return <BarChartGraph key={categorie.name} categorie={categorie} rawBioDataColumns={rawBioDataColumns} />;
          })}
        </>
      )}
      {pieChartCategoriesBON && (
        <>
          {pieChartCategoriesBON.map((categorie) => {
            return (
              <PieChartGraphBON key={categorie.name} categorie={categorie} rawBioDataColumns={rawBioDataColumns} />
            );
          })}
        </>
      )}
      {pieChartCategories && (
        <>
          {pieChartCategories.map((categorie) => {
            return <PieChartGraph key={categorie.name} categorie={categorie} rawBioDataColumns={rawBioDataColumns} />;
          })}
        </>
      )}
      {hasBioData && NoFoundContent}
    </StyledGraphicWrapper>
  );
};

const StyledGraphicWrapper = styled.div`
  position: relative;
  overflow: auto;
  overflow-x: hidden;
  padding: 2px;
  display: flex;
  flex-wrap: wrap;
  max-width: calc(100vw - 200px);
  max-height: 100vh;
  height: inherit;
`;

const EmptyMessageWrapper = styled.div`
  position: absolute;
  top: 55%;
  left: 60%;
  transform: translate(-55%, -60%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EmptyMessage = styled.p`
  margin: 15px 0 0 0;
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
`;

export default BioDataView;
