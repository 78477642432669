import { createSelector } from 'reselect';
import { extractLocalizedData } from '../../../components/Settings/utils/customCompetencies.util';

const selectSummary = (state) => state.competencyGroupsDetails.summary;
const selectGroups = (state) => state.competencyGroupsDetails.groups;
const selectQuestions = (state) => state.competencyGroupsDetails.questions;
const selectScales = (state) => state.competencyGroupsDetails.scales;
const selectResponses = (state) => state.competencyGroupsDetails.responses;
const selectSelectedScale = (state) => state.competencyGroupsDetails.selectedScale;
const selectSelectedOrder = (state) => state.competencyGroupsDetails.selectedOrder;
const selectCustomOrderSequence = (state) => state.competencyGroupsDetails.customOrderSequence;
const selectIsLoading = (state) => state.competencyGroupsDetails.isLoading;
const selectInitialValues = (state) => state.competencyGroupsDetails.initialValues;
const selectLocale = (state) => state.i18n.locale;

const selectLocalizedGroups = createSelector([selectGroups, selectLocale], (groups, locale) =>
  groups.map((group) => ({
    ...group,
    ...extractLocalizedData(group, locale),
    categories: group.categories.map((category) => ({ ...category, ...extractLocalizedData(category, locale) })),
  })),
);

const competencyGroupsDetailsSelectors = {
  selectSummary,
  selectGroups,
  selectLocalizedGroups,
  selectQuestions,
  selectIsLoading,
  selectScales,
  selectResponses,
  selectSelectedScale,
  selectSelectedOrder,
  selectCustomOrderSequence,
  selectInitialValues,
};

export default competencyGroupsDetailsSelectors;
