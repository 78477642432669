import React, { useState, useEffect, useMemo } from 'react';
import { func, array } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { push } from 'redux-first-history';

import { I18n } from 'react-redux-i18n';
import NewModal from '../../reusable/NewModal';
import CustomInput from '../../reusable/FormComponents/Input';
import CustomSelect from '../../reusable/Selects/Select';
import CustomButton from '../../reusable/Buttons/Button';
import RadioButton from '../../reusable/FormComponents/RadioButton';

import {
  createIdealProfile,
  fetchGroupsForIdealProfiles,
  fetchScalesResponse,
} from '../../../store/settings/ideal-profiles/actions';
import { selectIdealProfileGroupsOptions } from '../../../store/settings/ideal-profiles/selectors';
import { calculateDefaultValues } from './CustomForm';

const profileTypes = [
  // translated in props
  {
    value: 'manual',
    label: 'Manually select the values',
    backendReference: 1,
  },
  {
    value: 'automatic',
    label: 'Use respondent data to determine the values',
    backendReference: 0,
  },
  {
    value: 'custom_form',
    label: 'Complete ideal profile questionnaire',
    backendReference: 2,
  },
];

const availableQuestionnaireForms = ['15FQ+', 'OPP'];

const convertNormToOption = (norm) => ({ ...norm, value: norm.normID, label: norm.name });

const AddIdealProfileModal = ({ onClose, tests }) => {
  const groups = useSelector(selectIdealProfileGroupsOptions);
  const [norms, setNorms] = useState([]);
  const [profileName, setProfileName] = useState('');
  const [test, setTest] = useState(null);
  const [selectedNorm, setSelectedNorm] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [profileType, setProfileType] = useState(profileTypes[0]);
  const dispatch = useDispatch();

  const profileTypesR = useMemo(
    () =>
      profileTypes.filter((profile) =>
        profile.value === 'custom_form' ? availableQuestionnaireForms.includes(test?.value) : true,
      ),
    [test],
  );

  useEffect(() => {
    if (tests.length) setTest(tests[0]);
  }, [tests]);

  useEffect(() => {
    if (test) {
      setNorms(test.norms.map((norm) => convertNormToOption(norm)));
      dispatch(fetchGroupsForIdealProfiles(test.testID));
    }
  }, [test]);

  const onTypeChange = (type) => {
    setProfileType(type);
    if (type.value === 'manual') {
      setSelectedGroup(null);
      setSelectedNorm(null);
    }
  };

  const onTestChange = (test) => {
    setTest(test);
    if (profileType.value === 'automatic') {
      setSelectedNorm(null);
      setSelectedGroup(null);
    }
  };

  const onSubmit = async () => {
    let data = {
      name: profileName,
      type: profileTypes.find((item) => item.value === profileType.value).backendReference,
      testID: test.testID,
    };
    if (profileType.value === 'automatic') {
      data.normID = selectedNorm.normID;
      data.groupID = selectedGroup.groupID;
    }

    if (profileType.value === 'custom_form') {
      const scaleResponse = await dispatch(fetchScalesResponse(data.testID));

      const defaults = calculateDefaultValues(data.testID, scaleResponse);
      data = { ...data, ...defaults };
    }
    dispatch(
      createIdealProfile(data, (err, idealProfileId) => {
        onClose();
        if (!err) dispatch(push(`/settings/ideal-profiles/${idealProfileId}`));
      }),
    );
  };

  const isSaveButtonDisabled = useMemo(() => {
    if (profileType.value === 'custom_form') {
      return !profileName;
    }
    return profileType.value === 'manual'
      ? !test || !profileName
      : !test || !profileName || !selectedGroup || !selectedNorm;
  }, [profileType.value, test, profileName, selectedNorm, selectedGroup]);
  return (
    <StyledModal isVisible onClose={onClose} title={I18n.t('Add ideal profile')}>
      <FormSection>
        <StyledInput
          value={profileName}
          onChange={(e) => setProfileName(e.target.value)}
          inputName={I18n.t('Ideal profile name')}
        />
        <StyledSelect
          options={tests}
          placeholder={I18n.t('Assessment')}
          selectProps={{ value: test, onChange: onTestChange }}
        />
        <ActionTypesWrapper>
          {profileTypesR.map((item) => {
            const isActive = item.value === profileType.value;
            return (
              <ActionType key={item.value} onClick={() => onTypeChange(item)} isActive={isActive}>
                <StyledRadioButton isChecked={isActive} />
                <span>&nbsp;{I18n.t(item.label)}</span>
              </ActionType>
            );
          })}
        </ActionTypesWrapper>
        {profileType.value === 'automatic' && (
          <>
            <StyledSelect
              options={norms}
              placeholder={I18n.t('Norms')}
              selectProps={{ value: selectedNorm, onChange: setSelectedNorm }}
            />
            <StyledSelect
              options={groups}
              placeholder={I18n.t('addIdealProfileGroups')}
              selectProps={{ value: selectedGroup, onChange: setSelectedGroup }}
            />
          </>
        )}
      </FormSection>
      <ButtonWrapper>
        <StyledButton disabled={isSaveButtonDisabled} handler={onSubmit}>
          {I18n.t('Save')}
        </StyledButton>
      </ButtonWrapper>
    </StyledModal>
  );
};

AddIdealProfileModal.propTypes = {
  onClose: func.isRequired,
  tests: array.isRequired,
};

const StyledModal = styled(NewModal)`
  display: flex;
  flex-direction: column;
  width: 75rem;
  max-height: 46rem;
  height: 100%;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledInput = styled(CustomInput)`
  width: 50rem;
  margin-bottom: 2rem;
`;

const StyledSelect = styled(CustomSelect)`
  width: 50rem;
  margin-bottom: 2rem;
`;

const ActionTypesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const ActionType = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  span {
    font-weight: bold;
    color: ${(props) => (props.isActive ? props.theme.colors.lightBlue : props.theme.colors.darkBlue2)};
    font-size: 1.4rem;
  }

  :hover {
    cursor: pointer;
  }

  :last-child {
    margin-bottom: 0;
  }
`;

const StyledRadioButton = styled(RadioButton)`
  margin-right: 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledButton = styled(CustomButton)`
  width: 12rem;
  text-transform: uppercase;
`;

export default AddIdealProfileModal;
