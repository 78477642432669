import React from 'react';
import { func, oneOfType, string, number } from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as Close } from '../../assets/icons/close.svg';

const Label = ({ value, onDelete }) => {
  return (
    <LabelItem>
      <span>{value}</span>
      <CloseIcon onClick={onDelete}>
        <Close />
      </CloseIcon>
    </LabelItem>
  );
};

Label.propTypes = {
  value: oneOfType([string, number]).isRequired,
  onDelete: func,
};
Label.defaultProps = {
  onDelete: undefined,
};

const LabelItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.lightBlue1};
  border-radius: 0.4rem;
  padding: 0.6rem;
  margin-right: 1rem;
  margin-bottom: 1rem;

  span {
    font-size: ${(props) => props.theme.fontSizes.small};
    font-weight: 600;
    color: ${(props) => props.theme.colors.darkBlue2};
    margin-right: 1rem;
  }
`;

const CloseIcon = styled.div`
  display: flex;
  width: 1.6rem;
  height: 1.6rem;
  background-color: ${(props) => props.theme.colors.lightBlue};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;

  :hover {
    cursor: pointer;
    transform: scale(0.95);
  }

  svg {
    width: 0.8rem;
    height: 0.8rem;
  }
`;

export default Label;
