/* eslint-disable no-param-reassign */
import * as actions from './actions';
import handleActions from '../immerHandleActions';
import reportsColumns from '../../table/columns/reports';
import {
  TEXT_FILTER_TYPES,
  DATE_FILTER_TYPES,
  textFilterOptions,
  datesFilterOptions,
} from '../../constants/advancedFilterOptions';
import languagesMap from '../../constants/languages';
import storageService from '../../services/storageService';

const REPORTS_FILTER_FIELDS = reportsColumns
  .filter((item) => item.name !== 'status' && item.name !== 'actions')
  .map((column) => ({ name: column.name, type: column.type }));

const initialInputsState = REPORTS_FILTER_FIELDS.map((fieldObj) => ({
  name: fieldObj.name,
  value: fieldObj.type === 'date' ? null : '',
  type: fieldObj.type,
  filterType:
    fieldObj.type === 'date'
      ? datesFilterOptions.find((item) => item.name === DATE_FILTER_TYPES.THREE_DAYS)
      : textFilterOptions.find((item) => item.name === TEXT_FILTER_TYPES.CONTAINS),
}));

export const reportTypesMap = {
  0: 'all',
  1: 'pending',
  2: 'unread',
  3: 'viewed',
};

const tabs = [
  {
    name: 'all',
    label: 'All',
  },
  {
    name: 'pending',
    label: 'Pending',
  },
  {
    name: 'unread',
    label: 'Unread',
  },
  {
    name: 'viewed',
    label: 'Viewed',
  },
];

const initialState = {
  reports: [],
  selectedReports: [],
  tabs,
  pagesAvailable: 0,
  totalCount: 0,
  totalPending: 0,
  totalUnread: 0,
  totalViewed: 0,
  lastPageIndex: 0,
  activeTab: tabs[0],
  search: '',
  filter: {
    isApplied: false,
    dirty: false,
    appliedInputs: initialInputsState,
    inputs: initialInputsState,
  },
  availableLanguages: [],
  lastTableParams: null,
};

const reportsReducer = handleActions(
  {
    // FILTERS PART
    [actions.setReportsSearchValue]: (draft, { payload: { searchValue } }) => {
      draft.search = searchValue;
    },
    [actions.setReportsFilterValue]: (draft, { payload: { fieldName, value } }) => {
      draft.filter.inputs = draft.filter.inputs.map((item) => (item.name === fieldName ? { ...item, value } : item));
      draft.filter.dirty = true;
    },
    [actions.setReportsFilterType]: (draft, { payload: { fieldName, filterType } }) => {
      const initValue = fieldName.toLowerCase().includes('date')
        ? datesFilterOptions.find((item) => item.name === DATE_FILTER_TYPES.THREE_DAYS)
        : textFilterOptions.find((item) => item.name === TEXT_FILTER_TYPES.CONTAINS);
      draft.filter.inputs = draft.filter.inputs.map((item) =>
        item.name === fieldName ? { ...item, filterType: filterType === 'initial' ? initValue : filterType } : item,
      );
      draft.filter.dirty = true;
    },
    [actions.applyReportsFilters]: (draft) => {
      draft.filter.dirty = false;
      draft.filter.isApplied = true;
      draft.filter.appliedInputs = draft.filter.inputs;
    },
    [actions.clearReportsFilters]: (draft) => {
      draft.filter.dirty = true;
      draft.filter.inputs = initialInputsState;
    },
    [actions.deleteReportsAppliedFilter]: (draft, { payload: { fieldName } }) => {
      draft.filter.appliedInputs = draft.filter.appliedInputs.map((input) =>
        input.name === fieldName ? { ...input, value: input.type === 'date' ? null : '' } : input,
      );
      draft.filter.inputs = draft.filter.inputs.map((input) =>
        input.name === fieldName ? { ...input, value: input.type === 'date' ? null : '' } : input,
      );
    },

    // END OF FILTERS, EVERYTHING ELSE
    [actions.fetchReportsSuccess]: (draft, { payload: { data, tableParams, shouldReset } }) => {
      const withStatus = (item) => ({ ...item, status: reportTypesMap[item.reportType] });
      draft.reports = shouldReset ? data.reports.map(withStatus) : [...draft.reports, ...data.reports.map(withStatus)];
      draft.pagesAvailable = data.pagesAvailable;
      draft.totalCount = data.totalCount;
      draft.totalPending = data.totalPending;
      draft.totalUnread = data.totalUnread;
      draft.totalViewed = data.totalViewed;
      draft.lastPageIndex = data.pageNumber;
      draft.lastTableParams = tableParams;
    },
    [actions.fetchReportsCountSuccess]: (draft, { payload: { data } }) => {
      draft.totalCount = data.totalCount;
      draft.totalPending = data.totalPending;
      draft.totalUnread = data.totalUnread;
      draft.totalViewed = data.totalViewed;
    },
    [actions.fetchAvailableReportsLanguagesSuccess]: (draft, { payload: { languages } }) => {
      const interfaceLanguage = storageService.getItem('psLang');
      // if (interfaceLanguage === 'ch') interfaceLanguage = 'ch-prc';
      draft.availableLanguages = languages
        .map((langId) => ({
          code: langId,
          value: langId,
          label: languagesMap[langId] || langId,
        }))
        .sort((x, y) =>
          // eslint-disable-next-line no-nested-ternary
          x.code.toLocaleLowerCase() === interfaceLanguage
            ? -1
            : y.code.toLocaleLowerCase() === interfaceLanguage
            ? 1
            : 0,
        );
    },
    [actions.setActiveReportsTab]: (draft, { payload: { tab } }) => {
      draft.activeTab = tab;
    },
    [actions.setSelectedReports]: (draft, { payload: { reports } }) => {
      draft.selectedReports = reports;
    },
    [actions.markReportsAsViewedSuccess]: (draft, { payload: { reportsIds } }) => {
      draft.reports.forEach((report) => {
        if (reportsIds.includes(report.notificationID)) {
          report.status = 'viewed';
          report.reportType = 3;
        }
      });
    },
    // [actions.deleteReportSuccess]: (draft, { payload: { report } }) => {
    //   draft.reports = draft.reports.filter((item) => {
    //     if (item.status !== report.status) return true;
    //     const idAccessor = report.status === 'pending' ? 'requestID' : 'reportRequestID';
    //     return item[idAccessor] !== report[idAccessor];
    //   });
    // },
    // [actions.fetchPendingReportsSuccess]: (draft, { payload: { reports } }) => {
    //   const oldReports = draft.reports.filter((item) => item.status !== 'pending');
    //   const oldPendingReports = draft.reports.filter((item) => item.status === 'pending');
    //   const newPendingReports = reports.map((item) => ({
    //     ...item,
    //     status: 'pending',
    //     questionnaire: item.reportName,
    //     creationDate: item.requestedAt,
    //   }));
    //   // if report was created successfully
    //   const isSuccessful = newPendingReports.length < oldPendingReports.length;
    //   // to prevent unnecessary updates
    //   const diff = differenceBy(newPendingReports, oldPendingReports, 'requestID');
    //   // if report creation was unsuccessful
    //   const hasErrorStatusChanged =
    //     newPendingReports.filter((report) => {
    //       const oldReport = oldPendingReports.find((item) => item.requestID === report.requestID);
    //       if (!oldReport) return false;
    //       return oldReport.error !== report.error;
    //     }).length > 0;
    //   if (diff.length || hasErrorStatusChanged || isSuccessful) draft.reports = [...oldReports, ...newPendingReports];
    // },
  },
  initialState,
);

export default reportsReducer;
