import { I18n } from 'react-redux-i18n';
import * as actions from '../actionsList';
import getRatersByStatus from '../../utils/getRatersByStatus';

const sessionsDropdownActions = (raters, self) => {
  // const { notCompleted, notInvited, invitedNotCompleted } = getRatersByStatus(raters);
  const { notInvited, invitedNotCompleted } = getRatersByStatus(raters);

  return [
    // {
    //   name: actions.SEND_INVITE_TO_RATERS,
    //   label: 'Invite raters who have not yet completed',
    //   isDisabled: notCompleted.length === 0,
    //   commandType: 0,
    // },
    {
      name: actions.SEND_INVITE_TO_RATERS,
      label: I18n.t('Send invite'),
      isDisabled: notInvited.length === 0,
      commandType: 1,
    },
    {
      name: actions.SEND_INVITE_TO_RATERS,
      label: I18n.t('Send reminder'),
      isDisabled: invitedNotCompleted.length === 0,
      commandType: 2,
    },
    {
      name: actions.SESSION_INVITE_SPECIFY_RATERS,
      label: I18n.t('Invite to specify own raters'),
      borderBottom: true,
    },
    {
      name: actions.SESSION_REQUEST_REPORT,
      label: I18n.t('Request report'),
      borderBottom: true,
      isDisabled: self.completeStatus === 0,
    },
    {
      name: actions.DELETE_SESSIONS,
      label: I18n.t('Delete session'),
    },
  ];
};

export default sessionsDropdownActions;
