import React from 'react';
import { func, bool, string } from 'prop-types';
import styled from 'styled-components';

import { I18n } from 'react-redux-i18n';
import Modal from './NewModal';
import CustomButton from './Buttons/Button';

const ConfirmationModal = ({
  isVisible,
  onClose,
  onConfirm,
  onReject,
  description,
  title,
  isConfirmDisabled,
  isHeaderHidden,
  confirmText,
  cancelText,
  buttonsBetween,
  className,
}) => {
  const onCancelClick = () => {
    if (onReject) onReject();
    onClose();
  };

  return (
    <StyledModal
      isVisible={isVisible}
      onClose={onClose}
      title={title}
      className={className}
      isHeaderHidden={isHeaderHidden}
    >
      <Description>{description}</Description>
      <ButtonsWrapper buttonsBetween={buttonsBetween}>
        <StyledButton onClick={onCancelClick} variant="secondary" disabled={isConfirmDisabled}>
          {cancelText || I18n.t('No')}
        </StyledButton>
        <StyledButton onClick={onConfirm} variant="primary" loading={isConfirmDisabled} disabled={isConfirmDisabled}>
          {confirmText || I18n.t('Yes')}
        </StyledButton>
      </ButtonsWrapper>
    </StyledModal>
  );
};

ConfirmationModal.propTypes = {
  isVisible: bool.isRequired,
  onClose: func.isRequired,
  onConfirm: func.isRequired,
  description: string.isRequired,
  title: string,
  onReject: func,
  isConfirmDisabled: bool,
  isHeaderHidden: bool,
  confirmText: string,
  cancelText: string,
  buttonsBetween: bool,
  className: string,
};

ConfirmationModal.defaultProps = {
  title: 'Genesys Online',
  onReject: undefined,
  isConfirmDisabled: false,
  isHeaderHidden: false,
  confirmText: '',
  cancelText: '',
  buttonsBetween: false,
  className: '',
};

const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  min-height: 20rem;
  max-width: 80rem;
`;

const Description = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: ${(props) => props.theme.colors.primary};
  flex-grow: 1;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-self: center;
  align-items: center;
  justify-content: ${(props) => (props.buttonsBetween ? 'space-between' : 'flex-end')};
  margin-top: 2rem;
`;

const StyledButton = styled(CustomButton)`
  margin-left: 1rem;
  min-width: 10rem;
  text-transform: uppercase;
`;

export default ConfirmationModal;
