import { I18n } from 'react-redux-i18n';
import { ADD_RATERS } from '../actionsList';

const ratersTableActions = [
  {
    name: ADD_RATERS,
    label: I18n.t('Add rater'),
  },
];

export default ratersTableActions;
