import { I18n } from 'react-redux-i18n';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as InviteRatersIcon } from '../../assets/icons/sidebar-invite-not-invited.svg';

import * as actions from '../actionsList';
import getRatersByStatus from '../../utils/getRatersByStatus';
import { AP_COMPLETED_STATUS } from '../../constants/assessmentProjectStatuses';

const ratersBulkActions = [
  {
    name: actions.SEND_INVITE_TO_RATERS,
    label: I18n.t('Send invite to selected rater(s)'),
    Icon: InviteRatersIcon,
    isDisabled: (raters, project) => {
      if (project?.status === AP_COMPLETED_STATUS) {
        return true;
      }
      const { completed } = getRatersByStatus(raters);
      return completed.length === raters.length;
    },
    commandType: 0,
  },
  {
    name: actions.DELETE_RATERS,
    label: I18n.t('Remove selected rater(s) from session'),
    Icon: DeleteIcon,
    isDisabled: (raters) => raters.some((item) => item.role === 0),
  },
];

export default ratersBulkActions;
