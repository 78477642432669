import { I18n } from 'react-redux-i18n';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';

import * as actions from '../actionsList';

const ratersResultsBulkActions = [
  {
    name: actions.DELETE_COMPLETED_TEST,
    label: I18n.t('Delete selected result(s)'),
    Icon: DeleteIcon,
  },
];

export default ratersResultsBulkActions;
