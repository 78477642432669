import React from 'react';
import { array } from 'prop-types';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { push } from 'redux-first-history';
import { ReactComponent as RightArrow } from '../../assets/icons/black-arrow-right.svg';

const Breadcrumbs = ({ breadcrumbs }) => {
  const dispatch = useDispatch();
  return (
    <BreadcrumbsWrapper>
      {breadcrumbs.map((chainLink, i) => {
        const isLast = i === breadcrumbs.length - 1;
        return (
          <BreadcrumbsItem
            onClick={() => chainLink.path && dispatch(push(chainLink.path))}
            isLast={isLast}
            key={chainLink.label}
          >
            <span>{chainLink.shouldNotTranslate ? chainLink.label : I18n.t(chainLink.label)}</span>
            {!isLast && <ArrowRight />}
          </BreadcrumbsItem>
        );
      })}
    </BreadcrumbsWrapper>
  );
};

Breadcrumbs.propTypes = {
  breadcrumbs: array.isRequired,
};

const BreadcrumbsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

const BreadcrumbsItem = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => (props.isLast ? props.theme.colors.mediumBlue : 'rgba(0, 0, 0, 0.6)')};
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 600;
  letter-spacing: 1.5px;
  span {
    text-transform: uppercase;
  }

  transition: color 0.2s;
  ${(props) =>
    !props.isLast &&
    css`
      :hover {
        cursor: pointer;
        color: ${(props) => props.theme.colors.lightBlue};
      }
    `}
`;

const ArrowRight = styled(RightArrow)`
  margin: 0 1.5rem;
  path {
    fill: ${(props) => props.theme.colors.grey2};
  }
`;
export default Breadcrumbs;
