import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import PageWrapper from '../PageWrapper';
import {
  fetchProjects,
  deleteProjects,
  setProjectsFilterType,
  setProjectsFilterValue,
  setProjectsSearchValue,
  deleteProjectsAppliedFilter,
  clearProjectsFilters,
  applyProjectsFilters,
  setSelectedProjects,
  updateExpiryProjectInvite,
} from '../../store/projects/actions';
import Table from '../reusable/Tables/Table';
import useTableFilter from '../../hooks/useTableFilter';
import projectsColumns from '../../table/columns/projects';
import AdvancedFilter from '../reusable/AdvancedFilter';
import projectsTableActions from '../../table/tableActions/projects';
import projectsBulkActions from '../../table/bulkActions/projects';
import TableTabs from '../reusable/TableTabs';
import * as actions from '../../table/actionsList';
import useModals from '../../hooks/useModals';
import BulkActionsSidebar from '../BulkActionsSidebar';
import useBulkSidebarState from '../../hooks/useBulkSidebarState';
import ConfirmationModal from '../reusable/ConfirmationModal';
import AddProjectsModal from './AddProjectsModal';

import { selectProjectsTabsCounts } from '../../store/projects/selector';

import ResetExpiryDate from '../Invites/ResetExpiryDate';

const Projects = () => {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects.projects);
  const selectedProjects = useSelector((state) => state.projects.selectedProjects);
  const pageCount = useSelector((state) => state.projects.pagesAvailable);
  const { totalCount, totalAssessment, total360 } = useSelector(selectProjectsTabsCounts);
  const filter = useSelector((state) => state.projects.filter);
  const searchValue = useSelector((state) => state.projects.search);
  const lastPageIndex = useSelector((state) => state.projects.lastPageIndex);
  // const activeTab = useSelector((state) => state.projects.activeTab);

  const [expiryDate, setExpiryDate] = useState(new Date());

  const [shouldResetPage, setShouldResetPageStatus] = useState(false);

  const modals = [actions.ADD_PROJECT, actions.DELETE_PROJECTS, actions.RESET_DEADLINE];

  // Controlling bulk actions sidebar state
  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedProjects });

  const { modalsState, openModal, closeModal } = useModals(modals);
  const onProjectsDelete = () => {
    dispatch(
      deleteProjects(
        selectedProjects.map((p) => ({ type: p.type, projectId: p.projectId })),
        () => {
          closeModal(actions.DELETE_PROJECTS);
          setShouldResetPageStatus(true);
        },
      ),
    );
  };

  // needed for table row rendering optimization, attempt to prevent unnecessary rerenders
  const rowEqualityFunc = useCallback((prevItem, nextItem) => {
    return (
      prevItem.totalCount === nextItem.totalCount &&
      prevItem.status === nextItem.status &&
      prevItem.description === nextItem.description &&
      prevItem.name === nextItem.name &&
      prevItem.deadline === nextItem.deadline
    );
    // return prevItem.sessions.length === nextItem.sessions.length && prevItem.sessions.length === nextItem.sessions.length;
  }, []);

  // table actions
  const tableHandlers = {
    [actions.ADD_PROJECT]: () => {
      dispatch(setSelectedProjects([]));
      openModal(actions.ADD_PROJECT);
    },
  };

  const tableActions = useMemo(() => {
    return projectsTableActions.map((item) => ({ ...item, handler: tableHandlers[item.name] }));
  }, []);

  // bulk and row actions
  const navigateToProjectDetails = (project) => {
    const url = project.type === 0 ? `/projects/360/${project.projectId}` : `/projects/assessment/${project.projectId}`;
    dispatch(push(url));
    dispatch(setSelectedProjects([]));
  };

  // bulk and row actions
  const navigateToEdit = (project) => {
    dispatch(setSelectedProjects([project]));
    openModal(actions.ADD_PROJECT);
  };

  const bulkActionsHandler = {
    [actions.RESET_DEADLINE]: () => openModal(actions.RESET_DEADLINE),
    [actions.EDIT_PROJECT_DETAILS]: () => navigateToEdit(selectedProjects[0]),
    [actions.DELETE_PROJECTS]: () => openModal(actions.DELETE_PROJECTS),
  };

  const bulkActions = useMemo(() => {
    return projectsBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandler[item.name],
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedProjects) : item.isDisabled,
    }));
  }, [selectedProjects]);

  const [isLoading, setLoadingStatus] = useState(true);

  const columns = useMemo(() => {
    return projectsColumns;
  }, []);

  const setFilterType = (name, value) => dispatch(setProjectsFilterType(name, value));
  const setFilterValue = (name, value) => dispatch(setProjectsFilterValue(name, value));

  const { inputsWithHandlers, appliedFiltersWithColumnNames } = useTableFilter({
    filter,
    columns,
    setFilterType,
    setFilterValue,
  });

  const tabs = useMemo(() => {
    return [
      { name: 'all', label: `${I18n.t('All')} (${totalCount})`, value: 0 },
      { name: '360', label: `${I18n.t('360 Appraisal')} (${total360})`, value: 2 },
      { name: 'assessments', label: `${I18n.t('IAssessments')} (${totalAssessment})`, value: 1 },
    ];
  }, [totalCount, total360, totalAssessment]);

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const onTabClick = (tab) => {
    setShouldResetPageStatus(true);
    setActiveTab(tab);
  };

  const fetchData = useCallback(
    ({ tableData, shouldReset, showSpinner, tableCallback }) => {
      if (showSpinner) setLoadingStatus(true);
      dispatch(
        fetchProjects(tableData, activeTab.value, {
          callback: () => {
            setLoadingStatus(false);
            tableCallback();
          },
          shouldReset,
        }),
      );
    },
    [activeTab.value],
  );

  const onSelectChange = useCallback((data) => {
    dispatch(setSelectedProjects(data));
  }, []);

  const memoizedSetProjectsSearchValue = useCallback((val) => dispatch(setProjectsSearchValue(val)), []);
  const memoizedDeleteProjectsAppliedFilter = useCallback(
    (fieldName) => dispatch(deleteProjectsAppliedFilter(fieldName)),
    [],
  );
  const memoizedClearProjectsFilters = useCallback(() => dispatch(clearProjectsFilters()), []);

  const onFilterApply = () => {
    dispatch(applyProjectsFilters());
  };

  // initial table state
  const initialState = useMemo(() => ({ sortBy: [{ id: 'Creation Date', desc: true }] }), []);

  const onValueChange = (value) => {
    setExpiryDate(value);
  };

  useEffect(() => {
    setExpiryDate(selectedProjects[0] ? new Date(selectedProjects[0].deadline) : new Date());

    return () => {};
  }, [selectedProjects.length]);

  const handleExpiryDateUpdates = () => {
    const { name, description, status, projectId } = selectedProjects[0];
    const data = {
      name,
      description,
      deadline: expiryDate.toISOString(),
      status,
      projectId,
    };
    dispatch(updateExpiryProjectInvite(data), closeModal(actions.RESET_DEADLINE));
  };

  return (
    <PageWrapper title={I18n.t('Projects')} buttons={tableActions}>
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      <TableTabs tabs={tabs} activeTab={activeTab} clickHandler={onTabClick} />
      <AdvancedFilter
        searchProps={{
          appliedFilters: appliedFiltersWithColumnNames,
          deleteFilter: memoizedDeleteProjectsAppliedFilter,
          searchValue,
          onSearchChange: memoizedSetProjectsSearchValue,
        }}
        filterProps={{
          inputs: inputsWithHandlers,
          appliedInputs: filter.appliedInputs,
          applyFilters: onFilterApply,
          clearAll: memoizedClearProjectsFilters,
          isDirty: filter.dirty,
        }}
      />
      <StyledTable
        data={projects}
        columns={columns}
        onFetchData={fetchData}
        pageCount={pageCount}
        totalCount={totalCount}
        appliedFilters={appliedFiltersWithColumnNames}
        idAccessor="projectId"
        entityName="project"
        isLoading={isLoading}
        searchValue={searchValue}
        rowEqualityFunc={rowEqualityFunc}
        initialState={initialState}
        lastPageIndex={lastPageIndex}
        onSelectChange={onSelectChange}
        shouldResetPage={shouldResetPage}
        setShouldReset={setShouldResetPageStatus}
        onRowClick={navigateToProjectDetails}
        interceptCheckboxClick
      />
      <ConfirmationModal
        isVisible={modalsState[actions.DELETE_PROJECTS]}
        title={I18n.t('Delete project')}
        description={`${I18n.t('Are you sure you want to delete')} ${
          selectedProjects.length > 1 ? I18n.t('selected projects?') : I18n.t('this project?')
        }`}
        onClose={() => closeModal(actions.DELETE_PROJECTS)}
        onConfirm={onProjectsDelete}
        caution
      />
      {modalsState[actions.ADD_PROJECT] && (
        <AddProjectsModal
          onClose={() => closeModal(actions.ADD_PROJECT)}
          onReset={setShouldResetPageStatus}
          selectedProjects={selectedProjects}
          // isAssessmentProjectDisabled={!featureKeyword || featureKeyword.toLowerCase() !== 'demo'}
        />
      )}
      {modalsState[actions.RESET_DEADLINE] && (
        <ResetExpiryDate
          onClose={() => closeModal(actions.RESET_DEADLINE)}
          onConfirm={handleExpiryDateUpdates}
          expiryDate={expiryDate}
          onValueChange={onValueChange}
        />
      )}
    </PageWrapper>
  );
};

const StyledTable = styled(Table)`
  tr:hover {
    & > div div {
      background: rgba(241, 241, 241, 0.1);
    }
  }
`;

export default Projects;
