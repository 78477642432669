import { createAction } from 'redux-actions';
import { I18n } from 'react-redux-i18n';
import defaultTableState from '../../constants/defaultTableState';
import sessionsColumns from '../../table/columns/sessions';
import generateQueryString from '../generateQueryString';
import apiInstance2 from '../../services/apiService';
import createToastNotification from '../../utils/createToastNotification';

export const setActiveSession = createAction('SET_ACTIVE_SESSION', (session) => ({ session }));
export const setSessionSelf = createAction('SET_SESSION_SELF', (respondent) => ({ respondent }));

// ----------------- FILTERS ----------------------
export const setSessionsSearchValue = createAction('SET_SESSIONS_SEARCH_VALUE', (searchValue) => ({
  searchValue,
}));

export const setSessionsFilterValue = createAction('SET_SESSIONS_FILTER_VALUE', (fieldName, value) => ({
  fieldName,
  value,
}));

export const setSessionsFilterType = createAction('SET_SESSIONS_FILTER_TYPE', (fieldName, filterType) => ({
  fieldName,
  filterType,
}));

export const deleteSessionsAppliedFilter = createAction('DELETE_SESSIONS_APPLIED_FILTER', (fieldName) => ({
  fieldName,
}));

export const applySessionsFilters = createAction('APPLY_SESSIONS_FILTERS');
export const clearSessionsFilters = createAction('CLEAR_SESSIONS_FILTERS');
export const resetSessionsTableState = createAction('RESET_SESSIONS_TABLE_STATE');

export const deleteSessionsStart = createAction('DELETE_SESSIONS_START');
export const deleteSessionsSuccess = createAction('DELETE_SESSIONS_SUCCESS', (sessions) => ({ sessions }));
export const deleteSessionsFailure = createAction('DELETE_SESSIONS_FAILURE');
// ---------  NETWORK RELATED ----------

export const fetchSessionsStart = createAction('FETCH_SESSIONS_START');
export const fetchSessionsSuccess = createAction('FETCH_SESSIONS_SUCCESS', (data, shouldReset) => ({
  data,
  shouldReset,
}));
export const fetchSessionsFailure = createAction('FETCH_SESSIONS_FAILURE');

export const fetchSessionByIdStart = createAction('FETCH_SESSIONS_BY_ID_START');
export const fetchSessionByIdSuccess = createAction('FETCH_SESSIONS_BY_ID_SUCCESS');
export const fetchSessionByIdFailure = createAction('FETCH_SESSIONS_BY_ID_FAILURE');

export const setSelectedSessions = createAction('SET_SELECTED_SESSIONS', (sessions) => ({
  sessions,
}));

export const addSessionStart = createAction('ADD_SESSION_START');
export const addSessionSuccess = createAction('ADD_SESSION_SUCCESS');
export const addSessionFailure = createAction('ADD_SESSION_FAILURE');

export const inviteSessionsStart = createAction('INVITE_SESSIONS_START');
export const inviteSessionsSuccess = createAction('INVITE_SESSIONS_SUCCESS');
export const inviteSessionsFailure = createAction('INVITE_SESSIONS_FAILURE');

export const inviteSpecifyOwnRatersStart = createAction('INVITE_SPECIFY_OWN_RATERS_START');
export const inviteSpecifyOwnRatersSuccess = createAction('INVITE_SPECIFY_OWN_RATERS_SUCCESS');
export const inviteSpecifyOwnRatersFailure = createAction('INVITE_SPECIFY_OWN_RATERS_FAILURE');

export const inviteProjectStart = createAction('INVITE_PROJECT_START');
export const inviteProjectSuccess = createAction('INVITE_PROJECT_SUCCESS');
export const inviteProjectFailure = createAction('INVITE_PROJECT_FAILURE');

export const addRatersStart = createAction('ADD_RATERS_START');
export const addRatersSuccess = createAction('ADD_RATERS_SUCCESS');
export const addRatersFailure = createAction('ADD_RATERS_FAILURE');

export const fetchSessionInviteLanguagesStart = createAction('FETCH_SESSION_INVITE_LANGUAGES_START');
export const fetchSessionInviteLanguagesSuccess = createAction(
  'FETCH_SESSION_INVITE_LANGUAGES_SUCCESS',
  (languages) => ({ languages }),
);
export const fetchSessionInviteLanguagesFailure = createAction('FETCH_SESSION_INVITE_LANGUAGES_FAILURE');

export const fetchSessions = (projectId, tableState = defaultTableState, { callback, shouldReset }) => async (
  dispatch,
) => {
  try {
    dispatch(fetchSessionsStart());
    const columns = sessionsColumns();

    if (tableState.sortBy) {
      const sortingObj = tableState.sortBy[0];
      const columnIndex = columns.findIndex((item) => item.Header === sortingObj.id || item.name === sortingObj.id);

      const order = sortingObj.desc ? 1 : 0;
      tableState.sortBy = { ColumnIndex: columnIndex, Order: order };
    }
    const queryString = generateQueryString(tableState, columns);
    const response = await apiInstance2.get(`/api/v2/360/projects/${projectId}/sessions?${queryString}`);
    if (response.status === 200) {
      dispatch(fetchSessionsSuccess(response.data, shouldReset));
      if (callback) callback();
    }
  } catch (e) {
    dispatch(fetchSessionsFailure(e));
    if (callback) callback(e);
  }
};

export const fetchSessionById = (projectId, sessionId) => async (dispatch) => {
  dispatch(fetchSessionByIdStart());
  try {
    const response = await apiInstance2.get(`/api/v2/360/projects/${projectId}/sessions/${sessionId}`);
    if (response.status === 200) {
      dispatch(fetchSessionByIdSuccess());
      dispatch(setActiveSession(response.data));
    }
  } catch (err) {
    dispatch(fetchSessionByIdFailure(err.message));
  }
};

export const fetchSessionInviteLanguages = (competencyGroupId) => async (dispatch) => {
  dispatch(fetchSessionInviteLanguagesStart());
  try {
    const response = await apiInstance2.get(
      `/api/v2/360/competency-groups/available-languages?competencyGroupID=${competencyGroupId}`,
    );
    if (response.status === 200) {
      dispatch(fetchSessionInviteLanguagesSuccess(response.data));
    }
  } catch (err) {
    dispatch(fetchSessionInviteLanguagesFailure(err.message));
  }
};

export const addSession = (projectId, respondentId, callback) => async (dispatch) => {
  dispatch(addSessionStart());
  try {
    const response = await apiInstance2.post(`/api/v2/360/projects/${projectId}/sessions`, {
      selfRespondentId: respondentId,
    });
    if (response.status === 200) {
      dispatch(addSessionSuccess());
      createToastNotification({ message: I18n.t('sessionAdditionToast') });
      if (callback) callback(response.data);
    }
  } catch (err) {
    dispatch(addSessionFailure(err.message));
    if (callback) callback(err.message);
  }
};

export const deleteSessions = (projectId, sessions, cb) => async (dispatch) => {
  dispatch(deleteSessionsStart());
  try {
    const response = await apiInstance2.delete(`/api/v2/360/projects/${projectId}/sessions`, { data: sessions });
    if (response.status === 200) {
      dispatch(deleteSessionsSuccess(sessions));
    }
  } catch (err) {
    dispatch(deleteSessionsFailure(err.message));
  } finally {
    cb();
  }
};

export const inviteSessions = (projectId, data, callback) => async (dispatch) => {
  dispatch(inviteSessionsStart());
  try {
    const response = await apiInstance2.post(`/api/v2/360/projects/${projectId}/sessions/invites`, data);

    if (response.status === 200) {
      dispatch(inviteSessionsSuccess());
      if (callback) callback();
    }
  } catch (err) {
    dispatch(inviteSessionsFailure(err.message));
    if (callback) callback(err);
  }
};

export const inviteSpecifyOwnRaters = (projectId, data) => async (dispatch) => {
  dispatch(inviteSpecifyOwnRatersStart());
  try {
    const response = await apiInstance2.post(
      `/api/v2/360/projects/${projectId}/sessions/self/specify-raters-invite`,
      data,
    );

    if (response.status === 200) {
      dispatch(inviteSpecifyOwnRatersSuccess());
      return response;
    }
  } catch (err) {
    dispatch(inviteSpecifyOwnRatersFailure(err.message));
    throw err;
  }
};

export const inviteProject = (projectId, data, callback) => async (dispatch) => {
  dispatch(inviteProjectStart());
  try {
    const response = await apiInstance2.post(`/api/v2/360/projects/${projectId}/invites`, data);

    if (response.status === 200) {
      dispatch(inviteProjectSuccess());
      if (callback) callback();
    }
  } catch (err) {
    dispatch(inviteProjectFailure(err.message));
    if (callback) callback(err);
  }
};

export const addRaters = (projectId, sessionId, raters, callback) => async (dispatch) => {
  dispatch(addRatersStart());
  try {
    const response = await apiInstance2.post(`/api/v2/360/projects/${projectId}/sessions/${sessionId}/raters`, raters);
    if (response.status === 200) {
      dispatch(addRatersSuccess());
      createToastNotification({ message: I18n.t('ratersAdditionToast') });
      dispatch(fetchSessionById(projectId, sessionId));
    }
    if (callback) callback();
  } catch (err) {
    dispatch(addRatersFailure());
    if (callback) callback();
  }
};
