import handleActions from '../immerHandleActions';
import * as actions from './actions';

const initialState = {
  isDeleteInProgress: false,
  isCreateNormInProgress: false,
  isGroupFetchInProgress: false,
  groups: [],
  error: '',
};

const normsReducer = handleActions(
  {
    [actions.deleteNormsStart]: (draft) => {
      draft.isDeleteInProgress = true;
      draft.error = '';
    },
    [actions.deleteNormsSuccess]: (draft) => {
      draft.isDeleteInProgress = false;
      draft.error = '';
    },
    [actions.deleteNormsFailure]: (draft, { payload: { err } }) => {
      draft.isDeleteInProgress = false;
      draft.error = err;
    },
    [actions.createNormsStart]: (draft) => {
      draft.isCreateNormInProgress = true;
      draft.error = '';
    },
    [actions.createNormsSuccess]: (draft) => {
      draft.isCreateNormInProgress = false;
      draft.error = '';
    },
    [actions.createNormsFailure]: (draft, { payload: { err } }) => {
      draft.isCreateNormInProgress = false;
      draft.error = err;
    },
    [actions.fetchBriefGroupsStart]: (draft) => {
      draft.isGroupFetchInProgress = true;
      draft.error = '';
    },
    [actions.fetchBriefGroupsSuccess]: (draft, { payload: { groups } }) => {
      draft.isGroupFetchInProgress = false;
      draft.groups = groups.map((group) => ({ label: group.name, value: group.groupID }));
      draft.error = '';
    },
    [actions.fetchBriefGroupsFailure]: (draft, { payload: { err } }) => {
      draft.isGroupFetchInProgress = false;
      draft.error = err;
    },
  },
  initialState,
);

export default normsReducer;
