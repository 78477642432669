import React, { useState, useMemo, useEffect, useRef } from 'react';
import { bool, array, object, func, number } from 'prop-types';
import { intersection, orderBy } from 'lodash';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import CustomButton from '../../reusable/Buttons/Button';
import CustomSelect from '../../reusable/Selects/Select';
import CompactSelect from '../../reusable/Selects/CompactSelect';
import ReportsCreditsCost from '../../reusable/ReportsCreditsCost';
import Radio from '../../formComponents/Radio';
import { assessmentTypeOptions } from '../../../utils/inviteCreationOptions';

import ReportsSet, { findIfReportIsDisabled } from './Set';
import Solution from './Solution';

import { ReactComponent as EmptyIcon } from '../../../assets/icons/empty_state.svg';

export const defineDependencies = (sets) => {
  const reports = sets.reduce((acc, item) => [...acc, ...item.reports], []);
  const checkedReports = reports.filter((item) => item.isChecked);
  if (!checkedReports.length)
    return {
      normsDependencies: [],
      profilesDependencies: [],
    };
  const normsDependencies = checkedReports
    .filter((item) => !item.isSolutionReport)
    .reduce((acc, item) => [...acc, ...item.dependencies.split(';')], []);
  const profilesDependencies = checkedReports
    .filter((item) => item.isIdealProfileReport)
    .reduce((acc, item) => [...acc, ...item.ipDependencies.split(';')], []);
  return {
    normsDependencies: new Array(...new Set(normsDependencies)),
    profilesDependencies: new Array(...new Set(profilesDependencies)),
  };
};

const assessmentOptions = assessmentTypeOptions
  .filter((item) => item.value !== 'battery')
  .map((item) => (item.value === 'test' ? { ...item, name: I18n.t('Assessment/Battery') } : item));

const calculateCost = (sets, respondents) => {
  const respondentsIds = respondents.map((item) => item.respondentID);
  return sets.reduce((acc, item) => {
    // respondents who've already paid for the report set
    const uniquePaidRespondents = intersection(item.paidRespondentIDs, respondentsIds);
    // unpaid respondents or cost multiplier
    const unpaidRespondentsLength = respondentsIds.length - uniquePaidRespondents.length;
    return acc + item.creditsCost * unpaidRespondentsLength;
  }, 0);
};

const ReportsList = ({
  languages,
  multiStep,
  language,
  setLanguage,
  toPrevStep,
  reports,
  commonTests,
  norms,
  idealProfiles,
  onSubmit,
  isLoading,
  onNormChange,
  onIdealProfileChange,
  credits,
  respondents,
  hasSelectedNorm,
}) => {
  const [assessmentType, setAssessmentType] = useState(assessmentOptions[0]);
  const [data, setData] = useState(reports.map((item) => ({ ...item, isOpen: false, isChecked: false })));
  const reportsListRef = useRef(null);
  useEffect(() => {
    setData(reports.map((item) => ({ ...item, isOpen: false, isChecked: false })));
  }, [reports]);

  const onAssessmentTypeChange = (e) => {
    setAssessmentType(assessmentOptions.find((item) => item.value === e.target.value));
  };

  const onSetClick = (id) => {
    setData((prev) => prev.map((item) => ({ ...item, isOpen: id === item.reportSetID ? !item.isOpen : item.isOpen })));
    const isLast = data[data.length - 1].reportSetID === id;
    if (isLast && !data[data.length - 1].isOpen)
      setTimeout(() => reportsListRef.current.scrollBy({ top: 200, behavior: 'smooth' }), 350);
  };

  const onSetCheckboxClick = (setId) => {
    setData((prev) => {
      return prev.map((report) =>
        report.reportSetID === setId
          ? {
              ...report,
              isChecked: !report.isChecked,
              reports: report.reports.map((item) => ({
                ...item,
                isChecked: findIfReportIsDisabled(item, commonTests, idealProfiles) ? false : !report.isChecked,
              })),
            }
          : report,
      );
    });
  };

  const onSetReportClick = (setId, reportId) => {
    setData((prev) =>
      prev.map((report) => {
        if (report.reportSetID !== setId) return report;
        const wasChildChecked = report.reports.find((report) => report.reportID === reportId).isChecked;
        const checkedChildren = report.reports.filter((report) => report.isChecked);
        return {
          ...report,
          isChecked: !(report.isChecked && wasChildChecked && checkedChildren.length <= 1),
          reports: report.reports.map((report) =>
            report.reportID === reportId ? { ...report, isChecked: !report.isChecked } : report,
          ),
        };
      }),
    );
  };
  const filteredByType = useMemo(() => {
    if (assessmentType.value === 'test') return data.filter((item) => item.type === 'set' && !item.isSolutionReportSet);
    if (assessmentType.value === 'solution')
      return data.filter((item) => item.type === 'set' && item.isSolutionReportSet);
  }, [assessmentType, data]);

  const totalCost = calculateCost(
    data.filter((item) => item.isChecked),
    respondents,
  );

  const isEnoughCredits = totalCost <= credits;
  let isRequestButtonDisabled =
    isLoading || data.filter((report) => report.isChecked).length === 0 || !isEnoughCredits || !hasSelectedNorm;

  const deps = defineDependencies(data);
  const normsToShow = deps.normsDependencies.map((key) => (norms[key] ? norms[key] : undefined)).filter((item) => item);
  const profilesToShow = deps.profilesDependencies
    .map((key) => (idealProfiles[key] ? idealProfiles[key] : undefined))
    .filter((item) => item);

  const showOptions = Boolean(normsToShow.length || profilesToShow.length);

  if (showOptions === false) {
    isRequestButtonDisabled = false;
  }
  const isReportsAvailable = reports.length > 0;
  const ErrorMessage = () => {
    return <StyledErrorMessage>{I18n.t('selectNormForReport')}</StyledErrorMessage>;
  };
  if (normsToShow[0] && isRequestButtonDisabled) {
    normsToShow[0].value = {};
  }

  return (
    <Container>
      <CustomSelect
        placeholder={I18n.t('Language')}
        options={languages}
        selectProps={{
          value: language,
          onChange: setLanguage,
        }}
      />
      <StyledRadioWrapper
        options={assessmentOptions}
        label={I18n.t('Type')}
        field={{
          value: assessmentType.value,
          onChange: onAssessmentTypeChange,
        }}
      />
      {isReportsAvailable ? (
        <MainSectionWrapper>
          <ReportsWrapper showOptions={showOptions}>
            {Boolean(filteredByType.length) && <ListTitle>{I18n.t('Available Reports')}</ListTitle>}
            <ReportsItemsList ref={reportsListRef}>
              {filteredByType.map((item) => {
                switch (assessmentType.value) {
                  case 'test':
                    return (
                      <ReportsSet
                        key={item.reportSetID}
                        set={item}
                        onReportClick={onSetReportClick}
                        onSetCheckboxClick={onSetCheckboxClick}
                        onSetClick={onSetClick}
                        commonTests={commonTests}
                        idealProfiles={idealProfiles}
                      />
                    );
                  case 'battery':
                    return null;
                  case 'solution':
                    return (
                      <Solution
                        key={item.reportSetID}
                        solution={item}
                        onSolutionClick={onSetClick}
                        onReportClick={onSetReportClick}
                        onSolutionCheckboxClick={onSetCheckboxClick}
                        idealProfiles={idealProfiles}
                      />
                    );
                  default:
                    return null;
                }
              })}
            </ReportsItemsList>
          </ReportsWrapper>
          <OptionsWrapper showOptions={showOptions}>
            {showOptions && <ListTitle>{I18n.t('Options')}</ListTitle>}
            {Boolean(normsToShow.length) && (
              <NormsList areProfilesVisible={Boolean(profilesToShow.length)}>
                {isRequestButtonDisabled && <ErrorMessage />}
                {normsToShow.map((norm) => {
                  return (
                    <CompactSelect
                      key={`${norm.normID}${norm.name}`}
                      options={orderBy(norm.options, 'name')}
                      label={`${norm.name} ${I18n.t('Norm')}`}
                      selectProps={{ value: norm.value, onChange: (val) => onNormChange(norm.name, val) }}
                    />
                  );
                })}
              </NormsList>
            )}
            {Boolean(profilesToShow.length) && (
              <IdealProfilesList areNormsVisible={Boolean(normsToShow.length)}>
                {profilesToShow.map((idealProfile) => {
                  return (
                    <CompactSelect
                      key={idealProfile.idealProfileID}
                      options={idealProfile.options}
                      label={`${idealProfile.name} ${I18n.t('Ideal Profile')}`}
                      selectProps={{
                        value: idealProfile.value,
                        onChange: (val) => onIdealProfileChange(idealProfile.name, val),
                      }}
                    />
                  );
                })}
              </IdealProfilesList>
            )}
          </OptionsWrapper>
        </MainSectionWrapper>
      ) : (
        <EmptyMessageWrapper>
          <EmptyIcon />
          <EmptyMessage>{I18n.t('emptyLanguageReports')}</EmptyMessage>
        </EmptyMessageWrapper>
      )}
      <Footer>
        <ReportsCreditsCost creditsCost={totalCost} creditsAvailable={credits} />
        {multiStep ? (
          <ButtonsWrapper>
            <PrevButton variant="secondary" handler={toPrevStep}>
              {I18n.t('Previous')}
            </PrevButton>
            <CustomButton variant="primary" disabled={isRequestButtonDisabled} handler={() => onSubmit(data)}>
              {I18n.t('Request')}
            </CustomButton>
          </ButtonsWrapper>
        ) : (
          <CustomButton variant="primary" disabled={isRequestButtonDisabled} handler={() => onSubmit(data)}>
            {I18n.t('Request')}
          </CustomButton>
        )}
      </Footer>
    </Container>
  );
};

ReportsList.propTypes = {
  languages: array.isRequired,
  multiStep: bool.isRequired,
  language: object,
  setLanguage: func.isRequired,
  toPrevStep: func.isRequired,
  reports: array.isRequired,
  commonTests: array.isRequired,
  norms: object.isRequired,
  idealProfiles: object.isRequired,
  onSubmit: func.isRequired,
  isLoading: bool.isRequired,
  onNormChange: func.isRequired,
  onIdealProfileChange: func.isRequired,
  credits: number.isRequired,
  respondents: array.isRequired,
  hasSelectedNorm: bool.isRequired,
};

ReportsList.defaultProps = {
  language: {
    value: 'EN',
    code: 'EN',
    label: 'English',
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 1rem;
`;

const StyledRadioWrapper = styled(Radio)`
  margin-bottom: 3rem;
`;

const MainSectionWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
`;

const ReportsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.showOptions ? '60%' : '100%')};
  transition: width 0.3s;
  max-height: 36rem;
  height: calc(100vh - 30rem);
`;

const ReportsItemsList = styled.div`
  display: block;
  flex-direction: column;
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; /* Remove scrollbar space */
  }
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.showOptions ? '38%' : '0')};
  opacity: ${(props) => (props.showOptions ? 1 : 0)};
  transition: width, opacity 0.3s;
  max-height: 34rem;
`;

const NormsList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  border: 1px solid #cccccc;
  border-radius: 7px;
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; /* Remove scrollbar space */
  }
  height: ${(props) => {
    const baseHeight = 340;
    return props.areProfilesVisible ? `${baseHeight - 100}px` : 'auto';
  }};
  margin-bottom: ${(props) => (props.areProfilesVisible ? '5px' : 0)};
  flex-grow: 3;
`;

const IdealProfilesList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  border: 1px solid #cccccc;
  border-radius: 7px;
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; /* Remove scrollbar space */
  }
  height: ${(props) => (props.areNormsVisible ? `100px` : 'auto')};
  flex-grow: 1;
`;

const ListTitle = styled.span`
  font-size: 14px;
  color: ${(props) => props.theme.colors.lightBlue};
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Footer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 0;
  padding: 0 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  button {
    text-transform: uppercase;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

const PrevButton = styled(CustomButton)`
  margin-right: 10px;
`;

const EmptyMessageWrapper = styled.div`
  position: absolute;
  min-width: 300px;
  top: 55%;
  left: 55%;
  transform: translate(-55%, -55%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EmptyMessage = styled.p`
  margin: 15px 0 0 0;
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
`;

const StyledErrorMessage = styled.p`
  color: red;
`;

export default ReportsList;
