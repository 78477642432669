import React from 'react';
import { array } from 'prop-types';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';

import CustomButton from '../../reusable/Buttons/Button';
import ActionsDropdown from '../../reusable/ActionsDropdown';

import { ReactComponent as TriangleArrow } from '../../../assets/icons/triangle-arrow.svg';

const ProjectInviteDropdown = ({ ...props }) => {
  const CustomTrigger = ({ disabled, handler, isOpen }) => {
    return (
      <StyledButton disabled={disabled} handler={handler} variant="secondary" size="s">
        <span>{I18n.t('Invite')}</span>
        {isOpen ? <TriangleArrowUp disabled={disabled} /> : <TriangleArrowDown disabled={disabled} />}
      </StyledButton>
    );
  };

  return <ActionsDropdown customTrigger={CustomTrigger} bigVariant {...props} />;
};

ProjectInviteDropdown.propTypes = {
  actions: array.isRequired,
};

const TriangleArrowUp = styled(TriangleArrow)`
  width: 10px;

  path {
    fill: ${(props) => (props.disabled ? props.theme.colors.grey2 : props.theme.colors.lightBlue)};
  }
`;

const TriangleArrowDown = styled(TriangleArrowUp)`
  transform: rotate(180deg);
`;

const StyledButton = styled(CustomButton)`
  span {
    margin-right: 1rem;
  }
`;

export default ProjectInviteDropdown;
