import React from 'react';
import styled, { css } from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import { PanelTitleQuestion } from './stylesDND';
import isEqualProps from '../../../utils/isEqualProps';
import ActionsDropdown from '../ActionsDropdown';

const AccordionNestedList = ({ panel, category }) => {
  const getLanguages = (scale) => {
    const keys = Object.keys(scale.languages);

    if (!keys.length) {
      return '';
    }

    return `[${keys.map((key) => key).join(', ')}]`;
  };

  return (
    <>
      {category.competencyItems &&
        category.competencyItems.map((item, index) => {
          return (
            <Draggable key={`questionDND${item.itemID}`} draggableId={`questionDND${item.itemID}`} index={index}>
              {(provided, snapshot) => (
                <PanelTitleQuestionStyled
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                  draggable={snapshot.isDragging}
                  isBorderTop={index > 0}
                  pLeft={15}
                >
                  <span>
                    {item.item} {getLanguages(item)}
                  </span>
                  <RightBlock>
                    {!!item.type && <StyledFreeText>Free field</StyledFreeText>}
                    {!!panel.competencyType && (
                      <ActionsDropdownStyled
                        smallSize
                        actions={item.actions.map((question) => {
                          return {
                            ...question,
                            handler: () => question.handler && question.handler(item),
                          };
                        })}
                        color="primary"
                      />
                    )}
                  </RightBlock>
                </PanelTitleQuestionStyled>
              )}
            </Draggable>
          );
        })}
    </>
  );
};

const PanelTitleQuestionStyled = styled(PanelTitleQuestion)`
  display: flex;
  padding: 5px 0 5px 0;
  margin: 0 15px;
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey6};

  &:last-of-type {
    border-bottom: none;
  }

  ${(props) =>
    props.draggable &&
    css`
      margin: 0;
      padding-left: 15px;
    `}
`;

const ActionsDropdownStyled = styled(ActionsDropdown)`
  margin-left: 5px;
`;

const RightBlock = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 0;
`;

const StyledFreeText = styled.span`
  padding: 2px 8px;
  background-color: ${(props) => props.theme.colors.grey1};
  border: 1px solid ${(props) => props.theme.colors.grey2};
  border-radius: 4px;
`;

export default React.memo(AccordionNestedList, (prevProps, nextProps) => {
  return isEqualProps(prevProps, nextProps);
});
