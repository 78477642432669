import React, { useState } from 'react';
import { func, number, oneOfType, string, bool } from 'prop-types';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';

import NewModal from '../../reusable/NewModal';
import Steps from '../../reusable/Steps';
import PrepareStep from './PrepareStep';
import ReviewStep from './ReviewStep';
import SuccessfulImportStep from './SuccessfulImportStep';

import useStep from '../../../hooks/useStep';

const steps = ['prepare', 'preview', 'import'];

const ImportRespondentsModal = ({ groupId, onClose, isAssessmentProject, projectId, onSuccess }) => {
  const { step, goNext } = useStep({ steps });
  const [importedValues, setImportedValues] = useState([]);
  const [importedAmount, setImportedAmount] = useState(0);

  return (
    <StyledModal title={I18n.t('Import respondents')} isVisible onClose={onClose}>
      <Steps steps={steps} stepIndex={step} />
      {step === 0 && (
        <PrepareStep
          goNext={goNext}
          onClose={onClose}
          setImportedValues={setImportedValues}
          isAssessmentProject={isAssessmentProject}
        />
      )}
      {step === 1 && (
        <ReviewStep
          data={importedValues}
          goNext={goNext}
          onClose={onClose}
          setImportedAmount={setImportedAmount}
          onSuccess={onSuccess}
          groupId={groupId}
          isAssessmentProject={isAssessmentProject}
          projectId={projectId}
        />
      )}
      {step === 2 && <SuccessfulImportStep onClose={onClose} importedAmount={importedAmount} />}
    </StyledModal>
  );
};

ImportRespondentsModal.propTypes = {
  onClose: func.isRequired,
  onSuccess: func.isRequired,
  isAssessmentProject: bool,
  groupId: oneOfType([string, number]),
  projectId: oneOfType([string, number]),
};

ImportRespondentsModal.defaultProps = {
  groupId: null,
  projectId: null,
  isAssessmentProject: false,
};

const StyledModal = styled(NewModal)`
  height: 100%;
  max-height: 54rem;
  min-width: 82rem;
  max-width: 110rem;
  display: flex;
  flex-direction: column;
`;

export default ImportRespondentsModal;
