import React, { useRef } from 'react';
import range from 'lodash/range';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';

const buildData = (scale, maxNumber, rawData) => {
  const rawDataCopy = [...rawData];
  let minNumber = 0;
  const maxCheck = rawData.length - 1 === maxNumber;

  const loopLevel = (searchIndex, table, acc = []) => {
    if (table.indexOf(searchIndex) !== -1) {
      const append = table.splice(table.indexOf(searchIndex), 1)[0];
      const currentAcc = append ? acc.concat(append) : acc;
      return loopLevel(searchIndex, table, currentAcc);
    }
    return acc;
  };

  const getResultScalesTotalLength = (resultScales) => {
    return resultScales.reduce((acc, el) => acc.concat(...el), []).length;
  };

  const squeezeResultScales = (resultScales, until) => {
    const internalResultScales = resultScales.reverse();
    const chckIfOnlyOnesLeft = (resultScales) => !resultScales.find((arr) => arr.length > 1);

    const squeezeLoop = () => {
      const onlyOnes = chckIfOnlyOnesLeft(internalResultScales);
      if (getResultScalesTotalLength(internalResultScales) > until) {
        internalResultScales.forEach((arr) => {
          if (getResultScalesTotalLength(internalResultScales) <= until) return false;

          if (onlyOnes) {
            arr.pop();
          } else if (arr.length > 1) {
            arr.pop();
          }
        });
        squeezeLoop();
      }
    };
    squeezeLoop();

    return internalResultScales.reverse();
  };

  const paddResultScales = (resultScales, until) => {
    const internalResultScales = resultScales;
    const padding = until - getResultScalesTotalLength(resultScales);
    // eslint-disable-next-line no-unused-vars
    let levelScale = internalResultScales[internalResultScales.length - 1];
    levelScale = levelScale.concat(...Array(padding).fill(String(scale)));
    internalResultScales[internalResultScales.length - 1] = levelScale;
    return internalResultScales;
  };

  let resultScales = [];

  Array(scale)
    .fill('')
    .forEach((_, idx) => {
      const level = String(idx + 1);
      resultScales.push(loopLevel(level, rawDataCopy));
    });

  if (!maxCheck) {
    if (rawData.length - 1 > maxNumber) {
      resultScales = squeezeResultScales(resultScales, maxNumber + 1);
    } else {
      resultScales = paddResultScales(resultScales, maxNumber + 1);
    }
  }

  let removeFromEnd = 0;
  const result = resultScales.map((level, idx) => {
    if (idx === 0 && level.length === 0) {
      const number = minNumber;
      minNumber += 1;
      removeFromEnd = 1;
      return `${number}`;
    }

    if (level.length === 0) {
      return '-';
    }

    if (level.length === 1) {
      const number = minNumber;
      minNumber += 1;
      return `${number}`;
    }

    if (level.length > 1) {
      const startNumber = minNumber;
      let endNumber = startNumber + level.length - 1;

      if (idx === resultScales.length - 1) {
        if (removeFromEnd) {
          endNumber -= removeFromEnd;
          removeFromEnd = 0;
        }
      }

      minNumber = endNumber + 1;
      return `${startNumber}-${endNumber}`;
    }

    return '-';
  });

  return result;
};

const groups = {
  sten: 10,
  stanine: 9,
};

const SteTable = ({ norms, className, activeGroup }) => {
  const steType = `${activeGroup.name}Table`;

  const tableHeader = range(groups[activeGroup.name]);

  const tableRows = norms.map((norm) => {
    return [
      norm.scaleName,
      buildData(tableHeader.length, norm.max, norm[steType] ? norm[steType].split(';') : [], norm),
    ];
  });

  const scrollWrapperRef = useRef(null);

  return (
    <TableStyles className={className}>
      <ScrollWrapper ref={scrollWrapperRef}>
        <table>
          <thead>
            <tr>
              <th className="title-cell">{I18n.t('Scale')}</th>
              {tableHeader.map((col, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <th key={idx}>{col + 1}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableRows.map((row, idx) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={idx}>
                  <td>{row[0]}</td>
                  {row[1].map((col, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <td key={idx}>{col}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </ScrollWrapper>
    </TableStyles>
  );
};

const ScrollWrapper = styled.div`
  position: relative;
  overflow-y: auto;
  height: calc(100% - 70px);
`;

const TableStyles = styled.div`
  position: relative;
  height: calc(100% - 170px);

  table {
    table-layout: fixed;
    word-break: break-word;
    border-spacing: 0;
    color: ${(props) => props.theme.colors.primary};
    font-weight: 500;
    font-size: ${(props) => props.theme.fontSizes.small};
    width: auto;
    min-width: 100%;
    thead {
      user-select: none;
      th {
        position: sticky;
        top: 0;
        z-index: 8;
        color: ${(props) => props.theme.colors.darkBlue};
        &:last-child {
          word-wrap: initial;
          word-break: initial;
          width: 65px !important;
          max-width: 65px !important;
        }
        text-align: left;
        :hover {
          background-color: ${(props) => props.theme.colors.lightBlue1};
        }

        &.title-cell {
          width: 200px;
        }
      }
    }

    th {
      color: ${(props) => props.theme.colors.secondary};
      border-bottom: 1px solid ${(props) => props.theme.colors.grey6};
      padding: 16px 12px;
      font-weight: 600;
      background: ${(props) => props.theme.colors.white};
    }
    td {
      margin: 0;
      padding: 16px 12px;
      cursor: default;
      border-bottom: 1px solid ${(props) => props.theme.colors.grey6};
      max-width: 60px;
      font-size: 12px;
      line-height: 15px;
      color: #295368;
    }

    tr td:first-child,
    th:first-child {
      padding: ${(props) => (props.selectDisabled ? '2rem 1rem' : 0)};
    }

    tbody tr td {
      cursor: pointer;
      word-wrap: break-word;
    }
    tbody tr td:last-child {
      word-wrap: initial;
      word-break: initial;
    }
  }
`;

export default SteTable;
