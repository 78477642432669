import React from 'react';
import { format } from 'date-fns';
import styled, { keyframes, css } from 'styled-components';
import { capitalize } from 'lodash';

import { I18n } from 'react-redux-i18n';
import { caseInsensitiveSortingFunc } from './respondents';
import { reportTypesMap } from '../../store/reports/reducer';

import { ReactComponent as CompletedIcon } from '../../assets/icons/check.svg';
import { ReactComponent as UnreadIcon } from '../../assets/icons/unread.svg';
import { ReactComponent as ProgressIcon } from '../../assets/icons/progress.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg';
// import ActionsDropdown from '../../components/reusable/ActionsDropdown';

import { findReportFormat } from '../bulkActions/reports';
import { formatIconsMap } from './reports';

export const getReportID = (report) => (report && report.status === 'pending' ? 'requestID' : 'notificationID');

const statusIconsMap = {
  viewed: CompletedIcon,
  unread: UnreadIcon,
  pending: ProgressIcon,
  error: WarningIcon,
};

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
   100% {
     transform: rotate(360deg);
   }
`;

const animation = () =>
  css`
    ${rotate} 2s infinite linear;
  `;

const styleIcon = (Icon, status, withMargin) => styled(Icon)`
  margin-right: ${() => withMargin && '10px'};
  width: 18px;
  height: 18px;
  animation: ${() => (status === 'pending' ? animation : 'none')};

  .a {
    ${() =>
      status === 'error' &&
      css`
        fill: ${(props) => props.theme.colors.red};
        stroke: ${(props) => props.theme.colors.red};
        stroke-width: 0.3px;
      `}
  }
`;

const StatusCell = ({ reportType, error }) => {
  const resolvedStatus = reportTypesMap[reportType];
  const finalizedStatus = error ? 'error' : resolvedStatus;
  const Icon = resolvedStatus !== 'deleted' && styleIcon(statusIconsMap[finalizedStatus], finalizedStatus, true);
  return (
    <StatusContainer>
      {resolvedStatus !== 'deleted' && <Icon />}
      <StatusLabel error={error}>
        {finalizedStatus === 'error' ? I18n.t('Support ticket raised') : I18n.t(capitalize(finalizedStatus))}
      </StatusLabel>
    </StatusContainer>
  );
};

const DateCell = ({ row }) => <span>{format(new Date(row.original.creationDate), 'dd/M/y HH:mm')}</span>;

// const columns = (rowActions, setSelectedReports) => [
const columns = [
  {
    Header: I18n.t('Report'),
    name: 'report',
    sortType: caseInsensitiveSortingFunc,
    Cell: ({ cell }) => {
      const resolvedName = cell.row.original.name || cell.row.original.reportName || cell.row.original.questionnaire;
      const Icon = formatIconsMap[findReportFormat(cell.row.original)];
      return (
        <Row>
          {cell.row.original.downloadLink && <Icon />}
          <span>{resolvedName}</span>
        </Row>
      );
    },
    disableSortBy: true,
  },
  {
    Header: I18n.t('Status'),
    accessor: 'reportType',
    name: 'status',
    Cell: ({ cell }) => {
      return <StatusCell reportType={cell.value} error={cell.row.original.error} />;
    },
  },
  {
    Header: I18n.t('Creation Date'),
    width: 35,
    name: 'creationDate',
    accessor: (data) => new Date(data.creationDate),
    Cell: (props) => <DateCell {...props} />,
    sortType: 'datetime',
  },
  // {
  //   Header: I18n.t(''),
  //   name: 'actions',
  //   type: 'actions',
  //   id: 'actions',
  //   disableSortBy: true,
  //   Cell: ({ row }) => (
  //     <ActionsDropdown
  //       actions={rowActions.map((item) => ({
  //         ...item,
  //         handler: () => item.handler && item.handler(row.original),
  //         isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(row.original) : item.isDisabled,
  //       }))}
  //       clickHandler={() => setSelectedReports(row.original)}
  //     />
  //   ),
  //   width: 50,
  // },
];

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StatusLabel = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => (props.error ? props.theme.colors.red : props.theme.colors.primary)};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

export default columns;
