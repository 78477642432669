import handleActions from '../immerHandleActions';

import * as actions from './actions';

const initialState = {
  notifications: [],
  pageCount: 0,
  totalCount: 0,
};

const notificationsReducer = handleActions(
  {
    [actions.fetchNotificationsSuccess]: (draft, { payload: { data, shouldReset } }) => {
      draft.notifications = shouldReset ? data.notifications : [...draft.notifications, ...data.notifications];
      draft.totalCount = data.totalCount;
      draft.pageCount = data.pagesAvailable;
      draft.lastPageIndex = data.pageNumber;
    },
    [actions.resetNotificationsState]: () => {
      return initialState;
    },
  },
  initialState,
);

export default notificationsReducer;
