import React from 'react';
import { func, bool, object } from 'prop-types';
import { SketchPicker } from 'react-color';
import styled from 'styled-components';

const ColorPicker = ({ expanded, onExpandEvent, onChange, currentState }) => {
  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const onColorChange = (color) => {
    onChange('color', color.hex);
  };

  const renderModal = () => {
    const { color } = currentState;
    return (
      <ModalWrapper onClick={stopPropagation}>
        <SketchPicker color={color} onChangeComplete={onColorChange} />
      </ModalWrapper>
    );
  };
  return (
    <PopupDiv aria-haspopup="true" aria-expanded={expanded} aria-label="rdw-color-picker">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={onExpandEvent}>
        <ColorButton activeColor={currentState.color} />
      </div>
      {expanded ? renderModal() : undefined}
    </PopupDiv>
  );
};

ColorPicker.propTypes = {
  expanded: bool,
  onExpandEvent: func.isRequired,
  onChange: func.isRequired,
  currentState: object.isRequired,
};

ColorPicker.defaultProps = {
  expanded: false,
};

const ModalWrapper = styled.div`
  position: absolute;
  top: 3rem;
  left: 0;
  z-index: 1000;
`;

const PopupDiv = styled.div`
  position: relative;
  min-height: 4rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.6rem;
`;

const ColorButton = styled.div`
  padding: 0.2rem;
  border: 1px solid ${(props) => props.theme.colors.grey2};
  border-radius: 0.5rem;
  transition: border-color 0.2s;
  :hover {
    cursor: pointer;
    border-color: ${(props) => props.theme.colors.grey4};
  }
  background-color: ${(props) => props.activeColor};
  width: 2.2rem;
  height: 2.2rem;
  min-width: 2.2rem;
  min-height: 2.2rem;
  margin: 0 0.6rem;
`;

export default ColorPicker;
