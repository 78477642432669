import React, { useState } from 'react';
import { func, bool, array } from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { I18n } from 'react-redux-i18n';
import { useFormik } from 'formik';
import NewModal from '../reusable/NewModal';
import Spinner from '../reusable/Spinner';
import CustomTextArea from '../reusable/FormComponents/TextArea';
import { createNewGroup, createNewGroupFromBulkSelection } from '../../store/groups/actions';
import { errorMessages } from '../../constants/errorMessages';
import CustomButton from '../reusable/Buttons/Button';
import FormikInput from '../reusable/FormComponents/FormikInput';

const AddNewGroup = ({ onClose, selectedRespondents, isBulkFlowActive }) => {
  const dispatch = useDispatch();
  const [isLoading, setLoadingState] = useState(false);

  const { values, errors, touched, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: {
      name: '',
      notes: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required(errorMessages.inputField)
        .max(250, errorMessages.tooLong(250)),
      notes: Yup.string().max(1000, errorMessages.tooLong(1000)),
    }),
    onSubmit: (values) => {
      setLoadingState(true);

      const data = {
        description: values.name,
        notes: values.notes,
        groupType: 0,
      };
      if (!isBulkFlowActive)
        data.respondentIds = selectedRespondents.length ? selectedRespondents.map((item) => item.respondentID) : [];

      const method = isBulkFlowActive ? createNewGroupFromBulkSelection : createNewGroup;

      dispatch(
        method(data, (err, groupId) => {
          if (!err && typeof groupId !== 'undefined') {
            resetForm();
            onClose();
          } else {
            setLoadingState(false);
            resetForm();
            onClose();
          }
        }),
      );
    },
  });

  return (
    <StyledModal title={I18n.t('Add new group')} isVisible onClose={onClose}>
      <Spinner isLoading={isLoading} />
      <StyledBody>
        <InputsWrapper>
          <StyledInput
            inputName={I18n.t('Group name')}
            name="name"
            id="name"
            value={values.name}
            onChange={handleChange}
            errors={errors}
            touched={touched}
          />
          <CustomTextArea
            inputName={I18n.t('Group description')}
            name="notes"
            id="notes"
            value={values.notes}
            onChange={handleChange}
            errors={errors}
            touched={touched}
          />
        </InputsWrapper>
        <ButtonsWrapper>
          <CustomButton type="button" size="m" width={120} variant="secondary" onClick={onClose}>
            {I18n.t('Cancel')}
          </CustomButton>
          <CustomButton type="button" size="m" width={120} variant="primary" disabled={false} onClick={handleSubmit}>
            {I18n.t('Create')}
          </CustomButton>
        </ButtonsWrapper>
      </StyledBody>
    </StyledModal>
  );
};

AddNewGroup.propTypes = {
  onClose: func.isRequired,
  selectedRespondents: array,
  isBulkFlowActive: bool,
};
AddNewGroup.defaultProps = {
  selectedRespondents: [],
  isBulkFlowActive: false,
};

const StyledModal = styled(NewModal)`
  min-width: 45rem;
  max-height: 35rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

const StyledBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled(FormikInput)`
  margin-bottom: 15px;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  align-self: center;
  width: 70%;
  justify-content: space-between;
  align-items: center;
`;

export default AddNewGroup;
