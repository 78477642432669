import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { object } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { I18n } from 'react-redux-i18n';
import reportsColumns from '../../table/columns/reports';

import Table from '../reusable/Tables/Table';
import PageWrapper from '../PageWrapper';
import TableTabs from '../reusable/TableTabs';

import AdvancedFilter from '../reusable/AdvancedFilter';
import BulkActionsSidebar from '../BulkActionsSidebar';

import reportsBulkActions from '../../table/bulkActions/reports';

import useTableFilter from '../../hooks/useTableFilter';
import useBulkSidebarState from '../../hooks/useBulkSidebarState';
import downloadZippedArchiveFromReports from './reports.utils';

import {
  setSelectedReports,
  setActiveReportsTab,
  setReportsSearchValue,
  setReportsFilterValue,
  setReportsFilterType,
  applyReportsFilters,
  clearReportsFilters,
  deleteReportsAppliedFilter,
  fetchReports,
  deleteReports,
  markReportsAsViewed,
} from '../../store/reports/actions';
import { selectTotalReportsCount, selectReportsTabs } from '../../store/reports/selectors';
import * as actions from '../../table/actionsList';
import ConfirmationModal from '../reusable/ConfirmationModal';
import createToastNotification from '../../utils/createToastNotification';

const Reports = ({ location: { pathname } }) => {
  const reports = useSelector((state) => state.reports.reports);
  const selectedReports = useSelector((state) => state.reports.selectedReports);
  const tabs = useSelector(selectReportsTabs);
  const activeTab = useSelector((state) => state.reports.activeTab);
  const searchValue = useSelector((state) => state.reports.search);
  const filter = useSelector((state) => state.reports.filter);
  const totalCount = useSelector(selectTotalReportsCount);
  const pageCount = useSelector((state) => state.reports.pagesAvailable);
  const lastPageIndex = useSelector((state) => state.reports.lastPageIndex);

  const dispatch = useDispatch();
  const memoizedSetReportsSearchValue = useCallback((val) => dispatch(setReportsSearchValue(val)), []);
  const memoizedClearReportsFilters = useCallback(() => dispatch(clearReportsFilters()), []);
  const memoizedDeleteReportsAppliedFilter = useCallback(
    (fieldName) => dispatch(deleteReportsAppliedFilter(fieldName)),
    [],
  );
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pathname]);

  const [isLoading, setLoadingStatus] = useState(true);

  const fetchData = useCallback(({ tableData, shouldReset, showSpinner, tableCallback }) => {
    if (showSpinner) setLoadingStatus(true);
    dispatch(
      fetchReports(tableData, {
        callback: () => {
          setLoadingStatus(false);
          tableCallback();
        },
        shouldReset,
      }),
    );
  }, []);

  const [isDeletionModalVisible, setDeletionModalStatus] = useState(false);

  const closeConfirmationModal = () => setDeletionModalStatus(false);
  const openConfirmationModal = () => setDeletionModalStatus(true);

  const [shouldResetPage, setShouldResetPageStatus] = useState(false);

  const onSelectChange = useCallback((data) => {
    dispatch(setSelectedReports(data));
  }, []);

  const onDeleteConfirm = () => {
    const data = selectedReports.map((report) => ({
      reportType: report.reportType,
      notificationID: report.notificationID || null,
      requestID: report.requestID || null,
    }));
    dispatch(
      deleteReports(data, () => {
        setShouldResetPageStatus(true);
        closeConfirmationModal();
      }),
    );
  };

  const onDownloadClick = (reports) => {
    reports.forEach((report) => {
      const link = document.createElement('a');
      link.href = report.downloadLink;
      link.download = report.downloadLink.substr(report.downloadLink.lastIndexOf('/') + 1);
      link.click();
    });
    dispatch(markReportsAsViewed(reports.map((item) => item.notificationID)));
  };

  const onOpenClick = (reports) => {
    reports.forEach((report) => {
      window.open(report.downloadLink, '_blank');
    });
    dispatch(markReportsAsViewed(reports.map((item) => item.notificationID)));
  };

  // Controlling bulk actions sidebar state
  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedReports });

  // BULK ACTIONS
  const bulkActionsHandlers = {
    [actions.OPEN_REPORT]: () => onOpenClick(selectedReports),
    [actions.DOWNLOAD_REPORT]: () => {
      if (selectedReports.length > 50) {
        createToastNotification({
          message: I18n.t('downloadLimitToast'),
          type: 'error',
        });
        return;
      }

      if (selectedReports.length > 1)
        downloadZippedArchiveFromReports(
          selectedReports.filter((item) => item.reportType !== 1),
          () => {
            dispatch(markReportsAsViewed(selectedReports.map((item) => item.notificationID)));
          },
        );
      else onDownloadClick(selectedReports);
    },
    [actions.DELETE_REPORT]: openConfirmationModal,
  };

  const bulkActions = useMemo(() => {
    return reportsBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedReports) : item.isDisabled,
    }));
  }, [selectedReports]);

  // columns settings
  const columns = useMemo(() => {
    return reportsColumns;
  }, []);

  // Table filtering
  const setFilterType = (name, value) => dispatch(setReportsFilterType(name, value));
  const setFilterValue = (name, value) => dispatch(setReportsFilterValue(name, value));
  const { inputsWithHandlers, appliedFiltersWithColumnNames } = useTableFilter({
    filter,
    columns,
    setFilterType,
    setFilterValue,
  });

  const onFilterApply = () => {
    dispatch(applyReportsFilters());
  };

  const onTabClick = (tab) => {
    setShouldResetPageStatus(true);
    dispatch(setActiveReportsTab(tab));
  };
  const initialState = useMemo(
    () => ({
      sortBy: [{ id: 'Creation Date', desc: true }],
    }),
    [],
  );
  return (
    <PageWrapper title={I18n.t('Reports')}>
      <BulkActionsSidebar actions={bulkActions} isOpen={isSidebarOpen} onClose={closeSidebar} />
      <TableTabs tabs={tabs} clickHandler={onTabClick} activeTab={activeTab} />
      <AdvancedFilter
        searchProps={{
          appliedFilters: appliedFiltersWithColumnNames,
          deleteFilter: memoizedDeleteReportsAppliedFilter,
          searchValue,
          onSearchChange: memoizedSetReportsSearchValue,
        }}
        filterProps={{
          inputs: inputsWithHandlers,
          appliedInputs: filter.appliedInputs,
          applyFilters: onFilterApply,
          clearAll: memoizedClearReportsFilters,
          isDirty: filter.dirty,
        }}
      />
      <Table
        columns={columns}
        data={reports}
        initialState={initialState}
        idAccessor={(row) => (row.original.status === 'pending' ? 'requestID' : 'notificationID')}
        highlightRowOptions={{ comparator: (row) => row.reportType === 2 }}
        onSelectChange={onSelectChange}
        rowEqualityFunc={(prevItem, nextItem) => prevItem.reportType === nextItem.reportType}
        interceptCheckboxClick
        shouldResetPage={shouldResetPage}
        setShouldReset={setShouldResetPageStatus}
        entityName="report"
        isLoading={isLoading}
        onFetchData={fetchData}
        totalCount={totalCount}
        searchValue={searchValue}
        appliedFilters={appliedFiltersWithColumnNames}
        pageCount={pageCount}
        lastPageIndex={lastPageIndex}
      />
      <ConfirmationModal
        title={I18n.t('Report deletion')}
        onClose={closeConfirmationModal}
        isVisible={isDeletionModalVisible}
        description={I18n.t('Are you sure you want to delete selected report(s)?')}
        onConfirm={onDeleteConfirm}
        caution
      />
    </PageWrapper>
  );
};

Reports.propTypes = {
  location: object,
};

Reports.defaultProps = {
  location: {},
};

export default Reports;
