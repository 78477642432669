import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { I18n } from 'react-redux-i18n';

import Spinner from './reusable/Spinner';
import LogoSVG from './reusable/Logo_SVG';

import { fetchUserRegion } from '../store/user/actions';

import SelectLanguage from './reusable/SelectLanguage';
import langList from '../constants/selectableLanguages';

import storageService from '../services/storageService';

const LoginFormSchema = Yup.object().shape({
  email: Yup.string().required(I18n.t('Email is required')),
});

const Login = () => {
  const [userLang, setUserLang] = useState(storageService.getItem('psLang'));
  const langCodes = langList.map((lang) => lang.value.toLowerCase());

  useEffect(() => {
    if (!langCodes.includes(userLang)) {
      storageService.setItem('psLang', 'en');
      setUserLang('en');
    }

    return () => {};
  }, [userLang]);

  const { isLoading } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const validateEmail = (value) => {
    let error;

    if (!value) {
      error = 'Email is required';
    } else if (!(/^[\w.'-]+@[\w.-]+\.\w+$/i.test(value) || /^masteruser@[\w.-]+$/i.test(value))) {
      error = 'Invalid email address';
    }
    return error;
  };

  const { handleSubmit, handleChange, values, errors, setErrors } = useFormik({
    validationSchema: LoginFormSchema,
    validateOnChange: false,
    initialValues: {
      email: '',
    },
    onSubmit: ({ email }) => {
      const error = validateEmail(email);
      if (!error) {
        dispatch(
          fetchUserRegion({ email }, (err) => {
            if (err) {
              setErrors({ major: err, email: true });
            } else {
              const urlData = {
                email,
                langId: userLang,
              };
              const urlQuery = new URLSearchParams(urlData);
              // const langQuery = new URLSearchParams({ langId });
              const userRegion = storageService.getItem('regionUrl');
              const localUrl = `/login-region?${urlQuery}`;
              const remoteUrl = `${userRegion}?${urlQuery}`;
              if (window._env_.REACT_APP_PSEUDO_NODE_ENV === 'development') {
                dispatch(push(localUrl));
              } else {
                window.location.assign(remoteUrl, 'Psytech', 'menubar=0,toolbar=0,location=0,status=0');
              }
            }
          }),
        );
      } else {
        setErrors({ major: error, email: true });
      }
    },
  });

  const onInputChange = (event) => {
    handleChange(event);
    setErrors({ email: '', major: '' });
  };

  return (
    <BgContainer>
      <Spinner isLoading={isLoading} />
      <LoginContainer>
        <StyledLogo />
        <StyledForm onSubmit={handleSubmit}>
          <InputsWrapper>
            <InputContainer>
              <InputLabel error={errors.email}>{I18n.t('Email')}</InputLabel>
              <StyledInput
                error={errors.email}
                type="text"
                name="email"
                id="email"
                onChange={onInputChange}
                value={values.email}
              />
            </InputContainer>
          </InputsWrapper>
          <Error>{errors.major || errors.email}</Error>
          <SubmitButton type="submit">{I18n.t('LoginNext')}</SubmitButton>
        </StyledForm>
        <StyledLanguageSelector>
          <SelectLanguage withRedirect />
        </StyledLanguageSelector>
      </LoginContainer>
    </BgContainer>
  );
};

const BgContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.background};
`;

const LoginContainer = styled.div`
  display: flex;
  width: 42rem;
  flex-direction: column;
  padding: 30px 60px;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 0 44px #00000012;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputLabel = styled.span`
  color: ${(props) => (props.error ? 'red' : props.theme.colors.text)};
  font-size: 12px;
  margin-left: 15px;
  margin-bottom: 5px;
  transition: all 0.2s;
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  width: 300px;
  margin-bottom: 10px;
  outline: none;
  border: 1px solid ${(props) => (props.error ? 'red' : props.theme.colors.text)};
  border-radius: 20px;
  padding: 13px 15px;
  opacity: 0.5;
  transition: all 0.2s;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  :hover {
    ${StyledInput} {
      border-color: ${(props) => (props.error ? 'red' : props.theme.colors.menuBg)};
    }
    ${InputLabel} {
      color: ${(props) => (props.error ? 'red' : props.theme.colors.menuBg)};
    }
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  outline: none;
  border: none;
  padding: 15px;
  text-align: center;
  background-color: ${(props) => props.theme.colors.menuBg};
  color: ${(props) => props.theme.colors.white};
  font-size: 16px;
  font-weight: 600;
  transition: all 0.2s;
  border-radius: 20px;
  box-shadow: 0 0 21px #00000014;
  text-transform: uppercase;

  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.menuBg};
  }
`;

const StyledLanguageSelector = styled.div`
  width: 100%;
  div {
    width: unset;
  }
`;

const Error = styled.div`
  display: flex;
  align-items: center;
  color: red;
  height: 18px;
  font-size: 12px;
  margin: 0 0 10px 0;
`;

const StyledLogo = styled(LogoSVG)`
  margin-bottom: 30px;
  margin-left: 1rem;
  min-height: 35px;

  svg {
    fill: ${(props) => props.theme.colors.menuBg};
    height: 80px;
    scale: 1.3;
  }
`;

export default Login;
