import { createAction } from 'redux-actions';
import { I18n } from 'react-redux-i18n';
import apiInstance2 from '../../../services/apiService';
import createToastNotification from '../../../utils/createToastNotification';

export const fetchCompetencies = createAction('[COMPETENCIES] FETCH_DATA');
export const fetchCompetenciesSuccess = createAction('[COMPETENCIES] FETCH_DATA_SUCCESS', (categories, data) => ({
  categories,
  data,
}));
export const fetchCompetenciesFailure = createAction('[COMPETENCIES] FETCH_DATA_FAILURE');

export const addCompetencyCategory = createAction('[COMPETENCIES] ADD_CATEGORY', (data) => ({
  data,
}));
export const updateCompetencyCategory = createAction('[COMPETENCIES] UPDATE_CATEGORY', (data) => ({
  data,
}));

export const deleteCompetenciesCategory = createAction('[COMPETENCIES] DELETE_COMPETENCIES_CATEGORY');
export const deleteCompetenciesCategorySuccess = createAction(
  '[COMPETENCIES] DELETE_COMPETENCIES_CATEGORY_SUCCESS',
  (data) => ({ data }),
);
export const deleteCompetenciesCategoryFailure = createAction('[COMPETENCIES] DELETE_COMPETENCIES_CATEGORY_FAILURE');

export const addCompetencyToCategory = createAction('[COMPETENCIES] ADD_COMPETENCY_TO_CATEGORY', (data) => ({ data }));
export const updateCompetencyToCategory = createAction('[COMPETENCIES] UPDATE_COMPETENCY_TO_CATEGORY', (data) => ({
  data,
}));

export const addCompetencyQuestion = createAction('[COMPETENCIES] ADD_COMPETENCY_QUESTION', (data) => ({ data }));
export const updateCompetencyQuestion = createAction('[COMPETENCIES] UPDATE_COMPETENCY_QUESTION', (data) => ({ data }));

export const deleteCompetency = createAction('[COMPETENCIES] DELETE_COMPETENCY');
export const deleteCompetencySuccess = createAction('[COMPETENCIES] DELETE_COMPETENCY_SUCCESS', (data) => ({ data }));
export const deleteCompetencyFailure = createAction('[COMPETENCIES] DELETE_COMPETENCY_FAILURE');

export const deleteCompetencyQuestionSuccess = createAction(
  '[COMPETENCIES] DELETE_COMPETENCY_QUESTION_SUCCESS',
  (data) => ({ data }),
);
export const deleteCompetencyQuestionFailure = createAction('[COMPETENCIES] DELETE_COMPETENCY_QUESTION_FAILURE');

export const resetCompetencies = createAction('[COMPETENCIES] RESET');

export const fetchCompetenciesAction = () => async (dispatch) => {
  dispatch(fetchCompetencies());

  try {
    const categories = await apiInstance2.get('/api/v2/360/competency-categories');
    const competencies = await apiInstance2.get(`/api/v2/360/competencies`);

    if (competencies.status === 200) {
      dispatch(fetchCompetenciesSuccess(categories.data, competencies.data));
    }
  } catch (err) {
    dispatch(fetchCompetenciesFailure());
  }
};

export const deleteCompetenciesCategoryAction = (data) => async (dispatch) => {
  dispatch(deleteCompetenciesCategory());

  try {
    const response = await apiInstance2.delete('/api/v2/360/competencies', { data });

    if (response.status === 200) {
      dispatch(deleteCompetenciesCategorySuccess(data));
      createToastNotification({ message: I18n.t('categoryDeletionToast') });
    }
  } catch (err) {
    dispatch(deleteCompetenciesCategoryFailure());
  }
};

export const deleteCompetencyAction = (data) => async (dispatch) => {
  dispatch(deleteCompetency());

  try {
    const response = await apiInstance2.delete('/api/v2/360/competencies', { data });

    if (response.status === 200) {
      dispatch(deleteCompetencySuccess(data));
      createToastNotification({ message: I18n.t('competencyDeletionToast') });
    }
  } catch (err) {
    dispatch(deleteCompetencyFailure());
  }
};

export const deleteCompetencyQuestionAction = (data) => async (dispatch) => {
  try {
    const response = await apiInstance2.delete(`/api/v2/360/competencies/${data.competencyID}/item`, {
      data: [data.itemID],
    });

    if (response.status === 200) {
      dispatch(
        deleteCompetencyQuestionSuccess({
          competencyID: data.competencyID,
          itemID: data.itemID,
        }),
      );
      createToastNotification({ message: I18n.t('questionDeletionToast') });
    }
  } catch (err) {
    dispatch(deleteCompetencyQuestionFailure());
  }
};
