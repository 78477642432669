import React from 'react';
import { instanceOf, array, number, object, func } from 'prop-types';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import CustomSelect from '../../reusable/Selects/Select';
import RadioButton from '../../reusable/FormComponents/RadioButton';
import { sendInviteOptions } from '../../Respondents/CreateInvite/InvitationStep';
import DateTimeSelect from '../../reusable/Selects/DateTimeSelect';

const GroupsInvitationStep = ({
  respondentsCount,
  respondentsWithoutEmailsCount,
  templates,
  onValueChange,
  template,
  invitationType,
  invitationDate,
}) => {
  const areEmailsMissing = respondentsCount === respondentsWithoutEmailsCount;
  const isFormDisabled = areEmailsMissing;

  return (
    <Container>
      <Title>{I18n.t('Send invitation link via email for respondents', { respondentsCount })}</Title>
      <EmailMessage>{I18n.t('Invite will be sent to respondents with valid email address only')}</EmailMessage>
      {areEmailsMissing && <ErrorMessage>{I18n.t('missingEmailError')}</ErrorMessage>}
      <CustomSelect
        options={templates}
        placeholder={I18n.t('Email template')}
        selectProps={{ value: template, onChange: (val) => onValueChange('template', val) }}
        isDisabled={isFormDisabled}
      />
      <Label>{I18n.t('When do you want to send an invite?')}</Label>
      <InvitationTypesWrapper isDisabled={isFormDisabled}>
        {sendInviteOptions.map((item) => (
          <RadioOption
            key={item.value}
            isActive={item.value === invitationType.value}
            onClick={() => !isFormDisabled && onValueChange('invitationType', item)}
          >
            <RadioButton isChecked={item.value === invitationType.value} readOnly />
            <span>{I18n.t(item.label)}</span>
          </RadioOption>
        ))}
      </InvitationTypesWrapper>
      {invitationType.value === 'later' && (
        <StyledTimeSelect
          date={invitationDate}
          onDateChange={(date) => onValueChange('invitationDate', date)}
          inputName={I18n.t('Invitation date')}
          minDate={new Date()}
          disabled={isFormDisabled}
        />
      )}
    </Container>
  );
};

GroupsInvitationStep.propTypes = {
  respondentsCount: number.isRequired,
  respondentsWithoutEmailsCount: number.isRequired,
  templates: array.isRequired,
  invitationType: object.isRequired,
  template: object.isRequired,
  invitationDate: instanceOf(Date),
  onValueChange: func.isRequired,
};

GroupsInvitationStep.defaultProps = {
  invitationDate: null,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  width: 50%;
  align-self: center;
`;

const Title = styled.h3`
  font-size: ${(props) => props.theme.fontSizes.medium};
  font-weight: bold;
  margin-bottom: 2rem;
  text-align: center;
`;

const EmailMessage = styled.span`
  font-weight: 500;
  color: ${(props) => props.theme.colors.grey4};
  text-align: center;
  margin-bottom: 2rem;
  font-size: ${(props) => props.theme.fontSizes.normal};
`;

const ErrorMessage = styled.span`
  font-size: ${(props) => props.theme.fontSizes.normal};
  font-weight: 500;
  color: ${(props) => props.theme.colors.red};
  margin-bottom: 2rem;
  text-align: center;
`;

const Label = styled.span`
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 2rem;
`;

const InvitationTypesWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
`;

const RadioOption = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2rem;
  user-select: none;

  :hover {
    cursor: pointer;
  }

  span {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: bold;
    margin-left: 0.5rem;
    color: ${(props) => (props.isActive ? props.theme.colors.lightBlue : props.theme.colors.darkBlue2)};
  }
`;
const StyledTimeSelect = styled(DateTimeSelect)`
  margin-bottom: 2rem;
`;

export default GroupsInvitationStep;
