import { createSelector } from 'reselect';
import { I18n } from 'react-redux-i18n';

const getTemplates = (state) => state.templates.templates;
const getTemplateBodyVariables = (state) => state.templates.bodyVariables;
const getTemplateSubjectVariables = (state) => state.templates.subjectVariables;

const defaultValue = {
  value: null,
  label: I18n.t('**Default'),
};

export const selectTemplatesAsOptions = createSelector(getTemplates, (templates) => {
  const temps = templates.map((item) => ({ ...item, value: item.templateID, label: item.name }));
  temps.unshift(defaultValue);
  return temps;
});

export const selectTemplateBodyVariables = createSelector(getTemplateBodyVariables, (variables) =>
  variables.map((item) => ({ value: item, label: item })),
);

export const selectTemplateSubjectVariables = createSelector(getTemplateSubjectVariables, (variables) =>
  variables.map((item) => ({ value: item, label: item })),
);
