import { createAction } from 'redux-actions';
import { push } from 'redux-first-history';
import { I18n } from 'react-redux-i18n';
import apiInstance2 from '../../../services/apiService';
import createToastNotification from '../../../utils/createToastNotification';

export const fetchCompetencyGroupsDetails = createAction('[COMPETENCY_GROUPS_DETAILS] FETCH_DATA');
export const fetchCompetencyGroupsDetailsSuccess = createAction(
  '[COMPETENCY_GROUPS_DETAILS] FETCH_DATA_SUCCESS',
  (summary, scales, scalesDefault, langID) => ({
    summary,
    scales,
    scalesDefault,
    langID,
  }),
);
export const fetchCompetencyGroupsDetailsFailure = createAction('[COMPETENCY_GROUPS_DETAILS] FETCH_DATA_FAILURE');
export const selectCompetencyGroupsDetailsScale = createAction(
  '[COMPETENCY_GROUPS_DETAILS] SELECT_SCALE',
  (scale, langID) => ({
    scale,
    langID,
  }),
);
export const sortQuestions = createAction('[COMPETENCY_GROUPS_DETAILS] SORT_QUESTIONS', (order, isCustom) => ({
  order,
  isCustom,
}));
export const setCustomOrderSequence = createAction(
  '[COMPETENCY_GROUPS_DETAILS] SET_CUSTOM_ORDER_SEQUENCE',
  (customOrderSequence) => ({
    customOrderSequence,
  }),
);
export const removeCompetenciesSuccess = createAction(
  '[COMPETENCY_GROUPS_DETAILS] REMOVE_COMPETENCIES_SUCCESS',
  (data) => ({
    data,
  }),
);
export const removeCompetenciesFailure = createAction('[COMPETENCY_GROUPS_DETAILS] REMOVE_COMPETENCIES_FAILURE');
export const removeAssessmentFailure = createAction('[COMPETENCY_GROUPS_DETAILS] REMOVE_COMPETENCIES_FAILURE');
export const calculateSummary = createAction('[COMPETENCY_GROUPS_DETAILS] CALCULATE_SUMMARY');
export const updateTitle = createAction('[COMPETENCY_GROUPS_DETAILS] UPDATE_TITLE', (data) => ({
  data,
}));
export const setSelectedScale = createAction('[COMPETENCY_GROUPS_DETAILS] SET_SELECTED_SCALE', (data) => ({
  data,
}));
export const setSelectedOrder = createAction('[COMPETENCY_GROUPS_DETAILS] SET_SELECTED_ORDER', (data) => ({
  data,
}));
export const saveDetailsSuccess = createAction('[COMPETENCY_GROUPS_DETAILS] SAVE_DETAILS_SUCCESS');
export const saveDetailsFailure = createAction('[COMPETENCY_GROUPS_DETAILS] SAVE_DETAILS_FAILURE');
export const resetCompetencyGroupsDetails = createAction('[COMPETENCY_GROUPS_DETAILS] RESET');

export const removeAssessment = (detailsGroupId) => async (dispatch) => {
  try {
    const response = await apiInstance2.delete(`/api/v2/360/competency-groups`, {
      data: [Number(detailsGroupId)],
    });

    if (response.status === 200) {
      dispatch(push('/settings/360-assessments'));
      createToastNotification({ message: I18n.t('deletedAssessmentToast') });
    }
  } catch (err) {
    dispatch(removeAssessmentFailure());
  }
};

export const removeCompetencies = (groupId, ids, cb) => async (dispatch) => {
  try {
    const response = await apiInstance2.delete(`/api/v2/360/competency-groups/${groupId}/competencies`, {
      data: ids,
    });

    if (response.status === 200) {
      dispatch(removeCompetenciesSuccess(ids));
      dispatch(calculateSummary());
      if (cb) cb();
    }
  } catch (err) {
    dispatch(removeCompetenciesFailure());
    if (cb) cb();
  }
};

export const fetchCompetencyGroupsDetailsAction = (groupId, langID) => async (dispatch) => {
  dispatch(fetchCompetencyGroupsDetails());

  try {
    const summary = await apiInstance2.get(`/api/v2/360/competency-groups/${groupId}`);
    const scales = await apiInstance2.get(`/api/v2/360/response-scales`);
    const scalesDefault = await apiInstance2.get(`/api/v2/360/response-scales/default`);

    if (summary.status === 200) {
      dispatch(fetchCompetencyGroupsDetailsSuccess(summary.data, scales.data, scalesDefault.data, langID));
    }
  } catch (err) {
    dispatch(fetchCompetencyGroupsDetailsFailure());
  }
};

export const saveDetails = (data) => async (dispatch) => {
  try {
    const response = await apiInstance2.patch(`/api/v2/360/competency-groups`, data);

    if (response.status === 200) {
      dispatch(saveDetailsSuccess());
      createToastNotification({ message: I18n.t('surveyUpdatedToast') });
    }
  } catch (err) {
    dispatch(saveDetailsFailure());
  }
};
