import React, { useReducer, useEffect } from 'react';
import { func, array, string, number, oneOfType } from 'prop-types';
import { addMonths, subMinutes } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { I18n } from 'react-redux-i18n';
import Modal from '../../reusable/NewModal';
import CustomButton from '../../reusable/Buttons/Button';

import { SpecifyOwnRatersInvitationStep } from './SpecifyOwnRatersInvitationStep';

import { selectTemplatesAsOptions } from '../../../store/settings/templates/selectors';
import { selectWelcomeTextsOptions } from '../../../store/settings/welcome-texts/selectors';
import { selectSessionInviteLanguages } from '../../../store/sessions/selectors';
import { fetchSessionInviteLanguages, inviteSpecifyOwnRaters } from '../../../store/sessions/actions';

const initState = (languages, welcomeTexts, templates) => {
  const english = languages.find((item) => item.langID === 'EN');
  return {
    language: languages.length ? english || languages[0] : null,
    expiryDate: addMonths(new Date(), 1),
    welcomeTextSelf: welcomeTexts[0],
    templateSelf: templates[0],
    invitationDate: null,
  };
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'changeValue':
      return { ...state, [payload.name]: payload.value };
    case 'reset':
      return { ...initState(payload.languages, payload.welcomeTexts, payload.templates) };
    default:
      throw new Error();
  }
};

export const SpecifyOwnRatersInvite = ({ onClose, items, sessionId, onSuccess }) => {
  const templates = useSelector(selectTemplatesAsOptions);
  const welcomeTexts = useSelector(selectWelcomeTextsOptions);
  const languages = useSelector(selectSessionInviteLanguages);
  const [values, reducerDispatch] = useReducer(reducer, initState(languages, welcomeTexts, templates));
  const activeProjectInView = useSelector((state) => state.projects.activeProjectInView);

  const dispatch = useDispatch();
  const changeValue = (name, value) => {
    reducerDispatch({ type: 'changeValue', payload: { name, value } });
  };

  // default language english or, if it's missing, first one on the list
  useEffect(() => {
    if (languages.length) changeValue('language', languages.find((item) => item.langID === 'EN') || languages[0]);
  }, [languages.length]);

  useEffect(() => {
    if (activeProjectInView) dispatch(fetchSessionInviteLanguages(activeProjectInView.competencyGroupID || -1));
  }, [activeProjectInView]);

  const closeModal = () => {
    reducerDispatch({ type: 'reset', payload: { languages, templates, welcomeTexts } });
    onClose();
  };

  const onSessionsInvite = async () => {
    const data = {
      welcomeTextTID: values.welcomeTextSelf.value,
      emailTID: values.templateSelf.value,
      sessionIDs: sessionId ? [Number(sessionId)] : items.map((item) => Number(item.sessionId)),
      langID: values.language.code,
      expiryDate: values.expiryDate,
      sendInviteDate: values.invitationDate || subMinutes(new Date(), 1),
    };

    await dispatch(inviteSpecifyOwnRaters(activeProjectInView.projectId, data));
    closeModal();
    if (onSuccess) onSuccess();
  };

  const isNextButtonDisabled = !values.language || !values.expiryDate;

  return (
    <StyledModal isVisible onClose={closeModal} title={I18n.t('Invite to specify own raters')}>
      <Description>{I18n.t('Send invite to self with a request to specify their own raters')}</Description>
      <Content>
        <SpecifyOwnRatersInvitationStep
          welcomeTexts={welcomeTexts}
          templates={templates}
          languages={languages}
          onValueChange={changeValue}
          values={values}
        />
      </Content>
      <Footer>
        <Button handler={onSessionsInvite} disabled={isNextButtonDisabled}>
          {I18n.t('Send')}
        </Button>
      </Footer>
    </StyledModal>
  );
};

SpecifyOwnRatersInvite.propTypes = {
  onClose: func.isRequired,
  items: array,
  sessionId: oneOfType([string, number]),
  onSuccess: func,
};

SpecifyOwnRatersInvite.defaultProps = {
  sessionId: '',
  onSuccess: undefined,
  items: [],
};

const StyledModal = styled(Modal)`
  width: 75rem;
  height: 75rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem;
`;

const Description = styled.p`
  margin: 0 0 2rem 0;
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;
  color: ${(props) => props.theme.colors.darkBlue2};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
`;

const Button = styled(CustomButton)`
  text-transform: uppercase;
  width: 14rem;
`;
