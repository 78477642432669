const translations = {
  Launch: 'Launch (tr)',
  About: 'About (tr)',
  Tutorial: 'Tutorial (tr)',

  // vars
  LoginSubmit: 'Login',
};

export default translations;
