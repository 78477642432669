const langList = [
  { value: 'EN', label: 'English' },
  { value: 'CH', label: '中文(简体) Chinese(Simplified)' },
  { value: 'CHTR', label: '中文(繁體) Chinese(Traditional)' },
  { value: 'IT', label: 'Italiano (Italian)' },
  { value: 'UA', label: 'Українська (Ukrainian)' },
  { value: 'ES', label: 'Español (Spanish)' },
  { value: 'ES-LATAM', label: 'Español Americano (American Spanish)' },
];

export default langList;
