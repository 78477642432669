// RESPONDENTS
export const ADMINISTER_ASSESSMENT = 'administerAssessment';
export const CREATE_INVITE = 'createInvite';
export const REQUEST_REPORT = 'requestReport';
export const ANSWER_SHEET = 'answerSheet';
export const MANAGE_TAGS = 'addTag';
export const CREATE_GROUP_FROM_SELECTION = 'createGroupFromSelection';
export const ADD_TO_GROUP = 'addToGroup';
export const DELETE_RESPONDENT = 'deleteRespondent';
export const EDIT_RESPONDENT = 'editRespondent';
export const ADD_RESPONDENT = 'addRespondent';
export const IMPORT_RESPONDENTS = 'importRespondents';
export const REMOVE_RESPONDENT_FROM_GROUPS = 'removeRespondentFromGroups';
export const REMOVE_RESPONDENTS_FROM_GROUP = 'removeRespondentsFromGroup';

// PROJECTS
export const ADD_PROJECT = 'addProject';
export const ADD_SESSION = 'addSession';
export const EDIT_PROJECT_DETAILS = 'editProjectDetails';
export const DELETE_PROJECTS = 'deleteProjects';
export const PROJECT_REQUEST_REPORT = 'projectRequestReport';
export const PROJECT_INVITE_UNINVITED_SESSIONS = 'projectInviteUninvitedSessions';
export const PROJECT_INVITE_INVITED_SESSIONS = 'projectInviteInvitedSessions';

// GROUPS
export const EDIT_GROUP_DETAILS = 'editGroupDetails';
export const DUPLICATE_GROUP = 'duplicateGroup';
export const ADMINISTER_GROUP_SESSION = 'administerGroupSession';
export const END_GROUP_SESSION = 'endGroupSession';
export const AUTHORIZE_RESPONDENT = 'authorizeRespondent';
export const IMPORT_GROUP = 'importGroup';
export const CREATE_GROUP = 'createGroup';
export const GROUP_CREATE_INVITE = 'groupCreateInvite';
export const ADMINISTER_MANAGED_GROUP = 'administerManagedGroup';
export const MANAGE_ACTIVE_LINKS = 'manageActiveLinks';
export const GROUP_REQUEST_REPORT = 'groupRequestReport';
export const DELETE_GROUPS = 'deleteGroups';
export const CREATE_NORM_BASED_ON_GROUP = 'createNormBasedOnGroup';
export const CREATE_PROFILE_BASED_ON_GROUP = 'createIdealProfileBasedOnGroup';

// INVITES
export const DELETE_INVITE = 'deleteInvite';
export const COPY_LINK = 'copyLink';
export const RESET_DEADLINE = 'resetDeadlineInvite';

// REPORTS
export const DOWNLOAD_REPORT = 'downloadReport';
export const OPEN_REPORT = 'openReport';
export const DELETE_REPORT = 'deleteReports';

// SESSIONS
export const DELETE_SESSIONS = 'deleteSessions';
export const SESSION_INVITE_RATERS = 'sessionInviteRaters';
export const SESSION_INVITE_SPECIFY_RATERS = 'sessionInviteSpecifyRaters';
export const SESSION_REQUEST_REPORT = 'sessionRequestReport';
export const IMPORT_SESSIONS = 'importSessions';

// RATERS
export const ADD_RATERS = 'addRaters';
export const DELETE_RATERS = 'deleteRaters';
export const SEND_INVITE_TO_RATERS = 'sendInviteToRaters';

// RESPONDENT TESTS
export const DELETE_COMPLETED_TEST = 'deleteCompletedTest';

// RESPONSE SCALES
export const VIEW_SCALE = 'viewScale';
export const DELETE_SCALE = 'deleteScale';
export const ADD_SCALE = 'addScales';

// COMPETENCIES GROUPS
export const VIEW_COMPETENCY_GROUP = 'viewCompetencyGroup';
export const DELETE_COMPETENCY_GROUP = 'deleteCompetencyGroup';
export const ADD_COMPETENCY_GROUP = 'addCompetencyGroup';
export const DELETE_COMPETENCY_GROUPS = 'deleteCompetencyGroups';

// COMPETENCIES GROUPS DETAILS
export const EDIT_TITLE_COMPETENCY_GROUPS_DETAILS = 'editTitleCompetencyGroupsDetails';
export const DUPLICATE_COMPETENCY_GROUPS_DETAILS = 'duplicateCompetencyGroupsDetails';
export const DELETE_COMPETENCY_GROUPS_DETAILS = 'deleteCompetencyGroupsDetails';
export const REVIEW_COMPETENCY_GROUPS_DETAILS = 'reviewCompetencyGroupsDetails';
export const SAVE_COMPETENCY_GROUPS_DETAILS = 'saveCompetencyGroupsDetails';

// COMPETENCIES
export const ADD_COMPETENCY = 'addCompetency';
export const DUPLICATE_COMPETENCY = 'duplicateCompetency';
export const ADD_COMPETENCY_CATEGORY = 'addCompetencyCategory';
export const EDIT_COMPETENCY_CATEGORY = 'editCompetencyCategory';
export const DELETE_COMPETENCY_CATEGORY = 'deleteCompetencyCategory';
export const EDIT_COMPETENCY_ITEM = 'editCompetencyItem';
export const ADD_QUESTION = 'addQuestion';
export const EDIT_QUESTION = 'editQuestion';
export const DELETE_QUESTION = 'deleteQuestion';
export const DELETE_COMPETENCY_ITEM = 'deleteCompetencyItem';

// NORMS
export const SHOW_NORM_REPORTS = 'useNormForReport';
export const HIDE_NORM_REPORTS = 'hideNormForReport';
export const ADD_NEW_NORM = 'addNewNorm';
export const DELETE_NORMS = 'deleteNorms';

// IDEAL PROFILES
export const ADD_NEW_IDEAL_PROFILE = 'addNewIdealProfile';
export const EDIT_IDEAL_PROFILE = 'editIdealProfile';
export const DELETE_IDEAL_PROFILES = 'deleteIdealProfiles';
export const SAVE_IDEAL_PROFILE_CHANGES = 'saveIdealProfileChanges';

// RESPONSE  SCALES
export const REMOVE_RESPONSE_SCALES = 'removeResponseScales';

// TEMPLATES
export const CREATE_TEMPLATE = 'createTemplate';
export const EDIT_TEMPLATE = 'editTemplate';
export const DELETE_TEMPLATES = 'deleteTemplates';

// WELCOME TEXTS
export const CREATE_WELCOME_TEXT = 'createWelcomeText';
export const DELETE_WELCOME_TEXTS = 'deleteWelcomeTexts';
export const EDIT_WELCOME_TEXT = 'editWelcomeText';

// ASSESSMENTS
export const ADD_CUSTOM_BATTERY = 'addCustomBattery';
export const EDIT_CUSTOM_BATTERY = 'editCustomBattery';
export const DELETE_CUSTOM_BATTERY = 'deleteCustomBattery';

// ASSESSMENT PROJECT
export const ASSESSMENT_PROJECT_IMPORT_RESPONDENTS = 'assessmentProjectImportRespondents';
export const ASSESSMENT_PROJECT_ADD_RESPONDENT = 'assessmentProjectAddRespondent';
export const ASSESSMENT_PROJECT_INVITE_ALL_RESPONDENTS = 'assessmentProjectInviteAllRespondents';
export const ASSESSMENT_PROJECT_DOWNLOAD_INVITES_CSV = 'assessmentProjectDownloadInvitesCsv';
export const ASSESSMENT_PROJECT_INVITE_RESPONDENTS = 'assessmentProjectInviteRespondents';
export const ASSESSMENT_PROJECT_DOWNLOAD_REPORTS = 'assessmentProjectDownloadReports';
export const ASSESSMENT_PROJECT_DOWNLOAD_RESPONDENT_REPORTS = 'assessmentProjectDownloadRespondentReports';
export const ASSESSMENT_PROJECT_DELETE_RESPONDENTS = 'assessmentProjectDeleteRespondents';
export const ASSESSMENT_PROJECT_COPY_INVITE_LINK = 'assessmentProjectCopyInviteLink';
export const ASSESSMENT_PROJECT_SEND_REMINDERS = 'assessmentProjectSendReminders';
export const ASSESSMENT_PROJECT_GO_TO_RESPONDENT_PROFILE = 'assessmentProjectGoToRespondentProfile';
export const NO_PROJECT_MODAL = 'noProjectModal';
export const END_ASSESSMENT_PROJECT = 'endAssessmentProject';
export const END_360_PROJECT = 'end360Project';

// BIODATA
export const SHOW_BIODATA_DETAILS = 'showBioDataDetails';
export const CHANGE_BIODATA_OPTIONS = 'changeBioDataOptions';
export const DELETE_BIODATA_TEMPLATE = 'deleteBioDataTemplate';
export const UPDATE_DEFAULT_BIO_TEMPLATE = 'updateDefaultBioDataTemplate';
