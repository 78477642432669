import React from 'react';
import { func, bool } from 'prop-types';
import styled from 'styled-components';

const RadioButton = ({ isChecked, clickHandler, disabled, ...rest }) => {
  return (
    <Radio
      type="radio"
      checked={isChecked}
      onClick={disabled || !clickHandler ? undefined : clickHandler}
      disabled={disabled}
      onChange={() => {}}
      {...rest}
    />
  );
};

RadioButton.propTypes = {
  isChecked: bool.isRequired,
  clickHandler: func,
  disabled: bool,
};

RadioButton.defaultProps = {
  disabled: false,
  clickHandler: undefined,
};

const Radio = styled.input`
  position: relative;
  appearance: none;
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  min-height: 2rem;
  border: 2px solid ${(props) => (props.disabled ? props.theme.colors.grey2 : props.theme.colors.darkBlue2)};
  cursor: pointer;
  outline: none;
  border-radius: 50%;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  :hover {
    border-color: ${(props) => {
      const color = !props.disabled && props.theme.colors.mediumBlue;
      return !props.checked && color;
    }};
  }

  &:checked {
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1rem;
      height: 1rem;
      background-color: ${(props) => props.theme.colors.lightBlue};
      border-radius: 50%;
    }
  }
`;

export default RadioButton;
