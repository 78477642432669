import { I18n } from 'react-redux-i18n';
import * as actions from '../actionsList';

const normsTableActions = [
  {
    name: actions.ADD_NEW_NORM,
    label: I18n.t('Add new norm'),
  },
];

export default normsTableActions;
