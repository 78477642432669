import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { string } from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { push } from 'redux-first-history';

import CustomInput from '../reusable/FormComponents/Input';
import Spinner from '../reusable/Spinner';
import Logo from '../reusable/Logo';
import CustomButton from '../reusable/Buttons/Button';
import { errorMessages } from '../../constants/errorMessages';

import { resetPassword, validateResetPasswordToken } from '../../store/user/actions';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, errorMessages.tooShort(6))
    .required(errorMessages.inputField),
  confirmPassword: Yup.string()
    .min(6, errorMessages.tooShort(6))
    .required(errorMessages.inputField)
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

const backendErrors = {
  200: '',
  400: 'Bad Request, password is empty or code is empty or invalid',
  422: 'Password is not complex enough',
};

const getError = (errorMessage) => {
  const codes = Object.keys(backendErrors);
  for (let i = 0; i <= codes.length; i += 1) {
    if (errorMessage.includes(String(codes[i]))) return backendErrors[codes[i]];
  }
};

const ResetPassword = ({ resetToken }) => {
  const dispatch = useDispatch();

  const [showSuccessStep, setSuccessStepStatus] = useState(false);
  const [showFailureStep, setFailureStepStatus] = useState(false);

  const [isLoading, setLoadingState] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    dispatch(
      validateResetPasswordToken(resetToken, (e) => {
        if (e) setFailureStepStatus(true);
        setLoadingState(false);
      }),
    );
  }, []);

  const { handleSubmit, handleChange, values, errors, setErrors } = useFormik({
    validationSchema,
    validateOnChange: false,
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    onSubmit: ({ password }) => {
      if (!resetToken) return;
      const data = { password, code: resetToken };
      setLoadingState(true);
      dispatch(
        resetPassword(data, (e) => {
          if (e) setError(getError(e.message));
          if (!e) setSuccessStepStatus(true);
          setLoadingState(false);
        }),
      );
    },
  });

  const onInputChange = (event) => {
    handleChange(event);
    setErrors({ email: '' });
    setError('');
  };

  return (
    <BgContainer>
      <Spinner isLoading={isLoading} />
      <ContentWrapper>
        {showFailureStep ? (
          <>
            <Logo marginBottom="0" />
            <Title>{I18n.t('Invalid link')}</Title>
            <Description>
              {I18n.t('This link has been used or is no longer valid')}
              {'. '}
              {I18n.t('Please request a new one and try again')}
              <BackToLoginLink onClick={() => dispatch(push('/login'))}>{I18n.t('Back to login page')}</BackToLoginLink>
            </Description>
          </>
        ) : (
          <>
            <Logo marginBottom="0" />
            <Title>{showSuccessStep ? I18n.t('Success!') : I18n.t('Create new password')}</Title>
            <Description>
              {showSuccessStep
                ? `${I18n.t('Your password has been updated')}.`
                : I18n.t('Must be at least 6 characters')}
            </Description>
            {showSuccessStep ? (
              <StyledButton isSuccess={showSuccessStep} handler={() => dispatch(push('/login'))}>
                {I18n.t('Go to login page')}
              </StyledButton>
            ) : (
              <>
                <StyledInput
                  error={errors.password}
                  inputName={I18n.t('Password')}
                  id="password"
                  onChange={onInputChange}
                  value={values.password}
                  type="password"
                />
                <StyledInput
                  error={errors.confirmPassword}
                  inputName={I18n.t('Confirm password')}
                  id="confirmPassword"
                  onChange={onInputChange}
                  value={values.confirmPassword}
                  type="password"
                  bottomMargin="0"
                />
                <Error>{I18n.t(error)}</Error>
                <StyledButton handler={handleSubmit}>{I18n.t('Reset password')}</StyledButton>
              </>
            )}
          </>
        )}
      </ContentWrapper>
    </BgContainer>
  );
};

ResetPassword.propTypes = {
  resetToken: string,
};

ResetPassword.defaultProps = {
  resetToken: '',
};

const BgContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.background};
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3.2rem;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 0 44px #00000012;
  border-radius: 10px;
  width: 42rem;
`;

const Title = styled.h1`
  color: ${(props) => props.theme.colors.darkBlue2};
  font-size: ${(props) => props.theme.fontSizes.large};
  margin: 4rem 0 0.8rem 0;
`;

const Description = styled.span`
  color: ${(props) => props.theme.colors.darkBlue2};
  font-size: ${(props) => props.theme.fontSizes.default};
  margin-bottom: 1.6rem;
`;

const StyledInput = styled(CustomInput)`
  margin-bottom: ${(props) => props.bottomMargin || '1.6rem'};
`;

const StyledButton = styled(CustomButton)`
  margin-top: ${(props) => (props.isSuccess ? '3.2rem' : 0)};
  text-transform: uppercase;
`;

const Error = styled.div`
  height: 4rem;
  padding-top: 0.8rem;
  color: ${(props) => props.theme.colors.red};
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;
  display: flex;
`;

const BackToLoginLink = styled.span`
  display: block;
  margin-top: 4rem;
  font-size: ${(props) => props.theme.fontSizes.default};
  color: ${(props) => props.theme.colors.mediumBlue};
  text-align: center;

  :hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.lightBlue};
  }
`;

export default ResetPassword;
