import React, { memo } from 'react';
import { func, bool, object, string } from 'prop-types';
import styled, { css } from 'styled-components';

const MultiSelectTableRow = ({ row, selectRow, highlightRowOptions, isActive, className, isInvalid }) => {
  return (
    <StyledBodyTr
      className={className}
      onClick={() => selectRow && selectRow(row.original)}
      highlight={highlightRowOptions && highlightRowOptions.comparator && highlightRowOptions.comparator(row.original)}
      isActive={isActive}
      isInvalid={isInvalid}
    >
      <>
        {row.cells.map((cell) => {
          return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
        })}
      </>
    </StyledBodyTr>
  );
};

MultiSelectTableRow.propTypes = {
  row: object.isRequired,
  selectRow: func,
  highlightRowOptions: object,
  isActive: bool,
  className: string,
  isInvalid: bool,
};

MultiSelectTableRow.defaultProps = {
  isActive: null,
  highlightRowOptions: null,
  className: '',
  selectRow: undefined,
  isInvalid: false,
};

const StyledBodyTr = styled.tr`
  transition: all 0.1s;
  position: relative;
  :hover {
    background-color: ${(props) => (props.isInvalid ? '#FFD8D8' : props.theme.colors.lightBlue1)};
    cursor: pointer;
    td > div::after {
      display: none;
    }
  }
  ${(props) =>
      props.isActive &&
      css`
        td > div::after {
          display: none;
        }
      `}
    :last-child {
    td {
      border-bottom: 0;
    }
  }

  font-weight: ${(props) => (props.highlight ? 'bold' : '500')};

  background-color: ${(props) => {
    const normalColor = props.highlight ? 'rgba(161, 170, 170, 0.15)' : props.theme.colors.white;
    if (props.isInvalid) return '#FFD8D8';
    return props.isActive ? props.theme.colors.lightBlue1 : normalColor;
  }};
`;

// TODO find a proper way to optimize table row
export default memo(MultiSelectTableRow, (prevProps, nextProps) => {
  const customEqualityLogic = prevProps.rowEqualityFunc
    ? prevProps.rowEqualityFunc(prevProps.row.original, nextProps.row.original)
    : true;
  return (
    prevProps.row.index === nextProps.row.index &&
    prevProps.isActive === nextProps.isActive &&
    prevProps.row.isSelected === nextProps.row.isSelected &&
    prevProps.row.original.name === nextProps.row.original.name &&
    prevProps.row.original[prevProps.resolvedAccessor] === nextProps.row.original[prevProps.resolvedAccessor] &&
    customEqualityLogic
  );
});

// export default MultiSelectTableRow;
