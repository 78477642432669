import React, { useState, useEffect } from 'react';
import { func, array, object } from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { sortBy } from 'lodash';

import { I18n } from 'react-redux-i18n';
import NewModal from '../../reusable/NewModal';
import CustomInput from '../../reusable/FormComponents/Input';
import CustomButton from '../../reusable/Buttons/Button';
import Checkbox from '../../reusable/FormComponents/Checkbox';

import { createNewBattery, editBattery } from '../../../store/user/actions';

import { ReactComponent as Arrow } from '../../../assets/icons/arrrow-right-border.svg';

const TestItem = ({ isChecked, testName, onClick }) => {
  return (
    <TestWrapper onClick={onClick} isChecked={isChecked}>
      <StyledCheckbox isChecked={isChecked} />
      <span>{testName}</span>
    </TestWrapper>
  );
};

const ManageBatteryModal = ({ onClose, assessments, battery }) => {
  const [batteryName, setBatteryName] = useState('');
  const [allTests, setAllTests] = useState(assessments);
  const [selectedTests, setSelectedTests] = useState(battery ? battery.tests : []);

  const [allCurrentlySelected, setAllCurrentlySelected] = useState([]);
  const [selectedCurrentlySelected, setSelectedCurrentlySelected] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setAllTests(assessments);
  }, [assessments]);

  useEffect(() => {
    if (battery) {
      const existingTestsIds = battery.uses.split(';');
      setSelectedTests(battery.tests);
      setAllTests((prev) => prev.filter((item) => !existingTestsIds.includes(item.testID)));
      setBatteryName(battery.name || battery.title);
    }
  }, [battery]);

  const onModalClose = () => {
    setBatteryName('');
    setSelectedTests([]);
    setAllCurrentlySelected([]);
    setSelectedCurrentlySelected([]);
    onClose();
  };

  const onTestClick = (item, block) => {
    const cb = (prev) =>
      prev.find((obj) => obj.testID === item.testID)
        ? prev.filter((obj) => obj.testID !== item.testID)
        : [...prev, item];

    if (block === 'all') {
      setAllCurrentlySelected(cb);
      setSelectedCurrentlySelected([]);
    }
    if (block === 'selected') {
      setSelectedCurrentlySelected(cb);
      setAllCurrentlySelected([]);
    }
  };

  const onSave = () => {
    const data = {
      title: batteryName,
      testIDs: selectedTests.map((item) => item.testID),
      ...(battery && { batteryID: battery.batteryID }),
    };
    const method = battery ? editBattery : createNewBattery;
    dispatch(
      method(data, (err) => {
        if (!err) onModalClose();
      }),
    );
  };

  const moveItem = (to) => {
    const itemsBeingMoved = to === 'selected' ? allCurrentlySelected : selectedCurrentlySelected;
    const idsOfItemsBeingMoved = itemsBeingMoved.map((item) => item.testID);
    if (to === 'selected') {
      setAllTests((prev) => prev.filter((obj) => !idsOfItemsBeingMoved.includes(obj.testID)));
      setSelectedTests((prev) => [...prev, ...itemsBeingMoved]);
    }
    if (to === 'all') {
      setSelectedTests((prev) => prev.filter((obj) => !idsOfItemsBeingMoved.includes(obj.testID)));
      setAllTests((prev) => [...prev, ...itemsBeingMoved]);
    }
    setAllCurrentlySelected([]);
    setSelectedCurrentlySelected([]);
  };

  const allSorted = sortBy(allTests, 'testID');

  const title = battery ? battery.name || battery.title : I18n.t('Add battery');

  return (
    <Modal isVisible onClose={onModalClose} title={title}>
      <Content>
        <Input
          inputName={I18n.t('Battery name')}
          value={batteryName}
          onChange={(e) => setBatteryName(e.target.value)}
        />
        <BlocksRow>
          <Block>
            <BlockTitle>{I18n.t('Search existing assessments')}</BlockTitle>
            <TestsList>
              {allSorted.map((test) => (
                <TestItem
                  key={`${test.testID}${test.name}`}
                  testName={`${test.name} (${test.testID})`}
                  onClick={() => onTestClick(test, 'all')}
                  isChecked={Boolean(allCurrentlySelected.find((item) => item.testID === test.testID))}
                />
              ))}
            </TestsList>
          </Block>
          <ArrowsBlock>
            <ArrowRight disabled={!allCurrentlySelected.length} onClick={() => moveItem('selected')} />
            <ArrowLeft disabled={!selectedCurrentlySelected.length} onClick={() => moveItem('all')} />
          </ArrowsBlock>
          <Block>
            <BlockTitle>{I18n.t('selected assessments')}</BlockTitle>
            <TestsList>
              {selectedTests.map((test) => (
                <TestItem
                  key={`${test.testID}${test.name}`}
                  testName={`${test.name} (${test.testID})`}
                  onClick={() => onTestClick(test, 'selected')}
                  isChecked={Boolean(selectedCurrentlySelected.find((item) => item.testID === test.testID))}
                />
              ))}
            </TestsList>
          </Block>
        </BlocksRow>
      </Content>
      <ButtonsWrapper>
        <Button disabled={!batteryName || !selectedTests.length} handler={onSave}>
          {battery ? I18n.t('Save') : I18n.t('Add')}
        </Button>
      </ButtonsWrapper>
    </Modal>
  );
};

ManageBatteryModal.propTypes = {
  onClose: func.isRequired,
  assessments: array.isRequired,
  battery: object,
};

ManageBatteryModal.defaultProps = {
  battery: null,
};

const Modal = styled(NewModal)`
  min-width: 74rem;
  width: 74rem;
  max-height: 52rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Input = styled(CustomInput)`
  width: 100%;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Button = styled(CustomButton)`
  min-width: 12rem;
  text-transform: uppercase;
`;

const BlocksRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4rem;
`;

const ArrowsBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.8rem 1.2rem 0 1.2rem;
  width: 44%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
`;

const BlockTitle = styled.h5`
  font-weight: 600;
  color: ${(props) => props.theme.colors.darkBlue2};
  font-size: ${(props) => props.theme.fontSizes.normal};
  margin-bottom: 1rem;
  text-transform: uppercase;
`;

const TestsList = styled.div`
  display: flex;
  flex-direction: column;
  height: 23rem;
  overflow: auto;
`;

const TestWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8rem;
  cursor: default;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};
  background-color: ${(props) => (props.isChecked ? props.theme.colors.lightBlueHover1 : props.theme.colors.white)};
  transition: 0.2s all;
  :hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.isChecked ? props.theme.colors.lightBlueHover1 : props.theme.colors.lightBlue1};
  }

  span {
    margin-left: 1rem;
    height: auto;
    font-size: ${(props) => props.theme.fontSizes.small};
    color: ${(props) => props.theme.colors.darkBlue2};
  }
`;

const StyledCheckbox = styled(Checkbox)`
  border-color: #acacac;
`;

const ArrowRight = styled(Arrow)`
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  :hover {
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  }
`;

const ArrowLeft = styled(ArrowRight)`
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  transform: rotate(180deg);
  margin-top: 2rem;

  :hover {
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  }
`;

export default ManageBatteryModal;
