import React from 'react';
import { array, bool } from 'prop-types';
import styled, { css } from 'styled-components';

import { I18n } from 'react-redux-i18n';
import Avatar from '../../reusable/Avatar';
import isChineseEnv from '../../../utils/isChineseEnv';

const RespondentsStep = ({ respondents, disableEmailValidation }) => {
  return (
    <RespondentsList>
      {respondents.map((item, i) => {
        const email = disableEmailValidation
          ? item.email
          : item.email || I18n.t('Email address is missing or not valid in respondent record');
        return (
          <RespondentItem key={item.respondentID} withBorderBtm={i === respondents.length - 1}>
            {!isChineseEnv && <StyledAvatar firstName={item.firstName} familyName={item.familyName} />}
            <Info error={!item.email}>
              <span>{`${item.firstName} ${item.familyName}`}</span>
              <span>{email}</span>
            </Info>
          </RespondentItem>
        );
      })}
    </RespondentsList>
  );
};

RespondentsStep.propTypes = {
  respondents: array.isRequired,
  disableEmailValidation: bool,
};

RespondentsStep.defaultProps = {
  disableEmailValidation: false,
};

const RespondentsList = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  align-self: center;
  width: 50%;
  margin-top: 2rem;
`;

const RespondentItem = styled.div`
  display: flex;
  align-items: center;
  padding: 1.2rem 0;
  border-top: 1px solid ${(props) => props.theme.colors.grey6};
  ${(props) =>
    props.withBorderBtm &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.colors.grey6};
    `}
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-weight: 500;
  }

  span:first-child {
    font-size: ${(props) => props.theme.fontSizes.small};
    margin-bottom: 0.5rem;
  }

  span:last-child {
    font-size: 1rem;
    color: ${(props) => (props.error ? props.theme.colors.red : props.theme.colors.primary)};
  }
`;

const StyledAvatar = styled(Avatar)`
  margin-right: 0.8rem;
`;

export default RespondentsStep;
