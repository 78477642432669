import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import { ReactComponent as AuthorizeIcon } from '../../assets/icons/sidebar-authorize.svg';

import * as actions from '../actionsList';

const StyledAuthorizeIcon = styled(AuthorizeIcon)`
  width: 2.2rem !important;
  height: 2.2rem !important;
  min-width: 2.2rem !important;
  min-height: 2.2rem !important;
`;

const managedGroupRespondentsBulkActions = [
  {
    name: actions.AUTHORIZE_RESPONDENT,
    label: I18n.t('Authorize'),
    Icon: StyledAuthorizeIcon,
    isDisabled: (respondents) => respondents.filter((item) => item.statusId !== 0).length,
  },
];

export default managedGroupRespondentsBulkActions;
