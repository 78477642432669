import React from 'react';
import { func, array, object } from 'prop-types';
import { I18n } from 'react-redux-i18n';
import styled from 'styled-components';

import RadioButton from '../../reusable/FormComponents/RadioButton';

// TODO add translations?
const CommonAssessmentsStep = ({ assessmentsList, selectedAssessment, onAssessmentChange }) => {
  return (
    <Container>
      <Header>
        <Title>{I18n.t('Select common assessment / battery')}</Title>
        <TableLabel>{I18n.t('Results')}</TableLabel>
      </Header>
      <AssessmentsList>
        {assessmentsList.map((item) => {
          const isSelected = selectedAssessment?.label === item.label;
          return (
            <AssessmentItem key={item.label} isSelected={isSelected} onClick={() => onAssessmentChange(item)}>
              <AssessmentName>
                <RadioButton isChecked={isSelected} />
                <span>{item.label}</span>
              </AssessmentName>
              <RespondentsCount>{item.respondentsCount}</RespondentsCount>
            </AssessmentItem>
          );
        })}
      </AssessmentsList>
    </Container>
  );
};

CommonAssessmentsStep.propTypes = {
  assessmentsList: array.isRequired,
  onAssessmentChange: func.isRequired,
  selectedAssessment: object,
};

CommonAssessmentsStep.defaultProps = {
  selectedAssessment: null,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  padding-right: 1rem;
`;

const TableLabel = styled.span`
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: bold;
`;

const Title = styled.h3`
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSizes.normal};
  color: ${(props) => props.theme.colors.darkBlue2};
`;

const AssessmentsList = styled.div`
  display: flex;
  flex-direction: column;
  height: 38rem;
  overflow: auto;
`;

const AssessmentItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};
  background-color: ${(props) => (props.isSelected ? props.theme.colors.lightBlue1 : props.theme.colors.white)};
  transition: all 0.3s;

  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.lightBlue1};
  }
`;

const AssessmentName = styled.div`
  display: flex;
  align-items: center;

  span {
    font-weight: 600;
    color: ${(props) => props.theme.colors.darkBlue2};
    font-size: ${(props) => props.theme.fontSizes.small};
    margin-left: 1rem;
  }
`;

const RespondentsCount = styled.span`
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSizes.small};
  color: ${(props) => props.theme.colors.mediumBlue};
`;

export default CommonAssessmentsStep;
