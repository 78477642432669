import { I18n } from 'react-redux-i18n';

export default [
  {
    name: '360 Appraisal',
    description:
      'Manage your entire 360 appraisal process here Easy to use, allowing appraisals to be set up in minutes and results generated instantly Choose from Psytech existing competency framework or fully customise your 360 project',
    title: I18n.t('Add 360 Appraisal Project'),
  },
  {
    name: 'Assessment',
    description:
      'Manage your assessment projects here Plan and implement the right assessment approach and tests Effectively and consistently administrate, communicate and manage the whole assessment process using this user friendly tool',
    title: I18n.t('Add assessment project'),
  },
];
