import styled, { css } from 'styled-components';
import DraggableDotsSvg from '../../../assets/icons/draggable-dots.svg';

export const PanelTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px 15px 45px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  position: relative;
  border-radius: 0.6rem;
  
  &:after {
    content: '';
    position: absolute;
    left: -15px;
    top: 9px;
    width: 9px;
    height: 28px;
    background-image: url("${DraggableDotsSvg}");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity .3s ease;
  }
  
  &:hover {
    &:after {
      opacity: 1;
    }
  }
  
  ${(props) => props.pLeft && `padding-left: ${props.pLeft}px;`}
  ${(props) => props.root && `font-weight: bold;`}
  ${(props) => props.selectable && `cursor: pointer;`}
  ${(props) =>
    props.noBorder &&
    css`
      border: none !important;

      & + & {
        border-top: 1px solid ${(props) => props.theme.colors.grey6} !important;
      }
    `}

    ${(props) =>
      props.draggable &&
      css`
        border: 1px solid #40c4ff !important;
        border-top: 1px solid #40c4ff !important;
        background: #dff6ff !important;
        border-radius: 6px !important;

        &:after {
          opacity: 1;
        }
      `}
`;

export const PanelTitleQuestion = styled.div`
  min-height: 45px;
  background: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  padding: 15px 15px 15px 45px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  position: relative;
  border: none;
    
  &:after {
    content: '';
    position: absolute;
    left: -30px;
    top: calc(50% - 14px);
    width: 9px;
    height: 28px;
    background-image: url("${DraggableDotsSvg}");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity .3s ease;
  }
  
  &:hover {
    &:after {
      opacity: 1;
    }
  }
  
  ${(props) => props.pLeft && `padding-left: ${props.pLeft}px;`}

  ${(props) =>
    props.draggable &&
    css`
      border: 1px solid #40c4ff !important;
      border-top: 1px solid #40c4ff !important;
      background: #dff6ff !important;
      border-radius: 6px !important;

      &:after {
        left: -15px;
        opacity: 1;
      }
    `}
`;
