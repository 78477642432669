import React from 'react';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import Badge from './styles';

const CustomBadge = () => <Custom>{I18n.t('CUSTOM')}</Custom>;

const Custom = styled(Badge)`
  background-color: ${(props) => props.theme.colors.lightBlueHover1};
  color: ${(props) => props.theme.colors.mediumBlue};
`;

export default CustomBadge;
