import React from 'react';
import { object } from 'prop-types';
import styled from 'styled-components';
import { format, differenceInDays } from 'date-fns';
import { I18n } from 'react-redux-i18n';
import CustomButton from '../reusable/Buttons/Button';

import * as actionsList from '../../table/actionsList';
import { AP_COMPLETED_STATUS } from '../../constants/assessmentProjectStatuses';

const SessionHeader = ({ project, actions }) => {
  const {
    creationDate,
    deadline,
    competencyGroupName,
    totalRatersCount,
    totalRatersInvitedCount,
    totalRatersCompletedCount,
    totalCount,
    completedCount,
    status,
  } = project;

  const notInvitedCount = totalRatersCount - totalRatersInvitedCount;
  const outstandingResults = totalRatersInvitedCount - totalRatersCompletedCount;
  const deadlineDate = new Date(deadline);

  const daysDiff = differenceInDays(deadlineDate, new Date());
  return (
    <SessionHeaderContainer>
      <Row>
        <InfoWrapper>
          <Pair>
            <BoldSpan>{I18n.t('360 Survey')}</BoldSpan>
            <UsualSpan>{competencyGroupName || I18n.t('All Standard')}</UsualSpan>
          </Pair>
          <Pair>
            <BoldSpan>{I18n.t('Created')}</BoldSpan>
            <UsualSpan>{creationDate ? format(new Date(creationDate), 'dd/M/y') : '-'}</UsualSpan>
          </Pair>
          <Pair>
            <BoldSpan>{I18n.t('Deadline')}</BoldSpan>
            {deadline ? (
              <UsualSpan>
                {format(deadlineDate, 'dd/M/y')}
                <BoldSpan>({daysDiff >= 0 ? `${daysDiff} ${I18n.t('days left')}` : I18n.t('expired')})</BoldSpan>
              </UsualSpan>
            ) : (
              <UsualSpan>-</UsualSpan>
            )}
          </Pair>
        </InfoWrapper>
      </Row>
      <TilesWrapper>
        <Tile>
          <span>
            {totalRatersInvitedCount}/{totalRatersCount}
          </span>
          <span>{I18n.t('Invited')}</span>
          <Button
            size="s"
            width={130}
            disabled={notInvitedCount <= 0 || status === AP_COMPLETED_STATUS}
            handler={actions[actionsList.PROJECT_INVITE_UNINVITED_SESSIONS]}
          >
            {I18n.t('Send invites')}
          </Button>
        </Tile>
        <Tile>
          <span>
            {outstandingResults}/{totalRatersCount}
          </span>
          <span>{I18n.t('Outstanding ratings')}</span>
          <Button
            size="s"
            width={130}
            disabled={outstandingResults <= 0 || status === AP_COMPLETED_STATUS}
            handler={actions[actionsList.PROJECT_INVITE_INVITED_SESSIONS]}
          >
            {I18n.t('Send reminder')}
          </Button>
        </Tile>
        <Tile>
          <span>
            {completedCount}/{totalCount}
          </span>
          <span>{I18n.t('Sessions completed')}</span>
          <Button
            size="s"
            width={130}
            disabled={completedCount <= 0}
            handler={actions[actionsList.SESSION_REQUEST_REPORT]}
          >
            {I18n.t('Request report')}
          </Button>
        </Tile>
      </TilesWrapper>
    </SessionHeaderContainer>
  );
};

SessionHeader.propTypes = {
  project: object.isRequired,
  actions: object.isRequired,
};

const SessionHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1.5rem;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

const Pair = styled.div`
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;
  margin-right: 2rem;
`;

const UsualSpan = styled.span`
  font-weight: 500;
`;

const BoldSpan = styled.span`
  font-weight: bold;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
`;

const TilesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const Tile = styled.div`
  width: 25rem;
  padding: 1.6rem 2.4rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 0.4rem;
  margin-right: 1rem;

  color: ${(props) => props.theme.colors.darkBlue2};
  font-weight: 600;
  font-size: 1.4rem;

  span:first-child {
    font-size: 2.4rem;
    font-weight: bold;
  }
`;

const Button = styled(CustomButton)`
  margin-top: 1rem;
`;

export default SessionHeader;
