import { I18n } from 'react-redux-i18n';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import * as actions from '../actionsList';

const respondentGroupsBulkActions = [
  {
    name: actions.REMOVE_RESPONDENT_FROM_GROUPS,
    label: I18n.t('Remove respondent from selected group(s)'),
    Icon: DeleteIcon,
  },
];

export default respondentGroupsBulkActions;
