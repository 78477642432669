import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { subMonths, endOfDay, startOfDay } from 'date-fns';
import styled from 'styled-components';
import { push } from 'redux-first-history';

import { I18n } from 'react-redux-i18n';
import SimplifiedTable from '../../reusable/Tables/SimplifiedTable';
import PageWrapper from '../../PageWrapper';
import TableTabs from '../../reusable/TableTabs';
import DateSelect from '../../reusable/FormComponents/DateSelect';
import Spinner from '../../reusable/Spinner';

import accountTabs from './accountTabs';
import { fetchCreditsHistory } from '../../../store/user/actions';

import creditHistoryColumns from '../../../table/columns/creditsHistory';

const CreditsHistory = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user.creditsHistory);
  const goBackToSettings = () => dispatch(push('/settings'));

  const currentDate = new Date();
  const [dates, setDates] = useState({ from: subMonths(currentDate, 1), to: endOfDay(currentDate) });
  const [isLoading, setLoadingState] = useState(true);

  useEffect(() => {
    setLoadingState(true);
    dispatch(fetchCreditsHistory(dates, () => setLoadingState(false)));
  }, [dates]);

  const onTabClick = (tab) => dispatch(push(tab.path));

  const onDateChange = (name, newDate) => {
    setDates((prev) => ({ ...prev, [name]: name === 'to' ? endOfDay(newDate) : startOfDay(newDate) }));
  };

  const totalUpdated = data.reduce((acc, item) => acc + item.amountRequested, 0);
  return (
    <PageWrapper title={I18n.t('Account')} backButtonHandler={goBackToSettings}>
      <Spinner isLoading={isLoading} full text={I18n.t('Loading credits history...')} />
      <TableTabs tabs={accountTabs} activeTab={accountTabs[1]} clickHandler={onTabClick} />
      <InfoRow>
        <Balance>
          {I18n.t('Total:')} {totalUpdated}
        </Balance>
        <Dates>
          <StyledDateSelect
            onDateChange={(date) => onDateChange('from', date)}
            selectedDate={dates.from}
            label={I18n.t('From date')}
            isBottom
          />
          -
          <StyledDateSelect
            onDateChange={(date) => onDateChange('to', date)}
            selectedDate={dates.to}
            label={I18n.t('To date')}
            isBottom
          />
        </Dates>
      </InfoRow>
      <SimplifiedTable
        columns={creditHistoryColumns}
        data={data}
        disableSelect
        idAccessor="updateID"
        entityName="record"
      />
    </PageWrapper>
  );
};

CreditsHistory.propTypes = {};

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const Dates = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDateSelect = styled(DateSelect)`
  margin-left: 1rem;
  z-index: 9;
  position: relative;

  & > div > div > div:first-of-type {
    width: 15rem;
  }

  & > div > div > div:last-child {
    left: initial;
    right: 0;
  }
`;

const Balance = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.grey5};
`;

export default CreditsHistory;
