import React, { useState, useCallback, useMemo } from 'react';
import { array, func } from 'prop-types';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';

import { ReactComponent as EmptyIcon } from '../../assets/icons/empty_state.svg';

import respondentReportsColumns, { getReportID } from '../../table/columns/respondentReports';

import SimplifiedTable from '../reusable/Tables/SimplifiedTable';
import BulkActionsSidebar from '../BulkActionsSidebar';
import ConfirmationModal from '../reusable/ConfirmationModal';

import reportsBulkActions from '../../table/bulkActions/reports';

import useBulkSidebarState from '../../hooks/useBulkSidebarState';

import downloadZippedArchiveFromReports from '../Reports/reports.utils';

import * as actions from '../../table/actionsList';

const RespondentReports = ({ reports, deleteReports, markReportsAsViewed, fetchRespondent }) => {
  const [selectedReports, setSelectedReports] = useState([]);
  const onChange = useCallback((data) => {
    setSelectedReports(data);
  }, []);
  const [isDeletionModalVisible, setDeletionModalStatus] = useState(false);
  const openConfirmationModal = () => setDeletionModalStatus(true);
  const closeConfirmationModal = () => {
    setDeletionModalStatus(false);
  };

  // const onBulkDeleteClick = () => {
  //   openConfirmationModal();
  // };

  const onDeleteConfirm = () => {
    const data = selectedReports.map((report) => ({
      reportType: report.reportType,
      notificationID: report.notificationID || null,
      requestID: report.requestID || null,
    }));

    deleteReports(data, () => {
      closeConfirmationModal();
      fetchRespondent();
    });
  };

  const onDownloadClick = (reports) => {
    const items = selectedReports.length ? selectedReports : reports;
    if (items.length > 1)
      downloadZippedArchiveFromReports(
        items.filter((item) => item.reportType !== 1),
        () => markReportsAsViewed(items.map((item) => item.notificationID)),
      );
    else
      items.forEach((report) => {
        const link = document.createElement('a');
        link.href = report.downloadLink;
        link.download = report.downloadLink.substr(report.downloadLink.lastIndexOf('/') + 1);
        link.click();
      });
    markReportsAsViewed(items.map((item) => item.notificationID));
  };

  const openMultipleReports = (reports) => {
    const items = selectedReports.length ? selectedReports : reports;
    if (!items.length) return;
    items.forEach((report) => {
      window.open(report.downloadLink, '_blank');
    });
    markReportsAsViewed(items.map((item) => item.notificationID));
  };

  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedReports });

  // BULK ACTIONS
  const bulkActionsHandlers = {
    [actions.OPEN_REPORT]: openMultipleReports,
    [actions.DOWNLOAD_REPORT]: onDownloadClick,
    [actions.DELETE_REPORT]: openConfirmationModal,
  };
  const bulkActions = useMemo(() => {
    return reportsBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedReports) : item.isDisabled,
    }));
  }, [selectedReports]);

  // columns settings
  const columns = useMemo(() => {
    return respondentReportsColumns;
  }, []);

  if (!reports.length)
    return (
      <EmptyMessageWrapper>
        <EmptyIcon />
        <EmptyMessage>{I18n.t('Sorry, we could not find any reports')}</EmptyMessage>
      </EmptyMessageWrapper>
    );
  return (
    <RespondentReportsContainer>
      <BulkActionsSidebar actions={bulkActions} onClose={closeSidebar} isOpen={isSidebarOpen} />
      <SimplifiedTable
        columns={columns}
        data={reports}
        onSelectChange={onChange}
        highlightRowOptions={{ comparator: (row) => row.reportType === 2 }}
        idAccessor={(row) => getReportID(row.original)}
        initialState={{ sortBy: [{ id: 'Creation Date', desc: true }] }}
      />
      <ConfirmationModal
        title={I18n.t('Report deletion')}
        onClose={closeConfirmationModal}
        isVisible={isDeletionModalVisible}
        description={I18n.t('Are you sure you want to delete selected report?')}
        onConfirm={onDeleteConfirm}
        caution
      />
    </RespondentReportsContainer>
  );
};

RespondentReports.propTypes = {
  reports: array.isRequired,
  deleteReports: func.isRequired,
  markReportsAsViewed: func.isRequired,
  fetchRespondent: func.isRequired,
};

const RespondentReportsContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100% - 30px);
`;

const EmptyMessageWrapper = styled.div`
  position: absolute;
  min-width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EmptyMessage = styled.p`
  margin: 15px 0 0 0;
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
`;

export default RespondentReports;
