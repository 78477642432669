import React from 'react';
import styled from 'styled-components';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { I18n } from 'react-redux-i18n';
import { ReactComponent as EyeShow } from '../../../assets/icons/eye-show.svg';
import useModals from '../../../hooks/useModals';
import * as actions from '../../../table/actionsList';
import StatsModal from './StatsModal';

const PieChartGraphBON = ({ categorie, rawBioDataColumns }) => {
  const modals = [actions.SHOW_BIODATA_DETAILS];
  const { modalsState, openModal, closeModal } = useModals(modals);

  const COLORS = [
    '#5A96E3',
    '#E7CEA6',
    '#6CD4FF',
    '#D496A7',
    '#B6C454',
    '#6C756B',
    '#932F6D',
    '#72B01D',
    '#00A7E1',
    '#F901B0',
    '#FF9B42',
    '#EE8C01',
    '#068DA9',
    '#E55807',
    '#77037B',
    '#F6FA70',
    '#84A7A1',
    '#F2BE22',
  ];

  const significantDataMapper = ({ values }) => {
    const significantData = [];

    values.forEach((itemValue) => {
      const [groupName, count, percentage] = itemValue.split(':');

      return significantData.push({
        name: groupName,
        value: parseFloat(count),
        percent: parseFloat(percentage),
      });
    });

    return significantData;
  };

  const mappedData = significantDataMapper({
    values: categorie.values,
  });

  return (
    <GraphicsWrapper key={categorie.name}>
      <PreviewTitle>
        <PreviewItem>{I18n.t(categorie.name).toUpperCase()}</PreviewItem>
        <PreviewItem>
          <BackButtonWrapper onClick={() => openModal(actions.SHOW_BIODATA_DETAILS)}>
            <EyeShow />
          </BackButtonWrapper>
        </PreviewItem>
      </PreviewTitle>
      <StyledResponsiveContainer height="auto" aspect={1.2}>
        <PieChart
          width={450}
          height={450}
          margin={{ top: 0, right: 0, bottom: parseInt(mappedData.length, 10) * 10, left: 0 }}
        >
          <Pie
            data={mappedData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={40}
            outerRadius={70}
            paddingAngle={5}
            fill="#82ca9d"
            wrapperStyle={{ margin: '5px' }}
          >
            {mappedData.map((entry, index) => (
              <Cell key={`${categorie}-${COLORS[index]}-cell`} fill={COLORS[index]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend height={36} align="left" wrapperStyle={{ fontSize: '11.5px' }} />
        </PieChart>
      </StyledResponsiveContainer>
      {modalsState[actions.SHOW_BIODATA_DETAILS] && (
        <StatsModal
          categorieName={I18n.t(categorie.name)}
          rawDataColumns={rawBioDataColumns}
          rawData={mappedData}
          onClose={() => closeModal(actions.SHOW_BIODATA_DETAILS)}
        />
      )}
    </GraphicsWrapper>
  );
};

const GraphicsWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 25px 12px;
  max-height: 450px;
`;

const PreviewTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #295368;
`;

const PreviewItem = styled.p`
  color: #295368;
`;

const BackButtonWrapper = styled.button`
  outline: none;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  min-width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  transition: all 0.2s;

  :hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
`;

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  width: 34rem !important;
  margin: 15px;

  .recharts-default-legend {
    margin-top: 15px !important;
  }
`;

export default PieChartGraphBON;
