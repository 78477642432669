import handleActions from '../../immerHandleActions';
import * as actions from './actions';

const initialState = {
  templates: [],
  bodyVariables: [],
  subjectVariables: [],
  activeTemplate: null,
  defaultEmailInvitaionTemplate: '',
};

const templatesReducer = handleActions(
  {
    [actions.fetchTemplatesSuccess]: (draft, { payload: { templates } }) => {
      draft.templates = templates;
    },
    [actions.deleteTemplatesSuccess]: (draft, { payload: { templatesIds } }) => {
      draft.templates = draft.templates.filter((template) => !templatesIds.includes(template.templateID));
    },
    [actions.fetchTemplatesVariablesSuccess]: (draft, { payload: { variables } }) => {
      draft.bodyVariables = variables.body;
      draft.subjectVariables = variables.subject;
    },
    [actions.setActiveTemplate]: (draft, { payload: { template } }) => {
      draft.activeTemplate = template;
    },
    [actions.editTemplateSuccess]: (draft, { payload: { newTemplate } }) => {
      draft.activeTemplate = newTemplate;
    },
    [actions.setDefaultEmailInvitaionTemplate]: (draft, { payload: { htmlTemplate } }) => {
      draft.defaultEmailInvitaionTemplate = htmlTemplate;
    },
  },
  initialState,
);

export default templatesReducer;
