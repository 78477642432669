import { createAction } from 'redux-actions';
import qs from 'qs';
import { I18n } from 'react-redux-i18n';

import apiInstance2 from '../../../services/apiService';
import createToastNotification from '../../../utils/createToastNotification';

const buildFilteringOptions = () => {
  const filteringOptionsCheck = {
    testID: [],
  };
  const filteringOptions = {
    testID: [],
  };

  const testAndPushProp = (propName, norm) => {
    if (!filteringOptionsCheck[propName].includes(norm[propName])) {
      filteringOptionsCheck[propName].push(norm[propName]);
      filteringOptions[propName].push({
        label: norm[propName],
        value: norm[propName],
      });
    }
  };

  return [
    (norm) => {
      if (filteringOptions.testID) {
        norm.testID = norm.testID.toUpperCase();
        testAndPushProp('testID', norm);
      }
    },
    filteringOptions,
  ];
};

export const setIdealProfilesFilteringOptions = createAction('SET_IDEAL_PROFILE_FILTERING_OPTIONS');

export const setActiveIdealProfile = createAction('SET_ACTIVE_IDEAL_PROFILE', (idealProfile) => ({ idealProfile }));

export const fetchIdealProfilesStart = createAction('FETCH_IDEAL_PROFILES_START');
export const fetchIdealProfilesSuccess = createAction('FETCH_IDEAL_PROFILES_SUCCESS', (idealProfiles) => ({
  idealProfiles,
}));
export const fetchIdealProfilesFailure = createAction('FETCH_IDEAL_PROFILES_FAILURE');

export const fetchIdealProfileTestsStart = createAction('FETCH_IDEAL_PROFILE_TESTS_START');
export const fetchIdealProfileTestsSuccess = createAction('FETCH_IDEAL_PROFILE_TESTS_SUCCESS', (tests) => ({ tests }));
export const fetchIdealProfileTestsFailure = createAction('FETCH_IDEAL_PROFILE_TESTS_FAILURE');

export const createIdealProfileStart = createAction('CREATE_IDEAL_PROFILE_START');
export const createIdealProfileSuccess = createAction('CREATE_IDEAL_PROFILE_SUCCESS');
export const createIdealProfileFailure = createAction('CREATE_IDEAL_PROFILE_FAILURE');

export const deleteIdealProfilesStart = createAction('DELETE_IDEAL_PROFILES_START');
export const deleteIdealProfilesSuccess = createAction('DELETE_IDEAL_PROFILES_SUCCESS', (profilesIds) => ({
  profilesIds,
}));
export const deleteIdealProfilesFailure = createAction('DELETE_IDEAL_PROFILES_FAILURE');

export const fetchIdealProfileScalesStart = createAction('FETCH_IDEAL_PROFILE_SCALES_START');
export const fetchIdealProfileScalesSuccess = createAction('FETCH_IDEAL_PROFILE_SCALES_SUCCESS');
export const fetchIdealProfileScalesFailure = createAction('FETCH_IDEAL_PROFILE_SCALES_FAILURE');

export const editIdealProfileStart = createAction('CREATE_IDEAL_PROFILE_START');
export const editIdealProfileSuccess = createAction('CREATE_IDEAL_PROFILE_SUCCESS');
export const editIdealProfileFailure = createAction('CREATE_IDEAL_PROFILE_FAILURE');

export const fetchGroupsForIdealProfilesStart = createAction('FETCH_GROUPS_FOR_IDEAL_PROFILES_START');
export const fetchGroupsForIdealProfilesSuccess = createAction('FETCH_GROUPS_FOR_IDEAL_PROFILES_SUCCESS', (groups) => ({
  groups,
}));
export const fetchGroupsForIdealProfilesFailure = createAction('FETCH_GROUPS_FOR_IDEAL_PROFILES_FAILURE');

export const fetchIdealProfiles = (callback) => async (dispatch) => {
  try {
    dispatch(fetchIdealProfilesStart());
    const response = await apiInstance2.get('/api/v2/ideal-profiles');

    const [getNormFilteringOptions, filteringOptions] = buildFilteringOptions();
    response.data.forEach((norm) => {
      getNormFilteringOptions(norm);
    });
    dispatch(setIdealProfilesFilteringOptions(filteringOptions));

    if (response.status === 200) {
      dispatch(fetchIdealProfilesSuccess(response.data));
      if (callback) callback();
    }
  } catch (e) {
    dispatch(fetchIdealProfilesFailure());
    if (callback) callback();
  }
};

export const fetchIdealProfilesTests = (callback) => async (dispatch) => {
  try {
    dispatch(fetchIdealProfileTestsStart());
    const response = await apiInstance2.get('/api/v2/ideal-profiles/tests');
    if (response.status === 200) {
      dispatch(fetchIdealProfileTestsSuccess(response.data));
      if (callback) callback();
    }
  } catch (e) {
    dispatch(fetchIdealProfileTestsFailure());
    if (callback) callback();
  }
};

export const createIdealProfile = (data, callback) => async (dispatch) => {
  try {
    dispatch(createIdealProfileStart());
    const response = await apiInstance2.post('/api/v2/ideal-profiles', data);
    if (response.status === 200) {
      dispatch(createIdealProfileSuccess());
      createToastNotification({ message: I18n.t('Ideal profile has been created') });
      dispatch(fetchIdealProfiles());
      if (callback) callback(null, response.data.idealProfileID);
    }
  } catch (e) {
    dispatch(createIdealProfileFailure());
    if (callback) callback(e);
  }
};

export const deleteIdealProfile = (profilesIds, callback) => async (dispatch) => {
  try {
    dispatch(deleteIdealProfilesStart());
    const response = await apiInstance2.delete('/api/v2/ideal-profiles', { data: profilesIds });
    if (response.status === 200) {
      dispatch(deleteIdealProfilesSuccess(profilesIds));
      createToastNotification({ message: I18n.t('profilesDeletionToast') });
      if (callback) callback();
    }
  } catch (e) {
    dispatch(deleteIdealProfilesFailure());
    if (callback) callback();
  }
};

export const fetchScalesResponse = (testId) => async (_dispatch, getState) => {
  try {
    const {
      i18n: { locale },
    } = getState();
    const data = { langId: locale?.toUpperCase() || 'EN', testId };

    const response = await apiInstance2.get(`/api/v2/ideal-profiles/test?${qs.stringify(data)}`);
    if (response.status === 200) {
      return response?.data?.scales;
    }
    return undefined;
  } catch {
    return undefined;
  }
};

export const fetchIdealProfileScales = (profileId, callback) => async (dispatch, getState) => {
  try {
    const {
      i18n: { locale },
    } = getState();
    dispatch(fetchIdealProfileScalesStart());
    const idealProfileResponse = await apiInstance2.get(`/api/v2/ideal-profiles/${profileId}`);
    if (idealProfileResponse.status === 200) {
      const data = { langId: locale?.toUpperCase() || 'EN', testId: idealProfileResponse.data.testID };
      const scalesResponse = await apiInstance2.get(`/api/v2/ideal-profiles/test?${qs.stringify(data)}`);
      if (scalesResponse.status === 200) {
        dispatch(fetchIdealProfileScalesSuccess());
        dispatch(setActiveIdealProfile({ ...scalesResponse.data, ...idealProfileResponse.data }));
        if (callback) callback();
      }
    }
  } catch (e) {
    dispatch(fetchIdealProfileScalesFailure());
    if (callback) callback();
  }
};

export const editIdealProfile = (data, callback) => async (dispatch) => {
  try {
    dispatch(editIdealProfileStart());
    const response = await apiInstance2.patch('/api/v2/ideal-profiles', data);
    if (response.status === 200) {
      dispatch(editIdealProfileSuccess());
      createToastNotification({ message: I18n.t('profileUpdateToast') });
      dispatch(fetchIdealProfileScales(data.idealProfileID));
      if (callback) callback(true);
    }
  } catch (e) {
    dispatch(editIdealProfileFailure());
    createToastNotification({ message: `${I18n.t('profileUpdateToastError')}.`, type: 'error' });
    if (callback) callback(false);
  }
};

export const fetchGroupsForIdealProfiles = (testId, callback) => async (dispatch) => {
  try {
    dispatch(fetchGroupsForIdealProfilesStart());
    const response = await apiInstance2.get(`/api/v2/ideal-profiles/groups?testId=${encodeURIComponent(testId)}`);
    if (response.status === 200) {
      dispatch(fetchGroupsForIdealProfilesSuccess(response.data));
      if (callback) callback();
    }
  } catch (e) {
    dispatch(fetchGroupsForIdealProfilesFailure());
    if (callback) callback();
  }
};
