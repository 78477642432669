import { createSelector } from 'reselect';
import { I18n } from 'react-redux-i18n';

const getActiveFilter = (state) => state.invites.activeTab;
const getTotalCount = (state) => state.invites.totalCount;
const get360Count = (state) => state.invites.total360;
const getAssessmentsCount = (state) => state.invites.totalAssessments;
const getTabsList = (state) => state.invites.tabs;
const getUserLanguages = (state) => state.user.languages;

export const selectInvitesAmountsByStatus = createSelector(
  [getTotalCount, get360Count, getAssessmentsCount],
  (totalCount, count360, assessmentsCount) => {
    return {
      all: totalCount,
      360: count360,
      assessments: assessmentsCount,
    };
  },
);

export const selectTabs = createSelector([getTabsList, selectInvitesAmountsByStatus], (tabs, amounts) => {
  return tabs.map((item) => ({ ...item, label: `${I18n.t(item.label)} (${amounts[item.name]})` }));
});

export const selectTotalInvitesCount = createSelector(
  [getActiveFilter, getTotalCount, get360Count, getAssessmentsCount],
  (activeTab, totalCount, count360, assessmentsCount) => {
    if (activeTab.name === 'all') return totalCount;
    if (activeTab.name === '360') return count360;
    return assessmentsCount;
  },
);

export const selectInvitesLanguageOptions = createSelector(getUserLanguages, (languages) =>
  languages.map((item) => ({ ...item, value: item.langID, label: item.english })),
);
