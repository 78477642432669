const breadcrumbs = (group) => [
  {
    label: 'Groups',
    path: '/groups',
  },
  {
    label: group ? group.description : '',
    shouldNotTranslate: true,
  },
];

export default breadcrumbs;
