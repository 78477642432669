import { I18n } from 'react-redux-i18n';
import { ADD_SCALE } from '../actionsList';

const responseScalesTableActions = [
  {
    name: ADD_SCALE,
    label: I18n.t('Add response scale'),
  },
];

export default responseScalesTableActions;
