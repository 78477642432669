import React from 'react';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import { caseInsensitiveSortingFunc } from './respondents';

import { ReactComponent as LockIcon } from '../../assets/icons/lock.svg';
import round5 from '../../components/Settings/utils/round5';

// const columns = (rowActions) => [
const columns = [
  {
    Header: I18n.t('Name'),
    accessor: (data) => (
      <Row>
        <span>{data.name}</span>
        {data.projectsCount > 0 && <LockIcon />}
      </Row>
    ),
    name: 'name',
    type: 'text',
    filteringName: 'Name',
    disableSortBy: true,
    sortType: caseInsensitiveSortingFunc,
  },
  {
    Header: I18n.t('Response scale'),
    accessor: (data) => <span>{I18n.t(data.responseScale)}</span>,
    name: 'responseScale',
    type: 'text',
    filteringName: 'responseScale',
    disableSortBy: true,
    sortType: caseInsensitiveSortingFunc,
  },
  {
    Header: I18n.t('Categories'),
    accessor: 'categoriesCount',
    name: 'categoriesCount',
    type: 'text',
    filteringName: 'categoriesCount',
    disableSortBy: true,
    sortType: caseInsensitiveSortingFunc,
  },
  {
    Header: I18n.t('Competencies'),
    accessor: 'competenciesCount',
    name: 'competenciesCount',
    type: 'text',
    filteringName: 'Competencies',
    disableSortBy: true,
    sortType: caseInsensitiveSortingFunc,
  },
  {
    Header: I18n.t('Number of items'),
    accessor: 'itemsCount',
    name: 'itemsCount',
    type: 'text',
    filteringName: 'Number of items',
    disableSortBy: true,
    sortType: caseInsensitiveSortingFunc,
  },
  {
    Header: I18n.t('Estimated time'),
    accessor: ({ itemsCount, freeTextItemsCount }) =>
      `${round5((itemsCount * 30 + freeTextItemsCount * 180) / 60)} ${I18n.t('minutes')}`,
    name: 'estimatedTime',
    type: 'text',
    filteringName: 'Estimated time',
    disableSortBy: true,
    sortType: caseInsensitiveSortingFunc,
  },
  // {
  //   Header: '',
  //   name: 'actions',
  //   type: 'actions',
  //   id: 'actions',
  //   disableSortBy: true,
  //   Cell: ({ row }) => (
  //     <ActionsDropdown
  //       actions={rowActions.map((item) => ({
  //         ...item,
  //         handler: () => item.handler(row.original),
  //         isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(row.original) : item.isDisabled,
  //       }))}
  //     />
  //   ),
  //   width: 50,
  // },
];

const Row = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-right: 1rem;
  }
`;

export default columns;
