/* eslint-disable react/destructuring-assignment */
import React, { useState, useRef } from 'react';
import ReactSelect, { components } from 'react-select';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';

const MultiSelect = (props) => {
  const [selectInput, setSelectInput] = useState('');
  const isAllSelected = useRef(true);
  const selectAllLabel = useRef(I18n.t('Select all'));
  const allOption = { value: '*', label: selectAllLabel.current };

  const comparator = (v1, v2) => v1.value - v2.value;

  const filteredOptions = props.options;
  const filteredSelectedOptions = props.value;

  const Option = (props) => (
    <components.Option {...props}>
      <StyledOptionContainer>
        {props.value === '*' && isAllSelected.current && filteredSelectedOptions?.length > 0 ? (
          <input
            key={props.value}
            type="checkbox"
            ref={(input) => {
              if (input) input.indeterminate = true;
            }}
          />
        ) : (
          <input
            key={props.value}
            type="checkbox"
            checked={props.isSelected || isAllSelected.current}
            onChange={() => {}}
          />
        )}
        <p>{props.label}</p>
      </StyledOptionContainer>
    </components.Option>
  );

  const Input = (props) => (
    <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
      {props.children}
    </components.Input>
  );

  const ValueContainer = () => (
    <StyledValueContainer>
      {filteredSelectedOptions?.length} / {filteredOptions.length}
    </StyledValueContainer>
  );

  const customFilterOption = ({ value, label }, input) =>
    (value !== '*' && label.toLowerCase().includes(input.toLowerCase())) ||
    (value === '*' && filteredOptions?.length > 0);

  const onInputChange = (inputValue, event) => {
    if (event.action === 'input-change') setSelectInput(inputValue);
    else if (event.action === 'menu-close' && selectInput !== '') setSelectInput('');
  };

  const handleChange = (selected) => {
    if (
      selected &&
      selected.length > 0 &&
      !isAllSelected.current &&
      (selected[selected.length - 1].value === allOption.value ||
        JSON.stringify(filteredOptions) === JSON.stringify(selected.sort(comparator)))
    ) {
      return props.onChange(
        [
          ...(props.value ?? []),
          ...props.options.filter(
            ({ label }) =>
              label.toLowerCase().includes(selectInput?.toLowerCase()) &&
              (props.value ?? []).filter((opt) => opt.label === label).length === 0,
          ),
        ].sort(comparator),
      );
    }
    if (
      selected &&
      selected.length > 0 &&
      selected[selected.length - 1].value !== allOption.value &&
      JSON.stringify(selected.sort(comparator)) !== JSON.stringify(filteredOptions)
    )
      return props.onChange(selected);

    return props.onChange([
      ...props.value?.filter(({ label }) => !label.toLowerCase().includes(selectInput?.toLowerCase())),
    ]);
  };

  const customStyles = {
    multiValueLabel: () => ({
      display: 'none',
    }),
    multiValueRemove: () => ({
      display: 'none',
    }),
    valueContainer: (base) => ({
      ...base,
      maxHeight: '65px',
    }),
    option: (styles) => {
      return {
        ...styles,
        backgroundColor: null,
        color: null,
      };
    },
    menu: (def) => ({ ...def, zIndex: 9999 }),
  };

  if (filteredOptions !== 0) {
    isAllSelected.current = filteredSelectedOptions.length === filteredOptions.length;

    if (filteredSelectedOptions?.length > 0) {
      if (filteredSelectedOptions?.length === filteredOptions?.length)
        selectAllLabel.current = `${I18n.t('All')} (${filteredOptions.length})`;
    } else selectAllLabel.current = I18n.t('Select all');

    allOption.label = selectAllLabel.current;

    return (
      <ReactSelect
        inputValue={selectInput}
        onInputChange={onInputChange}
        filterOption={customFilterOption}
        components={{
          Option,
          Input,
          ValueContainer,
        }}
        menuPlacement="bottom"
        tabSelectsValue={false}
        hideSelectedOptions={false}
        blurInputOnSelect={false}
        backspaceRemovesValue={false}
        styles={customStyles}
        options={[allOption, ...props.options]}
        noOptionsMessage={() => I18n.t('No options')}
        onChange={handleChange}
        isMulti
        closeMenuOnSelect={false}
        maxMenuHeight={170}
        value={props.value}
      />
    );
  }
};

const StyledOptionContainer = styled.div`
  display: flex;
  gap: 15px;
`;

const StyledValueContainer = styled.p`
  margin: 10px;
`;

export default MultiSelect;
