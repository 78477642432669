import handleActions from '../../immerHandleActions';
import * as actions from './actions';

const initialState = {
  isLoading: true,
  groups: [],
};

const competencyGroupsReducer = handleActions(
  {
    [actions.fetchCompetencyGroups]: (draft) => {
      draft.isLoading = true;
    },
    [actions.fetchCompetencyGroupsSuccess]: (draft, { payload: { data } }) => {
      draft.groups = data.map((item) => ({
        ...item,
        responseScale: item.responseScaleName ?? 'Default',
        estimatedTime: '30 min',
      }));
      draft.isLoading = false;
    },
    [actions.addCompetencyGroup]: (draft, { payload: { data } }) => {
      const newData = {
        ...data,
        responseScale: 'Default',
        estimatedTime: '30 min',
      };
      draft.groups = [...draft.groups, newData];
    },
    [actions.fetchCompetencyGroupsFailure]: (draft) => {
      draft.isLoading = false;
    },
    [actions.deleteAssessmentsSuccess]: (draft, { payload: { data } }) => {
      draft.groups = draft.groups.filter((item) => !data.includes(item.competencyGroupID));
    },
    [actions.restCompetencyGroups]: () => {
      return {
        ...initialState,
      };
    },
  },
  initialState,
);

export default competencyGroupsReducer;
